import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ViewCampaignLogsModal = React.lazy(
  () => import("./view-campaign-logs.modal"),
);
const ViewCampaignReportLogs: iModalProps<
  typeof ViewCampaignLogsModal,
  {
    campaignId: string;
    logType: string;
  }
> = {
  name: "campaign-report-logs",
  component: GenerateModalWithSuspense(ViewCampaignLogsModal),
};

export default ViewCampaignReportLogs;
