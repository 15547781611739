import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const TestWebhookAppModel = React.lazy(
  () => import("./test-webhook-app.model"),
);

const TestWebhookApp: iModalProps<
  typeof TestWebhookAppModel,
  { connectedAppId: string }
> = {
  name: "test-webhook-app",
  component: GenerateModalWithSuspense(TestWebhookAppModel),
};

export default TestWebhookApp;
