import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const CollectionAddEditModal = React.lazy(
  () => import("./collection-add-edit.modal"),
);

const CollectionAddEdit: iModalProps<
  typeof CollectionAddEditModal,
  {
    onSaved?: () => any;
    mode: "ADD" | "EDIT";
    collectionId?: string;
  }
> = {
  name: "collection-add-edit",
  component: GenerateModalWithSuspense(CollectionAddEditModal),
};

export default CollectionAddEdit;
