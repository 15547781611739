import { iChatConnectorType } from "@sdk/chat-connectors/chat-connectors.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Form, Input, message, Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectChatConnectorById } from "store/modules/chat-connectors/chat-connectors.selectors";
import { useChatConnectorEntity } from "./use-chat-connector-entity";

export const EditChatConnectorModal = ({
  chatConnectorId,
  visible,
  onChangeVisibility,
  onEdited,
  type
}: {
  chatConnectorId: string;
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onEdited?: () => any;
  type?: iChatConnectorType;
}) => {
  const [form] = Form.useForm();

  const chatConnector = useSelector(selectChatConnectorById(chatConnectorId));

  useEffect(() => {
    form.setFieldsValue(chatConnector);
  }, [chatConnector, form]);

  const {
    doAction: onEditChatConnector,
    isProcessing,
    response
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => values =>
        SDK.chatConnectors
          .editById(chatConnectorId, {
            ...values
          })
          .then(res => {
            onEdited && onEdited();
            onChangeVisibility(false);
            form.resetFields();
          }),
      successMessage: "Chat Connector has been edited",
      failureMessage: "Something went wrong"
    }),
    [chatConnectorId, form, onChangeVisibility, onEdited]
  );

  const { entityName, icon } = useChatConnectorEntity(type);

  return (
    (<Modal
      title={<ModalTitle icon={icon} title={`Edit ${entityName}`} />}
      open={visible}
      onOk={async () => {
        try {
          await form.validateFields();
          const formValues = form.getFieldsValue();
          onEditChatConnector(formValues);
        } catch (e) {
          message.error("Please check your input");
        }
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Create"
      data-click-context="Edit Chat Connector Modal"
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{}}
      >
        <Form.Item
          name="label"
          label=""
          rules={[
            {
              required: true,
              message: "Please enter a label"
            }
          ]}
        >
          <Input placeholder="Label" size="large" />
        </Form.Item>
      </Form>
      <DarkModeBg />
    </Modal>)
  );
};
