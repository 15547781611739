import React, { useEffect } from "react";

export const QRCodeImage = ({
  value,
  size,
}: {
  value: string;
  size: number;
}) => {
  const code = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const gen = async () => {
      const QRCode = await import("easyqrcodejs").then((d) => d.default);
      new QRCode(code.current, {
        text: value,
        //   logo: "/cc-assets/logo.png",
        logo: "/assets/wa-web/wa-logo.svg",
        logoBackgroundTransparent: true,
        colorDark: "#122e31",
        version: 1,
        width: size,
        height: size,
      });
    };
    gen();

    const current = code.current;
    return () => {
      if (current) {
        current.innerHTML = "";
      }
    };
  }, [code, size, value]);

  return <div ref={code}></div>;
};
