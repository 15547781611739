import { useCallback, useState } from "react";

export const useSimpleState = <T>(initialValue: T) => {
  const [state, _setState] = useState(initialValue);
  const setState = useCallback(
    (edits: Partial<T>) =>
      _setState(_state => ({
        ..._state,
        ...edits
      })),
    []
  );
  return [state, setState] as [typeof state, typeof setState];
};
