import { iScheduledReport } from "@sdk/scheduled-reports/scheduled-reports";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { scheduledReportsDefaultQueryConfig } from "./scheduled-reports-default-query";

export const {
  slice: ScheduledReportsSlice,
  reducers: ScheduledReportsSliceReducer,
  actions: {
    setEntityQueryResults: setScheduledReportsQueryResults,
    setEntity: setScheduledReport,
    setEntities: setScheduledReports,
    patchEntity: patchScheduledReport,
    addNewEntity: addNewScheduledReport,
    setEntityFetchingStatus: setScheduledReportFetchingStatus,
    setEntitiesFetchingStatus: setScheduledReportsFetchingStatus,
    setEntityFailedStatus: setScheduledReportFailedStatus,
    setEntityQueryError: setScheduledReportsQueryError,
    resetQuery: resetScheduledReportsQuery
  }
} = CreateEntitySlice<iScheduledReport>({
  sliceName: "scheduledReports",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...scheduledReportsDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
