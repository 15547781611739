import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const CompanyProfileModal = React.lazy(() => import("./company-profile.modal"));

const CompanyProfile: iModalProps<
  typeof CompanyProfileModal,
  { companyId: string }
> = {
  name: "company-viewer",
  component: GenerateModalWithSuspense(CompanyProfileModal),
};

export default CompanyProfile;
