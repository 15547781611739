/* eslint-disable react/jsx-pascal-case */
import { iNotificationRecord } from "@sdk/notification-inbox/notifications.models";
import React from "react";
import { NotificationItem_Invite } from "./notification-types/noitification-item-invite";
import { NotificationItem_TransferRequest } from "./notification-types/noitification-item-transfer-request";
import { NotificationItem_SimpleConversation } from "./notification-types/simple-conversation-notification";
import { NotificationItem_SimplePostCommentReply } from "./notification-types/simple-post-comment-notification";

export const NotificationItem = ({
  notification
}: {
  notification: iNotificationRecord;
}) => {
  const type = notification.data.type;

  switch (type) {
    case "CONVERSATION_TRANSFER_REQUEST": {
      return <NotificationItem_TransferRequest notification={notification} />;
    }
    case "CONVERSATION_TRANSFER_REQUEST_ACCEPTED":
    case "CONVERSATION_TRANSFER_REQUEST_REJECTED":
    case "CONVERSATION_ASSIGNED_ALERT": {
      return (
        <NotificationItem_SimpleConversation notification={notification} />
      );
    }
    case "CONVERSATION_INVITE_REQUEST": {
      return <NotificationItem_Invite notification={notification} />;
    }
    case "FB_COMMENT_REPLY": {
      return (
        <NotificationItem_SimplePostCommentReply notification={notification} />
      );
    }

    default:
      return <div>Unknown notification type</div>;
  }
};
