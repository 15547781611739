import { axios } from "@sdk/axios";
import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import { AxiosResponse } from "axios";
import { UserTracker } from "user-tracker";
import { iNPSSubmission } from "./nps-submissions-model";

export class NPSSubmissionsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.NPSSubmissions.configure(config);
  }

  NPSSubmissions = Object.assign(
    new EntityServices<iNPSSubmission>(SDKConfig, "nps-submissions", {
      onCreate: () => {
        UserTracker.track("NPS_SUBMISSIONS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("NPS_SUBMISSIONS - Delete", {});
      },
    }),
    {
      getAverageRating: async (query: any) => {
        const results: AxiosResponse<{ average: number }> = await axios.post(
          this.config.basePath + `/nps-submissions/get-average-rating`,
          query,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      getCount: async (query: any) => {
        const results: AxiosResponse<{ count: number }> = await axios.post(
          this.config.basePath + `/nps-submissions/get-count`,
          query,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
