export const EmailBlock_Header: (data: {
  image: string;
  title?: string;
}) => string = ({ image, title }) => {
  return /*html*/ ` <table class="row masthead">
    <tbody>
        <tr>
            <!-- Masthead -->
            <th class="small-12 large-12 columns first last">
                <table>
                    <tr>
                        <th>
                        ${
                          title
                            ? `<h1 class="text-center dark-text bold-font">${title}</h1>`
                            : ""
                        }
                            <center data-parsed="">
                            <img src="${image}" valign="bottom"
                                    alt="${title}" align="center" width="200"
                                    class="text-center w-200">
                            </center>
                        </th>
                        <th class="expander"></th>
                    </tr>
                </table>
            </th>
        </tr>
    </tbody>
</table>`;
};

export const EmailBlock_HeaderLonger: (data: {
  image: string;
  title?: string;
}) => string = ({ image, title }) => {
  return /*html*/ ` <table class="row masthead">
    <tbody>
        <tr>
            <!-- Masthead -->
            <th class="small-12 large-12 columns first last">
                <table>
                    <tr>
                        <th>
                        ${
                          title
                            ? `<h1 class="text-center dark-text bold-font">${title}</h1>`
                            : ""
                        }
                            <center data-parsed="">
                            <img src="${image}" valign="bottom"
                                    alt="${title}" align="center" width="400"
                                    class="text-center w-400">
                            </center>
                        </th>
                        <th class="expander"></th>
                    </tr>
                </table>
            </th>
        </tr>
    </tbody>
</table>`;
};
