import { Badge } from "antd";
import { selectTaskQuery } from "store/modules/tasks/tasks.selectors";
import { useSelectorWithMemoize } from "store/utils/use-selector-with-memoize";
import classNames from "utils/classnames";

const pageCount = 20;

export const QueryTasksBadgeCount = ({
  query,
  children,
}: {
  query?: string;
  children?: any;
}) => {
  const queryObject = useSelectorWithMemoize(
    () => selectTaskQuery(query!),
    [query],
  );
  const taskCount = queryObject?.list?.length || 0;

  const totalAvailableItems = queryObject.totalItems || 0;
  const totalFetchedItems = (queryObject.lastFetchedPage || 0) * pageCount;
  const hasMore = totalAvailableItems > totalFetchedItems;

  return (
    <>
      {taskCount ? (
        <Badge
          count={hasMore ? taskCount + 1 : taskCount}
          overflowCount={Math.min(totalFetchedItems, 99)}
          className={classNames("un-read-badge blue", {
            "w-full": Boolean(children),
          })}
        >
          {children}
        </Badge>
      ) : (
        children
      )}
    </>
  );
};
