export const deriveEntityTypeFromEntityId = (entityId: string) => {
  const dashedParts = entityId.split("-");
  if (dashedParts.length === 5) {
    return "CHAT";
  } else if (dashedParts.length === 3) {
    return "TASKS";
  }
  // Check for FB & IG
  const underscoreParts = entityId.split("_");
  if (underscoreParts.length === 2) {
    return "FB_POSTS";
  }
  // Eg: Valid IG_MEDIA - 18031230901381721 - 16chars
  if (
    dashedParts.length === 1 &&
    underscoreParts.length === 1 &&
    entityId.length > 10 &&
    entityId.length < 25
  ) {
    return "IG_MEDIAS";
  }
  return "CHAT";
};
