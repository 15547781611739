import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const UnsplashImageSelectorModal = React.lazy(
  () => import("./unsplash-image-selector.modal"),
);

const UnsplashImageSelector: iModalProps<
  typeof UnsplashImageSelectorModal,
  {
    initialQuery?: string;
    onPicked: (url: string) => any;
  }
> = {
  name: "unsplash-picker",
  component: GenerateModalWithSuspense(UnsplashImageSelectorModal),
};

export default UnsplashImageSelector;
