import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const EmailTranscriptModal = React.lazy(
  () => import("./email-transcript.modal"),
);

const EmailTranscript: iModalProps<
  typeof EmailTranscriptModal,
  { conversationId: string }
> = {
  name: "email-transcript",
  component: GenerateModalWithSuspense(EmailTranscriptModal),
};

export default EmailTranscript;
