import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";
import { iEmailCampaignData } from "./email-campaign-data-models";

const EmailCampaignEditorModal = React.lazy(
  () => import("./email-campaign-editor.modal"),
);

const EmailCampaignEditor: iModalProps<
  typeof EmailCampaignEditorModal,
  {
    data?: iEmailCampaignData;
    onSave: (data: iEmailCampaignData) => any;
    title: string;
    saveText: string;
  }
> = {
  name: "email-campaign-editor",
  component: GenerateModalWithSuspense(EmailCampaignEditorModal),
};

export default EmailCampaignEditor;
