import {
  iOrganizationHelpDeskPipelineStage,
  iPipelineCheckListItem,
} from "@sdk/user-management/user-management.models";
import { Button, Form, Input, Modal, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { SimpleDNDList } from "components/common/simple-dnd-list/dnd-list";
import { StyledSwitch } from "components/common/styled-swtich";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { AddEditPipelineStageCheckListItem } from "./add-edit-pipeline-stage-checklist-item";
import { CheckListItemType } from "./check-list-item-type-icons";

export const AddEditPipelineStage = ({
  visible,
  onChangeVisibility,
  onSubmit: _onSubmit,
  data,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onSubmit: (data: iOrganizationHelpDeskPipelineStage) => any;
  data?: iOrganizationHelpDeskPipelineStage;
}) => {
  const [form] = useForm();
  const [isProcessing, setProcessing] = useState(false);
  const [checkLists, setCheckLists] = useState([] as iPipelineCheckListItem[]);

  const onSubmit = useCallback(async () => {
    const formValues = form.getFieldsValue();
    try {
      setProcessing(true);
      await _onSubmit({
        ...formValues,
        checkLists,
      });
      setProcessing(false);
      onChangeVisibility(false);
      form.resetFields();
    } catch (e) {
      setProcessing(false);
    }
  }, [_onSubmit, checkLists, form, onChangeVisibility]);

  useEffect(() => {
    form.setFieldsValue({
      ...data,
    });
    setCheckLists(data?.checkLists || []);
  }, [form, data]);

  const onRemoveCheckListItem = useCallback(
    (checkListItemId: string) => {},
    [],
  );

  const [checkListItemModalState, setCheckListItemModalState] = useState<{
    visibility: boolean;
    mode: "ADD" | "EDIT";
    checkListItem: undefined | iPipelineCheckListItem;
  }>({
    visibility: false,
    mode: "ADD",
    checkListItem: undefined,
  });

  return (
    <>
      <Modal
        title={
          <ModalTitle
            icon={<i className="ri-flag-line"></i>}
            title={data ? `Edit Pipeline Stage` : `Create Pipeline Stage`}
          />
        }
        open={visible}
        okButtonProps={{ loading: isProcessing }}
        onOk={async () => {
          try {
            await form.validateFields();
            onSubmit();
          } catch (e) {
            message.error("Please check your input");
          }
        }}
        onCancel={() => {
          onChangeVisibility(false);
          form.resetFields();
        }}
        okText="Save"
        destroyOnClose={true}
        data-click-context="Add Edit Pipeline Stage Modal"
      >
        <Form layout="vertical" form={form}>
          {/* Label */}

          <Form.Item
            name="label"
            label="Label"
            rules={[{ required: true, message: "Please enter a name" }]}
          >
            <Input
              prefix={<i className="ri-flag-line"></i>}
              placeholder="Eg: Sales Funnel, Support Funnel"
              size="large"
            />
          </Form.Item>

          <SimpleDNDList
            list={checkLists}
            onListChange={setCheckLists}
            renderItem={(checkListItem, index) => (
              <PipelineCheckListItem
                key={checkListItem.id!}
                checkListItem={checkListItem}
                onDelete={onRemoveCheckListItem}
                onClick={() =>
                  setCheckListItemModalState({
                    visibility: true,
                    mode: "EDIT",
                    checkListItem: checkListItem,
                  })
                }
              />
            )}
          />
          <Button
            type="text"
            icon={<i className="ri-add-line"></i>}
            onClick={() =>
              setCheckListItemModalState({
                visibility: true,
                mode: "ADD",
                checkListItem: undefined,
              })
            }
          >
            Add Item
          </Button>

          <StyledSwitch
            name={["rules", "onlyMovableToNextStgeIfTasksAreCompleted"]}
            label="Requires Tasks to be completed to move to next stage"
          />
        </Form>

        <AddEditPipelineStageCheckListItem
          visible={checkListItemModalState.visibility}
          onChangeVisibility={() =>
            setCheckListItemModalState({
              visibility: false,
              mode: "ADD",
              checkListItem: undefined,
            })
          }
          onSubmit={(data) => {
            console.log("data", data, checkListItemModalState);
            if (checkListItemModalState.mode === "ADD") {
              setCheckLists([
                ...checkLists,
                {
                  ...data,
                  id: String(new Date().getTime()),
                },
              ]);
            } else {
              const existingItem = _.find(checkLists, {
                id: checkListItemModalState?.checkListItem?.id,
              });
              if (existingItem) {
                setCheckLists(
                  checkLists.map((item) => {
                    if (item === existingItem) {
                      return {
                        ...item,
                        ...data,
                      };
                    }
                    return item;
                  }),
                );
              }
            }
          }}
          data={checkListItemModalState.checkListItem}
        />
        <DarkModeBg />
      </Modal>
    </>
  );
};

const PipelineCheckListItem = ({
  checkListItem,
  onDelete,
  onClick,
}: {
  checkListItem: iPipelineCheckListItem;
  onDelete: (itemId: string) => any;
  onClick: (item: iPipelineCheckListItem) => any;
}) => {
  return (
    <div
      className="flex flex-row justify-between items-center cursor-pointer"
      onClick={() => onClick(checkListItem)}
    >
      <div className="flex flex-row items-center">
        {CheckListItemType[checkListItem.type]}
        {checkListItem.label}
      </div>
      <div className="right">
        <Button
          type="text"
          icon={<i className="ri-delete-bin-line"></i>}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(checkListItem.id);
          }}
        />
      </div>
    </div>
  );
};
