import { useSelector } from "react-redux";
import { selectIsDarkMode } from "store/modules/ui-state/ui-state.selectors";

export const DarkModeBg = () => {
  const isDarkMode = useSelector(selectIsDarkMode);
  if (!isDarkMode) {
    return <></>;
  }
  return (
    <div className="absolute z-20 top-0 inset-x-0 flex justify-center overflow-hidden pointer-events-none max-h-full">
      <div
        className="w-[108rem] flex-none flex justify-end"
        style={{ width: "1440px" }}
      >
        <picture
          style={{
            /* opacity: '0.6', */
            display: "none",
          }}
        >
          <source
            srcSet="/assets/dark-mode-bg/docs@30.beeb08605f12f699c5abc3814763b65e.avif"
            type="image/avif"
          />
          <img
            src="/assets/dark-mode-bg/docs@tinypng.61f4d3334a6d245fc2297517c87ae044.png"
            alt=""
            className="w-[71.75rem] flex-none max-w-none"
            decoding="async"
          />
        </picture>
        <picture>
          <source
            srcSet="/assets/dark-mode-bg/docs-dark@30.1a9f8cbf.avif"
            type="image/avif"
          />
          <img
            src="/assets/dark-mode-bg/docs-dark@tinypng.a8984b7fb44a4f8232d04de50220ab31.png"
            alt=""
            className="w-[90rem] flex-none max-w-none"
            decoding="async"
            style={{ width: "100%" }}
          />
        </picture>
      </div>
    </div>
  );
};
