import { iStore } from "../../store.model";

const defaultMessageObject = {
  data: [],
  fetchedPages: 0,
  newMessages: 0,
  unread: 0,
  isFullyFetched: false,
  isFetching: false,
  hasError: false
};

const emptyArray = [];

export const selectMessageByConversationId = (conversationId: string) => (
  state: iStore
) => state.messages.byConversations[conversationId] || defaultMessageObject;

export const selectMessageListByConversationId = (conversationId: string) => (
  state: iStore
) => state.messages.byConversations[conversationId]?.data || emptyArray;

export const selectUnreadCount = (conversationId: string) => (state: iStore) =>
  state.messages.byConversations[conversationId]?.unread || 0;

export const selectMessageCountByConversationId = (conversationId: string) => (
  state: iStore
) => state.messages.byConversations?.[conversationId]?.data?.length || 0;

export const selectMessagesMap = (state: iStore) =>
  state.messages.byConversations;
