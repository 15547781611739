import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iProactiveCampaign } from "@sdk/proactive-campaigns/proactive-campaigns-model";
import React from "react";
import { DeepPartial } from "redux";

const ProactiveCampaignEditorModal = React.lazy(
  () => import("./proactive-campaign-editor.modal"),
);

const ProactiveCampaignEditor: iModalProps<
  typeof ProactiveCampaignEditorModal,
  {
    campaignId?: string;
    initialData?: DeepPartial<iProactiveCampaign>;
  }
> = {
  name: "add-edit-proactive-campaign",
  component: GenerateModalWithSuspense(ProactiveCampaignEditorModal),
};

export default ProactiveCampaignEditor;
