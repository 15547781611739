import { iTicket } from "@sdk/tickets/tickets-model";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";
import { ticketsDefaultQueryConfig } from "./tickets-default-query";

export const {
  selectQueryMap: selectTicketQueryMap,
  selectEntityMap: selectTicketsMap,
  selectQueryMapAll: selectTicketsMapAll,
  selectQueryAllList: selectTicketsAllList,
  selectAllEntities: selectAllTickets,
  selectAllEntitiesQuery: selectAllTicketsQuery,
  selectEntityById: selectTicketById,
  selectEntityQuery: selectTicketQuery,
  selectEntityQueryList: selectTicketQueryList,
} = CreateSelectorForEntities<iStore, iTicket>({
  sliceName: "tickets",
});

export const generateTicketsByContactIdQuery = _.memoize(
  (contactId: string) => {
    const queryConfig = {
      ...ticketsDefaultQueryConfig,
      query: {
        "references.contactIds": contactId,
      },
    };
    return JSON.stringify(queryConfig);
  },
);

export const selectTicketsByContactId = (contactId: string) => {
  return selectTicketQuery(generateTicketsByContactIdQuery(contactId));
};
