import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const DPASignerModal = React.lazy(() => import("./dpa-signer.modal"));

const DPASigner: iModalProps<typeof DPASignerModal, {}> = {
  name: "dpa-signer",
  component: GenerateModalWithSuspense(DPASignerModal),
};

export default DPASigner;
