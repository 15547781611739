import { SDK } from "@sdk";
import { iConnection } from "@sdk/conversations/conversations.models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { connectionDefaultQueryConfig } from "./connection-default-query";
import { ConnectionsSlice } from "./connections.slice";

export const loadAllConnections = (
  store: Store<iStore>,
  forceReload?: boolean
) =>
  loadConnectionsQuery(connectionDefaultQueryConfig, "all")(store, forceReload);

export const {
  loadEntityQueries: loadConnectionsQuery,
  loadEntityById: loadConnectionById,
  loadEntitiesByIds: bulkLoadConnectionsByIds
} = CreateHelpersForEntity<iStore, iConnection>({
  sliceName: "connections",
  slice: ConnectionsSlice,
  isPaginatedQueries: true,
  loadEntityQueryProvider: query => SDK.connections.query(query),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.connections.getById(entityId),
  loadEntityByIdsProvider: (connectionIds: string[]) =>
    (async () => {
      const connections = await Promise.all(
        connectionIds.map(connectionId =>
          // Todo:
          SDK.connections.getById(connectionId)
        )
      );
      return connections;
    })()
});
