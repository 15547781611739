import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ExpressAccountSetUpModal = React.lazy(
  () => import("./express-account-setup.modal"),
);

const ExpressAccountSetUp: iModalProps<typeof ExpressAccountSetUpModal> = {
  name: "express-account-set-up",
  component: GenerateModalWithSuspense(ExpressAccountSetUpModal),
};

export default ExpressAccountSetUp;
