import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";
import { iShopifyProductVariant } from "./shopify-product-browser.modal";

const ShopifyProductBrowserModal = React.lazy(
  () => import("./shopify-product-browser.modal"),
);

const ShopifyProductBrowser: iModalProps<
  typeof ShopifyProductBrowserModal,
  {
    onSelected?: (product: iShopifyProductVariant) => any;
  }
> = {
  name: "shopify-product-browser",
  component: GenerateModalWithSuspense(ShopifyProductBrowserModal),
};

export default ShopifyProductBrowser;
