import { notification, Space } from "antd";
import { HelpCenterPromptButton } from "./help-center-prompt-button";

const iconMap = {
  QUESTION: <i className="ri-question-line" style={{ paddingRight: 0 }}></i>,
  LEARN: <i className="ri-book-mark-line" style={{ paddingRight: 0 }}></i>,
};

export const FireHelpCenterTip = ({
  title,
  description,
  articleId,
  collectionId,
}: {
  title: string;
  description: string;
  articleId: string;
  collectionId: string;
}) => {
  notification.open({
    message: <div className="font-bold">{title}</div>,
    description: description,
    btn: (
      <Space>
        {/* <NotificationBackgroundDimmer /> */}

        <HelpCenterPromptButton
          type="BANNER"
          articleId={articleId}
          collectionId={collectionId}
          icon="LEARN"
          text="Learn More"
          onViewed={() => {
            notification.destroy(title);
          }}
        />
        {/* <Button type={"default"} onClick={() => notification.close(title)}>
          Dismiss
        </Button> */}
      </Space>
    ),
    key: title,
    duration: 0,
  });
};
