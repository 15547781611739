import { useCallback, useEffect, useRef, useState } from "react";

export function useStateIfMountedX<T>(initialValue: T) {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [state, setState] = useState(initialValue);

  const newSetState = useCallback(value => {
    if (isMounted.current) {
      setState(value);
    }
  }, []);

  return [state, newSetState] as [typeof state, typeof setState];
}
