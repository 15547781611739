import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AiReplyWriterModal = React.lazy(() => import("./ai-reply-writer.modal"));

const AiReplyWriter: iModalProps<
  typeof AiReplyWriterModal,
  {
    conversationId: string;
    query?: string;
    onReplaceText: (content: string) => any;
  }
> = {
  name: "ai-reply-writer",
  component: GenerateModalWithSuspense(AiReplyWriterModal),
};

export default AiReplyWriter;
