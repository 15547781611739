import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Popconfirm } from "antd";
import { CollapsibleConfigurationSection } from "components/common/collapsible-configuration-panel/collapsible-configuration-container";
import { loadAllConnections } from "store/modules/connections/connections.helpers";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const DeleteConnectionSettings = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const { state: connections, retry: reload } = useQueryWithStore(
    selectAllConnections,
    loadAllConnections,
  );
  const { doAction: deleteConnection, isProcessing: isDeletingConnection } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (confirmation: string) =>
          SDK.dangerousDeletion
            .deleteConnection(connectionId, confirmation)
            .then((d) => {
              reload();
            }),
        successMessage: "Connection and associated data is deleted",
        failureMessage: "Something went wrong",
      }),
      [connectionId, reload],
    );

  return (
    <CollapsibleConfigurationSection
      icon={"ri-delete-bin-2-line"}
      title={"Permanently Delete Connection and Associated Data"}
      description={"Delete connection and all associated data"}
    >
      <div>
        <div className="mb-4">
          It's important to note that removing a connection is permanent and
          will delete all related data, such as chats and conversations.
        </div>
        <Popconfirm
          placement="leftTop"
          title="Are you sure? All conversation and contact records will be permanently removed. This action is non-reversible"
          onConfirm={() => {
            deleteConnection("CONFIRM");
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="primary"
            icon={<i className="ri-delete-bin-3-line"></i>}
            loading={isDeletingConnection}
            className="bg-red-600"
          >
            Delete Connection
          </Button>
        </Popconfirm>
      </div>
    </CollapsibleConfigurationSection>
  );
};
