export const LoadingView = (
  <div className="flex items-center justify-center h-screen w-screen router-loading-view">
    {/* <i className="ri-loader-5-line animate-spin text-4xl"></i> */}
    {/* <div className="loader animate-spin text-4xl">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="48"
        height="48"
        style={{ fill: "currentColor" }}
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 3a9 9 0 0 1 9 9h-2a7 7 0 0 0-7-7V3z" />
      </svg>
    </div> */}
    {/* Loading */}
    <div className="flex justify-center rounded-lg bg-gray-300/[1%] p-8 ring-1 ring-inset ring-gray-300/[3%]">
      <div className="w-[200px]">
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-6 py-1">
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-40 bg-slate-200 rounded-lg col-span-1"></div>
                <div className="col-span-2 grid gap-4">
                  <div className="h-4 bg-slate-200 rounded-lg"></div>
                  <div className="h-4 bg-slate-200 rounded-lg"></div>
                  <div className="h-4 bg-slate-200 rounded-lg"></div>
                  <div className="h-4 bg-slate-200 rounded-lg"></div>
                  <div className="h-4 bg-slate-200 rounded-lg"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
