import { Form, Input } from "antd";
import { ConfigurationEditorMiniComponent } from "components/common/configuration-editor-mini";

export const ChannelNameForm = ({ connectionId }: { connectionId }) => {
  return (
    <ConfigurationEditorMiniComponent
      entityType="CONNECTION"
      entityId={connectionId}
      icon={"ri-folder-info-line"}
      title="Connection Name"
      description="Change this connection's name"
    >
      <Form.Item
        label="Channel Name"
        name="label"
        rules={[{ required: true, message: "Please enter a friendly name" }]}
        help="A friendly name to identify channel Eg: Support Email / Sales Email"
      >
        <Input placeholder="" size="large" />
      </Form.Item>
    </ConfigurationEditorMiniComponent>
  );
};
