import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const NewPlanComparisonModal = React.lazy(
  () => import("./new-plan-comparison.modal"),
);

const NewPlanComparison: iModalProps<
  typeof NewPlanComparisonModal,
  {
    planNames: string[];
    highlightedColumn: number;
  }
> = {
  name: "compare-plans",
  component: GenerateModalWithSuspense(NewPlanComparisonModal),
};

export default NewPlanComparison;
