import { Popover, Transition } from "@headlessui/react";
import { useState } from "react";
import { usePopper } from "react-popper";
import classNames from "utils/classnames";
export const CCPopover = ({
  content,
  children,
  buttonClassName,
  className,
}: {
  content: JSX.Element | (({ closePopover }) => JSX.Element);
  children;
  // State is not used
  open?: boolean;
  onOpenChange?: (open: boolean) => any;
  trigger?: any;
  placement?: any;
  destroyTooltipOnHide?: boolean;
  buttonClassName?: string;
  className?: string;
}) => {
  const [referenceElement, setReferenceElement] = useState(null as any);
  const [popperElement, setPopperElement] = useState(null as any);
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  return (
    <Popover className="">
      {({ open }) => (
        <>
          <Popover.Button ref={setReferenceElement} className={buttonClassName}>
            {children}
          </Popover.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100 "
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            className={"z-10 relative"}
          >
            {/* Mark this component as `static` */}
            <Popover.Panel
              className={classNames(
                "absolute z-9999 bg-white beautiful-shadow dark-gradient-bg p-4 mt-2 dark:bg-black",
                className,
              )}
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              {({ close }) =>
                typeof content === "function"
                  ? content({ closePopover: close })
                  : content
              }
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
