import { iMarketingReference } from "@sdk/marketing-references/marketing-references.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";

export const {
  slice: MarketingReferencesSlice,
  reducers: MarketingReferencesSliceReducer,
  actions: {
    setEntityQueryResults: setMarketingReferencesQueryResults,
    setEntity: setMarketingReference,
    setEntities: setMarketingReferences,
    patchEntity: patchMarketingReference,
    addNewEntity: addNewMarketingReference,
    setEntityFetchingStatus: setMarketingReferenceFetchingStatus,
    setEntitiesFetchingStatus: setMarketingReferencesFetchingStatus,
    setEntityFailedStatus: setMarketingReferenceFailedStatus,
    setEntityQueryError: setMarketingReferencesQueryError,
    resetQuery: resetMarketingReferencesQuery,
    setQueryList: setMarketingReferenceQueryList
  }
} = CreateEntitySlice<iMarketingReference>({
  sliceName: "marketingReferences"
});
