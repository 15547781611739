export const TicketTypeTemplates = [
  {
    id: "1704970462647",
    label: "Bug Report",
    startingStageLabel: "Reported",
    successfulExitStageLabel: "Resolved",
    failureExistStageLabel: "Dropped",
    stages: [
      {
        id: "1704339220120",
        label: "Need More Info",
        checkLists: [],
      },
      {
        id: "1704339202425",
        label: "Investigating",
        checkLists: [],
      },
      {
        id: "1704339212475",
        label: "In Build",
        checkLists: [],
      },
    ],
    properties: [
      {
        id: "94921545",
        key: "94921545",
        label: "Module",
        description: "",
        inputType: "SELECT",
        inputConfig: {
          options: [
            {
              value: "User Management",
              label: "User Management",
            },
            {
              value: "Module A",
              label: "Module A",
            },
            {
              value: "Module B",
              label: "Module B",
            },
          ],
          validations: [],
        },
      },
      {
        id: "e6299247",
        key: "e6299247",
        label: "First Identified Date",
        inputType: "DATE",
      },
    ],
    type: "PUBLIC_TICKET",
    emoji: "🐞",
  },
  {
    id: "1704970472282",
    label: "Feature Request",
    type: "PUBLIC_TICKET",
    properties: [
      {
        id: "f783562a",
        key: "f783562a",
        label: "User Persona",
        inputType: "SELECT",
        inputConfig: {
          options: [
            {
              value: "Persona 1",
              label: "Persona 1",
            },
            {
              value: "Persona 2",
              label: "Persona 2",
            },
          ],
          validations: [],
        },
      },
    ],
    startingStageLabel: "Submitted",
    successfulExitStageLabel: "Released",
    failureExistStageLabel: "Dropped",
    stages: [
      {
        id: "1704878722827",
        label: "Under Review",
        checkLists: [],
      },
      {
        id: "1704878751643",
        label: "In Build",
        checkLists: [],
      },
    ],
    emoji: "💡",
  },
  {
    id: "1705011632417",
    label: "Extended Support Request",
    type: "CUSTOMER_TICKET",
    properties: [],
    startingStageLabel: "Query Received",
    successfulExitStageLabel: "Resolved",
    failureExistStageLabel: "Dropped",
    stages: [
      {
        id: "1705011620338",
        label: "Need More Info",
        checkLists: [],
      },
      {
        id: "1705011610780",
        label: "Investigating",
        checkLists: [],
      },
    ],
    emoji: "⛑️",
  },
  {
    id: "1705011696924",
    label: "Support Request",
    type: "CUSTOMER_TICKET",
    properties: [],
    startingStageLabel: "Query Received",
    successfulExitStageLabel: "Resolved",
    failureExistStageLabel: "Dropped",
    stages: [
      {
        id: "1705011643079",
        label: "Investigating",
        checkLists: [],
      },
      {
        id: "1705011654606",
        label: "Need More Info",
        checkLists: [],
      },
    ],
    emoji: "⛑️",
  },
  {
    id: "1705011717145",
    label: "Refund Request",
    type: "CUSTOMER_TICKET",
    properties: [],
    startingStageLabel: "Request Received",
    successfulExitStageLabel: "Refunded",
    failureExistStageLabel: "Dropped",
    stages: [
      {
        id: "1705011706854",
        label: "Processing",
        checkLists: [],
      },
    ],
    emoji: "💵",
  },
  {
    id: "1705011800374",
    label: "Demo Requested",
    type: "CUSTOMER_TICKET",
    properties: [],
    startingStageLabel: "Requested",
    successfulExitStageLabel: "Demo Scheduled",
    failureExistStageLabel: "Rejected",
    stages: [
      {
        id: "1705011720911",
        label: "Filtering",
        checkLists: [],
      },
    ],
    emoji: "💻",
  },
  {
    id: "1705011882192",
    label: "Order Change Request",
    type: "CUSTOMER_TICKET",
    properties: [
      {
        id: "7645be7b",
        key: "7645be7b",
        label: "Order ID",
        inputType: "TEXT",
        inputConfig: {
          validations: [],
          options: [],
        },
      },
    ],
    startingStageLabel: "Request Received",
    successfulExitStageLabel: "Order Changed",
    failureExistStageLabel: "Dropped",
    stages: [
      {
        id: "1705011816382",
        label: "Processing",
        checkLists: [],
      },
    ],
    emoji: "🛒",
  },
];
