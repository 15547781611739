import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddEmailConnectionModal = React.lazy(
  () => import("./add-email-connection.modal"),
);

const AddEmailConnection: iModalProps<typeof AddEmailConnectionModal> = {
  name: "add-email-connection",
  component: GenerateModalWithSuspense(AddEmailConnectionModal),
};

export default AddEmailConnection;
