import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const SideBarContactProfileConfigModal = React.lazy(
  () => import("./chat-side-bar-profile-config.modal"),
);

const SideBarContactProfileSectionConfig: iModalProps<
  typeof SideBarContactProfileConfigModal,
  {}
> = {
  name: "side-bar-profile-section",
  component: GenerateModalWithSuspense(SideBarContactProfileConfigModal),
};

export default SideBarContactProfileSectionConfig;
