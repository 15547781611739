import { iConnectorAPICallConfig } from "@sdk/chat-connectors/chat-connectors.models";
import { ReplaceTokens } from "utils/token-replacer";

export const GenerateUrlConfig = (
  apiConfig: iConnectorAPICallConfig,
  context: Record<string, any>,
) => {
  const urlParameters = {} as Record<string, string>;
  for (const key in apiConfig.urlParameters) {
    urlParameters[key] = ReplaceTokens(
      apiConfig.urlParameters[key].value,
      context,
    );
  }
  const urlString = ReplaceTokens(apiConfig.url, urlParameters);
  try {
    const url = new URL(
      urlString.includes("https://") ? urlString : `https://${urlString}`,
    );

    for (const key in apiConfig.queryParaMeters) {
      url.searchParams.append(
        key,
        ReplaceTokens(apiConfig.queryParaMeters[key].value, context),
      );
    }
    const body = {} as any;
    for (const key in apiConfig.body) {
      body[key] = ReplaceTokens(apiConfig.body[key].value, context);
    }
    const headers = {} as any;
    for (const key in apiConfig.headers) {
      headers[key] = ReplaceTokens(apiConfig.headers[key].value, context);
    }
    return {
      url: url.toString(),
      body: body,
      headers,
    };
  } catch (e) {
    return {
      url: "INVALID_URL",
      body: {},
      headers: {},
    };
  }
};
