import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ZohoDeskCreateTicketModal = React.lazy(
  () => import("./zoho-desk-create-ticket.modal"),
);

const ZohoDeskCreateTicket: iModalProps<
  typeof ZohoDeskCreateTicketModal,
  {
    onAdded?: (pageId: string) => any;
    contactId?: string;
    conversationId?: string;
  }
> = {
  name: "zoho-desk-create-ticket",
  component: GenerateModalWithSuspense(ZohoDeskCreateTicketModal),
};

export default ZohoDeskCreateTicket;
