import { axios, AxiosResponse } from "@sdk/axios";
import { iTextTransaction } from "@sdk/texts/texts.models";
import {
  PaginatedOptions,
  PaginatedResults
} from "@sdk/utils/paginated-results";

export class TransactionsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  /* ----------------------------- Transactions ----------------------------- */

  async queryTransactions(req: { query: any; options: PaginatedOptions }) {
    const results: AxiosResponse<PaginatedResults<
      iTextTransaction
    >> = await axios.post(this.config.basePath + "/transactions/query", req, {
      headers: {
        Authorization: this.config.token
      }
    });
    return results.data;
  }
}
