import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import {
  iConnection,
  iEmailConnectionData,
} from "@sdk/conversations/conversations.models";
import { Divider, Form, Input, Select, Switch } from "antd";
import {
  ConfigurationEditorMiniBase,
  ConfigurationEditorMiniComponent,
} from "components/common/configuration-editor-mini";
import { SectionHeader } from "components/common/section-header";
import SimpleModal from "components/common/simple-empty-modal/simple-empty.modal-registry";
import { GeneralConnectionConfig } from "components/modules/connections/common-connection-settings/general-connection-config";
import { RoutingPreferencesForm } from "components/modules/connections/common-connection-settings/routing-preferences-form/routing-preferences-form";
import { GlobalConfig } from "config";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { isEmail } from "utils/is-email";
import { debouncedMailboxEmailAvailability } from "../../helpers/mailbox-availabilty-checker";
import { ConfigureInbuiltMailServer } from "../configure-inbuilt-mail-server/configure-inbuilt-mail-server";
import { ConfigureCustomSMTP } from "../configure-smtp-connection/configure-smtp-connection";
import { ConfigureTicketWorkflows } from "../configure-ticket-workflows/configure-ticket-workflows";
import { ConnectGmailToConnection } from "../connect-gmail-to-connection/connect-gmail-to-connection";

export const ConfigureMailbox = ({
  connectionId,
  hideHeader,
}: {
  connectionId: string;
  hideHeader?: boolean;
}) => {
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const { customSmtp, customDomain, gmailMailboxData } =
    (connection?.data as iEmailConnectionData) || {};

  const isCustomEmailConfigured =
    customSmtp || customDomain || gmailMailboxData;

  const { triggerTempModal } = useModalPanels();

  return (
    <div className="animated fadeInLeftMin">
      {!hideHeader && (
        <>
          <SectionHeader
            title="Configure Mailbox"
            icon={<i className="ri-mail-settings-line"></i>}
            description="Configure mailbox, access, email workflows, etc"
          />
          <Divider />
        </>
      )}

      <div className="flex flex-row gap-4">
        <div className="flex-1">
          <Divider>Channel Setup</Divider>
          <ConfigurationEditorMiniComponent
            entityType="CONNECTION"
            entityId={connectionId}
            icon={"ri-user-smile-line"}
            title={`Sender Name - ${connection.data.senderName}`}
            description="Change the name a customer sees when sending an email through ClickConnector"
          >
            <Form.Item
              label="Sender Name"
              name={["data", "senderName"]}
              help="Your name which your clients will see Eg: Support @ ABC"
              rules={[{ required: true, message: "Please select an email" }]}
            >
              <Input placeholder="" size="large" />
            </Form.Item>
          </ConfigurationEditorMiniComponent>

          <ConfigurationEditorMiniComponent
            entityType="CONNECTION"
            entityId={connectionId}
            icon={"ri-at-line"}
            title={`ClickConnector Email - ${connection.data.email}`}
            description="Change the email address used within ClickConnector"
            transformFormValues={(values) => ({
              data: {
                email: `${values.data.email}@${GlobalConfig.HELP_DESK_EMAIL_DOMAIN}`,
              },
            })}
            transformEntityValues={(connection: iConnection) => ({
              data: {
                email: connection.data.email.replace(
                  `@${GlobalConfig.HELP_DESK_EMAIL_DOMAIN}`,
                  "",
                ),
              },
            })}
          >
            <Form.Item
              label="Email"
              name={["data", "email"]}
              rules={[
                { required: true, message: "Please select an email" },
                ({ getFieldValue }) => ({
                  validator: async (rule, value) => {
                    const isValid = isEmail(
                      `${value}@${GlobalConfig.HELP_DESK_EMAIL_DOMAIN}`,
                    );
                    if (!isValid) {
                      throw `${value}@${GlobalConfig.HELP_DESK_EMAIL_DOMAIN} is not a valid email`;
                    }
                  },
                }),
                ({ getFieldValue }) => ({
                  validator: async (rule, value) =>
                    await debouncedMailboxEmailAvailability(
                      `${value}@${GlobalConfig.HELP_DESK_EMAIL_DOMAIN}`,
                      connectionId,
                    ),
                }),
              ]}
              normalize={(term) => term.toLowerCase()}
            >
              <Input
                placeholder="support.myCompany"
                suffix={`@${GlobalConfig.HELP_DESK_EMAIL_DOMAIN}`}
                size="large"
              />
            </Form.Item>
          </ConfigurationEditorMiniComponent>

          {(!isCustomEmailConfigured || customDomain) && (
            <ConfigurationEditorMiniBase
              icon={"ri-mail-settings-line"}
              title="Setup Custom Email Domain"
              description="Setup a custom email address if you have a domain but no email server"
              onClick={() => {
                triggerTempModal(SimpleModal, {
                  config: {
                    title: "Setup Custom Email Domain",
                    content: (
                      <ConfigureInbuiltMailServer connectionId={connectionId} />
                    ),
                    icon: <i className="ri-mail-settings-line"></i>,
                    width: 800,
                  },
                });
              }}
            ></ConfigurationEditorMiniBase>
          )}

          {(!isCustomEmailConfigured || customSmtp) && (
            <ConfigurationEditorMiniBase
              icon={"ri-mail-settings-line"}
              title="Connect Gmail/AWS/Custom SMTP"
              description="Use an existing mail service provider through ClickConnector"
              onClick={() => {
                triggerTempModal(SimpleModal, {
                  config: {
                    title: "Connect Gmail/AWS/Custom SMTP",
                    content: (
                      <ConfigureCustomSMTP connectionId={connectionId} />
                    ),
                    icon: <i className="ri-mail-settings-line"></i>,
                    width: 800,
                  },
                });
              }}
            ></ConfigurationEditorMiniBase>
          )}

          {(!isCustomEmailConfigured || gmailMailboxData) && (
            <ConfigurationEditorMiniBase
              icon={"ri-mail-settings-line"}
              title="Connect Gmail (Depreciated)"
              description="Use an existing Gmail account through ClickConnector"
              onClick={() => {
                triggerTempModal(SimpleModal, {
                  config: {
                    title: "Connect Gmail (Depreciated)",
                    content: (
                      <ConnectGmailToConnection connectionId={connectionId} />
                    ),
                    icon: <i className="ri-mail-settings-line"></i>,
                    width: 800,
                  },
                });
              }}
            ></ConfigurationEditorMiniBase>
          )}
        </div>
        <div className="flex-1">
          <ConfigureTicketWorkflows connectionId={connectionId} hideHeader />
        </div>
      </div>

      <div className="flex flex-row gap-4">
        <div className="flex-1">
          {" "}
          <Divider>Email Routing Settings</Divider>
          {/* Routing Settings */}
          <RoutingPreferencesForm connectionId={connectionId} />
        </div>
        <div className="flex-1">
          <Divider>Mailbox Settings</Divider>
          <ConfigurationEditorMiniComponent
            entityType="CONNECTION"
            entityId={connectionId}
            icon={"ri-spam-line"}
            title="Spam Filter"
            description="Enable/disable default email spam filter"
            miniEditor={
              <>
                <Form.Item
                  valuePropName="checked"
                  name={["data", "enableSpamFilter"]}
                >
                  <Switch size="small" />
                </Form.Item>
              </>
            }
          ></ConfigurationEditorMiniComponent>

          <ConfigurationEditorMiniComponent
            entityType="CONNECTION"
            entityId={connectionId}
            icon={"ri-mail-star-line"}
            title="Read 'Reply-to' Headers as Sender"
            description="Automatically read 'Reply-to' headers as Sender"
            miniEditor={
              <>
                <Form.Item
                  valuePropName="checked"
                  name={["data", "readReplyToAsFrom"]}
                >
                  <Switch size="small" />
                </Form.Item>
              </>
            }
          ></ConfigurationEditorMiniComponent>

          <ConfigurationEditorMiniComponent
            entityType="CONNECTION"
            entityId={connectionId}
            icon={"ri-mail-lock-line"}
            // icon={"ri-chat-forward-line"}
            title="Whitelisted Forwarding Emails"
            description="When emails are forwarded from these address, ClickConnector will attempt to parse the original email that is forwarded"
          >
            <Form.Item
              name={["data", "whiteListedForwarderEmails"]}
              label="Whitelisted Forwarding Email Addresses"
              rules={[]}
            >
              <Select
                mode="tags"
                style={{ width: "100%" }}
                tokenSeparators={[","]}
                dropdownStyle={{ display: "none" }}
              >
                {" "}
              </Select>
            </Form.Item>
          </ConfigurationEditorMiniComponent>

          <ConfigurationEditorMiniComponent
            entityType="CONNECTION"
            entityId={connectionId}
            icon={"ri-chat-settings-line"}
            title="Enable Simple Chat Ids"
            description="5 digit Chat ID allows you to easily refer conversations with customer"
            miniEditor={
              <>
                <Form.Item
                  valuePropName="checked"
                  name={[
                    "data",
                    "ticketWorkflowConfig",
                    "simpleTicketIds",
                    "isEnabled",
                  ]}
                >
                  <Switch size="small" />
                </Form.Item>
              </>
            }
          ></ConfigurationEditorMiniComponent>
        </div>
      </div>

      <GeneralConnectionConfig
        connectionId={connectionId}
        withoutHeader
        powerSettingsDefaultExpanded
        additionalPowerSettings={<></>}
      />
    </div>
  );
};
