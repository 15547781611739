import { Alert, Form, Input } from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import React from "react";
import { debouncedMailboxEmailAvailability } from "../../helpers/mailbox-availabilty-checker";

export const ConfigureNewCustomEmailDomainForm = ({
  formInstance,
  showCreateHelpText,
  connectionId
}: {
  formInstance: FormInstance;
  showCreateHelpText?: boolean;
  connectionId?: string;
}) => {
  const [form] = useForm(formInstance);
  return (
    <Form layout="vertical" form={form} initialValues={{}}>
      {/* Email */}
      <Form.Item
        label="Custom Email (with your custom domain)"
        name="email"
        rules={[
          {
            required: true,
            type: "email",
            message: "Please enter a valid email"
          },
          ({ getFieldValue }) => ({
            validator: async (rule, value) =>
              await debouncedMailboxEmailAvailability(value, connectionId)
          })
        ]}
        help=""
      >
        <Input placeholder="support@abc.com" size="large" />
      </Form.Item>

      <Alert
        message="Make sure you can modify DNS records for the domain you have selected"
        type="info"
        showIcon
        className="p-4 rounded-lg"
      />
    </Form>
  );
};
