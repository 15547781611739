import { iEmailV2ConnectionData } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Divider, Input, message, Space, Spin } from "antd";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import {
  selectOrganization,
  selectOrganizationOnboardingState,
} from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { isEmail } from "utils/is-email";
import { ConnectionSendingDomainConfig } from "../connection-sending-domain-config/connection-sending-domain-config";

export const ConnectionSendingDomainSetup = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const organization = useSelector(selectOrganization);
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const { doAction: editConnection, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (edits) => SDK.connections.editById(connectionId, edits),
      failureMessage: "Something went wrong",
    }),
    [connectionId],
  );

  const onboardingState = useSelector(selectOrganizationOnboardingState);

  const organizationDomain = useMemo(() => {
    if (!organization?.data.website && !onboardingState?.organizationWebsite) {
      // return "helpdesk-local.clickconnector.com";
      return undefined;
    }
    return new URL(
      organization?.data.website! || onboardingState?.organizationWebsite!,
    ).hostname.replace("www.", "");
  }, [onboardingState?.organizationWebsite, organization?.data.website]);

  const [selectedEmailAddress, setSelectedEmailAddress] = useState("");

  const senderPart = useMemo(() => {
    return (
      connection?.data as iEmailV2ConnectionData
    )?.defaultSendingEmail.split("@")[0];
  }, [connection?.data]);

  const [selectedSenderPart, setSelectedSenderPart] = useState(senderPart);

  useEffect(() => {
    setSelectedSenderPart(senderPart);
  }, [senderPart]);

  const { doAction: validateDomain, isProcessing: isValidatingDomain } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (val) =>
          SDK.sendingDomains.verifyDomainAvailability(val),
        // successMessage: "Primary Inbox has been created",
        failureMessage: "Something went wrong",
        throwError: true,
      }),
      [],
    );

  const emailSuggestions = useMemo(() => {
    if (!organizationDomain) {
      return [];
    }
    return [
      {
        senderName: `Support Team @ ${organization?.data.name}`,
        email: `support@${organizationDomain}`,
      },
      {
        senderName: `Team @ ${organization?.data.name}`,
        email: `team@${organizationDomain}`,
      },
      {
        senderName: `Team @ ${organization?.data.name}`,
        email: `hello@${organizationDomain}`,
      },
    ];
  }, [organization?.data.name, organizationDomain]);

  const onEmailSelected = useCallback(
    async (record: { email: string; senderName: string }) => {
      try {
        const domain = record.email.split("@")[1];
        if (!record.email.includes("@emails.clickconnector.com")) {
          await validateDomain(domain);
        }
        setSelectedEmailAddress(record.email);
        //   Add Custom Domain: Basically got to change the connection senderEmail and the domain record will be added by ConnectionSendingDomainConfig
        await editConnection({
          data: {
            defaultSendingEmail: record.email,
          },
        });
      } catch (e) {
        // Ignore
      }
    },
    [editConnection, validateDomain],
  );

  const sendingEmailAddressDomain = useMemo(() => {
    return (
      connection?.data as iEmailV2ConnectionData
    )?.defaultSendingEmail.split("@")[1];
  }, [connection?.data]);

  const onSenderEmailChange = useCallback(async () => {
    const email = `${selectedSenderPart}@${sendingEmailAddressDomain}`;
    if (!isEmail(email)) {
      message.error("Invalid Email Address");
      return;
    }
    await editConnection({
      data: {
        defaultSendingEmail: email,
      },
    });
  }, [editConnection, selectedSenderPart, sendingEmailAddressDomain]);

  return (
    <div>
      {/* Domain Not Setup */}
      {(
        connection?.data as iEmailV2ConnectionData
      )?.defaultSendingEmail.includes("@emails.clickconnector.com") && (
        <>
          <div className="mb-4">
            <Spin
              spinning={isValidatingDomain || isProcessing}
              indicator={<LoadingIndicatorWithSpin />}
            >
              <Space direction="vertical" className="w-full">
                {emailSuggestions.map((ele) => (
                  <div
                    key={ele.email}
                    className="border border-gray-200 dark:border-gray-700 rounded-lg p-4 w-full cursor-pointer hover:bg-gray-100 hover:dark:bg-gray-900 group flex flex-row"
                    onClick={(e) => {
                      onEmailSelected(ele);
                    }}
                  >
                    <div className="flex-1">
                      <div className="font-bold">{ele.email}</div>
                      <div className="text-gray-600 font-normal">
                        {ele.senderName}
                      </div>
                    </div>
                    <div className="hidden group-hover:show animate-slide-right-fade">
                      <i className="ri-arrow-right-line text-xl"></i>
                    </div>
                  </div>
                ))}
                <Divider>Or</Divider>

                <div className="mb-4">
                  <Space.Compact style={{ width: "100%" }}>
                    <Input
                      placeholder="Your custom email ops@abc.com"
                      value={selectedEmailAddress}
                      onChange={(e) => setSelectedEmailAddress(e.target.value)}
                    />
                    <Button
                      type="primary"
                      onClick={(e) => {
                        onEmailSelected({
                          email: selectedEmailAddress,
                          senderName: "",
                        });
                      }}
                    >
                      Continue
                    </Button>
                  </Space.Compact>
                </div>
              </Space>
            </Spin>
          </div>
        </>
      )}
      {/* Domain Setup */}
      {!(
        connection?.data as iEmailV2ConnectionData
      )?.defaultSendingEmail.includes("@emails.clickconnector.com") && (
        <>
          <div className="mb-4">
            The sending domain configured for this connection is{" "}
            {sendingEmailAddressDomain}, which cannot be changed after the
            channel setup. Although you can modify the sender part of your email
            address, it is not advisable to do so as this could lead to
            confusion among your team and customers.
          </div>
          {/* Sending Email Change */}
          <Spin
            spinning={isValidatingDomain || isProcessing}
            indicator={<LoadingIndicatorWithSpin />}
          >
            <div className="mb-4">
              <Space.Compact style={{ width: "100%" }}>
                <Input
                  placeholder="eg: support, hello"
                  value={selectedSenderPart}
                  onChange={(e) => setSelectedSenderPart(e.target.value)}
                  suffix={`@${sendingEmailAddressDomain}`}
                  size="large"
                />
                <Button
                  type="primary"
                  onClick={(e) => {
                    onSenderEmailChange();
                  }}
                  loading={isProcessing}
                  disabled={selectedSenderPart === senderPart}
                >
                  Save
                </Button>
              </Space.Compact>
            </div>
          </Spin>
          {/* Sending Domain Setup  */}
          <div className="my-8">
            <ConnectionSendingDomainConfig connectionId={connectionId} />
          </div>
        </>
      )}
    </div>
  );
};
