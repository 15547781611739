import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ClickUpTaskBrowserModal = React.lazy(
  () => import("./clickup-task-browser.modal"),
);

const ClickUpTaskBrowser: iModalProps<
  typeof ClickUpTaskBrowserModal,
  {
    onSelected?: (pageId: string) => any;
  }
> = {
  name: "click-up-task-browser",
  component: GenerateModalWithSuspense(ClickUpTaskBrowserModal),
};

export default ClickUpTaskBrowser;
