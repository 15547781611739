import { axios, AxiosResponse } from "@sdk/axios";
import { iMessage } from "@sdk/conversations/conversations.models";
import { UserTracker } from "user-tracker";
import { iSendMessageReq } from "./help-desk.models";

export class HelpDeskService {
  constructor(protected config: { basePath: string; token: string }) {}

  async sendMessage(req: { conversationId: string } & iSendMessageReq) {
    UserTracker.track("CONVERSATIONS - Send message");
    const results: AxiosResponse<iMessage> = await axios.post(
      this.config.basePath +
        `/conversations/${req.conversationId}/send-message`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }
}
