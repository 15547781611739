import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ZenDeskCreateTicketModal = React.lazy(
  () => import("./zen-desk-create-ticket.modal"),
);

const ZenDeskCreateTicket: iModalProps<
  typeof ZenDeskCreateTicketModal,
  {
    onAdded?: (pageId: string) => any;
    zenDeskCustomerId?: string;
    conversationId?: string;
  }
> = {
  name: "zen-desk-create-ticket",
  component: GenerateModalWithSuspense(ZenDeskCreateTicketModal),
};

export default ZenDeskCreateTicket;
