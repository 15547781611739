import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddMessagePresetModal = React.lazy(
  () => import("./add-message-preset.modal"),
);

const AddMessagePreset: iModalProps<typeof AddMessagePresetModal, {}> = {
  name: "add-message-preset",
  component: GenerateModalWithSuspense(AddMessagePresetModal),
};

export default AddMessagePreset;
