import { EventEmitter } from "events";
import { isMobile } from "utils/is-mobile";

export class LocalNotificationsService {
  static get hasPermission() {
    if (typeof Notification === "undefined") {
      return false;
    }
    return Notification.permission === "granted";
  }

  static permissionEvents = new EventEmitter();

  static onPermissionChange() {
    this.permissionEvents.emit("PERMISSION_CHANGE", this.hasPermission);
  }

  static notifications: Notification[] = [];

  static requestPermissions() {
    if (typeof Notification === "undefined") {
      console.log("Notification is not supported");
    } else {
      console.log("Request Permission");
      // Disable notifications for mobile
      // if (isMobile.iOS()) {
      //   return;
      // }
      if (!Notification.requestPermission) {
        return;
      }
      // The user needs to allow this
      Notification.requestPermission()
        .then((permission) => {
          console.log("Notification Permission Request result", permission);
          LocalNotificationsService.onPermissionChange();
        })
        .catch((e) => {
          console.log("Error while requesting permission");
          LocalNotificationsService.onPermissionChange();
        });
    }
  }

  static displayNotification({
    title,
    body,
    tag,
    data,
    callback,
    icon,
  }: {
    title: string;
    body: string;
    tag: string;
    data: any;
    callback: () => any;
    icon?: string;
  }) {
    if (typeof Notification === "undefined") {
      console.log("Notification is not supported");
      return;
    }

    if (Notification.permission === "granted") {
      // Disable notifications for mobile
      if (isMobile.any()) {
        return;
      }
      this.dismiss({ tag, data });

      // Todo: To support mobiles, got to use service workers
      try {
        const notification = new Notification(title, {
          body: body,
          icon: icon || "/assets/img/notifications/notification-message.png",
          tag: tag,
          data: data,
        });
        notification.onclick = () => {
          window.focus();
          callback();
          this.dismiss({ tag, data });
        };
        this.notifications.push(notification);
        return notification;
      } catch (e) {
        // Todo: Failed to construct 'Notification': Illegal constructor. Use ServiceWorkerRegistration.showNotification() instead.
        return null;
      }
    } else {
      console.log("Notifications Permission Issue", Notification.permission);
    }
  }

  static dismiss({
    all,
    index,
    tag,
    data,
    notification,
  }: {
    all?: boolean;
    index?: number;
    tag?: string;
    data?: any;
    notification?: Notification;
  }) {
    if (all) {
      for (const notificationRef of this.notifications) {
        notificationRef.onclose = null;
        notificationRef.close();
      }
      this.notifications = [];
    } else if (tag && data) {
      for (let i = 0; i < this.notifications.length; i++) {
        if (
          this.notifications[i].tag === tag &&
          this.notifications[i].data === data
        ) {
          this.notifications[i].onclose = null;
          this.notifications[i].close();
          this.notifications.splice(i, 1);
          break;
        }
      }
    } else if (notification) {
      notification.close();
      const notificationIndex = this.notifications.indexOf(notification);
      if (notificationIndex > -1) {
        this.notifications.splice(notificationIndex, 1);
      }
    } else {
      if (typeof index === "number" && this.notifications[index]) {
        this.notifications[index].close();
        this.notifications.splice(index, 1);
      }
    }
  }
}
