import { Select } from "antd";
import _ from "lodash";
import { useEffect } from "react";
import { loadAllSelfServicePortals } from "store/modules/self-service-portals/self-service-portals.helpers";
import { selectAllSelfServicePortals } from "store/modules/self-service-portals/self-service-portals.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const SelfServicePortalSelector = ({
  value,
  onChange,
  autoSelectFirstOption,
  allowClear,
  autoFocus,
}: {
  value?: string;
  onChange?: (assistantId: string) => any;
  autoSelectFirstOption?: boolean;
  allowClear?: boolean;
  autoFocus?: boolean;
}) => {
  const { state: selfServicePortals, retry: reload } = useQueryWithStore(
    selectAllSelfServicePortals,
    loadAllSelfServicePortals,
  );

  useEffect(() => {
    // * Todo: Could properly rewrite this
    if (autoSelectFirstOption) {
      if (value) {
        if (!_.find(selfServicePortals, { id: value })) {
          if (selfServicePortals.length > 0) {
            onChange && onChange(selfServicePortals[0].id!);
          }
          onChange && onChange(undefined!);
        }
      } else {
        if (selfServicePortals.length > 0) {
          onChange && onChange(selfServicePortals[0].id!);
        }
      }
    } else {
      if (value) {
        if (!_.find(selfServicePortals, { id: value })) {
          onChange && onChange(undefined!);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSelectFirstOption, selfServicePortals]);

  return (
    <Select
      showSearch
      value={value}
      placeholder="Select Portal"
      filterOption={false}
      onChange={onChange}
      style={{ width: "100%" }}
      allowClear={allowClear}
      defaultOpen={autoFocus}
      autoFocus={autoFocus}
    >
      {(selfServicePortals || []).map((form) => (
        <Select.Option key={form.id} value={form.id!}>
          <div className="flex flex-row">
            <div className="icon-container mr-2">
              <i className="ri-pages-line"></i>
            </div>
            <div className="label">{form.label}</div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};
