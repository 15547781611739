import { useSDK } from "@sdk";
import { SocketConnector } from "@sdk/@libs/socket-connector";
import { iWAPrivateConnectionData } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { WAState } from "@sdk/whatsapp-private/whatsapp-private.service";
import { Button, Space } from "antd";
import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import React, { Suspense, useEffect } from "react";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { useQueryWithStore } from "store/store.hooks";

const QRCode = React.lazy(() => import("react-qr-code"));

export const ConfigureTwitterConnection = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId
  );

  const {
    data: status,
    reload: reloadStatus,
    isLoading: isStatusLoading,
  } = useSDK(
    (SDK) => SDK.whatsAppPrivateX.getConnectionStatus(connectionId),
    [connectionId],
    !connectionId
  );

  const {
    doAction: syncConversations,
    isProcessing: isSyncingConversations,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () =>
        SDK.whatsAppPrivateX.syncRecentConversations(connectionId),
      successMessage: "Conversations has been synced",
      failureMessage: "Something went wrong",
    }),
    [connectionId]
  );

  const {
    doAction: rebootInstance,
    isProcessing: isRebooting,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () => SDK.whatsAppPrivateX.rebootInstance(connectionId),
      successMessage: "Instance is rebooting",
      failureMessage: "Something went wrong",
    }),
    [connectionId]
  );

  const { doAction: logout, isProcessing: isLoggingOut } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () =>
        SDK.whatsAppPrivateX.logout(connectionId).then(() => {
          return reloadStatus();
        }),
      successMessage: "Successfully logged out",
      failureMessage: "Something went wrong",
    }),
    [connectionId, reloadStatus]
  );

  const {
    doAction: sendTakeOverSignal,
    isProcessing: isTakingOver,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () => SDK.whatsAppPrivateX.takeOverSession(connectionId),
      successMessage: "Took over the session",
      failureMessage: "Something went wrong",
    }),
    [connectionId]
  );

  useEffect(() => {
    const listener = (data: {
      name: string;
      data: { connectionId: string };
    }) => {
      console.log("Received Special Socket Event", data);
      if (
        data.name === "WHATSAPP_CONNECTION_STATUS" &&
        data.data.connectionId === connectionId
      ) {
        console.log("Reload Status");
        reloadStatus();
      }
    };
    if (SocketConnector.socket) {
      SocketConnector.socket.on("SPECIAL_EVENTS", listener);
    }

    return () => {
      if (SocketConnector.socket) {
        SocketConnector.socket.off("SPECIAL_EVENTS", listener);
      }
    };
  }, [connectionId, reloadStatus]);

  const connectionData = connection.data as iWAPrivateConnectionData;
  return (
    <div>
      <div className="action-buttons my-4 flex flex-row justify-end items-center">
        <Space>
          <Button
            icon={<i className="ri-refresh-line"></i>}
            type="link"
            onClick={syncConversations}
            loading={isSyncingConversations}
            // disabled
          >
            Sync Conversations
          </Button>
          <Button
            icon={<i className="ri-restart-line"></i>}
            type="link"
            onClick={rebootInstance}
            loading={isRebooting}
          >
            Reboot Instance
          </Button>
          <Button
            icon={<i className="ri-logout-box-line"></i>}
            type="link"
            onClick={logout}
            loading={isLoggingOut}
          >
            Logout
          </Button>
        </Space>
      </div>
      <div className="data-table w-full p-4">
        <table className="simple-data-table w-full">
          <tbody>
            <tr>
              <td className="text-gray-700 dark:text-gray-200  mode_transition">
                Status
              </td>
              <td className="font-bold">
                <div className="flex flex-row items-center">
                  {status?.state}{" "}
                  <Button
                    type="link"
                    icon={<i className="ri-refresh-line"></i>}
                    loading={isStatusLoading}
                    onClick={reloadStatus}
                  >
                    Refresh Status
                  </Button>
                </div>
              </td>
            </tr>
            {!isStatusLoading && status?.state !== WAState.CONNECTED && (
              <tr>
                <td className="text-gray-700 dark:text-gray-200  mode_transition">
                  Authentication QR Code
                </td>

                <td className="font-bold">
                  {status?.lastQrCode ? (
                    <Suspense fallback={<SuspenseLoadingIndicator />}>
                      <QRCode value={status?.lastQrCode} size={300} />
                    </Suspense>
                  ) : (
                    <span>Not Available</span>
                  )}
                </td>
              </tr>
            )}

            {connectionData?.waConnectionX && (
              <>
                {/* <tr>
                  <td className="text-gray-700 dark:text-gray-200 mode_transition">
                    Name
                  </td>
                  <td className="font-bold">
                    {(connectionData?.waConnectionX as WAPAGetStatusRes)}
                  </td>
                </tr>
                <tr>
                  <td className="text-gray-700 dark:text-gray-200 mode_transition">
                    WA ID
                  </td>
                  <td className="font-bold">
                    {connectionData?.waConnection.id}
                  </td>
                </tr>
                <tr>
                  <td className="text-gray-700 dark:text-gray-200 mode_transition">
                    Device
                  </td>
                  <td className="font-bold">
                    {connectionData?.waConnection.device?.model}{" "}
                    {connectionData?.waConnection.device?.manufacturer}{" "}
                    {connectionData?.waConnection.device?.os_version}
                  </td>
                </tr> */}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
