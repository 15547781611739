import { axios } from "@sdk/axios";
import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import { AxiosResponse } from "axios";
import { UserTracker } from "user-tracker";
import {
  iGPTResponse,
  iMagicAssistant,
  iMagicAssistProcessCustomerMessageReq,
} from "./magic-assistants-model";

export class MagicAssistantServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.magicAssistants.configure(config);
  }

  magicAssistants = Object.assign(
    new EntityServices<iMagicAssistant>(SDKConfig, "magic-assistants", {
      onCreate: () => {
        UserTracker.track("MAGIC_ASSISTANT - Create", {});
      },
      onDelete: () => {
        UserTracker.track("MAGIC_ASSISTANT - Delete", {});
      },
    }),
    {
      processCustomerMessage: async (
        data: iMagicAssistProcessCustomerMessageReq,
      ) => {
        UserTracker.track("MAGIC_ASSISTANT - Process Customer Message", {});
        const results: AxiosResponse<iGPTResponse> = await axios.post(
          this.config.basePath + `/ai-assistant/process-customer-message`,
          data,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
