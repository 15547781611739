import { Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { iPricingPlan } from "./pricing-plan-model";
import { PricingPlansChanger } from "./pricing-plans-changer";

export const UpdateSubscriptionModal = ({
  reloadStripeData,
  visible,
  onChangeVisibility,
  setActivatePaymentModalVisibility,
}: {
  reloadStripeData: () => any;
  visible: boolean;
  onChangeVisibility: (status: boolean) => any;
  setActivatePaymentModalVisibility: (plan: iPricingPlan) => any;
}) => {
  return (
    <Modal
      title={
        <ModalTitle
          title={"Update Subscription"}
          icon={<i className="ri-bank-card-line"></i>}
        />
      }
      open={visible}
      footer={null}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      data-click-context="Activate Subscriptions Modal"
    >
      <div className="flex flex-col w-full">
        <PricingPlansChanger
          reloadStripeData={reloadStripeData}
          setActivatePaymentModalVisibility={setActivatePaymentModalVisibility}
        />
      </div>
      <DarkModeBg />
    </Modal>
  );
};
