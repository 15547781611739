import { axios, AxiosResponse } from "@sdk/axios";
import {
  PaginatedOptions,
  PaginatedResults,
} from "@sdk/utils/paginated-results";
import { DeepPartial } from "redux";
import { UserTracker } from "user-tracker";
import { IGMediaCommentFilters } from "./ig-media.controller-models";
import { iIgMedia, iIgMediaComment } from "./instagram-models";

// tslint:disable-next-line:max-line-length

export class IgMediasService {
  constructor(protected config: { basePath: string; token: string }) {}

  instagram = {
    queryIgMedias: async (req: { query: any; options: PaginatedOptions }) => {
      const results: AxiosResponse<PaginatedResults<iIgMedia>> =
        await axios.post(this.config.basePath + `/ig-media/query`, req, {
          headers: { Authorization: this.config.token },
        });
      return results.data;
    },
    getIgMedia: async (mediaId: string) => {
      const results: AxiosResponse<iIgMedia> = await axios.get(
        this.config.basePath + `/ig-media/${mediaId}`,
        {
          headers: { Authorization: this.config.token },
        },
      );
      return results.data;
    },
    getIgMediaComments: async (
      {
        mediaId,
        filter,
        searchQuery,
      }: {
        mediaId: string;
        filter: IGMediaCommentFilters;
        searchQuery?: string;
      },
      { limit = 100, offset = 0 }: PaginatedOptions,
    ) => {
      // Todo: Need to add limit and page parameters
      const results: AxiosResponse<PaginatedResults<iIgMediaComment>> =
        await axios.get(
          this.config.basePath + `/ig-media/${mediaId}/comments`,
          {
            headers: { Authorization: this.config.token },
            params: {
              limit,
              offset,
              filter,
              searchQuery,
            },
          },
        );
      return results.data;
    },
    editIgMedia: async (mediaId: string, edits: DeepPartial<iIgMedia>) => {
      const results: AxiosResponse<iIgMedia> = await axios.patch(
        this.config.basePath + `/ig-media/${mediaId}`,
        edits,
        {
          headers: { Authorization: this.config.token },
        },
      );
      return results.data;
    },
    deleteIgMedia: async (mediaId: string) => {
      UserTracker.track("IG_MEDIA - Delete Media");
      const results: AxiosResponse<{ status: boolean }> = await axios.delete(
        this.config.basePath + `/ig-media/${mediaId}`,
        {
          headers: { Authorization: this.config.token },
        },
      );
      return results.data;
    },
    syncAllMedia: async (connectionId: string, forceRefresh?: boolean) => {
      UserTracker.track("IG_MEDIA - Sync All Media");
      const results: AxiosResponse<boolean> = await axios.post(
        this.config.basePath + `/ig-media/sync`,
        { connectionId, forceRefresh },
        {
          headers: { Authorization: this.config.token },
        },
      );
      return results.data;
    },
    /*----------  Media Actions  ----------*/
    reloadMedia: async (id: string) => {
      UserTracker.track("IG_MEDIA - Reload Media");
      const results: AxiosResponse<{
        post: iIgMedia;
        comments: iIgMediaComment[];
      }> = await axios.post(
        this.config.basePath + `/ig-media/${id}/reload`,
        {},
        {
          headers: { Authorization: this.config.token },
        },
      );
      return results.data;
    },
    reactToMedia: async (
      mediaId: string,
      req: { reaction: string; status: boolean },
    ) => {
      UserTracker.track("IG_MEDIA - React to Media");
      //* Not Implemented
      const results: AxiosResponse<iIgMedia> = await axios.post(
        this.config.basePath + `/ig-media/${mediaId}/react`,
        req,
        {
          headers: { Authorization: this.config.token },
        },
      );
      return results.data;
    },
    createComment: async (
      mediaId: string,
      comment: string,
      attachmentUrl?: string,
    ) => {
      UserTracker.track("IG_MEDIA - Create a Comment");
      const results: AxiosResponse<iIgMediaComment> = await axios.post(
        this.config.basePath + `/ig-media/${mediaId}/comments`,
        { comment, attachmentUrl },
        {
          headers: { Authorization: this.config.token },
        },
      );
      return results.data;
    },
    /*----------  Media Comment Actions  ----------*/

    reactToComment: async (
      mediaId: string,
      commentId: string,
      req: { reaction: string; status: boolean },
    ) => {
      UserTracker.track("IG_MEDIA - React To Comment");
      //! Not Implemented
      const results: AxiosResponse<iIgMediaComment> = await axios.post(
        this.config.basePath +
          `/ig-media/${mediaId}/comments/${commentId}/react`,
        req,
        {
          headers: { Authorization: this.config.token },
        },
      );
      return results.data;
    },

    deleteComment: async (mediaId: string, commentId: string) => {
      UserTracker.track("IG_MEDIA - Delete a Comment");
      //! Not Implemented
      const results: AxiosResponse<{ success: boolean }> = await axios.delete(
        this.config.basePath + `/ig-media/${mediaId}/comments/${commentId}`,
        {
          headers: { Authorization: this.config.token },
        },
      );
      return results.data;
    },
    hideComment: async (mediaId: string, commentId: string) => {
      UserTracker.track("IG_MEDIA - Hide a Comment");
      const results: AxiosResponse<iIgMediaComment> = await axios.patch(
        this.config.basePath +
          `/ig-media/${mediaId}/comments/${commentId}/hide`,
        {},
        {
          headers: { Authorization: this.config.token },
        },
      );
      return results.data;
    },
    unHideComment: async (mediaId: string, commentId: string) => {
      UserTracker.track("IG_MEDIA - Unhide a Comment");
      const results: AxiosResponse<iIgMediaComment> = await axios.patch(
        this.config.basePath +
          `/ig-media/${mediaId}/comments/${commentId}/hide`,
        { unHide: true },
        {
          headers: { Authorization: this.config.token },
        },
      );
      return results.data;
    },
    replyToComment: async (
      mediaId: string,
      commentId: string,
      comment: string,
      attachmentUrl?: string,
    ) => {
      UserTracker.track("IG_MEDIA - Reply to a Comment");
      const results: AxiosResponse<iIgMediaComment> = await axios.post(
        this.config.basePath +
          `/ig-media/${mediaId}/comments/${commentId}/reply`,
        { comment, attachmentUrl },
        {
          headers: { Authorization: this.config.token },
        },
      );
      return results.data;
    },

    markAsResolved: async (id: string, commentId: string, status: boolean) => {
      UserTracker.track("IG_MEDIA - Mark comment as resolved");
      const results: AxiosResponse<iIgMediaComment> = await axios.post(
        this.config.basePath + `/ig-media/${id}/comments/${commentId}/resolve`,
        { status },
        {
          headers: { Authorization: this.config.token },
        },
      );
      return results.data;
    },
    markAsAllResolved: async (id: string, status: boolean) => {
      UserTracker.track("IG_MEDIA - Mark all comments as resolved");
      const results: AxiosResponse<boolean> = await axios.post(
        this.config.basePath + `/ig-media/${id}/resolve-all`,
        { status },
        {
          headers: { Authorization: this.config.token },
        },
      );
      return results.data;
    },
    privateReply: async (
      mediaId: string,
      commentId: string,
      reply: string,
      parentCommentId?: string,
    ) => {
      UserTracker.track("IG_MEDIA - Private Reply");
      const results: AxiosResponse<{ status: boolean }> = await axios.post(
        this.config.basePath +
          `/ig-media/${mediaId}/comments/${commentId}/private-reply`,
        { reply, parentCommentId },
        {
          headers: { Authorization: this.config.token },
        },
      );
      return results.data;
    },
    assignUserToComment: async (
      mediaId: string,
      commentId: string,
      userId: string,
      status: boolean,
    ) => {
      UserTracker.track("IG_MEDIA - Assign User to Comment");
      const results: AxiosResponse<iIgMediaComment> = await axios.post(
        this.config.basePath +
          `/ig-media/${mediaId}/comments/${commentId}/assign`,
        { userId, status },
        {
          headers: { Authorization: this.config.token },
        },
      );
      return results.data;
    },
  };
}
