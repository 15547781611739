import { axios, AxiosResponse } from "@sdk/axios";
import {
  iOrganization,
  iUser,
} from "@sdk/user-management/user-management.models";
import { UserTracker } from "user-tracker";
import { iShopifyFulfillment } from "./shopify-fulfilment-model";
import { iShopifyProduct } from "./shopify-product-model";
import {
  iShopifyCalculateRefundRequest,
  iShopifyRefundCalculation,
} from "./shopify-refund-calculate-modells";
import { iShopifyRefund, iShopifyRefundRequest } from "./shopify-refund-models";

export class ShopifyServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  shopify = {
    getShopData: async (shop: string) => {
      const results: AxiosResponse<{
        isRegistered: boolean;
        shop: string;
        domain: string;
      }> = await axios.get(this.config.basePath + "/shopify/shop", {
        headers: {
          Authorization: this.config.token,
        },
        params: {
          shop,
        },
      });
      return results.data;
    },
    getAuthUrl: async (shop: string) => {
      const results: AxiosResponse<{ url: string }> = await axios.post(
        this.config.basePath + "/shopify/gut-auth-url",
        { shop },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    completeShopifyIntegration: async (req: { shop: string }) => {
      UserTracker.track("SHOPIFY - Complete Integration", {});
      const results: AxiosResponse<{ success: boolean }> = await axios.post(
        this.config.basePath + "/shopify/complete-integration",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    startRegistrationWithShopify: async (req: {
      code: string;
      shop: string;
    }) => {
      UserTracker.track("SHOPIFY - Start Registration", {});
      const results: AxiosResponse<{
        shop: string;
        user: {
          name: string;
          email: string;
        };
        existingDomain?: string;
      }> = await axios.post(
        this.config.basePath + "/shopify/start-registration-with-shopify",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    completeRegistrationWithShopify: async (req: {
      shop: string;
      password: string;
    }) => {
      UserTracker.track("Register", { source: "SHOPIFY" });
      const results: AxiosResponse<{
        accessToken: string;
        organization: iOrganization;
        user: iUser;
      }> = await axios.post(
        this.config.basePath + "/shopify/complete-registration-with-shopify",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    activateSubscription: async (req: {
      planId: string;
      planName: string;
      planPrice: number;
    }) => {
      UserTracker.track("SHOPIFY - Activate Subscription", {});
      const results: AxiosResponse<{
        name: string;
        status: string;
        confirmation_url: string;
      }> = await axios.post(
        this.config.basePath + "/shopify/activate-subscription",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    deActivateSubscription: async () => {
      UserTracker.track("SHOPIFY - Deactivate Subscription", {});
      const results: AxiosResponse<{
        name: string;
        status: string;
        confirmation_url: string;
      }> = await axios.post(
        this.config.basePath + "/shopify/de-activate-subscription",
        {},
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    refreshBillingStatus: async () => {
      const results: AxiosResponse<{
        status: boolean;
      }> = await axios.post(
        this.config.basePath + "/shopify/refresh-billing-status",
        {},
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    resolveShopifyCustomer: async (email?: string, phoneNumber?: string) => {
      const results: AxiosResponse<iShopifyCustomer> = await axios.post(
        this.config.basePath + "/shopify/resolve-customer",
        { email, phoneNumber },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getOrderById: async (orderId: string) => {
      const results: AxiosResponse<iShopifyOrder> = await axios.post(
        this.config.basePath + "/shopify/get-order-by-id",
        { orderId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getCustomerOrders: async (customerId: string | number) => {
      const results: AxiosResponse<iShopifyOrder[]> = await axios.post(
        this.config.basePath + "/shopify/get-customer-orders",
        { customerId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getAllProducts: async () => {
      const results: AxiosResponse<iShopifyProduct[]> = await axios.post(
        this.config.basePath + "/shopify/get-all-products",
        {},
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getProductById: async (productId: string | number) => {
      const results: AxiosResponse<iShopifyProduct> = await axios.post(
        this.config.basePath + "/shopify/get-product-by-id",
        { productId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    calculateARefund: async (
      orderId: string | number,
      refund: iShopifyCalculateRefundRequest,
    ) => {
      const results: AxiosResponse<iShopifyRefundCalculation> =
        await axios.post(
          this.config.basePath + "/shopify/calculate-refund",
          { orderId, refund },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
    refundAnOrder: async (
      orderId: string | number,
      refund: iShopifyRefundRequest,
    ) => {
      UserTracker.track("SHOPIFY - Refund", {});
      const results: AxiosResponse<iShopifyRefund> = await axios.post(
        this.config.basePath + "/shopify/refund",
        { orderId, refund },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    closeAnOrder: async (orderId: string | number) => {
      UserTracker.track("SHOPIFY - Close Order", {});
      const results: AxiosResponse<iShopifyOrder> = await axios.post(
        this.config.basePath + "/shopify/close-order",
        { orderId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    reopenAnOrder: async (orderId: string | number) => {
      UserTracker.track("SHOPIFY - ReOpen Order", {});
      const results: AxiosResponse<iShopifyOrder> = await axios.post(
        this.config.basePath + "/shopify/re-open-order",
        { orderId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    cancelAnOrder: async (
      orderId: string | number,
      req: iCancelOrderRequest,
    ) => {
      UserTracker.track("SHOPIFY - Cancel Order", {});
      const results: AxiosResponse<iShopifyOrder[]> = await axios.post(
        this.config.basePath + "/shopify/cancel-order",
        { orderId, req },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    editAnOrder: async (
      orderId: string | number,
      edits: iShopifyEditOrderRequest,
    ) => {
      UserTracker.track("SHOPIFY - Edit Order", {});
      const results: AxiosResponse<iShopifyOrder> = await axios.post(
        this.config.basePath + "/shopify/edit-order",
        { orderId, edits },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    deleteAnOrder: async (orderId: string | number) => {
      UserTracker.track("SHOPIFY - Delete Order", {});
      const results: AxiosResponse<any> = await axios.post(
        this.config.basePath + "/shopify/delete-order",
        { orderId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    createAnOrder: async (order: iShopifyEditOrderRequest) => {
      UserTracker.track("SHOPIFY - Create Order", {});
      const results: AxiosResponse<iShopifyOrder> = await axios.post(
        this.config.basePath + "/shopify/create-order",
        order,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
  };
}

export interface iShopifyCustomer {
  id: number;
  email: string;
  accepts_marketing: boolean;
  created_at: string;
  updated_at: string;
  first_name: string;
  last_name: string;
  orders_count: number;
  state: string;
  total_spent: string;
  last_order_id: number;
  note: string;
  verified_email: boolean;
  multipass_identifier?: any;
  tax_exempt: boolean;
  tags: string;
  last_order_name: string;
  currency: string;
  phone?: any;
  addresses: Address[];
  accepts_marketing_updated_at: string;
  marketing_opt_in_level?: any;
  tax_exemptions: any[];
  email_marketing_consent: {
    state: string;
    opt_in_level: string;
    consent_updated_at?: any;
  };
  sms_marketing_consent?: {
    state: string;
    opt_in_level: string;
    consent_updated_at?: any;
    consent_collected_from: string;
  };
  admin_graphql_api_id: string;
  default_address: Address;
}

interface Address {
  id: number;
  customer_id: number;
  first_name: string;
  last_name: string;
  company: string;
  address1: string;
  address2?: any;
  city: string;
  province?: any;
  country: string;
  zip: string;
  phone?: any;
  name: string;
  province_code?: any;
  country_code: string;
  country_name: string;
  default: boolean;
}

export interface iShopifyOrder {
  id: number;
  admin_graphql_api_id: string;
  app_id: number;
  browser_ip?: any;
  buyer_accepts_marketing: boolean;
  cancel_reason?: any;
  cancelled_at?: any;
  cart_token?: any;
  checkout_id: number;
  checkout_token: string;
  client_details: Clientdetails;
  closed_at?: any;
  company?: any;
  confirmed: boolean;
  contact_email: string;
  created_at: string;
  currency: string;
  current_subtotal_price: string;
  current_subtotal_price_set: Currentsubtotalpriceset;
  current_total_discounts: string;
  current_total_discounts_set: Currentsubtotalpriceset;
  current_total_duties_set?: any;
  current_total_price: string;
  current_total_price_set: Currentsubtotalpriceset;
  current_total_tax: string;
  current_total_tax_set: Currentsubtotalpriceset;
  customer_locale?: any;
  device_id?: any;
  discount_codes: any[];
  email: string;
  estimated_taxes: boolean;
  financial_status: string;
  fulfillment_status?: any;
  gateway: string;
  landing_site?: any;
  landing_site_ref?: any;
  location_id: number;
  merchant_of_record_app_id?: any;
  name: string;
  note?: any;
  note_attributes: any[];
  number: number;
  order_number: number;
  order_status_url: string;
  original_total_duties_set?: any;
  payment_gateway_names: string[];
  phone?: any;
  presentment_currency: string;
  processed_at: string;
  processing_method: string;
  reference?: any;
  referring_site?: any;
  source_identifier?: any;
  source_name: string;
  source_url?: any;
  subtotal_price: string;
  subtotal_price_set: Currentsubtotalpriceset;
  tags: string;
  tax_lines: any[];
  taxes_included: boolean;
  test: boolean;
  token: string;
  total_discounts: string;
  total_discounts_set: Currentsubtotalpriceset;
  total_line_items_price: string;
  total_line_items_price_set: Currentsubtotalpriceset;
  total_outstanding: string;
  total_price: string;
  total_price_set: Currentsubtotalpriceset;
  total_shipping_price_set: Currentsubtotalpriceset;
  total_tax: string;
  total_tax_set: Currentsubtotalpriceset;
  total_tip_received: string;
  total_weight: number;
  updated_at: string;
  user_id: number;
  billing_address?: any;
  customer: Customer;
  discount_applications: any[];
  fulfillments: iShopifyFulfillment[];
  line_items: Lineitem[];
  payment_details?: any;
  payment_terms?: any;
  refunds: iShopifyRefund[];
  shipping_address?: any;
  shipping_lines: any[];
}

interface Lineitem {
  id: number;
  admin_graphql_api_id: string;
  fulfillable_quantity: number;
  fulfillment_service: string;
  fulfillment_status?: any;
  gift_card: boolean;
  grams: number;
  name: string;
  price: string;
  price_set: Currentsubtotalpriceset;
  product_exists: boolean;
  product_id: number;
  properties: any[];
  quantity: number;
  requires_shipping: boolean;
  sku: string;
  taxable: boolean;
  title: string;
  total_discount: string;
  total_discount_set: Currentsubtotalpriceset;
  variant_id: number;
  variant_inventory_management: string;
  variant_title: string;
  vendor: string;
  tax_lines: any[];
  duties: any[];
  discount_allocations: any[];
}

interface Customer {
  id: number;
  email: string;
  accepts_marketing: boolean;
  created_at: string;
  updated_at: string;
  first_name: string;
  last_name: string;
  state: string;
  note: string;
  verified_email: boolean;
  multipass_identifier?: any;
  tax_exempt: boolean;
  tags: string;
  currency: string;
  phone?: any;
  accepts_marketing_updated_at: string;
  marketing_opt_in_level?: any;
  tax_exemptions: any[];
  email_marketing_consent: Emailmarketingconsent;
  sms_marketing_consent?: any;
  admin_graphql_api_id: string;
}

interface Emailmarketingconsent {
  state: string;
  opt_in_level: string;
  consent_updated_at?: any;
}

interface Currentsubtotalpriceset {
  shop_money: Shopmoney;
  presentment_money: Shopmoney;
}

interface Shopmoney {
  amount: string;
  currency_code: string;
}

interface Clientdetails {
  accept_language?: any;
  browser_height?: any;
  browser_ip?: any;
  browser_width?: any;
  session_hash?: any;
  user_agent?: any;
}

export interface iCancelOrderRequest {
  amount?: string;
  currency?: string;
  email?: boolean;
  restock?: boolean;
  reason?: "customer" | "inventory" | "fraud" | "declined" | "other";
}

export interface iShopifyEditOrderRequest {
  id: string;
  metafields?: {
    key: string;
    value: string;
    type: "single_line_text_field";
    namespace: "global";
  }[];
  buyer_accepts_marketing?: boolean;
  email?: string;
  phone?: string;
  note?: string;
  tags?: string;
  shipping_address_attributes?: any;
}
