import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { iCallLog } from "@sdk/call-logs/call-logs-model";
import { iTelnyxConnectionData } from "@sdk/conversations/conversations.models";
import { TelnyxRTCContext } from "@telnyx/react-client";
import { Dropdown, Menu, Tag, Tooltip } from "antd";
import { Avatar, AvatarGroup } from "components/common/avatar/avatar";
import { ContactAvatar } from "components/common/avatar/client-avatar";
import { UserAvatar } from "components/common/avatar/user-avatar";
import { getContactName } from "components/modules/crm/contacts/helpers/get-contact-name";
import ContactProfile from "components/pages/contacts/components/contact-profile/contact-profile-v2.modal-registry";
import dayjs from "dayjs";

import { useCallback, useContext, useMemo } from "react";
import { loadAllCallLogs } from "store/modules/call-logs/call-logs.helpers";
import { selectAllCallLogs } from "store/modules/call-logs/call-logs.selectors";
import { loadAllConnections } from "store/modules/connections/connections.helpers";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { loadContactById } from "store/modules/contacts/contacts.helpers";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { convertMillisecondsToReadableString } from "utils/convert-milliseconds-to-readable-string";

export const TelnyxPhoneLogs = ({
  onSwitchToDialer,
}: {
  onSwitchToDialer: () => any;
}) => {
  const {
    state: callLogs,
    retry: reload,
    isLoading,
  } = useQueryWithStore(selectAllCallLogs, loadAllCallLogs(), []);
  const { state: connections } = useQueryWithStore(
    selectAllConnections,
    loadAllConnections,
  );

  const client = useContext(TelnyxRTCContext);

  const telephoneConnection = useMemo(() => {
    return connections.find((item) => item.type === "TELNYX");
  }, [connections]);
  const telephonyData = telephoneConnection?.data as iTelnyxConnectionData;

  const callNumber = useCallback(
    (number: string) => {
      const call = client?.newCall({
        // Destination is required and can be a phone number or SIP URI
        destinationNumber: number,
        callerNumber: telephonyData?.phoneNumber,
      });
      onSwitchToDialer();
    },
    [client, onSwitchToDialer, telephonyData?.phoneNumber],
  );

  return (
    <div
      className="logs-container overflow-auto w-full flex-1"
      style={{ height: 430 }}
    >
      {callLogs.map((log, index) => (
        <CallLogItem key={log.id} log={log} callNumber={callNumber} />
      ))}
    </div>
  );
};

export const CallLogItem = ({
  log,
  callNumber,
}: {
  log: iCallLog;
  callNumber: (number: string) => any;
}) => {
  const { state: contact } = useQueryWithStore(
    selectContactById(log.contactId!),
    loadContactById(log.contactId!)(),
    [log.contactId],
    !log.contactId,
  );

  const { changePanelState } = useModalPanels();

  return (
    <Dropdown
      overlay={
        <Menu style={{ minWidth: "10" }} data-click-context="Call Log Menu">
          <Menu.Item
            title=""
            key="CALL_NOW"
            onClick={() => {
              callNumber(log.direction === "incoming" ? log.from : log.to);
            }}
          >
            <span className="">
              <i className="ri-phone-line"></i> Call Number
            </span>
          </Menu.Item>
          {log.contactId && (
            <Menu.Item
              title=""
              key="CALL_NOW"
              onClick={() => {
                changePanelState(ContactProfile, true, {
                  contactId: log.contactId!,
                });
              }}
            >
              <span className="">
                <i className="ri-user-line"></i> View Contact
              </span>
            </Menu.Item>
          )}
        </Menu>
      }
      trigger={["click"]}
    >
      <div className="flex flex-row justify-start items-center p-4 hover:bg-gray-100 border-b border-gray-200 dark:border-gray-700 rounded-lg cursor-pointer">
        <div className="flex flex-row justify-start items-center w-full">
          {log.contactId ? (
            <ContactAvatar contactId={log.contactId} size={40} />
          ) : (
            <Avatar name="Unknown" size={40} />
          )}
          <div className="right flex flex-col ml-2 flex-1">
            <div className=" flex flex-row justify-between items-center">
              <div className="name">
                {getContactName(contact) || "Unknown Contact"}
              </div>
              <div className="number text-gray-600 text-sm">
                {log.state === "ENDED" && (
                  <>{convertMillisecondsToReadableString(log.duration)}</>
                )}
                {log.state !== "ENDED" && <Tag>{log.state}</Tag>}
              </div>
            </div>

            <div className="second-line flex flex-row justify-between items-center">
              <div className="flex flex-row">
                {log.direction === "incoming" && (
                  <Tooltip title="Incoming Call">
                    <div className="text-blue-600">
                      <i className="ri-phone-fill pr-0 transform rotate-45"></i>
                      <i className="ri-arrow-left-line -ml-1"></i>
                    </div>
                  </Tooltip>
                )}
                {log.direction === "outgoing" && (
                  <Tooltip title="Outgoing Call">
                    <div className="text-green-600">
                      <i className="ri-phone-fill pr-0 transform rotate-45"></i>
                      <i className="ri-arrow-right-line -ml-1"></i>
                    </div>
                  </Tooltip>
                )}
                {log.direction === "incoming" &&
                  log.state === "ENDED" &&
                  log.members.length === 0 && (
                    <Tooltip title="Missed Call">
                      <div className="text-red-600">
                        <i className="ri-phone-fill pr-0 transform rotate-45"></i>
                        <i className="ri-arrow-down-line"></i>
                      </div>
                    </Tooltip>
                  )}
                <div className="number text-gray-600">
                  {dayjs(log.initiatedAt).format("MMM Do, h:mm:ss a")}
                </div>
              </div>

              <AvatarGroup>
                {log.members.map((member) => (
                  <div className="avatar-container" key={member.userId}>
                    <UserAvatar
                      userId={member.userId}
                      key={member.userId}
                      enableToolTip
                      size={20}
                    />
                  </div>
                ))}
                {log.routingLogs
                  .filter((item) => item.status === "ACTIVE")
                  .map((member) => (
                    <div
                      className="avatar-container opacity-50"
                      key={member.userId}
                    >
                      <UserAvatar
                        userId={member.userId}
                        key={member.userId}
                        enableToolTip
                        size={20}
                        tooltipTemplate={(name) => `Routing to ${name}`}
                      />
                    </div>
                  ))}
              </AvatarGroup>
            </div>
            <div className="third-line text-gray-600">
              {log.direction === "incoming" ? log.from : log.to}
            </div>
          </div>
        </div>
      </div>
    </Dropdown>
  );
};
