import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iHolidayQuote } from "@sdk/holiday-quotes/holiday-quotes-model";
import React from "react";

const QuoteHeaderEditorModal = React.lazy(
  () => import("./quoter-header-editor.modal"),
);

const QuoteHeaderEditor: iModalProps<
  typeof QuoteHeaderEditorModal,
  {
    onSave: (content: iHolidayQuote) => any;
    data: iHolidayQuote;
  }
> = {
  name: "quote-header-editor",
  component: GenerateModalWithSuspense(QuoteHeaderEditorModal),
};

export default QuoteHeaderEditor;
