import { createSlice, DeepPartial, PayloadAction } from "@reduxjs/toolkit";
import { iUser } from "@sdk/user-management/user-management.models";
import { deduceListChangesOnNewEntity } from "store/utils/new-entity-analyzer";
import { QueryAliasResolver } from "store/utils/query-alias-resolver";
import { DeepAssign } from "utils/deep-assign";
import { setQueryListState } from "../../utils/list-query";
import { usersDefaultQueryConfig } from "./users-default-query";
import { iUsersSlice } from "./users.model";

const resolveQueryAlias: QueryAliasResolver = (query: string) => {
  if (query === "all") {
    return {
      ...usersDefaultQueryConfig,
      alias: "all"
    };
  }
};

const UsersSlice = createSlice({
  name: "users",
  initialState: {
    accessToken: "",
    byIds: {},
    queries: {}
  } as iUsersSlice,
  reducers: {
    setAllUsers(state, action: PayloadAction<iUser[]>) {
      setQueryListState(state, "all", action.payload);
    },
    setUser(state, action: PayloadAction<iUser>) {
      state.byIds[action.payload.id] = action.payload;
    },
    patchUser(state, action: PayloadAction<DeepPartial<iUser>>) {
      DeepAssign(state.byIds[action.payload.id!], action.payload);
    },
    addNewUser(state, action: PayloadAction<iUser>) {
      state.byIds[action.payload.id] = action.payload;

      const entity = action.payload;
      const { listToAddTo } = deduceListChangesOnNewEntity(
        entity,
        state,
        resolveQueryAlias
      );
      for (const list of listToAddTo) {
        state.queries[list].list.push(action.payload.id);
      }
    },
    setUsersQueryError(state, action: PayloadAction<string>) {
      state.queries[action.payload] = {
        lastFetched: Date.now(),
        isLoading: false,
        hasError: true,
        list: state.queries[action.payload]
          ? state.queries[action.payload].list || []
          : []
      };
    },
    setAccessToken(state, action: PayloadAction<string>) {
      state.accessToken = action.payload;
    },
    setCurrentUser(state, action: PayloadAction<iUser>) {
      state.currentUser = action.payload.id!;
      state.byIds[action.payload.id!] = action.payload;
    },
    logout(state) {
      state.accessToken = "";
    }
  }
});

export const UsersSliceReducers = UsersSlice.reducer;

export const UsersSliceActions = UsersSlice.actions;
