import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const CustomizeExperienceModal = React.lazy(
  () => import("./customize-experience.modal"),
);

const CustomizeExperience: iModalProps<typeof CustomizeExperienceModal> = {
  name: "customize-experience",
  component: GenerateModalWithSuspense(CustomizeExperienceModal),
};

export default CustomizeExperience;
