import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import { UserTracker } from "user-tracker";
import { iReminder } from "./reminder-model";

export class RemindersServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.reminders.configure(config);
  }

  reminders = Object.assign(
    new EntityServices<iReminder>(SDKConfig, "reminders", {
      onCreate: () => {
        UserTracker.track("REMINDERS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("REMINDERS - Delete", {});
      },
    }),
    {},
  );
}
