import { axios, AxiosResponse } from "@sdk/axios";
import { UserTracker } from "user-tracker";
import { InstanceStatus } from "./whatsapp-private.controller-models";

export class WhatsappPrivateServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  whatsAppPrivate = {
    syncRecentConversations: async (connectionId: string) => {
      UserTracker.track("WA_PRIVATE - Sync Recent Conversations", {});
      const results: AxiosResponse<{
        message: string;
      }> = await axios.post(
        this.config.basePath + "/whatsapp-private/sync-conversations",
        { connectionId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    syncConversation: async (conversationId: string) => {
      UserTracker.track("WA_PRIVATE - Sync Conversation", {});
      const results: AxiosResponse<{
        message: string;
      }> = await axios.post(
        this.config.basePath + "/whatsapp-private/sync-conversation",
        { conversationId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getConnectionStatus: async (connectionId: string) => {
      const results: AxiosResponse<InstanceStatus> = await axios.get(
        this.config.basePath + "/whatsapp-private/status",
        {
          headers: {
            Authorization: this.config.token,
          },
          params: { connectionId },
        },
      );
      return results.data;
    },
    logout: async (connectionId: string) => {
      UserTracker.track("WA_PRIVATE - Logout", {});
      const results: AxiosResponse<InstanceStatus> = await axios.post(
        this.config.basePath + "/whatsapp-private/logout",
        { connectionId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    rebootInstance: async (connectionId: string) => {
      UserTracker.track("WA_PRIVATE - Reboot Instance", {});
      const results: AxiosResponse<InstanceStatus> = await axios.post(
        this.config.basePath + "/whatsapp-private/reboot-instance",
        { connectionId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getQRCode: async (connectionId: string) => {
      const results: AxiosResponse<InstanceStatus> = await axios.get(
        this.config.basePath + "/whatsapp-private/qr-code",
        {
          headers: {
            Authorization: this.config.token,
          },
          params: { connectionId },
        },
      );
      return results.data;
    },
    takeOverSession: async (connectionId: string) => {
      UserTracker.track("WA_PRIVATE - Take over session", {});
      const results: AxiosResponse<InstanceStatus> = await axios.post(
        this.config.basePath + "/whatsapp-private/take-over-session",
        { connectionId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    // Manual Sync
    syncSession: async (connectionId: string) => {
      UserTracker.track("WA_PRIVATE - Sync Session", {});
      const results: AxiosResponse<InstanceStatus> = await axios.post(
        this.config.basePath + "/whatsapp-private/sync-session",
        { connectionId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
  };

  whatsAppPrivateX = {
    syncRecentConversations: async (connectionId: string) => {
      UserTracker.track("WA_PRIVATE - Sync Recent Conversations", {});
      const results: AxiosResponse<{
        message: string;
      }> = await axios.post(
        this.config.basePath + "/whatsapp-private-x/sync-conversations",
        { connectionId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    syncConversation: async (conversationId: string) => {
      UserTracker.track("WA_PRIVATE - Sync Conversation", {});
      const results: AxiosResponse<{
        message: string;
      }> = await axios.post(
        this.config.basePath + "/whatsapp-private-x/sync-conversation",
        { conversationId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getConnectionStatus: async (connectionId: string) => {
      const results: AxiosResponse<WAPAGetStatusRes> = await axios.get(
        this.config.basePath + "/whatsapp-private-x/status",
        {
          headers: {
            Authorization: this.config.token,
          },
          params: { connectionId },
        },
      );
      return results.data;
    },
    logout: async (connectionId: string) => {
      UserTracker.track("WA_PRIVATE - Logout", {});
      const results: AxiosResponse<InstanceStatus> = await axios.post(
        this.config.basePath + "/whatsapp-private-x/logout",
        { connectionId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    rebootInstance: async (connectionId: string) => {
      UserTracker.track("WA_PRIVATE - Reboot Instance", {});
      const results: AxiosResponse<InstanceStatus> = await axios.post(
        this.config.basePath + "/whatsapp-private-x/reboot-instance",
        { connectionId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    resetInstance: async (connectionId: string) => {
      UserTracker.track("WA_PRIVATE - Reset Instance", {});
      const results: AxiosResponse<InstanceStatus> = await axios.post(
        this.config.basePath + "/whatsapp-private-x/reset-instance",
        { connectionId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    removeInstance: async (connectionId: string) => {
      UserTracker.track("WA_PRIVATE - Remove Instance", {});
      const results: AxiosResponse<InstanceStatus> = await axios.post(
        this.config.basePath + "/whatsapp-private-x/remove-instance",
        { connectionId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getQRCode: async (connectionId: string) => {
      const results: AxiosResponse<InstanceStatus> = await axios.get(
        this.config.basePath + "/whatsapp-private-x/qr-code",
        {
          headers: {
            Authorization: this.config.token,
          },
          params: { connectionId },
        },
      );
      return results.data;
    },
    getScreenshot: async (connectionId: string) => {
      const results: AxiosResponse<any> = await axios.get(
        this.config.basePath + "/whatsapp-private-x/screenshot",
        {
          headers: {
            Authorization: this.config.token,
          },
          params: { connectionId },
        },
      );
      return results.data;
    },
    takeOverSession: async (connectionId: string) => {
      UserTracker.track("WA_PRIVATE - Take over session", {});
      const results: AxiosResponse<InstanceStatus> = await axios.post(
        this.config.basePath + "/whatsapp-private-x/take-over-session",
        { connectionId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    // Manual Sync
    syncSession: async (connectionId: string) => {
      UserTracker.track("WA_PRIVATE - Sync Session", {});
      const results: AxiosResponse<InstanceStatus> = await axios.post(
        this.config.basePath + "/whatsapp-private-x/sync-session",
        { connectionId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    dismissConnectionError: async (connectionId: string) => {
      UserTracker.track("WA_PRIVATE - Dismiss Connection Error", {});
      const results: AxiosResponse<InstanceStatus> = await axios.post(
        this.config.basePath + "/whatsapp-private-x/dismiss-connection-error",
        { connectionId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
  };
}

export interface WAPAGetStatusRes {
  state: WAState | undefined;
  lastQrCode: string;
  info: {
    pushname: string;
    wid: {
      server: string;
      user: string;
      _serialized: string;
    };
    me: {
      server: string;
      user: string;
      _serialized: string;
    };
    platform: string;
    phone: {
      wa_version: string;
      os_version: string;
      device_manufacturer: string;
      device_model: string;
      os_build_number: string;
    };
  };
}

export enum WAState {
  CONFLICT = "CONFLICT",
  CONNECTED = "CONNECTED",
  DEPRECATED_VERSION = "DEPRECATED_VERSION",
  OPENING = "OPENING",
  PAIRING = "PAIRING",
  PROXYBLOCK = "PROXYBLOCK",
  SMB_TOS_BLOCK = "SMB_TOS_BLOCK",
  TIMEOUT = "TIMEOUT",
  TOS_BLOCK = "TOS_BLOCK",
  UNLAUNCHED = "UNLAUNCHED",
  UNPAIRED = "UNPAIRED",
  UNPAIRED_IDLE = "UNPAIRED_IDLE",
}
