import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ConversationViewerModal = React.lazy(
  () => import("./conversation-viewer.modal"),
);

const ConversationViewer: iModalProps<
  typeof ConversationViewerModal,
  { conversationId: string }
> = {
  name: "conversation-viewer",
  component: GenerateModalWithSuspense(ConversationViewerModal),
};

export default ConversationViewer;
