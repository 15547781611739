export const slaOptions = [
  {
    value: 60 * 1000,
    label: "within 1 Minute",
    disableForClose: true,
  },
  {
    value: 2 * 60 * 1000,
    label: "within 2 Minutes",
    disableForClose: true,
  },
  {
    value: 5 * 60 * 1000,
    label: "within 5 Minutes",
    disableForClose: true,
  },
  {
    value: 10 * 60 * 1000,
    label: "within 10 Minutes",
    reminderOptions: [
      {
        value: 1 * 60 * 1000,
        label: "before 1 minute",
      },
      {
        value: 2 * 60 * 1000,
        label: "before 2 minutes",
      },
    ],
  },
  {
    value: 30 * 60 * 1000,
    label: "within 30 Minutes",
    reminderOptions: [
      {
        value: 1 * 60 * 1000,
        label: "before 1 minute",
      },
      {
        value: 2 * 60 * 1000,
        label: "before 2 minutes",
      },
      {
        value: 5 * 60 * 1000,
        label: "before 5 minutes",
      },
      {
        value: 10 * 60 * 1000,
        label: "before 10 minutes",
      },
    ],
  },
  {
    value: 60 * 60 * 1000,
    label: "within 1 hour",
    reminderOptions: [
      {
        value: 5 * 60 * 1000,
        label: "before 5 minutes",
      },
      {
        value: 10 * 60 * 1000,
        label: "before 10 minutes",
      },
    ],
  },
  {
    value: 2 * 60 * 60 * 1000,
    label: "within 2 hours",
    reminderOptions: [
      {
        value: 5 * 60 * 1000,
        label: "before 5 minutes",
      },
      {
        value: 10 * 60 * 1000,
        label: "before 10 minutes",
      },
    ],
  },
  {
    value: 6 * 60 * 60 * 1000,
    label: "within 6 hours",
    reminderOptions: [
      {
        value: 10 * 60 * 1000,
        label: "before 10 minutes",
      },
      {
        value: 30 * 60 * 1000,
        label: "before 30 minutes",
      },
      {
        value: 60 * 60 * 1000,
        label: "before 1 hour",
      },
    ],
  },
  {
    value: 12 * 60 * 60 * 1000,
    label: "within 12 hours",
    reminderOptions: [
      {
        value: 30 * 60 * 1000,
        label: "before 30 minutes",
      },
      {
        value: 60 * 60 * 1000,
        label: "before 1 hour",
      },
      {
        value: 2 * 60 * 60 * 1000,
        label: "before 2 hours",
      },
    ],
  },
  {
    value: 24 * 60 * 60 * 1000,
    label: "within 24 hours",
    reminderOptions: [
      {
        value: 60 * 60 * 1000,
        label: "before 1 hour",
      },
      {
        value: 2 * 60 * 60 * 1000,
        label: "before 2 hours",
      },
    ],
  },
  {
    value: 2 * 24 * 60 * 60 * 1000,
    label: "within 2 days",
    reminderOptions: [
      {
        value: 60 * 60 * 1000,
        label: "before 1 hour",
      },
      {
        value: 2 * 60 * 60 * 1000,
        label: "before 2 hours",
      },
    ],
  },
  {
    value: 3 * 24 * 60 * 60 * 1000,
    label: "within 3 days",
    reminderOptions: [
      {
        value: 2 * 60 * 60 * 1000,
        label: "before 2 hours",
      },
      {
        value: 6 * 60 * 60 * 1000,
        label: "before 6 hours",
      },
    ],
    disableForFirstResponse: true,
  },
  {
    value: 5 * 24 * 60 * 60 * 1000,
    label: "within 5 days",
    reminderOptions: [
      {
        value: 6 * 60 * 60 * 1000,
        label: "before 6 hours",
      },
      {
        value: 1 * 24 * 60 * 60 * 1000,
        label: "before 1 day",
      },
    ],
    disableForFirstResponse: true,
  },
  {
    value: 7 * 24 * 60 * 60 * 1000,
    label: "within 7 days",
    reminderOptions: [
      {
        value: 6 * 60 * 60 * 1000,
        label: "before 6 hours",
      },
      {
        value: 1 * 24 * 60 * 60 * 1000,
        label: "before 1 day",
      },
      {
        value: 2 * 24 * 60 * 60 * 1000,
        label: "before 2 days",
      },
    ],
    disableForFirstResponse: true,
  },
  {
    value: 14 * 24 * 60 * 60 * 1000,
    label: "within 14 days",
    reminderOptions: [
      {
        value: 1 * 24 * 60 * 60 * 1000,
        label: "before 1 day",
      },
      {
        value: 2 * 24 * 60 * 60 * 1000,
        label: "before 2 days",
      },
    ],
    disableForFirstResponse: true,
  },
  {
    value: 28 * 24 * 60 * 60 * 1000,
    label: "within 28 days",
    reminderOptions: [
      {
        value: 1 * 24 * 60 * 60 * 1000,
        label: "before 1 day",
      },
      {
        value: 2 * 24 * 60 * 60 * 1000,
        label: "before 2 days",
      },
      {
        value: 3 * 24 * 60 * 60 * 1000,
        label: "before 3 days",
      },
    ],
    disableForFirstResponse: true,
  },
];
