import classNames from "classnames";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { EditText, EditTextarea, inputTextType } from "react-edit-text";
import "./editable-text.scss";

export const EditableText = forwardRef(
  (
    {
      value,
      onSave,
      isProcessing,
      className,
      placeholder,
      type,
      formatDisplayText,
    }: {
      value?: string;
      onSave?: (text: string) => any;
      isProcessing?: boolean;
      className?: string;
      placeholder?: string;
      type?: inputTextType;
      formatDisplayText?: (value: string) => string;
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
      setInputValue(value);
    }, [value, setInputValue]);

    useImperativeHandle(ref, () => ({
      setInputValue(text: string) {
        setInputValue(text);
      },
    }));

    return (
      <EditText
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        onSave={({ value }) => {
          onSave && onSave(value);
        }}
        className={classNames("editable-text", className)}
        inputClassName={classNames("editable-text", className)}
        placeholder={placeholder}
        type={type}
        formatDisplayText={formatDisplayText}
      />
    );
  },
);

export const EditableTextArea = ({
  value,
  onSave,
  isProcessing,
  className,
  placeholder,
  rows,
}: {
  value?: string;
  onSave?: (text: string) => any;
  isProcessing?: boolean;
  className?: string;
  placeholder?: string;
  rows?: number;
}) => {
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    setInputValue(value);
  }, [value, setInputValue]);
  return (
    <EditTextarea
      value={inputValue}
      onChange={(e) => {
        setInputValue(e.target.value);
      }}
      onSave={({ value }) => {
        onSave && onSave(value);
      }}
      className={classNames("editable-text", className)}
      inputClassName={classNames("editable-text", className)}
      placeholder={placeholder}
      rows={rows}
    />
  );
};
