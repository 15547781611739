export const SectionHeader = ({
  title,
  icon,
  description,
  illustration,
  illustrationImage,
  addons,
}: {
  title: string | JSX.Element;
  icon: JSX.Element;
  description?: string;
  illustration?: JSX.Element;
  illustrationImage?: string;
  addons?: JSX.Element;
}) => {
  return (
    <div className="header flex flex-col md:flex-row gap-4 md:gap-0 md:justify-between md:items-end">
      <div className="flex flex-row">
        <div className="header flex flex-row justify-center items-center">
          {icon && <div className="icon text-4xl mr-2">{icon && icon}</div>}
          <div className="flex flex-col">
            <div className="font-bold text-xl flex flex-row items-center md:text-2xl">
              {title}
            </div>
            <div className="text-gray-700 dark:text-gray-200 text-sm flex-1 pr-10 mode_transition">
              {description}
            </div>
          </div>
        </div>
      </div>

      {addons}
      {illustration}
      {illustrationImage && (
        <img
          src={illustrationImage}
          // height={400}
          style={{ transform: "scale(1.5)", height: "4em" }}
          alt=""
        />
      )}
    </div>
  );
};
