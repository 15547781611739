export const WidgetHomeArticles = () => {
  return (
    <div
      className=" p-4 m-4 mt-0 animate fade-in-right  bg-white "
      style={{
        borderRadius: 10,
        boxShadow:
          "rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 2px 8px",
      }}
    >
      <div className="search-bar flex flex-row items-center justify-between cursor-pointer px-6 bg-gray-200 hover:bg-gray-300 rounded-md">
        <input
          placeholder="Search Articles"
          className="flex-1 border-b border-solid border-gray-200 pb-2 pt-2 cursor-pointer  outline-none bg-gray-200 hover:bg-gray-300 font-bold"
        />
        <div
          className="icon-container mr-2"
          style={{ width: "1.3rem", height: "1.3rem" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="1.3rem"
            height="1.3rem"
            className="icon-svg"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
          </svg>
        </div>
      </div>
      <div className="faq-list-container mt-4">
        <div className="flex flex-row pointer hover:bg-gray-100 p-2 cursor-pointer justify-between items-center rounded-lg">
          <div className="title truncate">How to setup SDK?</div>
          <div
            className="icon-container"
            style={{ width: "1.1rem", height: "1.1rem" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="1.1rem"
              height="1.1rem"
              className="icon-svg"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
            </svg>
          </div>
        </div>
        <div className="flex flex-row pointer hover:bg-gray-100 p-2 cursor-pointer justify-between items-center rounded-lg">
          <div className="title truncate">How to Integrate with Zapier?</div>
          <div
            className="icon-container"
            style={{ width: "1.1rem", height: "1.1rem" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="1.1rem"
              height="1.1rem"
              className="icon-svg"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
            </svg>
          </div>
        </div>
        <div className="flex flex-row pointer hover:bg-gray-100 p-2 cursor-pointer justify-between items-center rounded-lg">
          <div className="title truncate">Common Billing Concerns</div>
          <div
            className="icon-container"
            style={{ width: "1.1rem", height: "1.1rem" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="1.1rem"
              height="1.1rem"
              className="icon-svg"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
            </svg>
          </div>
        </div>
      </div>
      <div className="browse-all-button flex flex-row justify-end items-center p-4 cursor-pointer hover-text-primary">
        Browse All{" "}
        <div
          className="icon-container"
          style={{ width: "1.1rem", height: "1.1rem" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="1.1rem"
            height="1.1rem"
            className="icon-svg"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
          </svg>
        </div>
      </div>
    </div>
  );
};
