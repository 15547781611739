import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAccountManager } from "store/modules/workspace/workspace.selectors";

export const useAccountManager = () => {
  const accountManager = useSelector(selectAccountManager);

  const data = useMemo(() => {
    if (accountManager === "RUBAN") {
      return {
        fullName: "Ruban Nanthagopal",
        shortName: "Ruban",
        avatar: "/assets/account-managers/ruban.png",
        email: "ruban@sales.clickconnector.com",
        phoneNumber: "+13022615327 | +44 20 3097 1730",
        chatId: ""
      };
    }
    return {
      fullName: "Nafees",
      shortName: "Nafees",
      avatar: "/assets/account-managers/nafees.png",
      email: "nafees@sales.clickconnector.com",
      phoneNumber: "+13022615327 | +44 20 3097 1730",
      chatId: ""
    };
  }, [accountManager]);

  const onContactAccountManager = useCallback(() => {
    // TOdo
    (window as any).ccWidget.setWidgetVisibility(true, data.chatId);
  }, [data.chatId]);

  return { ...data, onContactAccountManager };
};
