import { iTask } from "@sdk/tasks/task-model";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectTaskQueryMap,
  selectEntityMap: selectTasksMap,
  selectQueryMapAll: selectTasksMapAll,
  selectQueryAllList: selectTasksAllList,
  selectAllEntities: selectAllTasks,
  selectAllEntitiesQuery: selectAllTasksQuery,
  selectEntityById: selectTaskById,
  selectEntityQuery: selectTaskQuery,
  selectEntityQueryList: selectTaskQueryList
} = CreateSelectorForEntities<iStore, iTask>({
  sliceName: "tasks"
});

export const selectTaskByConnectionId = (connectionId: string) => (
  state: iStore
) => {
  return _.find(selectAllTasks(state), {
    connectionId
  });
};
