import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddWACloudNativeConnectionModal = React.lazy(
  () => import("./add-whatsapp-cloud-native-connection.modal"),
);

const AddWACloudNativeConnection: iModalProps<
  typeof AddWACloudNativeConnectionModal
> = {
  name: "add-wa-cloud-native-connection",
  component: GenerateModalWithSuspense(AddWACloudNativeConnectionModal),
};

export default AddWACloudNativeConnection;
