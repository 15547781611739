import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iAutomationWorkflowStep } from "@sdk/automation-workflows/automation-workflows-model";
import React from "react";

const SequenceDelayEditorModal = React.lazy(
  () => import("./delay-editor.modal"),
);

const SequenceDelayEditor: iModalProps<
  typeof SequenceDelayEditorModal,
  {
    step?: iAutomationWorkflowStep;
    onSave: (step: iAutomationWorkflowStep) => any;
  }
> = {
  name: "sequence-delay-editor",
  component: GenerateModalWithSuspense(SequenceDelayEditorModal),
};

export default SequenceDelayEditor;
