import { iConversation } from "@sdk/conversations/conversations.models";
import { iContact } from "@sdk/crm/crm.models";
import { iUser } from "@sdk/user-management/user-management.models";
import { getContactName } from "components/modules/crm/contacts/helpers/get-contact-name";
import { getUserName } from "components/modules/user-management/helpers/get-user-name";
import { Store } from "redux";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { DatabasePropertyConfigResponse } from "./notion-models";
export const ConvertNotionBlocksToPlainText = (blocks: NotionBlock[]) => {
  return blocks.map((item) => item.text.content).join(" ");
};

export const getMappableNotionDBProperties = (
  propertiesObj: Record<string, DatabasePropertyConfigResponse>,
) => {
  const properties = Object.values(propertiesObj);
  const filteredProperties = properties.filter((property) =>
    ["title", "rich_text", "url"].includes(property.type),
  );
  return filteredProperties;
};

export const getMappableNotionDBPropertiesInForm = (
  propertiesObj: Record<string, DatabasePropertyConfigResponse>,
) => {
  const properties = Object.values(propertiesObj);
  const filteredProperties = properties.filter((property) =>
    ["title", "rich_text", "url", "number", "select", "checkbox"].includes(
      property.type,
    ),
  );
  return filteredProperties;
};

export const PropertiesToMap = [
  {
    value: "CONTACT_ID",
    label: "Customer Id",
  },
  {
    value: "CONTACT_FIRST_NAME",
    label: "Customer's First Name",
  },
  {
    value: "CONTACT_LAST_NAME",
    label: "Customer's Last Name",
  },
  {
    value: "CONTACT_NAME",
    label: "Customer's Name",
  },
  {
    value: "CONTACT_EMAIL",
    label: "Customer's Email",
  },
  {
    value: "CONTACT_PHONE",
    label: "Customer's Phone Number",
  },
  {
    value: "CONTACT_URL",
    label: "Customer Profile URL",
  },
  {
    value: "USER_NAME",
    label: "Current User's Name",
  },
  {
    value: "USER_EMAIL",
    label: "Current User's Email",
  },
  {
    value: "USER_FIRST_NAME",
    label: "Current User's First Name",
  },
  {
    value: "USER_LAST_NAME",
    label: "Current User's Last Name",
  },
  {
    value: "CONVERSATION_ID",
    label: "Conversation ID",
  },
  {
    value: "CONVERSATION_SUBJECT",
    label: "Conversation Subject",
  },
  {
    value: "CONVERSATION_TAGS",
    label: "Conversation Tags",
  },
  {
    value: "CONVERSATION_MEMBERS",
    label: "Conversation Assigned Users",
  },
  {
    value: "CONVERSATION_URL",
    label: "Conversation URL",
  },
];

export const PropertyMapFunction = {
  CONTACT: {
    ID: (contact: iContact) => contact.id!,
    FIRST_NAME: (contact: iContact) => contact.data.firstName,
    LAST_NAME: (contact: iContact) => contact.data.lastName,
    NAME: getContactName,
    EMAIL: (contact: iContact) => contact.data.primaryEmail,
    PHONE: (contact: iContact) => contact.data.primaryMobile,
    URL: (contact: iContact) => contact.id,
  },
  CONVERSATION: {
    ID: (conversation: iConversation) => conversation.id,
    SUBJECT: (conversation: iConversation) => conversation.subject,
    TAGS: (conversation: iConversation) => conversation.tags.join(", "),
    MEMBERS: (conversation: iConversation) =>
      conversation.members.map((member) => member.userId).join(", "),
    URL: (conversation: iConversation) => conversation.id,
  },
  USER: {
    NAME: (user: iUser) => getUserName(user),
    EMAIL: (user: iUser) => user.credentials.email,
    FIRST_NAME: (user: iUser) => user.data.firstName,
    LAST_NAME: (user: iUser) => user.data.lastName,
  },
};

interface NotionBlock {
  type: string;
  text: {
    content: string;
    link?: any;
  };
  annotations: {
    bold: boolean;
    italic: boolean;
    strikethrough: boolean;
    underline: boolean;
    code: boolean;
    color: string;
  };
  plain_text: string;
  href?: any;
}

export const getNotionMappedColumnValue = ({
  token,
  conversationId,
  contactId,
  store,
}: {
  token: string;
  conversationId: string;
  contactId: string;
  store: Store;
}) => {
  const tokenParts = token.split("_");
  const nameSpace = tokenParts.shift();
  const tokenVariable = tokenParts.join("_");
  if (nameSpace === "CONTACT") {
    const valueFunction = PropertyMapFunction.CONTACT[tokenVariable];
    const conversation = selectConversationById(conversationId)(
      store.getState(),
    );
    const contact = selectContactById(conversation?.contactId)(
      store.getState(),
    );
    if (valueFunction) {
      return valueFunction(contact);
    }
  } else if (nameSpace === "USER") {
    const valueFunction = PropertyMapFunction.USER[tokenVariable];
    const user = selectCurrentUser(store.getState());
    if (valueFunction) {
      return valueFunction(user);
    }
  } else if (nameSpace === "CONVERSATION") {
    const valueFunction = PropertyMapFunction.CONVERSATION[tokenVariable];
    const conversation = selectConversationById(conversationId)(
      store.getState(),
    );
    if (valueFunction) {
      return valueFunction(conversation);
    }
  }
};
