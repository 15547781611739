import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AsanaTaskBrowserModal = React.lazy(
  () => import("./asana-task-browser.modal"),
);

const AsanaTaskBrowser: iModalProps<
  typeof AsanaTaskBrowserModal,
  {
    onSelected?: (pageId: string) => any;
  }
> = {
  name: "asana-task-browser",
  component: GenerateModalWithSuspense(AsanaTaskBrowserModal),
};

export default AsanaTaskBrowser;
