import { SDK } from "@sdk";
import { processServerError } from "@sdk/sdk.hooks";
import { iOrganizationAddOnSubscription } from "@sdk/user-management/user-management.models";
import { Button, Space, Table, Tag, message } from "antd";
import dayjs from "dayjs";
import { AddOnPlans } from "./addon-plans";
import { AddOnLabels } from "./pricing-plans-data";
import { useSubscriptionDetails } from "./use-subscription-details";
export const BillingAddons = ({
  reloadStripeData,
}: {
  reloadStripeData: () => any;
}) => {
  const addOnColumns = [
    {
      title: "Name",
      dataIndex: "addOnId",
      render: (addOnId: string) => <span>{AddOnLabels[addOnId]}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: string) => (
        <span>
          <Tag>Active</Tag>
        </span>
      ),
    },
    {
      title: "Purchased Date",
      dataIndex: "created",
      render: (created: number) => (
        <span>{dayjs(created).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "",
      key: "Cancel",
      render: (invoice_pdf, record: iOrganizationAddOnSubscription) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={async () => {
              await SDK.deactivateAddOn({
                addOnId: record.addOnId,
              })
                .then((d) => {
                  message.info("Add on has been removed");
                  reloadStripeData();
                })
                .catch((e) => {
                  message.error(processServerError(e, "Something went wrong"));
                });
            }}
          >
            Cancel
          </Button>
        </Space>
      ),
    },
  ];

  const { isSubscribed, subscriptionDetails, isYearly, plan } =
    useSubscriptionDetails();

  return (
    <>
      {subscriptionDetails?.billing.addOns &&
        subscriptionDetails?.billing.addOns.length > 0 && (
          <>
            <div className="subtitle text-gray-600 text-xl font-bold dark:text-gray-400 mb-2 mt-8 mode_transition">
              Active Addons
            </div>
            <div className="flex flex-row p-4 w-full">
              <Table
                columns={addOnColumns}
                dataSource={subscriptionDetails?.billing.addOns}
                pagination={false}
                rowKey="id"
                className="w-full"
              />
            </div>
          </>
        )}
      <div className="subtitle text-gray-600 dark:text-gray-400 mb-2 mt-8 mode_transition">
        Available Add ons
      </div>
      <div className="flex flex-row p-4 w-full">
        <AddOnPlans
          onBuyAddOn={async (addOn) => {
            await SDK.activateAddOn({
              addOnId: addOn.addOnId,
            })
              .then((d) => {
                message.info("Addon subscription has been activated");
                reloadStripeData();
              })
              .catch((e) => {
                message.error(processServerError(e, "Something went wrong"));
              });
          }}
        />
      </div>
    </>
  );
};
