import { iChatConnectorType } from "@sdk/chat-connectors/chat-connectors.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Form, Input, message, Modal, Select } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useChatConnectorEntity } from "./use-chat-connector-entity";

export const AddChatConnectorModal = ({
  visible,
  onChangeVisibility,
  onCreated,
  type,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated: () => any;
  type?: iChatConnectorType;
}) => {
  const [form] = Form.useForm();

  const {
    doAction: onAddChatConnector,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values) =>
        SDK.chatConnectors
          .create({
            ...values,
          })
          .then((res) => {
            onCreated();
            onChangeVisibility(false);
            form.resetFields();
          }),
      successMessage: "Chat Connector has been created",
      failureMessage: "Something went wrong",
    }),
    [form, onChangeVisibility, onCreated],
  );

  const { entityName, icon } = useChatConnectorEntity(type);

  return (
    <Modal
      title={<ModalTitle icon={icon} title={`Create New ${entityName}`} />}
      open={visible}
      onOk={async () => {
        try {
          await form.validateFields();
          const formValues = form.getFieldsValue();
          if (type) {
            formValues.type = type;
          }

          if (formValues.type === "CONVERSATION_ACTION") {
            const config = {
              method: `POST`,
            };
            Object.assign(config, {
              url: "https://",
              urlParameters: {},
              queryParaMeters: {},
              body: {},
              headers: {},
            });
            formValues.data = {
              apiCall: config,
              actionLabel: "Call External API",
            };
          } else {
            formValues.data = {
              header: {
                title: "My Awesome Connector",
              },
              body: [],
            };
          }

          onAddChatConnector(formValues);
        } catch (e) {
          message.error("Please check your input");
        }
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Create"
      data-click-context="Create New Connector Modal"
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ type: type || "CONVERSATION_ACTION" }}
      >
        <Form.Item
          name="label"
          label=""
          rules={[
            {
              required: true,
              message: "Please enter a label",
            },
          ]}
        >
          <Input placeholder="Label" size="large" />
        </Form.Item>
        {!type && (
          <Form.Item name="type" label="Type">
            <Select defaultValue="CONVERSATION_ACTION">
              <Select.Option value={"CONTACT_SIDE_BAR"}>
                Conversation Side Bar
              </Select.Option>
              <Select.Option value={"CONVERSATION_ACTION"}>
                Conversation Action
              </Select.Option>
            </Select>
          </Form.Item>
        )}
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
