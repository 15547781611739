import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddChatBotModal = React.lazy(() => import("./add-chat-bot.modal"));

const AddChatBot: iModalProps<typeof AddChatBotModal, {}> = {
  name: "add-chat-bot",
  component: GenerateModalWithSuspense(AddChatBotModal),
};

export default AddChatBot;
