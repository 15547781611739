import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const KnowledgeBaseManageCollectionsModal = React.lazy(
  () => import("./knowledge-base-manage-collection.modal"),
);

const KnowledgeBaseManageCollections: iModalProps<
  typeof KnowledgeBaseManageCollectionsModal,
  {}
> = {
  name: "manage-knowledge-base",
  component: GenerateModalWithSuspense(KnowledgeBaseManageCollectionsModal),
};

export default KnowledgeBaseManageCollections;
