import { createSelector } from "@reduxjs/toolkit";
import { iChatBot } from "@sdk/chat-bots/chat-bots.models";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectChatBotQueryMap,
  selectEntityMap: selectChatBotsMap,
  selectQueryMapAll: selectChatBotsMapAll,
  selectQueryAllList: selectChatBotsAllList,
  selectAllEntities: _selectAllChatBots,
  selectAllEntitiesQuery: selectAllChatBotsQuery,
  selectEntityById: selectChatBotById,
  selectEntityQuery: selectChatBotQuery,
  selectEntityQueryList: selectChatBotQueryList,
} = CreateSelectorForEntities<iStore, iChatBot<any>>({
  sliceName: "chatBots",
});

export const selectChatBotByConnectionId =
  (connectionId: string) => (state: iStore) => {
    return _.find(selectAllChatBots(state), {
      connectionId,
    });
  };

export const selectAllChatBots = createSelector(
  _selectAllChatBots,
  (allChatbots) => {
    return allChatbots.filter((chatBot) => !chatBot.isDeleted);
  },
);
