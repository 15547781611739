import JsonURL from "@jsonurl/jsonurl";
import { filter, uniqBy } from "lodash";
import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import { uuidv4 } from "utils/generate-uuid";
import { useUrlState } from "utils/hooks/use-url-state";
import { iModalPanelRegistryItem, iModalState } from "./modal-panels-model";
import { ModalsStateContext } from "./modal-state-context";

export const useModalPanels = () => {
  const [portalsStateString, setPortalStateString] = useUrlState("portals");
  const { state, setState } = React.useContext(ModalsStateContext);

  const activePortals = useMemo(() => {
    if (!portalsStateString) {
      return [] as iModalState[];
    }
    try {
      // * For some odd reason, + are converted back to spaced. Probably by the useUrlState function
      const _portalsStateString = portalsStateString.replace(" ", "+");
      const value = JsonURL.parse(_portalsStateString || "()", { AQF: true });
      if (Array.isArray(value)) {
        return value as iModalState[];
      }
    } catch (e) {
      console.log("e", e);
      // Nothing
    }

    return [] as iModalState[];
  }, [portalsStateString]);

  const activePortalsRef = useRef({ activePortals });

  useLayoutEffect(() => {
    activePortalsRef.current = { activePortals };
  }, [activePortals]);

  const setPortalsState = useCallback(
    (portals: iModalState[], additionalStageChange?: Record<string, any>) => {
      const string = JsonURL.stringify(portals, {
        AQF: true,
      });
      if (string) {
        return setPortalStateString(string, additionalStageChange);
      }
      return setPortalStateString("", additionalStageChange);
    },
    [setPortalStateString],
  );

  const changePanelStateWithName = useCallback(
    (
      panelName: string,
      visibility: boolean,
      state: Record<string, string | number> = {},
      additionalStageChange?: Record<string, any>,
      hidePanels?: string[],
    ) => {
      const newState = uniqBy(
        [
          ...filter(
            activePortalsRef.current.activePortals,
            (portal: iModalState) => portal.name !== panelName,
          ),
          { name: panelName, visible: visibility, ...state },
        ],
        "name",
      )
        .filter((item) => item.visible)
        .filter((item) => !(hidePanels || []).includes(item.name));
      setPortalsState(newState, additionalStageChange);
    },
    [setPortalsState],
  );

  const changePanelState = useCallback(
    <T extends {}>(
      panel: iModalPanelRegistryItem<T>,
      visibility: boolean,
      state: T,
      additionalStageChange?: Record<string, any>,
      hidePanels?: string[],
    ) => {
      changePanelStateWithName(
        panel.name,
        visibility,
        state,
        additionalStageChange,
        hidePanels,
      );
    },
    [changePanelStateWithName],
  );

  const triggerTempModal = useCallback(
    <T extends {}>(panel: iModalPanelRegistryItem<T>, state: T) => {
      const modalId = uuidv4();
      setState((existingPanels) => [
        ...existingPanels,
        {
          id: modalId,
          name: panel.name,
          ...state,
        },
      ]);
      return () => {
        setState((existingPanels) =>
          filter(existingPanels, (panel) => panel.id !== modalId),
        );
      };
    },
    [setState],
  );

  const triggerTempModalWithName = useCallback(
    <T extends {}>(
      panelName: string,
      state: T,
      additionalStageChange?: Record<string, any>,
    ) => {
      setState((existingPanels) => [
        ...existingPanels,
        {
          id: uuidv4(),
          name: panelName,
          ...state,
        },
      ]);
      setPortalsState(
        filter(
          activePortalsRef.current.activePortals,
          (portal: iModalState) => portal.name !== panelName,
        ),
        additionalStageChange,
      );
    },
    [setPortalsState, setState],
  );

  return {
    changePanelStateWithName,
    changePanelState,
    activePortals,
    triggerTempModal,
    triggerTempModalWithName,
  };
};
