import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { BulkConversationsAction } from "@sdk/conversations/conversations.controller-models";
import React from "react";

const BulkEditConversationsModal = React.lazy(
  () => import("./bulk-edit-conversations.modal"),
);

const BulkEditConversations: iModalProps<
  typeof BulkEditConversationsModal,
  {
    onActionCompleted: () => any;
    action: BulkConversationsAction;
    query: any;
  }
> = {
  name: "bulk-edit-conversations",
  component: GenerateModalWithSuspense(BulkEditConversationsModal),
};

export default BulkEditConversations;
