import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const CalLinkPickerModal = React.lazy(
  () => import("./cal-event-link-picker.modal"),
);

const CalLinkPicker: iModalProps<
  typeof CalLinkPickerModal,
  {
    onLinkSelected?: (content: string) => any;
  }
> = {
  name: "cal-link-picker",
  component: GenerateModalWithSuspense(CalLinkPickerModal),
};

export default CalLinkPicker;
