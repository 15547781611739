import { SDK } from "@sdk";
import { iChatBot } from "@sdk/chat-bots/chat-bots.models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { chatBotsDefaultQueryConfig } from "./chat-bots-default-query";
import { ChatBotsSlice } from "./chat-bots.slice";

export const loadAllChatBots = () => async (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  await loadChatBotsQuery(chatBotsDefaultQueryConfig, "all")(
    store,
    forceReload
  );
};

export const {
  loadEntityQueries: loadChatBotsQuery,
  loadEntityById: loadChatBotById
} = CreateHelpersForEntity<iStore, iChatBot<any>>({
  sliceName: "chatBots",
  slice: ChatBotsSlice,
  isPaginatedQueries: false,
  fetchPageSize: 100,
  loadEntityQueryProvider: query => SDK.chatBots.getAll(),
  loadEntityByIdProvider: (entityId: string) => SDK.chatBots.getById(entityId)
});
