import { UIStateSliceActions } from "./ui-state.slice";

export const {
  setSideBarCollapsed,
  setDarkMode,
  setLastAutoCancelScheduleMessagePreference,
  setAwayDetectorState,
  setEnteredMessages,
  setIsAdvancedMode,
  setFullScreen,
  setIsPinned,
  setWindowSize,
  setWindowState,
  setElectronMode,
} = UIStateSliceActions;
