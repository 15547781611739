import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iNewsFeedItem } from "@sdk/newsfeed-items/newsfeed-model";
import React from "react";

const AddEditNewsfeedItemModal = React.lazy(
  () => import("./add-edit-newsfeed-item.modal"),
);

const AddEditNewsfeedItem: iModalProps<
  typeof AddEditNewsfeedItemModal,
  {
    initialValue?: iNewsFeedItem;
    mode: "ADD" | "EDIT";
    newsfeedTypeId?: string;
    newsfeedItemId?: string;
    onSave?: () => any;
  }
> = {
  name: "add-edit-newsfeed-item",
  component: GenerateModalWithSuspense(AddEditNewsfeedItemModal),
};

export default AddEditNewsfeedItem;
