import { Button } from "antd";

import { NotificationInstance } from "antd/es/notification/interface";
import { useEffect, useMemo } from "react";
import classNames from "utils/classnames";
import { uuidv4 } from "utils/generate-uuid";
import { GlobalEventEmitter } from "utils/global-event-emitter";
import { KeyboardShortcut } from "../keyboard-shortcut/keyboard-shortcut";
import { AvailableAudios } from "./audio-notifications-controller";

const CloseNotificationvbackground = () => {
  document
    .querySelector(".ant-notification")
    ?.classList.remove("alert-notification-container");
};

const NotificationBackgroundDimmer = () => {
  // * once the notification context is set, the the notification component does not get destroyed
  useEffect(() => {
    document
      .querySelector(".ant-notification")
      ?.classList.add("alert-notification-container");
    return () => {
      CloseNotificationvbackground();
    };
  }, []);
  return <></>;
};

export const NotificationActions = ({
  data,
  api,
}: {
  data: iNotification;
  api: NotificationInstance;
}) => {
  const buttons = (data.buttons || []).map((button, index) => ({
    ...button,
    shortcut: index < 2 ? (button.isPrimary ? "Enter" : "Escape") : undefined,
    id: uuidv4(),
  }));

  const kbBindings = useMemo(() => {
    if (data.type === "PROMPT") {
      return buttons.slice(0, 2).map((item) => ({
        id: item.id,
        name: item.text,
        shortcut: [item.isPrimary ? "Enter" : "Escape"],
        keywords: item.text,
        perform: item.action,
      }));
    }
    return [];
  }, [buttons, data.type]);

  useEffect(() => {
    GlobalEventEmitter.emit("SHORT_CUT_REGISTRY_ADD", kbBindings);
    return () => {
      GlobalEventEmitter.emit("SHORT_CUT_REGISTRY_REMOVE", kbBindings);
    };
  }, [kbBindings]);

  return (
    <div
      className={classNames("flex flex-row items-center gap-2 w-full", {
        "justify-around": buttons.length > 1,
        "justify-end": buttons.length === 1,
      })}
    >
      {data.duration === 0 && <NotificationBackgroundDimmer />}
      {buttons.map((item) => (
        <Button
          key={item.id}
          id={item.id}
          type={item.isPrimary ? "primary" : "text"}
          onClick={item.action}
          size="small"
          className="font-semibold p-4 my-2"
        >
          {item.text}{" "}
          {item.shortcut && (
            <KeyboardShortcut label={item.shortcut} inDarkBg={item.isPrimary} />
          )}
        </Button>
      ))}
    </div>
  );
};

export interface iNotification {
  id: string;
  title: string;
  body: string;
  icon: string;
  audio: AvailableAudios;
  tag: string;
  data: {
    type: string;
    conversationId?: string;
    userId?: string;
    sessionId?: string;
  };
  channels: iNotificationChannel[];
  type: iNotificationType;
  isUnDismissible?: boolean;
  duration?: number;
  buttons?: { text: string; action: () => any; isPrimary?: boolean }[];
  onClick: (data?) => any;
  refs: {
    antd?: string;
    browser?: Notification;
  };
}

export type iNotificationChannel = "ONSCREEN" | "BROWSER";

export type iNotificationType = "PROMPT" | "SIMPLE";
