import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import { Button, Collapse, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";

import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import React, { Suspense, useRef, useState } from "react";
import { uuidv4 } from "utils/generate-uuid";
import "./single-editable-faq-collection.scss";
const { Panel } = Collapse;

const DraftJSInput = React.lazy(() =>
  import("components/common/draft-js-input/draft-js-input")
);

const AddFAQ = ({
  collectionId,
  dispatch,
}: {
  collectionId: string;
  dispatch: React.Dispatch<any>;
}) => {
  const [activeKey, setActiveKey] = useState([] as string[]);
  const draftJsRef = useRef<any>();
  const [AddFAQForm] = useForm();
  return (
    <Collapse
      activeKey={activeKey}
      className="p-0 m-2 border-none faq-add"
      style={{ background: "#00000000" }}
    >
      <Panel
        header={
          <div
            className="flex flex-col items-center text-gray-600 dark:text-gray-400 mt-6 mb-2 mode_transition"
            onClick={() => {
              setActiveKey(activeKey.length === 0 ? [`AddFAQ`] : []);
            }}
          >
            <div className="flex items-center font-semibold">
              <PlusCircleOutlined className="text-xl mr-2" /> Add New FAQ
            </div>
          </div>
        }
        key="AddFAQ"
        showArrow={false}
      >
        <Form
          layout="vertical"
          form={AddFAQForm}
          onFinish={() => {
            dispatch({
              type: "ADD_FAQ",
              payload: {
                collectionId: collectionId,
                faq: {
                  id: uuidv4(),
                  question: AddFAQForm.getFieldValue("FAQQuestion"),
                  body: draftJsRef.current.getHtmlContent(),
                },
              },
            });
            setActiveKey([]);
            AddFAQForm.resetFields();
            draftJsRef.current.setHtmlContent("");
          }}
        >
          <Form.Item label="" name="FAQQuestion" rules={[{ required: true }]}>
            <Input placeholder="FAQ title or an article title" />
          </Form.Item>
          <Suspense fallback={<SuspenseLoadingIndicator />}>
            <DraftJSInput initialValue="" ref={draftJsRef} />
          </Suspense>

          <Button type="primary" htmlType="submit">
            Add
          </Button>
          <Button
            type="text"
            onClick={() => {
              setActiveKey([]);
            }}
          >
            Cancel
          </Button>
        </Form>
      </Panel>
    </Collapse>
  );
};
export default AddFAQ;
