import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddEmailV2ConnectionModal = React.lazy(
  () => import("./add-email-v2-connection.modal"),
);

const AddEmailV2Connection: iModalProps<typeof AddEmailV2ConnectionModal> = {
  name: "add-email-v2-connection",
  component: GenerateModalWithSuspense(AddEmailV2ConnectionModal),
};

export default AddEmailV2Connection;
