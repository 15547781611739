import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const MessageVariantEditorModal = React.lazy(
  () => import("./message-variant-editor.modal"),
);

const MessageVariantEditor: iModalProps<
  typeof MessageVariantEditorModal,
  {
    existingVariants: string[];
    onSave: (variants: string[]) => any;
  }
> = {
  name: "message-variant-editor",
  component: GenerateModalWithSuspense(MessageVariantEditorModal),
};

export default MessageVariantEditor;
