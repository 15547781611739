import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import axios, { AxiosResponse } from "axios";
import { UserTracker } from "user-tracker";
import { iScreenTour } from "./screen-tours-model";

export class ScreenToursServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.screenTours.configure(config);
  }

  screenTours = Object.assign(
    new EntityServices<iScreenTour>(SDKConfig, "screen-tours", {
      onCreate: () => {
        UserTracker.track("SCREEN_TOURS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("SCREEN_TOURS - Delete", {});
      },
    }),
    {
      queryReport: async (tourId: string, query: any = {}) => {
        const results: AxiosResponse<{
          activations: number;
          completions: number;
        }> = await axios.post(
          this.config.basePath + `/screen-tours/${tourId}/report`,
          {
            query,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
