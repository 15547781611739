import { UserAvatar } from "components/common/avatar/user-avatar";
import { useSelector } from "react-redux";
import { selectCurrentUserId } from "store/modules/users/users.selectors";

export const CurrentUserAvatar = ({
  size = 14,
  className,
}: {
  size?: number;
  className?: string;
}) => {
  const currentUserId = useSelector(selectCurrentUserId);
  return (
    <UserAvatar userId={currentUserId} size={size} className={className} />
  );
};
