import produce from "immer";
import _ from "lodash";
import { iPresetState } from "../../../@sdk/user-management/preset-state-model";

export const initialBlockBuilderState: iPresetState = {
  presets: [],
  folders: [],
};

export const reducer = (state: iPresetState, action): iPresetState => {
  switch (action.type) {
    case "RESET_STATE":
      return action.payload.state;
    case "ADD_PRESET":
      return produce(state, (draft) => {
        const preset = action.payload.preset;
        draft.presets.push(preset);
      });
    case "EDIT_PRESET":
      return produce(state, (draft) => {
        const preset = action.payload.preset;
        const presetToEdit = _.find(draft.presets, { id: preset.id });
        if (presetToEdit) {
          Object.assign(presetToEdit, preset);
        }
      });
    case "REMOVE_PRESET":
      return produce(state, (draft) => {
        const preset = action.payload.preset;
        const presetToRemove = _.find(draft.presets, { id: preset.id });
        _.pull(draft.presets, presetToRemove);
      });
    case "ADD_FOLDER":
      return produce(state, (draft) => {
        const folder = action.payload.folder;
        draft.folders.push(folder);
      });
    case "EDIT_FOLDER":
      return produce(state, (draft) => {
        const folder = action.payload.folder;
        const folderToEdit = _.find(draft.folders, { id: folder.id });
        if (folderToEdit) {
          Object.assign(folderToEdit, folder);
        }
      });
    case "REMOVE_FOLDER":
      return produce(state, (draft) => {
        const folder = action.payload.folder;
        const folderToRemove = _.find(draft.folders, { id: folder.id });
        _.pull(draft.folders, folderToRemove);

        // Remove Folder Reference
        draft.presets.forEach((preset) => {
          if (preset.folder === folder.id) {
            preset.folder = "";
          }
        });
      });
    case "REMOVE_FOLDER_WITH_PRESETS":
      return produce(state, (draft) => {
        const folder = action.payload.folder;
        const folderToRemove = _.find(draft.folders, { id: folder.id });
        _.pull(draft.folders, folderToRemove);
        // Remove Presets Belonging to folder
        draft.presets = _.filter(
          draft.presets,
          (preset) => preset.folder !== folder.id,
        );
      });
    default:
      // console.log("action", action);
      // console.log("state", state);
      throw new Error();
  }
};
