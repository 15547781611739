import { iCompany } from "@sdk/crm/crm.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { companiesDefaultQueryConfig } from "./companies-default-query";

export const {
  slice: CompaniesSlice,
  reducers: CompaniesSliceReducer,
  actions: {
    setEntityQueryResults: setCompaniesQueryResults,
    setEntity: setCompany,
    setEntities: setCompanies,
    patchEntity: patchCompany,
    addNewEntity: addNewCompany,
    setEntityFetchingStatus: setCompanyFetchingStatus,
    setEntitiesFetchingStatus: setCompaniesFetchingStatus,
    setEntityFailedStatus: setCompanyFailedStatus,
    setEntityQueryError: setCompaniesQueryError,
    resetQuery: resetCompaniesQuery
  }
} = CreateEntitySlice<iCompany>({
  sliceName: "companies",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...companiesDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
