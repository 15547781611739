import produce from "immer";
import { iBlockBuilderState } from "./block-builder-model";
export const initialBlockBuilderState: iBlockBuilderState = {
  blocks: {
    byIds: {},
    list: []
  }
};

export const reducer = (state: iBlockBuilderState, action) => {
  switch (action.type) {
    case "RESET_STATE":
      return action.payload.state;
    case "ADD_BLOCK":
      return produce(state, draft => {
        draft.blocks.byIds[action.payload.block.id] = action.payload.block;
        draft.blocks.list.push(action.payload.block.id);
      });
    case "EDIT_BLOCK":
      return produce(state, draft => {
        Object.assign(
          draft.blocks.byIds[action.payload.block.id],
          action.payload.block
        );
      });
    case "REMOVE_BLOCK":
      return produce(state, draft => {
        const {
          [action.payload.blockId]: removeCollection,
          ...restCollections
        } = draft.blocks.byIds;
        draft.blocks.byIds = restCollections;
        //check these things for mutations???????????????????????????????
        const filteredBlockIds = draft.blocks.list.filter(
          item => item !== action.payload.blockId
        );
        draft.blocks.list = filteredBlockIds;
      });
    case "REORDER_BLOCK":
      return produce(state, draft => {
        draft.blocks.list = action.payload.list;
      });
    default:
      throw new Error();
  }
};
