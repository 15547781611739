export const GenerateBaseEmailTemplate: (
  blockCodes: string[],
  companyName: string,
  configurations: {
    primaryColor?: string;
    preBodyContent?: string;
  },
) => string = (
  blockCodes,
  companyName,
  { primaryColor = "#8c54a1", preBodyContent },
) => {
  return /*html*/ `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml" lang="en" xml:lang="en">
  
    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
        <meta name="viewport" content="width=device-width">
        <style type="text/css">
            #outlook a {
                padding: 0;
            }
  
            body {
                width: 100% !important;
                min-width: 100%;
                -webkit-text-size-adjust: 100%;
                -ms-text-size-adjust: 100%;
                margin: 0;
                Margin: 0;
                padding: 0;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
            }
  
            #backgroundTable {
                margin: 0;
                Margin: 0;
                padding: 0;
                width: 100% !important;
                line-height: 100% !important;
            }
  
            img {
                outline: none;
                text-decoration: none;
                -ms-interpolation-mode: bicubic;
                width: auto;
                max-width: 100%;
                clear: both;
                display: block;
            }
  
            center {
                width: 100%;
                min-width: 580px;
            }
  
            a img {
                border: none;
            }
  
            p {
                margin: 0 0 0 10px;
                Margin: 0 0 0 10px;
            }
  
            table {
                border-spacing: 0;
                border-collapse: collapse;
            }
  
            td {
                word-wrap: break-word;
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                hyphens: auto;
                border-collapse: collapse !important;
            }
  
            table,
            tr,
            td {
                padding: 0;
                vertical-align: top;
                text-align: left;
            }
  
            html {
                min-height: 100%;
                background: #f0f0f0;
            }
  
            .gray-background {
                background: #f0f0f0;
            }
  
            .dark-text {
                color: #555555;
            }
  
            table.body {
                background: #f0f0f0;
                height: 100%;
                width: 100%;
            }
  
            table.container {
                background: #fefefe;
                width: 580px;
                margin: 0 auto;
                Margin: 0 auto;
                text-align: inherit;
            }
  
            table.row {
                padding: 0;
                width: 100%;
                position: relative;
            }
  
            table.container table.row {
                display: table;
            }
  
            td.columns,
            td.column,
            th.columns,
            th.column {
                margin: 0 auto;
                Margin: 0 auto;
                padding-left: 70px;
                padding-right: 70px;
                padding-bottom: 16px;
            }
  
            td.columns.last,
            td.column.last,
            th.columns.last,
            th.column.last {
                padding-right: 70px;
            }
  
            td.columns table,
            td.column table,
            th.columns table,
            th.column table {
                width: 100%;
            }
  
            table.text-center,
            td.text-center,
            h1.text-center,
            h2.text-center,
            h3.text-center,
            h4.text-center,
            h5.text-center,
            h6.text-center,
            p.text-center,
            span.text-center {
                text-align: center;
            }
  
            h1.text-left,
            h2.text-left,
            h3.text-left,
            h4.text-left,
            h5.text-left,
            h6.text-left,
            p.text-left,
            span.text-left {
                text-align: left;
            }
  
            h1.text-right,
            h2.text-right,
            h3.text-right,
            h4.text-right,
            h5.text-right,
            h6.text-right,
            p.text-right,
            span.text-right {
                text-align: right;
            }


            h4 {
                font-size: 24px;
            }

            h5 {
                font-size: 20px;
            }
  
            span.text-center {
                display: block;
                width: 100%;
                text-align: center;
            }
  
            img.float-left {
                float: left;
                text-align: left;
            }
  
            img.float-right {
                float: right;
                text-align: right;
            }
  
            img.float-center,
            img.text-center {
                margin: 0 auto;
                Margin: 0 auto;
                float: none;
                text-align: center;
            }
  
            table.float-center,
            td.float-center,
            th.float-center {
                margin: 0 auto;
                Margin: 0 auto;
                float: none;
                text-align: center;
            }
  
            table.body table.container .hide-for-large {
                display: none;
                width: 0;
                mso-hide: all;
                overflow: hidden;
                max-height: 0px;
                font-size: 0;
                width: 0px;
                line-height: 0;
            }
  
  
  
  
            body,
            table.body,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p,
            td,
            th,
            a {
                color: #0a0a0a;
                font-family: Helvetica, Arial, sans-serif;
                font-weight: 200;
                padding: 0;
                margin: 0;
                Margin: 0;
                text-align: left;
                line-height: 1.3;
            }
  
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: inherit;
                word-wrap: normal;
                font-family: Helvetica, Arial, sans-serif;
                font-weight: normal;
                margin-bottom: 10px;
                Margin-bottom: 10px;
            }
            
            th.code-block{
                padding-left: 10px;
                padding-right: 10px;
                padding-bottom: 10px;
                padding-top: 10px;
            }
  
  
            body,
            table.body,
            p,
            td,
            th {
                font-size: 15px;
                line-height: 19px;
            }
  
            p {
                margin-bottom: 10px;
            }
  
            hr {
                max-width: 580px;
                height: 0;
                border-right: 0;
                border-top: 0;
                border-bottom: 1px solid #cacaca;
                border-left: 0;
                margin: 20px auto;
                Margin: 20px auto;
                clear: both;
            }
  
  
            .footercopy {
                padding: 20px 0px;
                font-size: 12px;
                color: #777777;
            }
  
            p {
                color: #777777 !important;
            }

  
            .chat-message {
                color: #333333;
                font-family: Helvetica, Arial, sans-serif;
                font-size: 15px;
                word-break: break-word;
            }

            .smaller-lighter-font{
                color: #555555;
                font-family: Helvetica, Arial, sans-serif;
                font-size: 12px;
            }
  
            .button-link {
                background-color: ${primaryColor};
                border: 0px solid ${primaryColor};
                border-radius: 3px;
                color: #ffffff !important;
                display: inline-block;
                font-family: sans-serif;
                font-size: 16px;
                font-weight: bold;
                line-height: 40px;
                text-align: center;
                text-decoration: none;
                width: 200px;
                -webkit-text-size-adjust: none;
                mso-hide: all;
            }
  
            .primary-background {
                background-color: ${primaryColor};
            }
  
            .w-200{
                width:200px;
            }

            .w-400{
                width:400px;
            }
  
            .bold-font{
                font-weight: 600;
            }

            .grey {
                background: #f0f0f0;
            }

            .p-10{
                padding:10px;
            }
        </style>
  
  
    </head>
  
    <body>
        <table class="body">
            <tr>
                <td class="" align="center" valign="top">
                    <center data-parsed="">
                        <table class="container text-center">
                            <tbody>
                                <tr>
                                    <td>
                                        <!-- This container adds the gap at the top of the email -->
                                        <table class="row grey">
                                            <tbody>
                                                <tr>
                                                    <th class="small-12 large-12 columns first last gray-background">
                                                        <table>
                                                            <tr>
                                                                <th>
                                                                    &#xA0;
                                                                </th>
                                                                <th class="expander">${
                                                                  preBodyContent ||
                                                                  ""
                                                                }</th>
                                                            </tr>
                                                        </table>
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
  
                        <table class="container text-center">
                            <tbody>
                                <tr>
                                    <td>
                                    ${blockCodes
                                      .map((blockCode) => blockCode)
                                      .join("")}

                                        <table class="row">
                                            <tbody>
                                                <tr>
                                                    <!--This row adds the gap -->
                                                    <th class="small-12 large-12 columns first last">
                                                        <table>
                                                            <tr>
                                                                <th>
                                                                    &#xA0;
                                                                </th>
                                                                <th class="expander"></th>
                                                            </tr>
                                                        </table>
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table> <!-- / End main email content -->
  
                        <table class="container text-center">
                            <tbody>
                                <tr>
                                    <td>
                                        <table class="row gray-background">
                                            <tbody>
                                                <tr>
                                                    <th class="small-12 large-12 columns first last">
                                                        <table>
                                                            <tr>
                                                                <th>
                                                                    <p class="text-center footercopy">&#xA9; Copyright 2017
                                                                        ${companyName}. All Rights Reserved.</p>
                                                                </th>
                                                                <th class="expander"></th>
                                                            </tr>
                                                        </table>
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </center>
                </td>
            </tr>
        </table>
    </body>
  </html>
    `;
};
