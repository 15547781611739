import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iFileRecord } from "@sdk/file-management/file-management.models";
import {
  iImageUploadResizerOptions,
  iUploadValidation,
} from "components/modules/file-management/upload-validation-model";
import React from "react";

const FileSelectorModal = React.lazy(() => import("./file-selector.modal"));

const FileSelector: iModalProps<
  typeof FileSelectorModal,
  {
    singleSelection?: boolean;
    onSelected: (files: iFileRecord[]) => any;
    reference: {
      type: string;
      entityId: string;
    };
    filePickerPresetRenderer?: (data: {
      onFileSelection: (file: iFileRecord) => void;
      selectedItems: string[];
    }) => JSX.Element;
    validations?: iUploadValidation[];
    resizeBeforeUpload?: iImageUploadResizerOptions;
    customRecentUploadQuery?: string;
  }
> = {
  name: "file-picker",
  component: GenerateModalWithSuspense(FileSelectorModal),
};

export default FileSelector;
