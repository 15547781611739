import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const EmailSeriesGeneratorWithPromptModal = React.lazy(
  () => import("./email-series-generator-with-prompt.modal"),
);

const EmailSeriesGeneratorWithPrompt: iModalProps<
  typeof EmailSeriesGeneratorWithPromptModal,
  {
    generatorType: "WELCOME_EMAIL_SERIES" | "CUSTOM_EMAIL_SERIES";
    defaultPrompt?: string;
  }
> = {
  name: "email-series-generator-with-prompt",
  component: GenerateModalWithSuspense(EmailSeriesGeneratorWithPromptModal),
};

export default EmailSeriesGeneratorWithPrompt;
