export const allIntegrations: {
  section: string;
  icon: any;
  apps: {
    name: string;
    logo: any;
    description: string;
    content?: JSX.Element;
  }[];
}[] = [
  {
    section: "CRM",
    icon: <i className="ri-folder-user-line"></i>,
    apps: [
      {
        name: "Mailchimp",
        description: "Sync Contacts with your mailing List",
        logo: (
          <img
            src="/assets/integrations/mailchimp.png"
            style={{ width: 30 }}
            alt=""
            className=""
          />
        ),
      },
      {
        name: "Hub Spot",
        description:
          "Integrate your Hubspot CRM and see customer data right in your ClickConnector Inbox",
        logo: (
          <img
            src="/assets/integrations/hubspot-logo.jpg"
            style={{ width: 30 }}
            alt=""
            className=""
          />
        ),
      },
      {
        name: "Pipe Drive",
        description:
          "Integrate your Pipe Drive CRM and see customer data right in your ClickConnector Inbox",
        logo: (
          <img
            src="/assets/integrations/pipe-drive-logo-2.png"
            style={{ width: 30 }}
            alt=""
            className=""
          />
        ),
      },
    ],
  },
  {
    section: "E-Commerce",
    icon: <i className="ri-shopping-cart-line"></i>,
    apps: [
      {
        name: "Shopify",
        description:
          "Integrate your Shopify Store with ClickConnector for Seamless Customer Support",
        logo: (
          <img
            src="/assets/integrations/shopify.png"
            style={{ width: 30 }}
            alt=""
            className=""
          />
        ),
      },
    ],
  },
  {
    section: "Payment Providers",
    icon: <i className="ri-bank-card-line"></i>,
    apps: [
      {
        name: "Stripe",
        description:
          "Integrate your Stripe Account to see the invoices and payments associated with Stripe Records",
        logo: (
          <img
            src="/assets/integrations/stripe-logo.svg"
            style={{ width: 30 }}
            alt=""
            className=""
          />
        ),
      },
    ],
  },
  {
    section: "Task Management",
    icon: <i className="ri-list-check-3"></i>,
    apps: [
      {
        name: "Notion",
        description:
          "Integrate your Notion Account to link conversations to your Notion database records",
        logo: (
          <img
            src="/assets/integrations/notion-logo.png"
            style={{ width: 30 }}
            alt=""
            className=""
          />
        ),
      },
      {
        name: "Asana",
        description:
          "Integrate your Asana Account to link conversations to your Asana tasks",
        logo: (
          <img
            src="/assets/integrations/asana-logo.png"
            style={{ width: 30 }}
            alt=""
            className=""
          />
        ),
      },
      {
        name: "ClickUp",
        description:
          "Integrate your ClickUp Account to link conversations to your ClickUp tasks",
        logo: (
          <img
            src="/assets/integrations/click-up-logo.png"
            style={{ width: 30 }}
            alt=""
            className=""
          />
        ),
      },
      {
        name: "Jira",
        description:
          "Integrate your Jira Account to link conversations to your Jira tasks",
        logo: (
          <img
            src="/assets/integrations/jira-logo.png"
            style={{ width: 30 }}
            alt=""
            className=""
          />
        ),
      },
      {
        name: "Git Hub",
        description:
          "Integrate your Git Hub Repository and link conversations to your GitHub Issues",
        logo: (
          <img
            src="/assets/integrations/github-logo.png"
            style={{ width: 30 }}
            alt=""
            className=""
          />
        ),
      },
    ],
  },
  {
    section: "Help Desk",
    icon: <i className="ri-customer-service-2-line"></i>,
    apps: [
      {
        name: "ZenDesk",
        description:
          "Integrate your Zendesk Account to link conversations to your Zendesk tickets",
        logo: (
          <img
            src="/assets/integrations/zen-desk-logo.png"
            style={{ width: 30 }}
            alt=""
            className=""
          />
        ),
      },
      {
        name: "FreshDesk",
        description:
          "Integrate your Fresh Desk Account to link conversations to your Fresh Desk tickets",
        logo: (
          <img
            src="/assets/integrations/fresh-desk-logo.png"
            style={{ width: 30 }}
            alt=""
            className=""
          />
        ),
      },
      {
        name: "ZohoDesk",
        description:
          "Integrate your Zoho Desk Account to link conversations to your Zoho Desk tickets",
        logo: (
          <img
            src="/assets/integrations/zoho-desk-logo.png"
            style={{ width: 30 }}
            alt=""
            className=""
          />
        ),
      },
    ],
  },
  {
    section: "Scheduling Apps",
    icon: <i className="ri-calendar-check-line"></i>,
    apps: [
      {
        name: "Google Meet",
        description:
          "Integrate your Google Meet Account to create Google Meet links directly from ClickConnector",
        logo: (
          <img
            src="/assets/integrations/google-meet-logo.png"
            style={{ width: 30 }}
            alt=""
            className=""
          />
        ),
      },
      {
        name: "Calendly",
        description:
          "Integrate your Calendly Account to create send calendar links directly from ClickConnector",
        logo: (
          <img
            src="/assets/integrations/calendly-logo.png"
            style={{ width: 30 }}
            alt=""
            className=""
          />
        ),
      },
      {
        name: "Cal",
        description:
          "Integrate your Cal Account to create send calendar links directly from ClickConnector",
        logo: (
          <img
            src="/assets/integrations/cal-logo.png"
            style={{ width: 30 }}
            alt=""
            className=""
          />
        ),
      },
    ],
  },
  {
    section: "DIY Integrations",
    icon: <i className="ri-plug-line"></i>,
    apps: [
      {
        name: "Zapier",
        description:
          "Zapier opens up integrations with 1,000 other tools that ClickConnector does not natively integrate with.",
        logo: (
          <img
            src="/assets/integrations/zapier.svg"
            style={{ width: 30 }}
            alt=""
          />
        ),
      },
      {
        name: "Webhooks",
        description:
          "Webhooks enable you to monitor data modifications in the ClickConnector platform and effect essential updates in your systems.",
        logo: <i className="ri-webhook-line"></i>,
      },
      {
        name: "APIs",
        description:
          "Our APIs can facilitate sending data to the ClickConnector platform.",
        logo: <i className="ri-plug-line"></i>,
      },
    ],
  },
];
