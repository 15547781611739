export const EmailBlock_EmptySpace: (options?: {
  background?: string;
}) => string = (options) => {
  return /*html*/ `<table class="row" ${
    options?.background ? `style="background: ${options.background};"` : ``
  }>
      <tbody>
          <tr>
              <th class="small-12 large-12 columns first last">
                  <table>
                      <tr>
                          <th class="expander"></th>
                      </tr>
                  </table>
              </th>
          </tr>
      </tbody>
  </table>`;
};
