import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const TestimonialCollectionModal = React.lazy(
  () => import("./testimonial-collection.modal"),
);
const TestimonialCollection: iModalProps<
  typeof TestimonialCollectionModal,
  {}
> = {
  name: "testimonial-collection",
  component: GenerateModalWithSuspense(TestimonialCollectionModal),
};

export default TestimonialCollection;
