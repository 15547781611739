export const getFbAvatar = (fbUserId: string, accessToken?: string) => {
  if (fbUserId.indexOf("dummy_FB_User") > -1) {
    return `https://i.pravatar.cc/150?u=${fbUserId}`;
  }
  if (accessToken) {
    const url = `https://graph.facebook.com/${fbUserId}/picture?type=square&access_token=${accessToken}`;
    const proxyUrl = `https://us-central1-click-connector.cloudfunctions.net/app/tunnel?url=${encodeURIComponent(
      url
    )}`;
    return proxyUrl;
  }
  return `https://graph.facebook.com/${fbUserId}/picture?type=square`;
  // return `/assets/img/avatars/missing-profile-picture.jpg`;
};
