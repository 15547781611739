import { iOrganization } from "@sdk/user-management/user-management.models";
import { Checkbox, Form, InputNumber, Select } from "antd";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { StyledSwitch } from "components/common/styled-swtich";
import { useSelector } from "react-redux";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import {
  selectIndustry,
  selectOrganization,
} from "store/modules/workspace/workspace.selectors";
import { isIpAddress } from "utils/is-ip-address";
import { stringArrayToSentence } from "utils/string-array-to-sentence";
import { BetaFeatures } from "../sections/general-settings/beta-features";
import { FeatureThatCanBeLimited } from "../sections/general-settings/features-limited";
import { CheckBoxWithDeleteIcon } from "../sections/general-settings/general-settings-home";

export const AccessAndAvailabilitySettings = () => {
  const organization = useSelector(selectOrganization);

  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  const industry = useSelector(selectIndustry);

  return (
    <>
      <ConfigurationEditorComponent
        icon="ri-shield-check-line"
        title="Whitelisted IPs"
        description="Choose which IPs can login to your ClickConnector's workspace"
        entityType="WORKSPACE"
      >
        <Form.Item
          label="Whitelisted IPs"
          name={["data", "whiteListedAccessIPs"]}
          hasFeedback
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, ips) {
                if (!ips || ips.length === 0) {
                  return Promise.resolve();
                }
                const invalidIps = ips.filter((ip) => !isIpAddress(ip));
                if (invalidIps.length > 0) {
                  return Promise.reject(
                    `The following Ips are Invalid: ${stringArrayToSentence(
                      invalidIps,
                    )}`,
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Whitelisted IPs"
            dropdownStyle={{ display: "none" }}
            allowClear
          ></Select>
        </Form.Item>
      </ConfigurationEditorComponent>

      <ConfigurationEditorComponent
        icon={"ri-timer-line"}
        title={"Smart Availability"}
        description={`Automatically set user's availability to "away" after a period of inactivity`}
        entityType="WORKSPACE"
        transformFormValues={(values) => ({
          appData: {
            HELP_DESK: {
              autoKickUsers: {
                active: values.appData?.HELP_DESK?.autoKickUsers?.active,
                timeout:
                  (values.appData.HELP_DESK?.autoKickUsers?.timeout || 0) *
                  (60 * 1000),
              },
            },
          },
        })}
        transformEntityValues={(organization: iOrganization) => ({
          appData: {
            HELP_DESK: {
              autoKickUsers: {
                active: organization?.appData?.HELP_DESK?.autoKickUsers?.active,
                timeout:
                  (organization?.appData?.HELP_DESK?.autoKickUsers?.timeout ||
                    0) /
                  (60 * 1000),
              },
            },
          },
        })}
      >
        <div className="mb-3">
          <StyledSwitch
            label="Active"
            name={["appData", "HELP_DESK", "autoKickUsers", "active"]}
          />
        </div>
        <Form.Item
          label="Timeout (minutes):"
          name={["appData", "HELP_DESK", "autoKickUsers", "timeout"]}
          rules={[{ required: true }]}
          className="m-0"
        >
          <InputNumber className="w-full" min={5} max={60} />
        </Form.Item>
      </ConfigurationEditorComponent>

      <ConfigurationEditorComponent
        icon={"ri-lock-password-line"}
        title={"Stronger Password"}
        description={`Enforce users to set strong password`}
        entityType="WORKSPACE"
      >
        <div className="mb-3">
          <StyledSwitch
            label="Enforce Strong Password"
            name={["appData", "HELP_DESK", "shouldEnforceStrongerPassword"]}
          />
          <div className="text-gray-600">
            This enforcement will only be enabled when user tries to set a new
            password
          </div>
        </div>
      </ConfigurationEditorComponent>

      <ConfigurationEditorComponent
        icon="ri-forbid-line"
        title="Restrict ClickConnector Features by User Roles"
        description="Set granular user role level access to ClickConnector features"
        entityType="WORKSPACE"
      >
        * By default below features are available to all user roles
        <table className="simple-table-style w-full my-8">
          <thead>
            <tr>
              <th className="font-bold">Feature</th>
              <th className="font-bold">Team Member</th>
              <th className="font-bold">Manager</th>
            </tr>
          </thead>
          <tbody>
            {FeatureThatCanBeLimited.map((item, index) => {
              return (
                <tr key={item.key}>
                  <td>{item.label}</td>
                  <td>
                    <Form.Item
                      label=""
                      name={[
                        "appData",
                        "HELP_DESK",
                        "featureRestrictionConfigs",
                        item.key,
                        "team",
                      ]}
                      rules={[]}
                      className="m-0"
                    >
                      <CheckBoxWithDeleteIcon />
                    </Form.Item>
                  </td>
                  <td>
                    {" "}
                    <Form.Item
                      label=""
                      name={[
                        "appData",
                        "HELP_DESK",
                        "featureRestrictionConfigs",
                        item.key,
                        "manager",
                      ]}
                      rules={[]}
                      className="m-0"
                    >
                      <CheckBoxWithDeleteIcon />
                    </Form.Item>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </ConfigurationEditorComponent>
      {isAdvancedMode && (
        <ConfigurationEditorComponent
          icon="ri-gift-line"
          title="Enable Beta Features"
          description="Set granular user role level access to ClickConnector Insider features"
          entityType="WORKSPACE"
        >
          * By default below features are not available to any user roles
          <table className="simple-table-style w-full my-8">
            <thead>
              <tr>
                <th className="font-bold">Feature</th>
                <th className="font-bold">Team Member</th>
                <th className="font-bold">Manager</th>
              </tr>
            </thead>
            <tbody>
              {BetaFeatures.map((item, index) => {
                return (
                  <tr key={item.key}>
                    <td>{item.label}</td>
                    <td>
                      <Form.Item
                        label=""
                        name={[
                          "appData",
                          "HELP_DESK",
                          "betaFeaturesConfig",
                          item.key,
                          "Team Member",
                        ]}
                        rules={[]}
                        className="m-0"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </td>
                    <td>
                      {" "}
                      <Form.Item
                        label=""
                        name={[
                          "appData",
                          "HELP_DESK",
                          "betaFeaturesConfig",
                          item.key,
                          "Manager",
                        ]}
                        rules={[]}
                        className="m-0"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ConfigurationEditorComponent>
      )}
    </>
  );
};
