import { iUser } from "@sdk/user-management/user-management.models";
import { GlobalConfig } from "config";
// Tutorial
// https://medium.com/@seladir/how-to-implement-web-push-notifications-in-your-node-react-app-9bed79b53f34

const REACT_APP_PUBLIC_VAPID_KEY =
  "BFoKFnPN5imktV8iiR-SbFASO9TYPkpUYTuYZnwidfXo9V49HAe4vUZyvWcv8iSVi0ljcgcuBtl4-OjjE8DmWek";
const convertedVapidKey = urlBase64ToUint8Array(REACT_APP_PUBLIC_VAPID_KEY);

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding)
    // eslint-disable-next-line no-useless-escape
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function sendSubscription(subscription, token: string) {
  const config = {
    method: `POST`,
  };
  Object.assign(config, {
    body: JSON.stringify(subscription),
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  });

  return fetch(`${GlobalConfig.ENGINE_BASE_PATH}/users/me/subscribe-to-push`, {
    ...config,
  });
}

export async function getSubscription(token: string, user: iUser) {
  if (!("serviceWorker" in navigator)) {
    return null;
  }
  try {
    const registration = await navigator.serviceWorker.ready;
    if (!registration.pushManager) {
      console.log("Push manager unavailable.");
      return null;
    }
    const existedSubscription =
      await registration.pushManager.getSubscription();
    if (existedSubscription === null) {
      return null;
    }
    // console.log("Existed subscription detected.");
    const currentClient = existedSubscription.toJSON();
    let alreadyInDb = false;
    for (const client of user.pushClients || []) {
      if (
        client.keys.auth === currentClient.keys?.auth &&
        client.keys.p256dh === currentClient.keys?.p256dh
      ) {
        alreadyInDb = true;
      }
    }
    return {
      subscription: existedSubscription,
      isRegistered: alreadyInDb,
    };
  } catch (e) {
    console.error("An error ocurred get Push Subscription", e);
    return null;
  }
}

export function subscribeUser(token: string, user: iUser) {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then(function (registration) {
        if (!registration.pushManager) {
          console.log("Push manager unavailable.");
          return;
        }

        registration.pushManager
          .getSubscription()
          .then(function (existedSubscription) {
            if (existedSubscription === null) {
              console.log("No subscription detected, make a request.");
              registration.pushManager
                .subscribe({
                  applicationServerKey: convertedVapidKey,
                  userVisibleOnly: true,
                })
                .then(function (newSubscription) {
                  console.log("New subscription added.");
                  sendSubscription(newSubscription, token);
                })
                .catch(function (e) {
                  if (typeof Notification === "undefined") {
                    return false;
                  }
                  if (Notification.permission !== "granted") {
                    console.log("Permission was not granted.");
                  } else {
                    console.error(
                      "An error ocurred during the subscription process.",
                      e,
                    );
                  }
                });
            } else {
              // console.log("Existed subscription detected.");
              const currentClient = existedSubscription.toJSON();
              let alreadyInDb = false;
              for (const client of user.pushClients || []) {
                if (
                  client.keys.auth === currentClient.keys?.auth &&
                  client.keys.p256dh === currentClient.keys?.p256dh
                ) {
                  alreadyInDb = true;
                }
              }
              // console.log("alreadyInDb", alreadyInDb);
              if (!alreadyInDb) {
                sendSubscription(existedSubscription, token);
              }
            }
          });
      })
      .catch(function (e) {
        console.error(
          "An error ocurred during Service Worker registration.",
          e,
        );
      });
  }
}
