import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { BulkUserActions } from "@sdk/user-management/user-management.controller-models";
import React from "react";

const BulkEditUsersModal = React.lazy(() => import("./bulk-edit-users.modal"));

const BulkEditUsers: iModalProps<
  typeof BulkEditUsersModal,
  { query: any; action: BulkUserActions }
> = {
  name: "bulk-edit-users",
  component: GenerateModalWithSuspense(BulkEditUsersModal),
};

export default BulkEditUsers;
