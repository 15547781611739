import { Avatar } from "components/common/avatar/avatar";

export const UserPill = ({
  name,
  email,
  avatar
}: {
  name: string;
  email: string;
  avatar?: string;
}) => (
  <div className="user-pill leading-normal">
    <div className="flex-row flex justify-start items-center">
      <Avatar avatarUrl={avatar} name={name!} size={30} />
      <div className="flex-col pl-3">
        <div className="font-bold">{`${name}`}</div>
        <div className="description">{`${email}`}</div>
      </div>
    </div>
  </div>
);
