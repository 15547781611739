export const PowerSettingsOptions = [
  {
    label: "New chats will notify everyone",
    description: "Incoming chat will be alerted to all users",
    icon: <i className="ri-notification-3-line" />,
  },
  {
    label: "Alert all on unassigned chats",
    description: "Show a visual indicator of unassigned chats",
    icon: <i className="ri-alarm-warning-line" />,
  },
  {
    label: `Prevent "Join Chat" clashes`,
    description: `Prevent additional users joining a chat for few seconds after it has been assigned to a user`,
    icon: <i className="ri-sword-line" />,
  },
  {
    label: "Display connection context",
    description: "Display connection name across chats, contacts and sessions",
    icon: <i className="ri-honour-line" />,
  },
  {
    label: "Default Inboxes",
    description: "Configure which inboxes should be visible for new users",
    icon: <i className="ri-folders-line" />,
  },
  {
    label: "Default Chat Side Bar Setup",
    description:
      "Configure how chat side bar should be organized for new users",
    icon: <i className="ri-layout-right-line" />,
  },
  {
    label: "Auto Translation",
    description:
      "Configure the default language for converting incoming messages",
    icon: <i className="ri-translate" />,
  },
];

export const AccessPowerSettingsOptions = [
  {
    label: "Whitelisted IPs",
    description:
      "Choose which IPs can login to your ClickConnector's workspace",
    icon: <i className="ri-shield-check-line" />,
  },
  {
    label: "Smart Availability",
    description: `Automatically set user's availability to "away" after a period of inactivity`,
    icon: <i className="ri-timer-line" />,
  },
  {
    label: "Stronger Password",
    description: "Enforce users to set strong password",
    icon: <i className="ri-lock-password-line" />,
  },
  {
    label: "Restrict ClickConnector Features by User Roles",
    description:
      "Set granular user role level access to ClickConnector features",
    icon: <i className="ri-forbid-line" />,
  },
];
