import styled from "styled-components";
import { GenerateColorTheme } from "./generate-theme";

export const StyledWidgetContainer = styled.div`
  ${(props) => {
    const primaryColor = props.color || "#000000";

    const additionalStyle = `
    // .chat-widget-avatar-container {
    //   border:2px solid ${primaryColor};
    // }
    height:100%;
    `;
    return additionalStyle + "\n" + GenerateColorTheme(primaryColor, "#333");
  }}
`;
