import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iCustomDomain } from "@sdk/sending-domains/sending-domains-models";
import { Button, Collapse, Table, Typography } from "antd";
import { useMemo } from "react";

const emptyArray = [];

export const SendingDomainConfig = ({
  sendingDomain,
}: {
  sendingDomain: iCustomDomain;
}) => {
  const columns = useMemo(() => {
    return [
      {
        title: "",
        dataIndex: "valid",
        render: (status) => {
          if (status === "valid") {
            return (
              <div className="rounded-full bg-green-200 w-5 h-5 flex flex-row justify-center items-center text-green-600">
                <i className="ri-check-line"></i>
              </div>
            );
          }
          return (
            <div className=" rounded-full w-5 h-5 flex flex-row justify-center items-center text-red-600">
              <i className="ri-error-warning-fill"></i>
            </div>
          );
        },
      },
      {
        title: "Type",
        dataIndex: "record_type",
      },
      {
        title: "Hostname",
        dataIndex: "name",
        render: (name) => name || sendingDomain.domain,
      },
      {
        title: "Priority",
        dataIndex: "priority",
      },
      {
        title: "Enter This Value",
        dataIndex: "value",
        render: (value) => (
          <div
            className="p-4 bg-gray-100  dark:bg-gray-700 rounded-lg mode_transition"
            style={{ maxWidth: 200 }}
          >
            <Typography.Paragraph
              ellipsis={{ rows: 3, expandable: false, symbol: "more" }}
              copyable={{ text: value }}
            >
              {value}
            </Typography.Paragraph>
          </div>
        ),
      },
      {
        title: "Current Value",
        dataIndex: "cached",
        render: (cached: string[]) => (
          <div className="" style={{ maxWidth: 200 }}>
            <span>
              {cached.map((value, index) => (
                <Typography.Text code ellipsis key={value + index}>
                  {value}
                </Typography.Text>
              ))}
              {cached.length === 0 && <div>None found</div>}
            </span>
          </div>
        ),
      },
    ];
  }, [sendingDomain.domain]);

  const columnsWithoutPriority = useMemo(() => {
    return columns.filter((item) => item.title !== "Priority");
  }, [columns]);

  const _sendingRecords =
    sendingDomain?.mailgunData.sending_dns_records || emptyArray;
  const receivingRecords =
    sendingDomain?.mailgunData.receiving_dns_records || emptyArray;

  const sendingRecords = useMemo(() => {
    return _sendingRecords.filter((item) => item.value !== "mailgun.org");
  }, [_sendingRecords]);

  const trackingRecords = useMemo(() => {
    return _sendingRecords.filter((item) => item.value === "mailgun.org");
  }, [_sendingRecords]);

  const { doAction: verifyDomain, isProcessing: isVerifying } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.sendingDomains.verifyCustomDomain(sendingDomain?.id!),
      }),
      [sendingDomain?.id],
    );

  return (
    <div className="styled-accordion-container">
      <div className="flex flex-row mb-4  items-center justify-between">
        <div className="text-lg font-bold">
          Follow these steps to verify your domain
        </div>
        <div className="flex flex-row items-center gap-2 text-lg font-bold">
          {sendingDomain.isMailBoxReady && (
            <>
              <i className="ri-checkbox-circle-fill text-green-600"></i>
              <span className="">Verified</span>
            </>
          )}
        </div>
      </div>
      <Collapse className="border-0" defaultActiveKey={[]}>
        <Collapse.Panel header="1. Go to your DNS provider" key="PROVIDER">
          <div className="Box-row">
            <p>
              Go to the DNS provider that you use to manage{` `}
              {sendingDomain.domain} and add the following DNS records.
            </p>
            <p>
              Need a step-by-step walk through?
              <Button
                type="link"
                className="mg-link px-1"
                href="https://help.mailgun.com/hc/en-us/articles/360026833053"
                target="_blank"
              >
                Click here
              </Button>
            </p>
            <p>
              Prefer to watch a video?
              <Button
                type="link"
                className="mg-link px-1"
                href="https://www.mailgun.com/videos/getting-started-with-mailgun"
                target="_blank"
              >
                We got you covered.
              </Button>
            </p>
            <strong>Common providers include:</strong>
            <ul className="sc-fzoKki gxLdbv">
              <li className="sc-fzoYkl iMSPiu">
                <Button
                  type="link"
                  className="mg-link"
                  href="https://support.godaddy.com/help/article/7925/adding-or-editing-txt-records?locale=en"
                  target="_blank"
                >
                  GoDaddy
                </Button>
              </li>
              <li className="sc-fzoYkl iMSPiu">
                <Button
                  type="link"
                  className="mg-link"
                  href="https://www.namecheap.com/support/knowledgebase/article.aspx/579/2237/which-record-type-option-should-i-choose-for-the-information-im-about-to-enter"
                  target="_blank"
                >
                  NameCheap
                </Button>
              </li>
              <li className="sc-fzoYkl iMSPiu">
                <Button
                  type="link"
                  className="mg-link"
                  href="http://www.networksolutions.com/support/how-to-manage-advanced-dns-records/"
                  target="_blank"
                >
                  Network Solutions
                </Button>
              </li>
              <li className="sc-fzoYkl iMSPiu">
                <Button
                  type="link"
                  className="mg-link"
                  href="https://support.rackspace.com/how-to/set-up-dns-records-for-cloud-office-email-and-skype-for-business/"
                  target="_blank"
                >
                  Rackspace Email &amp; Apps
                </Button>
              </li>
              <li className="sc-fzoYkl iMSPiu">
                <Button
                  type="link"
                  className="mg-link"
                  href="http://www.rackspace.com/knowledge_center/article/rackspace-cloud-dns"
                  target="_blank"
                >
                  Rackspace Cloud DNS
                </Button>
              </li>
              <li className="sc-fzoYkl iMSPiu">
                <Button
                  type="link"
                  className="mg-link"
                  href="http://docs.aws.amazon.com/Route53/latest/DeveloperGuide/R53Console.html"
                  target="_blank"
                >
                  Amazon Route 53
                </Button>
              </li>
              <li className="sc-fzoYkl iMSPiu">
                <Button
                  type="link"
                  className="mg-link"
                  href="http://code.krister.ee/mailgun-digitalocean/"
                  target="_blank"
                >
                  Digital Ocean
                </Button>
              </li>
            </ul>
          </div>
        </Collapse.Panel>
        <Collapse.Panel header="2. Add DNS records for sending" key="SENDING">
          <Table
            columns={columnsWithoutPriority}
            dataSource={sendingRecords}
            pagination={false}
            rowKey="value"
          />
          <div className="text-gray-600 dark:text-gray-400 mode_transition">
            MX records are recommended for all domains, even if you are only
            sending messages. Unless you already have MX records for this domain
            pointing to another email provider (e.g. Gmail), you should update
            the following records.{" "}
            <Button
              type="link"
              className="mg-link"
              href="https://help.mailgun.com/hc/en-us/articles/203357040-Can-I-use-the-same-domain-name-for-Mailgun-and-for-Google-Apps-or-another-email-server-"
              target="_blank"
            >
              More info on MX records
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={receivingRecords}
            pagination={false}
            rowKey="value"
          />
        </Collapse.Panel>
        <Collapse.Panel header="3. Add DNS records for tracking" key="TRACKING">
          <div className="text-gray-600 dark:text-gray-400 mode_transition">
            The CNAME record is necessary for tracking opens, clicks, and
            unsubscribes (recommended).
          </div>
          <Table
            columns={columnsWithoutPriority}
            dataSource={trackingRecords}
            pagination={false}
            rowKey="value"
          />
        </Collapse.Panel>
        {/* <Collapse.Panel
    header="4. Add DMARC (If you haven't already)"
    key="DMARC"
  >
    <div className="text-gray-600 dark:text-gray-400 mode_transition">
      DMARC records are mandtory for all sub domain
    </div>
    <Table
      columns={columnsWithoutPriority}
      dataSource={trackingRecords}
      pagination={false}
      rowKey="value"
    />
  </Collapse.Panel> */}
        <Collapse.Panel header="4. Verify" key="VERIFY">
          <div className="text-gray-600 dark:text-gray-400 mb-4 mode_transition">
            Once you make the above DNS changes it can ta hrs for those changes
            to propagate.We will email you to let you know once your domain is
            verified
          </div>
          <Button
            type="primary"
            onClick={() => verifyDomain()}
            loading={isVerifying}
          >
            Verify
          </Button>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};
