import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";
import { iEmailBodyEditorData } from "./email-body-editor.modal";

const EmailBodyEditorModal = React.lazy(
  () => import("./email-body-editor.modal"),
);

const EmailBodyEditor: iModalProps<
  typeof EmailBodyEditorModal,
  {
    data: iEmailBodyEditorData;
    onSave: (data: iEmailBodyEditorData) => any;
  }
> = {
  name: "email-body-editor",
  component: GenerateModalWithSuspense(EmailBodyEditorModal),
};

export default EmailBodyEditor;
