import { iBadgeRecord } from "@sdk/badges/badge-record-model";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectBadgeQueryMap,
  selectEntityMap: selectBadgesMap,
  selectQueryMapAll: selectBadgesMapAll,
  selectQueryAllList: selectBadgesAllList,
  selectAllEntities: selectAllBadges,
  selectAllEntitiesQuery: selectAllBadgesQuery,
  selectEntityById: selectBadgeById,
  selectEntityQuery: selectBadgesQuery,
} = CreateSelectorForEntities<iStore, iBadgeRecord>({
  sliceName: "badges",
});
