import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ArticlePreviewModal = React.lazy(() => import("./article-preview.modal"));

const ArticlePreview: iModalProps<
  typeof ArticlePreviewModal,
  {
    articleId: string;
  }
> = {
  name: "article-preview",
  component: GenerateModalWithSuspense(ArticlePreviewModal),
};

export default ArticlePreview;
