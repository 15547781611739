import { Select } from "antd";
import { loadAllChatBots } from "store/modules/chat-bots/chat-bots.helpers";
import { selectAllChatBots } from "store/modules/chat-bots/chat-bots.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const ChatBotSelector = (props) => {
  const { state: chatBots, retry: reloadChatBots } = useQueryWithStore(
    selectAllChatBots,
    loadAllChatBots(),
    [],
  );

  return (
    <Select placeholder="Select Chatbot" {...props}>
      {chatBots.map((chatBot) => (
        <Select.Option key={chatBot.id} value={chatBot.id!}>
          {chatBot.label}
        </Select.Option>
      ))}
    </Select>
  );
};
