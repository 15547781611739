import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iKnowledgeDocument } from "@sdk/knowledge-documents/knowledge-documents-model";
import React from "react";

const AddEditKnowledgeDocumentModal = React.lazy(
  () => import("./add-edit-document.modal"),
);

const AddEditKnowledgeDocument: iModalProps<
  typeof AddEditKnowledgeDocumentModal,
  {
    documentId?: string;
    initialValue?: string;
    onArticleSaved?: (doc: iKnowledgeDocument) => any;
  }
> = {
  name: "manage-knowledge-document",
  component: GenerateModalWithSuspense(AddEditKnowledgeDocumentModal),
};

export default AddEditKnowledgeDocument;
