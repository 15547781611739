import { iConnectorContactSideBarData } from "@sdk/chat-connectors/chat-connectors.models";
import { Form, Input } from "antd";
import { SimpleCollapse } from "components/common/simple-collapse/simple-collapse";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { BlockBuilder } from "./block-builder/block-builder.component";
import { IconSelector } from "./icon-selector";

export const ConfigureSideBarPanel = forwardRef((props, ref) => {
  const [form] = Form.useForm();

  const blockBuilderRef = useRef<any>();

  useImperativeHandle(
    ref,
    () => ({
      setSnapshot(connectorData: iConnectorContactSideBarData) {
        form.resetFields();
        form.setFieldsValue({
          ...connectorData.header
        });
        blockBuilderRef.current.setSnapshot(connectorData.body);
      },
      getSnapshot() {
        const header = form.getFieldsValue();
        const body = blockBuilderRef.current.getSnapshot();
        return { header, body } as iConnectorContactSideBarData;
      }
    }),
    [form]
  );

  return (
    <div className="chat-action-builder">
      <div className="border rounded-lg p-4 m-4" style={{}}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{ type: "CONVERSATION_ACTION" }}
        >
          <Form.Item
            name="title"
            label={<div className="font-bold">Side Bar Title</div>}
            rules={[
              {
                required: true,
                message: "Please enter a label"
              }
            ]}
          >
            <Input placeholder="Label" size="large" />
          </Form.Item>
          <Form.Item
            name="icon"
            label={<div className="font-bold">Panel Icon</div>}
            rules={[]}
          >
            <IconSelector />
          </Form.Item>

          <SimpleCollapse title="Appearance">
            <div className="mb-4">
              <Form.Item
                name="height"
                label={<div className="font-bold">Height</div>}
              >
                <Input placeholder="100%" />
              </Form.Item>
              <Form.Item
                name="width"
                label={<div className="font-bold">Width</div>}
              >
                <Input placeholder="100%" />
              </Form.Item>
            </div>
          </SimpleCollapse>
        </Form>
      </div>

      <div className="border rounded-lg p-4 m-4" style={{}}>
        <div className="font-bold mb-8">Blocks</div>
        <BlockBuilder ref={blockBuilderRef} />
      </div>
    </div>
  );
});
