import {
  mdiBookCogOutline,
  mdiCodeBlockTags,
  mdiCogPlay,
  mdiConnection,
  mdiDatabaseSettingsOutline,
  mdiDockRight,
  mdiFolderEditOutline,
  mdiRayStartArrow,
  mdiWrenchCog,
} from "@mdi/js";
import { MDIIcon } from "components/common/mdi-icon";
import {
  EmailChannelSettings,
  EmailV2ChannelSettings,
  FBChannelSettings,
  IGChannelSettings,
  TelegramChannelSetting,
  WhatsappCloudChannelSetting,
  WhatsappPrivateChannelSetting,
  WidgetChannelSettings,
} from "components/modules/connections/channel-menu-index";
import { ConnectionPill } from "components/modules/connections/connection-pill/connection-pill";
import { ConnectionIcons } from "components/modules/connections/helpers/connection-icons";
import { ConnectionTypeLabelMap } from "components/modules/connections/helpers/connection-type-labels";
import { ManageChannelOuter } from "components/modules/connections/manage-channel-outer";
import { ManageChannels } from "components/modules/connections/manage-channels";
import { ManageAutomationRules } from "components/modules/conversations/components/automation-rules/manage-automation-rules";
import { ManageChatActions } from "components/modules/conversations/components/manage-chat-connectors/manage-chat-actions";
import { ManageChatSidePanel } from "components/modules/conversations/components/manage-chat-connectors/manage-chat-side-panel";
import { ManageNewsfeedTypes } from "components/modules/conversations/components/news-feed-type/manage-news-feed-types";
import { ManagePipelines } from "components/modules/conversations/components/pipelines/manage-pipelines";
import { ManageSendingDomains } from "components/modules/conversations/components/sending-domains/manage-sending-domains";
import { ManageSLATemplates } from "components/modules/conversations/components/sla-templates/manage-sla-templates";
import { ManageCaseTypes } from "components/modules/conversations/components/support-case-types/manage-case-types";
import { AppsAndIntegrations } from "components/modules/organization-management/general/apps-and-integrations/apps-and-integrations";
import { allIntegrations } from "components/modules/organization-management/general/apps-and-integrations/external-apps-registry";
import { BillingPortal } from "components/modules/organization-management/general/billing/billing-portal";
import { GetPricingPlanById } from "components/modules/organization-management/general/billing/pricing-plans-data";
import { ManageUserGroups } from "components/modules/user-management/user-groups/manage-user-groups/manage-user-groups";
import { ManageUsers } from "components/modules/user-management/users/components/manage-users/manage-users";
import { flattenDeep, uniqBy } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAllArticles } from "store/modules/articles/articles.selectors";
import { selectAllAutomationWorkflows } from "store/modules/automation-workflows/automation-workflows.selectors";
import { selectAllCampaigns } from "store/modules/campaigns/campaigns.selectors";
import { selectAllChatBots } from "store/modules/chat-bots/chat-bots.selectors";
import { selectAllChatConnectors } from "store/modules/chat-connectors/chat-connectors.selectors";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { selectAllGroups } from "store/modules/groups/groups.selectors";
import { selectAllKnowledgeDocuments } from "store/modules/knowledge-documents/knowledge-documents.selectors";
import { selectAllProactiveCampaigns } from "store/modules/proactive-campaigns/proactive-campaigns.selectors";
import { selectAllScreenTours } from "store/modules/screen-tours/screen-tours.selectors";
import { selectAllSelfServicePortals } from "store/modules/self-service-portals/self-service-portals.selectors";
import { selectAllSendingDomains } from "store/modules/sending-domains/sending-domains.selectors";
import {
  selectAllAPIUsers,
  selectAllUsers,
} from "store/modules/users/users.selectors";
import {
  selectAutomationRules,
  selectCRMCustomProperties,
  selectContactTags,
  selectConversationCustomProperties,
  selectConversationTags,
  selectMacros,
  selectNewsfeedTypes,
  selectOrganization,
  selectOrganizationWideMessagePresetsX,
  selectSLATemplates,
  selectSubscriptionDetails,
  selectTicketTypes,
} from "store/modules/workspace/workspace.selectors";
import { stringArrayToSentence } from "utils/string-array-to-sentence";
import ProductsPage from "../products/products.page";
import { ConfigureBrandAndWorkspace } from "./brand-and-workspace/configure-brand-and-workspace";
import { useBrandAndWorkspaceConfig } from "./brand-and-workspace/use-brand-and-workspace-config";
import { ConfigureMacrosAndPresets } from "./macros-and-presets/configure-macros-and-presets";
import { ConfigurePowerSettings } from "./power-settings/configure-power-settings";
import { PowerSettingsOptions } from "./power-settings/power-settings-index";
import AddNewConnection from "./sections/add-new-connection.modal-registry";
import { SetupEmbeds } from "./setup-embeds/setup-embeds";
import { SetupEmbedsOptions } from "./setup-embeds/setup-embeds-index";
import { ConfigureTagsAndAttributes } from "./tags-and-attributes/configure-tags-and-attributes";
import { ManageTagsAndAttributesOuter } from "./tags-and-attributes/tags-and-attributes-child-outer";
import { TagsAndAttributesMenuLabelMap } from "./tags-and-attributes/tags-and-attributes-menu-label-map";

interface iConfigureMenuItem {
  section:
    | "SETUP"
    | "CHANNELS"
    | "BUILD"
    | "AUTOMATE"
    | "ENGAGE"
    | "ADMINISTRATE";
  id?: string;
  title: string;
  icon: JSX.Element;
  description: string;
  sub?: string;
  component?: any;
  perform?: ({ triggerTempModal, setSettings }: any) => any;
  searchKeywords?: string;
  subSections?: {
    label: string;
    path: string;
    activeConfig?: string;
    activeSection?: string;
    icon?: JSX.Element;
    description?: string;
    searchKeywords?: string;
    subSections?: {
      label: string;
      path: string;
      icon?: JSX.Element;
      description?: string;
      searchKeywords?: string;
      activeConfig?: string;
      activeSection?: string;
    }[];
  }[];
  childBreadcrumb?: (path: string) => JSX.Element;
  childComponent?: (path: string) => JSX.Element;
  isHidden?: boolean;
  isHiddenShallow?: boolean;
  isBeta?: boolean;
  ignoreSearch?: boolean;
}

export const useConfigurationMenu = () => {
  const ticketTypes = useSelector(selectTicketTypes);
  const allConnections = useSelector(selectAllConnections);
  const allSelfServicePortals = useSelector(selectAllSelfServicePortals);
  const allChatbotFlows = useSelector(selectAllChatBots);
  const allSLATemplates = useSelector(selectSLATemplates);
  const allNewsfeedTypes = useSelector(selectNewsfeedTypes);
  const allSendingDomains = useSelector(selectAllSendingDomains);
  const allMacros = useSelector(selectMacros);
  const allPresets = useSelector(selectOrganizationWideMessagePresetsX);
  const contactTags = useSelector(selectContactTags);
  const conversationTags = useSelector(selectConversationTags);
  const customCRMProperties = useSelector(selectCRMCustomProperties);
  const customChatProperties = useSelector(selectConversationCustomProperties);
  const allArticles = useSelector(selectAllArticles);
  const allKnowledgeDocuments = useSelector(selectAllKnowledgeDocuments);
  const allWorkflowAutomation = useSelector(selectAutomationRules);
  const allEmailSeries = useSelector(selectAllAutomationWorkflows);
  const allChatConnectors = useSelector(selectAllChatConnectors);
  const allSideBars = useMemo(
    () =>
      allChatConnectors
        .filter((chatConnector) => chatConnector.isActive)
        .filter((chatConnector) => {
          return chatConnector.type === "CONVERSATION_ACTION";
        }),
    [allChatConnectors],
  );
  const allChatActions = useMemo(
    () =>
      allChatConnectors
        .filter((chatConnector) => chatConnector.isActive)
        .filter((chatConnector) => {
          return chatConnector.type === "CONVERSATION_ACTION";
        }),
    [allChatConnectors],
  );

  // Apps and Integrations
  // Todo
  const allAPIUsers = useSelector(selectAllAPIUsers);
  const organization = useSelector(selectOrganization);
  // Connected Apps not in store
  const allConnectedAppsLength = useMemo(() => {
    // 18 Apps
    const slack =
      organization?.appData?.HELP_DESK?.slackNotifications?.isActive;
    const mailchimp =
      organization?.appData?.CRM?.mailChimpIntegration?.isActive;
    const shopify = organization?.appData?.HELP_DESK?.shopifyStore?.isActive;
    const otherAppsKeys = Object.keys(
      organization?.appData?.EXTERNAL_APPS || {},
    );
    const activeOtherApps = otherAppsKeys.filter(
      (appKey) => organization?.appData?.EXTERNAL_APPS?.[appKey]?.auth,
    );
    const allActiveApps = [slack, mailchimp, ...activeOtherApps].filter(
      (e) => e,
    ).length;
    return allActiveApps;
  }, [organization]);

  const allScreenTours = useSelector(selectAllScreenTours);

  const allMessageCampaigns = useSelector(selectAllCampaigns);
  const allProactiveCampaigns = useSelector(selectAllProactiveCampaigns);

  const allUsers = useSelector(selectAllUsers);
  const allUserGroups = useSelector(selectAllGroups);
  const subscriptionData = useSelector(selectSubscriptionDetails);

  const brandAndWorkspaceSettings = useBrandAndWorkspaceConfig();

  const configurationsMenu: iConfigureMenuItem[] = [
    {
      section: "SETUP",
      id: "MANAGE_CASE_TYPE",
      title: "Manage Trackers",
      icon: <MDIIcon icon={mdiFolderEditOutline} size="3rem" />,
      description: "Define support operation Trackers",
      sub: (() => {
        if (ticketTypes.length > 0) {
          return `${ticketTypes.length} types configured`;
        }
        return `Nothing configured here`;
      })(),
      component: ManageCaseTypes,
      searchKeywords:
        "bug report feature request customer support cases tickets",
      subSections: ticketTypes.map((type) => ({
        label: type.label,
        path: "",
      })),
      isBeta: true,
    },
    {
      section: "SETUP",
      id: "MANAGE_CHANNELS",
      title: "Manage Channels",
      icon: <MDIIcon icon={mdiConnection} size="3rem" />,
      description: "Connect and Manage Social Media Channels",
      sub: (() => {
        const uniqConnectionTypes = uniqBy(
          allConnections.filter((item) => item.metaData.isActive),
          (item) => item.type,
        ).length;
        const availableChannels = 5 - uniqConnectionTypes;
        if (allConnections.length > 0) {
          if (availableChannels > 0) {
            return `${allConnections.length} channel active; ${availableChannels} available`;
          }
          return `${allConnections.length} channel(s) active`;
        }
        return `You have not configured any channels yet`;
      })(),
      component: ManageChannels,
      searchKeywords: "manage connections add channel new connection",
      subSections: allConnections.map((connection) => ({
        label: connection.label,
        path: `/${connection.id}`,
        description: `Manage ${
          ConnectionTypeLabelMap[connection.type]
        } connection`,
        subSections: (() => {
          const getChannelMenu = (
            items: {
              label: string;
              description: string;
              icon: JSX.Element;
              section?: string;
            }[],
          ) => {
            return items.map((item) => ({
              label: `${connection.label} > ${item.label}`,
              description: item.description,
              icon: item.icon,
              path: "",
              activeConfig: item.label,
              activeSection: item.section,
            }));
          };
          switch (connection.type) {
            case "EMAIL": {
              return getChannelMenu(EmailChannelSettings);
            }
            case "EMAIL_V2": {
              return getChannelMenu(EmailV2ChannelSettings);
            }
            case "FACEBOOK": {
              return getChannelMenu(FBChannelSettings);
            }
            case "INSTAGRAM": {
              return getChannelMenu(IGChannelSettings);
            }
            case "TELEGRAM": {
              return getChannelMenu(TelegramChannelSetting);
            }
            case "WEB_CHAT": {
              return getChannelMenu(WidgetChannelSettings);
            }
            case "WHATS_APP_CLOUD": {
              return getChannelMenu(WhatsappCloudChannelSetting);
            }
            case "WHATS_APP_PRIVATE": {
              return getChannelMenu(WhatsappPrivateChannelSetting);
            }
          }

          return [];
        })(),
      })),
      childBreadcrumb: (path: string) => (
        <li className="flex items-center">
          <svg
            className="w-3 h-3 me-2 sm:me-4 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 12 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m7 9 4-4-4-4M1 9l4-4-4-4"
            />
          </svg>
          <ConnectionPill connectionId={path} withoutBg withoutPadding />
        </li>
      ),
      isHiddenShallow: true,
      childComponent: (path: string) => (
        <ManageChannelOuter connectionId={path} />
      ),
    },
    // {
    //   section: "SETUP",
    //   id: "SELF_SERVICE__PORTALS",
    //   title: "Self Service Portals",
    //   icon: <i className="ri-layout-top-line"></i>,
    //   description: "Configure knowledge base portals and collections",
    //   searchKeywords: "knowledge base portals KB collections",
    //   sub: (() => {
    //     if (allSelfServicePortals.length > 0) {
    //       return `${allSelfServicePortals.length} portal active`;
    //     }
    //     return `Nothing configured here`;
    //   })(),
    //   subSections: allSelfServicePortals.map((portal) => ({
    //     label: portal.label,
    //     path: `/`,
    //   })),
    //   component: ConfigureSelfServicePortals,
    // },
    // {
    //   section: "SETUP",
    //   id: "CHAT_BOT_FLOWS",
    //   title: "Chatbot Flows",
    //   icon: <i className="ri-robot-2-line"></i>,
    //   description: "Create Bot flows with no-code builder",
    //   searchKeywords: "chatbot bot",
    //   sub: (() => {
    //     if (allChatbotFlows.length > 0) {
    //       return `${allChatbotFlows.length} flows created`;
    //     }
    //     return `Nothing configured here`;
    //   })(),
    //   subSections: allChatbotFlows.map((flow) => ({
    //     label: flow.label,
    //     path: `/`,
    //   })),
    //      isHidden: true,
    //   component: ManageChatBots,
    // },
    {
      section: "SETUP",
      id: "SLA_TEMPLATES",
      title: "Manage SLA Templates",
      icon: <i className="ri-timer-line"></i>,
      description: "Manage sla templates that can be activated on chats",
      searchKeywords: "sla templates",
      sub: (() => {
        if (allSLATemplates.length > 0) {
          return `${allSLATemplates.length} templates created`;
        }
        return `Nothing configured here`;
      })(),
      subSections: allSLATemplates.map((template) => ({
        label: template.label,
        path: `/`,
      })),
      component: ManageSLATemplates,
    },
    {
      section: "SETUP",
      id: "NEWSFEED_TYPES",
      title: "Manage Newsfeed Types",
      icon: <i className="ri-megaphone-line"></i>,
      description: "Manage channels to send announcements",
      searchKeywords: "manage newsfeed types",
      sub: (() => {
        if (allNewsfeedTypes.length > 0) {
          return `${allNewsfeedTypes.length} newsfeed types created`;
        }
        return `Nothing configured here`;
      })(),
      subSections: allNewsfeedTypes.map((template) => ({
        label: template.label,
        path: `/`,
      })),
      component: ManageNewsfeedTypes,
    },
    {
      section: "SETUP",
      id: "SENDING_DOMAIN",
      title: "Manage Sending Domains",
      icon: <i className="ri-mail-send-line"></i>,
      description: "Manage channels to send announcements",
      searchKeywords: "manage sending domains",
      sub: (() => {
        if (allSendingDomains.length > 0) {
          return `${allSendingDomains.length} sending domains added`;
        }
        return `Nothing configured here`;
      })(),
      subSections: allSendingDomains.map((template) => ({
        label: template.domain,
        path: `/`,
      })),
      component: ManageSendingDomains,
    },
    {
      section: "SETUP",
      id: "MACROS_PRESETS",
      title: "Macros and Presets",
      icon: <MDIIcon icon={mdiBookCogOutline} size="3rem" />,
      description: "Setup presets and macros for faster replies",
      sub: (() => {
        return `${allMacros.length} macros; ${allPresets?.presets?.length} presets`;
      })(),
      subSections: [
        {
          label: "Manage Presets",
          path: ``,
          description: "Create & Manage presets of commonly used messages",
          icon: <i className="ri-booklet-line"></i>,
        },
        {
          label: "Manage Macros",
          path: ``,
          description:
            "Create & Manage Macros that help agents become more productive",
          icon: <MDIIcon icon={mdiCogPlay} />,
        },
      ],
      component: ConfigureMacrosAndPresets,
    },
    {
      section: "SETUP",
      id: "TAGS_ATTRIBUTES",
      title: "Tags and Attributes",
      icon: <MDIIcon icon={mdiDatabaseSettingsOutline} size="3rem" />,
      description: "Setup chat tags, crm tags, chat properties, etc",
      component: ConfigureTagsAndAttributes,
      sub: (() => {
        return `${
          (contactTags || []).length + (conversationTags || []).length
        } tags; ${
          (customCRMProperties || []).length +
          (customChatProperties || []).length
        } attributes;`;
      })(),
      subSections: [
        {
          label: TagsAndAttributesMenuLabelMap["chat-tags"],
          description: "Tags that can be added to Chats",
          searchKeywords: "chat tags conversation tags",
          path: "/chat-tags",
        },
        {
          label: TagsAndAttributesMenuLabelMap["crm-tags"],
          description: "Tags that can be added to contact records",
          path: "/crm-tags",
          searchKeywords: "contact tags customer tags",
        },
        {
          label: TagsAndAttributesMenuLabelMap["chat-properties"],
          description:
            "Custom properties allow you to capture additional data in conversations",
          path: "/chat-properties",
          searchKeywords: "chat attributes conversation attributes",
        },
        {
          label: TagsAndAttributesMenuLabelMap["crm-properties"],
          description:
            "Custom properties allow you to capture additional data in your CRM",
          path: "/crm-properties",
          searchKeywords: "contact attributes customer ",
        },
      ],
      childBreadcrumb: (path: string) => (
        <li className="flex items-center">
          <svg
            className="w-3 h-3 me-2 sm:me-4 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 12 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m7 9 4-4-4-4M1 9l4-4-4-4"
            />
          </svg>
          {TagsAndAttributesMenuLabelMap[path]}
        </li>
      ),
      childComponent: (path: string) => (
        <ManageTagsAndAttributesOuter settings={path as any} />
      ),
    },
    {
      section: "SETUP",
      id: "EMBEDS",
      title: "Embeds",
      // icon: <MDIIcon icon={mdiPuzzleOutline} size="3rem" />,
      icon: <MDIIcon icon={mdiCodeBlockTags} size="3rem" />,
      description:
        "Learn to use embeds - Chat Widget, Knowledge Base and Trackers",
      component: SetupEmbeds,
      subSections: SetupEmbedsOptions.map((item) => ({ ...item, path: "" })),
    },
    {
      section: "SETUP",
      id: "POWER_SETTINGS",
      title: "Power Settings",
      icon: <MDIIcon icon={mdiWrenchCog} size="3rem" />,
      description: "Access Configurations, Inbox Tweaking etc",
      component: ConfigurePowerSettings,
      subSections: PowerSettingsOptions.map((item) => ({ ...item, path: "" })),
    },
    {
      section: "SETUP",
      id: "PRODUCTS",
      title: "Manage Products",
      icon: <i className="ri-store-line"></i>,
      description: "Manage Products",
      component: ProductsPage,
      isHidden: true,
      isBeta: true,
    },
    {
      section: "SETUP",
      id: "PIPE_LINES",
      title: "Manage Pipelines",
      icon: <i className="ri-git-branch-line rotate-90"></i>,
      description:
        "Create & Manage Pipelines that help organize and track progress",
      component: ManagePipelines,
      isHidden: true,
      isBeta: true,
    },
    ...[
      ...allConnections.map((connection) => ({
        section: "CHANNELS" as const,
        id: `CHANNEL_${connection.id}`,
        title: connection.label,
        icon: <i className={ConnectionIcons[connection.type]}></i>,
        description: (() => {
          if (connection.type === "EMAIL") {
            return `Configure mailbox, access, email workflows, etc`;
          }
          if (connection.type === "EMAIL_V2") {
            return `Configure mailbox, access, email workflows, etc`;
          }
          if (connection.type === "FACEBOOK") {
            return `Manage connections, routing, and post integration`;
          }
          if (connection.type === "INSTAGRAM") {
            return `Manage connections, routing, and post integration`;
          }
          if (connection.type === "TELNYX") {
            return `Incoming call configurations, sms routing, etc`;
          }
          if (connection.type === "WHATS_APP_CLOUD_NATIVE") {
            return `Chat routing, access, user limits, etc.`;
          }
          if (connection.type === "WEB_CHAT") {
            return `Configure appearance and functionalities, etc`;
          }
          return `${ConnectionTypeLabelMap[connection.type]}`;
        })(),
        sub: (() => {
          if (connection?.metaData?.hasError) {
            return `Connection has a problem`;
          }
          return `Active`;
        })(),
        perform: ({ setSettings }) => {
          setSettings(`MANAGE_CHANNELS/${connection.id}`);
        },
        searchKeywords: "",
        ignoreSearch: true,
      })),
      {
        section: "CHANNELS" as const,
        id: "ADD_CHANNEL",
        title: "New Channel",
        icon: <i className="ri-function-add-line"></i>,
        description: "Add a new support channel",
        sub: (() => {
          const uniqConnectionTypes = uniqBy(
            allConnections.filter((item) => item.metaData.isActive),
            (item) => item.type,
          ).length;
          const availableChannels = 5 - uniqConnectionTypes;
          if (availableChannels > 0) {
            return `${availableChannels} more channels available`;
          }
          return `Live Chat, Email, SMS, etc`;
        })(),
        perform: ({ triggerTempModal }) => {
          triggerTempModal(AddNewConnection, {});
        },
        searchKeywords: "Add channel new connection",
        ignoreSearch: true,
      },
    ],

    // {
    //   section: "BUILD",
    //   title: "KnowledgeBase Articles",
    //   id: "KB_ARTICLES",
    //   icon: <MDIIcon icon={mdiFolderFileOutline} size="3rem" />,
    //   description: "Build and manage help desk articles",
    //   sub: (() => {
    //     if (allArticles.length > 0) {
    //       return `${allArticles.length} articles added`;
    //     }
    //     return `start adding articles`;
    //   })(),
    //   perform: ({ triggerTempModal }) => {
    //     triggerTempModal(KnowledgeBaseManageArticles, {});
    //   },
    // },
    // {
    //   section: "BUILD",
    //   id: "KNOWLEDGE_KIT",
    //   title: "AI Knowledge KIT",
    //   icon: <MDIIcon icon={mdiFolderStarOutline} size="3rem" />,
    //   description: "Build knowledge repository for AI Assistants",
    //   sub: (() => {
    //     if (allKnowledgeDocuments.length > 0) {
    //       return `${allKnowledgeDocuments.length} articles added`;
    //     }
    //     return `start adding articles`;
    //   })(),
    //   subSections: [
    //     {
    //       label: "Knowledge Documents",
    //       description:
    //         "Empower AI Assistants with Knowledge Documents for Informed and Accurate Response",
    //       path: "",
    //     },
    //     {
    //       label: "Magic Assistant",
    //       path: "Create and Manage AI Assistants",
    //     },
    //   ],
    //   component: ManageKnowledgeDocuments,
    // },
    {
      section: "AUTOMATE",
      id: "WORKFLOW_AUTOMATION",
      title: "Workflow Automations",
      searchKeywords:
        "inbox automation auto close extract unsubscribe followup email",
      icon: <MDIIcon icon={mdiCogPlay} size="3rem" />,
      description: "Automate repetitive tasks for efficiency",
      sub: (() => {
        if (allWorkflowAutomation.length > 0) {
          return `${allWorkflowAutomation.length} rules created`;
        }
        return `create your first rule`;
      })(),
      subSections: allWorkflowAutomation.map((rule) => ({
        label: rule.label,
        description: "configure workflow automation",
        path: ``,
      })),
      component: ManageAutomationRules,
      isBeta: true,
    },
    {
      section: "AUTOMATE",
      title: "Apps and Integrations",
      id: "APPS_AND_INTEGRATIONS",
      searchKeywords: "zapier webhooks api integration api documentation",
      icon: <i className="ri-apps-line"></i>,
      description: "Integrate with third-party apps and tools",
      sub: (() => {
        if (allConnectedAppsLength > 0) {
          return `${allConnectedAppsLength} active`;
        }
        return `18 apps available`;
      })(),
      subSections: flattenDeep(
        allIntegrations.map((section) =>
          section.apps.map((app) => ({
            label: `Apps and Integrations > ${app.name}`,
            description: app.description,
            path: "",
            activeConfig: app.name,
            icon: app.logo,
          })),
        ),
      ),
      component: AppsAndIntegrations,
    },
    {
      section: "AUTOMATE",
      id: "SIDE_BAR_PANELS",
      title: "DIY Sidebar Panels",
      icon: <MDIIcon icon={mdiDockRight} size="3rem" />,
      description:
        "Integrate with your own internal systems with side bar panels",
      sub: (() => {
        if (allSideBars.length > 0) {
          return `${allSideBars.length} panels active`;
        }
        return undefined;
      })(),
      subSections: allSideBars.map((panel) => ({
        label: panel.label,
        description: "configure sidebar panel",
        path: ``,
      })),
      component: ManageChatSidePanel,
      isBeta: true,
    },
    {
      section: "AUTOMATE",
      id: "CUSTOM_CHAT_ACTIONS",
      title: "Custom Chat Actions",
      icon: <MDIIcon icon={mdiRayStartArrow} size="3rem" />,
      description:
        "Integrate with your own internal systems with side bar panels",
      sub: (() => {
        if (allChatActions.length > 0) {
          return `${allChatActions.length} actions active`;
        }
        return undefined;
      })(),
      subSections: allChatActions.map((action) => ({
        label: action.label,
        description: "configure custom chat action",
        path: ``,
      })),
      component: ManageChatActions,
      isBeta: true,
    },
    // {
    //   section: "ENGAGE",
    //   title: "Email Series",
    //   id: "EMAIL_SERIES",
    //   icon: <MDIIcon icon={mdiEmailMultipleOutline} size="3rem" />,
    //   description: "Send targeted email campaigns to users",
    //   sub: (() => {
    //     if (allEmailSeries.length > 0) {
    //       return `${allEmailSeries.length} series created`;
    //     }
    //     return "create your first series";
    //   })(),
    //   subSections: allEmailSeries.map((series) => ({
    //     label: series.label,
    //     description: "configure email series",
    //     path: ``,
    //   })),
    //   component: ManageSequences,
    //   isBeta: true,
    // },
    // {
    //   section: "ENGAGE",
    //   title: "Product Tours",
    //   id: "PRODUCT_TOURS",
    //   icon: <i className="ri-cursor-line"></i>,
    //   description: "Provide interactive tours to guide users",
    //   sub: (() => {
    //     if (allScreenTours.length > 0) {
    //       return `${allScreenTours.length} tours created`;
    //     }
    //     return "create your first tour";
    //   })(),
    //   subSections: allScreenTours.map((tour) => ({
    //     label: tour.name,
    //     description: "configure tour",
    //     path: ``,
    //   })),
    //   component: ManageProductTours,
    //   isBeta: true,
    // },
    // {
    //   section: "ENGAGE",
    //   title: "Email Campaigns",
    //   id: "MESSAGE_CAMPAIGNS",
    //   icon: <i className="ri-mail-send-line"></i>,
    //   description: "Engage users with targeted email campaigns",
    //   sub: (() => {
    //     if (allMessageCampaigns.length > 0) {
    //       return `${allMessageCampaigns.length} campaigns created`;
    //     }
    //     return "create your first campaign";
    //   })(),
    //   component: CampaignsPage,
    //   isBeta: true,
    // },
    // {
    //   section: "ENGAGE",
    //   id: "PROACTIVE_SUPPORT",
    //   title: "Proactive Support",
    //   icon: <MDIIcon icon={mdiHandshake} size="3rem" />,
    //   description: "Anticipate and address customer needs before they arise",
    //   component: ManageProactiveCampaigns,
    //   sub: (() => {
    //     if (allProactiveCampaigns.length > 0) {
    //       return `${allProactiveCampaigns.length} campaigns created`;
    //     }
    //     return "create your first campaign";
    //   })(),
    //   subSections: allProactiveCampaigns.map((campaign) => ({
    //     label: campaign.label,
    //     description: "configure proactive campaign",
    //     path: ``,
    //   })),
    //   isBeta: true,
    // },
    // {
    //   section: "ENGAGE",
    //   id: "FORMS",
    //   title: "Forms",
    //   icon: <i className="ri-file-text-line"></i>,
    //   description: "create custom form to capture data",
    //   component: ManageDynamicFormsX,
    //   isHidden: true,
    //   isBeta: true,
    // },
    {
      section: "ADMINISTRATE",
      id: "BRAND_AND_WORKSPACE",
      title: "Brand and Workspace",
      icon: <i className="ri-building-line"></i>,
      description: "Default branding, country, industry, mailing address, etc",
      component: ConfigureBrandAndWorkspace,
      sub: (() => {
        const missingSettings = brandAndWorkspaceSettings.filter(
          (item) => !item.isConfigured,
        );
        if (missingSettings.length > 0) {
          return `Missing - ${stringArrayToSentence(
            missingSettings.map((item) => item.label),
          )}`;
        }
        return undefined;
      })(),
      subSections: brandAndWorkspaceSettings.map((setting) => ({
        label: setting.label,
        path: ``,
      })),
    },
    {
      section: "ADMINISTRATE",
      id: "USER_MANAGEMENT",
      title: "User Management",
      icon: <i className="ri-group-line"></i>,
      description:
        "Add and remove user accounts, invite users, set user roles, etc",
      sub: (() => {
        const activeUsers = allUsers.filter(
          (user) => user.metaData.isActive && !user.metaData.isAPIUser,
        );
        return `${activeUsers.length} users`;
      })(),
      component: ManageUsers,
    },
    {
      section: "ADMINISTRATE",
      id: "TEAMS_AND_DEPARTMENTS",
      title: "Teams and Departments",
      icon: <i className="ri-team-line"></i>,
      description: "User groups to reflect how your business functions",
      sub: (() => {
        return `${allUserGroups.length} groups active`;
      })(),
      subSections: [
        {
          label: "User Groups",
          path: "",
        },
        {
          label: "Teams",
          path: "",
        },
        {
          label: "Departments",
          path: "",
        },
      ],
      component: ManageUserGroups,
    },
    {
      section: "ADMINISTRATE",
      id: "BILLING_AND_SUBSCRIPTION",
      title: "Billing and Subscription",
      icon: <i className="ri-bank-card-2-line"></i>,
      description: "View and change your billing details.",
      sub: (() => {
        return `${
          GetPricingPlanById(subscriptionData?.planId || "FREE") || ""
        }`;
      })(),
      subSections: [
        {
          label: "Change Subscription",
          path: "",
        },
        {
          label: "Past Invoices",
          path: "",
        },
      ],
      component: BillingPortal,
    },
  ];
  return configurationsMenu;
};
