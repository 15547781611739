import { CountryCodeMap } from "components/pages/live-view/components/country-code-map";
import _ from "lodash";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { store } from "store/store";
import { getMobileId } from "utils/clean-mobile-number";
import { escapeRegExp } from "utils/escape-regex-search-string";
import { ExtractPhoneNumbers } from "utils/extract-phone-numbers";
import { isEmail } from "utils/is-email";
import { toTitleCase } from "utils/to-title-case";

const use$textSearch = true;

export function ConversationSearchQueryBuilder(term: string) {
  if (use$textSearch) {
    return ConversationSearchQueryBuilderWithTextSearch(term);
  }
  return ConversationSearchQueryBuilderWithRegex(term);
}

export function ConversationSearchQueryBuilderWithRegex(term: string) {
  term = term.trim();
  // 1) User could enter contact Name
  // 2) User could enter contact email
  // 3) User could enter contact mobile
  // 4) User could enter email subject

  // Advanced
  // 5) User could enter a message in the thread

  //* Not Implemented Yet
  // User could enter special keywords
  //      tag:sale
  //      email:abc@abc.com

  const words = term.split(" ");

  // 1) User could enter contact Name
  const searchByContactNameQuery =
    words.length === 1
      ? [
          {
            "contact.data.firstName": {
              $regex: `${escapeRegExp(words[0])}`,
              $options: "i",
            },
          },
          {
            "contact.data.lastName": {
              $regex: `${escapeRegExp(words[0])}`,
              $options: "i",
            },
          },
        ]
      : words.length === 2
      ? [
          {
            $and: [
              {
                "contact.data.firstName": {
                  $regex: `${escapeRegExp(words[0])}`,
                  $options: "i",
                },
              },
              {
                "contact.data.lastName": {
                  $regex: `${escapeRegExp(
                    words.slice(1).join(",").replace(/,/g, " "),
                  )}`,
                  $options: "i",
                },
              },
            ],
          },
          {
            "contact.data.firstName": {
              $regex: `${escapeRegExp(words.join(",").replace(/,/g, " "))}`,
              $options: "i",
            },
          },
        ]
      : [
          {
            $and: [
              {
                "contact.data.firstName": {
                  $regex: `${escapeRegExp(
                    words.slice(0, 2).join(",").replace(/,/g, " "),
                  )}`,
                  $options: "i",
                },
              },
              {
                "contact.data.lastName": {
                  $regex: `${escapeRegExp(
                    words.slice(2).join(",").replace(/,/g, " "),
                  )}`,
                  $options: "i",
                },
              },
            ],
          },
          {
            $and: [
              {
                "contact.data.firstName": {
                  $regex: `${escapeRegExp(
                    words.slice(0, 1).join(",").replace(/,/g, " "),
                  )}`,
                  $options: "i",
                },
              },
              {
                "contact.data.lastName": {
                  $regex: `${escapeRegExp(
                    words.slice(1).join(",").replace(/,/g, " "),
                  )}`,
                  $options: "i",
                },
              },
            ],
          },
        ];

  // 2) User could enter contact email
  const searchByEmailQuery = {
    "contact.data.primaryEmail": {
      $regex: escapeRegExp(term.toLowerCase()),
      // $options: "i",
    },
  };

  // 3) User could enter contact mobile
  const mobileId = getMobileId(term).replace("+", "").trim();
  const searchByMobileQuery = mobileId
    ? {
        "contact.data.primaryMobile": {
          $regex: escapeRegExp(mobileId),
          $options: "i",
        },
      }
    : undefined;

  // 4) User could enter email subject
  const searchBySubjectQuery = {
    subject: {
      $regex: escapeRegExp(term),
      $options: "i",
    },
  };

  // Search byID
  const searchByIdQuery = {
    id: term,
  };

  if (isEmail(term)) {
    return {
      $or: _.flattenDeep([searchByEmailQuery]),
    };
  }

  if (term.length === 30 && term.split("-").length === 5) {
    return {
      $or: _.flattenDeep([searchByIdQuery]),
    };
  }

  const searchBySimpleIdQuery = {
    "data.simpleId": term.replace("#", ""),
  };

  if (term.length === 6 && term.charAt(0) === "#") {
    return {
      $or: _.flattenDeep([searchBySimpleIdQuery]),
    };
  }

  if (term.includes("tag:")) {
    const tagToSearch = term.replace("tag:", "").trim();

    const filterByTag = {
      tags: {
        $in: [tagToSearch, tagToSearch.toLowerCase(), toTitleCase(tagToSearch)],
      },
    };
    return {
      $or: _.flattenDeep([filterByTag]),
    };
  }

  return {
    $or: _.flattenDeep(
      [
        ...searchByContactNameQuery,
        searchByEmailQuery,
        searchByMobileQuery,
        searchBySubjectQuery,
      ].filter((e) => e),
    ),
  };
}

export function ConversationSearchQueryBuilderWithTextSearch(term: string) {
  term = term.trim();

  if (term.length === 30 && term.split("-").length === 5) {
    // Search byID
    const searchByIdQuery = {
      id: term,
    };
    return searchByIdQuery;
  }

  if (isEmail(term)) {
    const searchByEmailQuery = {
      "contact.data.primaryEmail": {
        $regex: `^${escapeRegExp(term.toLowerCase())}$`,
        // $options: "i", // No need
      },
    };

    return searchByEmailQuery;
  }

  if (term.length === 6 && term.charAt(0) === "#") {
    const searchBySimpleIdQuery = {
      "data.simpleId": term.replace("#", ""),
    };
    return searchBySimpleIdQuery;
  }

  if (term.includes("tag:")) {
    const tagToSearch = term.replace("tag:", "").trim();
    const filterByTag = {
      tags: {
        $in: [tagToSearch, tagToSearch.toLowerCase(), toTitleCase(tagToSearch)],
      },
    };
    return filterByTag;
  }

  const phoneNumbers: string[] = ExtractPhoneNumbers(term);
  const country = selectOrganization(store.getState())?.data?.country;
  const countryCode = CountryCodeMap[country || ""]?.dialCode;

  if (phoneNumbers.length > 0) {
    for (const mobile of phoneNumbers) {
      //* Async Not Supported
      // const mobileId = await cleanMobileNumber(mobile,country);
      const withoutZero = mobile.startsWith("0") ? mobile.slice(1) : mobile;
      const mobileId = getMobileId(withoutZero, countryCode);
      term += ` ${mobileId}`;
    }
  }

  return {
    $text: {
      $search: term,
    },
  };
}
function generateRegexKeywordSearch(searchTerm: string) {
  const keywords = searchTerm.split(" ");
  const escapedKeywords = keywords.map((keyword) =>
    keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
  );
  const regexString = escapedKeywords.join(".*");
  return regexString;
}

export const BuildChatMessageSearchQuery = (term: string) => {
  term = term.trim();

  if (term.split(" ").length > 3) {
    term = term.split(" ").slice(0, 3).join(" ");
  }

  // Todo: Both works - Got to check which one is best
  // const searchByMessageTextQuery = {
  //   message: {
  //     $regex: generateRegexKeywordSearch(escapeRegExp(term)),
  //     $options: "i",
  //   },
  // };

  const searchByMessageTextQuery = {
    $text: {
      $search: term,
    },
  };

  return searchByMessageTextQuery;
};
