import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddDynamicFormModal = React.lazy(
  () => import("./add-dynamic-form.modal"),
);

const AddNewForm: iModalProps<typeof AddDynamicFormModal, {}> = {
  name: "add-new-form",
  component: GenerateModalWithSuspense(AddDynamicFormModal),
};

export default AddNewForm;
