import { mdiDatabaseSettingsOutline } from "@mdi/js";
import { Divider } from "antd";
import { MDIIcon } from "components/common/mdi-icon";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectCRMCustomProperties,
  selectContactTags,
  selectConversationCustomProperties,
  selectConversationTags,
} from "store/modules/workspace/workspace.selectors";
import { TagsAndAttributesMenuLabelMap } from "./tags-and-attributes-menu-label-map";

export const ConfigureTagsAndAttributes = ({ setSettings }) => {
  const contactTags = useSelector(selectContactTags);
  const conversationTags = useSelector(selectConversationTags);
  const customCRMProperties = useSelector(selectCRMCustomProperties);
  const customChatProperties = useSelector(selectConversationCustomProperties);

  const tagsMenu = useMemo(() => {
    return [
      {
        id: "chat-tags",
        title: TagsAndAttributesMenuLabelMap["chat-tags"],
        icon: <i className="ri-price-tag-3-line"></i>,
        description: "Tags that can be added to Chats",
        sub: `${conversationTags.length} configured`,
      },
      {
        id: "crm-tags",
        title: TagsAndAttributesMenuLabelMap["crm-tags"],
        icon: <i className="ri-price-tag-3-line"></i>,
        description: "Tags that can be added to contact records",
        sub: `${contactTags.length} configured`,
      },
    ];
  }, [contactTags.length, conversationTags.length]);
  const attributesMenu = useMemo(() => {
    return [
      {
        id: "chat-properties",
        title: TagsAndAttributesMenuLabelMap["chat-properties"],
        icon: <i className="ri-database-2-line"></i>,
        description:
          "Custom properties allow you to capture additional data in conversations",
        sub: `${customChatProperties.length} configured`,
      },
      {
        id: "crm-properties",
        title: TagsAndAttributesMenuLabelMap["crm-properties"],
        icon: <i className="ri-database-2-line"></i>,
        description:
          "Custom properties allow you to capture additional data in your CRM",
        sub: `${customCRMProperties.length} configured`,
      },
    ];
  }, [customCRMProperties.length, customChatProperties.length]);
  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin overflow-x-hidden  dark:bg-gray-950">
      <div className="flex flex-col w-full py-8 rounded-xl rounded-t-none bg-gray-100 dark:bg-gray-900">
        <div className="">
          <div className="font-bold text-2xl text-center">
            {/* <i className="ri-tools-line"></i> */}
            <MDIIcon icon={mdiDatabaseSettingsOutline} size="3rem" /> Tags and
            Attributes
          </div>
        </div>
      </div>

      <div className="p-8">
        <Divider orientation="left" className="font-bold">
          Tags
        </Divider>
        <div className="flex flex-row flex-wrap gap-4 px-8">
          {tagsMenu.map((item) => (
            <div
              key={item.title}
              className="flex flex-row bg-gray-150 hover:bg-gray-200 dark:hover:bg-gray-900 rounded-lg transition-all cursor-pointer p-4 gap-2"
              style={{ width: 300 }}
              onClick={() => {
                setSettings(`TAGS_ATTRIBUTES/${item.id}`);
              }}
            >
              <div className="text-5xl">{item.icon}</div>
              <div className="flex flex-col">
                <div className="">{item.title}</div>
                <div className="text-sm text-gray-600 mb-2">
                  {item.description}
                </div>
                <div className="text-xs">{item.sub}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="p-8">
        <Divider orientation="left" className="font-bold">
          Attributes
        </Divider>
        <div className="flex flex-row flex-wrap gap-4 px-8">
          {attributesMenu.map((item) => (
            <div
              key={item.title}
              className="flex flex-row bg-gray-150 hover:bg-gray-200 dark:hover:bg-gray-900 rounded-lg transition-all cursor-pointer p-4 gap-2"
              style={{ width: 300 }}
              onClick={() => {
                setSettings(`TAGS_ATTRIBUTES/${item.id}`);
              }}
            >
              <div className="text-5xl">{item.icon}</div>
              <div className="flex flex-col">
                <div className="">{item.title}</div>
                <div className="text-sm text-gray-600 mb-2">
                  {item.description}
                </div>
                <div className="text-xs">{item.sub}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </StyledScrollArea>
  );
};
