export const FeatureThatCanBeLimited = [
  {
    key: "CHAT_FILTERING",
    label: "Chat Filtering",
  },
  {
    key: "CRM_ACCESS",
    label: "CRM Access",
  },
  {
    key: "BULK_MESSAGE_SENDING",
    label: "Bulk Message Sending",
  },
  {
    key: "CHANGE_SELF_EMAIL",
    label: "Change Self Email",
  },
];
