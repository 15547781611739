import { useMemo, useState } from "react";

export const useSearch = <T>(originalData: T[]) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = useMemo(() => {
    if (!searchTerm) {
      return originalData;
    }
    return originalData.filter((o) => {
      return Object.keys(o as any).some(
        (k) =>
          typeof o[k] === "string" &&
          String(o[k]).toLowerCase().includes(searchTerm.toLowerCase()),
      );
    });
  }, [originalData, searchTerm]);

  return {
    filteredData,
    searchTerm,
    setSearchTerm,
  };
};
