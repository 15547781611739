import { HQLayout } from "components/pages/hq/layout/hq-layout/hq-layout.template";
import { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";

import { bounceTransition, mapStyles } from "router-helpers";
import { LoadingView } from "router-loading-view";
import { selectHQAccessToken } from "store/modules/hq/hq.selectors";
import { AuthenticatedRoutesHQ, UnauthenticatedRoutesHQ } from "./navigation";
import { GetRouteMeta } from "./route-helper";

export const HQRouter = () => {
  const isHQAuthenticated = useSelector(selectHQAccessToken);

  const location = useLocation();

  const Container =
    GetRouteMeta(
      isHQAuthenticated ? AuthenticatedRoutesHQ : UnauthenticatedRoutesHQ,
      location?.pathname,
    )?.layout || HQLayout;

  useEffect(() => {
    if (!isHQAuthenticated) {
      UnauthenticatedRoutesHQ.forEach((item) => {
        (item.component as any).preload && (item.component as any).preload();
      });
    }
  }, [isHQAuthenticated]);
  if (isHQAuthenticated) {
    return (
      <>
        <Container>
          <Suspense fallback={LoadingView}>
            <Switch>
              {AuthenticatedRoutesHQ.map((item) => (
                <Route
                  path={item.path}
                  component={item.component}
                  key={item.path}
                  exact
                ></Route>
              ))}
              <Route
                path="*"
                component={({ location }) => (
                  <Redirect
                    to={{
                      ...location,
                      pathname: `/hq/organizations`,
                    }}
                  />
                )}
              />
            </Switch>
          </Suspense>
        </Container>
      </>
    );
  }
  return (
    <Suspense fallback={LoadingView}>
      <AnimatedSwitch
        atEnter={bounceTransition.atEnter}
        atLeave={bounceTransition.atLeave}
        atActive={bounceTransition.atActive}
        mapStyles={mapStyles}
        className="route-wrapper"
      >
        {UnauthenticatedRoutesHQ.map((item) => (
          <Route
            path={item.path}
            component={item.component}
            key={item.path}
          ></Route>
        ))}

        <Route
          path="*"
          component={({ location }) => (
            <Redirect
              to={{
                ...location,
                pathname: `/hq`,
              }}
            />
          )}
        />
      </AnimatedSwitch>
    </Suspense>
  );
};
