import { iBlockTypes } from "@sdk/chat-connectors/chat-connectors.models";
import { uuidv4 } from "utils/generate-uuid";
import { GenerateEmptyUrlConfigBlock } from "./generate-empty-url-config";

export const GenerateBlock = (type: iBlockTypes) => {
  switch (type) {
    case "TEXT":
    case "HEADING":
    case "MARKDOWN": {
      return {
        id: uuidv4(),
        type: type,
        label: "",
      };
    }
    case "IFRAME": {
      return {
        id: uuidv4(),
        type: type,
        label: "",
        url: GenerateEmptyUrlConfigBlock(),
        width: "100%",
        height: "100%",
      };
    }
    case "SIMPLE_TEXT_DATA": {
      return {
        id: uuidv4(),
        type: type,
        url: GenerateEmptyUrlConfigBlock(),
        textTemplate: "",
      };
    }
    case "SIMPLE_ARRAY_TABLE":
    case "SIMPLE_KEY_VALUE": {
      return {
        id: uuidv4(),
        type: type,
        url: GenerateEmptyUrlConfigBlock(),
      };
    }
    case "SIMPLE_ACTION_BUTTON": {
      return {
        id: uuidv4(),
        buttonLabel: "Do Something",
        type: type,
        url: GenerateEmptyUrlConfigBlock(),
      };
    }
    default: {
      return {
        id: uuidv4(),
        type: "TEXT",
        label: "",
      };
    }
  }
};
