import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ConfigureSelfServicePortalModal = React.lazy(
  () => import("./configure-self-service-portals.modal"),
);

const ConfigureSelfServicePortal: iModalProps<
  typeof ConfigureSelfServicePortalModal,
  {
    portalId: string;
  }
> = {
  name: "configure-kb-portal",
  component: GenerateModalWithSuspense(ConfigureSelfServicePortalModal),
};

export default ConfigureSelfServicePortal;
