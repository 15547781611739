import { iPipelineCard } from "@sdk/pipeline-cards/pipeline-cards-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { pipelineCardsDefaultQueryConfig } from "./pipeline-cards-default-query";

export const {
  slice: PipelineCardsSlice,
  reducers: PipelineCardsSliceReducer,
  actions: {
    setEntityQueryResults: setPipelineCardsQueryResults,
    setEntity: setPipelineCard,
    setEntities: setPipelineCards,
    patchEntity: patchPipelineCard,
    addNewEntity: addNewPipelineCard,
    setEntityFetchingStatus: setPipelineCardFetchingStatus,
    setEntitiesFetchingStatus: setPipelineCardsFetchingStatus,
    setEntityFailedStatus: setPipelineCardFailedStatus,
    setEntityQueryError: setPipelineCardsQueryError,
    resetQuery: resetPipelineCardsQuery,
    setQueryList: setPipelineCardQueryList
  }
} = CreateEntitySlice<iPipelineCard>({
  sliceName: "pipelineCards",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...pipelineCardsDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
