import _ from "lodash";

export const userHasConnectionGroupPermission = (
  connectionUserGroups?: string[],
  accessibleUserGroups: string[] = []
) => {
  return (
    !connectionUserGroups ||
    connectionUserGroups.length === 0 ||
    _.intersection(accessibleUserGroups, connectionUserGroups).length > 0
  );
};
