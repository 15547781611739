import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iAutomationWorkflowStep } from "@sdk/automation-workflows/automation-workflows-model";
import React from "react";

const StepEditorModal = React.lazy(() => import("./step-editor.modal"));

const SequenceStepEditor: iModalProps<
  typeof StepEditorModal,
  {
    step?: iAutomationWorkflowStep;
    onSave: (step: iAutomationWorkflowStep) => any;
  }
> = {
  name: "sequence-step-editor",
  component: GenerateModalWithSuspense(StepEditorModal),
};

export default SequenceStepEditor;
