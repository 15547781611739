import { createSelector } from "@reduxjs/toolkit";
import { iConversation } from "@sdk/conversations/conversations.models";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";
import {
  selectMessagesMap,
  selectUnreadCount
} from "../messages/messages.selectors";
import { DefaultConversationQueryConfig } from "../ui-state/ui-state.slice";
import { selectCurrentUserId } from "../users/users.selectors";

export const {
  selectQueryMap: selectConversationQueryMap,
  selectEntityMap: selectConversationsMap,
  selectQueryMapAll: selectConversationsMapAll,
  selectQueryAllList: selectConversationsAllList,
  selectAllEntities: selectAllConversations,
  selectAllEntitiesQuery: selectAllConversationsQuery,
  selectEntityById: selectConversationById,
  selectEntityQuery: selectConversationQuery,
  selectEntityQueryList: selectConversationQueryList,
  selectEntityQueryWithoutPopulation: selectConversationQueryWithIds
} = CreateSelectorForEntities<iStore, iConversation>({
  sliceName: "conversations"
});

export const selectConversationProperties = <T>(
  conversationId: string,
  selector: (conversation?: iConversation) => T
) => createSelector([selectConversationById(conversationId)], selector);

export const selectConversationExists = (conversationId: string) =>
  createSelector([selectConversationById(conversationId)], conversation =>
    Boolean(conversation)
  );

const calculateUnreadOfConversation = (
  conversation: iConversation,
  unReadCount: number,
  currentUserId: string
) => {
  const userWatermark = conversation.metaData.readWatermarks.byUser
    ? conversation.metaData.readWatermarks.byUser[currentUserId]
    : 0 || 0;
  const teamWatermark = conversation.metaData.readWatermarks.team || 0;
  const isUserMember = _.find(conversation.members, {
    userId: currentUserId
  });
  const hasUnread = isUserMember
    ? userWatermark < conversation.metaData.lastMessage.timestamp
    : teamWatermark < conversation.metaData.lastMessage.timestamp;
  return {
    hasUnread,
    count: unReadCount
  };
};

export const selectUnreadMessages = (conversationId: string) =>
  createSelector(
    [
      selectConversationById(conversationId),
      selectUnreadCount(conversationId),
      selectCurrentUserId
    ],
    calculateUnreadOfConversation
  );

export const selectAllUnreadMessages = (state: iStore) =>
  createSelector(
    [selectConversationsMap, selectMessagesMap],
    (conversationsMap, messagesMap) => {
      const conversations = Object.keys(conversationsMap);
      return conversations.reduce(
        (acc, conversationId) => {
          const { hasUnread, count } = selectUnreadMessages(conversationId)(
            state
          );
          acc.hasUnread = acc.hasUnread || hasUnread;
          acc.count = acc.count + count;
          return acc;
        },
        {
          hasUnread: false,
          count: 0
        }
      );
    }
  )(state);

export const selectUnreadMessagesFoQuery = (query: string) =>
  createSelector(
    [
      selectConversationsMap,
      selectMessagesMap,
      selectConversationQueryList(query),
      selectCurrentUserId
    ],
    (conversationsMap, messagesMap, conversationList, currentUserId) => {
      const conversations = conversationList || [];
      return conversations.reduce(
        (acc, conversationId) => {
          const { hasUnread, count } = (() => {
            // selectUnreadMessages(conversationId)(
            //   state
            // );
            const unread = messagesMap[conversationId]?.unread || 0;

            return calculateUnreadOfConversation(
              conversationsMap[conversationId],
              unread,
              currentUserId
            );
          })();

          acc.hasUnread = acc.hasUnread || hasUnread;
          acc.count = acc.count + count;
          return acc;
        },
        {
          hasUnread: false,
          count: 0
        }
      );
    }
  );

export const selectConversationsCountForQuery = (query: string) =>
  createSelector([selectConversationQueryList(query)], conversationList => {
    const conversations = conversationList || [];
    return conversations.length;
  });

export const selectAllLoadedConversations = createSelector(
  [selectConversationsMap],
  conversationsMap => {
    return Object.keys(conversationsMap);
  }
);

const generateConversationByContactIdQuery = _.memoize((contactId: string) => {
  const queryConfig = {
    ...DefaultConversationQueryConfig,
    query: {
      contactId
    }
  };
  return JSON.stringify(queryConfig);
});

export const selectConversationsByContactId = (contactId: string) => {
  return selectConversationQuery(
    generateConversationByContactIdQuery(contactId)
  );
};

export const selectConversationIdsByContactId = (contactId: string) => {
  return selectConversationQueryWithIds(
    generateConversationByContactIdQuery(contactId)
  );
};
