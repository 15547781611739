import { iScreenRecording } from "@sdk/screen-recordings/screen-recordings-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { screenRecordingsDefaultQueryConfig } from "./screen-recordings-default-query";

export const {
  slice: ScreenRecordingsSlice,
  reducers: ScreenRecordingsSliceReducer,
  actions: {
    setEntityQueryResults: setScreenRecordingsQueryResults,
    setEntity: setScreenRecording,
    setEntities: setScreenRecordings,
    patchEntity: patchScreenRecording,
    addNewEntity: addNewScreenRecording,
    setEntityFetchingStatus: setScreenRecordingFetchingStatus,
    setEntitiesFetchingStatus: setScreenRecordingsFetchingStatus,
    setEntityFailedStatus: setScreenRecordingFailedStatus,
    setEntityQueryError: setScreenRecordingsQueryError,
    resetQuery: resetScreenRecordingsQuery,
    setQueryList: setScreenRecordingQueryList,
  },
} = CreateEntitySlice<iScreenRecording>({
  sliceName: "screenRecordings",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...screenRecordingsDefaultQueryConfig,
        alias: "all",
      };
    }
  },
});
