import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iAppFeatureRequest } from "@sdk/app-feature-requests/app-feature-requests-model";
import React from "react";

const FeatureViewerModal = React.lazy(() => import("./feature-viewer.modal"));

const FeatureRequestViewerLegacy: iModalProps<
  typeof FeatureViewerModal,
  {
    feature: iAppFeatureRequest;
    onUpdateRequest: (item: iAppFeatureRequest) => any;
  }
> = {
  name: "feature-request-viewer",
  component: GenerateModalWithSuspense(FeatureViewerModal),
};

export default FeatureRequestViewerLegacy;
