import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const HelpCenterModal = React.lazy(() => import("./help-center.modal"));

const HelpCenterLegacy: iModalProps<typeof HelpCenterModal, {}> = {
  name: "help-center",
  component: GenerateModalWithSuspense(HelpCenterModal),
};

export default HelpCenterLegacy;
