import { iConnector } from "@sdk/chat-connectors/chat-connectors.models";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectChatConnectorQueryMap,
  selectEntityMap: selectChatConnectorsMap,
  selectQueryMapAll: selectChatConnectorsMapAll,
  selectQueryAllList: selectChatConnectorsAllList,
  selectAllEntities: selectAllChatConnectors,
  selectAllEntitiesQuery: selectAllChatConnectorsQuery,
  selectEntityById: selectChatConnectorById,
  selectEntityQuery: selectChatConnectorQuery,
  selectEntityQueryList: selectChatConnectorQueryList
} = CreateSelectorForEntities<iStore, iConnector>({
  sliceName: "chatConnectors"
});

export const selectChatConnectorByConnectionId = (connectionId: string) => (
  state: iStore
) => {
  return _.find(selectAllChatConnectors(state), {
    connectionId
  });
};
