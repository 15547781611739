import { axios, AxiosResponse } from "@sdk/axios";
import { SDKConfig } from "@sdk/default-sdk-config";
import { iFileRecord } from "@sdk/file-management/file-management.models";
import { EntityServices } from "@sdk/utils/entity.service";
import {
  PaginatedOptions,
  PaginatedResults,
} from "@sdk/utils/paginated-results";
import { UserTracker } from "user-tracker";
import {
  iKnowledgeDocument,
  iKnowledgeQueryLog,
  iMatchedKnowledgeDocument,
  TypeOfQuery,
} from "./knowledge-documents-model";

export class KnowledgeDocumentsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.knowledgeDocuments.configure(config);
  }

  knowledgeDocuments = Object.assign(
    new EntityServices<iKnowledgeDocument>(SDKConfig, "knowledge-documents", {
      onCreate: () => {
        UserTracker.track("KNOWLEDGE_DOCUMENTS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("KNOWLEDGE_DOCUMENTS - Delete", {});
      },
    }),
    {
      createWithFile: async (file: iFileRecord) => {
        UserTracker.track("KNOWLEDGE_DOCUMENTS - Create With File", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/knowledge-documents/create-with-file`,
          {
            file,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      clearAllKnowledgeDocuments: async (query?: any) => {
        UserTracker.track(
          "KNOWLEDGE_DOCUMENTS - Clear Knowledge Documents",
          {},
        );
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath +
            `/knowledge-documents/clear-knowledge-documents`,
          { confirm: "CONFIRM", query },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      reprocessDocument: async (documentId: string) => {
        UserTracker.track("KNOWLEDGE_DOCUMENTS - ReProcess Document", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath +
            `/knowledge-documents/${documentId}/re-process`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      queryKnowledge: async (
        query: string,
        options?: {
          overridePrompt?: string;
          chatHistory?: string;
          debug?: boolean;
          assistantId?: string;
          noSkipNoMatchedContext?: boolean;
          contextKeywords?: string;
        },
      ) => {
        UserTracker.track("KNOWLEDGE_DOCUMENTS - Query Knowledge", {});
        const results: AxiosResponse<{
          response: string;
          matchedDocuments: iMatchedKnowledgeDocument[];
          typeOfQuery: TypeOfQuery;
          prompt?: boolean;
          originalQuery?: string;
        }> = await axios.post(
          this.config.basePath + `/knowledge-documents/query-knowledge`,
          { query, ...(options || {}) },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      searchKnowledge: async (query: string) => {
        UserTracker.track("KNOWLEDGE_DOCUMENTS - Search Knowledge", {});
        const results: AxiosResponse<iKnowledgeDocument[]> = await axios.post(
          this.config.basePath + `/knowledge-documents/search-knowledge`,
          { query },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      reProcessKb: async () => {
        UserTracker.track("KNOWLEDGE_DOCUMENTS - Reprocess KB", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/knowledge-documents/re-process-kb`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      querySearchLogs: async (req: {
        query: any;
        options: PaginatedOptions;
      }) => {
        const results: AxiosResponse<PaginatedResults<iKnowledgeQueryLog>> =
          await axios.post(
            this.config.basePath +
              `/knowledge-documents/query-knowledge-search-logs`,
            req,
            {
              headers: {
                Authorization: this.config.token,
              },
            },
          );
        return results.data;
      },
      reviewMagicAssistantResponse: async (req: {
        id: string;
        status: boolean;
      }) => {
        const results: AxiosResponse<iKnowledgeQueryLog> = await axios.post(
          this.config.basePath +
            `/knowledge-documents/review-magic-assistant-response`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
