import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iAppData } from "@sdk/app-service/app.service";
import _ from "lodash";
import { iAppState, iRecentLoginData } from "./app-state.model";

export const appSlice_initialState: iAppState = {
  isSwitchingProfile: false,
  isSocketConnected: true,
  recentLogins: [],
  lastAppVersion: 0,
  appData: {
    latestVersion: 0,
    alphaOrganizations: [],
    alphaUsers: [],
    betaOrganizations: [],
    betaUsers: [],
    insiderOrganizations: [],
    insiderUsers: []
  }
};

const AppStateSlice = createSlice({
  name: "appState",
  initialState: appSlice_initialState,
  reducers: {
    setSocketStatus(state, action: PayloadAction<boolean>) {
      state.isSocketConnected = action.payload;
    },
    setSwitchingProfile(state, action: PayloadAction<boolean>) {
      state.isSwitchingProfile = action.payload;
    },
    addRecentLoginData(state, action: PayloadAction<iRecentLoginData>) {
      let updated = false;
      state.recentLogins = state.recentLogins.map(item => {
        if (
          item.domain === action.payload.domain &&
          item.email === action.payload.email
        ) {
          updated = true;
          return action.payload;
        }
        return item;
      });
      if (!updated) {
        state.recentLogins.push(action.payload);
      }
      state.recentLogins = _.uniqBy(
        state.recentLogins,
        e => `${e.domain}:${e.email}`
      );
    },
    removeRecentLoginData(
      state,
      action: PayloadAction<{ email: string; domain: string }>
    ) {
      state.recentLogins = _.filter(
        state.recentLogins,
        item =>
          !(
            item.domain === action.payload.domain &&
            item.email === action.payload.email
          )
      );
    },
    updateRecentLoginData(state, action: PayloadAction<iRecentLoginData>) {
      state.recentLogins = state.recentLogins.map(item => {
        if (
          item.domain === action.payload.domain &&
          item.email === action.payload.email
        ) {
          return action.payload;
        }
        return item;
      });
    },
    setLastAppVersion(state, action: PayloadAction<number>) {
      state.lastAppVersion = action.payload;
    },
    setAppData(state, action: PayloadAction<iAppData>) {
      state.appData = action.payload;
    }
  }
});

export const AppStateSliceReducers = AppStateSlice.reducer;

export const AppStateSliceActions = AppStateSlice.actions;
