import { iWidget } from "@sdk/chat-widgets/chat-widgets.models";
import { iConnection } from "@sdk/conversations/conversations.models";
import { iContact } from "@sdk/crm/crm.models";
import { iDynamicForm } from "@sdk/dynamic-forms/dynamic-forms-model";
import { iSelfServicePortal } from "@sdk/knowledge-base/knowledge-base.models";
import { iMagicAssistant } from "@sdk/magic-assistants/magic-assistants-model";
import {
  iOrganization,
  iUser,
} from "@sdk/user-management/user-management.models";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectChatWidgetById } from "store/modules/chat-widgets/chat-widgets.selectors";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { selectDynamicFormById } from "store/modules/dynamic-forms/dynamic-forms.selectors";
import { selectMagicAssistantById } from "store/modules/magic-assistants/magic-assistants.selectors";
import { selectSelfServicePortalById } from "store/modules/self-service-portals/self-service-portals.selectors";
import {
  selectCurrentUserId,
  selectUser,
} from "store/modules/users/users.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";

// Modify `iConfigurableEntityTypes` to refer keys in `EntityMap`
export type iConfigurableEntityTypes = keyof EntityMap;

export type EntityMap = {
  CONNECTION: iConnection;
  CONTACT: iContact;
  WORKSPACE: iOrganization;
  MY_PROFILE: iUser;
  USER: iUser;
  WIDGET: iWidget;
  SELF_SERVICE_PORTAL: iSelfServicePortal;
  DYNAMIC_FORM: iDynamicForm;
  MAGIC_ASSISTANT: iMagicAssistant;
};

export const useSelectEntity = <T extends iConfigurableEntityTypes>(
  entityType: iConfigurableEntityTypes,
  entityId?: string,
) => {
  const selector = useMemo(() => {
    if (entityType === "CONNECTION") {
      return selectConnectionById(entityId!);
    } else if (entityType === "WORKSPACE") {
      return selectOrganization;
    } else if (entityType === "USER") {
      return selectUser(entityId);
    } else if (entityType === "MY_PROFILE") {
      return selectCurrentUserId;
    } else if (entityType === "WIDGET") {
      return selectChatWidgetById(entityId!);
    } else if (entityType === "SELF_SERVICE_PORTAL") {
      return selectSelfServicePortalById(entityId!);
    } else if (entityType === "DYNAMIC_FORM") {
      return selectDynamicFormById(entityId!);
    } else if (entityType === "CONTACT") {
      return selectContactById(entityId!);
    } else if (entityType === "MAGIC_ASSISTANT") {
      return selectMagicAssistantById(entityId!);
    }
    return selectUser(entityId!);
  }, [entityId, entityType]);

  const entity = useSelector(selector as any) as EntityMap[T];
  return entity;
};
