import { axios, AxiosResponse } from "@sdk/axios";
import { Validated_AccountCreateRequest } from "@sdk/user-management/user-management.controller-models";
import {
  iOrganization,
  iUser,
} from "@sdk/user-management/user-management.models";
import { UserTracker } from "user-tracker";

export class AppSumoServices {
  constructor(protected config: { basePath: string; token: string }) {}

  async getAppSumoLicenseData(uuid: string) {
    const results: AxiosResponse<iAppSumoLicense> = await axios.get(
      this.config.basePath + `/app-sumo-licenses/${uuid}`,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async createAccountWithAppSumo(
    req: Validated_AccountCreateRequest & {
      appSumoLicenseId: string;
    },
  ) {
    UserTracker.track("Register", { source: "APP_SUMO" });
    const results: AxiosResponse<{
      accessToken: string;
      organization: iOrganization;
      user: iUser;
    }> = await axios.post(
      this.config.basePath + "/app-sumo-licenses/redeem-license",
      {
        ...req,
        organizationData: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          ...(req.organizationData || {}),
        },
      },
      {
        headers: {},
      },
    );
    return results.data;
  }
}
export interface iAppSumoLicense {
  uuid: string;
  plan_id: string;
  activation_email: string;
  invoice_item_uuid: string;
  connectedWorkspaceId: string;
}
