import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const GitHubAddIssueModal = React.lazy(
  () => import("./github-add-issue.modal"),
);

const GitHubAddIssue: iModalProps<
  typeof GitHubAddIssueModal,
  {
    onAdded?: (pageId: string) => any;
    contactId?: string;
    conversationId?: string;
  }
> = {
  name: "github-add-issue",
  component: GenerateModalWithSuspense(GitHubAddIssueModal),
};

export default GitHubAddIssue;
