import { Input } from "antd";
import React, { useEffect } from "react";
import { iQueryInputElementProps } from "../models";

export const StandardTextInput = ({
  value,
  onChange,
}: iQueryInputElementProps) => {
  useEffect(() => {
    if (typeof value !== "string") {
      onChange("");
    }
  }, [onChange, value]);
  return <Input value={value} onChange={(e) => onChange(e.target.value)} />;
};
