import { iContactList } from "@sdk/crm/crm.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { connectionListDefaultQueryConfig } from "./connection-lists-default-query";

export const {
  slice: ContactListsSlice,
  reducers: ContactListsSliceReducer,
  actions: {
    setEntityQueryResults: setContactListsQueryResults,
    setEntity: setContactList,
    setEntities: setContactLists,
    patchEntity: patchContactList,
    addNewEntity: addNewContactList,
    setEntityFetchingStatus: setContactListFetchingStatus,
    setEntitiesFetchingStatus: setContactListsFetchingStatus,
    setEntityFailedStatus: setContactListFailedStatus,
    setEntityQueryError: setContactListsQueryError,
    resetQuery: resetContactListsQuery
  }
} = CreateEntitySlice<iContactList>({
  sliceName: "contactLists",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...connectionListDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
