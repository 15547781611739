import { ICall } from "@telnyx/webrtc/lib/src";
import { IWebRTCCall } from "@telnyx/webrtc/lib/src/Modules/Verto/webrtc/interfaces";
import { useMemo } from "react";
import { loadContactsQuery } from "store/modules/contacts/contacts.helpers";
import { selectContactsQuery } from "store/modules/contacts/contacts.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const useCallContact = (call?: ICall & IWebRTCCall) => {
  const contactPhoneNumber = useMemo(() => {
    const number =
      call?.direction === "outgoing"
        ? call?.options?.destinationNumber
        : call?.options?.remoteCallerNumber;
    console.log("number", number, number?.charAt(0));
    return number?.charAt(0) === "+" ? number : `+${number}`;
  }, [
    call?.direction,
    call?.options?.destinationNumber,
    call?.options?.remoteCallerNumber
  ]);

  const callerName = call?.options?.remoteCallerName;

  const matchedContactsQuery = useMemo(() => {
    const query = {
      "data.primaryMobile": contactPhoneNumber
    };
    return {
      query: query,
      options: {
        sortBy: ["metaData.createdTime"]
      }
    };
  }, [contactPhoneNumber]);

  const { state: matchedContacts, retry: reloadContacts } = useQueryWithStore(
    selectContactsQuery(JSON.stringify(matchedContactsQuery)),
    loadContactsQuery(
      matchedContactsQuery,
      JSON.stringify(matchedContactsQuery)
    ),
    [matchedContactsQuery],
    !contactPhoneNumber
  );

  const matchedContact = matchedContacts?.list?.[0];

  return {
    contact: matchedContact,
    phoneNumber: contactPhoneNumber,
    callerName
  };
};
