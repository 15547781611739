import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const CreatePipelineCard = React.lazy(
  () => import("./create-pipeline-card.modal"),
);

const CreatePipelineCardModal: iModalProps<typeof CreatePipelineCard, {}> = {
  name: "create-pipeline-card",
  component: GenerateModalWithSuspense(CreatePipelineCard),
};

export default CreatePipelineCardModal;
