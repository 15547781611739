import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import React, { Suspense } from "react";

const QuillEditor = React.lazy(() => import("./quill-editor"));

export const LazyQuillEditor = ({ ...props }) => {
  return (
    <Suspense fallback={<SuspenseLoadingIndicator />}>
      <QuillEditor {...props} />
    </Suspense>
  );
};
