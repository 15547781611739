import { SDK } from "@sdk";
import { iSession } from "@sdk/sessions/sessions.models";
import { Store } from "redux";
import { QueryConfig } from "store/utils/query-config";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import {
  bulkLoadContactsByIds,
  loadContactById,
} from "../contacts/contacts.helpers";
import { selectOrganization } from "../workspace/workspace.selectors";
import { sessionsDefaultQueryConfig } from "./sessions-default-query";
import { SessionsSlice } from "./sessions.slice";

export const loadQuerySessions =
  (queryConfig: QueryConfig, queryAlias?: string) =>
  async (store: Store<iStore>, forceReload?: boolean) => {
    queryAlias = queryAlias || JSON.stringify(queryConfig);
    await loadSessionsQuery(queryConfig, queryAlias)(store, forceReload);
  };

export const loadContactSessions =
  (contactId) => async (store: Store<iStore>, forceReload?: boolean) => {
    const queryConfig: QueryConfig = {
      query: { contactId },
      options: {
        sortBy: ["metaData.createdAt"],
      },
    };
    await loadSessionsQuery(queryConfig, JSON.stringify(queryConfig))(
      store,
      forceReload,
    );
  };

export const loadActiveSessions = async (
  store: Store<iStore>,
  forceReload?: boolean,
  loadAllSessionAsActive?: boolean,
) => {
  const selectOrganizationId = selectOrganization(store.getState())?.id;
  const sessionsMap = await SDK.sessions.getActiveGuestSessions(
    selectOrganizationId!,
  );
  const sessionIds = Object.keys(sessionsMap);
  await loadSessionsQuery(
    {
      query: !loadAllSessionAsActive
        ? {
            id: { $in: sessionIds },
          }
        : {},
      options: {
        ...sessionsDefaultQueryConfig.options,
      },
    },
    "activeSessions",
  )(store, true);
};

export const {
  loadEntityQueries: loadSessionsQuery,
  loadEntityById: loadSessionById,
  loadEntitiesByIds: loadSessionsByIds,
} = CreateHelpersForEntity<iStore, iSession>({
  sliceName: "sessions",
  slice: SessionsSlice,
  isPaginatedQueries: true,
  fetchPageSize: 150,
  loadEntityQueryProvider: (query) => SDK.sessions.query(query),
  loadEntityByIdProvider: (entityId: string) => {
    // console.log("entityId", entityId);
    return SDK.sessions.getById(entityId);
  },
  loadEntityByIdsProvider: async (entityIds: string[]) =>
    (
      await SDK.sessions.query({
        query: { id: { $in: entityIds } },
        options: {},
      })
    ).docs,
  postLoadEntity: async (session, store, forceReload) => {
    if (session.contactId) {
      await loadContactById(session.contactId!)()(store, forceReload);
    }
  },
  postLoadEntities: async (sessions, store, forceReload) => {
    const associatedContacts = sessions
      .map((session) => session.contactId)
      .filter((e) => e);
    // Load Contacts
    if (associatedContacts.length > 0) {
      await bulkLoadContactsByIds(associatedContacts)(store, forceReload);
    }
  },
});
