import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddQuickCustomInboxModal = React.lazy(
  () => import("./add-quick-custom-inbox.modal"),
);

const AddQuickCustomInbox: iModalProps<typeof AddQuickCustomInboxModal> = {
  name: "add-quick-custom-inbox",
  component: GenerateModalWithSuspense(AddQuickCustomInboxModal),
};

export default AddQuickCustomInbox;
