import English from "antd/es/locale/en_US";
import Dutch from "antd/es/locale/nl_NL";
import { AvailableLanguages } from "../store/modules/preferences/preferences.model";

export function getAntLocal(language: AvailableLanguages) {
  if (language === AvailableLanguages.DUTCH) {
    return Dutch;
  }
  if (English.Empty?.description) {
    English.Empty.description = (
      <div className="text-gray-600">No Data</div>
    ) as any;
  }
  return English;
}
