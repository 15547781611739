import { iCustomDomain } from "@sdk/sending-domains/sending-domains-models";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectSendingDomainQueryMap,
  selectEntityMap: selectSendingDomainsMap,
  selectQueryMapAll: selectSendingDomainsMapAll,
  selectQueryAllList: selectSendingDomainsAllList,
  selectAllEntities: selectAllSendingDomains,
  selectAllEntitiesQuery: selectAllSendingDomainsQuery,
  selectEntityById: selectSendingDomainById,
  selectEntityQuery: selectSendingDomainQuery,
  selectEntityQueryList: selectSendingDomainQueryList,
} = CreateSelectorForEntities<iStore, iCustomDomain>({
  sliceName: "sendingDomains",
});

export const selectSendingDomainByConnectionId =
  (connectionId: string) => (state: iStore) => {
    return _.find(selectAllSendingDomains(state), {
      connectionId,
    });
  };
