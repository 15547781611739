import { Button, ColProps, Form, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { CollapsibleConfigurationSection } from "components/common/collapsible-configuration-panel/collapsible-configuration-container";
import { RouterPrompt } from "components/common/router-prompt/router-prompt";

import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { UserTracker } from "user-tracker";
import { useEditEntity } from "./use-edit-entity";
import { useSelectEntity } from "./use-select-entity";

export const ConfigurationEditorComponent = ({
  children,
  icon,
  title,
  description,
  entityType,
  entityId,
  transformFormValues,
  transformEntityValues,
  onFormValueChange,
  panelRef,
  configEditorRef,
  ignoreNavigationWarning,
  onSaved,
  labelCol,
}: {
  icon: string | JSX.Element;
  title: string;
  description: string;
  children;
  entityType:
    | "CONNECTION"
    | "CONTACT"
    | "WORKSPACE"
    | "MY_PROFILE"
    | "USER"
    | "WIDGET"
    | "SELF_SERVICE_PORTAL"
    | "DYNAMIC_FORM"
    | "MAGIC_ASSISTANT";
  entityId?: string;
  transformFormValues?: (formValues: any) => any;
  transformEntityValues?: (entity: any) => any;
  onFormValueChange?: (formValues: any) => any;
  panelRef?: any;
  configEditorRef?: any;
  ignoreNavigationWarning?: boolean;
  onSaved?: () => any;
  labelCol?: ColProps;
}) => {
  return (
    <CollapsibleConfigurationSection
      icon={icon}
      title={title}
      description={description}
      ref={panelRef}
    >
      <ConfigurationEditorInner
        entityType={entityType}
        entityId={entityId}
        transformFormValues={transformFormValues}
        transformEntityValues={transformEntityValues}
        onFormValueChange={onFormValueChange}
        configEditorRef={configEditorRef}
        ignoreNavigationWarning={ignoreNavigationWarning}
        onSaved={onSaved}
        title={title}
        labelCol={labelCol}
      >
        {children}
      </ConfigurationEditorInner>
    </CollapsibleConfigurationSection>
  );
};

export const ConfigurationEditorInner = forwardRef(
  (
    {
      children,
      entityType,
      entityId,
      transformFormValues,
      transformEntityValues,
      onFormValueChange,
      configEditorRef,
      ignoreNavigationWarning,
      onSaved,
      title,
      formLayout,
      labelCol,
    }: {
      children;
      entityType:
        | "CONNECTION"
        | "CONTACT"
        | "WORKSPACE"
        | "MY_PROFILE"
        | "USER"
        | "WIDGET"
        | "SELF_SERVICE_PORTAL"
        | "DYNAMIC_FORM"
        | "MAGIC_ASSISTANT";
      entityId?: string;
      transformFormValues?: (formValues: any) => any;
      transformEntityValues?: (entity: any) => any;
      onFormValueChange?: (formValues: any) => any;
      configEditorRef?: any;
      ignoreNavigationWarning?: boolean;
      onSaved?: () => any;
      title: string;
      formLayout?: "vertical" | "inline" | "horizontal";
      labelCol?: ColProps;
    },
    ref,
  ) => {
    const [form] = useForm();
    const [isFormTouched, setFormTouched] = useState(false);

    const entity = useSelectEntity(entityType, entityId);

    const initialValues = useMemo(() => {
      if (!entity) {
        return {};
      }
      return {
        ...(transformEntityValues ? transformEntityValues(entity) : entity),
      };
    }, [entity, transformEntityValues]);

    useEffect(() => {
      form.resetFields();
      setFormTouched(false);
    }, [entity, form]);

    const { doAction: _editEntity, isProcessing } = useEditEntity(
      entityType,
      entityId,
    );

    const editEntity = useCallback(
      (edits) => {
        UserTracker.track(`${entityType} - Edit - ${title}`, {});
        if (transformFormValues) {
          edits = transformFormValues(edits);
        }
        return _editEntity(edits);
      },
      [_editEntity, entityType, title, transformFormValues],
    );

    const getForm = useCallback(() => {
      return form;
    }, [form]);

    const getIsFormTouched = useCallback(() => {
      return isFormTouched;
    }, [isFormTouched]);

    useImperativeHandle(
      ref,
      () => ({
        getForm,
        setFormTouched,
        getIsFormTouched,
      }),
      [getForm, getIsFormTouched],
    );

    return (
      <div>
        {!ignoreNavigationWarning && (
          <RouterPrompt when={form.isFieldsTouched()} />
        )}

        <Form
          layout={formLayout || "vertical"}
          labelCol={labelCol}
          onFinish={() => {
            const formValues = form.getFieldsValue();
            editEntity(formValues).then((d) => {
              message.success("Saved");
              onSaved && onSaved();
            });
          }}
          form={form}
          initialValues={initialValues}
          onValuesChange={(d) => {
            if (form.isFieldsTouched() && !isFormTouched) {
              setFormTouched(true);
            }
            onFormValueChange && onFormValueChange(form.getFieldsValue());
          }}
        >
          {children}
        </Form>

        <div
          className="flex flex-row justify-end items-center"
          style={{ height: 30 }}
        >
          {isFormTouched && (
            <Button
              onClick={() => form.submit()}
              className="font-bold animated fadeInRight"
              icon={<i className="ri-save-line"></i>}
              loading={isProcessing}
              type="primary"
            >
              Save
            </Button>
          )}
        </div>
      </div>
    );
  },
);
