import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddInstagramConnectionModal = React.lazy(
  () => import("./add-instagram-connection.modal"),
);

const AddInstagramConnection: iModalProps<typeof AddInstagramConnectionModal> =
  {
    name: "add-instagram-connection",
    component: GenerateModalWithSuspense(AddInstagramConnectionModal),
  };

export default AddInstagramConnection;
