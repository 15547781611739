import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iScreenRecording } from "@sdk/screen-recordings/screen-recordings-model";
import React from "react";

const NewScreenRecorderModal = React.lazy(
  () => import("./new-screen-recorder.modal"),
);

const NewScreenRecorder: iModalProps<
  typeof NewScreenRecorderModal,
  {
    onCompleted: (screenRecording: iScreenRecording) => any;
  }
> = {
  name: "new-screen-recorder",
  component: GenerateModalWithSuspense(NewScreenRecorderModal),
};

export default NewScreenRecorder;
