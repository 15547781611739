import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";
import { iEmailBlock } from "./simple-email-block-editor.modal";

const SimpleEmailBlockEditorModal = React.lazy(
  () => import("./simple-email-block-editor.modal"),
);

const SimpleEmailBlockEditor: iModalProps<
  typeof SimpleEmailBlockEditorModal,
  {
    blockInContext: iEmailBlock;
    onSave: (data: iEmailBlock) => any;
  }
> = {
  name: "simple-email-block-editor",
  component: GenerateModalWithSuspense(SimpleEmailBlockEditorModal),
};

export default SimpleEmailBlockEditor;
