import { Badge, Button, Dropdown, Tooltip, message } from "antd";
import classNames from "classnames";
import { HelpCenterPromptButton } from "components/common/help-center-legacy/embeddable-components/help-center-prompt-button";
import { ModalTitle } from "components/common/modal-title";
import { NotificationsInbox } from "components/common/notification-inbox/notifications-inbox";
import { NotificationHelper } from "components/common/notifications/notifications-helper";
import { useNotificationsPermission } from "components/common/notifications/use-notifications-permission";
import { usePushNotificationStatus } from "components/common/notifications/use-push-noitificaiton-status";
import { CCPopoverV2 } from "components/common/popover/popover-v2-portal";
import { useState } from "react";
import { loadAllNotifications } from "store/modules/notifications/notifications.helpers";
import { selectActiveNotification } from "store/modules/notifications/notifications.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const NotificationsMenu = ({ mobileMode }: { mobileMode }) => {
  const [notificationVisibility, setNotificationVisibility] = useState(false);

  const { state: allNotifications } = useQueryWithStore(
    selectActiveNotification,
    loadAllNotifications,
  );

  const { requestNotificationPermission, notificationPermissionState } =
    useNotificationsPermission();

  const { subscribeToPush, isOfflinePushEnabled } = usePushNotificationStatus();

  return (
    <CCPopoverV2
      buttonClassName="w-full"
      className="p-4"
      content={
        <div>
          <div className="my-2 flex flex-row justify-between items-center">
            <ModalTitle
              title="Notifications"
              icon={<i className="ri-notification-3-fill"></i>}
            />
            <div className="flex flex-row items-center gap-4">
              <Tooltip title="Test Notification">
                <Button
                  type="text"
                  icon={<i className="ri-flask-line"></i>}
                  onClick={() => {
                    NotificationHelper.fire.testBrowserNotification({
                      title: "Notification is working",
                      body: "If you can see this, then you device is capable of showing visual notifications",
                    });
                    message.info(
                      "Test notification has been triggered. If you did not receive it, please troubleshoot your device notification settings",
                    );
                  }}
                ></Button>
              </Tooltip>
              <HelpCenterPromptButton
                type="ICON"
                text={
                  <div className="text-lg p-3">
                    Learn to Troubleshoot Notifications
                  </div>
                }
                description=""
                icon="QUESTION"
                articleId="fc89e0-0e2fe"
                disableAutoShow
              />
              {notificationPermissionState && (
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "OFFLINE_NOTIFICATIONS",
                        label: "Activate offline notifications",
                        icon: isOfflinePushEnabled ? (
                          <i className="ri-check-line"></i>
                        ) : (
                          <i className="ri-line"></i>
                        ),
                        onClick: () => {
                          subscribeToPush();
                        },
                      },
                    ],
                  }}
                >
                  <Button
                    type="text"
                    icon={<i className="ri-more-2-line"></i>}
                  ></Button>
                </Dropdown>
              )}
            </div>
          </div>
          <NotificationsInbox />
        </div>
      }
      popoverId="notification-menu"
    >
      <Badge count={allNotifications.length} rootClassName="w-full">
        <Button
          type="text"
          id="notification-icon-nav"
          className="h-10"
          icon={
            <i
              className={classNames("ri-notification-3-fill cursor-pointer")}
            ></i>
          }
          block
        ></Button>
      </Badge>
    </CCPopoverV2>
  );
};
