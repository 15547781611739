import { iChatBot } from "@sdk/chat-bots/chat-bots.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { chatBotsDefaultQueryConfig } from "./chat-bots-default-query";

export const {
  slice: ChatBotsSlice,
  reducers: ChatBotsSliceReducer,
  actions: {
    setEntityQueryResults: setChatBotsQueryResults,
    setEntity: setChatBot,
    setEntities: setChatBots,
    patchEntity: patchChatBot,
    addNewEntity: addNewChatBot,
    setEntityFetchingStatus: setChatBotFetchingStatus,
    setEntitiesFetchingStatus: setChatBotsFetchingStatus,
    setEntityFailedStatus: setChatBotFailedStatus,
    setEntityQueryError: setChatBotsQueryError,
    resetQuery: resetChatBotsQuery,
    setQueryList: setChatBotQueryList
  }
} = CreateEntitySlice<iChatBot<any>>({
  sliceName: "chatBots",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...chatBotsDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
