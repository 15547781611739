import { iCustomDomain } from "@sdk/sending-domains/sending-domains-models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { sendingDomainsDefaultQueryConfig } from "./sending-domains-default-query";

export const {
  slice: SendingDomainsSlice,
  reducers: SendingDomainsSliceReducer,
  actions: {
    setEntityQueryResults: setSendingDomainsQueryResults,
    setEntity: setSendingDomain,
    setEntities: setSendingDomains,
    patchEntity: patchSendingDomain,
    addNewEntity: addNewSendingDomain,
    setEntityFetchingStatus: setSendingDomainFetchingStatus,
    setEntitiesFetchingStatus: setSendingDomainsFetchingStatus,
    setEntityFailedStatus: setSendingDomainFailedStatus,
    setEntityQueryError: setSendingDomainsQueryError,
    resetQuery: resetSendingDomainsQuery,
    setQueryList: setSendingDomainQueryList,
  },
} = CreateEntitySlice<iCustomDomain>({
  sliceName: "sendingDomains",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...sendingDomainsDefaultQueryConfig,
        alias: "all",
      };
    }
  },
});
