import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const OpenAiAssistantModal = React.lazy(
  () => import("./open-ai-assistant.modal"),
);

const OpenAiAssistant: iModalProps<
  typeof OpenAiAssistantModal,
  {
    input: string;
    onReplaceText: (content: string) => any;
  }
> = {
  name: "open-ai-assistant",
  component: GenerateModalWithSuspense(OpenAiAssistantModal),
};

export default OpenAiAssistant;
