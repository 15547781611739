import { defaultWidgetPreviewData } from "../default-widget-data";

export const ChatWidgetAvatarGroup = ({
  data,
}: {
  data: typeof defaultWidgetPreviewData.HOME_HEADER;
}) => {
  const defaultAvatar =
    "https://widget.clickconnector.app/assets/default-avatar.png";
  return (
    <div>
      <div>
        <div className="user-list  mt-4">
          <div className="relative flex flex-row h-full w-full">
            {data.AVATARS_IMAGES.slice(0, 4).map((imageUrl, index) => (
              <div
                style={{ zIndex: data.AVATARS_IMAGES.length - index }}
                key={imageUrl + index}
              >
                <div
                  className={`chat-widget-avatar-container ${
                    index === 0 ? "relative" : "absolute"
                  }`}
                  style={{
                    width: "3rem",
                    height: "3rem",
                    left: `${index * 2}rem`,
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  <img src={imageUrl || defaultAvatar} alt="" />
                </div>
              </div>
            ))}
            {data.TOTAL_USERS - 4 > 0 && (
              <div
                className="absolute chat-widget-avatar-container flex flex-row justify-center items-center text-white"
                style={{
                  width: "3rem",
                  height: "3rem",
                  borderRadius: "50%",
                  left: "8rem",
                  background: "#00000044",
                }}
              >
                {data.TOTAL_USERS - 4}+
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
