import { SDK } from "@sdk";
import { AvailabilityStatus } from "@sdk/user-management/user-management.models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import {
  setAllUsers,
  setCurrentUser,
  setUsersQueryError
} from "./users.actions";

export const loadAllUsers = (loadOnFirstRun?: boolean) => async (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  const state = store.getState().users;
  if (
    forceReload ||
    state.queries.all?.hasError ||
    (state.queries.all?.lastFetched || 0) + 10 * 60 * 1000 < Date.now()
  ) {
    try {
      const res = await SDK.getAllUsers();
      store.dispatch(setAllUsers(res));
    } catch (e) {
      console.log("Error while loading all users", e);
      store.dispatch(setUsersQueryError("all"));
      throw e;
    }
  }
};

export const reloadCurrentUser = async (store: Store) => {
  const res = await SDK.getCurrentUser();
  store.dispatch(setCurrentUser(res));
};

export const changeUserAvailabilityStatus = (
  availabilityStatus: AvailabilityStatus
) => {
  SDK.editCurrentUser({
    metaData: {
      availabilityStatus
    }
  }).catch(e => {
    // Ignore Error
  });
};
