import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import { iProduct } from "./products-model";

export class ProductsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.products.configure(config);
  }

  products = Object.assign(
    new EntityServices<iProduct>(SDKConfig, "products"),
    {},
  );
}
