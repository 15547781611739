import classnames from "classnames";
import { forwardRef, useMemo } from "react";
import PhoneInput from "react-phone-number-input";
import { useSelector } from "react-redux";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import "./mobile-number-input.scss";

export const MobileNumberInput = forwardRef((props: any, ref) => {
  const organization = useSelector(selectOrganization);

  const defaultCountry = useMemo(
    () => organization?.data?.country || "GB",
    [organization?.data?.country],
  );

  return (
    <PhoneInput
      international
      defaultCountry={defaultCountry}
      placeholder="Enter a mobile number"
      {...props}
      ref={ref as any}
      className={classnames(props.className)}
    />
  );
});
