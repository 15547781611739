import MailOutlined from "@ant-design/icons/MailOutlined";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  Button,
  Form,
  Input,
  message,
  Popover,
  Space,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { CollapsibleConfigurationSection } from "components/common/collapsible-configuration-panel/collapsible-configuration-container";

import { HelpCenterPromptButton } from "components/common/help-center-legacy/embeddable-components/help-center-prompt-button";
import { ShopifyInstallInstruction } from "components/modules/organization-management/general/apps-and-integrations/shopify-install-instructions/shopify-install-instructions";
import { EmbedWidgetSetup } from "components/pages/configurations/setup-embeds/components/embed-widget-setup";
import { GlobalConfig } from "config";
import copyToClipboard from "copy-to-clipboard";
import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectChatWidgetByConnectionId } from "store/modules/chat-widgets/chat-widgets.selectors";
import { selectIsDarkMode } from "store/modules/ui-state/ui-state.selectors";
import { selectShopifyStoreConfig } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import "./chat-widget-install-instructions.scss";

const platformIcons = {
  shopify: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 48 48"
    >
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <path
            fill="#95BF46"
            d="M39.785 9.426a.462.462 0 0 0-.417-.388c-.174-.014-3.844-.286-3.844-.286s-2.55-2.53-2.829-2.811c-.28-.28-.827-.195-1.039-.132l-1.426.44c-.852-2.45-2.355-4.702-4.998-4.702-.073 0-.149.003-.224.007C24.256.56 23.325.128 22.521.128c-6.16 0-9.101 7.699-10.024 11.611l-4.31 1.337c-1.336.42-1.378.461-1.554 1.72-.132.953-3.627 27.982-3.627 27.982l27.235 5.103 14.757-3.192s-5.18-35.023-5.213-35.263zm-11.06-2.711l-2.305.713.002-.497c0-1.523-.212-2.749-.551-3.72 1.362.17 2.27 1.72 2.853 3.504zM24.18 3.512c.379.949.625 2.311.625 4.149 0 .094 0 .18-.002.267l-4.759 1.474c.917-3.537 2.634-5.246 4.136-5.89zm-1.83-1.732c.266 0 .534.09.79.267-1.973.928-4.09 3.268-4.983 7.939l-3.762 1.165c1.047-3.563 3.532-9.371 7.955-9.371z"
          />
          <path
            fill="#5E8E3E"
            d="M39.368 9.038c-.174-.014-3.844-.286-3.844-.286s-2.55-2.53-2.829-2.811a.699.699 0 0 0-.393-.18l-2.06 42.12 14.756-3.192s-5.18-35.023-5.213-35.263a.462.462 0 0 0-.417-.388"
          />
          <path
            fill="#FFF"
            d="M25.232 17.192l-1.82 5.413s-1.594-.851-3.548-.851c-2.865 0-3.01 1.798-3.01 2.25 0 2.473 6.444 3.42 6.444 9.21 0 4.556-2.889 7.489-6.785 7.489-4.675 0-7.066-2.91-7.066-2.91l1.252-4.135s2.458 2.11 4.531 2.11c1.355 0 1.906-1.067 1.906-1.847 0-3.225-5.286-3.368-5.286-8.667 0-4.46 3.2-8.775 9.662-8.775 2.49 0 3.72.713 3.72.713"
          />
        </g>
      </g>
    </svg>
  ),
  wordpress: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 48 48"
    >
      <path
        fill="#464342"
        fillRule="nonzero"
        d="M3.398 23.906c0 8.118 4.718 15.134 11.559 18.458L5.174 15.56a20.431 20.431 0 0 0-1.776 8.346zm34.354-1.035c0-2.534-.91-4.29-1.691-5.656-1.04-1.69-2.014-3.12-2.014-4.81 0-1.884 1.43-3.64 3.444-3.64.09 0 .177.012.266.017a20.433 20.433 0 0 0-13.85-5.384c-7.165 0-13.468 3.676-17.135 9.244.48.015.934.025 1.32.025 2.144 0 5.465-.26 5.465-.26 1.106-.066 1.236 1.558.132 1.69 0 0-1.111.13-2.348.194l7.469 22.215 4.488-13.46-3.195-8.755a37.345 37.345 0 0 1-2.15-.196c-1.106-.064-.976-1.754.129-1.689 0 0 3.387.26 5.402.26 2.145 0 5.466-.26 5.466-.26 1.106-.065 1.236 1.559.131 1.69 0 0-1.113.13-2.347.195l7.411 22.046 2.046-6.836c.887-2.836 1.561-4.874 1.561-6.63zm-13.485 2.83l-6.153 17.88a20.506 20.506 0 0 0 12.604-.327 1.8 1.8 0 0 1-.146-.283l-6.305-17.27zm17.636-11.634c.088.653.138 1.354.138 2.109 0 2.08-.389 4.42-1.56 7.346l-6.264 18.112c6.097-3.556 10.198-10.161 10.198-17.727 0-3.566-.91-6.919-2.512-9.84zM23.907 0C10.725 0 0 10.724 0 23.906 0 37.09 10.725 47.814 23.907 47.814S47.815 37.09 47.815 23.906C47.815 10.724 37.09 0 23.907 0zm0 46.718c-12.577 0-22.81-10.233-22.81-22.812 0-12.577 10.232-22.81 22.81-22.81 12.577 0 22.81 10.233 22.81 22.81 0 12.579-10.233 22.812-22.81 22.812z"
      />
    </svg>
  ),
  prestaShop: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 48 48"
    >
      <g fill="none" fillRule="nonzero">
        <path
          fill="#A5DBE8"
          d="M15.559 36.828c.383-.17.771-.328 1.163-.475a21.147 21.147 0 0 1 3.046-.904 21.15 21.15 0 0 1 2.35-.363 73.465 73.465 0 0 1-1.464 4.157c.022.267.036.535.042.804a17.051 17.051 0 0 1 6.608 0c.006-.269.02-.537.042-.804a73.318 73.318 0 0 1-1.464-4.157c.79.077 1.575.199 2.35.364a21.14 21.14 0 0 1 3.046.903 20.825 20.825 0 0 1 8.396 5.813C44.77 37.767 48 31.262 48 24 48 10.745 37.255 0 24 0S0 10.745 0 24c0 7.262 3.23 13.766 8.326 18.166a20.827 20.827 0 0 1 7.233-5.338"
        />
        <path
          fill="#525353"
          d="M32.442 36.828l1.49 5.123.422.02c.275 0 .468.072.468.347 0 .456.023.968.067 1.415.371.316.823.671 1.166 1.017a24.057 24.057 0 0 0 3.619-2.584 20.825 20.825 0 0 0-7.232-5.338m-2.913-.051l1.749-.424a21.111 21.111 0 0 0-3.046-.904 21.15 21.15 0 0 0-2.35-.363c.446 1.4.935 2.786 1.464 4.157-.022.267-.036.535-.042.804 1.24.244 2.458.67 3.585 1.168l-1.36-4.438"
        />
        <path
          fill="#000"
          d="M11.945 44.75c.279.162.56.32.846.472.1-.481.26-1.148.309-1.642-.371.315-.812.824-1.155 1.17m18.915-3.58a16.842 16.842 0 0 0-3.556-1.123 17.053 17.053 0 0 0-6.608 0c-1.24.244-2.533.615-3.66 1.113l-.119.746h14.166l-.223-.736"
        />
        <path
          fill="#525353"
          d="M13.056 43.722c.044-.448.076-.847.076-1.303 0-.274.188-.45.462-.45l.45-.015 1.515-5.126a20.827 20.827 0 0 0-7.233 5.338 24.057 24.057 0 0 0 3.62 2.584c.342-.346.7-.677 1.07-.992"
        />
        <path
          fill="#000"
          d="M35.072 44.633c.097.488.12.585.137.589.285-.152.567-.31.846-.473-.346-.349-.708-.684-1.082-1.002l.1.886"
        />
        <path
          fill="#AA9678"
          d="M30.86 41.17l.223.736h2.898l-1.54-5.078c-.382-.17-.77-.328-1.163-.475l-1.75.424 1.332 4.393"
        />
        <path fill="#C4B6A1" d="M14.07 42.932l.045-.032h-.043l-.002.032" />
        <path fill="#AA9678" d="M33.928 42.9h-.044l.046.032-.002-.032" />
        <path
          fill="#AA9678"
          d="M33.878 42.826l-9.899.011L24 48a23.9 23.9 0 0 0 10.292-2.316c-.203-.895-.224-1.858-.257-2.796-.015-.011-.157-.062-.157-.062"
        />
        <path
          fill="#C4B6A1"
          d="M23.984 42.858s-9.953-.012-9.969-.002c-.033.939-.226 2.052-.307 2.828A23.9 23.9 0 0 0 24 48l.006-5.125-.022-.017"
        />
        <path fill="#786453" d="M24 42.403v.497z" />
        <path
          fill="#AA9678"
          d="M31.424 39.03l.005.016-.005-.016s-.244-.193-.552-.548c.308.355.552.548.552.548"
        />
        <path fill="#BBBCBD" d="M39.723 24.218v-.006.006" />
        <path
          fill="#676A6A"
          d="M39.696 23.844l.004.019.002.01v.002c.011.092.016.188.018.284 0 .018.003.035.003.053V24.225c0 .02-.003.037-.003.056a2.86 2.86 0 0 1-.018.279v.001l-.002.014-.004.02c-.013.096-.03.191-.054.285v.001l.003-.003.001-.005.628-.657-.633-.663v.001c.024.093.042.19.055.29"
        />
        <path
          fill="#000"
          d="M39.696 24.596c.001-.013.005-.024.006-.036a2.86 2.86 0 0 0 .018-.279c0-.021.003-.041.003-.063 0-.02-.003-.039-.003-.059a2.796 2.796 0 0 0-.018-.284l-.006-.03a2.543 2.543 0 0 0-.055-.29c-.008-.033-.02-.062-.029-.093a1.856 1.856 0 0 0-.226-.491 1.493 1.493 0 0 0-.115-.15.974.974 0 0 0-.124-.116c-.017-.012-.034-.021-.051-.032a.706.706 0 0 0-.167-.08.583.583 0 0 0-.172-.03.584.584 0 0 0-.172.03.7.7 0 0 0-.166.08c-.017.01-.034.02-.05.032a.99.99 0 0 0-.24.266 1.85 1.85 0 0 0-.226.491l-.007.023a2.528 2.528 0 0 0-.061.27c-.005.029-.012.056-.016.085a2.59 2.59 0 0 0-.024.325l-.003.045h.004c0 .914.432 1.655.966 1.655a.6.6 0 0 0 .264-.065.782.782 0 0 0 .142-.09c.019-.014.036-.032.053-.048a1.416 1.416 0 0 0 .199-.244 1.737 1.737 0 0 0 .12-.236l.025-.06a2.2 2.2 0 0 0 .13-.526zm-.904-.598c-.2 0-.362-.278-.362-.621 0-.343.162-.62.362-.62.2 0 .362.277.362.62 0 .343-.162.62-.362.62z"
        />
        <path
          fill="#FFF"
          d="M38.792 22.756c-.2 0-.362.278-.362.62 0 .344.162.622.362.622.2 0 .362-.278.362-.621 0-.343-.162-.62-.362-.62"
        />
        <path
          fill="#909393"
          d="M37.903 23.462a1.85 1.85 0 0 1 .264-.545.977.977 0 0 1 .201-.212l.05-.032a.692.692 0 0 1 .167-.08.57.57 0 0 1 .172-.03.57.57 0 0 1 .172.03c.06.019.115.046.167.08.017.01.034.02.05.032a.973.973 0 0 1 .125.116c.041.047.08.097.115.15.092.137.17.303.226.491l-.855-3.914-.854 3.914"
        />
        <path
          fill="#000"
          d="M27.431 24.181a2.327 2.327 0 0 0-.015-.233v-.003a2.29 2.29 0 0 0-.038-.223l-.002-.005a2.252 2.252 0 0 0-.058-.213l-.002-.004a2.145 2.145 0 0 0-.08-.204l-.001-.005a2.273 2.273 0 0 0-1.208-1.17l-.007-.002a2.243 2.243 0 0 0-.404-.122c-.004 0-.007 0-.01-.002a2.248 2.248 0 0 0-.86 0l-.015.002c-.138.027-.27.067-.397.118l-.01.004a2.241 2.241 0 0 0-.697.447l-.003.008-.008.002c-.162.153-.3.33-.41.524l-.005.007a2.14 2.14 0 0 0-.092.184l-.01.02a2.197 2.197 0 0 0-.068.18c-.004.011-.01.022-.013.034a2.459 2.459 0 0 0-.046.174c-.004.017-.01.033-.013.05-.012.057-.02.116-.028.175l-.008.054a2.25 2.25 0 0 0 .03.673l.002.009c.028.141.07.277.123.408l.003.005a2.27 2.27 0 0 0 .985 1.113l.003.001c.06.034.124.065.188.094l.015.007c.06.026.121.048.183.07l.03.01c.058.019.117.034.176.047.015.004.03.009.047.012.057.012.116.02.175.028l.054.008a2.256 2.256 0 0 0 2.11-.985c.252-.373.386-.813.386-1.264V24.2l-.002-.018zm-2.26-.264a.85.85 0 1 1 0-1.7.85.85 0 0 1 0 1.7z"
        />
        <path
          fill="#FFF"
          d="M25.172 22.218a.85.85 0 1 0 0 1.698.85.85 0 0 0 0-1.698"
        />
        <path
          fill="#BBBCBD"
          d="M23.616 22.576l.008-.002.003-.008 1.557-4.763v-1.127a13.273 13.273 0 0 0-2.672-.276c-.305 0-.607.013-.907.034l-.132.009a13.38 13.38 0 0 0-4.527 1.177 14.477 14.477 0 0 0-3.38 2.165l-.001.001c-.273.234-.538.478-.793.731l-.145.141c-.241.244-.475.495-.7.754-.05.056-.097.114-.146.17-.227.267-.446.542-.656.822l-.051.072c-.2.273-.39.55-.573.834-.036.056-.073.11-.108.166a17.52 17.52 0 0 0-.423.718h7.095l6.551-1.618"
        />
        <path
          fill="#E3DDDB"
          d="M29.605 22.04a21.327 21.327 0 0 0 .063-1.313c.004-.196.003-.391.002-.587v-.17a30.065 30.065 0 0 0-.055-1.513l-.004-.003-.002-.003-.563-.322-.013-.008-.147-.083-.003-.001-.102-.057-.02-.012a13.952 13.952 0 0 0-1.071-.509c-.807-.34-1.894-.639-2.506-.783v1.127l1.544 4.77.002.005a2.229 2.229 0 0 1 .505.714l.002.004c.03.066.056.134.079.204l.002.004c.022.07.042.14.058.213l.002.006c.016.072.029.147.038.222v.003c.009.077.014.155.015.233l.006.013h1.89c.127-.693.218-1.393.273-2.096l.005-.058"
        />
        <path fill="#FCB03F" d="M32.077 20.353l-.003.007.003-.007" />
        <path
          fill="#909393"
          d="M23.627 22.566a2.303 2.303 0 0 1 .696-.447l.01-.004c.13-.052.262-.091.398-.118l.014-.003a2.262 2.262 0 0 1 .86.001l.01.002a2.243 2.243 0 0 1 1.115.58l-.002-.003-1.544-4.77-1.557 4.762"
        />
        <path
          fill="#676A6A"
          d="M23.078 25.068a2.228 2.228 0 0 1-.123-.408l-.001-.009a2.25 2.25 0 0 1-.031-.673l.008-.054c.008-.059.016-.118.028-.176l.013-.05c.013-.058.028-.116.046-.173l.013-.035c.02-.06.043-.12.068-.18l.01-.02a2.14 2.14 0 0 1 .092-.183l.005-.007c.11-.194.248-.37.41-.524l-6.551 1.618h-.013l6.489 1.584a2.27 2.27 0 0 1-.46-.705l-.003-.005"
        />
        <path
          fill="#E3DDDB"
          d="M37.82 23.84c.003-.029.01-.056.015-.085.016-.093.035-.184.06-.27l.008-.03.854-3.915v-2.232c-.698-.576-1.446-.892-2.225-.892-.984 0-1.901.544-2.587 1.215-.751.734-1.331 1.658-1.8 2.59l-.065.128c.144.125.287.252.428.381.32.293.633.592.94.897.145.141.285.286.427.43.15.153.3.306.448.46a50.877 50.877 0 0 1 1.468 1.614v.001l.073.078h1.927c0-.016.004-.03.004-.045.002-.112.01-.22.024-.325"
        />
        <path
          fill="#525353"
          d="M8.808 26.657l.017-.043c.259-.671.557-1.327.894-1.963.052-.1.103-.199.157-.297.03-.054.064-.106.094-.16.137-.242.275-.483.423-.718.035-.056.072-.11.108-.166.182-.283.373-.561.573-.834l.05-.072c.21-.28.43-.555.657-.821l.146-.171c.225-.26.459-.51.7-.754l.145-.141c.255-.253.52-.497.793-.73l.002-.002c1.078-3.612 4.56-5.832 8.464-5.832 1.57 0 3.134.453 4.473 1.272 1.373.84 2.31 1.661 3.037 3.096l.01.022c.02.036.04.07.058.108l.002.003.004.003c.61.378 1.192.8 1.74 1.265.185.155.365.315.543.479.058.053.118.104.176.159l.003-.007.002-.004.06-.123c.55-3.472 2.298-5.365 4.104-5.365 1.362 0 2.352.812 3.1 2.998l.007.023.002.003c.22.24.426.494.616.759l.012.015.03.096-.028-.114c-2.247-6.653-8.54-11.443-15.951-11.443-9.297 0-16.833 7.536-16.833 16.833 0 1.832.293 3.597.837 5.238l.002-.01a18.04 18.04 0 0 1 .77-2.602"
        />
        <path
          fill="#FFF"
          d="M40.233 24.217l-.587.656-.001.005c-.023.086-.05.167-.08.244l-.025.06a1.713 1.713 0 0 1-.076.157l-.044.079a1.416 1.416 0 0 1-.199.244c-.017.016-.034.034-.053.048a.782.782 0 0 1-.142.09.6.6 0 0 1-.264.065c-.534 0-.966-.74-.966-1.655h-1.938v.002c.365.42.723.846 1.076 1.277.339.414.673.831 1.004 1.252l.049.062c.313.4.623.801.93 1.205l.09.117c.326.43.65.862.973 1.295a16.8 16.8 0 0 0 .883-5.21l-.545-.02-.085.027"
        />
        <path
          fill="#000"
          d="M21.473 16.443l.132-.01c.302-.02.604-.032.907-.033.913 0 1.805.098 2.672.276.612.144 1.7.443 2.506.784.338.143.671.299.997.468l.074.04.02.012.102.057.003.001.147.083.013.008c.207.117.437.25.524.3l.023.012.016.01c-.018-.037-.039-.072-.057-.108l-.011-.022a8.763 8.763 0 0 0-3.22-3.506 8.626 8.626 0 0 0-4.473-1.272c-3.904 0-7.203 2.63-8.281 6.242a14.477 14.477 0 0 1 3.378-2.165 13.38 13.38 0 0 1 4.528-1.177"
        />
        <path
          fill="#BBBCBD"
          d="M39.98 18.66v-.001l-.012-.015a8.164 8.164 0 0 0-.616-.76l-.006-.006a6.84 6.84 0 0 0-.29-.297c-.037-.035-.076-.066-.113-.1-.062-.056-.123-.114-.186-.166v2.233l.855 3.915.03.09.591.664.006-.007h.62c0-.057.004-.114.004-.172a16.8 16.8 0 0 0-.853-5.283l-.03-.096"
        />
        <path
          fill="#000"
          d="M33.945 17.63c.686-.67 1.603-1.214 2.587-1.214.779 0 1.527.316 2.225.892v.008c.063.05.124.109.186.165.037.034.076.064.113.1.1.096.196.195.29.297l.006.007a.01.01 0 0 0-.002-.003l-.008-.023c-.747-2.186-1.954-3.606-3.315-3.606-1.806 0-3.338 2.501-3.888 5.973l.006-.005c.469-.932 1.049-1.856 1.8-2.59m-2.441 21.338s-.244-.193-.552-.548a5.583 5.583 0 0 1-.519-.695c-.116-.185-.21-.41-.312-.632-.12-.257-.12-.428-.188-.668a4.719 4.719 0 0 1-.176-1.423 5.19 5.19 0 0 1 .153-1.061 6.388 6.388 0 0 1 .738-1.736l.094-.21c.12-.2.146-.191.26-.384 1.606-2.71 2.472-4.759 2.049-7.914-.047-.323-.1-.646-.159-.967a38.48 38.48 0 0 1-.117.697c-.016.072-.06.15-.076.2-.697 2.064-1.795 3.583-3.375 5.297-.22.238-.443.473-.668.706-.403.416-.81.828-1.22 1.236-.17.17-.345.342-.523.518-.163.162-.365.506-.607.76-.15.418-.32.89-.463 1.385-.064-.024-.126-.046-.192-.072-3.069-1.187-5.123-2.773-5.123-2.773s1.58 2.059 4.244 3.991c.245.178.499.342.761.493-.11 1.74.658 3.409 4.12 3.907.586.084 1.186.08 1.79.012-.006-.018.034-.017.03-.027-.003-.01.033-.037.033-.037"
        />
        <path
          fill="#9F2254"
          d="M29.651 34.492c3.075.496 7.625.415 12.349-1.797.114-.053.24.087.353.031 0 0-3.229 3.298-7.084 5.162-.113.055-.224.113-.338.164-.372.17-.751.319-1.132.456-.746.268-1.502.471-2.252.565l-.104.015a4.649 4.649 0 0 1-.014-.042l-.005-.016s-.244-.193-.552-.548a5.479 5.479 0 0 1-.851-1.307 4.93 4.93 0 0 1-.268-.719 4.635 4.635 0 0 1-.128-1.952l.026-.012"
        />
        <path
          fill="#FCB03F"
          d="M27.447 30.992c.411-.408.818-.82 1.22-1.236.225-.233.448-.468.668-.706 1.58-1.714 2.74-3.319 3.437-5.383.017-.05.033-.114.049-.186l.007-.034c.017-.077.033-.163.049-.26.006-.066.215-.2.22-.263-.174-.898-.578-1.843-1.018-2.565a.163.163 0 0 1 .003.009l-.008-.008c-.058-.055-.118-.106-.176-.16a16.117 16.117 0 0 0-.543-.478 14.492 14.492 0 0 0-1.74-1.265c.03.505.05 1.01.056 1.514l-.001.17c0 .195.002.39-.002.586a23.62 23.62 0 0 1-.063 1.313l-.005.058a20.641 20.641 0 0 1-.273 2.096l-.222 1.05c-.464 1.88-1.405 3.585-2.18 5.346-.205.467-.4.939-.57 1.42l-.05.135c.204-.214.41-.426.62-.635l.522-.518"
        />
        <path
          fill="#525353"
          d="M17.287 41.102c1.127-.498 2.17-.81 3.409-1.055-.006-.269-.02-.537-.042-.804a73.465 73.465 0 0 0 1.464-4.157 21.15 21.15 0 0 0-2.35.364 21.124 21.124 0 0 0-3.046.903l1.75.424-1.408 4.419.223-.094"
        />
        <path
          fill="#DF0067"
          d="M38.917 28.008a107.56 107.56 0 0 0-.93-1.205l-.05-.062a79.257 79.257 0 0 0-1.016-1.268 61.535 61.535 0 0 0-1.062-1.261l-.069-.08a50.877 50.877 0 0 0-1.467-1.614 41.532 41.532 0 0 0-.448-.46c-.142-.145-.282-.29-.426-.431a35.354 35.354 0 0 0-.94-.897c-.142-.129-.285-.256-.43-.381a.033.033 0 0 0-.002.004c.304.874.651 1.76.825 2.67.045.238.083.469.113.694.424 3.155-.587 5.215-2.193 7.925-.117.198-.235.396-.354.593a6.4 6.4 0 0 0-.738 1.737 5.241 5.241 0 0 0-.105.532c3.149.508 7.884.603 12.728-1.778 0 0-.95-1.396-2.38-3.315-.32-.43-.642-.859-.966-1.286l-.09-.117"
        />
        <path
          fill="#AA9678"
          d="M16.897 41.985l1.575-5.208-1.75-.424c-.392.147-.78.304-1.163.475l-1.56 5.157 1.741.028 1.157-.028"
        />
        <path
          fill="#FFF"
          d="M18.004 39.732c.153.06.31.112.466.167 1.787.627 3.667.947 5.56.946a16.778 16.778 0 0 0 6.287-1.218c.103-.041.204-.087.305-.13.165-.07.329-.143.491-.218.146-.067.29-.134.434-.206l-.104.015a7.031 7.031 0 0 1-1.79-.012c-3.462-.498-4.23-2.168-4.12-3.907a8.637 8.637 0 0 1-.76-.493c-2.665-1.932-4.245-3.99-4.245-3.99s2.054 1.585 5.123 2.772c.066.026.128.048.192.072.144-.495.314-.967.463-1.385l.048-.135c.171-.481.366-.953.571-1.42.775-1.76 1.716-3.466 2.18-5.346l.222-1.05h-1.894v.02a2.255 2.255 0 0 1-1.381 2.083 2.252 2.252 0 0 1-1.115.166l-.054-.008a2.39 2.39 0 0 1-.175-.028c-.016-.003-.031-.008-.047-.012a2.175 2.175 0 0 1-.175-.046l-.031-.012a2.262 2.262 0 0 1-.183-.069l-.015-.007a2.266 2.266 0 0 1-.188-.094l-.003-.001a2.283 2.283 0 0 1-.525-.408l-6.489-1.584H9.97c-.03.054-.064.106-.094.16-.054.098-.105.198-.157.297a18.045 18.045 0 0 0-.894 1.963l-.017.043a18.04 18.04 0 0 0-.77 2.603 16.874 16.874 0 0 0 6.119 8.382c.082.06.17.113.253.171a16.834 16.834 0 0 0 3.594 1.919"
        />
        <path
          fill="#8B7460"
          d="M34.984 43.758a13.98 13.98 0 0 1-.07-1.355.497.497 0 0 0-.497-.497H13.583a.497.497 0 0 0-.496.497 13.92 13.92 0 0 1-.296 2.819c.302.16.607.315.917.462.207-.904.328-1.825.362-2.752l.002-.032h19.856l.002.032c.034.927.155 1.848.362 2.752.31-.148.615-.302.917-.462a13.79 13.79 0 0 1-.225-1.464"
        />
      </g>
    </svg>
  ),
  bigCommerce: (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 48 48"
      >
        <g fill="#34313F" fillRule="nonzero">
          <path d="M25.291 27.29h6.05c1.721 0 2.81-.947 2.81-2.467 0-1.434-1.089-2.467-2.81-2.467h-6.05c-.2 0-.372.172-.372.345v4.243c.027.2.17.345.372.345zm0 9.777h6.251c1.921 0 3.068-.975 3.068-2.696 0-1.492-1.089-2.696-3.068-2.696h-6.251c-.2 0-.372.171-.372.345v4.675c.027.229.17.372.372.372z" />
          <path d="M47.428.106L29.534 17.942h2.925c4.56 0 7.255 2.867 7.255 5.992 0 2.467-1.663 4.273-3.441 5.076-.287.116-.287.516.029.63 2.063.804 3.526 2.954 3.526 5.476 0 3.556-2.38 6.394-6.997 6.394H20.158c-.2 0-.372-.171-.372-.345V27.66L.143 47.218c-.259.258-.087.716.287.716h47.227a.319.319 0 0 0 .316-.316V.364c.085-.316-.316-.487-.545-.258z" />
        </g>
      </svg>
    </>
  ),
  ameriCommerce: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 48 48"
    >
      <path
        fill="#FF703E"
        fillRule="nonzero"
        d="M32.84.093c-16.347 0-15.253 16.96-15.253 16.96S.093 17.053.093 32.547c0 15.493 17 15.253 17 15.253l30.254.12V16.987C47.333 16.987 48.96.093 32.84.093zM17.08 39.04s-8.493 1-8.493-6.88 8.493-6.88 8.493-6.88v13.76zM32.587 8.547c7.493 0 6.746 8.253 6.746 8.253H25.587s-.507-8.253 7-8.253zM39.36 39.04H25.573V25.293h13.774V39.04h.013z"
      />
    </svg>
  ),
};

export const ChatWidgetInstallInstructions = ({
  connectionId,
  openPanelOnShow,
  tabPosition,
}: {
  connectionId: string;
  openPanelOnShow?: boolean;
  tabPosition?: string;
}) => {
  useEffect(() => {
    if (openPanelOnShow) {
      const timer = setTimeout(() => {
        if (panelRef.current) {
          panelRef.current.openPanel();
        }
      }, 400);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [openPanelOnShow]);

  const panelRef = useRef<any>(null);

  return (
    <CollapsibleConfigurationSection
      icon={"ri-code-box-line"}
      title={"Install Widget"}
      description={
        "A simple way to place the widget on any website or platform"
      }
      ref={panelRef}
      skipScrollingIntoView={true}
    >
      <ChatWidgetInstallInstructionsInner
        connectionId={connectionId}
        tabPosition={tabPosition}
      />
    </CollapsibleConfigurationSection>
  );
};

export const ChatWidgetInstallInstructionsInner = ({
  connectionId,

  tabPosition,
}: {
  connectionId: string;
  tabPosition?: string;
}) => {
  const { state: widget, retry: reload } = useQueryWithStore(
    selectChatWidgetByConnectionId(connectionId),
    loadAllChatWidgets(),
    [connectionId],
  );

  const widgetSnippet = useMemo(() => {
    //   return `<script>
    //   function loadCCWidget(){
    //     var widget = new window["click-connector-widget"]();
    //     widget.mount({});
    //   }

    //   function loadCCScript(){
    //     var script = document.createElement('script');
    //     script.id = "cc-widget-script";
    //     script.setAttribute('data-widget-id', '${widget?.id}');
    //     script.type = "text/javascript";
    //     script.defer = true;
    //     script.addEventListener("load", function(event) {
    //         loadCCWidget();
    //     });
    //     script.src = "${GlobalConfig.CHAT_WIDGET_BASE}/widget.js";
    //     document.getElementsByTagName('head')[0].appendChild(script);
    //   }
    //   loadCCScript();
    // </script>

    //   return `
    //   <script>
    //   function loadCCWidget(){
    //     const widget = new window["click-connector-widget"]();
    //     widget.mount({});
    //   }
    // </script>
    // <script src="${GlobalConfig.CHAT_WIDGET_BASE}/widget.js" id="cc-widget-script" data-widget-id="${widget?.id}" defer async onload="loadCCWidget()"></script>`;

    return `<script>
function loadCCWidget(){(new window["click-connector-widget"]).mount({})}function loadCCScript(){var t=document.createElement("script");t.id="cc-widget-script",t.setAttribute("data-widget-id","${widget?.id}"),t.type="text/javascript",t.defer=!0,t.addEventListener("load",(function(t){loadCCWidget()})),t.src="${GlobalConfig.CHAT_WIDGET_BASE}/widget.js",document.getElementsByTagName("head")[0].appendChild(t)}loadCCScript();
</script>`;
  }, [widget]);

  const {
    doAction: sendToDeveloper,
    isProcessing,
    response,
    dispatch,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (email) =>
        SDK.chatWidgets.sendScriptToDeveloper(widget?.id!, email),
      successMessage: "Email has been sent",
      failureMessage: "Something went wrong",
    }),
    [widget?.id],
  );

  const [sendToDeveloperForm] = useForm();

  const panelRef = useRef<any>(null);

  const widgetDirectUrl = useMemo(
    () => `https://widget.clickconnector.app/${widget?.id}/`,
    [widget?.id],
  );

  const shopifyStoreConfig = useSelector(selectShopifyStoreConfig);

  const isDarkMode = useSelector(selectIsDarkMode);

  const scriptCode = useMemo(() => {
    return (
      <>
        <Input.TextArea
          value={widgetSnippet}
          readOnly={true}
          className="mt-4"
          style={{ height: 200 }}
          onClick={() => {
            copyToClipboard(widgetSnippet);
            message.success("Snippet has been copied to clipboard");
          }}
          onFocus={(event) => event.target.select()}
        ></Input.TextArea>
        <div className="flex flex-row justify-end items-center mb-4">
          <Button
            type="text"
            onClick={() => {
              copyToClipboard(widgetSnippet);
              message.success("Snippet has been copied to clipboard");
            }}
          >
            Click here to copy
          </Button>
        </div>
      </>
    );
  }, [widgetSnippet]);

  const instructions = useMemo(() => {
    return [
      {
        label: (
          <div className="flex flex-row">
            <i className="ri-javascript-line"></i>{" "}
            <div className="ml-2">React / Angular / JS</div>
          </div>
        ),
        key: `NPM`,
        children: (
          <>
            <div className="flex flex-row justify-center items-center mb-4">
              <img
                src="/assets/app-installation/react-angular-vue.png"
                alt="React Angular Vue"
                width={120}
              />
            </div>
            <EmbedWidgetSetup connectionId={connectionId} />

            <HelpCenterPromptButton
              type="BANNER"
              text="💡 You can use Chat Widget API to create custom experiences"
              description="Learn about Chat Widget JS APIs"
              articleId="fc89e0-b4c0b"
              // collectionId="fc89e0-d943b"
              className="my-2"
            />
          </>
        ),
      },
      {
        label: (
          <div className="flex flex-row">
            <i className="ri-code-line"></i>{" "}
            <div className="ml-2">Any Website</div>
          </div>
        ),
        key: `Any Website`,
        children: (
          <>
            <ol>
              <li>
                <span>
                  1. Copy and paste this code before the closing of{" "}
                  <span className="text-green-600">{`</body>`}</span> tag on
                  every page of your website
                </span>
                {scriptCode}
              </li>
              <li>
                2. Reload your website. LiveChat should appear in the bottom
                right corner.
              </li>
            </ol>
            <span>
              Need help?{" "}
              <Popover
                placement="bottom"
                trigger="click"
                content={
                  <>
                    <Form
                      layout="vertical"
                      form={sendToDeveloperForm}
                      onFinish={async () => {
                        try {
                          await sendToDeveloperForm.validateFields();
                        } catch (e) {
                          message.warning("Invalid Email");
                          return;
                        }
                        const { email } = sendToDeveloperForm.getFieldsValue();
                        await sendToDeveloper(email).catch((e) => {});
                        sendToDeveloperForm.resetFields();
                      }}
                    >
                      <div className="flex flex-col" style={{ minWidth: 400 }}>
                        <Form.Item
                          name="email"
                          hasFeedback
                          validateFirst={true}
                          rules={[{ type: "email", required: true }]}
                          label="Email"
                        >
                          <Input
                            prefix={
                              <MailOutlined className="site-form-item-icon" />
                            }
                            placeholder="Email"
                            size="large"
                          />
                        </Form.Item>
                        <div className="flex flex-row justify-end items-center">
                          <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            loading={isProcessing}
                          >
                            Send Snippet
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </>
                }
                title={
                  <div className="font-bold">
                    <Space>
                      <i className="ri-mail-send-line"></i>
                      Send to Developer
                    </Space>
                  </div>
                }
              >
                <Button type="link">Send this task to your developer</Button>
              </Popover>
            </span>
            <HelpCenterPromptButton
              type="BANNER"
              text="💡 You can use Chat Widget API to create custom experiences"
              description="Learn about Chat Widget JS APIs"
              articleId="fc89e0-b4c0b"
              // collectionId="fc89e0-d943b"
              className="my-2"
            />
          </>
        ),
      },
      // Shopify App
      {
        label: (
          <div className="flex flex-row">
            {platformIcons.shopify} <div className="ml-2">Shopify</div>
          </div>
        ),
        key: `Shopify (App)`,
        children: (
          <>
            <div className="p-4 flex flex-row justify-center items-center">
              <a
                href="https://apps.shopify.com/click-connector"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src={
                    isDarkMode
                      ? "/assets/integrations/Shopify-App-Store-Badge-Final-Black.svg"
                      : "/assets/integrations/Shopify-App-Store-Badge-Final-White.svg"
                  }
                  alt="Find us in Shopify App Store"
                  style={{ width: 300 }}
                />
              </a>
            </div>
          </>
        ),
      },
      // Shopify Manual
      // {
      //   label: (
      //     <div className="flex flex-row">
      //       {platformIcons.shopify} <div className="ml-2">Shopify (Manual)</div>
      //     </div>
      //   ),
      //   key: `Shopify (Manual)`,
      //   children: (
      //     <>
      //       <ol>
      //         <li>1. Login to your Shopify panel</li>
      //         <li>
      //           2. Go to Online Store &gt; Themes &gt; Actions drop-down menu
      //           under Current Theme &gt; Edit code &gt; theme.liquid
      //         </li>
      //         <li>
      //           <span>
      //             4. Copy and paste this code before the closing of{" "}
      //             <span className="text-green-600">{`</body>`}</span> tag at the
      //             end of the theme.liquid script and save
      //           </span>
      //           {scriptCode}
      //         </li>
      //       </ol>
      //     </>
      //   ),
      // },
      // WordPress
      {
        label: (
          <div className="flex flex-row">
            {platformIcons.wordpress} <div className="ml-2">WordPress</div>
          </div>
        ),
        key: `WordPress`,
        children: (
          <>
            <ol>
              <li>1. Login to your WordPress admin panel</li>
              <li>2. Go to Appearance &gt; Theme Editor</li>
              <li>
                3. Under Theme Files (far right), find the Theme Footer
                (footer.php) and select it
              </li>
              <li>
                <span>
                  4. Copy and paste this code before the closing of{" "}
                  <span className="text-green-600">{`</body>`}</span> tag at the
                  bottom
                </span>
                {scriptCode}
              </li>
              <li>5. Click Update File to save changes</li>
            </ol>
          </>
        ),
      },
      // Presta Shop
      // {
      //   label: (
      //     <div className="flex flex-row">
      //       {platformIcons.prestaShop} <div className="ml-2">Presta Shop</div>
      //     </div>
      //   ),
      //   key: `Prestashop`,
      //   children: (
      //     <>
      //       <ol>
      //         <li>1. In your PrestaShop panel select “Add new module“</li>
      //         <li>
      //           2. Download the HTMLbox module (
      //           <a
      //             target="_blank"
      //             rel="noopener noreferrer"
      //             href="https://mypresta.eu/modules/front-office-features/html-box.html"
      //           >
      //             https://mypresta.eu/modules/front-office-features/html-box.html
      //           </a>
      //           ) and add it to your shop by selecting “choose file” then click
      //           “Upload this module”
      //         </li>
      //         <li>
      //           3. Go to “Modules &gt; Modules” , find HTMLbox module and click
      //           “Install”
      //         </li>
      //         <li>4. After installation of the module go to “configure”</li>
      //         <li>
      //           <span>5. Copy and paste this code</span>
      //           {scriptCode}
      //         </li>
      //       </ol>
      //     </>
      //   ),
      // },
      // // Big Commerce
      // {
      //   label: (
      //     <div className="flex flex-row">
      //       {platformIcons.bigCommerce} <div className="ml-2">Big Commerce</div>
      //     </div>
      //   ),
      //   key: `Big Commerce`,
      //   children: (
      //     <>
      //       <ol>
      //         <li>1. Login to your Bigcommerce panel</li>
      //         <li>2. Go to the 'Storefront' section</li>
      //         <li>3. Select 'Footer Scripts' from the list</li>

      //         <li>
      //           <span>
      //             5. Copy and paste this code into the text area and save your
      //             changes
      //           </span>
      //           {scriptCode}
      //         </li>
      //       </ol>
      //     </>
      //   ),
      // },
      // // Ameri Commerice
      // {
      //   label: (
      //     <div className="flex flex-row">
      //       {platformIcons.ameriCommerce}{" "}
      //       <div className="ml-2">AmeriCommerce</div>
      //     </div>
      //   ),
      //   key: `AmeriCommerce`,
      //   children: (
      //     <>
      //       <ol>
      //         <li>1. Log into your AmeriCommerce panel</li>
      //         <li>
      //           2. Go to the Themes tab on the left side your AmeriCommerce
      //           panel
      //         </li>
      //         <li>3. Click “Edit“ in the theme preview</li>
      //         <li>4. Go to the “Templates / Content Only“ tab</li>

      //         <li>
      //           <span>
      //             5. Copy and paste this code just before &lt;/body&gt;
      //           </span>
      //           {scriptCode}
      //         </li>
      //         <li>6. Click “Save“ to finish the integration process</li>
      //       </ol>
      //     </>
      //   ),
      // },
      {
        label: (
          <div className="flex flex-row">
            <i className="ri-mac-line"></i>
            <div className="ml-2">Direct Chat Page </div>
          </div>
        ),
        key: `Direct URL`,
        children: (
          <>
            You can access your live chat widget from the below URL
            <div
              className="flex flex-row items-center justify-between text-xl m-4 bg-gray-100 dark:bg-gray-800 p-4 rounded-lg cursor-pointer"
              onClick={() => {
                copyToClipboard(widgetDirectUrl);
                message.success("URL has been copied to clipboard");
              }}
            >
              <div className="left">{widgetDirectUrl}</div>
              <div className="right">
                <Tooltip title="Copy URL to clipboard">
                  <Button
                    type="text"
                    icon={<i className="ri-clipboard-line"></i>}
                  ></Button>
                </Tooltip>
              </div>
            </div>
          </>
        ),
      },
    ];
  }, [
    isDarkMode,
    isProcessing,
    scriptCode,
    sendToDeveloper,
    sendToDeveloperForm,
    widget?.id,
    widgetDirectUrl,
  ]);

  return (
    <>
      {shopifyStoreConfig?.config?.shop && (
        <div className="mb-8">
          <div className="flex flex-row justify-between my-8">
            <div className="text">Connected Shop</div>
            <Tag className="font-bold">{shopifyStoreConfig?.config?.shop}</Tag>
          </div>
          <ShopifyInstallInstruction
            storeDomain={shopifyStoreConfig?.config?.shop}
          />
        </div>
      )}

      <Tabs tabPosition={(tabPosition as any) || "left"} items={instructions} />
    </>
  );
};
