import { Avatar as _Avatar } from "antd";
import React, { useMemo } from "react";
import { LightenDarkenColor } from "../../../utils/color-utils";
import { getHashedColor } from "../../../utils/get-hashed-color";

export const NamedAvatar = ({
  name,
  size,
  style,
  className,
}: {
  name?: string;
  size?: number;
  style?: React.CSSProperties;
  className?: string;
}) => {
  const firstLetter = name?.charAt(0).toUpperCase() || "U";

  const avatarStyle = useMemo(() => {
    return {
      color: LightenDarkenColor(getHashedColor(firstLetter || ""), 0),
      backgroundColor: LightenDarkenColor(
        getHashedColor(firstLetter || ""),
        160,
      ),
      ...(style || {}),
    };
  }, [style, firstLetter]);

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <_Avatar style={avatarStyle} size={size} className={className}>
      {firstLetter}
    </_Avatar>
  );
};
