import { axios, AxiosResponse } from "@sdk/axios";
import { UserTracker } from "user-tracker";

export class MailchimpServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  mailchimp = {
    getAllList: async (apiKey: string) => {
      const results: AxiosResponse<any> = await axios.post(
        this.config.basePath + `/mailchimp/get-lists`,
        { apiKey },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getProfile: async (email: string) => {
      const results: AxiosResponse<MailchimpGetProfileRes> = await axios.post(
        this.config.basePath + `/mailchimp/get-profile`,
        { email },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    changeSubscriptionStatus: async (req: {
      email: string;
      status: "subscribed" | "unsubscribed";
    }) => {
      UserTracker.track("MAIL_CHIMP - Change Subscription Status", {});
      const results: AxiosResponse<any> = await axios.post(
        this.config.basePath + `/mailchimp/subscribe`,
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
  };
}

interface MailchimpGetProfileRes {
  exact_matches: Exactmatches;
  full_search: Fullsearch;
  _links: Link2[];
}

interface Link2 {
  rel: string;
  href: string;
  method: string;
  targetSchema: string;
}

interface Fullsearch {
  members: any[];
  total_items: number;
}

interface Exactmatches {
  members: Member[];
  total_items: number;
}

interface Member {
  id: string;
  email_address: string;
  unique_email_id: string;
  contact_id: string;
  full_name: string;
  web_id: number;
  email_type: string;
  status: string;
  consents_to_one_to_one_messaging: boolean;
  merge_fields: Mergefields;
  stats: Stats;
  ip_signup: string;
  timestamp_signup: string;
  ip_opt: string;
  timestamp_opt: string;
  member_rating: number;
  last_changed: string;
  language: string;
  vip: boolean;
  email_client: string;
  location: Location;
  source: string;
  tags_count: number;
  tags: any[];
  list_id: string;
  _links: Link[];
}

interface Link {
  rel: string;
  href: string;
  method: string;
  targetSchema?: string;
  schema?: string;
}

interface Location {
  latitude: number;
  longitude: number;
  gmtoff: number;
  dstoff: number;
  country_code: string;
  timezone: string;
  region: string;
}

interface Stats {
  avg_open_rate: number;
  avg_click_rate: number;
}

interface Mergefields {
  FNAME: string;
  LNAME: string;
  ADDRESS: string;
  PHONE: string;
}
