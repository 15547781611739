import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import axios, { AxiosResponse } from "axios";
import { DeepPartial } from "redux";
import { UserTracker } from "user-tracker";
import { iScreenRecording } from "./screen-recordings-model";

export class ScreenRecordingsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.screenRecordings.configure(config);
  }

  screenRecordings = Object.assign(
    new EntityServices<iScreenRecording>(SDKConfig, "screen-recordings", {
      onCreate: () => {
        UserTracker.track("SCREEN_RECORDINGS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("SCREEN_RECORDINGS - Delete", {});
      },
    }),
    {
      getUploadMetaData: async (req: DeepPartial<iScreenRecording>) => {
        UserTracker.track("SCREEN_RECORDINGS - Get Token", {});
        const results: AxiosResponse<{
          uploadToken: {
            token: string;
            ttl: number;
            createdAt: number;
            expiresAt: number;
          };
          video: {
            videoId: string;
          };
        }> = await axios.post(
          this.config.basePath + `/screen-recordings/get-upload-meta-data`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
