import MenuOutlined from "@ant-design/icons/MenuOutlined";
import {
  SortableContainer as sortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

export const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

export const SortableItem = SortableElement((props) => <tr {...props} />);
export const SortableContainer = sortableContainer((props) => (
  <tbody {...props} />
));

export const DraggableBodyRowGenerator = (state) => ({
  // className,
  // style,
  ...restProps
}) => {
  const dataSource = state;
  // function findIndex base on Table rowKey props and should always be a right array index
  const index = dataSource.findIndex((x) => x.id === restProps["data-row-key"]);
  return <SortableItem index={index} {...restProps} />;
};

export const DraggableContainerGenerator = (onSortEnd) => (props) => (
  <SortableContainer
    useDragHandle
    helperClass="row-dragging"
    onSortEnd={onSortEnd}
    {...props}
  />
);
