import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ConfigureChatInputModal = React.lazy(
  () => import("./configure-chat-input.modal"),
);

const ConfigureChatInput: iModalProps<typeof ConfigureChatInputModal> = {
  name: "configure-chat-input",
  component: GenerateModalWithSuspense(ConfigureChatInputModal),
};

export default ConfigureChatInput;
