import { Store } from "@reduxjs/toolkit";
import { SDK } from "../../../@sdk/sdk";
import { iUserGroup } from "../../../@sdk/user-management/user-management.models";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { groupsDefaultQueryConfig } from "./groups-default-query";
import { GroupsSlice } from "./groups.slice";

export const LoadAllGroups = (store: Store<iStore>, forceReload?: boolean) =>
  loadGroupsQuery(groupsDefaultQueryConfig, "all")(store, forceReload);

export const {
  loadEntityQueries: loadGroupsQuery,
  loadEntityById: loadGroupById
} = CreateHelpersForEntity<iStore, iUserGroup>({
  sliceName: "userGroups",
  slice: GroupsSlice,
  isPaginatedQueries: false,
  loadEntityQueryProvider: async () => {
    const results = await SDK.getAllGroups();
    return results;
  },
  loadEntityByIdProvider: (entityId: string) => SDK.getGroupById(entityId)
});
