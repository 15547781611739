import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddChatWidgetConnectionModal = React.lazy(
  () => import("./add-chat-widget-connection.modal"),
);

const AddChatWidget: iModalProps<typeof AddChatWidgetConnectionModal> = {
  name: "add-chat-widget",
  component: GenerateModalWithSuspense(AddChatWidgetConnectionModal),
};

export default AddChatWidget;
