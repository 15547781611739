import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ViewCampaignReportModal = React.lazy(
  () => import("./view-campaign-report.modal"),
);

const ViewCampaignReport: iModalProps<
  typeof ViewCampaignReportModal,
  {
    campaignId: string;
  }
> = {
  name: "campaign-report",
  component: GenerateModalWithSuspense(ViewCampaignReportModal),
};

export default ViewCampaignReport;
