import { iProduct } from "@sdk/products/products-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { productsDefaultQueryConfig } from "./products-default-query";

export const {
  slice: ProductsSlice,
  reducers: ProductsSliceReducer,
  actions: {
    setEntityQueryResults: setProductsQueryResults,
    setEntity: setProduct,
    setEntities: setProducts,
    patchEntity: patchProduct,
    addNewEntity: addNewProduct,
    setEntityFetchingStatus: setProductFetchingStatus,
    setEntitiesFetchingStatus: setProductsFetchingStatus,
    setEntityFailedStatus: setProductFailedStatus,
    setEntityQueryError: setProductsQueryError,
    resetQuery: resetProductsQuery,
    setQueryList: setProductQueryList
  }
} = CreateEntitySlice<iProduct>({
  sliceName: "products",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...productsDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
