import { AppStateSliceActions } from "./app-state.slice";

export const {
  setSocketStatus,
  addRecentLoginData,
  removeRecentLoginData,
  updateRecentLoginData,
  setSwitchingProfile,
  setLastAppVersion,
  setAppData
} = AppStateSliceActions;
