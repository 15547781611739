import { iConversation } from "@sdk/conversations/conversations.models";
import { buildChatViewQueryConfigs } from "components/modules/conversations/helpers/build-chat-view-queries";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";

export const {
  slice: ConversationsSlice,
  reducers: ConversationsSliceReducer,
  actions: {
    setEntityQueryResults: setConversationsQueryResults,
    setEntity: setConversation,
    setEntities: setConversations,
    patchEntity: patchConversation,
    addNewEntity: addNewConversation,
    setEntityFetchingStatus: setConversationFetchingStatus,
    setEntitiesFetchingStatus: setConversationsFetchingStatus,
    setEntityFailedStatus: setConversationFailedStatus,
    setEntityQueryError: setConversationsQueryError,
    resetQuery: resetConversationsQuery,
    setQueryList: setConversationQueryList
  }
} = CreateEntitySlice<iConversation>({
  sliceName: "conversations",
  queryAliasResolver: (queryConfigAlias: string) => {
    const currentUserId = window["CC_CURRENT_USER_ID"];

    const chatViews = buildChatViewQueryConfigs(currentUserId);
    if (chatViews[queryConfigAlias]) {
      return {
        ...chatViews[queryConfigAlias],
        alias: queryConfigAlias
      };
    }
  }
});
