import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const JiraAddTaskModal = React.lazy(() => import("./jira-add-issue.modal"));

const JiraAddTask: iModalProps<
  typeof JiraAddTaskModal,
  {
    onAdded?: (pageId: string) => any;
    contactId?: string;
    conversationId?: string;
  }
> = {
  name: "jira-add-task",
  component: GenerateModalWithSuspense(JiraAddTaskModal),
};

export default JiraAddTask;
