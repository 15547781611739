import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const BgPatternPickerModal = React.lazy(
  () => import("./bg-pattern-selector.modal"),
);

const BgPatternPicker: iModalProps<
  typeof BgPatternPickerModal,
  {
    primaryColor: string;
    onSelected: (url: string) => any;
  }
> = {
  name: "bg-pattern-picker",
  component: GenerateModalWithSuspense(BgPatternPickerModal),
};

export default BgPatternPicker;
