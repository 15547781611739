import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import { iCallLog } from "./call-logs-model";

export class CallLogsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.callLogs.configure(config);
  }

  callLogs = Object.assign(
    new EntityServices<iCallLog>(SDKConfig, "call-logs"),
    {},
  ) as EntityServices<iCallLog>;
}
