export const EmailBlock_Logo: (data: {
  url: string;
  companyName: string;
}) => string = ({ url, companyName }) => {
  return /*html*/ `<table class="row">
    <tbody>
        <tr>
            <!-- Logo -->
            <th class="small-12 large-12 columns first last" style="padding-top: 0px;">
                <table>
                    <tr class="primary-background" height="2">
                        <th>
                        </th>
                        <th class="expander"></th>
                    </tr>
                    <tr>
                        <th>
                            &#xA0;
                        </th>
                        <th class="expander"></th>
                    </tr>
                    <tr>
                        <th>
                            <center data-parsed="">
                            <img src="${url}" class="swu-logo"
                                    alt="${companyName}" width="50" height="50">
                            </center>
                        </th>
                        <th class="expander"></th>
                    </tr>
                </table>
            </th>
        </tr>
    </tbody>
</table>`;
};
