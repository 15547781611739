import { Button } from "antd";

const Keypads = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "+"];

export const TelnyxKeyPad = ({
  onKeyPress
}: {
  onKeyPress: (number: string) => any;
}) => {
  return (
    <div className="key-pad-buttons flex flex-col justify-center items-center">
      <div className="grid grid-cols-3 gap-4">
        {Keypads.map(item => (
          <div key={item}>
            <Button
              type="text"
              shape="circle"
              className="p-4 text-2xl mx-4"
              style={{ width: 40, height: 40 }}
              onClick={() => onKeyPress(item)}
            >
              {item}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};
