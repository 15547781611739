import { ProtobiQueryUnDot } from "@libs/protobi-query-patches";
import _, { isEqual } from "lodash";
import Query from "query";
import { ofType } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";
import { ListQueryState } from "store/utils/list-query";
import { QueryConfig } from "store/utils/query-config";
import { selectCurrentUserId } from "../modules/users/users.selectors";

export const createEntityStateListMaintainerEpic = <T extends { id?: string }>({
  entity,
  selectEntityById,
  selectEntityQueryMap,
  setEntityQueryList,
  otherAliasBuilder,
}: {
  entity: string;
  selectEntityById: (id: string) => (state) => T;
  selectEntityQueryMap: (state) => Record<string, ListQueryState>;
  setEntityQueryList: (payload: {
    query: string;
    list: string[];
    totalItems?: number;
  }) => any;
  otherAliasBuilder?: (userId: string) => Record<string, QueryConfig>;
}) => {
  const epic$ = (action$, state$) => {
    return action$.pipe(
      ofType(`${entity}/patchEntity`, `${entity}/setEntity`),
      // subscribe to actions observable
      map((action: any) => {
        const state = state$.value;
        const currentUserId = selectCurrentUserId(state);
        // Ignore empty events
        if (!action.payload) {
          return;
        }
        const entityId = action.payload.id;

        const entity = selectEntityById(entityId)(state);

        if (!entity) {
          console.log(`Tried to patch a ${entity} that was not in the store`);
          return [];
        }

        const entityQueryMap = selectEntityQueryMap(state$.value) || {};
        const queryConfigAliases = Object.keys(entityQueryMap);

        const fieldsUpdated = _.without(
          Object.keys(action.payload),
          "id",
          "organizationId",
        );

        const includedQueryList = queryConfigAliases.filter(
          (query) => entityQueryMap[query].list.indexOf(entityId) > -1,
        );

        const queryConfigByAlias =
          otherAliasBuilder && currentUserId
            ? otherAliasBuilder(currentUserId)
            : {};

        const allQueries: (QueryConfig & {
          alias: string;
        })[] = queryConfigAliases.map((queryConfigAlias) => {
          if (queryConfigByAlias[queryConfigAlias]) {
            return {
              ...queryConfigByAlias[queryConfigAlias],
              alias: queryConfigAlias,
            };
          }
          try {
            const parsedQuery = JSON.parse(queryConfigAlias) as QueryConfig;
            return {
              ...parsedQuery,
              alias: queryConfigAlias,
            };
          } catch (e) {
            console.error(e);
            // console.log("queryConfigAlias", queryConfigAlias);
            throw e;
          }
        });

        const matchedQueries = allQueries.filter((queryConfig) => {
          //* Note  Query.undot support dot notations in fields
          //* Sentry Event https://sentry.io/organizations/click-connector/issues/2527561191/events/bc5b5984d5044ba3965b45c7d905fd5f/
          // Basically deep nested query throws error if the task does not have contact object associated to it
          try {
            const matches = Query.query(
              [entity],
              queryConfig.query,
              ProtobiQueryUnDot,
            );
            return matches.length > 0;
          } catch (e) {
            // Todo: $exp $gt is not supported
            console.log(
              "Error while Processing Protobi Query",
              e,
              [entity],
              queryConfig.query,
            );
            return true;
          }
        });

        const actionsToEmit: any[] = [];

        // Remove from the removed List
        const queriesToRemoveFrom = _.difference(
          includedQueryList,
          matchedQueries.map((item) => item.alias),
        );
        for (const queryAlias of queriesToRemoveFrom) {
          const query = selectEntityQueryMap(state)[queryAlias];
          const existingList = query.list;
          const newList = _.without(existingList, entityId)!;
          actionsToEmit.push(
            setEntityQueryList({
              query: queryAlias,
              list: _.without(existingList, entityId)!,
              // update total items
              totalItems:
                existingList.length !== newList.length && query.totalItems
                  ? query.totalItems - 1
                  : undefined,
            }),
          );
          //
        }

        // Add to the new lists
        const listToAddTo = _.difference(
          matchedQueries.map((item) => item.alias),
          includedQueryList,
        );

        // In the new list as well as the matched list, check if order is changed and re-order
        for (const queryConfig of matchedQueries) {
          const query = selectEntityQueryMap(state)[queryConfig.alias];
          const existingList = query.list;
          const newList = [...existingList];

          if (listToAddTo.indexOf(queryConfig.alias) > -1) {
            newList.push(entity.id!);
          }
          const sortByOptions = (queryConfig.options.sortBy || []).reduce(
            (sortConfig, fieldString) => {
              if (fieldString?.charAt(0) === "-") {
                sortConfig.fields.push(fieldString?.slice(1));
                sortConfig.order.push("desc");
              } else {
                sortConfig.fields.push(fieldString);
                sortConfig.order.push("asc");
              }
              return sortConfig;
            },
            {
              fields: [] as string[],
              order: [] as ("asc" | "desc")[],
            },
          );

          const sortedList = _.orderBy(
            newList.map((id) => selectEntityById(id)(state)),
            sortByOptions.fields,
            sortByOptions.order,
          );
          const sortedNewList = sortedList.filter((e) => e).map((c) => c.id!);
          if (!isEqual(existingList, sortedNewList)) {
            actionsToEmit.push(
              setEntityQueryList({
                query: queryConfig.alias,
                list: sortedNewList,
                // update total items
                totalItems:
                  existingList.length !== newList.length && query.totalItems
                    ? query.totalItems + 1
                    : undefined,
              }),
            );
          }
        }

        // Todo: If query is not activated, Just increase the count

        // * Todo: What if there is nothing called queries.
        // Maintain a query state
        // But when displaying, Just memoize and filter the entire loaded entities
        // To speed up that, could use throttle
        // In this way, list integrity is easily maintained
        //
        return actionsToEmit;
      }),
      mergeMap((actions: any) => actions),
    );
  };

  return epic$;
};
