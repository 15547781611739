import { Tooltip } from "antd";
import classNames from "classnames";
import React from "react";
import { Avatar } from "./avatar";
import { useUserAvatar } from "./use-user-avatar";

export const UserAvatar = ({
  userId,
  size = 32,
  style,
  className,
  enableToolTip,
  tooltipTemplate,
}: {
  userId: string;
  size?: number;
  style?: React.CSSProperties;
  className?: string;
  enableToolTip?: boolean;
  tooltipTemplate?: (name: string) => string;
}) => {
  const {
    name,
    isOnline,
    isAvailable,
    availabilityStatus,
    avatar: avatarUrl,
  } = useUserAvatar(userId);

  const avatar = (
    <div
      className={classNames(
        "user-avatar",
        isAvailable
          ? availabilityStatus === "AVAILABLE"
            ? "online"
            : availabilityStatus === "BUSY"
            ? "busy"
            : "away"
          : "",
      )}
      style={{ width: size }}
    >
      <Avatar
        name={name}
        avatarUrl={avatarUrl}
        size={size}
        style={style}
        className={classNames(className)}
      />
    </div>
  );

  if (enableToolTip) {
    return (
      <Tooltip title={tooltipTemplate ? tooltipTemplate(name) : name}>
        {avatar}
      </Tooltip>
    );
  } else {
    return avatar;
  }
};
