import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";
import { Button, Divider, Form, Input, Radio, Select, Space, Tag } from "antd";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectChatWidgetByConnectionId } from "store/modules/chat-widgets/chat-widgets.selectors";
import { useQueryWithStore } from "store/store.hooks";

import { iWidget } from "@sdk/chat-widgets/chat-widgets.models";
import { ConfigurationEditorMiniComponent } from "components/common/configuration-editor-mini";
import { SimpleCollapse } from "components/common/simple-collapse/simple-collapse";
import { ImageInputWithUploader } from "components/modules/file-management/image-input-with-uploader";
import { loadAllChatBots } from "store/modules/chat-bots/chat-bots.helpers";
import { selectAllChatBots } from "store/modules/chat-bots/chat-bots.selectors";

export const ChatWidgetGreetings = ({ connectionId }: { connectionId }) => {
  const { state: widget, retry: reload } = useQueryWithStore(
    selectChatWidgetByConnectionId(connectionId),
    loadAllChatWidgets(),
    [connectionId],
  );

  const { state: chatBots, retry: reloadChatBots } = useQueryWithStore(
    selectAllChatBots,
    loadAllChatBots(),
    [],
  );

  return (
    <>
      <ConfigurationEditorMiniComponent
        icon={"ri-chat-smile-line"}
        title="Setup Chat Widget Greeting"
        description="Choose and configure between Simple Greetings and Chatbots"
        entityId={widget?.id!}
        entityType="WIDGET"
        transformFormValues={(
          formValues: iWidget & { greetingType: string },
        ) => {
          if (formValues.greetingType === "SIMPLE_GREETING") {
            formValues.configurations.conversationFlow.greetingsMessage.chatBotId =
              null as any;
          }
          return formValues;
        }}
        // onEntityReceived={entity=>{}}
      >
        <Form.Item
          shouldUpdate
          style={{ marginBottom: 0 }}
          className="bold-form-labels"
        >
          {({ getFieldValue, setFieldsValue }) => {
            const defaultGreetingType = getFieldValue([
              "configurations",
              "conversationFlow",
              "greetingsMessage",
              "chatBotId",
            ])
              ? "CHAT_BOT"
              : "SIMPLE_GREETING";
            return (
              <>
                <Form.Item
                  name="greetingType"
                  label={
                    <div className="font-bold">
                      How would you like to greet your customers?
                    </div>
                  }
                  className="w-full"
                >
                  <Radio.Group
                    defaultValue={defaultGreetingType}
                    className="w-full"
                    onChange={() => {
                      // highlightElement(
                      //   document.getElementById("greetings-config")
                      // );
                    }}
                  >
                    <Space direction="vertical" className="w-full">
                      <Radio
                        value="SIMPLE_GREETING"
                        className="border border-gray-200 dark:border-gray-700 rounded-lg p-4 w-full font-bold"
                      >
                        With a Simple Greeting
                      </Radio>
                      <Radio
                        value="CHAT_BOT"
                        className="border border-gray-200 dark:border-gray-700 rounded-lg p-4 w-full font-bold"
                      >
                        With a Chatbot
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <div id="greetings-config" className="rounded-lg">
                  <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                    {({ getFieldValue, setFieldsValue }) => {
                      const targetType =
                        getFieldValue(["greetingType"]) || defaultGreetingType;

                      return (
                        <Divider>
                          <Tag>
                            {targetType === "SIMPLE_GREETING"
                              ? "Set up greetings"
                              : "Set up Chatbot"}
                          </Tag>
                        </Divider>
                      );
                    }}
                  </Form.Item>

                  <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                    {({ getFieldValue, setFieldsValue }) => {
                      const targetType =
                        getFieldValue(["greetingType"]) || defaultGreetingType;
                      if (targetType === "SIMPLE_GREETING") {
                        return (
                          <div className="body">
                            <Form.Item
                              label=""
                              name={[
                                "configurations",
                                "conversationFlow",
                                "greetingsMessage",
                                "text",
                              ]}
                              rules={[]}
                            >
                              <Input.TextArea
                                showCount
                                maxLength={140}
                                rows={2}
                                placeholder="Eg: Hey there 👋, How can we help you?"
                              />
                            </Form.Item>

                            <div className="mb-2">
                              <SimpleCollapse title="Greetings Image (Optional)">
                                <div className="mb-4" style={{ width: 320 }}>
                                  <Form.Item
                                    label=""
                                    name={[
                                      "configurations",
                                      "conversationFlow",
                                      "greetingsMessage",
                                      "coverImage",
                                    ]}
                                    rules={[]}
                                    extra={
                                      <div>
                                        * We recommend 320px x 180px images.
                                        Please minify the images for faster
                                        loading time
                                      </div>
                                    }
                                    help={
                                      <div>
                                        <Form.Item
                                          shouldUpdate
                                          style={{ marginBottom: 0 }}
                                        >
                                          {() => {
                                            return getFieldValue([
                                              "configurations",
                                              "conversationFlow",
                                              "greetingsMessage",
                                              "coverImage",
                                            ]) ? (
                                              <Button
                                                type="link"
                                                onClick={() =>
                                                  setFieldsValue({
                                                    configurations: {
                                                      conversationFlow: {
                                                        greetingsMessage: {
                                                          coverImage: false,
                                                        },
                                                      },
                                                    },
                                                  })
                                                }
                                                className="p-0"
                                              >
                                                Clear Image
                                              </Button>
                                            ) : (
                                              <></>
                                            );
                                          }}
                                        </Form.Item>
                                      </div>
                                    }
                                  >
                                    <ImageInputWithUploader
                                      type="CONNECTION"
                                      entityId={connectionId}
                                      width={320}
                                      height={180}
                                    />
                                  </Form.Item>
                                </div>
                              </SimpleCollapse>
                            </div>

                            <div className="font-bold  ml-8">Quick Replies</div>
                            <div className="quick-replies-container ml-16 mt-4">
                              <Form.List
                                name={[
                                  "configurations",
                                  "conversationFlow",
                                  "greetingsMessage",
                                  "options",
                                ]}
                              >
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map((field) => (
                                      <Space
                                        key={field.key}
                                        style={{ display: "flex" }}
                                        align="baseline"
                                      >
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "label"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Enter a text",
                                            },
                                          ]}
                                          hasFeedback={false}
                                          style={{ marginBottom: 4 }}
                                        >
                                          <Input placeholder="Quick Reply" />
                                        </Form.Item>
                                        {/* <Form.Item
                                          {...field}
                                          name={[field.name, "action"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Select an action",
                                            },
                                          ]}
                                          hasFeedback={false}
                                          style={{ marginBottom: 4 }}
                                        >
                                          <Select style={{ minWidth: 150 }}>
                                            <Select.Option value="MESSAGE">
                                              Send as Message
                                            </Select.Option>
                                            <Select.Option value="FAQ" disabled>
                                              Show FAQ
                                            </Select.Option>
                                            <Select.Option
                                              value="ROUTE"
                                              disabled
                                            >
                                              Route to specific user group
                                            </Select.Option>
                                          </Select>
                                        </Form.Item> */}
                                        {/* User Group */}

                                        {/* <Form.Item
                                          shouldUpdate
                                          style={{ marginBottom: 4 }}
                                        >
                                          {({ getFieldValue }) => {
                                            const selectedOption =
                                              getFieldValue([
                                                "options",
                                                field.name,
                                                "action",
                                              ]);
                                            if (selectedOption === "FAQ") {
                                              return (
                                                <Form.Item
                                                  {...field}
                                                  name={[
                                                    field.name,
                                                    "data",
                                                    "FAQId",
                                                  ]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "Select a FAQ",
                                                    },
                                                  ]}
                                                  hasFeedback={false}
                                                  style={{ marginBottom: 0 }}
                                                >
                                                  <Select
                                                    style={{ minWidth: 150 }}
                                                  >
                                                    {availableArticleCollections.map(
                                                      (collection) => (
                                                        <Select.Option
                                                          key={collection.id}
                                                          value={collection.id!}
                                                        >
                                                          {collection.label}
                                                        </Select.Option>
                                                      )
                                                    )}
                                                  </Select>
                                                </Form.Item>
                                              );
                                            }
                                            if (selectedOption === "ROUTE") {
                                              return (
                                                <Form.Item
                                                  {...field}
                                                  name={[
                                                    field.name,
                                                    "data",
                                                    "userGroupId",
                                                  ]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message:
                                                        "Select a User Group",
                                                    },
                                                  ]}
                                                  hasFeedback={false}
                                                  style={{ marginBottom: 0 }}
                                                >
                                                  <Select
                                                    style={{ minWidth: 150 }}
                                                  >
                                                    {availableArticleCollections.map(
                                                      (collection) => (
                                                        <Select.Option
                                                          key={collection.id}
                                                          value={collection.id!}
                                                        >
                                                          {collection.label}
                                                        </Select.Option>
                                                      )
                                                    )}
                                                  </Select>
                                                </Form.Item>
                                              );
                                            }
                                            return <div></div>;
                                          }}
                                        </Form.Item> */}
                                        <MinusCircleOutlined
                                          onClick={() => remove(field.name)}
                                        />
                                      </Space>
                                    ))}
                                    <div
                                      onClick={() =>
                                        add({
                                          action: "MESSAGE",
                                          /*type: "work", phone: ""*/
                                        })
                                      }
                                      className="cursor-pointer"
                                    >
                                      + add a quick reply
                                    </div>
                                  </>
                                )}
                              </Form.List>
                            </div>
                          </div>
                        );
                      } else if (targetType === "CHAT_BOT") {
                        return (
                          <div className="mt-6">
                            <Form.Item
                              name={[
                                "configurations",
                                "conversationFlow",
                                "greetingsMessage",
                                "chatBotId",
                              ]}
                              label="Select Chatbot Flow"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select a Chatbot flow",
                                },
                              ]}
                            >
                              <Select style={{ minWidth: 150 }}>
                                {chatBots.map((chatBot) => (
                                  <Select.Option
                                    key={chatBot.id}
                                    value={chatBot.id!}
                                  >
                                    {chatBot.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        );
                      }
                      return <></>;
                    }}
                  </Form.Item>
                </div>
              </>
            );
          }}
        </Form.Item>
      </ConfigurationEditorMiniComponent>
    </>
  );
};
