export const AppSumoTiers: {
  planId: string;
  description: string;
  title: string;
  price: string;
  included: string[];
}[] = [
  {
    planId: "clickconnector_tier1",
    description: "Team Plan",
    title: "Tier 1",
    price: "$79",
    included: [
      "All Support Kit Features",
      "3 agent seats",
      "400 conversations per month",
      "3 Connections",
      "Unlimited Chatbot messages per month",
      "CC Brand Removal in Customer Portal",
    ],
  },
  {
    planId: "clickconnector_tier2",
    description: "Team+ Plan",
    title: "Tier 2",
    price: "$189",
    included: [
      "All Support Kit Features",
      "6 agent seats",
      "Unlimited Chats",
      "12 Connections",
      "Unlimited Chatbot messages per month",
      "CC Brand Removal in Customer Portal",
    ],
  },
  {
    planId: "clickconnector_tier3",
    description: "",
    title: "Tier 3",
    price: "$499",
    included: [
      "All Support Kit Features",
      "6 agent seats",
      "Unlimited Chats",
      "16 Connections",
      "Unlimited Chatbot messages per month",
      "CC Brand Removal in Customer Portal",
      "3 Tier 1 sub-accounts",
      "Agency Master Workplace",
    ],
  },
];
