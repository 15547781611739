import { iOpportunity } from "@sdk/crm/crm.models";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";
import { opportunitiesDefaultQueryConfig } from "./opportunities-default-query";

const generateOpportunitiesByContactIdQuery = _.memoize((contactId: string) => {
  const queryConfig = {
    ...opportunitiesDefaultQueryConfig,
    query: {
      contactId
    }
  };
  return JSON.stringify(queryConfig);
});

export const selectOpportunitiesByContactId = (contactId: string) => {
  return selectOpportunitiesQuery(
    generateOpportunitiesByContactIdQuery(contactId)
  );
};

export const {
  selectQueryMap: selectOpportunityQueryMap,
  selectEntityMap: selectOpportunitiesMap,
  selectQueryMapAll: selectOpportunitiesMapAll,
  selectQueryAllList: selectOpportunitiesAllList,
  selectAllEntities: selectAllOpportunities,
  selectAllEntitiesQuery: selectAllOpportunitiesQuery,
  selectEntityById: selectOpportunityById,
  selectEntityQuery: selectOpportunitiesQuery,
  selectEntityQueryList: selectOpportunitiesQueryList
} = CreateSelectorForEntities<iStore, iOpportunity>({
  sliceName: "opportunities"
});
