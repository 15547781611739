import { iEngagement } from "@sdk/crm/crm.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { engagementDefaultQueryConfig } from "./engagement-default-query";

export const {
  slice: EngagementsSlice,
  reducers: EngagementsSliceReducer,
  actions: {
    setEntityQueryResults: setEngagementsQueryResults,
    setEntity: setEngagement,
    setEntities: setEngagements,
    patchEntity: patchEngagement,
    addNewEntity: addNewEngagement,
    setEntityFetchingStatus: setEngagementFetchingStatus,
    setEntitiesFetchingStatus: setEngagementsFetchingStatus,
    setEntityFailedStatus: setEngagementFailedStatus,
    setEntityQueryError: setEngagementsQueryError,
    resetQuery: resetEngagementsQuery
  }
} = CreateEntitySlice<iEngagement>({
  sliceName: "engagements",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...engagementDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
