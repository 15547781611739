import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iMagicAssistant } from "@sdk/magic-assistants/magic-assistants-model";
import React from "react";

const AddEditMagicAssistantModal = React.lazy(
  () => import("./add-edit-magic-assistants.modal"),
);

const AddEditMagicAssistant: iModalProps<
  typeof AddEditMagicAssistantModal,
  { onSaved?: (data: iMagicAssistant) => any; magicAssistantId?: string }
> = {
  name: "add-edit-magic-assistant",
  component: GenerateModalWithSuspense(AddEditMagicAssistantModal),
};

export default AddEditMagicAssistant;
