import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const SimilarContactsExplorerModal = React.lazy(
  () => import("./similar-contacts-explorer.modal"),
);

const SimilarContactsExplorer: iModalProps<
  typeof SimilarContactsExplorerModal,
  {
    contactId: string;
  }
> = {
  name: "similar-contacts-explorer",
  component: GenerateModalWithSuspense(SimilarContactsExplorerModal),
};

export default SimilarContactsExplorer;
