import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddEmailChannelModal = React.lazy(
  () => import("./add-email-channel.modal"),
);

const AddEmailChannel: iModalProps<typeof AddEmailChannelModal, {}> = {
  name: "add-email-channel",
  component: GenerateModalWithSuspense(AddEmailChannelModal),
};

export default AddEmailChannel;
