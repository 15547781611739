import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iMagicAssistantConnectedAPIConfig } from "@sdk/magic-assistants/magic-assistants-model";
import React from "react";

const MagicAssistantConnectedAPIEditorModal = React.lazy(
  () => import("./magic-assistant-connected-api-editor.modal"),
);

const MagicAssistantConnectedAPIEditor: iModalProps<
  typeof MagicAssistantConnectedAPIEditorModal,
  {
    mode: "ADD" | "EDIT";
    initialValue?: iMagicAssistantConnectedAPIConfig;
    onSaved: (data: iMagicAssistantConnectedAPIConfig) => any;
  }
> = {
  name: "connected-api-editor",
  component: GenerateModalWithSuspense(MagicAssistantConnectedAPIEditorModal),
};

export default MagicAssistantConnectedAPIEditor;
