import { iWidget } from "@sdk/chat-widgets/chat-widgets.models";
import { iFbConnectionData } from "@sdk/conversations/conversations.models";
import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import { ConnectionIcons } from "components/modules/connections/helpers/connection-icons";
import { userHasConnectionGroupPermission } from "components/modules/connections/helpers/user-has-connection-permission";
import { buildChatViewQueryConfigs } from "components/modules/conversations/helpers/build-chat-view-queries";
import dayjs from "dayjs";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.selectors";
import { loadAllConnections } from "store/modules/connections/connections.helpers";
import { selectAllConnections } from "store/modules/connections/connections.selectors";

import { selectCurrentUser } from "store/modules/users/users.selectors";
import {
  selectChannels,
  selectConversationTags,
  selectInboxConfig,
  selectOrganization,
  selectTeams,
} from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { QueryConfig } from "store/utils/query-config";
import { CurrentUserAvatar } from "./current-user-avatar";
import { InboxMenuItem, iInboxItem } from "./inbox-models";
import { DividerMenu } from "./inboxes-devider-menu";
import { GenerateInboxSubTitleMenuItem } from "./inboxes-sub-title-menu";
import { GenerateInboxTitleMenuItem } from "./inboxes-title-menu";

// Inbox Config

export const defaultInboxViewTemplate: iInboxItem[] = [
  {
    id: "DEFAULT_MY",
    type: "CHAT",
    label: "My Inbox",
    // keyboardShortcut: "$mod+m",
    icon: <CurrentUserAvatar />,
    queryConfig: {
      alias: "My Inbox",
    },
    viewConfig: {
      breakByChatStatus: true,
    },
  },
  {
    id: "DEFAULT_TEAM",
    type: "CHAT",
    label: "All Assigned",
    // keyboardShortcut: "$mod+t",
    icon: <i className="ri-group-fill"></i>,
    queryConfig: {
      alias: "All Assigned",
    },
    viewConfig: {
      breakByChatStatus: true,
    },
  },
  {
    id: "DEFAULT_UNASSIGNED",
    type: "CHAT",
    label: "Unassigned",
    // keyboardShortcut: "$mod+u",
    icon: <i className="ri-inbox-fill"></i>,
    queryConfig: {
      alias: "Unassigned",
    },
    viewConfig: {
      breakByChatStatus: true,
    },
  },
  {
    id: "DEFAULT_ATTENTION",
    type: "CHAT",
    label: "All Awaiting Reply",
    icon: <i className="ri-time-line"></i>,
    queryConfig: {
      alias: "Requires Attention",
    },
    viewConfig: {
      breakByChatStatus: false,
    },
  },
];

export const useInboxes = (options?: {
  omitTitle?: boolean;
  omitTasks?: boolean;
}) => {
  const _inboxConfig = useSelector(selectInboxConfig);
  const currentUser = useSelector(selectCurrentUser);
  const organization = useSelector(selectOrganization);

  const inboxConfig = useMemo(
    () => currentUser.data.inboxConfig || _inboxConfig,
    [_inboxConfig, currentUser.data.inboxConfig],
  );

  const {
    state: connections,
    retry: reload,
    isLoading,
  } = useQueryWithStore(selectAllConnections, loadAllConnections, []);
  const teams = useSelector(selectTeams);
  const conversationTags = useSelector(selectConversationTags);

  const channels = useSelector(selectChannels);
  const userRole = useMemo(() => {
    const currentUserRole = getRoleFromPermission(currentUser?.permissions);
    return currentUserRole;
  }, [currentUser?.permissions]);

  const { state: widgets } = useQueryWithStore(
    selectAllChatWidgets,
    loadAllChatWidgets(),
    [],
  );

  const hasChatBotEnabled = useMemo(() => {
    for (const widget of widgets) {
      if (
        (widget as iWidget).configurations?.conversationFlow?.greetingsMessage
          ?.chatBotId
      ) {
        return true;
      }
    }
    return false;
  }, [widgets]);

  const hasFollowupsEnabled = useMemo(() => {
    const allRules = organization?.appData?.HELP_DESK?.automationRules || [];
    for (const rule of allRules) {
      for (const action of rule.actions) {
        if (action.action === "ACTIVATE_AUTO_FOLLOWUP_FLAGGING") {
          return true;
        }
      }
    }
    return false;
  }, [organization?.appData?.HELP_DESK?.automationRules]);

  const menu = useMemo(() => {
    const views = buildChatViewQueryConfigs(currentUser.id);

    const defaultItems: InboxMenuItem[] = [
      ..._.cloneDeep(defaultInboxViewTemplate).map((item) => ({
        menuItemType: "ITEM" as const,
        ...item,
      })),
    ];

    const pinnedConversationMenu: InboxMenuItem[] = [];

    const pinnedConversation = currentUser?.data.pinnedConversations || [];

    if (pinnedConversation.length > 0) {
      pinnedConversationMenu.push({
        id: "PINNED",
        menuItemType: "ITEM",
        type: "CHAT",
        label: "Pinned",
        icon: <i className="ri-pushpin-fill"></i>,
        queryConfig: {
          query: {
            id: { $in: pinnedConversation },
          },
          options: {
            sortBy: ["-metaData.lastMessage.timestamp"],
          },
        },
        viewConfig: {
          breakByChatStatus: false,
        },
      });
    }

    let tagsMenus: InboxMenuItem[] = [];
    if (inboxConfig.showInboxesByTags) {
      const groupedTags = _.groupBy(conversationTags, "tagGroup");

      const getStringId = (value: string) =>
        (value || "").toLowerCase().replace(/[^a-z0-9 _-]+/gi, "-");

      tagsMenus = Object.keys(groupedTags).map((group) => {
        // Populate Tags
        const tagsMenu: InboxMenuItem = {
          menuItemType: "GROUP",
          label:
            group === "undefined" ? "By tags" : `By ${group.toLowerCase()}`,
          icon: <i className="ri-price-tag-3-fill"></i>,
          children: [],
        };
        tagsMenu.children = (groupedTags[group] || [])
          .filter((e) => !e.isHidden)
          .map((tag) => ({
            id: `TAG_${getStringId(group)}_${getStringId(tag.label)}`,
            menuItemType: "ITEM" as const,
            type: "CHAT" as const,
            label: tag.label,
            icon: (
              <i
                className="ri-checkbox-blank-circle-fill"
                style={{ color: tag.color }}
              ></i>
            ),
            queryConfig: {
              query: {
                tags: tag.label,
                status: "OPEN",
              },
              options: {
                sortBy: ["-metaData.lastMessage.timestamp"],
              },
            },
            viewConfig: {
              breakByChatStatus: true,
            },
          }));
        return tagsMenu;
      });
    }

    // Add Pages
    const facebookConnections = _.filter(connections, {
      type: "FACEBOOK",
    });

    const facebookPageItems: InboxMenuItem[] = facebookConnections
      .filter((item) => item?.metaData?.isActive)
      .filter((e) => e?.data?.page)
      .filter((item) =>
        userHasConnectionGroupPermission(item?.userGroups, [
          currentUser?.id,
          ...(currentUser?.userGroups || []),
        ]),
      )
      .map((connection) => ({
        id: `Posts_Fb_${connection.id}`,
        menuItemType: "ITEM" as const,
        type: "FB_POSTS",
        label: `FB: ${connection.data?.page?.name}`,
        icon: <i className="ri-facebook-box-fill"></i>,
        target: "FB_POSTS",
        queryConfig: {
          query: {
            pageId: (connection.data as iFbConnectionData).page.id,
          },
          options: {
            sortBy: ["-updated_time"],
          },
        },
        viewConfig: {},
      }));

    // Add Instagram Accounts

    const instagramConnections = _.filter(connections, {
      type: "INSTAGRAM",
    });

    const instagramPageItems: InboxMenuItem[] = instagramConnections
      .filter((item) => item?.metaData?.isActive)
      .filter((e) => e?.data?.page)
      .filter((item) =>
        userHasConnectionGroupPermission(item?.userGroups, [
          currentUser?.id,
          ...(currentUser?.userGroups || []),
        ]),
      )
      .map((connection) => ({
        id: `Posts_Ig_${connection.id}`,
        menuItemType: "ITEM" as const,
        type: "IG_MEDIAS",
        label: `IG: ${connection.data.page?.name}`,
        icon: <i className="ri-instagram-fill"></i>,
        target: "IG_MEDIAS",
        queryConfig: {
          query: {
            connectionId: connection.id!,
          },
          options: {
            sortBy: ["-metaData.createdTime"],
          },
        },
        viewConfig: {},
      }));

    // Add Connections

    let connectionItems: InboxMenuItem[] = [];
    if (inboxConfig.showInboxesByConnections) {
      connectionItems = connections
        .filter((item) => item?.metaData?.isActive)
        .filter((item) =>
          userHasConnectionGroupPermission(item?.userGroups, [
            currentUser?.id,
            ...(currentUser?.userGroups || []),
          ]),
        )
        .map((connection) => ({
          id: `Connections_${connection.id}`,
          menuItemType: "ITEM" as const,
          type: "CHAT",
          label: connection.label,
          icon: <i className={ConnectionIcons[connection.type]}></i>,
          queryConfig: {
            query: {
              connectionId: connection.id!,
              status: "OPEN",
            },
            options: {
              sortBy: ["-metaData.lastMessage.timestamp"],
            },
          },
          viewConfig: { breakByChatStatus: true },
        }));
    }

    const connectionMenu: InboxMenuItem = {
      menuItemType: "GROUP",
      label: `By connections`,
      icon: <i className="ri-folder-line"></i>,
      children: connectionItems,
    };

    const inboxesByDateMenuItems: InboxMenuItem[] = [];

    if (inboxConfig.showMyInboxByDates) {
      const inboxByDates: InboxMenuItem = {
        menuItemType: "GROUP",
        label: `My Inbox by date`,
        icon: <i className="ri-calendar-2-fill"></i>,
        children: [],
      };
      inboxByDates.children = buildMyInboxesByDate(views["My Inbox"]);
      inboxesByDateMenuItems.push(inboxByDates);
    }

    const followupInboxes: InboxMenuItem[] = [];

    if (hasFollowupsEnabled) {
      followupInboxes.push({
        menuItemType: "ITEM" as const,
        id: "DEFAULT_FOLLOW_UP_INBOX",
        type: "CHAT",
        label: "My Followups",
        icon: <i className="ri-chat-follow-up-line"></i>,
        queryConfig: {
          query: {
            ...views["My Inbox"].query,
            "metaData.flags.requiresFollowup.isActive": true,
          },
          options: {
            ...views["My Inbox"].options,
          },
        },
        viewConfig: {
          breakByChatStatus: true,
        },
      });

      if (userRole === "Owner" || userRole === "Manager") {
        followupInboxes.push({
          menuItemType: "ITEM" as const,
          id: "DEFAULT_ATTENTION_ALL",
          type: "CHAT",
          label: "All Followups",
          icon: <i className="ri-chat-follow-up-line"></i>,
          queryConfig: {
            query: {
              ...views["All Assigned"].query,
              "metaData.flags.requiresFollowup.isActive": true,
            },
            options: {
              ...views["All Assigned"].options,
            },
          },
          viewConfig: {
            breakByChatStatus: true,
          },
        });
      }
    }

    const botInboxes: InboxMenuItem[] = [];
    if (hasChatBotEnabled) {
      botInboxes.push({
        menuItemType: "ITEM" as const,
        id: "DEFAULT_BOT_INBOX",
        type: "CHAT",
        label: "Bot Inbox",
        icon: <i className="ri-robot-fill"></i>,
        queryConfig: {
          alias: "Active Qualifications",
        },
        viewConfig: {
          breakByChatStatus: true,
        },
      });
    }

    const newViewShortcut: InboxMenuItem = {
      menuItemType: "CUSTOM_COMPONENT" as const,
      component: "QUICK_INBOX_ADD",
    };

    const customInboxes = currentUser?.data.customInboxes || [];

    const emailConnections = _.filter(connections, {
      type: "EMAIL",
    });

    const enableSpamBox = false;
    if (emailConnections.length > 0 && enableSpamBox) {
      const spamBox = {
        id: "SPAM_EMAILS",
        menuItemType: "ITEM",
        label: "Spam Emails",
        type: "SPAM_EMAILS",
        icon: <i className="ri-spam-fill"></i>,
        queryConfig: {
          query: {},
          options: {
            sortBy: ["-metaData.lastMessage.timestamp"],
          },
        },
        viewConfig: {
          breakByChatStatus: false,
        },
      };
    }

    const customInboxMenu = customInboxes.map((item) => ({
      id: `custom-${item.id}`,
      type: "CHAT" as const,
      menuItemType: "ITEM" as const,
      label: item.label,
      icon: item.icon || <i className="ri-star-fill"></i>,
      queryConfig: JSON.parse(item.query),
      viewConfig: {
        breakByChatStatus: false,
      },
    }));

    return [
      ...pinnedConversationMenu,
      ...defaultItems,
      ...followupInboxes,
      ...botInboxes,
      // DividerMenu,
      GenerateInboxSubTitleMenuItem("My Views"),
      ...customInboxMenu,
      newViewShortcut,
      DividerMenu,
      ...inboxesByDateMenuItems,
      ...(inboxConfig.showInboxesByConnections ? [connectionMenu] : []),
      ...tagsMenus,
      ...([...facebookPageItems, ...instagramPageItems].length > 0
        ? [DividerMenu, GenerateInboxTitleMenuItem("Posts")]
        : []),
      ...facebookPageItems,
      ...instagramPageItems,
    ];
  }, [
    connections,
    conversationTags,
    currentUser?.data.customInboxes,
    currentUser?.data.pinnedConversations,
    currentUser.id,
    currentUser?.userGroups,
    hasChatBotEnabled,
    hasFollowupsEnabled,
    inboxConfig.showInboxesByConnections,
    inboxConfig.showInboxesByTags,
    inboxConfig.showMyInboxByDates,
    userRole,
  ]);

  return menu;
};

export const buildMyInboxesByDate = (defaultConfig: QueryConfig) => {
  const dates = new Array(7).fill(null).map((item, index) => {
    return dayjs().subtract(index, "day").startOf("day");
  });

  return dates.map(
    (date) =>
      ({
        id: `INBOX_BY_DATE_${date.format("Do")}`,
        menuItemType: "ITEM",
        type: "CHAT",
        label: date.format("ddd, Do MMM"),
        icon: <i className="ri-calendar-2-fill"></i>,
        queryConfig: {
          query: {
            ...defaultConfig.query,
            "metaData.createdAt": {
              $gt: date.toDate().getTime(),
              $lt: date.clone().add(1, "d").toDate().getTime(),
            },
          },
          options: {
            ...defaultConfig.options,
            sortBy: ["-metaData.lastMessage.timestamp"],
          },
        },
        viewConfig: {
          breakByChatStatus: true,
        },
      }) as InboxMenuItem,
  );
};
