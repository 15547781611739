import { Tooltip } from "antd";

export const SideBarSubMenuItem = ({
  icon,
  label,
  onClick,
}: {
  icon: JSX.Element;
  label: string;
  onClick: () => any;
}) => {
  return (
    <div
      className="flex flex-row m-1 p-2 hover:bg-gray-100 dark:hover:bg-gray-900 rounded-lg cursor-pointer"
      onClick={onClick}
    >
      <div className="mr-2">{icon}</div>
      {label}
    </div>
  );
};

export const SideBarSubMenuIconItem = ({
  icon,
  label,
  onClick,
}: {
  icon: JSX.Element;
  label: string;
  onClick: () => any;
}) => {
  return (
    <Tooltip title={label}>
      <div
        className="flex flex-row justify-center items-center m-1 p-2 hover:bg-gray-100 dark:hover:bg-gray-900 rounded-lg cursor-pointer"
        onClick={onClick}
      >
        <div className="">{icon}</div>
      </div>
    </Tooltip>
  );
};
