import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const GoogleMeetSchedulerModal = React.lazy(
  () => import("./google-meet-scheduler.modal"),
);

const GoogleMeetScheduler: iModalProps<
  typeof GoogleMeetSchedulerModal,
  {
    onMeetScheduled?: (content: string) => any;
    contactId: string;
  }
> = {
  name: "g-meet-scheduler",
  component: GenerateModalWithSuspense(GoogleMeetSchedulerModal),
};

export default GoogleMeetScheduler;
