import { iModalProps } from "@libs/modal-panels/modal-registry-model";

import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import React from "react";

const SimplePromptModal = React.lazy(() => import("./simple-prompt.modal"));

const SimplePrompt: iModalProps<
  typeof SimplePromptModal,
  {
    config: {
      title: string;
      icon: JSX.Element;
      initialValues: any;
      formItems: JSX.Element;
      onSubmit: (formValues) => any;
    };
  }
> = {
  name: "simple-prompt",
  component: GenerateModalWithSuspense(SimplePromptModal),
};

export default SimplePrompt;
