import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AcademyViewerModal = React.lazy(() => import("./academy-viewer.modal"));

const AcademyViewer: iModalProps<typeof AcademyViewerModal> = {
  name: "academy-viewer",
  component: GenerateModalWithSuspense(AcademyViewerModal),
};

export default AcademyViewer;
