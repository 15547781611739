import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import { UserTracker } from "user-tracker";
import { iSLAEventLog } from "./sla-logs-model";

export class SLALogsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.slaLogs.configure(config);
  }

  slaLogs = Object.assign(
    new EntityServices<iSLAEventLog>(SDKConfig, "sla-logs", {
      onCreate: () => {
        UserTracker.track("SLA_LOGS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("SLA_LOGS - Delete", {});
      },
    }),
    {},
  );
}
