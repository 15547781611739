import { SDK } from "@sdk";

import { iProduct } from "@sdk/products/products-model";
import { Store } from "redux";
import { QueryConfig } from "store/utils/query-config";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { productsDefaultQueryConfig } from "./products-default-query";
import { ProductsSlice } from "./products.slice";

export const loadAllProducts = () => async (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  await loadProductsQuery(productsDefaultQueryConfig, "all")(
    store,
    forceReload
  );
};

export const loadQueryProducts = (
  queryConfig: QueryConfig,
  queryAlias?: string
) => async (store: Store<iStore>, forceReload?: boolean) => {
  queryAlias = queryAlias || JSON.stringify(queryConfig);
  await loadProductsQuery(queryConfig, queryAlias)(store, forceReload);
};

export const {
  loadEntityQueries: loadProductsQuery,
  loadEntityById: loadProductById,
  loadMoreEntityQueries: loadMoreProductsQueries
} = CreateHelpersForEntity<iStore, iProduct>({
  sliceName: "products",
  slice: ProductsSlice,
  isPaginatedQueries: true,
  fetchPageSize: 50,
  loadEntityQueryProvider: query => SDK.products.query(query),
  loadEntityByIdProvider: (entityId: string) => SDK.products.getById(entityId)
});
