import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const TroubleshootWhatsappConnectionModal = React.lazy(
  () => import("./troubleshoot-whatsapp-private-connection.modal"),
);

const TroubleshootWhatsappConnection: iModalProps<
  typeof TroubleshootWhatsappConnectionModal,
  { connectionId: string }
> = {
  name: "troubleshoot-wa-connection",
  component: GenerateModalWithSuspense(TroubleshootWhatsappConnectionModal),
};

export default TroubleshootWhatsappConnection;
