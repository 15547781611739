import _, { escapeRegExp } from "lodash";
import { getMobileId } from "utils/clean-mobile-number";
import { ExtractPhoneNumbers } from "utils/extract-phone-numbers";
import { isEmail } from "utils/is-email";
import { toTitleCase } from "utils/to-title-case";

const use$textSearch = true;

export function TicketSearchQueryBuilder(term: string) {
  if (use$textSearch) {
    return TicketSearchQueryBuilderWithTextSearch(term);
  }
  return TicketSearchQueryBuilderWithRegexSearch(term);
}

export const TicketSearchQueryBuilderWithRegexSearch = (term: string) => {
  term = term.trim();

  const words = term.split(" ");

  // 1) User could enter contact Name
  const searchByContactNameQuery =
    words.length === 1
      ? [
          {
            "primaryContact.data.firstName": {
              $regex: `${escapeRegExp(words[0])}`,
              $options: "i",
            },
          },
          {
            "primaryContact.data.lastName": {
              $regex: `${escapeRegExp(words[0])}`,
              $options: "i",
            },
          },
        ]
      : words.length === 2
      ? [
          {
            $and: [
              {
                "primaryContact.data.firstName": {
                  $regex: `${escapeRegExp(words[0])}`,
                  $options: "i",
                },
              },
              {
                "primaryContact.data.lastName": {
                  $regex: `${escapeRegExp(
                    words.slice(1).join(",").replace(/,/g, " "),
                  )}`,
                  $options: "i",
                },
              },
            ],
          },
          {
            "primaryContact.data.firstName": {
              $regex: `${escapeRegExp(words.join(",").replace(/,/g, " "))}`,
              $options: "i",
            },
          },
        ]
      : [
          {
            $and: [
              {
                "primaryContact.data.firstName": {
                  $regex: `${escapeRegExp(
                    words.slice(0, 2).join(",").replace(/,/g, " "),
                  )}`,
                  $options: "i",
                },
              },
              {
                "primaryContact.data.lastName": {
                  $regex: `${escapeRegExp(
                    words.slice(2).join(",").replace(/,/g, " "),
                  )}`,
                  $options: "i",
                },
              },
            ],
          },
          {
            $and: [
              {
                "primaryContact.data.firstName": {
                  $regex: `${escapeRegExp(
                    words.slice(0, 1).join(",").replace(/,/g, " "),
                  )}`,
                  $options: "i",
                },
              },
              {
                "primaryContact.data.lastName": {
                  $regex: `${escapeRegExp(
                    words.slice(1).join(",").replace(/,/g, " "),
                  )}`,
                  $options: "i",
                },
              },
            ],
          },
        ];

  // 2) User could enter contact email
  const searchByEmailQuery = {
    "primaryContact.data.primaryEmail": {
      $regex: escapeRegExp(term),
      $options: "i",
    },
  };

  // 3) User could enter contact mobile
  const mobileId = getMobileId(term).replace("+", "").trim();
  const searchByMobileQuery = mobileId
    ? {
        "primaryContact.data.primaryMobile": {
          $regex: escapeRegExp(mobileId),
          $options: "i",
        },
      }
    : undefined;

  // 4) User could enter email subject
  const searchBySubjectQuery = {
    label: {
      $regex: escapeRegExp(term),
      $options: "i",
    },
  };

  const searchByBodyQuery = {
    body: {
      $regex: escapeRegExp(term),
      $options: "i",
    },
  };

  // Search byID
  const searchByIdQuery = {
    id: term,
  };

  if (isEmail(term)) {
    return {
      $or: _.flattenDeep([searchByEmailQuery]),
    };
  }

  if (term.length === 18 && term.split("-").length === 3) {
    return {
      $or: _.flattenDeep([searchByIdQuery]),
    };
  }

  const searchBySimpleIdQuery = {
    "data.simpleId": term.replace("#", ""),
  };

  if (term.length === 6 && term.charAt(0) === "#") {
    return {
      $or: _.flattenDeep([searchBySimpleIdQuery]),
    };
  }

  if (term.includes("tag:")) {
    const tagToSearch = term.replace("tag:", "").trim();

    const filterByTag = {
      tags: {
        $in: [tagToSearch, tagToSearch.toLowerCase(), toTitleCase(tagToSearch)],
      },
    };
    return {
      $or: _.flattenDeep([filterByTag]),
    };
  }

  return {
    $or: _.flattenDeep(
      [
        // Contact Search
        ...searchByContactNameQuery,
        searchByEmailQuery,
        searchByMobileQuery,
        // Search by Tickets
        searchBySubjectQuery,
        searchByBodyQuery,
      ].filter((e) => e),
    ),
  };
};

export const TicketSearchQueryBuilderWithTextSearch = (searchQuery: string) => {
  searchQuery = searchQuery.trim();
  const phoneNumbers = ExtractPhoneNumbers(searchQuery);

  if (phoneNumbers.length > 0) {
    for (const mobile of phoneNumbers) {
      searchQuery += ` ${getMobileId(mobile)}`;
    }
  }

  return {
    $text: {
      $search: searchQuery,
    },
  };
};
