import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iTicket } from "@sdk/tickets/tickets-model";
import React from "react";

const CreateTicketModal = React.lazy(() => import("./create-ticket.model"));

const CreateTicket: iModalProps<
  typeof CreateTicketModal,
  {
    onCreated?: () => any;
    initialData?: Partial<iTicket>;
  }
> = {
  name: "create-ticket",
  component: GenerateModalWithSuspense(CreateTicketModal),
};

export default CreateTicket;
