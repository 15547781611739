import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const EditCustomInboxModal = React.lazy(
  () => import("./edit-custom-inbox.modal"),
);

const EditCustomInbox: iModalProps<
  typeof EditCustomInboxModal,
  { inboxId: string }
> = {
  name: "edit-custom-inbox",
  component: GenerateModalWithSuspense(EditCustomInboxModal),
};

export default EditCustomInbox;
