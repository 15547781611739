import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const EditInboxSettingsModal = React.lazy(
  () => import("./edit-inbox-settings.modal"),
);

const EditInboxSettings: iModalProps<typeof EditInboxSettingsModal, {}> = {
  name: "edit-inbox-settings",
  component: GenerateModalWithSuspense(EditInboxSettingsModal),
};

export default EditInboxSettings;
