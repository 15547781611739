import { iSelfServicePortal } from "@sdk/knowledge-base/knowledge-base.models";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectSelfServicePortalQueryMap,
  selectEntityMap: selectSelfServicePortalsMap,
  selectQueryMapAll: selectSelfServicePortalsMapAll,
  selectQueryAllList: selectSelfServicePortalsAllList,
  selectAllEntities: selectAllSelfServicePortals,
  selectAllEntitiesQuery: selectAllSelfServicePortalsQuery,
  selectEntityById: selectSelfServicePortalById,
  selectEntityQuery: selectSelfServicePortalsQuery
} = CreateSelectorForEntities<iStore, iSelfServicePortal>({
  sliceName: "selfServicePortals"
});
