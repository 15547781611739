import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const EditChatBotModal = React.lazy(() => import("./edit-chat-bot.modal"));

const EditChatBot: iModalProps<typeof EditChatBotModal, { chatBotId: string }> =
  {
    name: "edit-chat-bot",
    component: GenerateModalWithSuspense(EditChatBotModal),
  };

export default EditChatBot;
