import { iCallLog } from "@sdk/call-logs/call-logs-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { callLogsDefaultQueryConfig } from "./call-logs-default-query";

export const {
  slice: CallLogsSlice,
  reducers: CallLogsSliceReducer,
  actions: {
    setEntityQueryResults: setCallLogsQueryResults,
    setEntity: setCallLog,
    setEntities: setCallLogs,
    patchEntity: patchCallLog,
    addNewEntity: addNewCallLog,
    setEntityFetchingStatus: setCallLogFetchingStatus,
    setEntitiesFetchingStatus: setCallLogsFetchingStatus,
    setEntityFailedStatus: setCallLogFailedStatus,
    setEntityQueryError: setCallLogsQueryError,
    resetQuery: resetCallLogsQuery,
    setQueryList: setCallLogQueryList
  }
} = CreateEntitySlice<iCallLog>({
  sliceName: "callLogs",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...callLogsDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
