import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const EditEmailCampaignModal = React.lazy(
  () => import("./edit-email-campaign.modal"),
);

const EditEmailCampaign: iModalProps<
  typeof EditEmailCampaignModal,
  {
    campaignId: string;
  }
> = {
  name: "edit-email",
  component: GenerateModalWithSuspense(EditEmailCampaignModal),
};

export default EditEmailCampaign;
