import { SectionHeader } from "components/common/section-header";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { CustomConversationProperties } from "components/modules/conversations/components/custom-conversation-properties/custom-conversation-properties";
import { ConversationTagsConfiguration } from "components/modules/organization-management/conversations/conversation-tags/conversation-tags";
import { CRMTagsConfiguration } from "components/modules/organization-management/crm/crm-tags/crm-tags";

import { useMemo } from "react";
import { CustomCRMProperties } from "../sections/crm-settings/sections/custom-crm-properties/custom-crm-properties";

const CRMTags = () => {
  return (
    <>
      <SectionHeader
        title="Customer Tags"
        icon={<i className="ri-price-tag-3-line"></i>}
        description="Create & Manage available customer tags"
      />

      <CRMTagsConfiguration />
    </>
  );
};

const ConversationTags = () => {
  return (
    <>
      <SectionHeader
        title="Conversation Tags"
        icon={<i className="ri-price-tag-3-line"></i>}
        description="Create & Manage available chat tags"
      />

      <ConversationTagsConfiguration />
    </>
  );
};

export const ManageTagsAndAttributesOuter = ({
  settings,
}: {
  settings: "crm-tags" | "chat-tags" | "chat-properties" | "crm-properties";
}) => {
  const Component = useMemo(() => {
    if (settings === "crm-tags") {
      return <CRMTags />;
    }
    if (settings === "chat-tags") {
      return <ConversationTags />;
    }
    if (settings === "chat-properties") {
      return <CustomConversationProperties />;
    }
    if (settings === "crm-properties") {
      return <CustomCRMProperties />;
    }
    return <>Unidentified Setting: {settings}</>;
  }, [settings]);

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin p-8 overflow-x-hidden">
      {Component}
    </StyledScrollArea>
  );
};
