import { iUserAppPermission } from "@sdk/user-management/user-management.models";
import { iPermissionGuard } from "./permission-guard-models";

export const CheckPermission = (
  requiredPermissions: iPermissionGuard,
  userPermissions: iUserAppPermission[],
) => {
  // Todo: Check
  let hasPermission = true;
  for (const requiredApp in requiredPermissions) {
    for (const requiredScope in requiredPermissions[requiredApp]) {
      const matched = userPermissions.filter((appPermission) => {
        return appPermission.scopes.filter((scopeData) => {
          if (
            (requiredScope === "*" || requiredScope === scopeData.scope) &&
            appPermission.appId === requiredApp
          ) {
            return true;
          } else {
            return false;
          }
        }).length;
      });
      if (matched.length === 0) {
        hasPermission = false;
        return false;
      }
    }
  }
  return hasPermission;
};
