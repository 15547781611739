import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AllBadgesModal = React.lazy(() => import("./all-badges.modal"));

const AllBadges: iModalProps<typeof AllBadgesModal, {}> = {
  name: "badges",
  component: GenerateModalWithSuspense(AllBadgesModal),
};

export default AllBadges;
