import { createSlice, DeepPartial, PayloadAction } from "@reduxjs/toolkit";
import { DeepAssign } from "utils/deep-assign";
import {
  iCreditRecord,
  iOrganization
} from "../../../@sdk/user-management/user-management.models";
import { iOrganizationSlice } from "./workspace.model";

const OrganizationsSlice = createSlice({
  name: "organizations",
  initialState: {
    organization: undefined,
    credits: undefined
  } as iOrganizationSlice,
  reducers: {
    setOrganization(state, action: PayloadAction<iOrganization>) {
      state.organization = action.payload;
    },
    patchOrganization(
      state,
      action: PayloadAction<DeepPartial<iOrganization>>
    ) {
      DeepAssign(state.organization, action.payload);
    },
    setCredits(state, action: PayloadAction<iCreditRecord>) {
      state.credits = action.payload;
    },
    patchCredits(state, action: PayloadAction<DeepPartial<iCreditRecord>>) {
      DeepAssign(state.credits, action.payload);
    }
  }
});

export const OrganizationsSliceReducers = OrganizationsSlice.reducer;

export const OrganizationsSliceActions = OrganizationsSlice.actions;
