import { axios, AxiosResponse } from "@sdk/axios";
import { UserTracker } from "user-tracker";
import { ActiveSubscriptionRes } from "./billing-models";
import { iChangePlanReq } from "./user-management.controller-models";
import { iCreditRecord } from "./user-management.models";

export class BillingServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  async changePlan(req: iChangePlanReq) {
    UserTracker.track("BILLING - Change Plans", {});
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + "/billing/change-plan",
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async getRecentInvoices() {
    const results: AxiosResponse<any[]> = await axios.get(
      this.config.basePath + "/billing/invoices",
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async retrieveSubscription() {
    const results: AxiosResponse<ActiveSubscriptionRes> = await axios.get(
      this.config.basePath + "/billing/retrieve-subscription",
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async activateAddOn(req: { addOnId: string }) {
    UserTracker.track("BILLING - Activate Add On", { addon: req.addOnId });
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + "/billing/activate-add-on",
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }
  async deactivateAddOn(req: { addOnId: string }) {
    UserTracker.track("BILLING - DeActivate Add On", { addon: req.addOnId });
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + "/billing/deactivate-add-on",
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async buyCredits(req: { amount: number }) {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + "/billing/buy-credits",
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async getCredits() {
    const results: AxiosResponse<iCreditRecord> = await axios.get(
      this.config.basePath + "/organizations/me/credits",
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }
}
