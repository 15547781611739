import { iWidget } from "@sdk/chat-widgets/chat-widgets.models";
import { iConnection } from "@sdk/conversations/conversations.models";
import {
  Alert,
  Button,
  Checkbox,
  ColorPicker,
  Divider,
  Form,
  Input,
  Modal,
  Segmented,
  Select,
  Switch,
  Tooltip,
} from "antd";
import Slider, { Formatter } from "antd/es/slider";

import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { ModalTitle } from "components/common/modal-title";
import { LazyQuillEditor } from "components/common/quill-editor/lazy-quill-editor";
import { SectionHeader } from "components/common/section-header";
import { StyledSwitch } from "components/common/styled-swtich";
// import { WidgetPreviewHome } from "components/modules/connections/channels/chat-widget/components/configure-chat-widget/widget-preivew/widget-preview-home";
import { ChannelNameForm } from "components/modules/connections/common-connection-settings/channel-name/channel-name-form";
import { ConnectionLimits } from "components/modules/connections/common-connection-settings/connection-limits/connection-limits";
import { ConnectionTaggingPreferencesEditor } from "components/modules/connections/common-connection-settings/connection-tagging-preference-editor/connection-tagging-preferences-editor";
import { DeleteConnectionSettings } from "components/modules/connections/common-connection-settings/delete-connection-settings/delete-connection-settings";
import { EmailNotificationsForm } from "components/modules/connections/common-connection-settings/email-notifications-form/email-notifications-form";
import { RestrictAccessForm } from "components/modules/connections/common-connection-settings/restrict-access-form/restrict-access-form";
import { RoutingPreferencesForm } from "components/modules/connections/common-connection-settings/routing-preferences-form/routing-preferences-form";
import { ConnectionSelector } from "components/modules/conversations/components/connection-selector/connection-selector";

import BgPatternSelector from "components/common/bg-pattern-selector/bg-pattern-selector";
import {
  ConfigurationEditorMiniBase,
  ConfigurationEditorMiniComponent,
} from "components/common/configuration-editor-mini";

import { SimpleCollapseWithDivider } from "components/common/simple-collapse-with-header/simple-collapse-with-divider";
import SimpleModal from "components/common/simple-empty-modal/simple-empty.modal-registry";

import { useSDK } from "@sdk";
import { BorderBeam } from "components/common/border-beam/border-beam";

import { PerfectScrollbar } from "components/common/styled-scroll-area";
import { NewsfeedTypeSelector } from "components/modules/conversations/components/news-feed-type/news-feed-type-selector/news-feed-type-selector";
import { ImageInputWithUploader } from "components/modules/file-management/image-input-with-uploader";
import { SelfServicePortalSelector } from "components/pages/knowledge-base/self-service-portal-selector/self-service-portal-selector";
import { DarkModeBg } from "dark-mode-bg";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectChatWidgetByConnectionId } from "store/modules/chat-widgets/chat-widgets.selectors";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectShopifyStoreConfig } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import classNames from "utils/classnames";
import { CountryCodes } from "utils/country-codes";
import { isIpAddress } from "utils/is-ip-address";
import { stringArrayToSentence } from "utils/string-array-to-sentence";
import { CollapsibleHeader } from "../../../../../../common/collapsible-configuration-panel/collapsible-header";
import { ImageUploaderFormItem } from "../../../email/components/forms/email-template";
import { AutomaticEmailFollowups } from "../automatic-email-followups/automatic-email-followups";
import { ChatWidgetInstallInstructionsInner } from "../chat-widget-install-instructions/chat-widget-install-instructions";
import { ChatWidgetGreetings } from "../chat-widget-theme/chat-widget-greetings";
import { CustomTexts } from "../custom-texts/custom-texts";
import { ConfigureChatWidgetOfflineHours } from "../manage-offline-hours/manage-offline-hours";
import ManageChatCampaigns from "../manage-triggers/manage-chat-campaigns.modal-registry";
import { ManageWidgetFaqs } from "../manage-widget-faqs/manage-widget-faqs";
import { ChatbotAvatarPresets } from "./chat-bot-avatars";
import "./configure-chat-widget.scss";
import { defaultWidgetColors } from "./default-color-selections";
import { WidgetPreview } from "./widget-preview";

const preChatFormOptions = [
  { label: "Collect Name", value: "NAME" },
  { label: "Collect Email", value: "EMAIL" },
  { label: "Collect Mobile Number", value: "PHONE" },
  { label: "Obtain Marketing Consent", value: "MARKETING_PREFERENCE" },
];

const offSetSliderFormatter: Formatter = (value: number | undefined) =>
  `${value || 0}px`;

export const ConfigureChatWidgetConnection = ({
  connectionId,
  hideHeader,
  customInstallationButton,
}: {
  connectionId: string;
  hideHeader?: boolean;
  customInstallationButton?: JSX.Element;
}) => {
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const { state: widget } = useQueryWithStore(
    selectChatWidgetByConnectionId(connectionId),
    loadAllChatWidgets(),
    [connectionId],
  );

  return (
    <div className="animated fadeInLeftMin configure-chat-widget">
      {!hideHeader && (
        <>
          <SectionHeader
            title="Configure Chat Widget"
            icon={<i className="ri-chat-settings-line"></i>}
            description="Configure what your customers see when they chat with a user"
          />
          <Divider className="my-3" />
        </>
      )}

      <CustomizeChatWidget
        connectionId={connectionId}
        customInstallationButton={customInstallationButton}
      />
    </div>
  );
};

const CustomizeChatWidget = ({
  connectionId,
  hideHeader,
  customInstallationButton,
}: {
  connectionId: string;
  hideHeader?: boolean;
  customInstallationButton?: JSX.Element;
}) => {
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const { state: widget } = useQueryWithStore(
    selectChatWidgetByConnectionId(connectionId),
    loadAllChatWidgets(),
    [connectionId],
  );

  const shopifyPlugin = useSelector(selectShopifyStoreConfig);

  const dispatch = useDispatch();

  const { triggerTempModal, changePanelStateWithName } = useModalPanels();

  const {
    reload: reloadWidgetInstallationStatus,
    data: widgetInstallationStatus,
    isLoading: isWidgetInstallationStatusLoading,
  } = useSDK(
    (SDK) => SDK.chatWidgets.getInstallationStatus(widget?.id!),
    [widget],
    !widget,
  );

  const widgetPreviewRef = useRef<any>();

  return (
    <div className="animated fadeInLeftMin configure-chat-widget">
      <div
        className="flex flex-row overflow-hidden"
        style={{ maxHeight: "47rem" }}
      >
        <div className="">
          <WidgetPreview connectionId={connectionId} ref={widgetPreviewRef} />
        </div>
        <div className="flex-1 overflow-hidden">
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <div className="p-4 flex flex-col gap-2 overflow-x">
              <ConfigurationEditorMiniComponent
                icon={"ri-palette-line"}
                title="Widget Theme"
                description="Change widget colour, header pattern, chat bubble style, position and size"
                entityId={widget?.id!}
                entityType="WIDGET"
                onFormValueChange={(value) => {
                  const currentColor =
                    value?.configurations?.appearance?.colorPallet?.primary?.toHexString?.() ||
                    value?.configurations?.appearance?.colorPallet?.primary;
                  const currentPattern =
                    value?.configurations?.appearance?.headerPattern;
                  widgetPreviewRef?.current?.setWidgetColor?.(currentColor);
                  widgetPreviewRef?.current?.setHeaderPattern?.(currentPattern);
                }}
                transformFormValues={(value) => {
                  const currentColor =
                    value?.configurations?.appearance?.colorPallet?.primary?.toHexString?.();
                  if (currentColor) {
                    value.configurations.appearance.colorPallet.primary =
                      currentColor;
                  }
                  return value;
                }}
                miniEditor={
                  <>
                    <Form.Item
                      name={[
                        "configurations",
                        "appearance",
                        "colorPallet",
                        "primary",
                      ]}
                    >
                      <ColorPicker
                        arrow={false}
                        format="hex"
                        disabledAlpha
                        placement="bottomRight"
                        presets={[
                          { label: "Presets", colors: defaultWidgetColors },
                        ]}
                      />
                    </Form.Item>
                  </>
                }
                minEditorAutoSave={false}
              ></ConfigurationEditorMiniComponent>

              <ConfigurationEditorMiniComponent
                icon={"ri-equalizer-line"}
                title="Advanced Widget Look and Feel Customization"
                description="Change widget colour, header pattern, chat bubble style, position and size"
                entityId={widget?.id!}
                entityType="WIDGET"
                formLayout="horizontal"
                labelCol={{ span: 9 }}
                transformFormValues={(value) => {
                  const currentColor =
                    value?.configurations?.appearance?.colorPallet?.primary?.toHexString?.();
                  if (currentColor) {
                    value.configurations.appearance.colorPallet.primary =
                      currentColor;
                  }
                  return value;
                }}
              >
                <Form.Item
                  name={[
                    "configurations",
                    "appearance",
                    "colorPallet",
                    "primary",
                  ]}
                  label="Widget Theme"
                >
                  <ColorPicker
                    arrow={false}
                    format="hex"
                    disabledAlpha
                    placement="bottomRight"
                    presets={[
                      { label: "Presets", colors: defaultWidgetColors },
                    ]}
                  />
                </Form.Item>

                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const primaryColor = getFieldValue([
                      "configurations",
                      "appearance",
                      "colorPallet",
                      "primary",
                    ]);
                    return (
                      <Form.Item
                        name={["configurations", "appearance", "headerPattern"]}
                        label={"Header Pattern"}
                        rules={[]}
                      >
                        <BgPatternSelector
                          primaryColor={primaryColor}
                          width={"100%"}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
                {/* Widget Position */}
                <Form.Item
                  name={["configurations", "appearance", "launcherPosition"]}
                  label={"Widget Position"}
                  rules={[]}
                >
                  <Segmented
                    options={[
                      {
                        label: "Right",
                        value: "RIGHT_BOTTOM",
                        icon: <i className="ri-arrow-right-down-fill"></i>,
                      },
                      {
                        label: "left",
                        value: "LEFT_BOTTOM",
                        icon: <i className="ri-arrow-left-down-fill"></i>,
                      },
                    ]}
                  />
                </Form.Item>
                {/* Chat Bubble Style */}
                <Form.Item
                  name={["configurations", "appearance", "chatBubbleStyle"]}
                  label={"Widget Style"}
                  rules={[]}
                >
                  <Segmented
                    defaultValue={"rounded"}
                    options={[
                      {
                        label: "Rounded",
                        value: "rounded",
                        icon: <i className="ri-rounded-corner"></i>,
                      },
                      {
                        label: "Circle",
                        value: "circle",
                        icon: <i className="ri-circle-line"></i>,
                      },
                    ]}
                  />
                </Form.Item>
                {/* Chat Bubble Image */}
                <Form.Item
                  label="Custom Chat Bubble Image"
                  name={["configurations", "appearance", "chatBubbleImage"]}
                >
                  <ImageInputWithUploader
                    type={"CHAT_WIDGET"}
                    entityId={widget?.id!}
                    width={200}
                    height={120}
                    allowClear={true}
                    noPadding
                  />
                </Form.Item>

                {/* Chat Bubble Size */}
                <Form.Item
                  name={["configurations", "appearance", "chatBubbleSize"]}
                  label={"Chat Bubble Size"}
                  rules={[]}
                >
                  <Segmented
                    defaultValue={"DEFAULT"}
                    options={[
                      {
                        label: "Compact",
                        value: "COMPACT",
                      },
                      {
                        label: "Default",
                        value: "DEFAULT",
                      },
                      {
                        label: "Large",
                        value: "LARGE",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  name={["configurations", "appearance", "widgetSize"]}
                  label={"Chat Widget Size"}
                  rules={[]}
                >
                  <Segmented
                    defaultValue={"DEFAULT"}
                    options={[
                      {
                        label: "Compact",
                        value: "COMPACT",
                      },
                      {
                        label: "Default",
                        value: "DEFAULT",
                      },
                      {
                        label: "Large",
                        value: "LARGE",
                      },
                    ]}
                  />
                </Form.Item>
                {/* Chat Bubble X Offset */}
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const launcherPosition = getFieldValue([
                      "configurations",
                      "appearance",
                      "launcherPosition",
                    ]);
                    const positions =
                      launcherPosition === "LEFT_BOTTOM" ? "left" : "right";
                    return (
                      <Form.Item
                        name={[
                          "configurations",
                          "appearance",
                          "chatBubbleXOffset",
                        ]}
                        label={`Offset X`}
                        rules={[]}
                      >
                        <Slider
                          defaultValue={0}
                          tooltip={{ formatter: offSetSliderFormatter }}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>

                {/* Chat Bubble Y Offset */}
                <Form.Item
                  name={["configurations", "appearance", "chatBubbleYOffset"]}
                  label={"Offset Y"}
                  rules={[]}
                >
                  <Slider
                    defaultValue={0}
                    tooltip={{ formatter: offSetSliderFormatter }}
                  />
                </Form.Item>
              </ConfigurationEditorMiniComponent>

              <CustomTexts connectionId={connectionId} />

              <Divider>Install Widget</Divider>

              {customInstallationButton}
              {!customInstallationButton && (
                <>
                  <div
                    className={classNames(
                      "rounded-lg flex flex-row bg-gray-100 dark:bg-gray-800  gap-2 items-center justify-between configuration-mini-editor p-2 font-bold relative overflow-hidden",
                      {
                        "hover:bg-gray-200 hover:dark:bg-gray-700 cursor-pointer":
                          true,
                        "border border-gray-200 dark:border-gray-700":
                          widgetInstallationStatus?.status,
                        "border border-blue-600":
                          !widgetInstallationStatus?.status,
                      },
                    )}
                    onClick={() => {
                      triggerTempModal(SimpleModal, {
                        config: {
                          title: "Installation Instructions",
                          content: (
                            <>
                              <ChatWidgetInstallInstructionsInner
                                connectionId={connectionId}
                              />
                            </>
                          ),
                          icon: <i className="ri-tools-line"></i>,
                          width: 800,
                        },
                      });
                    }}
                  >
                    <div className="flex flex-row gap-2 items-center">
                      <div className="icon-container">
                        <i className="ri-tools-line"></i>
                      </div>
                      <div className="label">Installation Instructions</div>
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                      {widgetInstallationStatus.status ? (
                        <></>
                      ) : (
                        <Tooltip title="Your chat widget might not have been installed correctly. Click to recheck installation status">
                          <Button
                            type="text"
                            // className="cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              reloadWidgetInstallationStatus();
                            }}
                            loading={isWidgetInstallationStatusLoading}
                            // icon={<i className="ri-refresh-line"></i>}
                            icon={<i className="ri-error-warning-fill"></i>}
                          >
                            Not receiving hits
                          </Button>
                        </Tooltip>
                      )}
                      <div
                        className="config"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Button
                          type="text"
                          icon={<i className="ri-settings-2-line"></i>}
                        ></Button>
                      </div>
                    </div>
                    {!widgetInstallationStatus?.status && (
                      <BorderBeam className="rounded-lg" duration={5} />
                    )}
                  </div>
                </>
              )}

              <Divider>Greetings and Chat Routing</Divider>

              <ChatWidgetGreetings connectionId={connectionId} />

              {!widget?.configurations.conversationFlow.greetingsMessage
                .chatBotId && (
                <RoutingPreferencesForm connectionId={connectionId} />
              )}

              <ConfigurationEditorMiniComponent
                icon={"ri-send-plane-line"}
                title="Trigger greetings to all page visitors"
                description="Greeting message will be triggered after few seconds when a visitor lands on your website."
                entityId={widget?.id!}
                entityType="WIDGET"
                miniEditor={
                  <>
                    <Form.Item
                      name={[
                        "configurations",
                        "conversationFlow",
                        "greetingsMessage",
                        "autoTrigger",
                      ]}
                      hasFeedback={false}
                      valuePropName="checked"
                      style={{}}
                    >
                      <Switch size="small" />
                    </Form.Item>
                  </>
                }
              ></ConfigurationEditorMiniComponent>

              <ConfigurationEditorMiniComponent
                icon={"ri-id-card-fill"}
                title="Customize Chatbot Avatar and Name"
                description="Customize the avatar and name of chatbot in the live chat widget"
                entityId={widget?.id!}
                entityType="WIDGET"
              >
                <ImageUploaderFormItem
                  label="Bot Assistant Avatar"
                  fieldName={["configurations", "chatBotProfile", "avatar"]}
                  entityType="WIDGET"
                  entityId={widget?.id!}
                  rules={[]}
                  useFilePicker
                  pickButtonLabel="Pick Avatar"
                  imageClassName={"bg-white"}
                  filePickerPresetRenderer={({ onFileSelection }) => {
                    return (
                      <>
                        <div className="flex flex-row flex-wrap gap-4">
                          {ChatbotAvatarPresets.map((fileUrl) => (
                            <Tooltip key={fileUrl} title="Select Avatar">
                              <div
                                className="cursor-pointer border border-gray-200 dark:border-gray-700 rounded-lg flex flex-row items-center justify-center my-2 p-4 group relative dark:bg-white"
                                style={{ width: 133 }}
                                onClick={() => {
                                  onFileSelection({
                                    id: fileUrl,
                                    url: fileUrl,
                                  } as any);
                                }}
                              >
                                <img src={fileUrl} className="rounded-lg" />
                                <div className="absolute h-full w-full left-0 top-0 hidden group-hover:show">
                                  <div className="w-full h-full flex flex-row justify-center items-center absolute z-10">
                                    <div className="flex flex-row items-center">
                                      <i className="ri-hand mr-2"></i> Select
                                    </div>
                                  </div>
                                  <div className="absolute h-full w-full left-0 top-0 bg-white dark:bg-gray-900 opacity-50"></div>
                                </div>
                              </div>
                            </Tooltip>
                          ))}
                        </div>
                      </>
                    );
                  }}
                />

                <Form.Item
                  label="Bot Assistant Name"
                  name={["configurations", "chatBotProfile", "name"]}
                  rules={[]}
                >
                  <Input placeholder="Eg: AI Assistant" />
                </Form.Item>
              </ConfigurationEditorMiniComponent>

              <ConfigurationEditorMiniBase
                icon={"ri-chat-settings-line"}
                title={"Setup Custom Greetings for Landing Pages"}
                onClick={() => {
                  triggerTempModal(ManageChatCampaigns, { connectionId });
                }}
              />

              {/* Routing Settings */}

              <Divider>Chat Widget Features</Divider>

              <div className="flex flex-row gap-2">
                <div className="flex-1 flex flex-col gap-2">
                  <AutomaticEmailFollowups connectionId={connectionId!} />

                  {shopifyPlugin?.isActive && (
                    <ConfigurationEditorMiniComponent
                      icon={
                        <div>
                          <img
                            src="/assets/integrations/shopify.png"
                            style={{ width: "1.2rem" }}
                            alt=""
                            // className="mt-2"
                          />
                        </div>
                      }
                      title="Shopify Plugin"
                      description="Allow your customers to check order status right from ClickConnector"
                      entityId={widget?.id!}
                      entityType="WIDGET"
                      miniEditor={
                        <>
                          <Form.Item
                            name={[
                              "configurations",
                              "shopifyPlugin",
                              "isActive",
                            ]}
                            hasFeedback={false}
                            valuePropName="checked"
                            style={{}}
                          >
                            <Switch size="small" />
                          </Form.Item>
                        </>
                      }
                      triggerFullConfigOn={[
                        "configurations",
                        "shopifyPlugin",
                        "isActive",
                      ]}
                    >
                      <StyledSwitch
                        label="Enabled"
                        name={["configurations", "shopifyPlugin", "isActive"]}
                        disabled={!shopifyPlugin?.isActive}
                      />
                      {!shopifyPlugin?.isActive && (
                        <div className="flex flex-row justify-center items-center">
                          <span>Shopify Integration is not enabled</span>
                          <a
                            onClick={() => {
                              changePanelStateWithName(
                                "configurations",
                                true,
                                {},
                                {
                                  configurations: `APPS_AND_INTEGRATIONS`,
                                  activeConfigs: "Shopify",
                                },
                                [],
                              );
                            }}
                            className="pl-2 underline"
                          >
                            Click here to enable
                          </a>
                        </div>
                      )}
                    </ConfigurationEditorMiniComponent>
                  )}

                  {/* Chat Ratings */}
                  <ConfigurationEditorMiniComponent
                    icon={"ri-emotion-happy-line"}
                    title="Enable Chat Ratings"
                    description="Collect feedback after the conversation is ended"
                    entityId={widget?.id!}
                    entityType="WIDGET"
                    miniEditor={
                      <>
                        <Form.Item
                          name={[
                            "configurations",
                            "conversationFlow",
                            "chatRatings",
                            "enabled",
                          ]}
                          hasFeedback={false}
                          valuePropName="checked"
                          style={{}}
                        >
                          <Switch size="small" />
                        </Form.Item>
                      </>
                    }
                  ></ConfigurationEditorMiniComponent>
                  {/*  Knowledge Base */}
                  <ConfigurationEditorMiniComponent
                    icon={"ri-book-line"}
                    title="Show Knowledge Base"
                    description="Display your Knowledge Base articles right inside the chat widget"
                    entityId={widget?.id!}
                    entityType="WIDGET"
                    miniEditor={
                      <>
                        <Form.Item
                          valuePropName="checked"
                          name={["configurations", "knowledgeBase", "isActive"]}
                        >
                          <Switch size="small" />
                        </Form.Item>
                      </>
                    }
                    triggerFullConfigOn={[
                      "configurations",
                      "knowledgeBase",
                      "isActive",
                    ]}
                  >
                    <StyledSwitch
                      label="Enabled"
                      name={["configurations", "knowledgeBase", "isActive"]}
                    />
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue, setFieldsValue }) => {
                        const showPortal = getFieldValue([
                          "configurations",
                          "knowledgeBase",
                          "isActive",
                        ]);
                        if (showPortal) {
                          return (
                            <Form.Item
                              label="Knowledge Base Portal"
                              name={[
                                "configurations",
                                "knowledgeBase",
                                "selfServicePortalId",
                              ]}
                              hasFeedback
                            >
                              <SelfServicePortalSelector
                                autoSelectFirstOption
                              />
                            </Form.Item>
                          );
                        }
                        return <></>;
                      }}
                    </Form.Item>
                  </ConfigurationEditorMiniComponent>
                  <ConfigurationEditorMiniComponent
                    icon={"ri-file-list-line"}
                    title="Pre-chat Form"
                    description="Collect data through a simple form before a user starts to chat"
                    entityId={widget?.id!}
                    entityType="WIDGET"
                    miniEditor={
                      <>
                        <Form.Item
                          valuePropName="checked"
                          name={[
                            "configurations",
                            "conversationFlow",
                            "preChatForm",
                            "isEnabled",
                          ]}
                        >
                          <Switch size="small" />
                        </Form.Item>
                      </>
                    }
                  >
                    <StyledSwitch
                      label="Pre-chat form is enabled"
                      name={[
                        "configurations",
                        "conversationFlow",
                        "preChatForm",
                        "isEnabled",
                      ]}
                    />

                    <Form.Item shouldUpdate style={{ marginBottom: 4 }}>
                      {({ getFieldValue }) => {
                        const selectedOption = getFieldValue([
                          "configurations",
                          "conversationFlow",
                          "preChatForm",
                          "isEnabled",
                        ]);
                        if (selectedOption) {
                          return (
                            <>
                              <Form.Item
                                name={[
                                  "configurations",
                                  "conversationFlow",
                                  "preChatForm",
                                  "fields",
                                ]}
                                style={{ marginBottom: 4 }}
                              >
                                <Checkbox.Group options={preChatFormOptions} />
                              </Form.Item>
                              <Form.Item shouldUpdate noStyle>
                                {({ getFieldValue }) => {
                                  const fields = getFieldValue([
                                    "configurations",
                                    "conversationFlow",
                                    "preChatForm",
                                    "fields",
                                  ]);
                                  if (fields.includes("EMAIL")) {
                                    return (
                                      <StyledSwitch
                                        name={[
                                          "configurations",
                                          "conversationFlow",
                                          "preChatForm",
                                          "allowOnlyWorkEmails",
                                        ]}
                                        label={"Allow only professional email"}
                                      />
                                    );
                                  }
                                  return <div></div>;
                                }}
                              </Form.Item>
                              <Alert
                                message="If the client has already provided the required information, pre chat forms will be disabled automatically to improve the client experience"
                                type="info"
                              />

                              <div className="mt-4"></div>
                              <Form.Item
                                name={[
                                  "configurations",
                                  "conversationFlow",
                                  "preChatForm",
                                  "privacyPolicyLink",
                                ]}
                                label={
                                  "Optionally you could also display your privacy policy link"
                                }
                                rules={[
                                  {
                                    type: "url",
                                    message: "Please enter a valid URL",
                                  },
                                ]}
                              >
                                <Input placeholder="Privacy Policy Link (Optional)" />
                              </Form.Item>
                            </>
                          );
                        } else {
                          return <></>;
                        }
                      }}
                    </Form.Item>
                  </ConfigurationEditorMiniComponent>
                </div>
                <div className="flex-1 flex flex-col gap-2">
                  <ConfigureChatWidgetOfflineHours
                    connectionId={connectionId}
                  />
                  <ConfigurationEditorMiniComponent
                    icon={"ri-lock-2-line"}
                    title="Enable Customer Sign in"
                    description="Customer sign in allows your customers to sign in to your portal and see their chat history"
                    entityId={widget?.id!}
                    entityType="WIDGET"
                    miniEditor={
                      <>
                        <Form.Item
                          valuePropName="checked"
                          name={[
                            "configurations",
                            "customerSignIn",
                            "isEnabled",
                          ]}
                        >
                          <Switch size="small" />
                        </Form.Item>
                      </>
                    }
                  >
                    <StyledSwitch
                      label="Enabled"
                      name={["configurations", "customerSignIn", "isEnabled"]}
                    />
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue, setFieldsValue }) => {
                        const isEnabled = getFieldValue([
                          "configurations",
                          "customerSignIn",
                          "isEnabled",
                        ]);

                        if (isEnabled) {
                          return (
                            <div className="flex flex-row justify-end items-center">
                              <Button
                                type="link"
                                onClick={() => {
                                  changePanelStateWithName(
                                    "configurations",
                                    true,
                                    {},
                                    {
                                      activeConfigs:
                                        "Customer OTP Configurations",
                                      configurations: "POWER_SETTINGS",
                                    },
                                  );
                                }}
                                icon={<i className="ri-arrow-right-line"></i>}
                              >
                                Configure email connection to use when sending
                                OTP
                              </Button>
                            </div>
                          );
                        }
                        return <></>;
                      }}
                    </Form.Item>
                  </ConfigurationEditorMiniComponent>
                  <ConfigurationEditorMiniComponent
                    icon={"ri-sticky-note-line"}
                    title="Widget Announcement"
                    description="Show a simple temporary announcement to all customers"
                    entityId={widget?.id!}
                    entityType="WIDGET"
                    miniEditor={
                      <>
                        <Form.Item
                          valuePropName="checked"
                          name={[
                            "configurations",
                            "announcements",
                            "isEnabled",
                          ]}
                        >
                          <Switch size="small" />
                        </Form.Item>
                      </>
                    }
                    triggerFullConfigOn={[
                      "configurations",
                      "announcements",
                      "isEnabled",
                    ]}
                  >
                    <StyledSwitch
                      label="Enabled"
                      name={["configurations", "announcements", "isEnabled"]}
                    />

                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue, setFieldsValue }) => {
                        const isEnabled = getFieldValue([
                          "configurations",
                          "announcements",
                          "isEnabled",
                        ]);

                        if (isEnabled) {
                          return (
                            <Form.Item
                              label="Message"
                              name={[
                                "configurations",
                                "announcements",
                                "message",
                              ]}
                            >
                              <LazyQuillEditor />
                            </Form.Item>
                          );
                        }
                        return <></>;
                      }}
                    </Form.Item>
                  </ConfigurationEditorMiniComponent>
                  <ConfigurationEditorMiniComponent
                    icon={"ri-megaphone-line"}
                    title="Newsfeed"
                    description="Newsfeed allows you to show updates via the live chat widget"
                    entityId={widget?.id!}
                    entityType="WIDGET"
                    miniEditor={
                      <>
                        <Form.Item
                          valuePropName="checked"
                          name={["configurations", "newsfeedConfig", "enabled"]}
                        >
                          <Switch size="small" />
                        </Form.Item>
                      </>
                    }
                    triggerFullConfigOn={[
                      "configurations",
                      "newsfeedConfig",
                      "enabled",
                    ]}
                  >
                    <StyledSwitch
                      label="Enabled"
                      name={["configurations", "newsfeedConfig", "enabled"]}
                    />
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue, setFieldsValue }) => {
                        const isEnabled = getFieldValue([
                          "configurations",
                          "newsfeedConfig",
                          "enabled",
                        ]);

                        if (isEnabled) {
                          return (
                            <Form.Item
                              label="Select Newsfeed"
                              name={[
                                "configurations",
                                "newsfeedConfig",
                                "newsfeedTypeId",
                              ]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select a newsfeed type",
                                },
                              ]}
                            >
                              <NewsfeedTypeSelector />
                            </Form.Item>
                          );
                        }
                        return <></>;
                      }}
                    </Form.Item>
                  </ConfigurationEditorMiniComponent>
                </div>
              </div>

              <ChatWidgetPowerSettings connectionId={connectionId} />
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
};

const ChatWidgetPowerSettings = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const { state: widget } = useQueryWithStore(
    selectChatWidgetByConnectionId(connectionId),
    loadAllChatWidgets(),
    [connectionId],
  );

  const { triggerTempModal } = useModalPanels();
  return (
    <div className="animated fadeInLeftMin">
      <SimpleCollapseWithDivider title="Power Settings">
        <ChannelNameForm connectionId={connectionId} />

        <RestrictAccessForm connectionId={connectionId} />

        <EmailNotificationsForm connectionId={connectionId} />

        <RoutingPreferencesForm connectionId={connectionId} />

        <ConfigurationEditorMiniComponent
          icon={"ri-chat-settings-line"}
          title="Allow Multiple Active Chats"
          description="Customer are allowed to start a second chat while one conversation is open"
          entityId={widget?.id!}
          entityType="WIDGET"
          miniEditor={
            <>
              <Form.Item
                valuePropName="checked"
                name={["configurations", "allowMultipleActiveChat"]}
              >
                <Switch size="small" />
              </Form.Item>
            </>
          }
        ></ConfigurationEditorMiniComponent>

        <ConfigurationEditorMiniComponent
          icon={"ri-chat-upload-line"}
          title="Auto Widget Popup"
          description="Automatically open the chat widget when there is new message"
          entityId={widget?.id!}
          entityType="WIDGET"
          miniEditor={
            <>
              <Form.Item
                valuePropName="checked"
                name={[
                  "configurations",
                  "automaticallyOpenChatWidgetOnNewMessages",
                ]}
              >
                <Switch size="small" />
              </Form.Item>
            </>
          }
        ></ConfigurationEditorMiniComponent>

        <ConfigurationEditorMiniComponent
          icon={"ri-honour-line"}
          title="Remove ClickConnector Branding"
          description="Remove ClickConnector branding in the home screen of the live chat widget."
          entityId={widget?.id!}
          entityType="WIDGET"
        >
          <Alert
            message="Consider supporting us by keeping the non-obstructive text - 'Powered by ClickConnector' "
            type="info"
            className="mb-2"
          />
          <StyledSwitch
            label="Remove ClickConnector Branding"
            name={["configurations", "hideCCLogo"]}
          />
        </ConfigurationEditorMiniComponent>

        <ConfigurationEditorMiniComponent
          icon={"ri-mail-settings-line"}
          title="Connected Ticket Connection"
          description="If you are going to use Chat Widget APIs to create a conversation programmatically, you'll need to set up the ticket connection"
          entityId={connectionId!}
          entityType="CONNECTION"
        >
          <Form.Item
            label="Connections to Use"
            name={["data", "ticketConnectionId"]}
          >
            <ConnectionSelector
              connectionFilter={(connection) =>
                connection.type === "EMAIL" || connection.type === "EMAIL_V2"
              }
              allowClear={true}
            />
          </Form.Item>
        </ConfigurationEditorMiniComponent>
        <ConfigurationEditorMiniComponent
          icon={"ri-run-line"}
          title="Page Navigation Activities"
          description="Show Customer Page Navigation Activity in Chat Messages"
          entityId={connectionId!}
          entityType="CONNECTION"
          transformFormValues={(values) => ({
            data: {
              disablePageNavigationActivitiesInChats:
                !values.data.enablePageNavigationActivitiesInChats,
            },
          })}
          transformEntityValues={(connection: iConnection) => ({
            data: {
              enablePageNavigationActivitiesInChats:
                !connection.data.disablePageNavigationActivitiesInChats,
            },
          })}
          miniEditor={
            <>
              <Form.Item
                valuePropName="checked"
                name={["data", "enablePageNavigationActivitiesInChats"]}
              >
                <Switch size="small" />
              </Form.Item>
            </>
          }
        ></ConfigurationEditorMiniComponent>

        <ConfigurationEditorMiniComponent
          icon="ri-spam-3-line"
          title="Blacklisted Clients"
          description="disable the widget if a person tries to access chat through specific IPs"
          entityId={widget?.id!}
          entityType="WIDGET"
        >
          <Form.Item
            label="Blacklisted IPs"
            name={["configurations", "blackListedClients", "ips"]}
            hasFeedback
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, ips) {
                  if (!ips || ips.length === 0) {
                    return Promise.resolve();
                  }
                  const invalidIps = ips.filter((ip) => !isIpAddress(ip));
                  if (invalidIps.length > 0) {
                    return Promise.reject(
                      `The following Ips are Invalid: ${stringArrayToSentence(
                        invalidIps,
                      )}`,
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Blacklisted IPs"
              dropdownStyle={{ display: "none" }}
              allowClear
            ></Select>
          </Form.Item>
          <Form.Item
            label="Whitelisted Countries"
            name={[
              "configurations",
              "blackListedClients",
              "whiteListedCountries",
            ]}
            hasFeedback
            help="Leave it blank to allow all countries"
          >
            <Select showSearch mode="tags">
              {CountryCodes.map((country) => (
                <Select.Option
                  value={`${country.code}|${country.name}`}
                  key={country.code}
                >
                  {country.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </ConfigurationEditorMiniComponent>

        <ConfigurationEditorMiniComponent
          icon="ri-shield-check-line"
          title="Whitelisted Domains"
          description="Domains where the widget can be embedded"
          entityId={widget?.id!}
          entityType="WIDGET"
        >
          <Form.Item
            label="Whitelisted Domains"
            name={["configurations", "whitelistedDomains"]}
            hasFeedback
            rules={[]}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Whitelisted Domains"
              dropdownStyle={{ display: "none" }}
              allowClear
            ></Select>
          </Form.Item>
          <Alert
            type="info"
            showIcon={true}
            message={
              "Make sure to add your knowledge base domain if you are using one"
            }
          />
        </ConfigurationEditorMiniComponent>

        <ConfigurationEditorMiniComponent
          icon={"ri-file-info-line"}
          title="Automatic Form Submission Tracking"
          description="Track form submissions and associated data as activity of customer"
          entityId={widget?.id!}
          entityType="WIDGET"
          transformFormValues={(values) => ({
            configurations: {
              disableFormTracking: !values.configurations.enableFormTracking,
            },
          })}
          transformEntityValues={(widget: iWidget) => ({
            configurations: {
              enableFormTracking: !widget.configurations.disableFormTracking,
            },
          })}
          miniEditor={
            <>
              <Form.Item
                valuePropName="checked"
                name={["configurations", "enableFormTracking"]}
              >
                <Switch size="small" />
              </Form.Item>
            </>
          }
        ></ConfigurationEditorMiniComponent>

        <ConnectionTaggingPreferencesEditor connectionId={connectionId} />

        <ConfigurationEditorMiniBase
          icon={"ri-git-commit-fill"}
          title={"User Limits"}
          description={"Prevent users from being assigned too many chats"}
          onClick={() => {
            triggerTempModal(SimpleModal, {
              config: {
                title: "User Limits",
                content: <ConnectionLimits connectionId={connectionId!} />,
                icon: <i className="ri-git-commit-fill"></i>,
                width: 800,
              },
            });
          }}
        />

        <ConfigurationEditorMiniComponent
          icon={"ri-chat-delete-line"}
          title="Disable Widget"
          description="Disable the widget on sites temporarily"
          entityId={widget?.id!}
          entityType="WIDGET"
          transformFormValues={(values) => ({
            metaData: {
              isActive: !values.metaData.isDisabled,
            },
          })}
          transformEntityValues={(widget: iWidget) => ({
            metaData: {
              isDisabled: !widget.metaData.isActive,
            },
          })}
          miniEditor={
            <>
              <Form.Item
                valuePropName="checked"
                name={["metaData", "isDisabled"]}
              >
                <Switch size="small" />
              </Form.Item>
            </>
          }
        ></ConfigurationEditorMiniComponent>

        <Divider>Danger</Divider>
        <DeleteConnectionSettings connectionId={connectionId!} />
      </SimpleCollapseWithDivider>
    </div>
  );
};

const FAQsConfig = ({ connectionId }: { connectionId: string }) => {
  const [isFaqModalVisible, setFaqModalVisible] = useState(false);
  return (
    <>
      <div
        className="cursor-pointer border border-gray-400 dark:border-gray-700 rounded-lg p-4  flex flex-row items-center"
        onClick={() => setFaqModalVisible(true)}
      >
        <i className="ri-arrow-right-s-line text-lg mr-2"></i>
        <CollapsibleHeader
          icon={<i className={`ri-question-answer-line text-3xl`}></i>}
          title={"Setup FAQs (Depreciated - Use Knowledge Base instead)"}
          description={
            "Setup up most frequently asked questions for your customers to browse"
          }
        />
      </div>
      <Modal
        title={
          <ModalTitle
            title="Setup FAQs"
            icon={<i className="ri-question-answer-line"></i>}
          />
        }
        open={isFaqModalVisible}
        footer={null}
        onCancel={() => {
          setFaqModalVisible(false);
        }}
        data-click-context="Edit FAQs Modal"
        width={"100%"}
      >
        <ManageWidgetFaqs connectionId={connectionId} />
        <DarkModeBg />
      </Modal>
    </>
  );
};
