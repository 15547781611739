import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iArticle } from "@sdk/knowledge-base/knowledge-base.models";
import React from "react";

const ArticleEditorModal = React.lazy(() => import("./article-editor.modal"));

const ArticleEditor: iModalProps<
  typeof ArticleEditorModal,
  {
    articleId?: string;
    collectionId?: string;
    onArticleCreated?: (article: iArticle) => any;
  }
> = {
  name: "article-editor",
  component: GenerateModalWithSuspense(ArticleEditorModal),
};

export default ArticleEditor;
