import { iCallBackRequest } from "@sdk/call-back-requests/call-back-requests-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { callBackRequestsDefaultQueryConfig } from "./call-back-requests-default-query";

export const {
  slice: CallBackRequestsSlice,
  reducers: CallBackRequestsSliceReducer,
  actions: {
    setEntityQueryResults: setCallBackRequestsQueryResults,
    setEntity: setCallBackRequest,
    setEntities: setCallBackRequests,
    patchEntity: patchCallBackRequest,
    addNewEntity: addNewCallBackRequest,
    setEntityFetchingStatus: setCallBackRequestFetchingStatus,
    setEntitiesFetchingStatus: setCallBackRequestsFetchingStatus,
    setEntityFailedStatus: setCallBackRequestFailedStatus,
    setEntityQueryError: setCallBackRequestsQueryError,
    resetQuery: resetCallBackRequestsQuery,
    setQueryList: setCallBackRequestQueryList
  }
} = CreateEntitySlice<iCallBackRequest>({
  sliceName: "callBackRequests",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...callBackRequestsDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
