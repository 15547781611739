export const GenerateEmptyUrlConfigBlock = (method: string = "GET") => {
  const config = {
    method: `POST`,
  };
  Object.assign(config, {
    url: "https://",
    urlParameters: {},
    queryParaMeters: {},
    body: {},
    headers: {},
  });
  return config;
};
