import { axios } from "@sdk/axios";
import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import { AxiosResponse } from "axios";
import { UserTracker } from "user-tracker";
import { iTestimonial } from "./testimonials-model";

export class TestimonialsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.testimonials.configure(config);
  }

  testimonials = Object.assign(
    new EntityServices<iTestimonial>(SDKConfig, "testimonials", {
      onCreate: () => {
        UserTracker.track("TESTIMONIAL - Create", {});
      },
      onDelete: () => {
        UserTracker.track("TESTIMONIAL - Delete", {});
      },
    }),
    {
      getAverageRating: async (query: any) => {
        const results: AxiosResponse<{ average: number }> = await axios.post(
          this.config.basePath + `/testimonials/get-average-rating`,
          query,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      getCount: async (query: any) => {
        const results: AxiosResponse<{ count: number }> = await axios.post(
          this.config.basePath + `/testimonials/get-count`,
          query,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
