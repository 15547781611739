import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const MangeCustomInboxViewsModal = React.lazy(
  () => import("./manage-custom-views.modal"),
);

const MangeCustomInboxViews: iModalProps<typeof MangeCustomInboxViewsModal> = {
  name: "manage-custom-inbox-view",
  component: GenerateModalWithSuspense(MangeCustomInboxViewsModal),
};

export default MangeCustomInboxViews;
