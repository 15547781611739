import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const RequestFeatureModal = React.lazy(() => import("./request-feature.modal"));

const RequestLegacyFeature: iModalProps<
  typeof RequestFeatureModal,
  {
    onAdded?: () => any;
  }
> = {
  name: "request-feature",
  component: GenerateModalWithSuspense(RequestFeatureModal),
};

export default RequestLegacyFeature;
