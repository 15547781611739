import { TelnyxRTCContext } from "@telnyx/react-client";
import { ICall } from "@telnyx/webrtc";
import { IWebRTCCall } from "@telnyx/webrtc/lib/src/Modules/Verto/webrtc/interfaces";
import { Button, Select } from "antd";
import { useContext, useEffect, useState } from "react";

export const TelnyxMuteButton = ({ call }: { call?: ICall & IWebRTCCall }) => {
  const client = useContext(TelnyxRTCContext);
  const [isMuted, setMuted] = useState(false);
  return (
    <div>
      {isMuted && (
        <Button
          type="text"
          shape="circle"
          icon={<i className="ri-mic-off-line"></i>}
          onClick={async () => {
            call?.unmuteAudio();
            setMuted(false);
          }}
        />
      )}
      {!isMuted && (
        <Button
          type="text"
          shape="circle"
          icon={<i className="ri-mic-line"></i>}
          onClick={() => {
            call?.muteAudio();
            setMuted(true);
          }}
        />
      )}
    </div>
  );
};

export const TelnyxAudioInDeviceSelector = ({
  call,
}: {
  call?: ICall & IWebRTCCall;
}) => {
  const client = useContext(TelnyxRTCContext);

  const [audioInDevices, setAudioInDevices] = useState([] as MediaDeviceInfo[]);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState(
    undefined as any,
  );

  useEffect(() => {
    const fetchAudioInDevices = async () => {
      const result = await client?.getAudioInDevices();
      setAudioInDevices(result || []);
    };
    fetchAudioInDevices();
  }, [client]);
  return (
    <div>
      <Select
        style={{ minWidth: 140, maxWidth: 140 }}
        placeholder="Select Audio In"
        bordered={false}
        value={selectedAudioDevice}
        onChange={async (value) => {
          setSelectedAudioDevice(value);
          const x = await call?.setAudioInDevice(value);
          await client?.setAudioSettings({
            micId: value,
          });
        }}
      >
        {(audioInDevices || []).map((device) => (
          <Select.Option value={device.deviceId} key={device.deviceId}>
            {device.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export const TelnyxAudioOutDeviceSelector = ({
  call,
}: {
  call?: ICall & IWebRTCCall;
}) => {
  const client = useContext(TelnyxRTCContext);

  const [audioOutDevices, setAudioOutDevices] = useState(
    [] as MediaDeviceInfo[],
  );

  const [selectedAudioDevice, setSelectedAudioDevice] = useState(
    undefined as any,
  );

  useEffect(() => {
    const fetchAudioInDevices = async () => {
      const result = await client?.getAudioOutDevices();
      console.log("result", result, client?.speaker);
      setAudioOutDevices(result || []);
    };
    fetchAudioInDevices();
  }, [client]);

  return (
    <div>
      <Select
        style={{ minWidth: 140, maxWidth: 140 }}
        placeholder="Select Audio Out"
        bordered={false}
        value={selectedAudioDevice}
        onChange={async (value) => {
          setSelectedAudioDevice(value);
          const device = audioOutDevices.find((d) => d.deviceId === value);
          if (client) {
            client.speaker = value;
          }
          if (call) {
            await call.setAudioOutDevice(value);
            // await call.setSpeakerPhone?.(true);
          }
        }}
      >
        {(audioOutDevices || []).map((device) => (
          <Select.Option value={device.deviceId} key={device.deviceId}>
            {device.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
