import { iNotificationRecord } from "@sdk/notification-inbox/notifications.models";
import { createSelector } from "reselect";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectNotificationQueryMap,
  selectEntityMap: selectNotificationsMap,
  selectQueryMapAll: selectNotificationsMapAll,
  selectQueryAllList: selectNotificationsAllList,
  selectAllEntities: selectAllNotifications,
  selectAllEntitiesQuery: selectAllNotificationsQuery,
  selectEntityById: selectNotificationById,
  selectEntityQuery: selectNotificationsQuery
} = CreateSelectorForEntities<iStore, iNotificationRecord>({
  sliceName: "notifications"
});

export const selectActiveNotification = createSelector(
  selectAllNotifications,
  allNotifications => {
    return allNotifications.filter(notifications => !notifications.isDismissed);
  }
);
