import { iStore } from "../../store.model";

export const selectIsUserOnline = (userId: string) => (state: iStore) =>
  state.userPresence.users[userId]?.isOnline || false;

export const selectIsSessionOnline = (sessionIds: string[]) => (
  state: iStore
) => {
  for (const sessionId of sessionIds) {
    if (state.userPresence.users[sessionId]?.isOnline) {
      return true;
    }
  }

  // Patch
  for (const sessionId of sessionIds) {
    if (state.sessions.byIds[sessionId]?.metaData?.active) {
      return true;
    }
  }
  return false;
};
