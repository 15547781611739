import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ManageUserGroupMembersModal = React.lazy(
  () => import("./manager-members.modal"),
);

const ManageUserGroupMembers: iModalProps<
  typeof ManageUserGroupMembersModal,
  { userGroupId: string }
> = {
  name: "manage-user-group-members",
  component: GenerateModalWithSuspense(ManageUserGroupMembersModal),
};

export default ManageUserGroupMembers;
