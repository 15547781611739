import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import axios, { AxiosResponse } from "axios";
import { UserTracker } from "user-tracker";
import { iConnectedApp } from "./connected-apps.models";

export class ConnectedAppsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.connectedApps.configure(config);
  }

  connectedApps = Object.assign(
    new EntityServices<iConnectedApp>(SDKConfig, "connected-apps", {
      onCreate: () => {
        UserTracker.track("CONNECTED_APPS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("CONNECTED_APPS - Delete", {});
      },
    }),
    {
      testWebhook: async (connectedAppId: string) => {
        const results: AxiosResponse<{
          success: boolean;
          url: string;
          payload: any;
          statusCode: number;
        }> = await axios.post(
          this.config.basePath + `/connected-apps/${connectedAppId}/test`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
