import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const UserPickerModal = React.lazy(() => import("./user-picker.modal"));

const UserPicker: iModalProps<
  typeof UserPickerModal,
  { onUserSelected: (userId: string) => any; title?: JSX.Element }
> = {
  name: "user-picker",
  component: GenerateModalWithSuspense(UserPickerModal),
};

export default UserPicker;
