export const durationOptions = [
  {
    value: JSON.stringify({ $lt: 5 * 60 * 1000, $exists: true }),
    title: "Less than 5 minutes"
  },
  {
    value: JSON.stringify({
      $gte: 5 * 60 * 1000,
      $lt: 60 * 60 * 1000,
      $exists: true
    }),
    title: "Between 5 and 60 minutes"
  },
  {
    value: JSON.stringify({
      $gte: 1 * 60 * 60 * 1000,
      $lt: 4 * 60 * 60 * 1000,
      $exists: true
    }),
    title: "Between 1 and 4 hours"
  },
  {
    value: JSON.stringify({
      $gte: 4 * 60 * 60 * 1000,
      $lt: 24 * 60 * 60 * 1000,
      $exists: true
    }),
    title: "Between 4 and 24 hours"
  },
  {
    value: JSON.stringify({
      $gte: 24 * 60 * 60 * 1000,
      $exists: true
    }),
    title: "More than 24 hours"
  }
];
