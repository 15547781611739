import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddEditProductTourModal = React.lazy(
  () => import("./add-edit-product-tour.modal"),
);

const AddEditProductTour: iModalProps<
  typeof AddEditProductTourModal,
  {
    tourId?: string;
  }
> = {
  name: "add-edit-product-tour",
  component: GenerateModalWithSuspense(AddEditProductTourModal),
};

export default AddEditProductTour;
