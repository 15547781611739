import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const CompanyPickerModal = React.lazy(() => import("./company-picker.modal"));

const CompanyPicker: iModalProps<
  typeof CompanyPickerModal,
  {
    onCompanySelected: (companyId: string) => any;
    title?: JSX.Element;
    hideAdd?: boolean;
    hideOnSelected?: boolean;
  }
> = {
  name: "company-picker",
  component: GenerateModalWithSuspense(CompanyPickerModal),
};

export default CompanyPicker;
