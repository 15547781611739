import { iSession } from "@sdk/sessions/sessions.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { sessionsDefaultQueryConfig } from "./sessions-default-query";

export const {
  slice: SessionsSlice,
  reducers: SessionsSliceReducer,
  actions: {
    setEntityQueryResults: setSessionsQueryResults,
    setEntity: setSession,
    setEntities: setSessions,
    patchEntity: patchSession,
    addNewEntity: addNewSession,
    setEntityFetchingStatus: setSessionFetchingStatus,
    setEntitiesFetchingStatus: setSessionsFetchingStatus,
    setEntityFailedStatus: setSessionFailedStatus,
    setEntityQueryError: setSessionsQueryError,
    resetQuery: resetSessionsQuery,
    setQueryList: setSessionQueryList
  }
} = CreateEntitySlice<iSession>({
  sliceName: "sessions",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "activeSessions") {
      return {
        ...sessionsDefaultQueryConfig,
        query: {
          // "metaData.active": true,
        },
        alias: "activeSessions"
      };
    }
    // Get Sessions by contact Id
    // console.error("Unknown Query Alias under Sessions", queryAlias);
  }
});
