import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const UniboxShortcutsExplorerModal = React.lazy(
  () => import("./unibox-shortcuts-explorer.modal"),
);

const UniboxShortcutsExplorer: iModalProps<
  typeof UniboxShortcutsExplorerModal,
  {}
> = {
  name: "unibox-shortcuts",
  component: GenerateModalWithSuspense(UniboxShortcutsExplorerModal),
};

export default UniboxShortcutsExplorer;
