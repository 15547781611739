import { iContact } from "@sdk/crm/crm.models";
import { iUser } from "@sdk/user-management/user-management.models";
import { getContactName } from "components/modules/crm/contacts/helpers/get-contact-name";
import { getUserName } from "components/modules/user-management/helpers/get-user-name";

export const AvailableTokens = [
  "CONTACT_FIRST_NAME",
  "CONTACT_LAST_NAME",
  "CONTACT_NAME",
  "CONTACT_EMAIL",
  "CONTACT_PHONE",
  "USER_NAME",
  "USER_EMAIL",
  "USER_FIRST_NAME",
  "USER_LAST_NAME",
];

export const AvailableTokenTable = [
  {
    token: "CONTACT_FIRST_NAME",
    label: "Customer's First Name",
  },
  {
    token: "CONTACT_LAST_NAME",
    label: "Customer's Last Name",
  },
  {
    token: "CONTACT_NAME",
    label: "Customer's Name",
  },
  {
    token: "CONTACT_EMAIL",
    label: "Customer's Email",
  },
  {
    token: "CONTACT_PHONE",
    label: "Customer's Phone Number",
  },
  // {
  //   token: "WORKSPACE_NAME",
  //   label: "Your Workspace Name",
  // },
  {
    token: "USER_NAME",
    label: "Current User's Name",
  },
  {
    token: "USER_EMAIL",
    label: "Current User's Email",
  },
  {
    token: "USER_FIRST_NAME",
    label: "Current User's First Name",
  },
  {
    token: "USER_LAST_NAME",
    label: "Current User's Last Name",
  },
];

export const AvailableCampaignTokenTable = [
  {
    token: "CONTACT_FIRST_NAME",
    label: "Customer's First Name",
  },
  {
    token: "CONTACT_LAST_NAME",
    label: "Customer's Last Name",
  },
  {
    token: "CONTACT_NAME",
    label: "Customer's Name",
  },
  {
    token: "CONTACT_EMAIL",
    label: "Customer's Email",
  },
];

export const MessageTokenReplacerMap = {
  CONTACT: {
    FIRST_NAME: (contact: iContact) => contact.data.firstName,
    LAST_NAME: (contact: iContact) => contact.data.lastName,
    NAME: getContactName,
    EMAIL: (contact: iContact) => contact.data.primaryEmail,
    PHONE: (contact: iContact) => contact.data.primaryMobile,
  },
  USER: {
    NAME: (user: iUser) => getUserName(user),
    EMAIL: (user: iUser) => user.credentials.email,
    FIRST_NAME: (user: iUser) => user.data.firstName,
    LAST_NAME: (user: iUser) => user.data.lastName,
  },
};
