import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iRemoteResourcesSlice } from "./remote-resource-cache.model";

const RemoteResourcesSlice = createSlice({
  name: "remoteResources",
  initialState: {} as iRemoteResourcesSlice,
  reducers: {
    setResourceCache(
      state,
      action: PayloadAction<{ key: string; data: any; hasError?: boolean }>,
    ) {
      state[action.payload.key] = {
        cachedAt: Date.now(),
        data: action.payload.data,
        hasError: action.payload.hasError,
      };
    },
    removeResourceCache(state, action: PayloadAction<{ key: string }>) {
      delete state[action.payload.key];
    },
    removeMultipleCache(state, action: PayloadAction<{ keys: string[] }>) {
      for (const key of action.payload.keys) {
        delete state[key];
      }
    },
  },
});

export const RemoteResourcesSliceReducers = RemoteResourcesSlice.reducer;

export const RemoteResourcesSliceActions = RemoteResourcesSlice.actions;
