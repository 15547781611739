import { Tag } from "antd";
import { useMemo } from "react";

function taskPriorityToString(priority: number) {
  if (priority > 90) {
    return "Very High";
  }
  if (priority > 75) {
    return "High";
  }
  if (priority > 50) {
    return "Medium";
  }
  if (priority > 25) {
    return "Low";
  }
  return "Very Low";
}

export const PriorityTag = ({ priority }: { priority: number }) => {
  const label = taskPriorityToString(priority);
  const tagColor = useMemo(() => {
    if (label === "Very High") {
      return "red";
    }
    if (label === "High") {
      return "orange";
    }
    return "default";
  }, [label]);
  return (
    <Tag color={tagColor} className="rounded-md font-bold">
      {label}
    </Tag>
  );
};
