import { iIgMedia } from "@sdk/ig-media/instagram-models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { igMediasDefaultQueryConfig } from "./ig-medias-default-query";

export const {
  slice: IgMediasSlice,
  reducers: IgMediasSliceReducer,
  actions: {
    setEntityQueryResults: setIgMediasQueryResults,
    setEntity: setIgMedia,
    setEntities: setIgMedias,
    patchEntity: patchIgMedia,
    addNewEntity: addNewIgMedia,
    setEntityFetchingStatus: setIgMediaFetchingStatus,
    setEntitiesFetchingStatus: setIgMediasFetchingStatus,
    setEntityFailedStatus: setIgMediaFailedStatus,
    setEntityQueryError: setIgMediasQueryError,
    resetQuery: resetIgMediasQuery,
    setQueryList: setIgMediaQueryList
  }
} = CreateEntitySlice<iIgMedia>({
  sliceName: "igMedias",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...igMediasDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
