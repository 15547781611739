export function MaskSecretKey(input: string, length: number = 4): string {
  // Check if the input string length is more than 4
  if (input.length > length) {
    // Keep the first 4 characters and replace the rest with asterisks
    return input.substring(0, length) + "*".repeat(input.length - length);
  } else {
    // If the string is 4 characters or less, return it as is
    return input;
  }
}
