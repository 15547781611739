import { iConnectorAPICallConfig } from "@sdk/chat-connectors/chat-connectors.models";
import { useAxios, useAxiosAction } from "@sdk/sdk.hooks";
import { message } from "antd";
import { GenerateUrlConfig } from "components/modules/conversations/components/chat-view-side-bar/components/generate-url-config";
import { useMemo } from "react";
import { useSelector, useStore } from "react-redux";
import { Store } from "redux";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { selectCurrentUser } from "store/modules/users/users.selectors";

export const AvailableAPITokens = [
  {
    token: "CONTACT_ID",
    label: "Customer ID",
  },
  {
    token: "CONTACT_FIRST_NAME",
    label: "Customer's First Name",
  },
  {
    token: "CONTACT_LAST_NAME",
    label: "Customer's Last Name",
  },
  {
    token: "CONTACT_EMAIL",
    label: "Customer's Email",
  },
  {
    token: "CONTACT_PHONE",
    label: "Customer's Mobile Number",
  },
  {
    token: "CONVERSATION_ID",
    label: "Conversation Id",
  },
  {
    token: "CONVERSATION_TAGS",
    label: "Conversation tags",
  },
  {
    token: "CONVERSATION_STARTED_TIMESTAMP",
    label: "Conversation started timestamp",
  },
  // {
  //   token: "USER_ID",
  //   label: "Current User Id",
  // },
  // {
  //   token: "USER_FIRST_NAME",
  //   label: "Current User's First Name",
  // },
  // {
  //   token: "USER_LAST_NAME",
  //   label: "Current User's Last Name",
  // },
];

export const useChatConnectorAPIConfig = (
  conversationId: string,
  apiCallConfig: iConnectorAPICallConfig,
) => {
  const store = useStore();

  const conversation = useSelector(selectConversationById(conversationId));
  const contact = useSelector(selectContactById(conversation?.contactId));

  const apiConfig = useMemo(() => {
    if (!conversation?.id || !contact?.id) {
      // Conversation || Contact Context not loaded
      const urlConfig = GenerateUrlConfig(apiCallConfig, {});
      return {
        ...urlConfig,
        ready: false,
      };
    }

    const currentUser = selectCurrentUser(store.getState());

    const context = {
      // Contact
      CONTACT_ID: contact?.id,
      CONTACT_FIRST_NAME: contact?.data?.firstName || "",
      CONTACT_LAST_NAME: contact?.data?.lastName || "",
      CONTACT_EMAIL: contact?.data?.primaryEmail || "",
      CONTACT_PHONE: contact?.data?.primaryMobile || "",
      // Conversation
      CONVERSATION_ID: conversation?.id,
      CONVERSATION_TAGS: (conversation?.tags || []).join(","),
      CONVERSATION_STARTED_TIMESTAMP: conversation?.metaData?.createdAt,
      // Logged In User
      USER_ID: currentUser.id,
      USER_FIRST_NAME: currentUser.data.firstName,
      USER_LAST_NAME: currentUser.data.lastName,
    };

    const urlConfig = GenerateUrlConfig(apiCallConfig, context);

    return {
      ...urlConfig,
      ready: true,
    };
  }, [
    conversation?.id,
    conversation?.tags,
    conversation?.metaData?.createdAt,
    contact?.id,
    contact?.data?.firstName,
    contact?.data?.lastName,
    contact?.data?.primaryEmail,
    contact?.data?.primaryMobile,
    store,
    apiCallConfig,
  ]);

  return apiConfig;
};

export const useChatConnectorAPIData = (
  conversationId: string,
  apiCallConfig: iConnectorAPICallConfig,
  showNotifications?: boolean,
) => {
  const apiConfig = useChatConnectorAPIConfig(conversationId, apiCallConfig);

  const apiOptions = useMemo(() => {
    return {
      onSuccess: (response) => {
        message.success("Success");
      },
      onError: (response) => {
        message.error("Something Went Wrong");
      },
    };
  }, []);

  const { data, loading, hasError } = useAxios(
    apiCallConfig.method,
    apiConfig.url,
    apiConfig.body,
    apiConfig.headers,
  );

  const returnValue = useMemo(() => {
    return {
      loading,
      data,
      hasError,
    };
  }, [data, hasError, loading]);

  return returnValue;
};

export const useChatConnectorAPIAction = (
  conversationId: string,
  apiCallConfig: iConnectorAPICallConfig,
  showNotifications?: boolean,
) => {
  const apiConfig = useChatConnectorAPIConfig(conversationId, apiCallConfig);

  const apiOptions = useMemo(() => {
    return {
      onSuccess: (response) => {
        message.success("Success");
      },
      onError: (response) => {
        message.error("Something Went Wrong");
      },
    };
  }, []);

  const { data, loading, hasError, callAPI } = useAxiosAction(
    apiCallConfig.method,
    apiConfig.url,
    showNotifications ? apiOptions : {},
  );

  const returnValue = useMemo(() => {
    const _callAPI = () => {
      if (apiConfig.ready) {
        callAPI(apiConfig.body, apiConfig.headers);
      }
    };
    return {
      loading,
      data,
      hasError,
      callAPI: _callAPI,
    };
  }, [
    apiConfig.body,
    apiConfig.headers,
    apiConfig.ready,
    callAPI,
    data,
    hasError,
    loading,
  ]);

  return returnValue;
};

export const generateAPICallConfig = (
  store: Store,
  conversationId: string,
  apiCallConfig: iConnectorAPICallConfig,
) => {
  const conversation = selectConversationById(conversationId)(store.getState());
  const contact = selectContactById(conversation?.contactId)(store.getState());

  if (!conversation?.id || !contact?.id) {
    // Conversation || Contact Context not loaded
    const urlConfig = GenerateUrlConfig(apiCallConfig, {});
    return {
      ...urlConfig,
      ready: false,
    };
  }

  const currentUser = selectCurrentUser(store.getState());

  const context = {
    // Contact
    CONTACT_ID: contact?.id,
    CONTACT_FIRST_NAME: contact?.data?.firstName || "",
    CONTACT_LAST_NAME: contact?.data?.lastName || "",
    CONTACT_EMAIL: contact?.data?.primaryEmail || "",
    CONTACT_PHONE: contact?.data?.primaryMobile || "",
    // Conversation
    CONVERSATION_ID: conversation?.id,
    CONVERSATION_TAGS: (conversation?.tags || []).join(","),
    CONVERSATION_STARTED_TIMESTAMP: conversation?.metaData?.createdAt,
    // Logged In User
    USER_ID: currentUser.id,
    USER_FIRST_NAME: currentUser.data.firstName,
    USER_LAST_NAME: currentUser.data.lastName,
  };

  const urlConfig = GenerateUrlConfig(apiCallConfig, context);

  return {
    ...urlConfig,
    ready: true,
  };
};
