import { axios, AxiosResponse } from "@sdk/axios";
import {
  PaginatedOptions,
  PaginatedResults
} from "@sdk/utils/paginated-results";
import { DeepPartial } from "redux";
import { iFileRecord, iFileReference } from "./file-management.models";

export class FilesService {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  async uploadFile(file: File, reference: iFileReference, tags: string[] = []) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("reference", JSON.stringify(reference));
    formData.append("tags", JSON.stringify(tags));
    const results: AxiosResponse<iFileRecord> = await axios.post(
      this.config.basePath + "/files",
      formData,
      {
        headers: {
          Authorization: this.config.token,
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return results.data;
  }

  async uploadBase64(
    base64: string,
    fileName: string,
    reference: iFileReference
  ) {
    const results: AxiosResponse<iFileRecord> = await axios.post(
      this.config.basePath + "/files",
      {
        reference,
        base64,
        fileName
      },
      {
        headers: {
          Authorization: this.config.token
        }
      }
    );
    return results.data;
  }

  async getAllFilesByRefId(refId: string) {
    const results: AxiosResponse<iFileRecord[]> = await axios.get(
      this.config.basePath + `/files/ref/${refId}`,
      {
        headers: {
          Authorization: this.config.token
        }
      }
    );
    return results.data;
  }

  async queryFilesWithIds(fileIds: string[]) {
    const results: AxiosResponse<iFileRecord[]> = await axios.post(
      this.config.basePath + "/files/query-with-ids",
      { contactIds: fileIds },
      {
        headers: { Authorization: this.config.token }
      }
    );
    return results.data;
  }

  async queryFiles(req: { query: any; options: PaginatedOptions }) {
    const results: AxiosResponse<PaginatedResults<
      iFileRecord
    >> = await axios.post(this.config.basePath + `/files/query`, req, {
      headers: {
        Authorization: this.config.token
      }
    });
    return results.data;
  }

  async getFileRecordById(id: string) {
    const results: AxiosResponse<iFileRecord> = await axios.get(
      this.config.basePath + `/files/${id}`,
      {
        headers: {
          Authorization: this.config.token
        }
      }
    );
    return results.data;
  }

  async editFileRecordById(id: string, edits: DeepPartial<iFileRecord>) {
    const results: AxiosResponse<iFileRecord> = await axios.patch(
      this.config.basePath + `/files/${id}`,
      edits,
      {
        headers: {
          Authorization: this.config.token
        }
      }
    );
    return results.data;
  }

  async deleteFileRecordById(id: string, edits: DeepPartial<iFileRecord>) {
    const results: AxiosResponse<iFileRecord> = await axios.delete(
      this.config.basePath + `/files/${id}`,
      {
        headers: {
          Authorization: this.config.token
        }
      }
    );
    return results.data;
  }
}
