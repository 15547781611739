import { iKnowledgeDocument } from "@sdk/knowledge-documents/knowledge-documents-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { knowledgeDocumentsDefaultQueryConfig } from "./knowledge-documents-default-query";

export const {
  slice: KnowledgeDocumentsSlice,
  reducers: KnowledgeDocumentsSliceReducer,
  actions: {
    setEntityQueryResults: setKnowledgeDocumentsQueryResults,
    setEntity: setKnowledgeDocument,
    setEntities: setKnowledgeDocuments,
    patchEntity: patchKnowledgeDocument,
    addNewEntity: addNewKnowledgeDocument,
    setEntityFetchingStatus: setKnowledgeDocumentFetchingStatus,
    setEntitiesFetchingStatus: setKnowledgeDocumentsFetchingStatus,
    setEntityFailedStatus: setKnowledgeDocumentFailedStatus,
    setEntityQueryError: setKnowledgeDocumentsQueryError,
    resetQuery: resetKnowledgeDocumentsQuery,
    setQueryList: setKnowledgeDocumentQueryList
  }
} = CreateEntitySlice<iKnowledgeDocument>({
  sliceName: "knowledgeDocuments",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...knowledgeDocumentsDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
