export const emailValidatorRuleForSelect = ({ getFieldValue }) => ({
  validator(_, emails: string[]) {
    if (emails) {
      // eslint-disable-next-line no-useless-escape
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const invalidInputs = emails.filter((value) => !value.match(emailRegex));
      if (invalidInputs.length === 0) {
        return Promise.resolve();
      } else if (invalidInputs.length === 1) {
        return Promise.reject(
          new Error(invalidInputs.join("") + " is not a valid email"),
        );
      } else {
        return Promise.reject(
          new Error(
            invalidInputs.slice(0, -1).join(", ") +
              " and " +
              invalidInputs.slice(-1) +
              " are not valid emails",
          ),
        );
      }
    }
    return Promise.resolve();
  },
});
