import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddSequenceModal = React.lazy(() => import("./add-sequence.modal"));

const AddSequence: iModalProps<typeof AddSequenceModal, {}> = {
  name: "add-new-sequence",
  component: GenerateModalWithSuspense(AddSequenceModal),
};

export default AddSequence;
