import { Transition } from "@headlessui/react";
import {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { usePopper } from "react-popper";
import { Portal } from "react-portal";
import { useDebouncedCallback } from "use-debounce";
import classNames from "utils/classnames";

// Create PopoverContext
const PopoverContext = createContext({
  openPopoverId: null as any as string | undefined,
  setOpenPopoverId: (id: string) => {},
});

// PopoverGroup component to manage multiple popovers
export const PopoverGroup = ({ children }: { children }) => {
  const [openPopoverId, setOpenPopoverId] = useState(
    undefined as string | undefined,
  );

  return (
    <PopoverContext.Provider value={{ openPopoverId, setOpenPopoverId }}>
      {children}
    </PopoverContext.Provider>
  );
};

export const CCPopoverV2 = forwardRef(
  (
    {
      content,
      children,
      buttonClassName,
      popoverId,
      openState,
      className,
    }: {
      content: JSX.Element | (({ closePopover }) => JSX.Element);
      children;
      buttonClassName?: string;
      popoverId?: string;
      openState?: boolean;
      className?: string;
    },
    ref,
  ) => {
    const [referenceElement, setReferenceElement] = useState(null as any);
    const [popperElement, setPopperElement] = useState(null as any);
    const { styles, attributes, update, forceUpdate } = usePopper(
      referenceElement,
      popperElement,
      {
        placement: "right",
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              padding: -30, // set padding to 0 to allow reaching 0 from the bottom
            },
          },
          {
            name: "computeStyles",
            options: {
              adaptive: true, // true by default
            },
          },
        ],
      },
    );

    const [_isShowing, setIsShowing] = useState(false);

    const isShowing = _isShowing || Boolean(openState);

    const isHoveringRef = useRef(false);

    const { openPopoverId, setOpenPopoverId } = useContext(PopoverContext); // Accessing context

    const closePopover = useCallback(() => {
      setIsShowing(false);
    }, []);

    const hidePopover = useCallback(() => {
      if (!isHoveringRef.current) {
        closePopover();
      }
    }, [closePopover]);

    useImperativeHandle(
      ref,
      () => ({
        closePopover,
      }),
      [closePopover],
    );

    const debouncedHidePopover = useDebouncedCallback(hidePopover, 300);

    const handleMouseEnter = useCallback(() => {
      isHoveringRef.current = true;
      setIsShowing(true);
      setOpenPopoverId(popoverId!);
    }, [popoverId, setOpenPopoverId]);

    const handleMouseLeave = useCallback(() => {
      isHoveringRef.current = false;
      debouncedHidePopover();
    }, [debouncedHidePopover]);

    useEffect(() => {
      if (openPopoverId && openPopoverId !== popoverId) {
        hidePopover();
      }
    }, [hidePopover, openPopoverId, popoverId]);

    useEffect(() => {
      if (!popperElement) return;
      const resizeObserver = new ResizeObserver(() => {
        forceUpdate && forceUpdate();
      });
      resizeObserver.observe(popperElement);
      return () => resizeObserver.disconnect(); // clean up
    }, [popperElement, isShowing, forceUpdate]);

    return (
      <div className="w-full">
        <div
          ref={setReferenceElement}
          className={buttonClassName}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {children}
        </div>
        <Portal>
          <Transition
            show={isShowing}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            enter="transition duration-100 ease-out z-100"
            enterFrom="transform translate-y-10 opacity-100"
            enterTo="transform translate-y-0 opacity-100 "
            leave="transition duration-75 ease-out"
            leaveFrom="transform translate-y-0 opacity-100"
            leaveTo="transform  translate-y-10 opacity-0"
            className={"z-10 absolute"}
          >
            <div
              className={classNames(
                "absolute bg-white beautiful-shadow dark-gradient-bg dark:bg-black",
                className,
              )}
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              {typeof content === "function"
                ? content({ closePopover: hidePopover })
                : content}
            </div>
          </Transition>
        </Portal>
      </div>
    );
  },
);
