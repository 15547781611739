import { SDK } from "@sdk";
import { iNotificationRecord } from "@sdk/notification-inbox/notifications.models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { notificationsDefaultQueryConfig } from "./notifications-default-query";
import { NotificationsSlice } from "./notifications.slice";

export const loadAllNotifications = (
  store: Store<iStore>,
  forceReload?: boolean
) =>
  loadNotificationsQuery(notificationsDefaultQueryConfig, "all")(
    store,
    forceReload
  );

export const {
  loadEntityQueries: loadNotificationsQuery,
  loadEntityById: loadNotificationById,
  loadEntitiesByIds: bulkLoadNotificationsByIds
} = CreateHelpersForEntity<iStore, iNotificationRecord>({
  sliceName: "notifications",
  slice: NotificationsSlice,
  isPaginatedQueries: false,
  loadEntityQueryProvider: query => SDK.getAllNotifications(),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.getNotificationById(entityId),
  loadEntityByIdsProvider: (notificationIds: string[]) =>
    (async () => {
      const notifications = await Promise.all(
        notificationIds.map(notificationId =>
          SDK.getNotificationById(notificationId)
        )
      );
      return notifications;
    })()
});
