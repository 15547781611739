import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const NewBadgeEarnedModal = React.lazy(
  () => import("./new-badge-earned.modal"),
);

const NewBadgeEarned: iModalProps<
  typeof NewBadgeEarnedModal,
  { badgeId: string }
> = {
  name: "new-badge-earned",
  component: GenerateModalWithSuspense(NewBadgeEarnedModal),
};

export default NewBadgeEarned;
