import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { message, Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectChatConnectorById } from "store/modules/chat-connectors/chat-connectors.selectors";
import { ConfigureChatAction } from "./configure-chat-action";
import { ConfigureSideBarPanel } from "./configure-side-bar-panel";
import { useChatConnectorEntity } from "./use-chat-connector-entity";

export const ConfigureChatConnectorModal = ({
  chatConnectorId,
  visible,
  onChangeVisibility,
  onEdited
}: {
  chatConnectorId: string;
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onEdited?: () => any;
}) => {
  const chatConnector = useSelector(selectChatConnectorById(chatConnectorId));

  const configurationComponent = useRef<any>();

  useEffect(() => {
    if (configurationComponent.current) {
      configurationComponent.current.setSnapshot(chatConnector.data);
    }
  }, [chatConnector]);

  const {
    doAction: onEditChatConnector,
    isProcessing,
    response
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => values =>
        SDK.chatConnectors
          .editById(chatConnectorId, {
            data: {
              ...values
            }
          })
          .then(res => {
            onEdited && onEdited();
            onChangeVisibility(false);
          }),
      successMessage: "Chat Connector has been edited",
      failureMessage: "Something went wrong"
    }),
    [chatConnectorId, onChangeVisibility, onEdited]
  );

  const { entityName, icon } = useChatConnectorEntity(chatConnector?.type);

  return (
    (<Modal
      title={<ModalTitle icon={icon} title={`Configure ${entityName}`} />}
      open={visible}
      onOk={async () => {
        try {
          const snapshot = configurationComponent.current.getSnapshot();
          onEditChatConnector(snapshot);
        } catch (e) {
          message.error("Please check your input");
        }
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Save"
      data-click-context="Configure Chat Connector Modal"
    >
      <div>
        {chatConnector?.type === "CONTACT_SIDE_BAR" && (
          <ConfigureSideBarPanel ref={configurationComponent} />
        )}

        {chatConnector?.type === "CONVERSATION_ACTION" && (
          <ConfigureChatAction ref={configurationComponent} />
        )}
      </div>
      <DarkModeBg />
    </Modal>)
  );
};
