import { iProduct } from "@sdk/products/products-model";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectProductQueryMap,
  selectEntityMap: selectProductsMap,
  selectQueryMapAll: selectProductsMapAll,
  selectQueryAllList: selectProductsAllList,
  selectAllEntities: selectAllProducts,
  selectAllEntitiesQuery: selectAllProductsQuery,
  selectEntityById: selectProductById,
  selectEntityQuery: selectProductQuery,
  selectEntityQueryList: selectProductQueryList
} = CreateSelectorForEntities<iStore, iProduct>({
  sliceName: "products"
});

export const selectProductByConnectionId = (connectionId: string) => (
  state: iStore
) => {
  return _.find(selectAllProducts(state), {
    connectionId
  });
};
