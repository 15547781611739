import { iSDK } from "@sdk/sdk";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iConfigurableEntityTypes } from "./use-select-entity";

export const useEditEntity = (
  entityType: iConfigurableEntityTypes,
  entityId?: string,
) => {
  const res = useSDKActionWithDeps(
    () => ({
      action: ((SDK: iSDK) => (edits) => {
        if (entityType === "WORKSPACE") {
          return SDK.editCurrentOrganization(edits);
        } else if (entityType === "USER") {
          return SDK.editUser(entityId!, edits);
        } else if (entityType === "MY_PROFILE") {
          return SDK.editCurrentUser(edits);
        } else if (entityType === "CONNECTION") {
          return SDK.connections.editById(entityId!, edits);
        } else if (entityType === "WIDGET") {
          return SDK.chatWidgets.editById(entityId!, edits);
        } else if (entityType === "SELF_SERVICE_PORTAL") {
          return SDK.selfServicedPortals.editById(entityId!, edits);
        } else if (entityType === "DYNAMIC_FORM") {
          return SDK.dynamicForms.editById(entityId!, edits);
        } else if (entityType === "CONTACT") {
          return SDK.editContact(entityId!, edits);
        } else if (entityType === "MAGIC_ASSISTANT") {
          return SDK.magicAssistants.editById(entityId!, edits);
        }
        return SDK.editCurrentUser(edits);
      }) as any,
      failureMessage: "Something went wrong",
    }),
    [entityId, entityType],
  );
  return res;
};
