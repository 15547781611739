import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddContactModal = React.lazy(() => import("./add-contact.modal"));

const AddContact: iModalProps<typeof AddContactModal> = {
  name: "add-contact",
  component: GenerateModalWithSuspense(AddContactModal),
};

export default AddContact;
