import { iSession } from "@sdk/sessions/sessions.models";
import _ from "lodash";
import { QueryConfig } from "store/utils/query-config";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";
import { selectContactById } from "../contacts/contacts.selectors";

export const {
  selectQueryMap: selectSessionQueryMap,
  selectEntityMap: selectSessionsMap,
  selectQueryMapAll: selectSessionsMapAll,
  selectQueryAllList: selectSessionsAllList,
  selectAllEntities: selectAllSessions,
  selectAllEntitiesQuery: selectAllSessionsQuery,
  selectEntityById: selectSessionById,
  selectEntityQuery: selectSessionQuery,
  selectEntityQueryList: selectSessionQueryList
} = CreateSelectorForEntities<iStore, iSession>({
  sliceName: "sessions"
});

export const selectActiveSessions = (state: iStore) =>
  selectSessionQuery("activeSessions")(state)?.list || [];

export const selectActiveSessionsIds = (state: iStore) =>
  selectSessionQueryList("activeSessions")(state) || [];

export const selectSessionsWithContactIdV2 = (contactId: string) => (
  state: iStore
) =>
  _.flatten(
    (selectContactById(contactId)(state)?.metaData.connections || []).map(
      connection => connection.data.associatedSessions || []
    )
  ).map(sessionId => selectSessionById(sessionId)(state));

const generateSessionQueryIdWithContactId = _.memoize((contactId: string) => {
  const queryConfig: QueryConfig = {
    query: { contactId },
    options: {
      sortBy: ["metaData.createdAt"]
    }
  };
  return JSON.stringify(queryConfig);
});

export const selectSessionsWithContactId = (contactId: string) => (
  state: iStore
) => {
  return selectSessionQuery(generateSessionQueryIdWithContactId(contactId))(
    state
  );
};
