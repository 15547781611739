export class AudioNotificationsService {
  static playAudio(type: AvailableAudios) {
    try {
      (document.getElementById(type) as any).play().catch((e) => {
        // Ignore
      });
    } catch (e) {
      // Ignore
    }
  }
}

export enum AvailableAudios {
  INCOMING_CHAT = "CC_incomming",
  NEW_VISITOR = "CC_visitor",
  QUEUED_VISITOR = "CC_queuee",
  NEW_MESSAGE = "CC_message",
  VIDEO_RECORDING_START = "CC_notification_4",
}
