import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddEditMacroModal = React.lazy(() => import("./add-edit-macro.modal"));

const AddEditMacro: iModalProps<
  typeof AddEditMacroModal,
  { macroId?: string }
> = {
  name: "add-edit-macro",
  component: GenerateModalWithSuspense(AddEditMacroModal),
};

export default AddEditMacro;
