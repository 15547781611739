import { useEffect, useState } from "react";

export const useViewRefresher = (...dependencies: any[]) => {
  const [visible, setVisible] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setVisible(false);
    const timer = setTimeout(() => {
      setVisible(true);
    }, 75);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, counter]);

  return { visible, reRender: () => setCounter(counter + 1) };
};

export const useViewRefresherWithCustomTimer = (
  timeout: number,
  dependencies: any[]
) => {
  const [visible, setVisible] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setVisible(false);
    const timer = setTimeout(() => {
      setVisible(true);
    }, timeout);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, counter]);

  return { visible, reRender: () => setCounter(counter + 1) };
};
