import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import { iConversionWidget } from "./conversion-widgets-model";

export class ConversionWidgetsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.conversionWidgets.configure(config);
  }

  conversionWidgets = Object.assign(
    new EntityServices<iConversionWidget>(SDKConfig, "conversion-widgets"),
    {},
  );
}
