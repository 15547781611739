import { iCampaign } from "@sdk/campaigns/campaigns-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { campaignsDefaultQueryConfig } from "./campaigns-default-query";

export const {
  slice: CampaignsSlice,
  reducers: CampaignsSliceReducer,
  actions: {
    setEntityQueryResults: setCampaignsQueryResults,
    setEntity: setCampaign,
    setEntities: setCampaigns,
    patchEntity: patchCampaign,
    addNewEntity: addNewCampaign,
    setEntityFetchingStatus: setCampaignFetchingStatus,
    setEntitiesFetchingStatus: setCampaignsFetchingStatus,
    setEntityFailedStatus: setCampaignFailedStatus,
    setEntityQueryError: setCampaignsQueryError,
    resetQuery: resetCampaignsQuery,
    setQueryList: setCampaignQueryList
  }
} = CreateEntitySlice<iCampaign>({
  sliceName: "campaigns",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...campaignsDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
