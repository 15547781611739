import { iUserGroup } from "../../../@sdk/user-management/user-management.models";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";
import { selectAllUsers } from "../users/users.selectors";

export const {
  selectQueryMap: selectGroupQueryMap,
  selectEntityMap: selectGroupsMap,
  selectQueryMapAll: selectGroupsMapAll,
  selectQueryAllList: selectGroupsAllList,
  selectAllEntities: selectAllGroups,
  selectAllEntitiesQuery: selectAllGroupsQuery,
  selectEntityById: selectGroupById
} = CreateSelectorForEntities<iStore, iUserGroup>({
  sliceName: "userGroups"
});

const selectAllMembersInGroups = (groupId: string) => (state: iStore) =>
  selectAllUsers(state).filter(user => user?.userGroups.includes(groupId));
