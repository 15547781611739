import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { mdiWebhook } from "@mdi/js";
import { useSDK } from "@sdk";
import { iConnectedApp } from "@sdk/connected-apps/connected-apps.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Space, Spin, Tooltip } from "antd";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { MDIIcon } from "components/common/mdi-icon";
import dayjs from "dayjs";
import AddWebhookApp from "../add-webhook-app-model/add-webhook-app.model-registry";
import TestWebhookApp from "../test-webhook-app-model/test-webhook-app.model-registry";

export const ManageConnectedApps = () => {
  const {
    data: connectedApps,
    reload,
    isLoading,
  } = useSDK((SDK) => SDK.connectedApps.getAll(), [], false, []);

  const { triggerTempModal } = useModalPanels();

  return (
    <Spin spinning={isLoading} indicator={<LoadingIndicatorWithSpin />}>
      <div>
        <div className="flex flex-row justify-between items-center">
          <div className="font-lg font-bold my-8">Webhook Subscriptions</div>
          {connectedApps.length > 0 && (
            <Button
              type="text"
              onClick={() =>
                triggerTempModal(AddWebhookApp, {
                  onCreated: () => {
                    reload();
                  },
                })
              }
              icon={<i className="ri-add-line"></i>}
            >
              Add New Webhook Subscription
            </Button>
          )}
        </div>

        {/* Connected App List */}

        {connectedApps.map((app) => (
          <WebhookSubscriptionCard
            connectedApp={app}
            key={app.id}
            onDeleted={() => reload()}
          />
        ))}

        {connectedApps.length === 0 && (
          <div
            className="bg-white dark:bg-gray-900 rounded-lg border border-dashed border-gray-200 dark:border-gray-700  flex-col flex p-6 hover:bg-gray-100 cursor-pointer"
            style={{}}
            onClick={() =>
              triggerTempModal(AddWebhookApp, {
                onCreated: () => {
                  reload();
                },
              })
            }
          >
            <div className="flex flex-row items-center justify-center">
              <div className="icon">
                <i className="ri-add-line text-2xl"></i>
              </div>
              <div className="name text-xl font-bold ml-2">
                Create New Webhook Subscription
              </div>
            </div>
            <div className="description text-gray-600 flex-1 text-center">
              Send data to other platforms for continuity
            </div>
          </div>
        )}
      </div>
    </Spin>
  );
};

export const WebhookSubscriptionCard = ({
  connectedApp,
  onDeleted,
}: {
  connectedApp: iConnectedApp;
  onDeleted: () => any;
}) => {
  const { doAction: removeSubscription, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (connectedAppId: string) =>
        SDK.connectedApps.deleteById(connectedAppId).then((d) => {
          onDeleted();
        }),
      successMessage: "Webhook Subscription has been removed",
      failureMessage: "Something went wrong",
    }),
    [onDeleted],
  );

  const { triggerTempModal } = useModalPanels();

  return (
    <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-700  flex-row flex items-center p-4 mb-2 w-full">
      <div className="flex flex-row items-center">
        <div className="icon hidden md:block">
          {connectedApp.type === "Zapier" && (
            <img
              src="/assets/integrations/zapier-logo.png"
              style={{ width: 30 }}
              alt=""
            />
          )}

          {connectedApp.type === "Webhook" && (
            <MDIIcon icon={mdiWebhook} size={"4rem"} />
          )}
        </div>
      </div>
      <div className="flex flex-col  ml-2 flex-1">
        <div className="name text-lg font-bold">{connectedApp.label}</div>
        <div className="description text-gray-600 flex-1 truncate md:block hidden">
          URL: {connectedApp.targetUrl}
        </div>
        <div className="description text-gray-600 flex-1">
          Created on {dayjs(connectedApp.createdAt).format("DD-MM-YYYY")}
        </div>
      </div>

      <Space>
        <Tooltip
          title={
            connectedApp.type === "Zapier"
              ? "Please unsubscribe from Zapier"
              : "Remove Subscription"
          }
        >
          <Button
            type="text"
            onClick={() => {
              removeSubscription(connectedApp.id);
            }}
            icon={<i className="ri-delete-bin-line"></i>}
            loading={isProcessing}
            disabled={connectedApp.type === "Zapier"}
          >
            Remove
          </Button>
        </Tooltip>
        <Tooltip title={"Test Subscription"}>
          <Button
            type="text"
            onClick={() => {
              triggerTempModal(TestWebhookApp, {
                connectedAppId: connectedApp.id,
              });
            }}
            icon={<i className="ri-flask-line"></i>}
          >
            Test
          </Button>
        </Tooltip>
      </Space>
    </div>
  );
};
