import { notification } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";
import {
  iNotification,
  NotificationActions,
} from "./on-screen-notifications-controller";

export const fireInAppNotification = (
  data: iNotification,
  api: NotificationInstance,
) => {
  notification.open({
    message: <div className="font-semibold">{data.title}</div>,
    description: data.body,
    btn: <NotificationActions data={data} api={api} />,
    key: data.id,
    duration: data.duration,
    className: data.isUnDismissible ? "prompt-notification" : undefined,
  });
};
