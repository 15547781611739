import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { Suspense } from "react";

export const GenerateModalWithSuspense = (Modal) => {
  return (props) => (
    <Suspense
      fallback={
        <div
          className="w-full h-full absolute top-0 left-0 animate-fade-in bg-black/90 flex flex-row justify-center items-center text-white"
          style={{ zIndex: 99999999 }}
        >
          <LoadingIndicatorWithSpin fontSize={"2rem"} />
        </div>
      }
    >
      <Modal {...props} />
    </Suspense>
  );
};
