import { mdiFolderEditOutline } from "@mdi/js";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iOrganizationHelpDeskTicketType } from "@sdk/user-management/user-management.models";
import { Button, Divider, Popconfirm, Space, Table } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { MDIIcon } from "components/common/mdi-icon";
import { SectionHeader } from "components/common/section-header";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { HelpUsHelpYou } from "components/help-us-help-you/help-us-help-you";
import { useEffect, useMemo } from "react";
import { loadOrganization } from "store/modules/workspace/workspace.helpers";
import { selectTicketTypes } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";

import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import AddEditSupportCaseType from "./add-edit-case-type.modal-registry";
import { CaseTypeOptionLabels } from "./case-type-options-labels";
import { TicketTypeTemplates } from "./helpers/ticket-type-templates";

export const ManageCaseTypes = () => {
  const { state: availableTicketTypes, retry: reload } = useQueryWithStore(
    selectTicketTypes,
    loadOrganization,
  );

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { doAction: deleteTicketType, isProcessing: idDeletingTicketType } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (id: string) =>
          SDK.ticketTypes.deleteTicketType(id).then((d) => {
            reload();
          }),
        successMessage: "TicketType has been removed",
        failureMessage: "Something went wrong",
      }),
      [reload],
    );

  const { doAction: addTicketType, isProcessing: isAddingTicketType } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (data) => SDK.ticketTypes.addTicketType(data),
        successMessage: "TicketType has been added",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const { triggerTempModal } = useModalPanels();

  const columns = useMemo(
    () => [
      {
        title: "Tracker",
        dataIndex: "label",
        render: (label, ticketType: iOrganizationHelpDeskTicketType) => {
          return (
            <div className="font-bold">{`${
              ticketType.emoji || "📥"
            } ${label}`}</div>
          );
        },
      },
      {
        title: "Type",
        dataIndex: "type",
        render: (type) => {
          return `${CaseTypeOptionLabels[type]}`;
        },
      },
      {
        title: "Stages",
        dataIndex: "stages",
        render: (stages) => {
          return (
            <div className="flex-row items-center">
              <div className="boxes"></div>
              <div className="caption text-gray-600 dark:text-gray-400 mode_transition">{`${
                stages.length + 2
              } Stages`}</div>
            </div>
          );
        },
      },
      {
        title: "Properties",
        dataIndex: "properties",
        render: (properties) => {
          return (
            <div className="flex-row items-center">
              <div className="boxes"></div>
              <div className="caption text-gray-600 dark:text-gray-400 mode_transition">{`${properties.length} Properties`}</div>
            </div>
          );
        },
      },
      {
        title: "",
        dataIndex: "actions",
        width: 200,
        render: (data, record, index) => (
          <Space>
            <Button
              type="link"
              icon={<i className="ri-pencil-line"></i>}
              onClick={(e) => {
                e.stopPropagation();
                triggerTempModal(AddEditSupportCaseType, {
                  ticketTypeId: record.id,
                  onCreated: () => {
                    // Nothing
                  },
                });
              }}
            >
              Configure
            </Button>
            <Popconfirm
              placement="leftTop"
              title="Are you sure to delete?"
              onConfirm={(e) => {
                e?.stopPropagation();
                deleteTicketType(record.id);
                reload();
              }}
              onCancel={(e) => {
                e?.stopPropagation();
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="link"
                shape="circle"
                icon={<i className="ri-delete-bin-line"></i>}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              ></Button>
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [deleteTicketType, reload, triggerTempModal],
  );

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin p-8 overflow-x-hidden">
      <SectionHeader
        title="Manage Support Trackers (Beta)"
        icon={<MDIIcon icon={mdiFolderEditOutline} size="3rem" />}
        description="Create & Manage Trackers that help organize and track progress of customer support cases"
        addons={
          <Space>
            <Button
              type="primary"
              // size="large"
              icon={<i className="ri-add-line"></i>}
              onClick={() =>
                triggerTempModal(AddEditSupportCaseType, {
                  ticketTypeId: "",
                  onCreated: () => {
                    // Nothing
                  },
                })
              }
            >
              Create New Tracker
            </Button>
          </Space>
        }
      />
      <Divider />

      <Table
        columns={columns}
        dataSource={availableTicketTypes}
        pagination={false}
        rowKey="id"
        locale={{
          emptyText: (
            <EmptyData text="No support Trackers to show" icon="ri-flag-line" />
          ),
        }}
        onRow={(record) => ({
          onClick: () => {
            triggerTempModal(AddEditSupportCaseType, {
              ticketTypeId: record.id,
              onCreated: () => {
                // Nothing
              },
            });
          },
          className: "cursor-pointer",
        })}
      />

      {/*onChangeVisibility and setSelectedPipeline are used together in AddEditPipeline. Can use it as one single function */}

      <div className="mt-16 w-full"></div>

      <HelpUsHelpYou
        emoji="☝️"
        chatMessage="How can we improve customer support Trackers?"
        className="mb-16"
      />

      <SectionHeader
        title="Pre-Made Templates"
        icon={<i className="ticket-type"></i>}
        description="Get Started with pre-made templates"
      />
      <Divider />

      <div className="flex flex-row justify-start items-center flex-wrap">
        {TicketTypeTemplates.map((template) => (
          <div
            key={template.id}
            className="flex flex-col p-4 rounded-lg m-4 border border-gray-200 dark:border-gray-800"
            style={{ width: 220, height: 120 }}
          >
            <div className="font-bold">
              {template.emoji} {template.label}
            </div>
            <div className="">{CaseTypeOptionLabels[template.type]}</div>
            <div className="text-gray-600">
              {template.stages.length + 2} Stages
            </div>
            <div className="">
              <Button
                type="text"
                icon={<i className="ri-add-line"></i>}
                block
                onClick={() => {
                  const ticketTypeData = {
                    emoji: "📥",
                    ...(template as any),
                    id: String(new Date().getTime()),
                  };
                  addTicketType(ticketTypeData);
                }}
              >
                Duplicate
              </Button>
            </div>
          </div>
        ))}
      </div>
    </StyledScrollArea>
  );
};
