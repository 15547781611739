import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const SequenceEditorModal = React.lazy(() => import("./sequence-editor.modal"));

const SequenceEditor: iModalProps<
  typeof SequenceEditorModal,
  { sequenceId: string }
> = {
  name: "sequence-editor",
  component: GenerateModalWithSuspense(SequenceEditorModal),
};

export default SequenceEditor;
