import {
  GetWindowVisibility,
  OnVisibilityChange
} from "@libs/window-visibility-detector/window-visibility-detector.service";
import { Store } from "@reduxjs/toolkit";
import { SDK } from "@sdk";
import { AvailabilityStatus } from "@sdk/user-management/user-management.models";
import { last } from "lodash";

import { selectSocketStatus } from "store/modules/app-state/app-state.selectors";
import {
  selectCurrentUserAvailabilityStatus,
  selectCurrentUserId
} from "store/modules/users/users.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import {
  GetLocalStorageItem,
  SetLocalStorageItem
} from "utils/hooks/use-local-storage-store";

interface UserPresenceLog {
  id: string;
  organizationId: string;
  userId: string;
  connected: boolean;
  status: AvailabilityStatus;
  startedTime: number;
  endedTime: number;
  windowVisibilityLogs: WindowVisibilityLog[];
}

interface WindowVisibilityLog {
  visibility: boolean;
  startedTime: number;
  endedTime: number;
}

// Todo: What happens when two tabs are opened
// Merging Intervals
// https://keithwilliams-91944.medium.com/merge-intervals-solution-in-javascript-daa61b618ed4#:~:text=The%20Merge%20Intervals%20problem%20provides,element%20is%20the%20end%20value.
// Got to re-write the above to consider screen focus time too
// Or duplicates could be removed from the front end itself. By Cross checking logs added to the local storage

export class UserPresenceLoggerService {
  static STARTED = false;
  static BUFFER_TO_SEND: UserPresenceLog[] = (() => {
    const initialValue = JSON.parse(
      GetLocalStorageItem("persist:userPresence") || "[]"
    );
    if (!Array.isArray(initialValue)) {
      return [];
    }
    return initialValue;
  })();

  static PresenceUpdateInterval = 1 * 60 * 1000;

  static lastPresenceLogTime = Date.now();

  static onRestart = () => {};

  static persistChange() {
    // Persist Change
    SetLocalStorageItem(
      `persist:userPresence`,
      JSON.stringify(this.BUFFER_TO_SEND)
    );
  }

  static init(store: Store) {
    // this.onRestart();
    if (this.STARTED) {
      return;
    }

    if (this.BUFFER_TO_SEND.length > 50) {
      // Todo: should be remove few?
    }

    const removeVisibilityChangeDetection = OnVisibilityChange(() => {
      const isWindowVisible = GetWindowVisibility();
      // console.log("isWindowVisible", isWindowVisible);
      const lastUserPresenceLog = last(this.BUFFER_TO_SEND);
      if (lastUserPresenceLog) {
        const currentTime = Date.now();
        const lastVisibilityLog = last(
          lastUserPresenceLog.windowVisibilityLogs
        );
        const visibilityLog = {
          visibility: isWindowVisible,
          startedTime: Date.now(),
          endedTime: Date.now()
        };
        if (
          visibilityLog.visibility === lastVisibilityLog?.visibility &&
          // Check for inactivity in last 1 minute
          this.lastPresenceLogTime + this.PresenceUpdateInterval > currentTime
        ) {
          // Merge Time
          lastVisibilityLog.endedTime = currentTime;
        } else {
          if (lastVisibilityLog) {
            lastVisibilityLog.endedTime = currentTime;
          }
          lastUserPresenceLog.windowVisibilityLogs.push(visibilityLog);
        }
        // this.lastPresenceLogTime = currentTime;
        this.persistChange();
      }
    });

    const updatePresenceLogs = () => {
      const currentTime = Date.now();
      const currentUser = selectCurrentUserId(store.getState());

      if (!currentUser) {
        return;
      }
      const organization = selectOrganization(store.getState());

      const socketStatus = selectSocketStatus(store.getState());
      const userStatus = selectCurrentUserAvailabilityStatus(store.getState());

      const isWindowVisible = GetWindowVisibility();
      const logToSend: UserPresenceLog = {
        id: Date.now().toString(),
        organizationId: organization?.id!,
        userId: currentUser,
        connected: socketStatus,
        status: userStatus,
        startedTime: Date.now(),
        endedTime: Date.now(),
        windowVisibilityLogs: [
          {
            visibility: isWindowVisible,
            startedTime: Date.now(),
            endedTime: Date.now()
          }
        ]
      };

      const lastUserPresenceLog = last(this.BUFFER_TO_SEND);

      if (
        lastUserPresenceLog?.organizationId === logToSend.organizationId &&
        lastUserPresenceLog?.userId === logToSend.userId &&
        lastUserPresenceLog?.connected === logToSend.connected &&
        lastUserPresenceLog?.status === logToSend.status &&
        // Check for inactivity in last 1 minute
        this.lastPresenceLogTime + this.PresenceUpdateInterval > currentTime
      ) {
        // Merge Time
        lastUserPresenceLog.endedTime = currentTime;
        const lastWindowVisibilityLog = last(
          lastUserPresenceLog.windowVisibilityLogs
        );
        if (lastWindowVisibilityLog) {
          lastWindowVisibilityLog.endedTime = currentTime;
        }
      } else {
        this.BUFFER_TO_SEND.push(logToSend);
      }
      this.persistChange();
      // console.log("User Presence Logs", this.BUFFER_TO_SEND);
    };

    const presenceLoggerTimerTimer = setInterval(() => {
      updatePresenceLogs();
    }, this.PresenceUpdateInterval);

    const syncBuffer = () => {
      // console.log("Sync User Presence Logs");
      const logsToSend = [...this.BUFFER_TO_SEND];
      // This makes sure that only the active logs are updated with end time; When coming from sleep, lastPresenceLogTime be have passed the threshold
      if (this.lastPresenceLogTime + 1 * 60 * 1000 > Date.now()) {
        const lastLog = last(logsToSend);
        if (lastLog) {
          lastLog.endedTime = Date.now();
          const lastWindowVisibilityLog = last(lastLog.windowVisibilityLogs);
          if (lastWindowVisibilityLog) {
            lastWindowVisibilityLog.endedTime = Date.now();
          }
        }
      }

      this.BUFFER_TO_SEND = [];
      const organization = selectOrganization(store.getState());
      // Todo:
      SDK.addUserPresenceLogs(
        logsToSend.map(item => ({
          ...item,
          id: generateUserPresenceLogId(organization?.id!)
        }))
      )
        .then(e => {
          // Successfully sent, ignore
          this.persistChange();
        })
        .catch(e => {
          console.log("Error while sending logs to backend", e);
          // Readd the logs to buffer
          this.BUFFER_TO_SEND = [...logsToSend, ...this.BUFFER_TO_SEND];
        });
    };

    const bufferCleanerTimer = setInterval(() => {
      syncBuffer();
    }, this.PresenceUpdateInterval * 5);

    const firstSyncTimer = setTimeout(() => {
      if (this.BUFFER_TO_SEND.length > 0) {
        syncBuffer();
      }
      setTimeout(() => {
        updatePresenceLogs();
      }, 2000);
    }, 15 * 1000);

    this.onRestart = () => {
      this.onRestart = () => {
        removeVisibilityChangeDetection();
        clearTimeout(firstSyncTimer);
        clearInterval(bufferCleanerTimer);
        clearInterval(presenceLoggerTimerTimer);
      };
    };
    this.STARTED = true;
  }

  static destroy(store: Store) {
    console.log("Destroy Buffer");
    if (this.STARTED) {
      this.onRestart();
      this.BUFFER_TO_SEND = [];
    }
    this.STARTED = false;
  }
}

export function generateUserPresenceLogId(organizationId: string): string {
  const id = "xxxxx-xxxxx".replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
  return `${organizationId}-${id}`;
}
