import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Tag, Tooltip } from "antd";
import classnames from "classnames";
import { AvatarGroup } from "components/common/avatar/avatar";
import { UserAvatar } from "components/common/avatar/user-avatar";
import { useSelectedUniboxEntity } from "components/pages/conversations/use-unibox-view";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useStore } from "react-redux";
import { selectUser } from "store/modules/users/users.selectors";
import { AddEllipsis } from "utils/add-ellipsis";
import { iTask } from "../../../../@sdk/tasks/task-model";
import { PriorityTag } from "./PriorityTag";
import "./task-list-item.scss";

export const TaskListItem = ({
  task,
  onTaskSelected,
  isLastItem,
}: {
  task: iTask;
  onTaskSelected?: (taskId: string) => any;
  isLastItem?: boolean;
}) => {
  const store = useStore();

  const { doAction: markAsDone, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (status: boolean) =>
        SDK.tasks.markAsDone(task?.id, status),
      successMessage: "Done",
      failureMessage: "Something went wrong",
      actionDependencies: [task?.id],
    }),
    [task?.id],
  );

  const { entityId } = useSelectedUniboxEntity();

  const { showDueDate, showPriority, labelMaxLength } = useMemo(() => {
    const showDueDate = task.dueDate
      ? dayjs(task.dueDate).subtract(3, "days").isBefore(dayjs())
      : false;

    const showPriority = task.priority > 70;

    const labelMaxLength = (() => {
      if (showDueDate && showPriority) {
        return 20;
      }
      if (showDueDate || showPriority) {
        return 30;
      }
      return 35;
    })();
    return { showDueDate, showPriority, labelMaxLength };
  }, [task.dueDate, task.priority]);

  return (
    <div
      style={{ maxWidth: 700, minWidth: "18rem" }}
      onClick={() => onTaskSelected && onTaskSelected(task.id)}
      className={classnames(
        "task-list-item cursor-pointer w-full  p-4 flex flex-row hover:bg-gray-100 dark:hover:bg-gray-900 mode_transition",
        {
          "bg-gray-100 dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800":
            entityId === task.id,
          "border-b border-gray-200 dark:border-gray-700": !isLastItem,
        },
      )}
    >
      <div className="icon-container">
        {!task.isResolved ? (
          <Tooltip title="Mark as done">
            <div
              className="check-box cursor-pointer text-2xl"
              onClick={(e) => {
                markAsDone(true);
                e.stopPropagation();
              }}
            >
              <i className="ri-checkbox-blank-circle-line icon"></i>
              <i className="ri-checkbox-circle-fill on-hover-icon"></i>
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="Reopen Task">
            <div
              className="check-box cursor-pointer text-2xl"
              onClick={(e) => {
                markAsDone(false);
                e.stopPropagation();
              }}
            >
              <i className="ri-arrow-up-circle-line icon"></i>
              <i className="ri-arrow-up-circle-fill  on-hover-icon"></i>
            </div>
          </Tooltip>
        )}
      </div>
      <div className="card-body flex flex-col pl-2 flex-1">
        <div className="first-row flex flex-row w-full">
          <div className="description flex-1 flex flex-row items-center">
            <div className="label flex-1 font-semibold pr-2">
              {AddEllipsis(task.label, labelMaxLength)}
            </div>
            <div className="tags">
              {showDueDate && (
                <Tag
                  color={"red"}
                  className={classnames("rounded-md font-semibold", {
                    "m-0": !showPriority,
                  })}
                >
                  {dayjs(task.dueDate).format("Do MMM")}{" "}
                </Tag>
              )}

              {showPriority && <PriorityTag priority={task.priority} />}
            </div>
          </div>
        </div>
        <div className="status-data text-gray-600 flex flex-row justify-between text-xs mt-2">
          <div className="item">
            Created By:{" "}
            {selectUser(task.createdBy)(store.getState())?.data?.firstName}
          </div>

          <div className="item flex flex-row">
            <div className="created-date mr-2">
              {dayjs(task.createdAt).format("Do MMM")}
            </div>
            <div className="assigned-users">
              <AvatarGroup>
                {task.assignedTo.map((member) => (
                  <div className="avatar-container" key={member.userId}>
                    <UserAvatar
                      userId={member.userId}
                      key={member.userId}
                      enableToolTip={true}
                      size={20}
                    />
                  </div>
                ))}
              </AvatarGroup>
            </div>
          </div>
        </div>
        {/* <div className="mt-2 flex flex-row justify-between border-gray-100 border">
          <Space>
            {task.conversationId && <i className="ri-question-answer-line"></i>}
            {task.messageId && <i className="ri-chat-4-fill"></i>}
            {task.contactId && <i className="ri-user-star-line"></i>}
            {task.tags.map((item) => (
              <Tag key={item}>{item}</Tag>
            ))}
          </Space>
        </div> */}
      </div>
    </div>
  );
};
