import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddLiveChatWidgetModal = React.lazy(
  () => import("./add-live-chat-widget.modal"),
);

const AddLiveChatWidget: iModalProps<
  typeof AddLiveChatWidgetModal,
  {
    label?: string;
    connectionId?: string;
  }
> = {
  name: "add-live-chat-widget",
  component: GenerateModalWithSuspense(AddLiveChatWidgetModal),
};

export default AddLiveChatWidget;
