import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const BadgeViewerModal = React.lazy(() => import("./badge-viewer.modal"));

const BadgeViewer: iModalProps<typeof BadgeViewerModal, { badgeId: string }> = {
  name: "view-badge",
  component: GenerateModalWithSuspense(BadgeViewerModal),
};

export default BadgeViewer;
