import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iScreenRecording } from "@sdk/screen-recordings/screen-recordings-model";
import React from "react";

const ScreenRecordingPickerModal = React.lazy(
  () => import("./screen-recording-picker.modal"),
);

const ScreenRecordingPicker: iModalProps<
  typeof ScreenRecordingPickerModal,
  {
    onSelected: (screenRecording: iScreenRecording) => any;
  }
> = {
  name: "screen-recording-picker",
  component: GenerateModalWithSuspense(ScreenRecordingPickerModal),
};

export default ScreenRecordingPicker;
