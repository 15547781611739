import { SDK } from "@sdk";
import { combineEpics, ofType } from "redux-observable";
import { mergeMap, switchMap } from "rxjs/operators";
import {
  setUserPresence,
  setUserPresences
} from "../../modules/user-presence/user-presence.actions";

const userPresenceLoadedEpic1 = (action$, state$) => {
  return action$.pipe(
    ofType("users/addNewUser"),
    // subscribe to actions observable
    switchMap(({ payload: { id: userId } }: any) =>
      SDK.getRoomStatus(userId)
        .then(statusMap => [
          setUserPresence({
            userId,
            isOnline: statusMap[userId] || false
          })
        ])
        .catch(() => [])
    ),
    mergeMap((actions: any) => actions)
  );
};

const userPresenceLoadedEpic2 = (action$, state$) => {
  return action$.pipe(
    ofType("users/setAllUsers"),
    // subscribe to actions observable
    switchMap(({ payload: users }) =>
      SDK.queryStatus(users.map(user => user.id))
        .then(statusMap => [
          setUserPresences(
            users
              .map(user => user.id)
              .map(userId => ({
                userId,
                isOnline: statusMap[userId] || false
              }))
          )
        ])
        .catch(() => [])
    ),
    // tap(() => console.log("Set All Users Fired")),
    mergeMap((actions: any) => actions)
  );
};

export const userPresenceLoader = combineEpics(
  userPresenceLoadedEpic1,
  userPresenceLoadedEpic2
);
