import { ProtobiQueryUnDot } from "@libs/protobi-query-patches";
import { buildChatViewQueryConfigs } from "components/modules/conversations/helpers/build-chat-view-queries";
import Query from "query";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { selectFbPostById } from "store/modules/fb-posts/fb-posts.selectors";
import { selectIgMediaById } from "store/modules/ig-medias/ig-medias.selectors";
import { selectTaskById } from "store/modules/tasks/tasks.selectors";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { QueryConfig } from "store/utils/query-config";

import { deriveEntityTypeFromEntityId } from "../derive-entity-type-from-entity-id";
import { iInboxItem } from "./inbox-models";
import { useInboxes } from "./use-inboxes";

export const useMatchedInbox = (entityId: string) => {
  const InboxMenuItems = useInboxes();
  const entityType = useMemo(
    () => (entityId ? deriveEntityTypeFromEntityId(entityId) : "CHAT"),
    [entityId],
  );
  const selector = useMemo(() => {
    if (entityType === "FB_POSTS") {
      return selectFbPostById;
    }
    if (entityType === "IG_MEDIAS") {
      return selectIgMediaById;
    }
    if (entityType === "TASKS") {
      return selectTaskById;
    }
    return selectConversationById;
  }, [entityType]);

  const currentUserId = useSelector(selectCurrentUserId);

  const matchedInbox = useMemo(() => {
    const entity = selector(entityId);
    if (entity) {
      const inboxItems = InboxMenuItems.filter(
        (item) => item.menuItemType === "ITEM" && item.type === entityType,
      ) as iInboxItem[];

      const views = buildChatViewQueryConfigs(currentUserId);
      for (const inbox of inboxItems) {
        const queryConfig = (inbox.queryConfig as any).alias
          ? views[(inbox.queryConfig as any).alias]
          : (inbox.queryConfig as QueryConfig);
        try {
          const matches = Query.query(
            [entity],
            queryConfig.query,
            ProtobiQueryUnDot,
          );
          if (matches?.length > 0) {
            return inbox;
          }
        } catch (e) {
          // Todo: $exp $gt is not supported
          console.log(
            "Error while Processing Protobi Query",
            e,
            [entity],
            queryConfig.query,
          );
        }
      }
    }
  }, [InboxMenuItems, currentUserId, entityId, entityType, selector]);

  return matchedInbox;
};
