import { AppFeatureRequestsServices } from "./app-feature-requests/app-feature-requests.service";
import { AppService } from "./app-service/app.service";
import { AppSumoServices } from "./app-sumo-service/app-sumo.service";
import { AutomationRulesManagementServices } from "./automation-rules/automation-rules.service";
import { AutomationWorkflowsServices } from "./automation-workflows/automation-workflows.service";
import { BadgesServices } from "./badges/badges.service";
import { CallBackRequestsServices } from "./call-back-requests/call-back-requests.service";
import { CallLogsServices } from "./call-logs/call-logs.service";
import { CampaignsServices } from "./campaigns/campaigns.service";
import { CCKnowledgeBaseService } from "./cc-knowledge-base/cc-knowledge-base.service";
import { ChaBotServices } from "./chat-bots/chat-bots.service";
import { ChaConnectorsServices } from "./chat-connectors/chat-connectors.service";
import { ChatWidgetServices } from "./chat-widgets/chat-widgets.service";
import { CheckListsServices } from "./checklists/checklists.service";
import { ConnectedAppsServices } from "./connected-apps/connected-apps.service";
import { ConversationService } from "./conversations/conversations.service";
import { ConversionWidgetsServices } from "./conversion-widgets/conversion-widgets.service";
import { CRMService } from "./crm/crm.service";
import { DangerousDeletionServices } from "./dangerous-deletion/dangerous-deletion.service";
import { DynamicFormsServices } from "./dynamic-forms/dynamic-forms.service";
import { ExternalAccountsServices } from "./external-accounts/external-accounts.service";
import { FbPostsService } from "./facebook-posts/facebook-posts.service";
import { FilesService } from "./file-management/file-management.service";
import { HelpDeskService } from "./help-desk/help-desk.service";
import { HolidayQuotesServices } from "./holiday-quotes/holiday-quotes.service";
import { HQService } from "./hq/hq.service";
import { IgMediasService } from "./ig-media/ig-media.service";
import { IndexedWebPagesServices } from "./indexed-web-pages/indexed-web-pages.service";
import { KnowledgeBaseService } from "./knowledge-base/knowledge-base.service";
import { KnowledgeDocumentsServices } from "./knowledge-documents/knowledge-documents.service";
import { MacroManagementServices } from "./macros/macros.service";
import { MagicAssistantServices } from "./magic-assistants/magic-assistants.service";
import { MailchimpServices } from "./mailchimp/mailchimp.service";
import { MarketingReferencesServices } from "./marketing-references/marketing-references.service";
import { NewsfeedItemsServices } from "./newsfeed-items/newsfeed-items.service";
import { NewsfeedTypesServices } from "./newsfeed-items/newsfeed-types.service";
import { NotificationInboxServices } from "./notification-inbox/notification-inbox.service";
import { NPSSubmissionsServices } from "./nps-submissions/nps-submissions.service";
import { PipelineCardsServices } from "./pipeline-cards/pipeline-cards.service";
import { PipelineManagementServices } from "./pipelines/pipelines.service";
import { ProactiveCampaignCampaignsServices } from "./proactive-campaigns/proactive-campaigns.service";
import { ProductsServices } from "./products/products.service";
import { RemindersServices } from "./reminders/reminders.service";
import { ReportsServices } from "./reports/reports.service";
import { RoutingLogsService } from "./routing-logs/routing-logs.service";
import { ScheduledMessagesService } from "./scheduled-messages/scheduled-messages.service";
import { ScheduledReportsService } from "./scheduled-reports/scheduled-reports.service";
import { ScreenRecordingsServices } from "./screen-recordings/screen-recordings.service";
import { ScreenToursServices } from "./screen-tours/screen-tours.service";
import { SendingDomainsServices } from "./sending-domains/sending-domains.service";
import { SessionsServices } from "./sessions/sessions.service";
import { ShopifyServices } from "./shopify/shopify.service";
import { SLALogsServices } from "./sla-logs/sla-logs.service";
import { SLARulesServices } from "./sla-rules/sla-rules.service";
import { SLATemplatesManagementServices } from "./sla-templates/sla-templates.service";
import { SpamEmailsServices } from "./spam-emails/spam-email.service";
import { SyncServerServices } from "./sync-server/sync-server.service";
import { TasksServices } from "./tasks/tasks.service";
import { TelnyxServices } from "./telnyx/telnyx.service";
import { TestimonialsServices } from "./testimonials/testimonials.service";
import { TicketTypesServices } from "./ticket-types/ticket-types.service";
import { TicketsServices } from "./tickets/tickets.service";
import { TwitterServices } from "./twitter/twitter.service";
import { BillingServices } from "./user-management/billing.service";
import { TransactionsServices } from "./user-management/transactions.service";
import { AccountsAndUserManagementServices } from "./user-management/user-management.service";
import { UserPresenceLogsServices } from "./user-presence-logs/user-presence-logs.service";
import { aggregateClasses } from "./utils/mixin";
import { WhatsappOfficialServices } from "./whatsapp-official/whatsapp-official.service";
import { WhatsappPrivateServices } from "./whatsapp-private/whatsapp-private.service";

export class MainService extends aggregateClasses(
  AccountsAndUserManagementServices,
  BillingServices,
  TransactionsServices,
  PipelineManagementServices,
  CRMService,
  AppService,
  HelpDeskService,
  ConversationService,
  FbPostsService,
  ChatWidgetServices,
  FilesService,
  SyncServerServices,
  NotificationInboxServices,
  SessionsServices,
  ReportsServices,
  ScheduledReportsService,
  KnowledgeBaseService,
  MarketingReferencesServices,
  WhatsappOfficialServices,
  WhatsappPrivateServices,
  IgMediasService,
  ScheduledMessagesService,
  RoutingLogsService,
  UserPresenceLogsServices,
  HQService,
  CCKnowledgeBaseService,
  ChaBotServices,
  ChaConnectorsServices,
  TasksServices,
  SpamEmailsServices,
  ProductsServices,
  TwitterServices,
  TelnyxServices,
  CampaignsServices,
  DynamicFormsServices,
  MailchimpServices,
  ConversionWidgetsServices,
  ShopifyServices,
  CallLogsServices,
  CallBackRequestsServices,
  AutomationWorkflowsServices,
  PipelineCardsServices,
  RemindersServices,
  MacroManagementServices,
  BadgesServices,
  DangerousDeletionServices,
  HolidayQuotesServices,
  KnowledgeDocumentsServices,
  MagicAssistantServices,
  IndexedWebPagesServices,
  AppSumoServices,
  AppFeatureRequestsServices,
  ConnectedAppsServices,
  TicketTypesServices,
  TicketsServices,
  AutomationRulesManagementServices,
  ExternalAccountsServices,
  ScreenToursServices,
  ScreenRecordingsServices,
  SLATemplatesManagementServices,
  SLALogsServices,
  SLARulesServices,
  ProactiveCampaignCampaignsServices,
  CheckListsServices,
  NewsfeedItemsServices,
  NewsfeedTypesServices,
  SendingDomainsServices,
  NPSSubmissionsServices,
  TestimonialsServices,
) {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    super(config);
  }
}
