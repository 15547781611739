import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iAction } from "@sdk/chat-bots/action-models";
import { iMessageAttachment } from "@sdk/conversations/conversations.models";
import React from "react";

const MessageSchedulerModal = React.lazy(
  () => import("./message-scheduler.modal"),
);

const MessageScheduler: iModalProps<
  typeof MessageSchedulerModal,
  {
    data: {
      contactId: string;
      connectionId: string;
      conversationId: string;
      // Message Props
      subject?: string;
      message: string;
      attachments: iMessageAttachment[] | string[];
      cc?: string[];
      bcc?: string[];
      to?: string[];
      actions?: iAction[];
    };
    onScheduled?: () => any;
  }
> = {
  name: "schedule-message",
  component: GenerateModalWithSuspense(MessageSchedulerModal),
};

export default MessageScheduler;
