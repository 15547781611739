import { Select } from "antd";
import { loadAllScreenTours } from "store/modules/screen-tours/screen-tours.helpers";
import { selectAllScreenTours } from "store/modules/screen-tours/screen-tours.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const ProductTourSelector = (props) => {
  const { state: tours, retry: reloadTours } = useQueryWithStore(
    selectAllScreenTours,
    loadAllScreenTours(),
    [],
  );

  return (
    <Select placeholder="Select Product Tour" {...props}>
      {tours.map((tour) => (
        <Select.Option key={tour.id} value={tour.id!}>
          {tour.name}
        </Select.Option>
      ))}
    </Select>
  );
};
