import _ from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { loadAllConnections } from "store/modules/connections/connections.helpers";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { usePrevious } from "utils/hooks/use-previous";
import { useUrlState } from "utils/hooks/use-url-state";

import { deriveEntityTypeFromEntityId } from "./derive-entity-type-from-entity-id";
import { InboxType } from "./unibox-inboxes/inbox-models";
import { useInboxes } from "./unibox-inboxes/use-inboxes";
import { useMatchedInbox } from "./unibox-inboxes/use-matched-inbox";

export const useSelectedUniboxEntity = () => {
  const location = useLocation();
  const { inboxId, entityId } = useMemo(() => {
    const parts = location.pathname.split("/").slice(2); // omit empty string and "conversations"
    const [inboxId, entityId] = parts;
    return { inboxId: inboxId || "auto", entityId };
  }, [location.pathname]);
  return { entityId, inboxId };
};

export const useUniboxView = () => {
  const location = useLocation();
  const history = useHistory();

  const isConversationPath = useMemo(() => {
    const topLevel = `${location.pathname.split("/")[1]}`;
    return topLevel === "conversations";
  }, [location.pathname]);

  const [chatSidePanelView, setChatSidePanelView] =
    useUrlState("side-panel-view");
  const {
    state: connections,
    retry: reload,
    isLoading,
  } = useQueryWithStore(selectAllConnections, loadAllConnections, []);

  const InboxMenuItems = useInboxes();

  const { inboxId, entityId } = useMemo(() => {
    const parts = location.pathname.split("/").slice(2); // omit empty string and "conversations"
    const [inboxId, entityId] = parts;
    return { inboxId: inboxId || "auto", entityId };
  }, [location.pathname]);

  const autoMatchedInbox = useMatchedInbox(entityId);

  const inboxType: InboxType = useMemo(() => {
    if (inboxId) {
      if (inboxId === "auto" && entityId) {
        if (autoMatchedInbox?.type) {
          return autoMatchedInbox?.type;
        }
      }
      // derive from inboxID

      const matchedInbox = _.find(
        _.flatten(
          InboxMenuItems.filter(
            (item) =>
              item.menuItemType === "GROUP" || item.menuItemType === "ITEM",
          ).map((e) => (e.menuItemType === "ITEM" ? e : (e as any).children)),
        ),
        { id: inboxId },
      );

      if (matchedInbox) {
        return matchedInbox.type;
      } else {
        // Wrong Inbox ID
      }
    }
    if (entityId) {
      // derive from entity ID
      return deriveEntityTypeFromEntityId(entityId);
    }
    return "CHAT";
  }, [InboxMenuItems, autoMatchedInbox?.type, entityId, inboxId]);

  const lastInboxType = usePrevious(inboxType);

  const dispatch = useDispatch();

  const setEntityId = useCallback(
    (newEntityId?: string | null, newInboxId?: string, skipNav?: boolean) => {
      // console.log("setEntityId", newEntityId, newInboxId);
      const newLocationState = {
        search: location.search,
        pathname: `/conversations/${newInboxId || inboxId || "auto"}/${
          newEntityId === null ? "" : newEntityId || entityId || ""
        }`,
      };
      if (skipNav) {
        history.replace(newLocationState);
      } else {
        history.push(newLocationState);
      }
    },
    [entityId, history, inboxId, location.search],
  );

  useEffect(() => {
    // Wait till Inboxes are loaded
    // *  If no connections are there, then then InboxID will be auto always
    if (isConversationPath && connections.length > 0) {
      const entityId =
        lastInboxType && lastInboxType !== "auto" && lastInboxType !== inboxType
          ? null
          : undefined;

      // console.log("entityId", entityId);
      if (inboxId === "auto" && autoMatchedInbox) {
        setEntityId(entityId, autoMatchedInbox.id, true);
      } else if (inboxId === "auto") {
        setEntityId(entityId, "DEFAULT_MY", true);
      } else if (entityId === null) {
        setEntityId(entityId, undefined, true);
      }
    }
  }, [
    autoMatchedInbox,
    inboxId,
    setEntityId,
    inboxType,
    lastInboxType,
    connections.length,
    isConversationPath,
  ]);

  // Handle Wrong Inbox IDs
  useEffect(() => {
    // Wait till Inboxes are loaded
    if (isConversationPath && connections.length > 0) {
      if (inboxId !== "auto") {
        const matchedInbox = _.find(
          _.flatten(
            InboxMenuItems.filter(
              (item) =>
                item.menuItemType === "GROUP" || item.menuItemType === "ITEM",
            ).map((e) => (e.menuItemType === "ITEM" ? e : (e as any).children)),
          ),
          { id: inboxId },
        );
        if (!matchedInbox) {
          setEntityId(undefined, "auto", true);
        }
      }
    }
  }, [
    InboxMenuItems,
    connections.length,
    inboxId,
    isConversationPath,
    setEntityId,
  ]);

  const returnValue = useMemo(
    () => ({
      inboxId,
      inboxType,
      entityId,
      chatSidePanelView,
      setEntityId,
      setChatSidePanelView,
    }),
    [
      chatSidePanelView,
      entityId,
      inboxId,
      inboxType,
      setChatSidePanelView,
      setEntityId,
    ],
  );

  return returnValue;
};
