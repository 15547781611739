import { iModalProps } from "@libs/modal-panels/modal-registry-model";

import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import React from "react";

const SimpleConfirmModal = React.lazy(() => import("./simple-confirm.modal"));

const SimpleConfirm: iModalProps<
  typeof SimpleConfirmModal,
  {
    config: {
      title: string;
      icon: JSX.Element;
      content: JSX.Element;
      onConfirmed: () => any;
      okText?: string;
      okButtonProps?: any;
    };
  }
> = {
  name: "simple-confirm",
  component: GenerateModalWithSuspense(SimpleConfirmModal),
};

export default SimpleConfirm;
