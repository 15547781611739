import { SDK } from "@sdk";
import { iSelfServicePortal } from "@sdk/knowledge-base/knowledge-base.models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { selfServicePortalsDefaultQueryConfig } from "./self-service-portals-default-query";
import { SelfServicePortalsSlice } from "./self-service-portals.slice";

export const loadAllSelfServicePortals = (
  store: Store<iStore>,
  forceReload?: boolean
) =>
  loadSelfServicePortalsQuery(selfServicePortalsDefaultQueryConfig, "all")(
    store,
    forceReload
  );

export const {
  loadEntityQueries: loadSelfServicePortalsQuery,
  loadEntityById: loadSelfServicePortalById,
  loadEntitiesByIds: bulkLoadSelfServicePortalsByIds
} = CreateHelpersForEntity<iStore, iSelfServicePortal>({
  sliceName: "selfServicePortals",
  slice: SelfServicePortalsSlice,
  isPaginatedQueries: true,
  loadEntityQueryProvider: query => SDK.selfServicedPortals.query(query),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.selfServicedPortals.getById(entityId),
  loadEntityByIdsProvider: (selfServicePortalIds: string[]) =>
    (async () => {
      const selfServicePortals = await Promise.all(
        selfServicePortalIds.map(selfServicePortalId =>
          SDK.selfServicedPortals.getById(selfServicePortalId)
        )
      );
      return selfServicePortals;
    })()
});
