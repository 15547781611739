import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddProductModal = React.lazy(() => import("./add-product.modal"));

const AddProduct: iModalProps<typeof AddProductModal> = {
  name: "add-product",
  component: GenerateModalWithSuspense(AddProductModal),
};

export default AddProduct;
