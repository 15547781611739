import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const TicketViewModal = React.lazy(() => import("./ticket-view.modal"));

const TicketView: iModalProps<
  typeof TicketViewModal,
  {
    ticketId: string;
  }
> = {
  name: "view-ticket",
  component: GenerateModalWithSuspense(TicketViewModal),
};

export default TicketView;
