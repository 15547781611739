import { iMagicAssistant } from "@sdk/magic-assistants/magic-assistants-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { magicAssistantsDefaultQueryConfig } from "./magic-assistants-default-query";

export const {
  slice: MagicAssistantsSlice,
  reducers: MagicAssistantsSliceReducer,
  actions: {
    setEntityQueryResults: setMagicAssistantsQueryResults,
    setEntity: setMagicAssistant,
    setEntities: setMagicAssistants,
    patchEntity: patchMagicAssistant,
    addNewEntity: addNewMagicAssistant,
    setEntityFetchingStatus: setMagicAssistantFetchingStatus,
    setEntitiesFetchingStatus: setMagicAssistantsFetchingStatus,
    setEntityFailedStatus: setMagicAssistantFailedStatus,
    setEntityQueryError: setMagicAssistantsQueryError,
    resetQuery: resetMagicAssistantsQuery,
    setQueryList: setMagicAssistantQueryList,
  },
} = CreateEntitySlice<iMagicAssistant>({
  sliceName: "magicAssistants",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...magicAssistantsDefaultQueryConfig,
        alias: "all",
      };
    }
  },
});
