import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Form, Input, message, Modal, Select } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { useMemo } from "react";

export const AddProductModal = ({
  visible,
  onChangeVisibility,
  onCreated
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  onCreated: () => any;
}) => {
  const [form] = Form.useForm();

  const {
    doAction: onCreateProduct,
    isProcessing,
    response
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => values =>
        SDK.products
          .create({
            ...values
          })
          .then(res => {
            onCreated();
            onChangeVisibility(false);
            form.resetFields();
          }),
      successMessage: "Product has been created",
      failureMessage: "Something went wrong"
    }),
    [form, onChangeVisibility, onCreated]
  );

  const tags: any[] = useMemo(() => [], []);

  return (
    (<Modal
      title={
        <ModalTitle
          icon={<i className="ri-store-line"></i>}
          title="Add New Product"
        />
      }
      open={visible}
      onOk={async () => {
        try {
          await form.validateFields();
          onCreateProduct(form.getFieldsValue());
        } catch (e) {
          message.error("Please check your input");
        }
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okButtonProps={{ icon: <i className="ri-check-line"></i> }}
      okText="Save"
      data-click-context="Add New Product"
    >
      <Form form={form} layout="vertical" initialValues={{}}>
        <Form.Item
          name="label"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please enter a campaign name"
            }
          ]}
        >
          <Input placeholder="Label" />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[]}>
          <Input.TextArea placeholder="Description" />
        </Form.Item>

        <Form.Item name="body" label="Body" rules={[]}>
          <Input.TextArea placeholder="Body" />
        </Form.Item>

        <Form.Item label="Tags" name={["data", "tags"]}>
          <Select
            mode="tags"
            onChange={selectedTags => {
              const difference = _.difference(
                selectedTags as any,
                tags.map(item => item.tag)
              );
              if (difference.length > 0) {
                // Todo:
                // SDK.addContactTag(difference[0]).catch((e) => {
                //   console.log("Error while saving tags", e);
                // });
              }
            }}
            suffixIcon={<i className="ri-price-tag-3-line"></i>}
          >
            {tags.map(tag => (
              <Select.Option key={tag.tag} value={tag.tag}>
                {tag.tag}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Category" name={["data", "tags"]}>
          <Select
            mode="tags"
            onChange={selectedTags => {
              const difference = _.difference(
                selectedTags as any,
                tags.map(item => item.tag)
              );
              if (difference.length > 0) {
                // Todo:
                // SDK.addContactTag(difference[0]).catch((e) => {
                //   console.log("Error while saving tags", e);
                // });
              }
            }}
            suffixIcon={<i className="ri-price-tag-3-line"></i>}
          >
            {tags.map(tag => (
              <Select.Option key={tag.tag} value={tag.tag}>
                {tag.tag}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <DarkModeBg />
    </Modal>)
  );
};
