import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AsanaAddTaskModal = React.lazy(() => import("./asana-add-task.modal"));

const AsanaAddTask: iModalProps<
  typeof AsanaAddTaskModal,
  {
    onAdded?: (pageId: string) => any;
    contactId?: string;
    conversationId?: string;
  }
> = {
  name: "asana-add-task",
  component: GenerateModalWithSuspense(AsanaAddTaskModal),
};

export default AsanaAddTask;
