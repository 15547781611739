import { iCheckListInstance } from "@sdk/checklists/checklists-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { checkListInstancesDefaultQueryConfig } from "./checklist-instances-default-query";

export const {
  slice: CheckListInstancesSlice,
  reducers: CheckListInstancesSliceReducer,
  actions: {
    setEntityQueryResults: setCheckListInstancesQueryResults,
    setEntity: setCheckListInstance,
    setEntities: setCheckListInstances,
    patchEntity: patchCheckListInstance,
    addNewEntity: addNewCheckListInstance,
    setEntityFetchingStatus: setCheckListInstanceFetchingStatus,
    setEntitiesFetchingStatus: setCheckListInstancesFetchingStatus,
    setEntityFailedStatus: setCheckListInstanceFailedStatus,
    setEntityQueryError: setCheckListInstancesQueryError,
    resetQuery: resetCheckListInstancesQuery,
    setQueryList: setCheckListInstanceQueryList,
  },
} = CreateEntitySlice<iCheckListInstance>({
  sliceName: "checkListInstances",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...checkListInstancesDefaultQueryConfig,
        alias: "all",
      };
    }
  },
});
