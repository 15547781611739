import { Button, Form, Input, message, Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useCallback, useEffect } from "react";
import { uuidv4 } from "utils/generate-uuid";
import { iPresetFolder } from "../../../@sdk/user-management/preset-state-model";
import "./preset-editor-modal.scss";

interface iPreset {
  id: string;
  value: string;
  label: string;
  type: "SIMPLE_TEXT" | "RICH_TEXT";
}

export const PresetFolderEditorModal = ({
  visible,
  mode,
  onChangeVisibility,
  onSaved,
  initialValue
}: {
  visible: boolean;
  mode: "ADD" | "EDIT";
  onChangeVisibility: (state: boolean) => any;
  onSaved: (value: iPresetFolder) => any;
  initialValue?: iPresetFolder;
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      label: initialValue?.label
    });
  }, [visible, mode, initialValue, form]);

  const onFinish = useCallback(async () => {
    try {
      await form.validateFields();
      const { label } = form.getFieldsValue();
      onSaved({
        id: uuidv4(),
        ...((initialValue || {}) as any),
        label: label
      });
    } catch (e) {
      message.error("Please check your input");
    }
  }, [form, initialValue, onSaved]);

  return (
    <Modal
      title={
        mode === "ADD" ? (
          <ModalTitle
            icon={<i className="ri-folder-add-line"></i>}
            title="Add Folder"
          />
        ) : (
          <ModalTitle
            icon={<i className="ri-pencil-line"></i>}
            title="Edit Folder"
          />
        )
      }
      open={visible}
      onOk={onFinish}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Save"
      okButtonProps={{ className: "font-bold" }}
      data-click-context="Preset Folder Editor Modal"
      width={400}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{}}
        onFinish={onFinish}
      >
        <Form.Item
          name="label"
          label=""
          rules={[
            {
              required: true,
              message: "Please enter a label"
            }
          ]}
        >
          <Input placeholder="Label" size="large" />
        </Form.Item>
        <Button htmlType="submit" type="primary" className="hidden">
          Save
        </Button>
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
