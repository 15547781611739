import { iContactList } from "@sdk/crm/crm.models";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectContactListQueryMap,
  selectEntityMap: selectContactListsMap,
  selectQueryMapAll: selectContactListsMapAll,
  selectQueryAllList: selectContactListsAllList,
  selectAllEntities: selectAllContactLists,
  selectAllEntitiesQuery: selectAllContactListsQuery,
  selectEntityById: selectContactListById,
  selectEntityQuery: selectContactListsQuery
} = CreateSelectorForEntities<iStore, iContactList>({
  sliceName: "contactLists"
});
