import { iScreenTour } from "@sdk/screen-tours/screen-tours-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { screenToursDefaultQueryConfig } from "./screen-tours-default-query";

export const {
  slice: ScreenToursSlice,
  reducers: ScreenToursSliceReducer,
  actions: {
    setEntityQueryResults: setScreenToursQueryResults,
    setEntity: setScreenTour,
    setEntities: setScreenTours,
    patchEntity: patchScreenTour,
    addNewEntity: addNewScreenTour,
    setEntityFetchingStatus: setScreenTourFetchingStatus,
    setEntitiesFetchingStatus: setScreenToursFetchingStatus,
    setEntityFailedStatus: setScreenTourFailedStatus,
    setEntityQueryError: setScreenToursQueryError,
    resetQuery: resetScreenToursQuery,
    setQueryList: setScreenTourQueryList,
  },
} = CreateEntitySlice<iScreenTour>({
  sliceName: "screenTours",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...screenToursDefaultQueryConfig,
        alias: "all",
      };
    }
  },
});
