import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Space, Tooltip } from "antd";
import { RouterPrompt } from "components/common/router-prompt/router-prompt";
import { SimpleDNDList } from "components/common/simple-dnd-list/dnd-list";
import { useChatBarSections } from "components/modules/conversations/components/chat-view-side-bar/helpers/use-chat-side-bar-sections";
import _ from "lodash";

import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import SideBarContactProfileSectionConfig from "./chat-side-bar-profile-config.modal-registry";

export type iChatSideBarConfigRef = { onSave: () => any };
export const ChatSideBarConfig = forwardRef<
  iChatSideBarConfigRef | undefined,
  {
    target: "WORKSPACE" | "USER";
    disableRouterWarning?: boolean;
    disableSaveButton?: boolean;
    openConfig?: string;
  }
>(({ target, disableRouterWarning, disableSaveButton, openConfig }, ref) => {
  const {
    allSections: initialSectionOrder,
    hiddenSections: initialHiddenSections,
  } = useChatBarSections(target);

  const [sections, _setSections] = useState(initialSectionOrder);

  const [hiddenSections, _setHiddenSections] = useState(
    initialHiddenSections || [],
  );

  const [hasChanged, setHasChanged] = useState(false);

  const setSections = useCallback((items) => {
    _setSections(items);
    setHasChanged(true);
  }, []);

  const setHiddenSections = useCallback((items) => {
    _setHiddenSections(items);
    setHasChanged(true);
  }, []);

  useEffect(() => {
    setHasChanged(false);
    _setHiddenSections(initialHiddenSections || []);
  }, [initialHiddenSections, initialSectionOrder]);

  const toggleSectionVisibility = useCallback(
    (section: string) => {
      if (hiddenSections?.includes(section)) {
        // Unhide
        setHiddenSections(_.without(hiddenSections, section));
      } else {
        // Hide
        setHiddenSections([...hiddenSections, section]);
      }
    },
    [hiddenSections, setHiddenSections],
  );

  const { doAction: editWorkspace, isProcessing: isEditingWorkspace } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (edits) => {
          return SDK.editCurrentOrganization({
            appData: {
              HELP_DESK: {
                chatSideBarConfig: edits,
              },
            },
          });
        },
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const { doAction: editCurrentUser, isProcessing: isEditingUser } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (edits) => {
          return SDK.editCurrentUser({
            data: {
              chatSideBarConfig: edits,
            },
          });
        },
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const onSave = useCallback(() => {
    if (target === "WORKSPACE") {
      editWorkspace({
        sections: sections.map((item) => item.id),
        hiddenSections,
      });
    } else {
      editCurrentUser({
        sections: sections.map((item) => item.id),
        hiddenSections,
      });
    }
  }, [editCurrentUser, editWorkspace, hiddenSections, sections, target]);

  const { triggerTempModal } = useModalPanels();

  useImperativeHandle(
    ref,
    () => ({
      onSave: onSave,
    }),
    [onSave],
  );

  useEffect(() => {
    if (openConfig) {
      if (openConfig === "PROFILE") {
        setTimeout(() => {
          triggerTempModal(SideBarContactProfileSectionConfig, {});
        }, 200);
      }
    }
  }, [openConfig, triggerTempModal]);

  return (
    <div className="p-4">
      {!disableRouterWarning && <RouterPrompt when={hasChanged} />}

      <SimpleDNDList
        list={sections}
        onListChange={setSections}
        renderItem={(section, index) => (
          <div
            className="p-2 border border-gray-200 dark:border-gray-700 rounded-lg mb-4 flex flex-row justify-between items-center"
            key={section.id}
          >
            <div className="flex flex-row justify-center items-center">
              <div className=" mr-2">
                {typeof section.icon === "string" ? (
                  <i className={section.icon}></i>
                ) : (
                  <section.icon />
                )}
              </div>
              {section.title}
            </div>
            <div className="flex flex-row justify-center items-center">
              <Space>
                {section.id === "PROFILE" && (
                  <Tooltip title="Configure Section">
                    <Button
                      icon={<i className="ri-tools-line"></i>}
                      type="text"
                      onClick={() => {
                        triggerTempModal(
                          SideBarContactProfileSectionConfig,
                          {},
                        );
                      }}
                    />
                  </Tooltip>
                )}
                <Tooltip title="Toggle Visibility">
                  <Button
                    icon={
                      hiddenSections.includes(section.id) ? (
                        <i className="ri-eye-off-line"></i>
                      ) : (
                        <i className="ri-eye-line"></i>
                      )
                    }
                    type="text"
                    onClick={() => toggleSectionVisibility(section.id)}
                  />
                </Tooltip>
              </Space>
            </div>
          </div>
        )}
      />
      {!disableSaveButton && (
        <div
          className="flex flex-row justify-end items-center"
          style={{ height: 30 }}
        >
          {hasChanged && (
            <Button
              onClick={onSave}
              className="font-bold animated fadeInRight"
              icon={<i className="ri-save-line"></i>}
              loading={isEditingUser || isEditingWorkspace}
              type="primary"
            >
              Save
            </Button>
          )}
        </div>
      )}
    </div>
  );
});
