import { SDK } from "@sdk";
import { iCallLog } from "@sdk/call-logs/call-logs-model";

import { Store } from "redux";
import { QueryConfig } from "store/utils/query-config";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { callLogsDefaultQueryConfig } from "./call-logs-default-query";
import { CallLogsSlice } from "./call-logs.slice";

export const loadAllCallLogs = () => async (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  await loadCallLogsQuery(callLogsDefaultQueryConfig, "all")(
    store,
    forceReload
  );
};

export const generateCallLogOfContactQuery = (contactId: string) => ({
  ...callLogsDefaultQueryConfig,
  query: {
    contactId
  }
});

export const loadCallLogOfContact = (contactId: string) => async (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  await loadCallLogsQuery(
    generateCallLogOfContactQuery(contactId),
    JSON.stringify(generateCallLogOfContactQuery(contactId))
  )(store, forceReload);
};

export const loadQueryCallLogs = (
  queryConfig: QueryConfig,
  queryAlias?: string
) => async (store: Store<iStore>, forceReload?: boolean) => {
  queryAlias = queryAlias || JSON.stringify(queryConfig);
  await loadCallLogsQuery(queryConfig, queryAlias)(store, forceReload);
};

export const {
  loadEntityQueries: loadCallLogsQuery,
  loadEntityById: loadCallLogById,
  loadMoreEntityQueries: loadMoreCallLogsQueries
} = CreateHelpersForEntity<iStore, iCallLog>({
  sliceName: "callLogs",
  slice: CallLogsSlice,
  isPaginatedQueries: true,
  fetchPageSize: 50,
  loadEntityQueryProvider: query => SDK.callLogs.query(query),
  loadEntityByIdProvider: (entityId: string) => SDK.callLogs.getById(entityId)
});
