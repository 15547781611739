import { iChatBotInstance } from "@sdk/chat-bots/chat-bots.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { chatBotInstancesDefaultQueryConfig } from "./chat-bot-instance-default-query";

export const {
  slice: ChatBotInstancesSlice,
  reducers: ChatBotInstancesSliceReducer,
  actions: {
    setEntityQueryResults: setChatBotInstancesQueryResults,
    setEntity: setChatBotInstance,
    setEntities: setChatBotInstances,
    patchEntity: patchChatBotInstance,
    addNewEntity: addNewChatBotInstance,
    setEntityFetchingStatus: setChatBotInstanceFetchingStatus,
    setEntitiesFetchingStatus: setChatBotInstancesFetchingStatus,
    setEntityFailedStatus: setChatBotInstanceFailedStatus,
    setEntityQueryError: setChatBotInstancesQueryError,
    resetQuery: resetChatBotInstancesQuery,
    setQueryList: setChatBotInstanceQueryList
  }
} = CreateEntitySlice<iChatBotInstance>({
  sliceName: "chatBotInstances",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...chatBotInstancesDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
