import { SDK } from "@sdk";
import { processServerError } from "@sdk/sdk.hooks";
import { message } from "antd";
import { iPricingPlan } from "./pricing-plan-model";
import { PricingPlans } from "./pricing-plans";
import { useSubscriptionDetails } from "./use-subscription-details";

export const PricingPlansChanger = ({
  reloadStripeData,
  setActivatePaymentModalVisibility,
}: {
  reloadStripeData: () => any;
  setActivatePaymentModalVisibility: (plan: iPricingPlan) => any;
}) => {
  const { isSubscribed, subscriptionDetails, isYearly, plan } =
    useSubscriptionDetails();
  return (
    <>
      <PricingPlans
        onPlanSelect={async (plan, isYearly) => {
          const planId = isYearly ? `${plan.id}_YEARLY` : `${plan.id}_MONTHLY`;

          if (subscriptionDetails?.planId !== planId) {
            if (isSubscribed) {
              // Plan is there
              // Change Plan
              await SDK.changePlan({
                planId: planId,
              })
                .then((d) => {
                  message.info("Subscription has been changed");
                  reloadStripeData();
                })
                .catch((e) => {
                  console.log("Error while changing plan", e);
                  message.error(processServerError(e, "Something went wrong"));
                });
            } else {
              // Plan is not there, trigger Payment Modal
              const planId = isYearly
                ? `${plan.id}_YEARLY`
                : `${plan.id}_MONTHLY`;
              // Show Payment Method Details
              setActivatePaymentModalVisibility({
                ...plan,
                planId,
              });
            }
          }
        }}
        selectedPlan={subscriptionDetails?.planId || "STARTUP"}
        hideYearly={true}
      />
    </>
  );
};
