import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AutomationRuleEditorModal = React.lazy(
  () => import("./automation-rule-editor.modal"),
);

const AutomationRuleEditor: iModalProps<typeof AutomationRuleEditorModal, {}> =
  {
    name: "automation-rule-editor",
    component: GenerateModalWithSuspense(AutomationRuleEditorModal),
  };

export default AutomationRuleEditor;
