import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddTaskModal = React.lazy(() => import("./add-task.modal"));

const AddTask: iModalProps<typeof AddTaskModal, {}> = {
  name: "add-task",
  component: GenerateModalWithSuspense(AddTaskModal),
};

export default AddTask;
