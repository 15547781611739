import { axios, AxiosResponse } from "@sdk/axios";
import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import {
  PaginatedOptions,
  PaginatedResults,
} from "@sdk/utils/paginated-results";
import { UserTracker } from "user-tracker";

import { iCampaign } from "./campaigns-model";
import { iMailgunWebhookEvent } from "./mailgun-event-model";

export class CampaignsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.campaigns.configure(config);
  }

  campaigns = Object.assign(
    new EntityServices<iCampaign>(SDKConfig, "campaigns", {
      onCreate: () => {
        UserTracker.track("CAMPAIGNS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("CAMPAIGNS - Delete", {});
      },
    }),
    {
      sendCampaign: async (campaignId: string) => {
        UserTracker.track("CAMPAIGNS - Send Campaign", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/campaigns/${campaignId}/send`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      sendTest: async (campaignId: string, email: string) => {
        UserTracker.track("CAMPAIGNS - Send Test", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/campaigns/${campaignId}/send-test`,
          {
            email,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      queryLogs: async (
        campaignId: string,
        req: { query: any; options: PaginatedOptions },
      ) => {
        const results: AxiosResponse<PaginatedResults<iMailgunWebhookEvent>> =
          await axios.post(
            this.config.basePath + `/campaigns/${campaignId}/logs/query`,
            req,
            {
              headers: {
                Authorization: this.config.token,
              },
            },
          );
        return results.data;
      },
      queryReport: async (campaignId: string, req: { query: any }) => {
        const results: AxiosResponse<{ count: number }> = await axios.post(
          this.config.basePath + `/campaigns/${campaignId}/report`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
