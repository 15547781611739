import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ProactiveCampaignsWizardModal = React.lazy(
  () => import("./proactive-campaigns-wizard.modal"),
);

const ProactiveCampaignsWizard: iModalProps<
  typeof ProactiveCampaignsWizardModal,
  {}
> = {
  name: "proactive-campaigns-wizard",
  component: GenerateModalWithSuspense(ProactiveCampaignsWizardModal),
};

export default ProactiveCampaignsWizard;
