import { Button, Popover } from "antd";
import { useCallback, useEffect, useState } from "react";

import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import classnames from "classnames";
import CCEmbed from "components/common/cc-embed/cc-embed.modal-registry";

const iconMap = {
  QUESTION: <i className="ri-question-line" style={{ paddingRight: 0 }}></i>,
  LEARN: <i className="ri-book-mark-line" style={{ paddingRight: 0 }}></i>,
};

export const HelpCenterPromptButton = ({
  type = "ICON",
  icon = "QUESTION",
  text = "Learn More",
  description,
  articleId,
  collectionId,
  onViewed,
  disableAutoShow,
  className,
}: {
  type?: "ICON" | "BANNER";
  icon?: "QUESTION" | "LEARN";
  text?:
    | "Learn More"
    | "Learn More About This"
    | "What is this?"
    | "Read More"
    | string
    | JSX.Element;
  description?: string;
  articleId?: string;
  collectionId?: string;
  onViewed?: () => any;
  disableAutoShow?: boolean;
  className?: string;
}) => {
  //   const [selectedArticle, setSelectedArticle] = useUrlState(
  //     "help-center-article"
  //   );

  //   const [selectedCollection, onSelectCollection] = useUrlState(
  //     "help-center-collection"
  //   );

  // const [isHelpCenterVisible, setHelpCenterVisibility] = useUrlState(
  //   "activate-help-center"
  // );

  const { triggerTempModal } = useModalPanels();
  const activate = useCallback(() => {
    triggerTempModal(CCEmbed, {
      type: "KB",
      articleId,
      collectionId,
    });
    onViewed && onViewed();
    setTooltipVisibility(false);
  }, [articleId, collectionId, onViewed, triggerTempModal]);

  const [isToolTipVisible, setTooltipVisibility] = useState(
    disableAutoShow ? false : true,
  );

  useEffect(() => {
    if (!disableAutoShow) {
      if (type === "ICON") {
        const timer = setTimeout(() => {
          setTooltipVisibility(false);
        }, 2000);
        return () => {
          clearTimeout(timer);
        };
      }
    }
  }, [disableAutoShow, type]);

  if (type === "ICON") {
    return (
      <Popover
        title={<div className="font-bold">{text}</div>}
        content={
          <div className="bg-white dark:bg-gray-900">
            {description}
            {(articleId || collectionId) && (
              <Button
                type="primary"
                block
                icon={iconMap.LEARN}
                className="font-bold mt-2"
                onClick={activate}
              >
                Learn More
              </Button>
            )}
          </div>
        }
        open={isToolTipVisible}
        onOpenChange={setTooltipVisibility}
        overlayStyle={{ maxWidth: 280 }}
      >
        <Button
          type="text"
          shape="circle"
          // block
          icon={iconMap[icon]}
          className="font-bold"
          //   onClick={activate}
        ></Button>
      </Popover>
    );
  }

  return (
    <>
      <div
        className={classnames(
          "contact-card border border-gray-200 bg-gray-100 dark:bg-gray-900 dark:border-gray-800 flex flex-row p-2 rounded-lg justify-center items-center",
          className,
        )}
      >
        <Button
          type="dashed"
          block
          icon={iconMap[icon]}
          className="font-bold h-auto"
          onClick={activate}
        >
          {text}
        </Button>
      </div>
    </>
  );
};
