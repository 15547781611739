import classNames from "classnames";
import React from "react";

export const EmptyData = ({
  icon,
  text,
  className,
}: {
  icon?: string | React.ReactNode;
  text: string | React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col justify-center items-center my-8",
        className,
      )}
    >
      {icon && (
        <>
          {typeof icon === "string" ? (
            <i className={classNames(icon, "text-4xl")}></i>
          ) : (
            icon
          )}
        </>
      )}
      {text}
    </div>
  );
};

export const EmptyDataWithoutMargin = ({
  icon,
  text,
  className,
}: {
  icon?: string | React.ReactNode;
  text: string | React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col justify-center items-center",
        className,
      )}
    >
      {icon && (
        <>
          {typeof icon === "string" ? (
            <i className={classNames(icon, "text-4xl")}></i>
          ) : (
            icon
          )}
        </>
      )}
      {text}
    </div>
  );
};
