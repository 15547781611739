import { iUserGroup } from "../../../@sdk/user-management/user-management.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { groupsDefaultQueryConfig } from "./groups-default-query";

export const {
  slice: GroupsSlice,
  reducers: GroupsSliceReducer,
  actions: {
    setEntityQueryResults: setGroupQueryResults,
    setEntity: setGroup,
    patchEntity: patchGroup,
    setEntityFetchingStatus: setGroupFetchingStatus,
    setEntitiesFetchingStatus: setGroupsFetchingStatus,
    setEntityFailedStatus: setGroupFailedStatus,
    setEntityQueryError: setGroupQueryError,
    addNewEntity: addNewGroup
  }
} = CreateEntitySlice<iUserGroup>({
  sliceName: "userGroups",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...groupsDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
