import Icon from "@mdi/react";

export const MDIIcon = ({
  icon,
  size,
  className,
  style,
}: {
  icon: string;
  size?: string;
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <span style={style}>
      <Icon
        path={icon}
        size={size || "1rem"}
        className={`inline ${className || ""}`}
      />
    </span>
  );
};
