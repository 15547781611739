import classNames from "utils/classnames";

export const HelpUsHelpYou = ({
  chatMessage,
  emoji = "💡",
  title,
  description,
  className,
}: {
  chatMessage: string;
  emoji?: string;
  title?: string;
  description?: string;
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        "p-4 rounded-lg border border-gray-300 dark:border-gray-700 hidden md:flex flex-col cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800 mt-8",
        className,
      )}
      onClick={() => {
        (window as any).ccWidget?.onWidgetActivated(() => {
          (window as any).ccWidget?.triggerCampaign?.({
            message: chatMessage,
          });
          (window as any).ccWidget.setWidgetVisibility(true);
        });
      }}
    >
      <div className="flex flex-row items-center">
        <div className="text-4xl">{emoji || "🥰"}</div>
        <div className="text-xl font-bold ml-3">
          {title || "Help us Help you!"}
        </div>
      </div>

      <div className="text-gray-600 dark:text-gray-400">
        {description || "Share your feedback with us to improve ClickConnector"}
      </div>
    </div>
  );
};
