export const IndustryOptions = [
  {
    value: "Travel and Tourism",
    label: "Travel and Tourism",
    icon: <i className="ri-flight-takeoff-line"></i>,
  },
  {
    value: "E-Commerce",
    label: "E-Commerce",
    icon: <i className="ri-shopping-cart-line"></i>,
  },
  {
    value: "Automobile",
    label: "Automobile",
    icon: <i className="ri-car-line"></i>,
  },
  {
    value: "Telecommunication",
    label: "Telecommunication",
    icon: <i className="ri-radar-line"></i>,
  },
  {
    value: "Softwares and Application",
    label: "Softwares and Application",
    icon: <i className="ri-mac-line"></i>,
  },
  {
    value: "Other",
    label: "Other",
    icon: <i className="ri-more-line"></i>,
  },
];
