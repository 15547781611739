import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const NewMessageModal = React.lazy(() => import("./new-message.modal"));

const NewMessageCreator: iModalProps<typeof NewMessageModal> = {
  name: "new-message",
  component: GenerateModalWithSuspense(NewMessageModal),
};

export default NewMessageCreator;
