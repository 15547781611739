import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ManageTelnyxCallbackWidgetInner = React.lazy(
  () => import("./manage-telnyx-callback-widget.modal"),
);

const ManageTelnyxCallbackWidget: iModalProps<
  typeof ManageTelnyxCallbackWidgetInner,
  {
    connectionId: string;
  }
> = {
  name: "manage-callback-widget",
  component: GenerateModalWithSuspense(ManageTelnyxCallbackWidgetInner),
};

export default ManageTelnyxCallbackWidget;
