import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { CustomDomainConfigForWorkspace } from "./custom-domain-config";

export const CustomPagesDomain = () => {
  return (
    <ConfigurationEditorComponent
      title="Custom Domain for Custom Pages"
      description="Set your own domain for ClickConnector Forms and Product Pages"
      icon={"ri-global-line"}
      entityType="WORKSPACE"
    >
      <CustomDomainConfigForWorkspace />
    </ConfigurationEditorComponent>
  );
};
