import { useSelector } from "react-redux";
import {
  selectCurrentUserDisabledFromJoiningChats,
  selectCurrentUserId
} from "store/modules/users/users.selectors";

export const JoiningChatsRestrictedBanner = () => {
  const currentUserId = useSelector(selectCurrentUserId);
  const isCurrentUserDisabledFromJoiningChats = useSelector(
    selectCurrentUserDisabledFromJoiningChats
  );

  return (
    <>
      {currentUserId && isCurrentUserDisabledFromJoiningChats && (
        <div className="bg-yellow-300 font-bold text-orange-700 flex justify-center items-center p-2">
          Joining new chats is restricted by the admin
        </div>
      )}
    </>
  );
};
