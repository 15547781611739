import { Form, Input, Select } from "antd";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { StyledSwitch } from "components/common/styled-swtich";
import { SendingDomainSelector } from "components/modules/conversations/components/sending-domains/sending-domain-selector/sending-domain-selector";
import { FlightItineraryTemplateTypes } from "components/pages/conversations/pnr-converter/flight-itenerary-template-types";
import { useSelector } from "react-redux";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import {
  selectIndustry,
  selectOrganization,
} from "store/modules/workspace/workspace.selectors";

export const OtherPowerSettings = () => {
  const organization = useSelector(selectOrganization);

  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  const industry = useSelector(selectIndustry);

  return (
    <>
      {industry === "Travel and Tourism" && (
        <>
          <ConfigurationEditorComponent
            icon="ri-article-line"
            title="Holiday Quotes Configuration"
            description="Configure Holiday Quote Generator and default settings"
            entityType="WORKSPACE"
          >
            <StyledSwitch
              label="Enable Sharing Block by Default"
              name={[
                "appData",
                "HELP_DESK",
                "holidayQuoteConfig",
                "enableSharingBlockByDefault",
              ]}
              defaultChecked={true}
            />
            <StyledSwitch
              label="Enable Feedback Block by Default"
              name={[
                "appData",
                "HELP_DESK",
                "holidayQuoteConfig",
                "enableFeedbackBlockByDefault",
              ]}
              defaultChecked={true}
            />
            <StyledSwitch
              label="Enable Payment Block by Default"
              name={[
                "appData",
                "HELP_DESK",
                "holidayQuoteConfig",
                "enablePaymentLinksByDefault",
              ]}
              defaultChecked={true}
            />

            <Form.Item
              name={[
                "appData",
                "HELP_DESK",
                "holidayQuoteConfig",
                "defaultPaymentLink",
              ]}
              label="Default Payment Link"
              rules={[]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={[
                "appData",
                "HELP_DESK",
                "holidayQuoteConfig",
                "defaultFlightItineraryStyle",
              ]}
              label="Flight Itinerary Default Template"
              rules={[]}
            >
              <Select style={{ minWidth: 150 }} defaultValue={"Elegant"}>
                {FlightItineraryTemplateTypes.map((item) => (
                  <Select.Option value={item.value} key={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </ConfigurationEditorComponent>
        </>
      )}
      <ConfigurationEditorComponent
        icon="ri-lock-password-line"
        title="Customer OTP Configurations"
        description="Configure connection from which OTP emails should be delivered"
        entityType="WORKSPACE"
      >
        {/* <Form.Item
          name={[
            "appData",
            "HELP_DESK",
            "customerPortalConfig",
            "otpEmailConnectionId",
          ]}
          label="Connection to Use for OTP Emails"
          rules={[]}
          help={
            <div className="text-gray-600 text-sm">
              Select the email connection that will be used to deliver the OTP
              email.
            </div>
          }
        >
          <ConnectionSelector
            connectionFilter={(connection) => connection.type === "EMAIL"}
          />
        </Form.Item> */}
        <Form.Item
          name={[
            "appData",
            "HELP_DESK",
            "customerPortalConfig",
            "otpEmailSendingDomainId",
          ]}
          label="Email domain to use for OTP emails"
          rules={[]}
          help={
            <div className="text-gray-600 text-sm">
              Select the email domain that will be used to deliver the OTP
              email.
            </div>
          }
        >
          <SendingDomainSelector />
        </Form.Item>
      </ConfigurationEditorComponent>
    </>
  );
};
