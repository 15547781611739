export const ReplaceTokens = (
  str: string,
  dict: Record<string, string | undefined>,
) => {
  return str.replace(/\{([A-Z_a-z0-9]+)\}/g, (original, key) =>
    // eslint-disable-next-line no-prototype-builtins
    dict.hasOwnProperty(key) && typeof dict[key] !== "undefined"
      ? dict[key]!
      : original,
  );
};
