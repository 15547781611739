import { iSLATemplate } from "@sdk/user-management/user-management.models";
import { Form, Input, Modal, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { useEffect } from "react";
import { slaOptions } from "./sla-options";

export const AddEditSLATemplateModal = ({
  visible,
  onChangeVisibility,
  initialValue,
  onSave,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  initialValue?: iSLATemplate;
  onSave: (slaTemplate: iSLATemplate) => any;
}) => {
  const [form] = useForm();

  useEffect(() => {
    if (initialValue) {
      form.resetFields();
      form.setFieldsValue(initialValue);
    } else {
      form.resetFields();
    }
  }, [form, initialValue]);

  useEffect(() => {
    if (!initialValue) {
      setTimeout(() => {
        if (!initialValue) {
          form.resetFields();
        }
      }, 200);
    }
  }, [form, initialValue, visible]);

  return (
    <>
      <Modal
        title={
          <ModalTitle
            icon={<i className="ri-heart-pulse-line"></i>}
            title={
              !initialValue?.id ? `Create SLA Template` : `Edit SLA Template`
            }
          />
        }
        open={visible}
        // okButtonProps={{ loading: isAddingAssistant || isEditingAssistant }}
        onOk={async () => {
          try {
            await form.validateFields();
            const formValues = form.getFieldsValue();
            if (
              !(
                formValues.firstResponseTime ||
                formValues.nextResponseTime ||
                formValues.timeToClose
              )
            ) {
              message.error("Please select at least one SLA Rule");
              return;
            }
            onSave({
              ...(initialValue || {}),
              ...formValues,
            });
          } catch (e) {
            message.error("Please check your input");
          }
        }}
        onCancel={() => {
          form.resetFields();
          onChangeVisibility(false);
        }}
        okText={!initialValue?.id ? `Add` : `Save`}
        data-click-context="Add Edit SLA template Modal"
        destroyOnClose={true}
      >
        <Form
          layout="vertical"
          form={form}
          requiredMark={false}
          className="bold-form-labels"
          initialValues={{}}
        >
          {/* Label */}
          <Form.Item
            name="label"
            label="Name"
            rules={[{ required: true, message: "Please enter a name" }]}
          >
            <Input placeholder="Eg: Support SLA" />
          </Form.Item>

          <Form.Item
            name="firstResponseTime"
            label={
              <div>
                <i className="ri-timer-line"></i>First Response Time
              </div>
            }
            rules={[]}
          >
            <Select
              options={slaOptions.filter((e) => !e.disableForFirstResponse)}
              placeholder="Select an option"
              allowClear
            />
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const firstResponseTime = getFieldValue(["firstResponseTime"]);
              if (firstResponseTime) {
                const slaReminderOptions =
                  slaOptions.find(
                    (option) => option.value === firstResponseTime,
                  )?.reminderOptions || [];

                if (slaReminderOptions?.length === 0) {
                  return <></>;
                }
                return (
                  <Form.Item
                    name="firstResponseReminderTime"
                    label={
                      <div>
                        <i className="ri-timer-line"></i>First Response Time
                        Reminder
                      </div>
                    }
                    rules={[
                      ({ getFieldValue }) => ({
                        validator() {
                          const selectedReminderOption = getFieldValue(
                            "firstResponseReminderTime",
                          );
                          if (!selectedReminderOption) {
                            return Promise.resolve();
                          }
                          const isValid = slaReminderOptions?.find(
                            (option) => option.value === selectedReminderOption,
                          );
                          if (!isValid) {
                            return Promise.reject(
                              new Error("Please select a valid option"),
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Select
                      options={slaReminderOptions}
                      placeholder="Select an option"
                      allowClear
                    />
                  </Form.Item>
                );
              }
              return <></>;
            }}
          </Form.Item>

          <Form.Item
            name="nextResponseTime"
            label={
              <div>
                <i className="ri-timer-line"></i>Next Response Time
              </div>
            }
            rules={[]}
          >
            <Select
              options={slaOptions.filter((e) => !e.disableForFirstResponse)}
              placeholder="Select an option"
              allowClear
            />
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const nextResponseTime = getFieldValue(["nextResponseTime"]);
              if (nextResponseTime) {
                const slaReminderOptions =
                  slaOptions.find((option) => option.value === nextResponseTime)
                    ?.reminderOptions || [];

                if (slaReminderOptions?.length === 0) {
                  return <></>;
                }
                return (
                  <Form.Item
                    name="nextResponseReminderTime"
                    label={
                      <div>
                        <i className="ri-timer-line"></i>Next Response Time
                        Reminder
                      </div>
                    }
                    rules={[
                      ({ getFieldValue }) => ({
                        validator() {
                          const selectedReminderOption = getFieldValue(
                            "nextResponseReminderTime",
                          );
                          if (!selectedReminderOption) {
                            return Promise.resolve();
                          }
                          const isValid = slaReminderOptions?.find(
                            (option) => option.value === selectedReminderOption,
                          );
                          if (!isValid) {
                            return Promise.reject(
                              new Error("Please select a valid option"),
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Select
                      options={slaReminderOptions}
                      placeholder="Select an option"
                      allowClear
                    />
                  </Form.Item>
                );
              }
              return <></>;
            }}
          </Form.Item>

          <Form.Item
            name="timeToClose"
            label={
              <div>
                <i className="ri-timer-line"></i>Time to Close
              </div>
            }
            rules={[]}
          >
            <Select
              options={slaOptions.filter((e) => !e.disableForClose)}
              placeholder="Select an option"
              allowClear
            />
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const timeToClose = getFieldValue(["timeToClose"]);
              if (timeToClose) {
                const slaReminderOptions =
                  slaOptions.find((option) => option.value === timeToClose)
                    ?.reminderOptions || [];

                if (slaReminderOptions?.length === 0) {
                  return <></>;
                }
                return (
                  <Form.Item
                    name="timeToCloseReminderTime"
                    label={
                      <div>
                        <i className="ri-timer-line"></i>Time to Close Reminder
                      </div>
                    }
                    rules={[
                      ({ getFieldValue }) => ({
                        validator() {
                          const selectedReminderOption = getFieldValue(
                            "timeToCloseReminderTime",
                          );
                          if (!selectedReminderOption) {
                            return Promise.resolve();
                          }
                          const isValid = slaReminderOptions?.find(
                            (option) => option.value === selectedReminderOption,
                          );
                          if (!isValid) {
                            return Promise.reject(
                              new Error("Please select a valid option"),
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Select
                      options={slaReminderOptions}
                      placeholder="Select an option"
                      allowClear
                    />
                  </Form.Item>
                );
              }
              return <></>;
            }}
          </Form.Item>
        </Form>
        <DarkModeBg />
      </Modal>
    </>
  );
};
