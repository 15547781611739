export const FlightItineraryTemplateTypes = [
  {
    value: "Elegant",
    label: "Elegant",
  },
  {
    value: "Simple",
    label: "Simple",
  },
  {
    value: "Table",
    label: "Table",
  },
];
