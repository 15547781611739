import mixpanel from "mixpanel-browser";

export class UserTracker {
  static isEnabled = false;

  static init() {
    mixpanel.init("df0416fb84e5b50683fe03a515e8b48a", {});
    this.isEnabled = true;
  }

  static track(eventName: string, properties: any = {}) {
    if (!this.isEnabled) {
      return;
    }
    mixpanel.track(eventName, properties);
  }

  static reset() {
    if (!this.isEnabled) {
      return;
    }
    mixpanel.reset();
  }

  static identify(id: string) {
    if (!this.isEnabled) {
      return;
    }
    mixpanel.identify(id);
  }

  static setUserProperties(properties: any = {}) {
    // console.log("Identify User", properties, this.isEnabled);
    if (!this.isEnabled) {
      return;
    }
    // Register a set of super properties, which are included with all events. This will overwrite previous super property values.
    mixpanel.register(properties);
    mixpanel.people.set(properties);
  }
}
