import {
  iConnection,
  iFbConnectionData
} from "@sdk/conversations/conversations.models";
import _ from "lodash";
import { createSelector } from "reselect";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectConnectionQueryMap,
  selectEntityMap: selectConnectionsMap,
  selectQueryMapAll: selectConnectionsMapAll,
  selectQueryAllList: selectConnectionsAllList,
  selectAllEntities: _selectAllConnections,
  selectAllEntitiesQuery: selectAllConnectionsQuery,
  selectEntityById: selectConnectionById,
  selectEntityQuery: selectConnectionsQuery
} = CreateSelectorForEntities<iStore, iConnection>({
  sliceName: "connections"
});

export const selectConnectionByPageId = (pageId: string) => (state: iStore) =>
  _.find(
    _selectAllConnections(state),
    connection => (connection.data as iFbConnectionData)?.page?.id
  ) as iConnection;

export const selectAllActiveConnections = createSelector(
  [_selectAllConnections],
  list => (list || []).filter(item => item?.metaData?.isActive)
);

export const selectAllActiveAccessibleConnections = createSelector(
  [_selectAllConnections],
  list =>
    (list || [])
      .filter(item => item?.metaData?.isActive)
      .filter(item => {
        const currentUserId = window["CC_CURRENT_USER_ID"];
        return (
          !item?.data?.isPersonal ||
          (item?.data?.isPersonal && item?.data.ownerId === currentUserId)
        );
      })
);

export const selectAllConnections = selectAllActiveAccessibleConnections;
