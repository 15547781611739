import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { iEmailV2ConnectionData } from "@sdk/conversations/conversations.models";
import { Button, Divider, Form, Input, message, Select, Switch } from "antd";
import {
  ConfigurationEditorMiniBase,
  ConfigurationEditorMiniComponent,
} from "components/common/configuration-editor-mini";
import { SectionHeader } from "components/common/section-header";
import SimpleModal from "components/common/simple-empty-modal/simple-empty.modal-registry";
import { GeneralConnectionConfig } from "components/modules/connections/common-connection-settings/general-connection-config";
import { RoutingPreferencesForm } from "components/modules/connections/common-connection-settings/routing-preferences-form/routing-preferences-form";
import copyToClipboard from "copy-to-clipboard";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useEmailTester } from "../../helpers/use-email-tester";
import { ConfigureTicketWorkflows } from "../configure-ticket-workflows/configure-ticket-workflows";
import { ConnectionSendingDomainSetup } from "../connection-sending-domain-setup/connection-sending-domain-setup";
import { EmailV2ForwardingEmailInstructions } from "../email-v2-forwarding-instructions/email-v2-forwarding-email-instructions";

export const ConfigureMailboxV2 = ({
  connectionId,
  hideHeader,
}: {
  connectionId: string;
  hideHeader?: boolean;
}) => {
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const { triggerTempModal } = useModalPanels();

  const {
    testIncomingEmail,
    isTestingIncomingEmail,
    testOutgoingEmail,
    isTestingOutgoingEmail,
  } = useEmailTester(connectionId!);

  return (
    <div className="animated fadeInLeftMin">
      {!hideHeader && (
        <>
          <SectionHeader
            title="Configure Mailbox"
            icon={<i className="ri-mail-settings-line"></i>}
            description="Configure mailbox, access, email workflows, etc"
          />
          <Divider />
        </>
      )}

      <div className="mb-8 text-center text-gray-600 dark:text-gray-400 mt-8">
        <div className="">Channel Email Address</div>
        {(connection.data as iEmailV2ConnectionData).email ===
        (connection?.data as iEmailV2ConnectionData)?.defaultSendingEmail ? (
          <>
            <div className="flex flex-row items-center justify-center">
              <div
                className="bg-gray-200 dark:bg-gray-800 p-4 rounded-lg  my-1 text-lg  cursor-pointer px-16"
                onClick={() => {
                  copyToClipboard(
                    `${(connection.data as iEmailV2ConnectionData).email}`,
                  );
                  message.success("copied to clipboard");
                }}
              >
                {(connection?.data as iEmailV2ConnectionData)?.email}
                <i className="ri-clipboard-line ml-2"></i>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-row items-center justify-center">
              <div
                className="bg-gray-200 dark:bg-gray-800 p-4 rounded-lg  my-1 text-lg  cursor-pointer px-16"
                onClick={() => {
                  copyToClipboard(
                    `${
                      (connection.data as iEmailV2ConnectionData)
                        .defaultSendingEmail
                    }`,
                  );
                  message.success("copied to clipboard");
                }}
              >
                {
                  (connection?.data as iEmailV2ConnectionData)
                    ?.defaultSendingEmail
                }
                <i className="ri-clipboard-line ml-2"></i>
              </div>
            </div>
            <div
              className="text-sm cursor-pointer"
              onClick={() => {
                // Todo
                copyToClipboard(
                  `${(connection.data as iEmailV2ConnectionData).email}`,
                );
                message.success("copied to clipboard");
              }}
            >
              ClickConnector Email -{" "}
              {(connection.data as iEmailV2ConnectionData).email}{" "}
              <i className="ri-clipboard-line"></i>
            </div>
          </>
        )}
        <div className="flex flex-row gap-4 items-center justify-center mt-4">
          <Button
            type="text"
            icon={<i className="ri-flask-line"></i>}
            onClick={testIncomingEmail}
            loading={isTestingIncomingEmail}
          >
            Test Incoming Email
          </Button>
          <Button
            type="text"
            icon={<i className="ri-flask-line"></i>}
            onClick={testOutgoingEmail}
            loading={isTestingOutgoingEmail}
          >
            Test Outgoing Email
          </Button>
        </div>
      </div>

      <div className="flex flex-row gap-4">
        <div className="flex-1">
          <Divider>Mailbox Configuration</Divider>
          <ConfigurationEditorMiniComponent
            entityType="CONNECTION"
            entityId={connectionId}
            icon={"ri-user-smile-line"}
            title={`Sender Name - ${connection.data.senderName}`}
            description="Change the name a customer sees when sending an email through ClickConnector"
          >
            <Form.Item
              label="Sender Name"
              name={["data", "senderName"]}
              help="Your name which your clients will see Eg: Support @ ABC"
              rules={[{ required: true, message: "Please select an email" }]}
            >
              <Input placeholder="" size="large" />
            </Form.Item>
          </ConfigurationEditorMiniComponent>

          {(connection?.data as iEmailV2ConnectionData).sendingDomain !==
            "emails.clickconnector.com" && (
            <>
              <ConfigurationEditorMiniBase
                icon={"ri-corner-down-right-line"}
                title={"Mail Receiving Setup (Forward Instructions)"}
                description={
                  "See instructions to setup forwarders to start receiving emails in ClickConnector inbox"
                }
                onClick={() => {
                  triggerTempModal(SimpleModal, {
                    config: {
                      title:
                        "Mail Receiving Setup Instructions (Forward Instructions)",
                      content: (
                        <EmailV2ForwardingEmailInstructions
                          connectionId={connectionId}
                        />
                      ),
                      icon: <i className="ri-corner-down-right-line"></i>,
                      width: 800,
                    },
                  });
                }}
                miniEditorSection={
                  <>
                    {(connection?.data as iEmailV2ConnectionData)
                      .isForwardTested ? (
                      <>
                        <span className="flex flex-row items-center gap-2">
                          <i className="ri-checkbox-circle-fill text-green-600"></i>{" "}
                          Verified
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="flex flex-row items-center gap-2">
                          <i className="ri-error-warning-fill text-orange-600"></i>
                          Not Verified
                        </span>
                      </>
                    )}
                  </>
                }
              />

              <ConfigurationEditorMiniBase
                icon={"ri-at-line"}
                title={"Sending Email"}
                description={
                  "Customize the email from which yours customers will receive your replies"
                }
                onClick={() => {
                  triggerTempModal(SimpleModal, {
                    config: {
                      title: "Sending Email Address",
                      content: (
                        <ConnectionSendingDomainSetup
                          connectionId={connectionId}
                        />
                      ),
                      icon: <i className="ri-at-line"></i>,
                      width: 800,
                    },
                  });
                }}
                miniEditorSection={
                  <>
                    <span className="text-gray-600 dark:text-gray-400 text-xs">
                      {
                        (connection?.data as iEmailV2ConnectionData)
                          .defaultSendingEmail
                      }
                    </span>
                    {(connection?.data as iEmailV2ConnectionData)
                      .isSendingDomainVerified ? (
                      <>
                        <span className="flex flex-row items-center gap-2">
                          <i className="ri-checkbox-circle-fill text-green-600"></i>{" "}
                          Verified
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="flex flex-row items-center gap-2">
                          <i className="ri-error-warning-fill text-orange-600"></i>
                          Not Verified
                        </span>
                      </>
                    )}
                  </>
                }
              />
            </>
          )}
        </div>
        <div className="flex-1">
          <ConfigureTicketWorkflows connectionId={connectionId} hideHeader />
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <div className="flex-1">
          <Divider>Conversation Routing Settings</Divider>
          {/* Routing Settings */}
          <RoutingPreferencesForm connectionId={connectionId} />
        </div>
        <div className="flex-1">
          <Divider>Incoming Email Settings</Divider>

          <ConfigurationEditorMiniComponent
            entityType="CONNECTION"
            entityId={connectionId}
            icon={"ri-spam-line"}
            title="Spam Filter"
            description="Enable/disable default email spam filter"
            miniEditor={
              <>
                <Form.Item
                  valuePropName="checked"
                  name={["data", "enableSpamFilter"]}
                >
                  <Switch size="small" />
                </Form.Item>
              </>
            }
          ></ConfigurationEditorMiniComponent>

          <ConfigurationEditorMiniComponent
            entityType="CONNECTION"
            entityId={connectionId}
            icon={"ri-mail-star-line"}
            title="Read 'Reply-to' Headers as Sender"
            description="Automatically read 'Reply-to' headers as Sender"
            miniEditor={
              <>
                <Form.Item
                  valuePropName="checked"
                  name={["data", "readReplyToAsFrom"]}
                >
                  <Switch size="small" />
                </Form.Item>
              </>
            }
          ></ConfigurationEditorMiniComponent>

          <ConfigurationEditorMiniComponent
            entityType="CONNECTION"
            entityId={connectionId}
            icon={"ri-mail-lock-line"}
            // icon={"ri-chat-forward-line"}
            title="Whitelisted Forwarding Emails"
            description="When emails are forwarded from these address, ClickConnector will attempt to parse the original email that is forwarded"
          >
            <Form.Item
              name={["data", "whiteListedForwarderEmails"]}
              label="Whitelisted Forwarding Email Addresses"
              rules={[]}
            >
              <Select
                mode="tags"
                style={{ width: "100%" }}
                tokenSeparators={[","]}
                dropdownStyle={{ display: "none" }}
              >
                {" "}
              </Select>
            </Form.Item>
          </ConfigurationEditorMiniComponent>

          <ConfigurationEditorMiniComponent
            entityType="CONNECTION"
            entityId={connectionId}
            icon={"ri-chat-settings-line"}
            title="Enable Simple Chat Ids"
            description="5 digit Chat ID allows you to easily refer conversations with customer"
            miniEditor={
              <>
                <Form.Item
                  valuePropName="checked"
                  name={[
                    "data",
                    "ticketWorkflowConfig",
                    "simpleTicketIds",
                    "isEnabled",
                  ]}
                >
                  <Switch size="small" />
                </Form.Item>
              </>
            }
          ></ConfigurationEditorMiniComponent>
        </div>
      </div>

      <GeneralConnectionConfig
        connectionId={connectionId}
        withoutHeader
        additionalPowerSettings={<></>}
      />
    </div>
  );
};
