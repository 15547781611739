import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const DownloadAppModal = React.lazy(() => import("./download-app.modal"));

const DownloadApp: iModalProps<typeof DownloadAppModal, {}> = {
  name: "download-app",
  component: GenerateModalWithSuspense(DownloadAppModal),
};

export default DownloadApp;
