/* ------------------------------ Connection ----------------------------- */

import { iContact } from "@sdk/crm/crm.models";
import { DeepPartial } from "redux";

export interface iConnectionMetaData {
  createdAt: number;
  createdBy: string;
  isActive: boolean;
  hasError: boolean;
}
export type iSmtpDetails =
  | iGmailSmtpDetails
  | iAWSSmtpDetails
  | iCustomSmtpDetails;
export interface iGmailSmtpDetails {
  type: "GMAIL";
  data: {
    email: string;
    password: string;
  };
}
export interface iAWSSmtpDetails {
  type: "AWS";
  data: {
    server: string;
    username: string;
    password: string;
  };
}
export interface iCustomSmtpDetails {
  type: "CUSTOM";
  data: {
    server: string;
    port: number;
    username: string;
    password: string;
  };
}

export interface iTicketWorkflowConfig {
  isEnabled: boolean;
  onCreationEmail: {
    isEnabled: boolean;
    template: string;
  };
  onClosedEmail: {
    isEnabled: boolean;
    template: string;
  };
  formatRepliesInTemplate: {
    isEnabled: boolean;
  };
  simpleTicketIds: {
    isEnabled: boolean;
  };
}

// * Maybe to know that there is a personal email sent, there is a different API call - that is queried separately with customerID - Downside is that when sync events are sent, it's not sent to everyone - maybe it is sent to customer_private channels
export interface iPersonalConnectionConfig {
  isPersonal?: boolean;
  ownerId?: string;
  sharedWith?: string[];
  filterOnlyContactConversations?: boolean;
  whiteListedEmails?: string[];
}

export interface iGmailMailboxData {
  account: string;
  alias?: string;
  lastUpdatedHistoryId?: string;
  tokens: any;
}

export interface iEmailV2ConnectionData
  extends iConnectionRoutingConfigurationData,
    iConnectionConfigurationData,
    iPersonalConnectionConfig {
  defaultSendingEmail: string;
  email: string;
  senderName: string;
  sendingDomain: string;
  isSendingDomainVerified?: boolean;
  groupToRoute?: string;
  threadTemplateType?: string;
  emailConfiguration?: {
    logo?: string;
    primaryColor?: string;
  };
  ticketWorkflowConfig?: iTicketWorkflowConfig;
  whiteListedForwarderEmails?: string[];
  readReplyToAsFrom?: boolean;
  enableSpamFilter?: boolean;
  isForwardTested?: boolean;
}

export interface iEmailConnectionData
  extends iConnectionRoutingConfigurationData,
    iConnectionConfigurationData,
    iPersonalConnectionConfig {
  // * If customSmtp is present, then it has to use custom SMTP. if not it should use inbuilt email server
  email: string;
  senderName: string;
  groupToRoute?: string;
  threadTemplateType?: string;
  customSmtp?: any;
  customDomain?: {
    domain: string;
    email: string;
    data: any;
    trackingActivated?: boolean;
  };
  gmailMailboxData?: iGmailMailboxData;
  emailConfiguration?: {
    logo?: string;
    primaryColor?: string;
  };
  ticketWorkflowConfig?: iTicketWorkflowConfig;
  whiteListedForwarderEmails?: string[];
  readReplyToAsFrom?: boolean;
  enableSpamFilter?: boolean;
}

export interface iWAPrivateConnectionData
  extends iConnectionRoutingConfigurationData,
    iConnectionConfigurationData {
  isNewPrivateConnection?: boolean;
  instanceUrl: string;
  apiToken: string;
  instanceId: string;
  lastReceivedMessageNumber?: number;
  waConnection?: {
    id: string;
    battery: string;
    locale: string;
    name: string;
    wa_version: string;
    device: {
      os_version: string;
      manufacturer: string;
      model: string;
    };
  };
  waConnectionX?: any;
  lastConnectedNumber?: string;
  isInstanceDeleted?: boolean;
}

export interface iWAOfficialConnectionData
  extends iConnectionRoutingConfigurationData,
    iConnectionConfigurationData {
  instanceUrl: string;
  apiKey: string;
  about?: {
    text: string;
  };
  businessProfile?: {
    vertical: string;
    websites: string[];
    email: string;
    description: string;
    address: string;
  };
  profilePicture?: string;
}

export interface iLiveChatConnectionData
  extends iConnectionRoutingConfigurationData,
    iConnectionConfigurationData {
  defaultInviteMessage?: string;
  campaigns?: any[];
  emailConfiguration?: {
    logo?: string;
    primaryColor?: string;
    homepageAddress?: string;
  };
  notifyClientViaEmail?: {
    active: boolean;
    senderName: string;
    sendingDomainId?: string;
    connectionId?: string;
    customLocale?: {
      bannerImage?: string;
      title?: string;
    };
  };
  ticketConnectionId?: string;
  disablePageNavigationActivitiesInChats?: boolean;
}

export interface iRule {
  id?: string;
  field: string;
  operator: string;
  value: any;
  routeTo: "STOP_ROUTING" | "ROUTE_TO_ANY_AVAILABLE_AGENT" | "CUSTOM_ROUTING";
  routeToData: {
    group?: string[];
  };
}

export interface iAutomaticRoutingRuleConfig {
  isEnabled?: boolean;
  rules: iRule[];
}

export interface iAutomaticArchiveUnsubscribe {
  isEnabled?: boolean;
}
export interface iConnectionAutoExtractContactData {
  active: boolean;
}

export interface iConnectionAutFollowupFlagConfig {
  active: boolean;
  template: string;
}

export interface iWACloudConnectionData
  extends iConnectionRoutingConfigurationData,
    iConnectionConfigurationData {
  phoneNumber: string;
  phoneId: string;
  // WhatsApp Business Account Id
  wabaId: string;
  accessToken: string;
  webhookVerifyToken: string;
}

export interface iWACloudNativeConnectionData
  extends iConnectionRoutingConfigurationData,
    iConnectionConfigurationData {
  wabaId: string;
  phoneId: string;
  accessToken: string;
  phoneNumber: string;
}

export interface iTelegramConnectionData
  extends iConnectionRoutingConfigurationData,
    iConnectionConfigurationData {
  accessToken: string;
  tgUserName: string;
  tgFirstName?: string;
  tgLastName?: string;
  tgUserId?: number;
}

export interface iStartWithChatBot {
  isEnabled?: boolean;
  chatBotId: string;
  activateOn: "ALL_NEW_CONVERSATION" | "NEW_LEAD";
}

export interface iFbConnectionData
  extends iConnectionRoutingConfigurationData,
    iConnectionConfigurationData {
  userAccessToken: string;
  page: {
    access_token: string;
    category: string;
    category_list: {
      id: string;
      name: string;
    }[];
    name: string;
    id: string;
    tasks: string[];
    instagram_business_account?: {
      id: string;
    };
  };
  autoRouteConfig?: iAutomaticRoutingRuleConfig;
  autoArchiveUnsubscribes: iAutomaticArchiveUnsubscribe;
}

export interface iIgConnectionData
  extends iConnectionRoutingConfigurationData,
    iConnectionConfigurationData {
  userAccessToken: string;
  page: {
    access_token: string;
    category: string;
    category_list: {
      id: string;
      name: string;
    }[];
    name: string;
    id: string;
    tasks: string[];
    instagram_business_account: {
      id: string;
    };
  };
}

export interface iCallRoutingConfig {
  routingMethod:
    | "RING_ALL"
    | "ROUND_ROBING_SINGLE"
    | "RANDOM_SINGLE"
    | "ROUND_ROBIN_RING_TEAM";
  groupsToRoute?: string[];
  timeout: number;
}

export interface iTelnyxConnectionData
  extends iConnectionRoutingConfigurationData,
    iConnectionConfigurationData {
  // Phone Number and Features
  phoneNumber?: string;
  order?: {
    orderId: string;
    status: string;
    number: string;
  };
  phoneId?: string;
  isPhoneEnabled?: true;
  isMessagingEnabled?: boolean;

  // Telnyx Connection Configs
  messagingProfileId: string;
  applicationId?: string;
  outboundProfileId?: string;
  billingGroupId?: string;

  // Connection Behavior Configs
  incomingCallConfig?: {
    welcomeMessage: {
      type: "VOICE" | "FILE";
      value: string;
    };
    waitingTone: {
      type: "preset" | "custom";
      value: string;
    };
    waitingTimeout: {
      timeout: number;
      message: string;
      voiceMail: boolean;
    };
    endMessage: {
      type: "VOICE" | "FILE";
      value: string;
    };
    ivr: {
      enabled: boolean;
      options: {
        key: string;
        message: string;
        channel: string;
        userGroup: string;
      }[];
    };
    routing: iCallRoutingConfig;
  };
  callBackWidgetConfig?: {
    logo?: string;
    processCallbackRequestAfterClientAnswers;
    colorPallet: {
      primary: string;
    };
    locale: {
      custom: {
        COMPANY_NAME: string;
        TEAM_NAME: string;
        SALES_TEAM_TAGLINE: string;
        GREETINGS_MESSAGE: string;
        GDPR_CONSENT: string;
        OUT_OF_OFFICE: string;
        POST_SUBMIT: string;
        NOT_AVAILABLE: string;
        NOT_HELPFUL: string;
      };
    };
    offlineHours: {
      timezone: string;
      dayWiseConfig: {
        [day: string]: { startTime: string; endTime: string };
      };
    };
    blackListedClients: {
      ips: string[];
    };
    triggersOn: {
      countries: string[];
      pages: string[];
    };
    validatePhone: {
      whitelistedCountries: string[];
      blockedNumbers: string[];
    };
  };
}

export interface iTwitterConnectionData
  extends iConnectionRoutingConfigurationData,
    iConnectionConfigurationData {
  oathV1: {
    oauth_token: string;
    oauth_token_secret: string;
    user_id: string;
    screen_name: string;
  };
  oathV2: {
    token_type: string;
    expires_in: number;
    expiryTimestamp: number;
    access_token: string;
    scope: string;
    refresh_token: string;
  };
}

export type iConnectionRoutingStrategy = "ROUND_ROBIN" | "RANDOM" | "NO_ROUTE";
export interface iConnectionRoutingConfigurationData {
  groupsToRoute?: string[];
  secondaryGroupsToRouteTo?: string[];
  routingRules?: any[];
  routingStrategy?: iConnectionRoutingStrategy;
  routingMethod?: "INVITE" | "ASSIGN";
  routeOnlyToOnlineUsers?: boolean;
  maximumHops?: number;
  inviteExpiry?: number;
  awayMessage?: string;
}
export type AutoAssignLastAgentOptions = "ALWAYS" | "NEVER" | "IF_AVAILABLE";
export type iConnectionLimitType = "CONVERSATIONS_JOIN" | "PRIVATE_REPLY";

export interface iConnectionUserLimit {
  id: string;
  limitType: iConnectionLimitType;

  targetType: "USER" | "USER_GROUP" | "ALL";
  targetValue: string;

  limitPeriod: "DAILY" | "WEEKLY" | "MONTHLY";
  limit: number;
}

export interface iAutoMessageOnReOpened {
  message: string;
}

export interface iConnectionConfigurationData {
  forceUserToTag?: boolean;
  forceUserToTagByTagGroup?: string[];
  connectionBreakAlertEmails?: string[];
  autoAssignLastAgent?: AutoAssignLastAgentOptions;
  limits?: iConnectionUserLimit[];
  notifications?: {
    onNewChat?: {
      isActive?: boolean;
      for?: "ALL" | "NO_ONE_IS_ONLINE";
      emails?: string[];
      // waitTime?: number;
    };
    onNewMessage?: {
      isActive?: boolean;
      // waitTime?: number;
    };
  };
  // *  The following is still  in the solution designing stage
  autoClose?: {
    active: boolean;
    timeout: number;
    message: string;
  };
  autoExtractContactData?: iConnectionAutoExtractContactData;
  autoFollowupConfig?: iConnectionAutFollowupFlagConfig;
  autoMessageOnReOpened?: iAutoMessageOnReOpened;
  startWithChatBot?: iStartWithChatBot;
}

export type iConnectionType =
  | "SMS"
  | "EMAIL"
  | "WEB_FORM"
  | "WEB_CHAT"
  | "FACEBOOK"
  | "WHATS_APP"
  | "INSTAGRAM"
  | "WHATS_APP_PRIVATE"
  | "WHATS_APP_OFFICIAL"
  | "WHATS_APP_CLOUD"
  | "WHATS_APP_CLOUD_NATIVE"
  | "TWITTER"
  | "TELNYX"
  | "TELEGRAM"
  | "EMAIL_V2";

export interface iConnection {
  id?: string;
  organizationId: string;
  label: string;
  userGroups?: string[];
  type: iConnectionType;
  data: any;
  metaData: iConnectionMetaData;
}

/* ------------------------------ Conversations ----------------------------- */

export interface iConversationFlag {
  isActive: boolean;
  raisedBy: string;
  raisedAt: number;
  dismissedBy?: string;
  dismissedAt?: number;
}

export interface iConversationFlags {
  requiresAttention?: iConversationFlag;
  requiresFollowup?: iConversationFlag;
}

export interface iConversationReadWatermarksByUser {
  [userId: string]: number;
}

export interface iConversationReadWatermarks {
  client: number;
  team: number;
  byUser: iConversationReadWatermarksByUser;
}

export interface iConversationLastMessage {
  text: string;
  timestamp: number;
  client: number;
  team: number;
  isByClient: boolean;
}

export interface iConversationTransferRequestLog {
  userId: string;
  timestamp: number;
  sentBy: string;
  referenceId: string;
}

export interface iConversationRoutingMetaData {
  routingId: string;
  isActive: boolean;
  startedBy: string;
  config: iConnectionRoutingConfigurationData;
  // For INVITE strategy
  currentInvitedUser?: string;
  lastRoutingExpiry?: number;
  bidQueue?: string[];
  routingUserIdTrail?: string[];
}

export interface iChatTranslatorConfig {
  isEnabled: boolean;
  language?: string;
}

export interface iConversationMetaData {
  isActive: boolean;
  createdAt: number;
  lastMessage: iConversationLastMessage;
  readWatermarks: iConversationReadWatermarks;
  flags: iConversationFlags;
  totalMessages: number;
  duration?: number;
  closedAt?: number;
  closedBy?: string;
  // Reporting
  firstResponseTime: number;
  // Other
  dueData: number;
  // Start Source
  initSource: ChatInitSource;
  transferRequestLogs: iConversationTransferRequestLog[];
  routerMetaData?: iConversationRoutingMetaData;
  // Other Settings
  autoTranslation?: iChatTranslatorConfig;
}

export type ChatInitSource =
  | "INVITE"
  | "INBOUND_EMAIL"
  | "OUTBOUND_EMAIL"
  | "WEBSITE_CHAT"
  | "FB_PRIVATE_REPLY"
  | "FACEBOOK_AD";

export interface iConversationMember {
  userId: string;
  joinedTime: number;
}

export interface iConversationPipeline {
  id: string;
  currentStage: string;
  data?: {
    [stageId: string]: {
      enteredTime: number;
      exitTime: number;
      // Other Data could be added based on the pipeline automation
    };
  };
  // Could Add Reporting Data
  // Eg: Time between stages
}

export interface iMoment {
  timestamp: number;
  category: MomentCategory;
  title: string;
  notes: string[];
  featured: boolean;
}

export enum MomentCategory {
  META_DATA = "Meta_Data",
  USER_DEFINED = "User_Defined",
  AHA_MOMENT = "Aha_Moment",
  MILESTONE = "Milestone",
  FB_AD_REFERENCE = "FB_AD_REFERENCE",
  FB_POST_REFERENCE = "FB_POST_REFERENCE",
  TRANSFER_LOG = "TRANSFER_LOG",
}

export interface iConversationRef {
  type: iConversationReferenceType;
  value: string;
}

export enum iConversationReferenceType {
  FB_AD_REFERENCE = "FB_AD_REFERENCE",
  FB_POST_REFERENCE = "FB_POST_REFERENCE",
  // We could add Campaigns Reference Here
  // We could add tags here, (different type of tags)
}

export interface iChatRating {
  timestamp: number;
  rating: number; // 1-10
  comment: string;
}

export type iSLATargetType = "YES" | "NO" | "NOT_APPLICABLE" | "WAITING";

export type iSLAType = "FIRST_RESPONSE" | "CLOSE" | "NEXT_RESPONSE";

export type iSLANextTargetType = iSLAType | "NONE";

export interface iSLATarget {
  templateId: string;
  isActive: boolean; // IsRottenOrNot

  firstResponseTarget: number;
  firstResponseTargetMet: iSLATargetType;
  firstResponseTime: number;

  closeTarget: number;
  closeTargetMet: iSLATargetType;
  closedTime: number;

  nextResponseTarget: number;
  nextResponseTargetMet: iSLATargetType;
  lastResponseTime: number;
  customerWaitingFrom?: number;
  replyTimeMisses: number;

  nextSLADue?: number;
  nextSLAType?: iSLANextTargetType;
}

export interface iConversation {
  id?: string;
  organizationId: string;
  connectionId: string;
  connectionType: string;
  contactId: string;
  contact?: DeepPartial<iContact>;
  activeBot?: string;
  hasActiveBot?: boolean;
  data: any; // Any Data Added
  customProperties?: any;
  priority: number; // 1- 10
  subject: string;
  status: "OPEN" | "CLOSED";
  pipeline: iConversationPipeline;
  channel: string; // Department
  tags: string[];
  members: iConversationMember[];
  hasMembers?: string;
  moments: iMoment[]; // rename to events
  references: iConversationRef[];
  metaData: iConversationMetaData;
  chatRating?: iChatRating;
  slaTarget?: iSLATarget;
}

export interface iConversationLiveChatData extends iConversationBaseData {
  startedPage?: string;
  landedPage: any;
  referer: any;
  sessionId: string;
}

export interface iConversationEmailData extends iConversationBaseData {
  email: string;
  subject: string;
  threadMembers: {
    name: string;
    email: string;
    type: "TO" | "CC";
    joinedMailId: string;
    joinedTime: number;
    contactId?: string;
  }[];
  simpleId?: string;
}

export interface iConversationFacebookData extends iConversationBaseData {
  fbPostRef?: {
    postRefUrl: string;
    pageId: string;
    postId: string;
    commentId: string;
  };
  fbAdId?: string;
  fbUserId: string;
  fbConversationRef: {
    id: string;
    link: string;
  };
}

export interface iConversationInstagramData extends iConversationBaseData {
  igUserId: string;
  igConversationId: string;
}

export interface iConversationTelnyxData extends iConversationBaseData {
  telnyxPhoneId: string;
}

export interface iConversationTwitterData extends iConversationBaseData {
  twitterUserId: string;
}

export interface iConversationWAPrivateData extends iConversationBaseData {
  chatId: string;
  phone: string;
}

export interface iConversationWAOfficialData extends iConversationBaseData {
  waId: string;
}

export interface iConversationWACloudData extends iConversationBaseData {
  waPhoneId: string;
}

export interface iConversationWACloudNativeData extends iConversationBaseData {
  waPhoneId: string;
}

export interface iConversationTelegramData extends iConversationBaseData {
  tgUserName: string;
  tgChatId: number;
  tgUserId: number;
}

export interface iConversationBaseData {
  notion_mappedPages?: string[];
  asana_linkedTasks?: string[];
  clickUp_linkedTasks?: string[];
  jira_linkedTasks?: string[];
  gitHub_linkedIssues?: string[];
  // Help Desk
  freshDesk_linkedTickets?: string[];
  zenDesk_linkedTickets?: string[];
  zohoDesk_linkedTickets?: string[];
}

/* -------------------------------- iMessage -------------------------------- */

export interface iMessageMetaData {
  sentAt: number;
  deliveredAt: number;
  // Newly Added
  fbMessageReference?: string; // mid
  fbReplyReference?: {
    mid: string;
  }; // reply_to
  isDeleted?: boolean;
  messageEditHistory?: string[];
}

export enum iMessageSenderType {
  USER = "USER",
  CAMPAIGN = "CAMPAIGN",
  CLIENT = "CLIENT",
  SYSTEM = "SYSTEM",
}

export interface iMessageFrom {
  senderType: iMessageSenderType;
  id: string;
}
export interface iMessageAttachment {
  // formatting of the messages can be done in front end
  // Notes: Buttons can be used here and the button actions can be sent as a
  // different message containing reply as an attachment.... This can be manipulated in the front-end
  type: MessageAttachmentTypes;
  payload:
    | any
    | {
        // File Attachment
        file: {
          url: string;
        };
        type: "application/pdf" | "image/jpeg" | "image/png" | "file";
      }
    | {
        // Link Attachment
        link: {
          url: string;
          title?: string;
          metaData?: {
            postId?: string;
            postData?: {
              message: string;
              image: string;
            };
          };
        };
      }
    | {
        // FB_POST_REF
        postRefUrl: string;
        pageId: string;
        postId: string;
        commentId: string;
        postData?: {
          message: string;
          image: string;
        };
      }
    | {
        // FB_COMMENT_REF
        postRefUrl: string;
        pageId: string;
        postId: string;
        commentId: string;
        postData?: {
          message: string;
          image: string;
        };
        commentData: {
          message: string;
        };
      }
    | {
        // IG_MEDIA_REF
        connectionId: string;
        instagramBusinessId: string;
        mediaId: string;
        mediaData: {
          message: string;
          mediaUrl: string;
          mediaType: string;
          link: string;
        };
      }
    | {
        // IG_COMMENT_REF
        connectionId: string;
        instagramBusinessId: string;
        mediaId: string;
        commentId: string;
        mediaData: {
          message: string;
          mediaUrl: string;
          mediaType: string;
          link: string;
        };
        commentData: {
          message: string;
        };
      }
    | {
        // FbEventEntry_Referral
      }
    | {
        // Quick Reply Options;
        options: iChatFlowConfig_GreetingsOption[];
      }
    | {
        // Quick Reply Selection;
        selection: iChatFlowConfig_GreetingsOption;
      }
    | {
        // Location
        longitude: number;
        latitude: number;
      }
    | {
        // Call Log
        isMissedCall: boolean;
      }
    | {
        // VCard
        vCard: string;
      }
    | {
        // Message
        message: Partial<iMessage>;
      }
    | {
        // Task
        taskId: string;
      }
    | {
        // Related Articles
        matchedDocuments: { id: string; type: string; title: string }[];
      }
    | {
        // AI Assist Session
        messages: iMessage[];
      }
    | {
        // JSON Data
        jsonData: any[];
      };
}

interface iChatFlowConfig_GreetingsOption {
  label: string;
  action: string;
  data: any;
}

export enum MessageAttachmentTypes {
  VIDEO = "VIDEO",
  VOICE_NOTE = "VOICE_NOTE",
  FORM = "FORM",
  SURVEY = "SURVEY",
  Rich_Text = "Rich_Text",
  PRESENTATION = "PRESENTATION",
  LINK = "LINK",
  FILE = "FILE",
  TICKET_REF = "TICKET_REF",
  FB_POST_REF = "FB_POST_REF",
  FB_COMMENT_REF = "FB_COMMENT_REF",
  IG_MEDIA_REF = "IG_MEDIA_REF",
  IG_COMMENT_REF = "IG_COMMENT_REF",
  FB_MESSENGER_REFERRAL = "FB_MESSENGER_REFERRAL",
  QUICK_REPLIES = "QUICK_REPLIES",
  SELECTED_QUICK_REPLY = "SELECTED_QUICK_REPLY",
  LOCATION = "LOCATION",
  CALL_LOG = "CALL_LOG",
  V_CARD = "V_CARD",
  MESSAGE_REFERENCE = "MESSAGE_REFERENCE",
  TASK = "TASK",
  ACTION_LIST = "ACTION_LIST",
  RELATED_ARTICLES = "RELATED_ARTICLES",
  AI_ASSIST_SESSION = "AI_ASSIST_SESSION",
  JSON_DATA = "JSON_DATA",
}

export interface iMessage {
  id?: string;
  organizationId: string;
  contactId?: string;
  conversationId: string;
  from: iMessageFrom;
  message: string;
  metaData: iMessageMetaData;
  // Newly Added
  isPrivate?: boolean;
  isWhisper?: boolean;
  isNotes?: boolean;
  attachments: iMessageAttachment[];
  references: string[]; // referencing other messages
  connectionId: string;
  connectionType: string;
  originRef?: string;
  data?: any;
  isTemporary?: boolean;
}

export interface iEmailMessageData {
  to: {
    name: string;
    email: string;
  }[];
  cc: {
    name: string;
    email: string;
  }[];
  "Dkim-Signature": string;
  "message-headers": string;
  Received: string;
  signature: string;
}

export interface iMessageDataWithReaction {
  customerReaction?: string;
}

export interface GmailSendAsItem {
  /**
   * A name that appears in the "From:" header for mail sent using this alias. For custom "from" addresses, when this is empty, Gmail will populate the "From:" header with the name that is used for the primary address associated with the account. If the admin has disabled the ability for users to update their name format, requests to update this field for the primary login will silently fail.
   */
  displayName?: string | null;
  /**
   * Whether this address is selected as the default "From:" address in situations such as composing a new message or sending a vacation auto-reply. Every Gmail account has exactly one default send-as address, so the only legal value that clients may write to this field is `true`. Changing this from `false` to `true` for an address will result in this field becoming `false` for the other previous default address.
   */
  isDefault?: boolean | null;
  /**
   * Whether this address is the primary address used to login to the account. Every Gmail account has exactly one primary address, and it cannot be deleted from the collection of send-as aliases. This field is read-only.
   */
  isPrimary?: boolean | null;
  /**
   * An optional email address that is included in a "Reply-To:" header for mail sent using this alias. If this is empty, Gmail will not generate a "Reply-To:" header.
   */
  replyToAddress?: string | null;
  /**
   * The email address that appears in the "From:" header for mail sent using this alias. This is read-only for all operations except create.
   */
  sendAsEmail?: string | null;
  /**
   * An optional HTML signature that is included in messages composed with this alias in the Gmail web UI. This signature is added to new emails only.
   */
  signature?: string | null;
  /**
   * An optional SMTP service that will be used as an outbound relay for mail sent using this alias. If this is empty, outbound mail will be sent directly from Gmail's servers to the destination SMTP service. This setting only applies to custom "from" aliases.
   */
  smtpMsa?: any;
  /**
   * Whether Gmail should treat this address as an alias for the user's primary email address. This setting only applies to custom "from" aliases.
   */
  treatAsAlias?: boolean | null;
  /**
   * Indicates whether this address has been verified for use as a send-as alias. Read-only. This setting only applies to custom "from" aliases.
   */
  verificationStatus?: string | null;
}
