import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const CreateTrackerModal = React.lazy(() => import("./create-tracker.modal"));

const CreateTracker: iModalProps<typeof CreateTrackerModal> = {
  name: "create-tracker",
  component: GenerateModalWithSuspense(CreateTrackerModal),
};

export default CreateTracker;
