import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import { UserTracker } from "user-tracker";
import { iScheduledMessage } from "./scheduled-messages";

export class ScheduledMessagesService {
  constructor(protected config: { basePath: string; token: string }) {
    this.scheduledMessages.configure(config);
  }

  /* ---------------------------------- Scheduled Messages --------------------------------- */

  scheduledMessages = Object.assign(
    new EntityServices<iScheduledMessage>(SDKConfig, "scheduled-messages", {
      onCreate: () => {
        UserTracker.track("SCHEDULED_MESSAGES - Create", {});
      },
      onDelete: () => {
        UserTracker.track("SCHEDULED_MESSAGES - Delete", {});
      },
    }),
    {},
  );
}
