import { useSelector } from "react-redux";
import { iStore } from "../store/store.model";
import { GetSafely } from "../utils/get-safely";
import { CheckPermission } from "./check-permission";
import { iPermissionGuard } from "./permission-guard-models";

const convertStringPermissionToGuardObject = (permissions: string[]) => {
  return permissions.reduce((prev, curr) => {
    const parts = curr.split(":");
    if (!prev[parts[0]]) {
      prev[parts[0]] = {};
    }
    if (!prev[parts[0]][parts[1]]) {
      prev[parts[0]][parts[1]] = {};
    }
    return prev;
  }, {} as iPermissionGuard);
};

export const useCan = (requiredPermissions?: iPermissionGuard | string[]) => {
  if (Array.isArray(requiredPermissions)) {
    requiredPermissions =
      convertStringPermissionToGuardObject(requiredPermissions);
  }
  const permissions = useSelector((state: iStore) =>
    GetSafely(() => state.users.byIds[state.users.currentUser!].permissions),
  );
  // Permission is not loaded
  if (!permissions) {
    return {
      permissions: permissions,
      isReady: Boolean(permissions),
    };
  }

  // if Permission is provided
  if (requiredPermissions) {
    return {
      hasPermission: CheckPermission(requiredPermissions, permissions),
      role: permissions,
      isReady: Boolean(permissions),
    };
  }

  // No permission is provided
  return {
    can: (requiredPermissions: iPermissionGuard | string[]) => {
      if (Array.isArray(requiredPermissions)) {
        requiredPermissions =
          convertStringPermissionToGuardObject(requiredPermissions);
      }
      return CheckPermission(requiredPermissions, permissions);
    },
    role: permissions,
    isReady: Boolean(permissions),
  };
};
