import { iArticleCollection } from "@sdk/knowledge-base/knowledge-base.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { articleCollectionDefaultQueryConfig } from "./article-collection-default-query";

export const {
  slice: ArticleCollectionsSlice,
  reducers: ArticleCollectionsSliceReducer,
  actions: {
    setEntityQueryResults: setArticleCollectionsQueryResults,
    setEntity: setArticleCollection,
    setEntities: setArticleCollections,
    patchEntity: patchArticleCollection,
    addNewEntity: addNewArticleCollection,
    setEntityFetchingStatus: setArticleCollectionFetchingStatus,
    setEntitiesFetchingStatus: setArticleCollectionsFetchingStatus,
    setEntityFailedStatus: setArticleCollectionFailedStatus,
    setEntityQueryError: setArticleCollectionsQueryError,
    resetQuery: resetArticleCollectionsQuery
  }
} = CreateEntitySlice<iArticleCollection>({
  sliceName: "articleCollections",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...articleCollectionDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
