import {
  iConnection,
  iEmailConnectionData,
  iFbConnectionData,
  iWACloudNativeConnectionData,
  iWAPrivateConnectionData,
} from "@sdk/conversations/conversations.models";
import { Select } from "antd";
import classNames from "classnames";
import { ConnectionIcons } from "components/modules/connections/helpers/connection-icons";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { loadAllConnections } from "store/modules/connections/connections.helpers";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const ConnectionSelector = ({
  value,
  onChange,
  connectionFilter,
  autoSelectFirstOption,
  allowClear,
  autoFocus,
}: {
  value?: string;
  onChange?: (connectionId: string) => any;
  connectionFilter?: (connection: iConnection) => boolean;
  autoSelectFirstOption?: boolean;
  allowClear?: boolean;
  autoFocus?: boolean;
}) => {
  const { state: connections, retry: reload } = useQueryWithStore(
    selectAllConnections,
    loadAllConnections,
  );

  const connectionList = useMemo(() => {
    if (connectionFilter) {
      return connections.filter(connectionFilter);
    }
    return connections;
  }, [connectionFilter, connections]);

  const getConnectionData = useMemo(() => {
    return (connection: iConnection) => {
      switch (connection.type) {
        case "EMAIL": {
          const emailAddress = (connection.data as iEmailConnectionData)
            .customDomain
            ? (connection.data as iEmailConnectionData).customDomain?.email
            : (connection.data as iEmailConnectionData).customSmtp
            ? ((connection.data as iEmailConnectionData).customSmtp.data as any)
                .email ||
              ((connection.data as iEmailConnectionData).customSmtp.data as any)
                .username
            : (connection.data as iEmailConnectionData).email;

          return emailAddress;
        }
        case "FACEBOOK": {
          return (connection.data as iFbConnectionData).page.name;
        }
        case "WEB_CHAT": {
          return "Chat Widget";
        }
        case "WHATS_APP_PRIVATE": {
          if (
            (connection.data as iWAPrivateConnectionData).waConnectionX
              ?.state === "CONNECTED"
          ) {
            return `Private WA Connection`;
          }
          return `Private WA Connection - NOT ACTIVE`;
        }
        case "TELNYX": {
          return "SMS Message";
        }
        case "WHATS_APP_CLOUD_NATIVE": {
          const connectionData =
            connection.data as iWACloudNativeConnectionData;

          return connectionData.phoneNumber || "";
        }
        default: {
          return "Todo";
        }
      }
    };
  }, []);

  useEffect(() => {
    // * Todo: Could properly rewrite this
    if (autoSelectFirstOption) {
      if (value) {
        if (!_.find(connectionList, { id: value })) {
          if (connectionList.length > 0) {
            onChange && onChange(connectionList[0].id!);
          }
          onChange && onChange(undefined!);
        }
      } else {
        if (connectionList.length > 0) {
          onChange && onChange(connectionList[0].id!);
        }
      }
    } else {
      if (value) {
        if (!_.find(connectionList, { id: value })) {
          onChange && onChange(undefined!);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSelectFirstOption, connectionList]);

  return (
    <Select
      showSearch
      value={value}
      placeholder="Select Connection"
      filterOption={false}
      onChange={onChange}
      style={{ width: "100%" }}
      allowClear={allowClear}
      defaultOpen={autoFocus}
      autoFocus={autoFocus}
    >
      {(connectionList || []).map((connection) => (
        <Select.Option key={connection.id} value={connection.id!}>
          <div className="flex flex-row items-center">
            <div className="icon-container mr-2">
              <i className={classNames(ConnectionIcons[connection.type])} />
            </div>
            <div className="label">{connection.label}</div>
            <div className="text-gray-600 dark:text-gray-400 text-sm pl-2 mode_transition">
              {getConnectionData(connection)}
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};
