import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import { iUserBetaFeatureConfig } from "@sdk/user-management/user-management.models";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectCurrentUserBetaFeatures,
} from "store/modules/users/users.selectors";
import { selectOrganizationBetaFeatures } from "store/modules/workspace/workspace.selectors";

export const useBetaFeatures = () => {
  const userConfig = useSelector(selectCurrentUserBetaFeatures);
  const orgConfig = useSelector(selectOrganizationBetaFeatures);

  const currentUser = useSelector(selectCurrentUser);

  const userRole = useMemo(() => {
    const currentUserRole = getRoleFromPermission(
      currentUser?.permissions || [],
    );
    return currentUserRole;
  }, [currentUser?.permissions]);

  const config = useMemo(() => {
    const features = [
      "QUOTE_CREATOR",
      "PIPELINES",
      "FORMS",
      "SEQUENCES",
      "EMAIL_CAMPAIGNS",
      "APP_DOWNLOAD",
      "TICKETS",
    ];
    // const role = toLower(userRole === "Owner" ? "Manager" : userRole);
    const role = userRole === "Owner" ? "Manager" : userRole;
    return features.reduce(
      (acc, feature) => ({
        ...acc,
        [feature]:
          (userConfig || {})?.[feature] || (orgConfig || {})?.[feature]?.[role],
      }),
      {} as Partial<iUserBetaFeatureConfig>,
    );
  }, [orgConfig, userConfig, userRole]);

  return config;
};
