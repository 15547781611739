import { SDK } from "@sdk";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StackTracey from "stacktracey";
import { selectUIState } from "store/modules/ui-state/ui-state.selectors";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import { UserTracker } from "user-tracker";
import { convertMillisecondsToReadableString } from "utils/convert-milliseconds-to-readable-string";
import { store } from "./store/store";

export class CustomErrorBoundary extends React.Component {
  state: {
    error: any;
    errorInfo: any;
  };

  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  async componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      // Error Fallback
      return (
        <ErrorFallback
          error={this.state.error}
          componentStack={this.state.errorInfo.componentStack}
        />
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export const ErrorFallback = ({ error, componentStack }) => {
  const [sendingLog, setSendingLog] = useState(false);
  const [errorSendingLog, setErrorSendingLog] = useState(false);

  useEffect(() => {
    const process = async () => {
      const prettyPrintedString = (
        await new StackTracey(error).cleanAsync()
      ).asTable(); // asynchronous version

      const knownErrors = [
        "ChunkLoadError",
        "Loading CSS chunk",
        "dynamically imported module:",
      ];
      const errorText = error.toString();

      if (knownErrors.some((v) => errorText?.includes(v))) {
        console.log("Known Error Ignored");
        return;
      }
      if (
        !window.location.href.includes("localhost") &&
        !window.location.href.includes("file://")
      ) {
        const currentUser = selectCurrentUser(store.getState());
        setSendingLog(true);

        SDK.sendErrorLog({
          subject: "Error Log",
          data: {
            title: "Error Log",
            url: window.location.href,
            error: error.toString(),
            errorInfo: componentStack,
            stack: prettyPrintedString,
            organization: selectOrganization(store.getState())?.domain || "N/A",
            user:
              currentUser?.data?.firstName + " " + currentUser?.data?.lastName,
            userId: currentUser?.id,
            uiState: JSON.stringify(selectUIState(store.getState())),
            currentTime: new Date().toString(),
            nodeStartedTime: new Date(
              Number((window as any).appStartedTime),
            ).toString(),
            errorAfter: convertMillisecondsToReadableString(
              Date.now() - Number((window as any).appStartedTime),
            ),
          },
        })
          .then((d) => {
            setSendingLog(false);
          })
          .catch((e) => {
            setSendingLog(false);
            setErrorSendingLog(true);
          });
        UserTracker.track("App Crashed", {
          url: window.location.href,
          error: error.toString(),
          errorInfo: componentStack,
          stack: prettyPrintedString,
          currentTime: new Date().toString(),
          nodeStartedTime: new Date(
            Number((window as any).appStartedTime),
          ).toString(),
          errorAfter: convertMillisecondsToReadableString(
            Date.now() - Number((window as any).appStartedTime),
          ),
        });
      }
    };
    process().catch((e) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-8">
      <h2 className="text-3xl">Something went wrong.</h2>
      {sendingLog && <p>Sending logs to ClickConnector</p>}
      {errorSendingLog && <p>Error while sending logs to ClickConnector</p>}

      {!errorSendingLog && !sendingLog && (
        <p>Logs have been sent to ClickConnector</p>
      )}

      <details style={{ whiteSpace: "pre-wrap" }}>
        <div>{error.toString()}</div>
        <br />
        <div>{componentStack}</div>
      </details>
      <button
        onClick={() => {
          window.location.reload();
        }}
      >
        Reload Page
      </button>
    </div>
  );
};

export const GlobalErrorBoundary = ({ children }) => {
  const user = useSelector(selectCurrentUser);

  // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/user-feedback/
  // https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/
  return (
    <Sentry.ErrorBoundary
      showDialog={true}
      dialogOptions={{
        user: {
          name: user?.data?.firstName + " " + user?.data?.lastName,
          email: user?.credentials?.email,
        },
      }}
      fallback={(props) => <ErrorFallback {...props} />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
