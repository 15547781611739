import { find, last } from "lodash";
import { matchPath } from "react-router-dom";

export interface RouteRecord {
  path: string;
  component: any;
  label?: string;
  layout: any;
  requiredPermission?: string[];
  children?: RouteRecord[];
}
export function GenerateNormalizedRoutes(
  routes: RouteRecord[],
  basePath: string = "/"
) {
  const normalizedRoutes: RouteRecord[] = [];
  const fetchRoutes = (_routes: RouteRecord[], _basePath: string) => {
    for (const item of _routes) {
      if (item.children) {
        fetchRoutes(item.children, `${_basePath}${item.path}/`);
      }
      normalizedRoutes.push({
        path: `${_basePath}${item.path}`,
        label: item.label,
        component: item.component,
        requiredPermission: item.requiredPermission,
        layout: item.layout,
      });
    }
  };
  fetchRoutes(routes, basePath);
  return normalizedRoutes;
}

export interface RouteBreadCrumbItem {
  path: string;
  breadcrumbName: string;
}

export const GetRouteMeta = (routes: RouteRecord[], location: string) => {
  const matchedPath = find(routes, (path) =>
    matchPath(location, path)
  ) as RouteRecord;
  return matchedPath;
};

export function GenerateBreadCrumb(
  routes: RouteRecord[],
  locationPath: string,
  pathResolver?: { [key: string]: () => string }
): RouteBreadCrumbItem[] {
  const components = locationPath.split("/");
  const breadCrumbs = components
    .map((path, index) => {
      const parentPath = components.slice(0, index).join("/");
      const fullPath = `${parentPath}/${path}`;
      const matchedPath = GetRouteMeta(routes, fullPath);
      if (matchedPath) {
        let label = matchedPath.label;
        const pathSegmentId = last(matchedPath.path.split("/")) || "";
        if (pathSegmentId.indexOf(":") > -1 && pathResolver) {
          pathSegmentId.replace(":", "");
          label = `${label} - ${pathResolver[pathSegmentId]()}`;
        }
        return {
          path: fullPath,
          breadcrumbName: label,
        };
      }
      return null;
    })
    .filter((e) => e);
  return breadCrumbs as RouteBreadCrumbItem[];
}
