import { ConnectionSelector } from "../../connection-selector/connection-selector";
import { ConversationTagSelector } from "../../converation-tag-selector";
import { MessageFromSelector } from "./components/message-from-selector";
import { StandardTextInput } from "./components/standard-text-input";

import { Select } from "antd";
import { ConnectionLabel } from "components/modules/connections/connection-pill/connection-pill";
import { TicketTypeSelector } from "components/pages/tickets/components/ticket-type-selector/ticket-type-selector";
import dayjs from "dayjs";
import { MultiPhraseInput } from "./components/multi-phrase-input";
import { iAutomationRuleCondition } from "./models";

export const AllAutomationRuleConditionConfigurations: iAutomationRuleCondition[] =
  [
    //  Message related
    {
      label: "Message",
      key: "message_text",
      section: "Message",
      operators: [
        {
          key: "is",
          label: "is",
          help: "Enter a phrase to match against the message text",
          widget: StandardTextInput,
        },
        {
          key: "contains",
          label: "contains",
          help: "Enter a phrase to match against the message text. The phrase will be evaluated for for partial match",
          widget: StandardTextInput,
        },
      ],
    },
    {
      label: "Message Sender",
      key: "message_senderType",
      section: "Message",
      operators: [
        {
          key: "is",
          label: "is",
          help: "Enter a phrase to match against the message text",
          widget: MessageFromSelector,
        },
      ],
    },
    // Page Visit
    {
      label: "Visited Page URL",
      section: "Context",
      key: "context_visitedUrl",
      operators: [
        {
          key: "is",
          label: "is",
          help: "Enter a phrase to match against the message text",
          widget: StandardTextInput,
        },
        {
          key: "contains",
          label: "contains",
          help: "Enter a phrase to match against the message text. The phrase will be evaluated for for partial match",
          widget: StandardTextInput,
        },
      ],
    },

    // Session Related
    {
      label: "Session Landed Page URL",
      section: "Session",
      key: "session_landedPage",
      operators: [
        {
          key: "is",
          label: "is",
          help: "Enter a phrase to match against the message text",
          widget: StandardTextInput,
        },
        {
          key: "contains",
          label: "contains",
          help: "Enter a phrase to match against the message text. The phrase will be evaluated for for partial match",
          widget: StandardTextInput,
        },
      ],
    },
    {
      label: "Session Referer URL",
      section: "Session",
      key: "session_referer",
      operators: [
        {
          key: "is",
          label: "is",
          help: "Enter a phrase to match against the message text",
          widget: StandardTextInput,
        },
        {
          key: "contains",
          label: "contains",
          help: "Enter a phrase to match against the message text. The phrase will be evaluated for for partial match",
          widget: StandardTextInput,
        },
      ],
    },
    {
      label: "Session",
      section: "Session",
      key: "session_verification",
      operators: [
        {
          key: "is",
          label: "is",
          help: "Enter a phrase to match against the message text",
          // Todo: verified not verified
          widget: StandardTextInput,
        },
      ],
    },
    {
      label: "Session country",
      section: "Session",
      key: "session_country",
      operators: [
        {
          key: "is",
          label: "is",
          help: "Enter a phrase to match against the message text",
          // Todo: country selector
          widget: StandardTextInput,
        },
      ],
    },
    // Conversation Related
    {
      label: "Conversation Tags",
      section: "Conversation",
      key: "conversation_tags",
      operators: [
        {
          key: "has",
          label: "includes",
          widget: ConversationTagSelector,
        },
      ],
    },
    {
      label: "Conversation Subject",
      section: "Conversation",
      key: "conversation_subject",
      operators: [
        {
          key: "is",
          label: "is",
          help: "Enter a phrase to match against the subject text",
          widget: StandardTextInput,
        },
        {
          key: "contains",
          label: "contains",
          help: "Enter a phrase to match against the subject text. The phrase will be evaluated for for partial match",
          widget: StandardTextInput,
        },
      ],
    },
    {
      label: "Connection",
      section: "Conversation",
      key: "conversation_connection",
      operators: [
        {
          key: "is",
          label: "is",
          widget: ConnectionSelector,
          labelRender: (connectionId) => (
            <ConnectionLabel connectionId={connectionId} />
          ),
        },
      ],
    },
    // Todo: Conversation Started Page, Status, Subject
    // Contact Related
    {
      label: "Customer Tags",
      section: "Customer",
      key: "contact_tags",
      operators: [
        {
          key: "has",
          label: "has",
          widget: ConversationTagSelector,
        },
      ],
    },
    {
      label: "Customer Email",
      section: "Customer",
      key: "contact_email",
      operators: [
        {
          key: "is",
          label: "is",
          // Todo: Available, not available
          widget: ConversationTagSelector,
        },
      ],
    },
    {
      label: "Customer record created date",
      section: "Customer",
      key: "contact_createdDate",
      operators: [
        {
          key: "is_before",
          label: "is before",
          // Todo
          widget: ConversationTagSelector,
          labelRender: (timestamp) => (
            <>{dayjs(timestamp).format("LL h:mm:ss a ")}</>
          ),
        },
        {
          key: "is_after",
          label: "is after",
          // Todo
          widget: ConversationTagSelector,
          labelRender: (timestamp) => (
            <>{dayjs(timestamp).format("LL h:mm:ss a ")}</>
          ),
        },
      ],
    },
    // Ticket Related
    {
      label: "Ticket Tags",
      section: "Ticket",
      key: "ticket_tags",
      operators: [
        {
          key: "has",
          label: "has",
          widget: (props) => (
            <Select
              {...props}
              mode="tags"
              placeholder="Add tags"
              dropdownStyle={{ display: "none" }}
            />
          ),
        },
      ],
    },
    {
      label: "Ticket Type",
      section: "Ticket",
      key: "ticket_ticketTypeId",
      operators: [
        {
          key: "is",
          label: "is",
          widget: TicketTypeSelector,
        },
      ],
    },
    //* Could Add Ticket Stage with only default values
    // {
    //   label: "Ticket Stage",
    //   section: "Ticket",
    //   key: "ticket_ticketStageId",
    //   operators: [
    //     {
    //       key: "is",
    //       label: "is",
    //       // Todo: Available, not available
    //       widget: ConversationTagSelector,
    //     },
    //   ],
    // },
    {
      label: "Ticket Description",
      section: "Ticket",
      key: "ticket_body",
      operators: [
        {
          key: "is",
          label: "is",
          help: "Enter a phrase to match against the Ticket description",
          widget: StandardTextInput,
        },
        {
          key: "contains",
          label: "contains",
          help: "Enter a phrase to match against the Ticket description. The phrase will be evaluated for for partial match",
          widget: StandardTextInput,
        },
      ],
    },
    {
      label: "Ticket Label",
      section: "Ticket",
      key: "ticket_label",
      operators: [
        {
          key: "is",
          label: "is",
          help: "Enter a phrase to match against the Ticket Label",
          widget: StandardTextInput,
        },
        {
          key: "contains",
          label: "contains",
          help: "Enter a phrase to match against the Ticket Label. The phrase will be evaluated for for partial match",
          widget: StandardTextInput,
        },
      ],
    },
    // Fb Comments Related
    {
      label: "Connection",
      section: "FB Comments",
      key: "fbComment_connectionId",
      operators: [
        {
          key: "is",
          label: "is",
          widget: ConnectionSelector,
          labelRender: (connectionId) => (
            <ConnectionLabel connectionId={connectionId} />
          ),
        },
      ],
    },
    {
      label: "Post ID",
      section: "FB Comments",
      key: "fbComment_postId",
      operators: [
        {
          key: "is",
          label: "is",
          help: "Enter a phrase to match against the post ID",
          widget: StandardTextInput,
        },
        {
          key: "contains",
          label: "contains",
          help: "Enter a phrase to match against the post ID. The phrase will be evaluated for for partial match",
          widget: StandardTextInput,
        },
      ],
    },
    {
      label: "Comment Content",
      section: "FB Comments",
      key: "fbComment_message",
      operators: [
        {
          key: "is",
          label: "is",
          help: "Enter a phrase to match against the comment text",
          widget: StandardTextInput,
        },
        {
          key: "contains",
          label: "contains",
          help: "Enter a phrase to match against the comment text. The phrase will be evaluated for for partial match",
          widget: StandardTextInput,
        },
        {
          key: "contains_any_of",
          label: "contains phrases",
          help: "Enter a few phrases to match against the comment text. The phrase will be evaluated for for partial match",
          widget: MultiPhraseInput,
        },
      ],
    },
  ];
