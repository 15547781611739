import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectSubscriptionDetails } from "store/modules/workspace/workspace.selectors";
import { APPSUMO_PLANS, NewPlans } from "./pricing-plans-data";

export const useSubscriptionDetails = () => {
  const subscriptionDetails = useSelector(selectSubscriptionDetails);

  const planId = subscriptionDetails?.planId || "V3_BASIC_MONTHLY";

  const isSubscribed = !(
    !subscriptionDetails ||
    !subscriptionDetails.planId ||
    subscriptionDetails.planId === "STARTUP_MONTHLY" ||
    subscriptionDetails.planId === "STARTUP_YEARLY" ||
    subscriptionDetails.planId === "FREE" ||
    subscriptionDetails.planId === "V3_BASIC_MONTHLY"
  );

  const plan = useMemo(() => {
    const id = planId.replace(`_YEARLY`, "").replace(`_MONTHLY`, "");
    const plan = _.find(
      [
        ...APPSUMO_PLANS,
        // ...STARTUP_PRICING,
        // ...TEAM_PRICING,
        // ...BUSINESS_PRICING,
        ...NewPlans,
      ],
      {
        id,
      },
    );
    return plan;
  }, [planId]);

  return {
    isSubscribed,
    subscriptionDetails,
    isYearly: planId?.includes("_YEARLY"),
    plan: plan,
  };
};
