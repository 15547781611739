export const OnVisibilityChange = (listener: () => any) => {
  let visibilityChange: string;
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    visibilityChange = "visibilitychange";
  } else if (typeof (document as any).msHidden !== "undefined") {
    visibilityChange = "msvisibilitychange";
  } else if (typeof (document as any).webkitHidden !== "undefined") {
    visibilityChange = "webkitvisibilitychange";
  }
  window.addEventListener(visibilityChange!, listener);
  return () => {
    window.removeEventListener(visibilityChange!, listener);
  };
};

export const GetWindowVisibility = () => {
  let hidden: string;
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden";
  } else if (typeof (document as any).msHidden !== "undefined") {
    hidden = "msHidden";
  } else if (typeof (document as any).webkitHidden !== "undefined") {
    hidden = "webkitHidden";
  }
  return !document[hidden!] as boolean;
};

export const GetWindowFocus = () => document.hasFocus();
