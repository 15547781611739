import { axios, AxiosResponse } from "@sdk/axios";
import { iCustomDomain } from "./sending-domains-models";

import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import { UserTracker } from "user-tracker";

export class SendingDomainsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.sendingDomains.configure(config);
  }

  sendingDomains = Object.assign(
    new EntityServices<iCustomDomain>(SDKConfig, "sending-domains", {
      onCreate: () => {
        UserTracker.track("SENDING_DOMAINS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("SENDING_DOMAINS - Delete", {});
      },
    }),
    {
      verifyCustomDomain: async (domainId: string) => {
        UserTracker.track("SENDING_DOMAINS - Verify Custom Domain", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/sending-domains/${domainId}/verify`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      activateTrackingOfEmails: async (domainId: string) => {
        UserTracker.track("SENDING_DOMAINS - Activate Tracking", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath +
            `/sending-domains/${domainId}/activate-tracking`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      verifyDomainAvailability: async (domain: string) => {
        UserTracker.track("SENDING_DOMAINS - Verify Domain Availability", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/sending-domains/verify-domain-availability`,
          { domain },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
