import { SDK } from "@sdk";
import { SocketConnector } from "@sdk/@libs/socket-connector";
import { message, Tag } from "antd";
import { push } from "connected-react-router";
import { useCallback, useEffect, useState } from "react";
import { useSelector, useStore } from "react-redux";

import { setIsAdvancedMode } from "store/modules/ui-state/ui-state.actions";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import classNames from "utils/classnames";

export const Logo = () => {
  const store = useStore();
  const sendDebugData = useCallback(() => {
    const currentUser = selectCurrentUser(store.getState());
    SDK.sendErrorLog({
      subject: "Debug Data",
      data: {
        title: "Debug Data",
        url: window.location.href,
        connectionLogs: JSON.stringify(SocketConnector.connectionLogs),
        organization: selectOrganization(store.getState())?.domain || "N/A",
        user: currentUser?.data?.firstName + " " + currentUser?.data?.lastName,
        userId: currentUser?.id,
      },
    }).catch((e) => {
      message.error("Error while sending debug data");
    });
    message.info("Sending debug data");
  }, [store]);

  const [logoClickedCount, setLogoClickedCount] = useState(0);

  const onLogoClicked = useCallback(() => {
    if (logoClickedCount === 10) {
      setLogoClickedCount(0);
      sendDebugData();
    } else {
      setLogoClickedCount(logoClickedCount + 1);
    }
  }, [logoClickedCount, sendDebugData]);

  const isDevMode = useSelector(selectIsAdvancedMode);

  const toggleDevMode = useCallback(() => {
    store.dispatch(setIsAdvancedMode(!isDevMode));
    message.info(isDevMode ? "Exit Dev Mode" : "Enter Dev Mode");
  }, [store, isDevMode]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLogoClickedCount(0);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [logoClickedCount]);

  const isHomeActive = `/${location.pathname.split("/")[1]}` === "/home";

  return (
    <div className="flex flex-col">
      <div
        className={classNames(
          "bg-white rounded-lg m-2 w-10 h-10 cursor-pointer flex flex-col justify-center items-center",
          {
            "active-menu-item bg-blue-100": isHomeActive,
          },
        )}
        id="MAIN_MENU_/home"
        onClick={() => {
          store.dispatch(push("/home"));
          onLogoClicked();
        }}
      >
        {isHomeActive ? (
          <img
            src="/cc-assets/logo.png"
            alt="ClickConnector"
            onClick={onLogoClicked}
            // className="bg-white rounded-lg m-2"
          />
        ) : (
          <i className="ri-home-2-fill text-2xl text-gray-900"></i>
        )}
      </div>

      {(logoClickedCount === 9 || isDevMode) && (
        <div
          // style={{ width: 80 }}
          className="flex flex-row justify-center items-center cursor-pointer"
        >
          <Tag onClick={toggleDevMode} className="mr-0">
            Dev
          </Tag>
        </div>
      )}
    </div>
  );
};
