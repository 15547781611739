import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const EditContactModal = React.lazy(() => import("./edit-contact.modal"));

const EditContact: iModalProps<typeof EditContactModal, { contactId: string }> =
  {
    name: "edit-contact",
    component: GenerateModalWithSuspense(EditContactModal),
  };

export default EditContact;
