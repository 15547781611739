import { Store } from "redux";
import { SDK } from "../../../@sdk/sdk";
import { setCredits, setOrganization } from "./workspace.actions";

export const loadOrganization = async (store: Store) => {
  const res = await SDK.getCurrentOrganization();
  store.dispatch(setOrganization(res));
};

export const loadCredits = async (store: Store) => {
  const res = await SDK.getCredits();
  store.dispatch(setCredits(res));
};
