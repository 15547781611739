import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ConfigureTourModal = React.lazy(() => import("./configure-tour.modal"));

const ConfigureTour: iModalProps<
  typeof ConfigureTourModal,
  {
    tourId: string;
  }
> = {
  name: "configure-tour",
  component: GenerateModalWithSuspense(ConfigureTourModal),
};

export default ConfigureTour;
