import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ChatbotResponsesExplorerModal = React.lazy(
  () => import("./chat-bot-responses-explorer.modal"),
);

const ChatbotResponsesExplorer: iModalProps<
  typeof ChatbotResponsesExplorerModal,
  { chatBotId: string }
> = {
  name: "chat-bot-explore-responses",
  component: GenerateModalWithSuspense(ChatbotResponsesExplorerModal),
};

export default ChatbotResponsesExplorer;
