import { Menu } from "antd";
import { useBetaFeatures } from "components/pages/conversations/use-beta-features";
import React, { useMemo } from "react";
import { iMenuItem } from "./menu-item-mode";
import "./side-bar-menu.scss";

export const SideBarMenu = ({
  onMenuClick,
  menuItems: _menuItems,
  selectedKeys,
}: {
  onMenuClick: (item: iMenuItem) => any;
  menuItems: iMenuItem[];
  selectedKeys: string | string[];
}) => {
  const betaFeatures = useBetaFeatures();
  const menuItems = useMemo(() => {
    return _menuItems.filter((item) => {
      if (item.type === "LINK_ITEM" && item.isBeta) {
        return betaFeatures[item.betaKey!];
      }
      return true;
    });
  }, [_menuItems, betaFeatures]);
  return (
    <Menu
      className="flex-1 side-bar-menu"
      selectedKeys={
        selectedKeys
          ? Array.isArray(selectedKeys)
            ? selectedKeys
            : [selectedKeys]
          : []
      }
      mode="inline"
    >
      {menuItems.map((item) => {
        if (item.type === "CUSTOM_SECTION") {
          return <React.Fragment key={item.key}>{item.element}</React.Fragment>;
        }
        return (
          <Menu.Item
            icon={item.icon}
            key={item.key}
            onClick={() => onMenuClick(item)}
            className="font-bold ml-0"
          >
            {item.label}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};
