import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";
import { iEmailSeriesIdea } from "../helpers/generate-email-series-data";

const EmailSeriesTemplatePreviewModal = React.lazy(
  () => import("./email-series-template-preview.modal"),
);

const EmailSeriesTemplatePreview: iModalProps<
  typeof EmailSeriesTemplatePreviewModal,
  {
    template: iEmailSeriesIdea;
    changeParentModalVisibility?: (state: boolean) => any;
  }
> = {
  name: "email-series-template-preview",
  component: GenerateModalWithSuspense(EmailSeriesTemplatePreviewModal),
};

export default EmailSeriesTemplatePreview;
