import { iNewsFeedItem } from "@sdk/newsfeed-items/newsfeed-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { newsFeedItemsDefaultQueryConfig } from "./newsfeed-items-default-query";

export const {
  slice: NewsFeedItemsSlice,
  reducers: NewsFeedItemsSliceReducer,
  actions: {
    setEntityQueryResults: setNewsFeedItemsQueryResults,
    setEntity: setNewsFeedItem,
    setEntities: setNewsFeedItems,
    patchEntity: patchNewsFeedItem,
    addNewEntity: addNewNewsFeedItem,
    setEntityFetchingStatus: setNewsFeedItemFetchingStatus,
    setEntitiesFetchingStatus: setNewsFeedItemsFetchingStatus,
    setEntityFailedStatus: setNewsFeedItemFailedStatus,
    setEntityQueryError: setNewsFeedItemsQueryError,
    resetQuery: resetNewsFeedItemsQuery,
    setQueryList: setNewsFeedItemQueryList,
  },
} = CreateEntitySlice<iNewsFeedItem>({
  sliceName: "newsFeedItems",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...newsFeedItemsDefaultQueryConfig,
        alias: "all",
      };
    }
  },
});
