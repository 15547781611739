export const isEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const commonEmailDomains = [
  "@gmail",
  "@hotmail",
  "@yahoo",
  "@aol.com",
  "@outlook",
];

export function isWorkEmail(email: string): boolean {
  if (isEmail(email)) {
    if (commonEmailDomains.some((v) => email.includes(v))) {
      return false;
    }
    return true;
  }
  return false;
}
