import { iContact } from "@sdk/crm/crm.models";

export const GetContactIdentifier = (contact: iContact) => {
  if (!contact) {
    return "N/A";
  }
  if (contact.data.primaryEmail) {
    return contact.data.primaryEmail;
  }
  if (contact.data.primaryMobile) {
    return contact.data.primaryMobile;
  }
  if (contact.data) {
    return `No emails / mobile numbers`;
  }
};
