import { TourStepProps } from "antd";
import { Store } from "redux";
import { selectConversationsMap } from "store/modules/conversations/conversations.selectors";
import { SingleColumnTour, TwoColumnTour } from "./tour-modals";

export const GenerateInboxTour = (store: Store) => {
  const conversationMap = selectConversationsMap(store.getState());
  const sampleConversation = Object.keys(conversationMap)[1];

  const steps: TourStepProps[] = [
    {
      title: <></>,
      description: (
        <SingleColumnTour
          title="Welcome"
          illustration="/assets/tours/inbox/welcome.svg"
        >
          Welcome to your inbox! You can accept new chats, follow up and keep
          track of all your conversations from here. Let’s show you around!
        </SingleColumnTour>
      ),
      placement: "center"
    },
    {
      title: <></>,
      description: (
        <TwoColumnTour
          title="Inboxes"
          sectionName="Panel 1"
          illustration="/assets/tours/inbox/inbox.svg"
        >
          The first panel shows you all your inboxes. <br />
          <br />
          We have some pre-made inboxes that are helpful for business here.
          <br />
          <br />
          You can also create a custom inbox for your personalized needs at any
          point.
        </TwoColumnTour>
      ),
      placement: "right",
      target: () => document.getElementById("inbox-section")!
    },
    {
      title: <></>,
      description: (
        <TwoColumnTour
          title="Unassigned"
          sectionName="Panel 1"
          illustration="/assets/tours/inbox/unassigned.svg"
        >
          The “Unassigned” Inbox shows you all the conversations that haven’t
          been assigned to someone.
          <br />
          <br />
          Unassigned acts like a pool of leads anyone can pick from and convert.
        </TwoColumnTour>
      ),
      placement: "right",
      target: () => document.getElementById("INBOX_ITEM_DEFAULT_UNASSIGNED")!
    },
    {
      title: <></>,
      description: (
        <TwoColumnTour
          title="My Inbox"
          sectionName="Panel 1"
          illustration="/assets/tours/inbox/inbox.svg"
        >
          When a new conversation comes in and you accept it, or someone in your
          team assigns it to you, it goes into “My Inbox”
        </TwoColumnTour>
      ),
      placement: "right",
      target: () => document.getElementById("INBOX_ITEM_DEFAULT_MY")!
    },
    {
      title: <></>,
      description: (
        <TwoColumnTour
          title="All Assigned"
          sectionName="Panel 1"
          illustration="/assets/tours/inbox/all-assigned.svg"
        >
          The “All Assigned” Inbox shows you all the conversations that have
          been assigned to you or someone else in your team.
        </TwoColumnTour>
      ),
      placement: "right",
      target: () => document.getElementById("INBOX_ITEM_DEFAULT_TEAM")!
    },
    {
      title: <></>,
      description: (
        <TwoColumnTour
          title="Awaiting Reply"
          sectionName="Panel 1"
          illustration="/assets/tours/inbox/awaiting-reply.svg"
        >
          Awaiting Reply” shows the conversation where the last message has been
          sent by the lead, meaning that the ball is on your court!
          <br />
          <br />A good rule of thumb is to keep the number of conversations in
          this inbox as close to 0 as possible.
        </TwoColumnTour>
      ),
      placement: "right",
      target: () => document.getElementById("INBOX_ITEM_DEFAULT_ATTENTION")!
    },
    // {
    //   title: <></>,
    //   description: (
    //     <TwoColumnTour
    //       title="By Channel & Tag"
    //       sectionName="Panel 1"
    //       illustration="/assets/tours/inbox/tags-and-channel.svg"
    //     >
    //       Additionally, we have inboxes by channel and by tag that helps access
    //       specific conversations faster.
    //     </TwoColumnTour>
    //   ),
    //   target: () => document.getElementById("inbox-section")!,
    // },
    {
      title: <></>,
      description: (
        <TwoColumnTour
          title="Conversation List"
          sectionName="Panel 2"
          illustration="/assets/tours/inbox/conversation-list.svg"
        >
          The second panel shows you the ongoing conversations in the inbox
          you’ve selected and a search and filter feature to help you find a
          specific conversation.
        </TwoColumnTour>
      ),
      placement: "right",
      target: () => document.getElementById("conversation-list-container")!
    },
    {
      title: <></>,
      description: (
        <TwoColumnTour
          title="Search & Filters"
          sectionName="Panel 2"
          illustration="/assets/tours/inbox/filters.svg"
        >
          You can use many different filters to find a set of common
          conversations you want to find, or simply search a customer’s name,
          email address, phone number or agent’s name to find what you need.
        </TwoColumnTour>
      ),
      placement: "right",
      target: () => document.getElementById("chat-list-header")!
    },
    {
      title: <></>,
      description: (
        <TwoColumnTour
          title="Bulk Actions"
          sectionName="Panel 2"
          illustration="/assets/tours/inbox/bulk-actions.svg"
        >
          You can also perform bulk actions by clicking the conversation counter
          between the search feature and the conversation list.
        </TwoColumnTour>
      ),
      placement: "right",
      target: () => document.getElementById("chat-bulk-action-menu")!
    },
    {
      title: <></>,
      description: (
        <TwoColumnTour
          title="Messaging"
          sectionName="Panel 3"
          illustration={
            sampleConversation
              ? undefined
              : "/assets/tours/inbox/messaging-view.svg"
          }
        >
          The Third Panel shows you the messages being sent back and forth in
          the conversation you’ve selected.
          <br />
          <br />
          This is where the magic happens.
        </TwoColumnTour>
      ),
      placement: sampleConversation ? "left" : "center",
      target: () => document.getElementById("chat-view-container")!
    },
    ...(sampleConversation
      ? ([
          {
            title: <></>,
            description: (
              <TwoColumnTour
                title="Chat Actions"
                sectionName="Panel 3"
                illustration="/assets/tours/inbox/quick-actions.svg"
              >
                You can tag customers, assign and remove agents, among other
                things.
                <br />
                <br />
                Don’t worry, we’ll send you helpful tutorials about getting
                familiar with this panel.
              </TwoColumnTour>
            ),
            placement: "bottom",
            target: () => document.getElementById("chat-actions-bar")!
          },
          {
            title: <></>,
            description: (
              <TwoColumnTour
                title="Assistant"
                sectionName="Panel 4"
                // illustration="/assets/tours/inbox/welcome.svg"
              >
                the assistant panel gives you helpful information like customer
                information, a lead’s activity on your website, any past
                conversations you’ve had, any scheduled events or reminders,
                among other things.
                <br />
                <br /> <br />
                <br />
                You can customize what you see here, or collapse the panel when
                you’re not using it for a cleaner user experience.
              </TwoColumnTour>
            ),
            placement: "left",
            target: () => document.getElementById("chat-side-bar")!
          }
        ] as TourStepProps[])
      : [])
  ];
  return steps;
};
