import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddWACloudConnectionModal = React.lazy(
  () => import("./add-whatsapp-cloud-connection.modal"),
);

const AddWACloudConnection: iModalProps<typeof AddWACloudConnectionModal> = {
  name: "add-wa-cloud-connection",
  component: GenerateModalWithSuspense(AddWACloudConnectionModal),
};

export default AddWACloudConnection;
