import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Space } from "antd";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { useSelector } from "react-redux";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";

export const DemoAccountActions = () => {
  const organization = useSelector(selectOrganization);

  const {
    doAction: resetDemo,
    isProcessing: isResetting
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => (organizationId: string) => SDK.resetDemo(),
      successMessage: "Done",
      failureMessage: "Something went wrong"
    }),
    []
  );

  const { doAction: seedDemo, isProcessing: isSeeding } = useSDKActionWithDeps(
    () => ({
      action: SDK => (organizationId: string) => SDK.seedDemo(),
      successMessage: "Done",
      failureMessage: "Something went wrong"
    }),
    []
  );

  if (organization?.id.includes("demo")) {
    return (
      <SimpleCardSection className="flex-row flex justify-between m-0 mb-8 border-dashed">
        <div className="label text-gray-600 dark:text-gray-400 text-sm mode_transition text-center font-bold">
          Demo Account
        </div>

        <Space>
          <Button type="dashed" onClick={resetDemo} loading={isResetting}>
            Reset Demo
          </Button>
          <Button type="dashed" onClick={seedDemo} loading={isSeeding}>
            Seed Demo
          </Button>
        </Space>
      </SimpleCardSection>
    );
  }

  return <></>;
};
