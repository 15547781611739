import { Button, Collapse, Divider } from "antd";
import {
  CodeBlockWithCopyButton,
  CommandLineCodeBlockWithCopyIcon,
} from "components/common/code-blocks-with-copy-button";
import { ChatBotSelector } from "components/pages/chat-bots/chat-bot-selector/chat-bot-selector";
import { ProductTourSelector } from "components/pages/product-tours/product-tour-selector/product-tour-selector";
import { useState } from "react";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectChatWidgetByConnectionId } from "store/modules/chat-widgets/chat-widgets.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const EmbedWidgetSetup = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const { state: widget, retry: reload } = useQueryWithStore(
    selectChatWidgetByConnectionId(connectionId),
    loadAllChatWidgets(),
    [connectionId],
  );

  const [selectedProductTour, setSelectedProductTour] =
    useState("PRODUCT_TOUR_ID");

  const [selectedChatbotFlowId, setSelectedChatbotFlowId] =
    useState("CHAT_BOT_ID");

  return (
    <>
      <ol>
        <li>
          <span>
            1. Install the Widget Library{" "}
            <Button
              type="text"
              icon={<i className="ri-external-link-line"></i>}
              size="small"
              href="https://www.npmjs.com/package/@clickconnector/widget-sdk"
              target="_blank"
            ></Button>{" "}
            <div className="mt-2 mx-4">
              <CommandLineCodeBlockWithCopyIcon className="w-full">
                {`npm i @clickconnector/widget-sdk`}
              </CommandLineCodeBlockWithCopyIcon>
            </div>
          </span>
        </li>
        <li className="mt-2">
          2. In main.js or index.js
          <div className="mt-2 mx-4">
            <CodeBlockWithCopyButton className="w-full" language="JS">
              {`import { ChatWidget } from "@clickconnector/widget-sdk";

...

ChatWidget.load("${widget?.id}");

              `}
            </CodeBlockWithCopyButton>
          </div>
        </li>
      </ol>
      <Divider>Recommended Setup</Divider>
      <div className="p-4">
        <Collapse accordion>
          <Collapse.Panel
            header={"When Logged in / Registered"}
            key={"When Logged in / Registered"}
          >
            <CodeBlockWithCopyButton className="mt-2" language={"JS"}>
              {`ChatWidget.identify({
  id: "123", // Unique ID from your system
  firstName: "Thomas",
  primaryEmail: "thomas@gmail.com"
});`}
            </CodeBlockWithCopyButton>
          </Collapse.Panel>
          <Collapse.Panel
            header={"After success sign up to your product"}
            key={"After success sign up to your product"}
          >
            <CodeBlockWithCopyButton className="mt-2" language={"JS"}>
              {`ChatWidget.logActivity({
  eventName: 'signup',
});`}
            </CodeBlockWithCopyButton>
          </Collapse.Panel>
          <Collapse.Panel header={"When Logged out"} key={"When Logged out"}>
            <CodeBlockWithCopyButton className="mt-2" language={"JS"}>
              {`ChatWidget.resetSession();`}
            </CodeBlockWithCopyButton>
          </Collapse.Panel>
        </Collapse>
      </div>
      <Divider>Additional Functions</Divider>
      <div className="p-4">
        <Collapse accordion>
          <Collapse.Panel
            header={"To set User details"}
            key={"To set User details"}
          >
            <CodeBlockWithCopyButton className="mt-2" language={"JS"}>
              {`ChatWidget.setUser({
  name: "Thomas",
  email: "thomas@gmail.com",
  phone: "1234567890",
});`}
            </CodeBlockWithCopyButton>
          </Collapse.Panel>
          <Collapse.Panel
            header={"Show a KB Article"}
            key={"Show a KB Article"}
          >
            <div className="">
              Finding Article ID: Navigate to help center {">"} select the
              collection {">"} select article {">"} Click on{" "}
              <i className="ri-more-2-line"></i> and Click Copy Article Id
            </div>
            <CodeBlockWithCopyButton className="mt-2" language={"JS"}>
              {`ChatWidget.navigateToArticle('ARTICLE_ID');`}
            </CodeBlockWithCopyButton>
          </Collapse.Panel>
          <Collapse.Panel
            header={"Show Active Checklist"}
            key={"Show Active Checklist"}
          >
            <CodeBlockWithCopyButton className="mt-2" language={"JS"}>
              {`ChatWidget.showActiveChecklist();`}
            </CodeBlockWithCopyButton>
          </Collapse.Panel>
          <Collapse.Panel
            header={"Start a Product Tour"}
            key={"Start a Product Tour"}
          >
            <div className="flex flex-row items-center gap-4">
              Product Tour:
              <ProductTourSelector
                value={selectedProductTour}
                onChange={setSelectedProductTour}
              />
            </div>
            <CodeBlockWithCopyButton className="mt-2" language={"JS"}>
              {`ChatWidget.startTour("${selectedProductTour}");`}
            </CodeBlockWithCopyButton>
          </Collapse.Panel>
          <Collapse.Panel
            header={"Show a simple invite message"}
            key={"Show a simple invite message"}
          >
            <CodeBlockWithCopyButton className="mt-2" language={"JS"}>
              {`ChatWidget.triggerCampaign({
  message: "Hi, how can we help you?",
});`}
            </CodeBlockWithCopyButton>
          </Collapse.Panel>
          <Collapse.Panel
            header={"Start a chatbot flow"}
            key={"Start a chatbot flow"}
          >
            <div className="flex flex-row items-center gap-4">
              Chatbot Flow:
              <ChatBotSelector
                value={selectedChatbotFlowId}
                onChange={setSelectedChatbotFlowId}
              />
            </div>

            <CodeBlockWithCopyButton className="mt-2" language={"JS"}>
              {`ChatWidget.triggerCampaign({
  chatBotId: "${selectedChatbotFlowId}",
});`}
            </CodeBlockWithCopyButton>
          </Collapse.Panel>
        </Collapse>
      </div>

      <div className="mt-4">
        <span className="font-semibold">Widget APIS</span> <br />
        Visit{" "}
        <a
          href="https://www.npmjs.com/package/@clickconnector/widget-sdk"
          target="_blank"
          rel="noreferrer"
        >
          https://www.npmjs.com/package/@clickconnector/widget-sdk
        </a>
        {} to see all available APIs
      </div>
    </>
  );
};
