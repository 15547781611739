import { SDKConfig } from "@sdk/default-sdk-config";
import { iLocation } from "@sdk/sessions/sessions.models";
import { EntityServices } from "@sdk/utils/entity.service";
import axios, { AxiosResponse } from "axios";
import { iCallBackRequest } from "./call-back-requests-model";

export class CallBackRequestsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.callBackRequests.configure(config);
  }

  callBackRequests = Object.assign(
    new EntityServices<iCallBackRequest>(SDKConfig, "call-back-requests"),
    {
      sendScriptToDeveloper: async (connectionId: string, email: string) => {
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath +
            `/call-back-requests/${connectionId}/email-to-developer`,
          {
            email,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      resolveIP: async () => {
        const results: AxiosResponse<iLocation> = await axios.post(
          this.config.basePath + `/call-back-requests/resolve-ip`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
