import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import {
  iConnectionConfigurationData,
  iConnectionUserLimit,
} from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Space, Table, Tag } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { HelpCenterPromptButton } from "components/common/help-center-legacy/embeddable-components/help-center-prompt-button";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { ConnectionLimitEditorModal } from "./connection-limit-editor-modal/connection-limit-editor-modal";

const ConnectionLimitTypeLabels = {
  CONVERSATIONS_JOIN: "New Conversation Joins",
  PRIVATE_REPLY: "New Comment Private Replies",
};

const ConnectionLimitTargetLabels = {
  USER: "User",
  USER_GROUP: "User",
  ALL: "All",
};

const ConnectionLimitPeriodLabels = {
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
};

export const ConnectionLimits = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const connection = useSelector(selectConnectionById(connectionId));

  const limits = (connection?.data as iConnectionConfigurationData)?.limits;

  const [state, setState] = useState(limits || []);

  useEffect(() => {
    setState(limits || []);
  }, [limits]);

  const columns = [
    {
      title: "Type",
      dataIndex: "limitType",
      render: (text) => `Limit Type: ${ConnectionLimitTypeLabels[text]}`,
    },
    {
      title: "Target",
      dataIndex: "targetType",
      render: (text, record: iConnectionUserLimit) =>
        `Applied to : ${ConnectionLimitTargetLabels[text]}`,
    },
    {
      title: "Period",
      dataIndex: "limitPeriod",
      render: (text, record: iConnectionUserLimit) =>
        `Period : ${ConnectionLimitPeriodLabels[text]}`,
    },
    {
      title: "Limit",
      dataIndex: "limit",
      render: (limit, record: iConnectionUserLimit) => <Tag>{limit} Chats</Tag>,
    },
    {
      title: "",
      dataIndex: "actions",
      render: (data, record, index) => (
        <Space>
          <Button
            icon={<i className="ri-pencil-line"></i>}
            type="link"
            onClick={() => {
              setLimitEditorState({
                mode: "EDIT",
                visibility: true,
                state: record,
              });
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            type="link"
            onClick={() => {
              setState(_.without(state, record));
            }}
          />
        </Space>
      ),
    },
  ];

  const {
    doAction: saveConnectionLimit,
    isProcessing,
    response,
    dispatch,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (limits: iConnectionUserLimit[]) =>
        SDK.connections.editById(connectionId, {
          data: {
            limits,
          },
        }),
      failureMessage: "Something went wrong",
    }),
    [connectionId],
  );

  useEffectWhen(
    () => {
      if (limits !== state) {
        saveConnectionLimit(state);
      }
    },
    [state, limits, saveConnectionLimit],
    [state],
  );

  const [limitEditorState, setLimitEditorState] = useSimpleState({
    visibility: false,
    mode: "ADD" as "ADD" | "EDIT",
    state: undefined as any,
  });

  return (
    <div className="flex flex-1 flex-col h-full chat-widget-install-instructions">
      <HelpCenterPromptButton
        type="BANNER"
        text={
          <div className="text-lg p-3">
            Video Guide: Introduction to User Limits 📺
          </div>
        }
        description=""
        icon="LEARN"
        articleId="fc89e0-9793b"
        className="my-4"
      />
      <div
        className="content flex-1 overflow-auto"
        style={{ maxHeight: 450, minHeight: 450 }}
      >
        <Table
          pagination={false}
          dataSource={state}
          columns={columns}
          showHeader={false}
          locale={{
            emptyText: (
              <EmptyData
                text="No limits have been set"
                icon="ri-git-commit-fill"
                className="w-full"
              />
            ),
          }}
          title={() => (
            <div className="flex flex-row justify-end items-center">
              <Button
                type="default"
                icon={<i className="ri-add-line"></i>}
                onClick={() => {
                  setLimitEditorState({
                    mode: "ADD",
                    visibility: true,
                    state: null,
                  });
                }}
                className="mt-2"
              >
                Add Limit
              </Button>
            </div>
          )}
        />
      </div>

      <ConnectionLimitEditorModal
        visible={limitEditorState.visibility}
        mode={limitEditorState.mode}
        initialValue={limitEditorState.state}
        onChangeVisibility={(visibility) => setLimitEditorState({ visibility })}
        onSaved={(limitConfig) => {
          // console.log("limitConfig", limitConfig);
          setLimitEditorState({ visibility: false });
          if (limitEditorState.mode === "ADD") {
            setState((state) => [...state, limitConfig]);
          } else {
            setState((state) =>
              state.map((item) => {
                if (item.id === limitConfig.id) {
                  return limitConfig;
                }
                return item;
              }),
            );
          }
        }}
      />
    </div>
  );
};
