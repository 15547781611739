import { iTestimonial } from "@sdk/testimonials/testimonials-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { testimonialsDefaultQueryConfig } from "./testimonials-default-query";

export const {
  slice: TestimonialsSlice,
  reducers: TestimonialsSliceReducer,
  actions: {
    setEntityQueryResults: setTestimonialsQueryResults,
    setEntity: setTestimonial,
    setEntities: setTestimonials,
    patchEntity: patchTestimonial,
    addNewEntity: addNewTestimonial,
    setEntityFetchingStatus: setTestimonialFetchingStatus,
    setEntitiesFetchingStatus: setTestimonialsFetchingStatus,
    setEntityFailedStatus: setTestimonialFailedStatus,
    setEntityQueryError: setTestimonialsQueryError,
    resetQuery: resetTestimonialsQuery,
    setQueryList: setTestimonialQueryList,
  },
} = CreateEntitySlice<iTestimonial>({
  sliceName: "testimonials",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...testimonialsDefaultQueryConfig,
        alias: "all",
      };
    }
  },
});
