import { iActivity } from "@sdk/crm/crm.models";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";
import { activityDefaultQueryConfig } from "./activities-default-query";

export const selectActivityContactId = (contactId: string) => {
  const queryConfig = {
    ...activityDefaultQueryConfig,
    query: {
      contactId: contactId
    }
  };
  return selectActivitiesQuery(JSON.stringify(queryConfig));
};

export const {
  selectQueryMap: selectActivityQueryMap,
  selectEntityMap: selectActivitiesMap,
  selectQueryMapAll: selectActivitiesMapAll,
  selectQueryAllList: selectActivitiesAllList,
  selectAllEntities: selectAllActivities,
  selectAllEntitiesQuery: selectAllActivitiesQuery,
  selectEntityById: selectActivityById,
  selectEntityQuery: selectActivitiesQuery
} = CreateSelectorForEntities<iStore, iActivity>({
  sliceName: "activities"
});
