import { LazyJSONViewer } from "@libs/lazy-json-viewer/lazy-json-viewer";
import { useSDKWithRemotePagination } from "@sdk/sdk.hooks";
import { Button, Form, Input, Pagination, Space, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";

const APICallsBrowser = () => {
  const [jsonFilter, setJsonFilter] = useState({});
  const [form] = useForm();
  const {
    paginationState,
    setPaginationState,
    data: itemsInView,
    setData: setItemsInView,
    isLoading,
    reload: resetAndReload,
  } = useSDKWithRemotePagination(
    (SDK) => SDK.HQ_queryAPICalls,
    jsonFilter,
    [],
    {
      pageSize: 15,
      currentPage: 1,
    },
  );

  const columns = [
    {
      title: "Click To Expand",
      key: "json",
      render: (data) => {
        return <LazyJSONViewer src={data} collapsed={true} />;
      },
    },
  ];

  return (
    <>
      <Space>
        <Button
          onClick={() => {
            form.setFieldsValue({
              jsonValue: JSON.stringify(
                [
                  {
                    $group: {
                      _id: "$organizationId",
                      count: { $sum: 1 },
                    },
                  },
                  {
                    $sort: { count: -1 },
                  },
                  {
                    $lookup: {
                      from: "organizations",
                      localField: "_id",
                      foreignField: "id",
                      as: "organizationDetails",
                    },
                  },
                  {
                    $unwind: "$organizationDetails",
                  },
                  {
                    $project: {
                      _id: 1,
                      count: 1,
                      domain: "$organizationDetails.domain",
                      name: "$organizationDetails.data.name",
                    },
                  },
                ],
                null,
                2,
              ),
            });
          }}
        >
          By Org
        </Button>
        <Button
          onClick={() => {
            form.setFieldsValue({
              jsonValue: JSON.stringify(
                [
                  {
                    $group: {
                      _id: "$path",
                      count: { $sum: 1 },
                    },
                  },
                  {
                    $sort: { count: -1 },
                  },
                ],
                null,
                2,
              ),
            });
          }}
        >
          By Path
        </Button>
        <Button
          onClick={() => {
            form.setFieldsValue({
              jsonValue: JSON.stringify(
                [
                  {
                    $group: {
                      _id: "$country",
                      count: { $sum: 1 },
                    },
                  },
                  {
                    $sort: { count: -1 },
                  },
                ],
                null,
                2,
              ),
            });
          }}
        >
          By Country
        </Button>
        <Button
          onClick={() => {
            form.setFieldsValue({
              jsonValue: JSON.stringify(
                [
                  {
                    $group: {
                      _id: "$ipAddress",
                      count: { $sum: 1 },
                    },
                  },
                  {
                    $sort: { count: -1 },
                  },
                ],
                null,
                2,
              ),
            });
          }}
        >
          By IP
        </Button>
      </Space>
      <Form
        form={form}
        onFinish={() => {
          form.getFieldValue("jsonValue") !== "" &&
            setJsonFilter(JSON.parse(form.getFieldValue("jsonValue")));
          form.getFieldValue("jsonValue") === "" && setJsonFilter({});
          resetAndReload();
        }}
      >
        <div className="flex justify-center my-5 ">
          <Form.Item
            name="jsonValue"
            style={{ width: "calc(100% - 100px)" }}
            initialValue=""
          >
            <Input.TextArea defaultValue="" />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
      </Form>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Table
          columns={columns}
          dataSource={itemsInView}
          pagination={false}
          loading={isLoading}
        />
        <div className="flex items-center justify-between mt-3">
          <Pagination
            pageSize={paginationState.pageSize}
            current={paginationState.currentPage}
            total={paginationState.totalItems}
            onChange={(currentPage, pageSize) => {
              setPaginationState({
                ...paginationState,
                pageSize: pageSize || 15,
                currentPage,
              });
              console.log("currentPage", currentPage);
            }}
          />
          <div>Total items: {paginationState.totalItems}</div>
        </div>
      </div>
    </>
  );
};

export default APICallsBrowser;
