import {
  Apps,
  getRoleFromPermission,
} from "@sdk/roles-and-permissions/roles-and-permissions";
import { QueryUnreadCountBadge } from "components/pages/conversations/components/conversation-query-unread-count-badge/conversation-query-unread-count-badge";
import { QueryConversationsBadgeCount } from "components/pages/conversations/components/query-conversations-count-badge/query-conversations-count-badge";
import { useBetaFeatures } from "components/pages/conversations/use-beta-features";
import { useCan } from "permission-guards/use-can";

import { useMemo } from "react";
import { useSelector, useStore } from "react-redux";
import { loadAllConnections } from "store/modules/connections/connections.helpers";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import {
  selectFeatureRestrictionConfig,
  selectOrganization,
} from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";

const _navigation: {
  label: string;
  path: string;
  keyboardShortcut?: string;
  icon: JSX.Element;
  requiredPermission: string[];
  forInsider?: boolean;
  extraLabel?: JSX.Element;
}[] = [
  // {
  //   label: "Home",
  //   path: "/home",
  //   keyboardShortcut: "$mod+h",
  //   icon: <i className="ri-home-2-fill"></i>,
  //   requiredPermission: [`${Apps.CONVERSATION}:*`],
  // },

  {
    label: "Unibox",
    path: "/conversations",
    keyboardShortcut: "$mod+i",
    icon: <i className="ri-inbox-2-fill"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    extraLabel: (
      <>
        <>
          <span className="absolute main-nav-badge-cont">
            <QueryConversationsBadgeCount query={"Unassigned"} />
          </span>
          <span className="absolute main-nav-badge-cont">
            <QueryUnreadCountBadge query={"My Inbox"} />
          </span>
        </>
      </>
    ),
  },
  {
    label: "Trackers",
    path: "/trackers",
    // keyboardShortcut: "$mod+p",
    icon: <i className="ri-ticket-2-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    // forInsider: true,
  },
  {
    label: "Bot Kit",
    path: "/bot-kit",
    icon: <i className="ri-robot-2-fill"></i>,
    requiredPermission: [`${Apps.REPORTS}:*`],
    // forInsider: true,
  },
  {
    label: "Help Center",
    path: "/help-center",
    icon: <i className="ri-book-open-fill"></i>,
    requiredPermission: [`${Apps.REPORTS}:*`],
    // forInsider: true,
  },
  {
    label: "Engage Hub",
    path: "/engage-hub",
    icon: <i className="ri-send-plane-fill"></i>,
    requiredPermission: [`${Apps.REPORTS}:*`],
    // forInsider: true,
  },
  {
    label: "Customers",
    path: "/customers",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-team-fill"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
  },
  {
    label: "Live View",
    path: "/website-live-view",
    // keyboardShortcut: "$mod+l",
    icon: <i className="ri-global-fill"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
  },
  {
    label: "Holiday Quotes",
    path: "/holiday-quotes",
    // keyboardShortcut: "$mod+p",
    icon: <i className="ri-bill-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    // forInsider: true,
  },
  // {
  //   label: "Tracked Support Cases (Beta)",
  //   path: "/customer-cases",
  //   // keyboardShortcut: "$mod+p",
  //   icon: <i className="ri-folder-open-line"></i>,
  //   requiredPermission: [`${Apps.CONVERSATION}:*`],
  //   // forInsider: true,
  // },

  {
    label: "Pipelines",
    path: "/pipelines",
    icon: <i className="ri-layout-column-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  // {
  //   label: "Automations",
  //   path: "/automation",
  //   icon: <i className="ri-magic-line"></i>,
  //   requiredPermission: [`${Apps.ORGANIZATION_MANAGEMENT}:*`]
  // },
  {
    label: "Chatbots",
    path: "/chat-bots",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-robot-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Forms",
    path: "/forms",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-file-text-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Callback Requests",
    path: "/call-back-requests",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-folder-5-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Call Logs",
    path: "/call-logs",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-folder-5-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Sequences",
    path: "/sequences",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-mail-send-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Knowledge Kit",
    path: "/knowledge-kit",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-folder-5-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Knowledge Base",
    path: "/knowledge-base",
    // keyboardShortcut: "$mod+c",
    icon: <i className="ri-book-line"></i>,
    requiredPermission: [`${Apps.CONVERSATION}:*`],
    forInsider: true,
  },
  {
    label: "Reports",
    path: "/reports",
    // keyboardShortcut: "$mod+r",
    icon: <i className="ri-bar-chart-fill"></i>,
    requiredPermission: [`${Apps.REPORTS}:*`],
  },
  // {
  //   label: "Workspace Settings",
  //   path: "/configurations",
  //   keyboardShortcut: "$mod+s",
  //   icon: <i className="ri-home-gear-line"></i>,
  //   requiredPermission: [`${Apps.ORGANIZATION_MANAGEMENT}:*`],
  // },
];

export const useMainNavigation = () => {
  const store = useStore();
  const { can, isReady } = useCan();

  const currentOrganization = useSelector(selectOrganization);
  const currentUser = useSelector(selectCurrentUser);
  const userRole = useMemo(() => {
    const currentUserRole = getRoleFromPermission(
      currentUser?.permissions || [],
    );
    return currentUserRole;
  }, [currentUser?.permissions]);

  const featureRestrictionConfig = useSelector(selectFeatureRestrictionConfig);
  const betaFeatures = useBetaFeatures();

  const { state: connections, retry: reload } = useQueryWithStore(
    selectAllConnections,
    loadAllConnections,
  );
  const liveChatConnection = useMemo(() => {
    return connections.find((item) => item.type === "WEB_CHAT");
  }, [connections]);

  const navigation = useMemo(
    () =>
      _navigation
        .filter((item) => isReady && can!(item.requiredPermission))
        .filter((item) => {
          // Custom Filter
          if (item.path === "/website-live-view" && !liveChatConnection) {
            return false;
          }
          if (
            item.path === "/customers" &&
            featureRestrictionConfig?.["CRM_ACCESS"]?.[userRole]
          ) {
            return false;
          }
          if (item.path === "/holiday-quotes") {
            return betaFeatures?.QUOTE_CREATOR;
          }
          if (item.path === "/trackers") {
            return (
              (currentOrganization?.appData?.HELP_DESK?.ticketTypes?.length ||
                0) > 0
            );
          }
          if (item.path === "/help-center" || item.path === "/engage-hub") {
            return userRole === "Owner" || userRole === "Manager";
          }
          return true;
        })
        .filter((item) => (item.forInsider ? false : true))
        .filter((item) =>
          item.forInsider
            ? window.location.href.includes("localhost") ||
              window.location.href.includes("reach-it-right.clickconnector") ||
              window.location.href.includes("cc.clickconnector")
            : true,
        ),
    [
      betaFeatures?.QUOTE_CREATOR,
      can,
      currentOrganization?.appData?.HELP_DESK?.ticketTypes?.length,
      featureRestrictionConfig,
      isReady,
      liveChatConnection,
      userRole,
    ],
  );
  return navigation;
};
