import { axios, AxiosResponse } from "@sdk/axios";
import { iUser } from "@sdk/user-management/user-management.models";
import { UserTracker } from "user-tracker";
import {
  iPhoneSearchResults,
  iTelnyxPhoneSearchFilter,
} from "./telnyx-service-models";

export class TelnyxServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  telnyx = {
    queryPhoneNumber: async (query: iTelnyxPhoneSearchFilter) => {
      const results: AxiosResponse<iPhoneSearchResults> = await axios.post(
        this.config.basePath + `/telnyx/query-phone`,
        query,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    registerForSip: async (connectionId: string) => {
      UserTracker.track("TELNYX - Register For Sip", {});
      const results: AxiosResponse<iUser> = await axios.post(
        this.config.basePath + `/telnyx/register-for-sip`,
        { connectionId },
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    buyAndConnect: async (req: {
      connectionId: string;
      phoneNumber: string;
    }) => {
      UserTracker.track("TELNYX - Buy and Connect", {});
      const results: AxiosResponse<iPhoneSearchResults> = await axios.post(
        this.config.basePath + `/telnyx/buy-number-and-connect`,
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
  };
}
