import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ChangeMyEmailModal = React.lazy(() => import("./change-email.modal"));

const ChangeMyEmail: iModalProps<typeof ChangeMyEmailModal, {}> = {
  name: "change-email",
  component: GenerateModalWithSuspense(ChangeMyEmailModal),
};

export default ChangeMyEmail;
