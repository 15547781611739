import { SDK } from "@sdk";
import {
  Alert,
  Badge,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Tag,
  Tooltip,
  Upload,
} from "antd";
import { RcFile } from "antd/lib/upload";
import { ExtendControlType } from "braft-editor";
import { defaultAdditionalExcludedBraftControllers } from "components/common/draft-js-advanced/default-excluded-braft-controllers";
import { BraftJSInput } from "components/common/draft-js-advanced/draft-js-advanced";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { ModalTitle } from "components/common/modal-title";
import { StyledSwitch } from "components/common/styled-swtich";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { getTokens } from "utils/extract-tokens";
import { uuidv4 } from "utils/generate-uuid";
import { justWait } from "utils/just-wait";
import {
  iPreset,
  iPresetFolder,
} from "../../../@sdk/user-management/preset-state-model";
import {
  AvailableTokens,
  AvailableTokenTable,
} from "../conversations/components/chat-input/available-message-tokens";
import "./preset-editor-modal.scss";

export const PresetEditorModal = ({
  visible,
  mode,
  onChangeVisibility,
  onSaved,
  initialValue,
  folders,
}: {
  visible: boolean;
  mode: "ADD" | "EDIT";
  onChangeVisibility: (state: boolean) => any;
  onSaved: (value: iPreset) => any;
  initialValue?: iPreset;
  folders: iPresetFolder[];
}) => {
  const [form] = Form.useForm();

  const draftJsRef = useRef<any>();

  useEffect(() => {
    form.setFieldsValue({
      label: initialValue?.label,
      type: initialValue?.type || "SIMPLE_TEXT",
      folder: initialValue?.folder,
      value: initialValue?.type === "RICH_TEXT" ? "" : initialValue?.value,
      autoTagConversation: initialValue?.autoTagConversation,
    });

    if (draftJsRef.current && initialValue?.type === "RICH_TEXT") {
      draftJsRef.current.setHtmlContent(initialValue?.value || "");
    }
    setFileList(initialValue?.attachments || []);
  }, [visible, mode, initialValue, form]);

  const uploadFile = useCallback(async (file: RcFile) => {
    await justWait(3000);
    try {
      const fileRecord = await SDK.uploadFile(file, {
        type: "CONVERSATION",
        entityId: "xxxx1",
      });
      if (!fileRecord) {
        throw {
          message: "Uploading Failed. Check file",
        };
      }
      // console.log("fileRecord", fileRecord);
      return fileRecord.url;
    } catch (e) {
      console.log("File Upload Failed", e);
      message.error("File Upload Failed. Check File");
      throw new Error("File Upload Failed. Check File");
    }
  }, []);

  const uploadFileWithLoading = useCallback(
    async (file: RcFile) => {
      try {
        setIsUploading(true);
        const url = await uploadFile(file);
        setIsUploading(false);
        return url;
      } catch (e) {
        setIsUploading(false);
        throw e;
      }
    },
    [uploadFile],
  );

  const uploadFileX = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      setIsUploading(true);
      const url = await uploadFile(file);
      const fileListRecord = {
        uid: new Date().getTime().toString(),
        name: file.name,
        status: "done", // options：uploading, done, error, removed. Intercepted file by beforeUpload don't have status field.
        response: '{"status": "success"}', // response from server
        linkProps: '{"download": "image"}', // additional html props of file link
        xhr: "XMLHttpRequest{ ... }", // XMLHttpRequest Header
        url: url,
        thumbUrl: url,
      };
      setFileList((state) => [...state, fileListRecord]);
      setIsUploading(false);
      onSuccess("Ok");
    } catch (err) {
      onError({ err });
      setIsUploading(false);
    }
  };

  const [fileList, setFileList] = useState<any[]>([]);
  const [uploadingFiles, setUploadingFiles] = useState([] as string[]);

  const onFileListChange = ({ fileList: newFileList }) => {
    setFileList(newFileList.filter((item) => item.status === "done"));
  };

  const [isUploading, setIsUploading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setIsUploading(true);
      const ids = acceptedFiles.map(
        (file, index) => `${Date.now().toString()}-${index}`,
      );
      setUploadingFiles((state) => [...state, ...ids]);
      try {
        const newFileListRecords: any[] = [];
        for (const file of acceptedFiles) {
          const url = await uploadFile(file as any);
          newFileListRecords.push({
            uid: new Date().getTime().toString(),
            name: file.name,
            status: "done", // options：uploading, done, error, removed. Intercepted file by beforeUpload don't have status field.
            response: '{"status": "success"}', // response from server
            linkProps: '{"download": "image"}', // additional html props of file link
            xhr: "XMLHttpRequest{ ... }", // XMLHttpRequest Header
            url: url,
            thumbUrl: url,
          });
        }
        setUploadingFiles((state) => _.without(state, ...ids));
        setIsUploading(false);
        setFileList((state) => [...state, ...newFileListRecords]);
      } catch (e) {
        setUploadingFiles((state) => _.without(state, ...ids));
        setIsUploading(false);
        // No need to throw the error from here as the error is handled fully
        // throw e;
      }
    },
    [uploadFile],
  );

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    if (imgWindow) {
      imgWindow.document.write(image.outerHTML);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  const [value, setValue] = useState("");

  const capturedTokens = useMemo(() => {
    const tokens = getTokens(value);
    const validTokens = tokens.filter((token) =>
      AvailableTokens.includes(token),
    );
    const invalidTokens = tokens.filter(
      (token) => !AvailableTokens.includes(token),
    );
    return { validTokens, invalidTokens, all: tokens };
  }, [value]);

  const extendControls = useMemo(() => {
    const extendControls: ExtendControlType[] = [
      {
        key: "message-presets",
        type: "modal",
        text: (
          <Tooltip title="Message Personalization">
            <i className="ri-braces-line"></i>
          </Tooltip>
        ),
        modal: {
          id: "message-personalization-modal",
          title: (
            <span>
              <ModalTitle
                title="Message Personalization"
                icon={<i className="ri-braces-line"></i>}
              />
            </span>
          ) as any,
          showFooter: false,
          closeOnBlur: true,
          className: "message-personalization-modal",
          children: (
            <div style={{ width: 400, padding: "0 10px" }}>
              <div className="my-4">
                You can use variable tokens in your messages
                <div className="font-bold my-4">Available Tokens:</div>
                <table className="simple-table-style w-full">
                  <thead>
                    <tr>
                      <th className="font-bold">Variable</th>
                      <th className="font-bold">Token</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AvailableTokenTable.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.label}</td>
                          <td>
                            <Tag
                              onClick={() => {
                                draftJsRef.current.insertHtmlToCursor(
                                  `<span class="variable-token-item">{${item.token}}</span>`,
                                );
                                const cb =
                                  draftJsRef.current.getControlBarInstance();
                                cb?.extendedModals?.[
                                  "message-personalization-modal"
                                ].close();
                              }}
                              className="cursor-pointer"
                            >
                              {item.token}
                            </Tag>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ),
        },
      },
    ];
    return extendControls;
  }, []);

  return (
    <Modal
      title={
        mode === "ADD" ? (
          <ModalTitle
            icon={<i className="ri-chat-new-line"></i>}
            title="Add Preset"
          />
        ) : (
          <ModalTitle
            icon={<i className="ri-chat-settings-line"></i>}
            title="Edit Preset"
          />
        )
      }
      open={visible}
      destroyOnClose={true}
      onOk={async () => {
        try {
          await form.validateFields();
          const formValues = form.getFieldsValue();
          const htmlValue = draftJsRef.current?.getHtmlContent() || "";
          onSaved({
            id: uuidv4(),
            ...((initialValue || {}) as any),
            ...formValues,
            value:
              formValues.type === "RICH_TEXT" ? htmlValue : formValues.value,
            attachments: fileList,
          });
        } catch (e) {
          message.error("Please check your input");
        }
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Save"
      okButtonProps={{ className: "font-bold" }}
      data-click-context="Preset Editor Modal"
      width={720}
      className="preset-editor-modal"
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          type: "SIMPLE_TEXT",
        }}
      >
        <Form.Item
          name="label"
          label={<div className="font-bold">Preset Label</div>}
          rules={[
            {
              required: true,
              message: "Please enter a label",
            },
          ]}
        >
          <Input placeholder="Label" size="large" autoFocus={true} />
        </Form.Item>

        <Form.Item
          name="folder"
          label={<div className="font-bold">Preset Folder</div>}
        >
          <Select allowClear showSearch>
            {folders.map((folder) => (
              <Select.Option value={folder.id} key={folder.id}>
                {folder.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <div className="flex flex-row justify-center items-center w-full">
          <Form.Item name="type" label="" className="w-full">
            <Radio.Group
              defaultValue="SIMPLE_TEXT"
              buttonStyle="solid"
              className="w-full"
            >
              <Radio.Button
                value="SIMPLE_TEXT"
                style={{ width: "50%", textAlign: "center" }}
              >
                Simple Text
              </Radio.Button>
              <Radio.Button
                value="RICH_TEXT"
                style={{ width: "50%", textAlign: "center" }}
              >
                Rich Text (For emails only)
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
        <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
          {() => {
            return form.getFieldValue(["type"]) === "RICH_TEXT" ? (
              <>
                <div className="draft-js-container border border-gray-200 dark:border-gray-700 rounded-lg">
                  <BraftJSInput
                    initialValue={initialValue?.value || ""}
                    ref={draftJsRef}
                    onChange={setValue}
                    additionalExcludedControls={
                      defaultAdditionalExcludedBraftControllers
                    }
                    extendControls={extendControls}
                    showControlsOnTop={true}
                  />
                </div>
              </>
            ) : (
              <Form.Item name="value" label="">
                <Input.TextArea
                  placeholder="Enter your message here"
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                />
              </Form.Item>
            );
          }}
        </Form.Item>

        {capturedTokens.all.length === 0 && (
          <Alert
            message={
              <div className="text-gray-900 dark:text-gray-100 font-bold">
                Did you know?
              </div>
            }
            type="info"
            description={
              <div>
                You can use variable tokens in your preset
                <div className="font-bold my">Available Tokens:</div>
                {AvailableTokens.map((item, index) => (
                  <Tag key={index.toString()}>{item}</Tag>
                ))}
              </div>
            }
            className="mt-3"
          />
        )}

        {capturedTokens.all.length !== 0 && (
          <div className="token-container">
            <div className="font-bold my">Valid Tokens:</div>
            {capturedTokens.validTokens.map((item, index) => (
              <Tag key={index.toString()}>{item}</Tag>
            ))}
            <div className="font-bold my">Invalid Tokens:</div>
            {capturedTokens.invalidTokens.map((item, index) => (
              <Tag key={index.toString()}>{item}</Tag>
            ))}
            <div className="font-bold my">Available Tokens:</div>
            {AvailableTokens.map((item, index) => (
              <Tag key={index.toString()}>{item}</Tag>
            ))}
          </div>
        )}

        <div className="font-bold my-4">Attachments:</div>
        <div
          className="attachments-container p-4 border border-gray-200 dark:border-gray-700 rounded-lg bg-gray-100 dark:bg-gray-800 relative"
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          {isDragActive && (
            <div className="drop-indicator">Drop the files here ...</div>
          )}
          <Upload
            // action={file => uploadFileWithLoading(file)}
            customRequest={uploadFileX}
            listType="picture-card"
            fileList={fileList as any}
            onChange={onFileListChange}
            onPreview={onPreview}
            // showUploadList={true}
            openFileDialogOnClick={true}
          >
            {isUploading || uploadingFiles.length > 0 ? (
              <Badge count={uploadingFiles.length}>
                <LoadingIndicatorWithSpin />
              </Badge>
            ) : fileList.length < 5 ? (
              "+ Upload"
            ) : (
              ""
            )}
          </Upload>
        </div>
        <div className="mt-4">
          <StyledSwitch
            name={"autoTagConversation"}
            label="Auto Tag Conversation:"
            description="When you enable this, the preset label will be used to tag the conversation"
          />
        </div>
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
