import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import React from "react";
import classNames from "utils/classnames";

export const LoadingIndicatorWithSpin = ({ fontSize }: { fontSize? }) => {
  return <LoadingOutlined style={{ fontSize: fontSize || 24 }} spin />;
};

export const LoadingIndicatorWithoutSpin = () => {
  return <LoadingOutlined style={{ fontSize: 24 }} />;
};

export const SuspenseLoadingIndicator = ({
  style,
}: {
  style?: React.CSSProperties;
}) => (
  <div
    style={style}
    className="flex flex-col justify-center items-center w-full h-full"
  >
    <LoadingIndicatorWithSpin />
  </div>
);

export const ListLoadingIndicator = ({
  heightClassName = "h-3",
  itemCount = 5,
  containerClassName,
}: {
  heightClassName?: string;
  itemCount?: number;
  containerClassName?: string;
}) => {
  return (
    <div
      className={classNames(
        "animate-pulse flex flex-col w-full h-full",
        containerClassName,
      )}
    >
      {new Array(itemCount).fill(null).map((item, index) => (
        <div
          key={index.toString()}
          className={classNames(
            "bg-slate-200 dark:bg-slate-700 rounded-lg",
            heightClassName,
            {
              "opacity-50": index > itemCount / 2 && index < itemCount - 1,
              "opacity-20": index === itemCount - 1,
            },
          )}
        ></div>
      ))}
    </div>
  );
};
