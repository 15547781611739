import { iScheduledMessage } from "@sdk/scheduled-messages/scheduled-messages";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { scheduledMessagesDefaultQueryConfig } from "./scheduled-messages-default-query";

export const {
  slice: ScheduledMessagesSlice,
  reducers: ScheduledMessagesSliceReducer,
  actions: {
    setEntityQueryResults: setScheduledMessagesQueryResults,
    setEntity: setScheduledMessage,
    setEntities: setScheduledMessages,
    patchEntity: patchScheduledMessage,
    addNewEntity: addNewScheduledMessage,
    setEntityFetchingStatus: setScheduledMessageFetchingStatus,
    setEntitiesFetchingStatus: setScheduledMessagesFetchingStatus,
    setEntityFailedStatus: setScheduledMessageFailedStatus,
    setEntityQueryError: setScheduledMessagesQueryError,
    resetQuery: resetScheduledMessagesQuery
  }
} = CreateEntitySlice<iScheduledMessage>({
  sliceName: "scheduledMessages",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...scheduledMessagesDefaultQueryConfig,
        alias: "all"
      };
    } else if (queryAlias === "my") {
      const currentUserId = window["CC_CURRENT_USER_ID"];
      return {
        ...scheduledMessagesDefaultQueryConfig,
        query: {
          scheduledBy: currentUserId
        },

        alias: "my"
      };
    }
  }
});
