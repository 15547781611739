import { Space } from "antd";
import classNames from "classnames";
import React from "react";

export const ModalTitle = ({
  title,
  icon,
  className
}: {
  title: string | JSX.Element;
  icon: JSX.Element;
  className?: string;
}) => (
  <div
    className={classNames(
      "font-bold text-xl flex flex-row items-center",
      className
    )}
  >
    <Space>
      {icon && icon} {title}
    </Space>
  </div>
);
