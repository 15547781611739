import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iCampaign } from "@sdk/campaigns/campaigns-model";
import React from "react";
import { DeepPartial } from "redux";

const AddCampaignModal = React.lazy(() => import("./add-campaign.modal"));

const AddCampaign: iModalProps<
  typeof AddCampaignModal,
  {
    onCreated?: (content: iCampaign) => any;
    template?: DeepPartial<iCampaign>;
  }
> = {
  name: "add-campaign",
  component: GenerateModalWithSuspense(AddCampaignModal),
};

export default AddCampaign;
