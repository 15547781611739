import { UsersSliceActions } from "./users.store";

export const {
  setAllUsers,
  setUser,
  patchUser,
  addNewUser,
  setUsersQueryError,
  setAccessToken,
  setCurrentUser,
  logout
} = UsersSliceActions;
