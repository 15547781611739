import {
  iEmailConnectionData,
  iSmtpDetails,
} from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Alert, Button, Form, Input, Radio, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const ConfigureCustomSMTP = ({
  connectionId,
  onMailboxRead,
  smtpType: _smtpType,
}: {
  connectionId: string;
  onMailboxRead?: () => any;
  smtpType?: string;
}) => {
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const [smtpType, setSmtpType] = useState(_smtpType || "CUSTOM");
  const [GMailSmtpForm] = useForm();
  const [AWSSmtpForm] = useForm();
  const [CustomSmtpForm] = useForm();

  useEffect(() => {
    if (connection?.data?.customSmtp) {
      const smtpData = connection.data.customSmtp as iSmtpDetails;
      if (smtpData.type === "GMAIL") {
        setSmtpType(smtpData.type);
        GMailSmtpForm.setFieldsValue(smtpData.data);
      } else if (smtpData.type === "AWS") {
        setSmtpType(smtpData.type);
        AWSSmtpForm.setFieldsValue(smtpData.data);
      } else if (smtpData.type === "CUSTOM") {
        setSmtpType(smtpData.type);
        CustomSmtpForm.setFieldsValue(smtpData.data);
      }
    }
  }, [
    connection?.data,
    setSmtpType,
    GMailSmtpForm,
    AWSSmtpForm,
    CustomSmtpForm,
  ]);

  const verifySmtp = async () => {
    try {
      if (smtpType === "GMAIL") {
        await GMailSmtpForm.validateFields();
      } else if (smtpType === "AWS") {
        await AWSSmtpForm.validateFields();
      } else {
        // Custom
        await CustomSmtpForm.validateFields();
      }
      _verifySmtp({
        type: smtpType,
        data: (() => {
          if (smtpType === "GMAIL") {
            return GMailSmtpForm.getFieldsValue();
          } else if (smtpType === "AWS") {
            return AWSSmtpForm.getFieldsValue();
          } else {
            // Custom
            return CustomSmtpForm.getFieldsValue();
          }
        })(),
      });
    } catch (e) {
      message.warning("Please check your input");
    }
  };

  const { doAction: _verifySmtp, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (val) =>
        SDK.connections
          .verifyAndModifyCustomSmtp(connectionId, val)
          .then((d) => {
            onMailboxRead && onMailboxRead();
          }),
      successMessage: "SMTP details have been verified",
      failureMessage: "Something went wrong",
    }),
    [connectionId, onMailboxRead],
  );

  return (
    <div className="bold-form-labels">
      {/* Description */}

      {!_smtpType && (
        <>
          <div
            className="text-gray-700 dark:text-gray-200  mode_transition"
            style={{ maxWidth: "600px" }}
          >
            Custom SMTP is used to send outgoing emails. When you use custom
            email address to receive to messages. you need to provide SMTP
            server which is authorized to send emails in the name of your custom
            email.
          </div>
          <div className="smtp_type my-8">
            <Radio.Group
              value={smtpType}
              onChange={(e) => setSmtpType(e.target.value)}
              buttonStyle="solid"
            >
              <Radio.Button value="GMAIL">GMail</Radio.Button>
              <Radio.Button value="AWS">AWS SES</Radio.Button>
              <Radio.Button value="CUSTOM">Custom</Radio.Button>
            </Radio.Group>
          </div>
        </>
      )}

      {smtpType === "GMAIL" && (
        <div>
          <Form
            layout="vertical"
            form={GMailSmtpForm}
            initialValues={{}}
            autoComplete="off"
          >
            {/* Email */}
            <Form.Item
              label="GMail Email"
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input placeholder="abc@gmail.com" autoComplete="off" />
            </Form.Item>
            {/* Name */}
            <Form.Item
              label="GMail Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter a password",
                },
              ]}
              help="If your account has 2 step verification. You need to create application password. If you are using GSuite you need to enable this"
            >
              <Input type="password" />
            </Form.Item>
          </Form>
        </div>
      )}
      {smtpType === "AWS" && (
        <div>
          <Form layout="vertical" form={AWSSmtpForm} initialValues={{}}>
            {/* SMTP Server */}
            <Form.Item
              label="SMTP Server"
              name="server"
              rules={[
                {
                  required: true,
                  message: "Please enter a server address",
                },
              ]}
            >
              <Input />
            </Form.Item>
            {/* Username */}
            <Form.Item
              label="SMTP Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please enter a username",
                },
              ]}
            >
              <Input />
            </Form.Item>
            {/* Email */}
            <Form.Item
              label="SMTP Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input />
            </Form.Item>
            {/* Name */}
            <Form.Item
              label="SMTP Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter a password",
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
          </Form>
        </div>
      )}
      {smtpType === "CUSTOM" && (
        <div>
          <Form
            layout="vertical"
            form={CustomSmtpForm}
            initialValues={{ port: 465 }}
          >
            {/* SMTP Server */}
            <Form.Item
              label="SMTP Server"
              name="server"
              rules={[
                {
                  required: true,
                  message: "Please enter a server address",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Port"
              name="port"
              rules={[
                {
                  required: true,
                  message: "Please enter a value",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="Email (Optional)"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input placeholder="Optional: abc@gmail.com" />
            </Form.Item>
            {/* Username */}
            <Form.Item
              label="SMTP Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please enter a username",
                },
              ]}
            >
              <Input />
            </Form.Item>
            {/* Name */}
            <Form.Item
              label="SMTP Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter a password",
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
          </Form>
        </div>
      )}
      <div>
        <Alert
          showIcon
          icon={<i className="ri-share-forward-line"></i>}
          message={
            <div className="flex flex-row text-xl font-bold">
              Setup Email Forwarder to Receive Your Emails
            </div>
          }
          description={
            <div className="flex flex-col">
              <div className="">
                SMTP Details allow you to send emails through your email
                address. You still need to setup automatic email forwarder so
                that your emails can be seen in your Unibox.
              </div>
              <div className="">
                Your ClickConnector email address -{" "}
                <span className="font-bold">{connection?.data?.email}</span>
              </div>
              <div className="">
                Make sure that you have setup an automatic forwarder to the
                above email address.
              </div>
            </div>
          }
        />
      </div>
      <div
        className="footer flex flex-row justify-end items-center my-4"
        onClick={() => verifySmtp()}
      >
        {(connection?.data as iEmailConnectionData)?.customSmtp ? (
          <Button
            icon={<i className="ri-save-2-line"></i>}
            type="primary"
            loading={isProcessing}
          >
            Update
          </Button>
        ) : (
          <Button
            icon={<i className="ri-arrow-right-circle-fill"></i>}
            type="primary"
            loading={isProcessing}
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};
