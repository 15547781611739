import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { mdiCogPlay } from "@mdi/js";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Divider, Popconfirm, Space, Table } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { MDIIcon } from "components/common/mdi-icon";
import { SectionHeader } from "components/common/section-header";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { useEffect } from "react";
import { loadOrganization } from "store/modules/workspace/workspace.helpers";
import { selectMacros } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import AddEditMacro from "./add-edit-macro/add-edit-macro.modal-registry";

export const ManageMacros = () => {
  const { state: availableMacros, retry: reload } = useQueryWithStore(
    selectMacros,
    loadOrganization,
  );

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { doAction: deleteMacro, isProcessing: idDeletingMacro } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (id: string) => SDK.macros.deleteMacro(id),
        successMessage: "Macro has been removed",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const { changePanelState } = useModalPanels();

  const columns = [
    {
      title: "Label",
      dataIndex: "label",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (actions) => {
        return (
          <div className="flex-row items-center">
            <div className="boxes"></div>
            <div className="caption text-gray-600 dark:text-gray-400 mode_transition">{`${actions?.length} Actions`}</div>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "actions",
      width: 200,
      render: (data, record, index) => (
        <Space>
          <Button
            type="link"
            icon={<i className="ri-pencil-line"></i>}
            onClick={() => {
              changePanelState(AddEditMacro, true, { macroId: record.id });
            }}
          >
            Edit
          </Button>
          <Popconfirm
            placement="leftTop"
            title="Are you sure to delete?"
            onConfirm={() => {
              deleteMacro(record.id);
              reload();
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="link"
              shape="circle"
              icon={<i className="ri-delete-bin-line"></i>}
            ></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin">
      <SectionHeader
        title="Manage Macros"
        icon={<MDIIcon icon={mdiCogPlay} size="3rem" />}
        description="Create & Manage Macros that help agents become more productive"
        addons={
          <Space>
            <Button
              type="primary"
              // size="large"
              icon={<i className="ri-inbox-fill"></i>}
              onClick={() => {
                changePanelState(AddEditMacro, true, {});
              }}
            >
              Create New Macro
            </Button>
          </Space>
        }
      />
      <Divider />

      <Table
        columns={columns}
        dataSource={availableMacros}
        pagination={false}
        rowKey="id"
        locale={{
          emptyText: (
            <EmptyData
              text="No macros to show"
              icon={<MDIIcon icon={mdiCogPlay} size="3rem" />}
            />
          ),
        }}
      />
    </StyledScrollArea>
  );
};
