import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Divider } from "antd";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { useShopifyBillingStatusChecker } from "components/pages/home/onboarding/shopify-onboarding/helpers/shopify-billing-status-checker";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { selectSubscriptionDetails } from "store/modules/workspace/workspace.selectors";
import { iPricingPlan } from "./pricing-plan-model";
import { PricingPlans } from "./pricing-plans";

export const ShopifyBillingPortal = () => {
  const subscriptionDetails = useSelector(selectSubscriptionDetails);

  const [
    isShopifyBillingCheckerActivated,
    setSHopifyBillingChecker,
    reloadBillingStatus,
    isReloadingStatus,
  ] = useShopifyBillingStatusChecker();

  const {
    doAction: _activatePlan,
    isProcessing,
    response,
  } = useSDKActionWithDeps(
    () => ({
      action:
        (SDK) =>
        (req: { planId: string; planName: string; planPrice: number }) =>
          SDK.shopify.activateSubscription(req).then((d) => {
            window.open(d.confirmation_url, "cc-shopify-billing");
            setSHopifyBillingChecker(true);
            return d;
          }),
      // successMessage: "Done",
      failureMessage: "Something went wrong",
    }),
    [setSHopifyBillingChecker],
  );

  const { doAction: deActivatePlan } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () => SDK.shopify.deActivateSubscription(),
      // successMessage: "Done",
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const activatePlan = useCallback(
    (plan: iPricingPlan) => {
      if (plan.id === "STARTUP") {
        deActivatePlan();
      } else {
        _activatePlan({
          planId: plan.id,
          planName: plan.name,
          planPrice: Number(plan.price.replace("$", "")),
        });
      }
    },
    [_activatePlan, deActivatePlan],
  );

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin p-8 overflow-x-hidden">
      <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4">
        <SectionHeader
          title="Billing & Subscriptions"
          icon={<i className="ri-team-line"></i>}
          description="Your Subscription is managed by Shopify"
        />
        <Divider />

        <div className="w-full flex flex-col justify-center items-center">
          <PricingPlans
            onPlanSelect={async (plan, isYearly) => {
              activatePlan(plan);
            }}
            selectedPlan={
              subscriptionDetails?.planId === "STARTUP"
                ? "STARTUP"
                : `${subscriptionDetails?.planId}_MONTHLY`
            }
            hideYearly={true}
          />
        </div>
      </SimpleCardSection>
    </StyledScrollArea>
  );
};
