import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { Button, Collapse, Divider } from "antd";
import CCEmbedPreview from "components/common/cc-embed-preview/cc-embed-preview.modal-registry";
import { WidgetPreview } from "components/modules/connections/channels/chat-widget/components/configure-chat-widget/widget-preview";
import { useMemo, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.selectors";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { loadAllSelfServicePortals } from "store/modules/self-service-portals/self-service-portals.helpers";
import { selectAllSelfServicePortals } from "store/modules/self-service-portals/self-service-portals.selectors";
import { selectTicketTypes } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { EmbedKBPortalSetup } from "./components/embed-kb-portal-setup";
import { EmbedTrackersPortalSetup } from "./components/embed-trackers-setup";
import { EmbedWidgetSetup } from "./components/embed-widget-setup";

interface iEmbed {
  id: string;
  label: string;
  type: "WIDGET" | "KB_PORTAL" | "TRACKER";
  connectionId?: string;
  kbPortalId?: string;
  trackerTypeId?: string;
}

export const SetupEmbeds = () => {
  const { state: allWidgets, retry: reload } = useQueryWithStore(
    selectAllChatWidgets,
    loadAllChatWidgets(),
    [],
  );

  const { state: selfServicePortals } = useQueryWithStore(
    selectAllSelfServicePortals,
    loadAllSelfServicePortals,
    [],
  );

  const ticketTypes = useSelector(selectTicketTypes);

  const store = useStore();

  const availableEmbeds = useMemo(() => {
    const embeds: iEmbed[] = [];
    for (const widget of allWidgets) {
      const connection = selectConnectionById(widget.connectionId)(
        store.getState(),
      );
      embeds.push({
        id: widget.id!,
        type: "WIDGET",
        label: `Chat Widget: ${connection.label}`,
        connectionId: widget.connectionId,
      });
    }
    for (const portal of selfServicePortals) {
      embeds.push({
        id: portal.id!,
        type: "KB_PORTAL",
        label: `Help Center: ${portal.label}`,
        kbPortalId: portal.id,
      });
    }
    for (const ticketType of ticketTypes) {
      if (ticketType.type === "PUBLIC_TICKET") {
        embeds.push({
          id: ticketType.id!,
          label: `Tracker: ${ticketType.label}`,
          type: "TRACKER",
          trackerTypeId: ticketType.id,
        });
      }
    }
    return embeds;
  }, [allWidgets, selfServicePortals, store, ticketTypes]);

  const [activeKeys, setActiveKeys] = useState([] as string[]);

  const activeEmbed = useMemo(() => {
    return availableEmbeds.find((item) => item.id === activeKeys[0]);
  }, [activeKeys, availableEmbeds]);

  const [embedConfigs, setEmbedConfigs] = useState({} as any);

  return (
    <div className="w-full h-full flex flex-row items-stretch">
      <div className="flex-1 h-full overflow-auto">
        <div className="p-4">
          <div className="text-2xl">Available Embeds</div>
          <div className="text-gray-600 dark:text-gray-400">
            Embeds are components that can be embedded in your website/app
          </div>
        </div>
        <Divider>
          Click on an embed to preview and learn how to use the APIs
        </Divider>
        <div className="p-4">
          <Collapse
            accordion
            activeKey={activeKeys}
            onChange={setActiveKeys as any}
          >
            {availableEmbeds.map((item) => (
              <Collapse.Panel header={item.label} key={item.id}>
                {item.type === "WIDGET" && (
                  <EmbedWidgetSetup connectionId={item.connectionId!} />
                )}
                {item.type === "KB_PORTAL" && (
                  <EmbedKBPortalSetup kbPortalId={item.kbPortalId!} />
                )}
                {item.type === "TRACKER" && (
                  <EmbedTrackersPortalSetup
                    trackerTypeId={item.trackerTypeId!}
                  />
                )}
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      </div>
      <Divider type="vertical" className="h-full" />
      <div className="flex-1 h-full">
        {!activeEmbed && (
          <div className="h-full w-full flex flex-row justify-center items-center">
            <div className="text-gray-600 dark:text-gray-400">
              👈 Click on an embed to preview
            </div>
          </div>
        )}
        {activeEmbed && <EmbedPreview {...activeEmbed} />}
      </div>
    </div>
  );
};

const EmbedPreview = (props: iEmbed) => {
  if (props.type === "WIDGET") {
    return <EmbedPreview_Widget connectionId={props.connectionId!} />;
  }
  if (props.type === "KB_PORTAL") {
    return <EmbedPreview_KB kbPortalId={props.kbPortalId!} />;
  }
  if (props.type === "TRACKER") {
    return <EmbedPreview_Tracker trackerTypeId={props.trackerTypeId!} />;
  }
  return <></>;
};

const EmbedPreview_Widget = ({ connectionId }: { connectionId: string }) => {
  return (
    <div className="h-full w-full flex flex-row justify-center items-center">
      <WidgetPreview connectionId={connectionId} />
    </div>
  );
};

const EmbedPreview_KB = ({ kbPortalId }: { kbPortalId: string }) => {
  const { triggerTempModal } = useModalPanels();

  return (
    <div className="h-full w-full flex flex-col justify-center items-center gap-4">
      <div className="text-gray-600 dark:text-gray-400">
        Preview: Show Help Center Embeds
      </div>
      <Button
        icon={<i className="ri-sun-fill"></i>}
        type="primary"
        onClick={() => {
          triggerTempModal(CCEmbedPreview, {
            type: "KB_PORTAL",
            kbPortalId: kbPortalId,
            isDarkMode: false,
          });
        }}
      >
        Light Mode
      </Button>
      <Button
        icon={<i className="ri-moon-fill"></i>}
        type="primary"
        onClick={() => {
          triggerTempModal(CCEmbedPreview, {
            type: "KB_PORTAL",
            kbPortalId: kbPortalId,
            isDarkMode: true,
          });
        }}
      >
        Dark Mode
      </Button>
    </div>
  );
};

const EmbedPreview_Tracker = ({ trackerTypeId }: { trackerTypeId: string }) => {
  const { triggerTempModal } = useModalPanels();

  return (
    <div className="h-full w-full flex flex-col justify-center items-center gap-4">
      <div className="text-gray-600 dark:text-gray-400">
        Preview: Show Tracker Embeds
      </div>
      <div className="flex flex-row gap-4">
        <Button
          icon={<i className="ri-sun-fill"></i>}
          type="primary"
          onClick={() => {
            triggerTempModal(CCEmbedPreview, {
              type: "TRACKER",
              trackerTypeId,
              isDarkMode: false,
              viewMode: "kanban",
            });
          }}
        >
          Kanban View
        </Button>
        <Button
          icon={<i className="ri-moon-fill"></i>}
          type="primary"
          onClick={() => {
            triggerTempModal(CCEmbedPreview, {
              type: "TRACKER",
              trackerTypeId,
              isDarkMode: true,
              viewMode: "kanban",
            });
          }}
        >
          Kanban View
        </Button>
      </div>
      <div className="flex flex-row gap-4">
        <Button
          icon={<i className="ri-sun-fill"></i>}
          type="primary"
          onClick={() => {
            triggerTempModal(CCEmbedPreview, {
              type: "TRACKER",
              trackerTypeId,
              isDarkMode: false,
              viewMode: "list",
            });
          }}
        >
          List View
        </Button>
        <Button
          icon={<i className="ri-moon-fill"></i>}
          type="primary"
          onClick={() => {
            triggerTempModal(CCEmbedPreview, {
              type: "TRACKER",
              trackerTypeId,
              isDarkMode: true,
              viewMode: "list",
            });
          }}
        >
          List View
        </Button>
      </div>
    </div>
  );
};
