import { Collapse } from "antd";
import { CollapsibleHeader } from "components/common/collapsible-configuration-panel/collapsible-header";
import { kebabCase } from "lodash";
import qs from "query-string";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react";
import { useHistory } from "react-router";
import "./collapsible-config-container.scss";

export const CollapsibleConfigurationSection = forwardRef(
  (
    {
      icon,
      title,
      description,
      children,
      skipScrollingIntoView,
    }: {
      icon: string | JSX.Element;
      title: string;
      description: string;
      children: JSX.Element;
      skipScrollingIntoView?: boolean;
    },
    ref,
  ) => {
    const history = useHistory();
    const panelKey = title;

    const openPanels = useMemo(() => {
      const search = qs.parse(history.location.search);
      return search.activeConfigs || [];
    }, [history.location.search]);

    const changePanelState = useCallback(
      (state) => {
        const existingSearch = qs.parse(history.location.search);
        const newLocationObject = {
          ...history.location,
          search: qs.stringify({
            ...existingSearch,
            activeConfigs: state ? panelKey : "",
          }),
        };
        history.replace(newLocationObject);
        const collapseElement = document.getElementById(
          `collapse-${kebabCase(title)}`,
        );
        if (collapseElement) {
          setTimeout(() => {
            // if ((collapseElement as any).scrollIntoViewIfNeeded) {
            //   (collapseElement as any).scrollIntoViewIfNeeded({
            //     behavior: "smooth",
            //   });
            // } else {
            //   collapseElement.scrollIntoView({ behavior: "smooth" });
            // }
            if (!skipScrollingIntoView) {
              collapseElement.scrollIntoView({ behavior: "smooth" });
            }
          }, 400);
        }
      },
      [history, panelKey, skipScrollingIntoView, title],
    );

    // Scroll Into View only  on first Render
    useEffect(() => {
      const panelId = `collapse-${kebabCase(title)}`;
      const collapseElement = document.getElementById(panelId);
      if (openPanels.includes(title) && collapseElement) {
        setTimeout(() => {
          if (!skipScrollingIntoView) {
            collapseElement.scrollIntoView({ behavior: "smooth" });
          }
        }, 800);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openPanel = useCallback(() => {
      changePanelState(true);
    }, [changePanelState]);

    useImperativeHandle(
      ref,
      () => ({
        openPanel: openPanel,
      }),
      [openPanel],
    );

    return (
      <div className="config-collapsible">
        <Collapse
          className="border-0 w-full"
          activeKey={openPanels}
          onChange={(activePanels) =>
            changePanelState(
              activePanels ? activePanels.includes(panelKey) : false,
            )
          }
          accordion
        >
          <Collapse.Panel
            id={`collapse-${kebabCase(title)}`}
            header={
              <CollapsibleHeader
                icon={
                  typeof icon === "string" ? (
                    <i className={`${icon} text-3xl`}></i>
                  ) : (
                    icon
                  )
                }
                title={title}
                description={description}
              />
            }
            key={panelKey}
          >
            {children}
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  },
);
