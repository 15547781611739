import { iFbPost } from "@sdk/facebook-posts/facebook-posts.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { fbPostsDefaultQueryConfig } from "./fb-posts-default-query";

export const {
  slice: FbPostsSlice,
  reducers: FbPostsSliceReducer,
  actions: {
    setEntityQueryResults: setFbPostsQueryResults,
    setEntity: setFbPost,
    setEntities: setFbPosts,
    patchEntity: patchFbPost,
    addNewEntity: addNewFbPost,
    setEntityFetchingStatus: setFbPostFetchingStatus,
    setEntitiesFetchingStatus: setFbPostsFetchingStatus,
    setEntityFailedStatus: setFbPostFailedStatus,
    setEntityQueryError: setFbPostsQueryError,
    resetQuery: resetFbPostsQuery,
    setQueryList: setFbPostQueryList
  }
} = CreateEntitySlice<iFbPost>({
  sliceName: "fbPosts",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...fbPostsDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
