import { iMagicAssistant } from "@sdk/magic-assistants/magic-assistants-model";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectMagicAssistantQueryMap,
  selectEntityMap: selectMagicAssistantsMap,
  selectQueryMapAll: selectMagicAssistantsMapAll,
  selectQueryAllList: selectMagicAssistantsAllList,
  selectAllEntities: selectAllMagicAssistants,
  selectAllEntitiesQuery: selectAllMagicAssistantsQuery,
  selectEntityById: selectMagicAssistantById,
  selectEntityQuery: selectMagicAssistantQuery,
  selectEntityQueryList: selectMagicAssistantQueryList,
} = CreateSelectorForEntities<iStore, iMagicAssistant>({
  sliceName: "magicAssistants",
});
