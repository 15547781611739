import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { useSDK } from "@sdk";
import { Button, Input, Modal } from "antd";
import { ContactAvatar } from "components/common/avatar/client-avatar";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";

import { useState } from "react";
import { ContactSearchQueryBuilder } from "../../helpers/contact-search-query-builder";
import { getContactName } from "../../helpers/get-contact-name";
import AddContact from "../add-contact/add-contact.modal-registry";
import "./contact-picker-modal.scss";

const ContactPickerModal = ({
  visible,
  onChangeVisibility,
  zIndex,
  onContactSelected,
  title,
  hideAdd,
  hideOnSelected,
}: {
  visible: boolean;
  onChangeVisibility: (state: boolean) => any;
  zIndex?: number;
  onContactSelected: (userId: string) => any;
  title?: JSX.Element;
  hideAdd?: boolean;
  hideOnSelected?: boolean;
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { data: originalData, isLoading } = useSDK(
    async (SDK) =>
      SDK.queryContacts({
        query: ContactSearchQueryBuilder(searchQuery),
        options: {
          offset: 0,
          page: 1,
          limit: 20,
        },
      }),
    [searchQuery],
    false,
    {},
  );

  const { triggerTempModal } = useModalPanels();

  return (
    <>
      <Modal
        title={
          title || (
            <ModalTitle
              title="Select a contact"
              icon={<i className="ri-group-line"></i>}
            />
          )
        }
        open={visible}
        zIndex={zIndex}
        footer={null}
        onCancel={() => {
          onChangeVisibility(false);
        }}
        okText="Save"
        data-click-context="User Picker Modal"
      >
        <div className="flex flex-col w-full contact-picker">
          <div className="search-container flex flex-row">
            <Input.Search
              placeholder="Search..."
              size="large"
              className="rounded-md mb-8"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ height: "3.4em" }}
            />
            <div
              className={
                "cursor-pointer add-button hover:text-blue-600 flex flex-row justify-center items-center"
              }
              style={{ height: "3.4em" }}
            >
              <i className="ri-add-line"></i>
            </div>
            <Button
              type="text"
              onClick={() =>
                triggerTempModal(AddContact, { hideOnCreate: true })
              }
            ></Button>
          </div>
          <div className="contacts-container">
            {isLoading && <div>Loading</div>}

            {!isLoading &&
              originalData.docs &&
              originalData.docs.map((contact) => (
                <div
                  className="flex flex-row items-center p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-900 border-b border-gray-200 dark:border-gray-800 mode_transition"
                  onClick={() => {
                    onContactSelected(contact.id!);
                    if (hideOnSelected) {
                      onChangeVisibility(false);
                    }
                  }}
                  key={contact.id}
                >
                  <div className="avatar-container">
                    <ContactAvatar contactId={contact.id!} />
                  </div>
                  <div className="texts flex flex-col flex-1 ml-2">
                    <div className="name font-bold">
                      {getContactName(contact)}
                    </div>
                    <div className="email">{contact.data.primaryEmail}</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <DarkModeBg />
      </Modal>
    </>
  );
};

export default ContactPickerModal;
