import { axios, AxiosResponse } from "@sdk/axios";
import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import { UserTracker } from "user-tracker";
import { iSpamEmail } from "./spam-email-model";

export class SpamEmailsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.spamEmails.configure(config);
  }

  spamEmails = Object.assign(
    new EntityServices<iSpamEmail>(SDKConfig, "spam-emails"),
    {
      markAsSafe: async (itemId: string) => {
        UserTracker.track("SPAM_EMAILS - Mark as Safe", {});
        const results: AxiosResponse<any> = await axios.patch(
          this.config.basePath + `/spam-emails/${itemId}/mark-as-safe`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
