import { Layout } from "antd";
import { connect } from "react-redux";
import { iStore } from "../../../store/store.model";
import "./full-screen-layout.style.scss";

const { Content, Footer } = Layout;

const mapState = (store: iStore) => ({});
const mapDispatch = dispatch => ({});

const _FullScreenLayout = (
  props: ReturnType<typeof mapState> &
    ReturnType<typeof mapDispatch> & {
      children: JSX.Element;
      banner?: JSX.Element;
    }
) => {
  return (
    <>
      <Layout className="layout h-screen flex-col flex bg-white dark:bg-gray-900 mode_transition">
        {props.banner}
        <Content style={{}} className="flex-1 mt-1">
          {props.children}
        </Content>
      </Layout>
    </>
  );
};

const fullScreenLayout = connect(mapState, mapDispatch);

export const FullScreenLayout = fullScreenLayout(_FullScreenLayout);
