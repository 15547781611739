import { createSlice, DeepPartial, PayloadAction } from "@reduxjs/toolkit";
import {
  iMessage,
  iMessageSenderType,
} from "@sdk/conversations/conversations.models";
import { PaginatedResults } from "@sdk/utils/paginated-results";
import _ from "lodash";
import { DeepAssign } from "utils/deep-assign";
import { iMessagesSlice } from "./messages.model";

const MESSAGES_PAGE_SIZE = 50;

const MessagesSlice = createSlice({
  name: "messages",
  initialState: {
    byConversations: {},
  } as iMessagesSlice,
  reducers: {
    addNewMessage(state, { payload: message }: PayloadAction<iMessage>) {
      // console.log("addNewMessage", message);
      if (state.byConversations[message.conversationId]) {
        const currentUserId = window["CC_CURRENT_USER_ID"];
        const messagesSnapshot = [
          ...state.byConversations[message.conversationId].data,
        ];

        if (
          message.connectionType === "WHATS_APP_PRIVATE" ||
          message.from.id === currentUserId
        ) {
          // Todo: Can optimize this by checking only the last 5 messages
          const totalMessage = messagesSnapshot.length;
          const last5Messages = messagesSnapshot.slice(-5);
          const _tempMessageIndex = _.findIndex(last5Messages, (messageX) => {
            if (
              messageX.isTemporary &&
              (messageX.message.trim() === message.message.trim() ||
                (messageX.data?.original &&
                  messageX.data?.original?.trim() ===
                    message?.data?.original?.trim()))
            ) {
              return true;
            }
            return false;
          });
          const tempMessageIndex =
            _tempMessageIndex > -1
              ? totalMessage - 5 + _tempMessageIndex
              : _tempMessageIndex;
          if (tempMessageIndex > -1) {
            messagesSnapshot[tempMessageIndex] = message;
          } else {
            messagesSnapshot.push(message);
          }
        } else {
          messagesSnapshot.push(message);
        }

        state.byConversations[message.conversationId].data = _.uniqBy(
          messagesSnapshot,
          "id",
        );

        state.byConversations[message.conversationId].newMessages++;
        // Unread count
        if (message.from.senderType === iMessageSenderType.CLIENT) {
          state.byConversations[message.conversationId].unread++;
        }
      } else {
        // Ignore
      }
    },
    removeTemporaryMessages(
      state,
      { payload: conversationId }: PayloadAction<string>,
    ) {
      if (state.byConversations[conversationId]) {
        const messagesSnapshot = [
          ...state.byConversations[conversationId].data,
        ];
        const filteredMessages = _.filter(
          state.byConversations[conversationId].data,
          (message) => !message.isTemporary,
        );
        state.byConversations[conversationId].data = filteredMessages;
      } else {
        // Ignore
      }
    },
    resetUnreadCount(
      state,
      { payload: conversationId }: PayloadAction<string>,
    ) {
      if (state.byConversations[conversationId]) {
        state.byConversations[conversationId].unread = 0;
      }
    },
    patchMessage(
      state,
      { payload: message }: PayloadAction<DeepPartial<iMessage>>,
    ) {
      if (state.byConversations[message.conversationId!]) {
        const messageInContext = _.find(
          state.byConversations[message.conversationId!].data,
          { id: message.id },
        );
        if (messageInContext) {
          DeepAssign(messageInContext, message);
        }
      }
    },
    setMessagesRes(
      state,
      {
        payload: { conversationId, messagesResults, reset },
      }: PayloadAction<{
        conversationId: string;
        messagesResults: PaginatedResults<iMessage>;
        reset?: boolean;
      }>,
    ) {
      if (!state.byConversations[conversationId] || reset) {
        if (messagesResults.offset !== 0) {
          throw "Messages should always load from page 1";
        }
        const page = Math.ceil(messagesResults.offset / 50) + 1;
        state.byConversations[conversationId] = {
          data: _.reverse(_.clone(messagesResults.docs)),
          fetchedPages: page,
          newMessages: 0,
          unread: 0,
          isFullyFetched: messagesResults.docs.length < MESSAGES_PAGE_SIZE,
          isFetching: false,
          hasError: false,
        };
      } else {
        // Loading More Messages
        const page = Math.ceil(messagesResults.offset / 50) + 1;
        if (page < state.byConversations[conversationId].fetchedPages) {
          throw "Messages should loaded be sequentially";
        }

        Object.assign(state.byConversations[conversationId], {
          fetchedPages: Math.max(
            state.byConversations[conversationId].fetchedPages + 1,
            page,
          ),
          isFetching: false,
          isFullyFetched: MESSAGES_PAGE_SIZE !== messagesResults.docs.length,
          // Todo: Check this, order might be in wrong wince we are allowing already loaded messages to load again
          data: _.uniqBy(
            [
              ..._.reverse(_.clone(messagesResults.docs)),
              ...state.byConversations[conversationId].data,
            ],
            "id",
          ),
        });
      }
    },
    setMessagesLoadingStatus(
      state,
      {
        payload: { conversationId, isLoading },
      }: PayloadAction<{ conversationId: string; isLoading: boolean }>,
    ) {
      if (!state.byConversations[conversationId]) {
        state.byConversations[conversationId] = {
          data: [],
          fetchedPages: 0,
          newMessages: 0,
          unread: 0,
          isFullyFetched: false,
          isFetching: isLoading,
          hasError: false,
        };
      } else {
        if (isLoading) {
          state.byConversations[conversationId].hasError = false;
        }
        state.byConversations[conversationId].isFetching = isLoading;
      }
    },
    setMessagesLoadingErrorStatus(
      state,
      {
        payload: { conversationId, hasError },
      }: PayloadAction<{ conversationId: string; hasError: boolean }>,
    ) {
      if (!state.byConversations[conversationId]) {
        state.byConversations[conversationId] = {
          data: [],
          fetchedPages: 0,
          newMessages: 0,
          unread: 0,
          isFullyFetched: false,
          isFetching: false,
          hasError: hasError,
        };
      } else {
        if (hasError) {
          state.byConversations[conversationId].isFetching = false;
        }
        state.byConversations[conversationId].hasError = hasError;
      }
    },
  },
});

export const MessagesSliceReducers = MessagesSlice.reducer;

export const MessagesSliceActions = MessagesSlice.actions;
