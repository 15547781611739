import { axios, AxiosResponse } from "@sdk/axios";
import { SDKConfig } from "@sdk/default-sdk-config";
import { iFileRecord } from "@sdk/file-management/file-management.models";
import { EntityServices } from "@sdk/utils/entity.service";
import { UserTracker } from "user-tracker";
import { iHolidayQuote } from "./holiday-quotes-model";

export class HolidayQuotesServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.holidayQuotes.configure(config);
  }

  holidayQuotes = Object.assign(
    new EntityServices<iHolidayQuote>(SDKConfig, "holiday-quotes", {
      onCreate: () => {
        UserTracker.track("HOLIDAY_QUOTES - Create", {});
      },
      onDelete: () => {
        UserTracker.track("HOLIDAY_QUOTES - Delete", {});
      },
    }),
    {
      sendQuote: async (quoteId: string, connectionId: string) => {
        UserTracker.track("HOLIDAY_QUOTES - Send Quote");
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/holiday-quotes/${quoteId}/send`,
          { connectionId },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      rateAndComment: async (
        quoteId: string,
        req: { ratings: number; comment: string },
      ) => {
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/holiday-quotes/${quoteId}/rate-and-comment`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      generatePDF: async (quoteId: string) => {
        UserTracker.track("HOLIDAY_QUOTES - Generate PDF");
        const results: AxiosResponse<{ file: iFileRecord }> = await axios.post(
          this.config.basePath + `/holiday-quotes/${quoteId}/generate-pdf`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      generateImage: async (quoteId: string) => {
        UserTracker.track("HOLIDAY_QUOTES - Generate Image");
        const results: AxiosResponse<{ file: iFileRecord }> = await axios.post(
          this.config.basePath + `/holiday-quotes/${quoteId}/generate-image`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
