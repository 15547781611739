import { Select } from "antd";
import { useMemo } from "react";

function generateTimeOfTheDay(
  options: {
    startTime: { hour: number; minute: number };
    endTime: { hour: number; minute: number };
  }[],
) {
  const timeOfTheDays: { label: string; hour: number; minutes: number }[] = [];

  for (const option of options) {
    if (option.startTime.hour < option.endTime.hour) {
      const startMinutes = option.startTime.hour * 60 + option.startTime.minute;
      const endMinutes = option.endTime.hour * 60 + option.endTime.minute;
      // Todo: Workout the offset
      const offSetMinutes = new Date().getTimezoneOffset();
      const startMinuteRounded = Math.ceil(startMinutes / 15) * 15;
      for (
        let roller = startMinuteRounded;
        roller <= endMinutes;
        roller = roller + 15
      ) {
        const hour = Math.floor(roller / 60);
        let displayHour = ("00" + hour).substring(hour.toString().length);
        const minutes = roller % 60;

        let amPm = "am";
        let timeOfTheDay = "morning";

        if (hour > 12) {
          amPm = "pm";
          displayHour = ("00" + (hour - 12)).substring(
            (hour - 12).toString().length,
          );
        }
        if (hour === 12) {
          amPm = "pm";
        }

        if (hour === 12 && minutes === 0) {
          timeOfTheDay = "noon";
        }

        if (hour > 12) {
          timeOfTheDay = "afternoon";
        }

        if (hour >= 16) {
          timeOfTheDay = "evening";
        }

        timeOfTheDays.push({
          label: `${displayHour}:${("00" + minutes).substring(
            minutes.toString().length,
          )} ${amPm}`,
          // in the ${timeOfTheDay}
          hour,
          minutes,
        });
      }
    }
  }
  return timeOfTheDays;
}

export const CustomTimePicker = (props) => {
  const options = useMemo(() => {
    return generateTimeOfTheDay([
      {
        startTime: { hour: 0, minute: 0 },
        endTime: { hour: 23, minute: 45 },
      },
    ]);
  }, []);

  return (
    <Select
      {...props}
      allowClear
      showSearch
      optionFilterProp="value"
      filterOption={(input, option) => {
        return String(option?.children || "").includes(input || "");
      }}
    >
      {options.map((option) => (
        <Select.Option
          value={`${option.hour}:${option.minutes}`}
          key={option.label}
          label={option.label}
        >
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};
