import { Avatar as _Avatar } from "antd";
import classNames from "classnames";
import React, { useMemo } from "react";
import { LightenDarkenColor } from "../../../utils/color-utils";
import { getHashedColor } from "../../../utils/get-hashed-color";
import "./avatar.scss";
import { NamedAvatar } from "./named-avatar";

export const Avatar = ({
  name,
  avatarUrl,
  avatarColor,
  size,
  style,
  className,
}: {
  name?: string;
  avatarUrl?: string;
  avatarColor?: string;
  size?: number;
  style?: React.CSSProperties;
  className?: string;
}) => {
  const firstLetter = name?.charAt(0).toUpperCase() || "U";
  const avatarStyle = useMemo(() => {
    return {
      backgroundColor:
        avatarColor ||
        LightenDarkenColor(getHashedColor(firstLetter || ""), -160),
      ...(style || {}),
    };
  }, [style, avatarColor, firstLetter]);
  if (avatarUrl) {
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <_Avatar
        style={avatarStyle}
        size={size}
        className={classNames(classNames, "rounded-xl")}
        src={avatarUrl}
      ></_Avatar>
    );
  }
  return (
    <NamedAvatar
      name={name}
      size={size}
      className={classNames(classNames, "rounded-xl")}
    />
  );
};

export const AvatarGroup = _Avatar.Group;
