import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { Card } from "antd";
import classNames from "classnames";
import { EmptyData } from "components/common/empty-data/empty-data";
import { ModalTitle } from "components/common/modal-title";
import { TaskListItem } from "components/modules/tasks/task-list/task-list.item";

import TaskViewer from "components/modules/tasks/task-viewer/task-viewer.modal-registry";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { useMyTasks } from "./use-my-tasks";

export const MyTasksWidget = ({ className }: { className?: string }) => {
  const { list: tasks, isLoading, totalItems, lastFetchedPage } = useMyTasks();

  const dispatch = useDispatch();
  const currentUserId = useSelector(selectCurrentUserId);

  const { changePanelState } = useModalPanels();

  return (
    <Card
      title={
        <ModalTitle
          title="Mentions and Tasks"
          icon={<i className="ri-task-line"></i>}
        />
      }
      bodyStyle={{ padding: 0 }}
      className={classNames("w-full h-full dark:bg-gray-950", className)}
    >
      {!isLoading && tasks.length === 0 && (
        <EmptyData
          icon={"ri-check-line"}
          text="All tasks have been completed"
        />
      )}
      {isLoading && "Loading..."}

      {tasks.map((item) => (
        <TaskListItem
          onTaskSelected={(taskId) => {
            changePanelState(TaskViewer, true, { taskId });
          }}
          task={item}
          key={item.id!}
        />
      ))}
    </Card>
  );
};
