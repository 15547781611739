import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const MatchedDocumentsViewerModal = React.lazy(
  () => import("./matched-documents-viewer.modal"),
);

const MatchedKnowledgeDocumentsViewer: iModalProps<
  typeof MatchedDocumentsViewerModal,
  {
    documentIds: string[];
  }
> = {
  name: "matched-knowledge-documents",
  component: GenerateModalWithSuspense(MatchedDocumentsViewerModal),
};

export default MatchedKnowledgeDocumentsViewer;
