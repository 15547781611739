import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iHolidayQuote } from "@sdk/holiday-quotes/holiday-quotes-model";
import React from "react";
import { iItineraryBlock } from "./itineries-model";

const QuoteBlockEditorModal = React.lazy(
  () => import("./quote-block-editor.modal"),
);

const QuoteBlockEditor: iModalProps<
  typeof QuoteBlockEditorModal,
  {
    onSave: (content: iItineraryBlock) => any;
    data: iItineraryBlock;
    holidayQuote: iHolidayQuote;
  }
> = {
  name: "quote-block-editor",
  component: GenerateModalWithSuspense(QuoteBlockEditorModal),
};

export default QuoteBlockEditor;
