import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { SendingDomainConfig } from "components/modules/conversations/components/sending-domains/sending-domain-config/sending-domain-config";
import { useEffect, useMemo } from "react";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { loadAllSendingDomains } from "store/modules/sending-domains/sending-domains.helpers";
import { selectAllSendingDomains } from "store/modules/sending-domains/sending-domains.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const ConnectionSendingDomainConfig = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const { state: connection } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const defaultSendingEmail = useMemo(() => {
    return connection.data.defaultSendingEmail;
  }, [connection?.data?.defaultSendingEmail]);

  const customHostname = defaultSendingEmail.split("@")[1] || "";

  // const customHostname = "helpdesk-local.clickconnector.com";

  const { state: allSendingDomains, isLoading } = useQueryWithStore(
    selectAllSendingDomains,
    loadAllSendingDomains(),
    [],
  );

  const sendingDomain = useMemo(() => {
    return allSendingDomains.find((item) => item.domain === customHostname);
  }, [allSendingDomains, customHostname]);

  const { doAction: createSendingDomainConfig, isProcessing: isCreating } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (val) => SDK.sendingDomains.create(val),
        // successMessage: "Primary Inbox has been created",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  useEffect(() => {
    if (!sendingDomain) {
      createSendingDomainConfig({
        domain: customHostname,
      });
    }
  }, [createSendingDomainConfig, customHostname, sendingDomain]);

  if (!sendingDomain) {
    return <></>;
  }

  return <SendingDomainConfig sendingDomain={sendingDomain} />;
};
