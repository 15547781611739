import { useMemo } from "react";
import { useLocation } from "react-router";

export function useUrlQuery() {
  const location = useLocation();
  const search = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  return search;
}
