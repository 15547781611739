import { useSDK } from "@sdk";
import { Select } from "antd";

export const MailchimpListSelector = ({
  value,
  onChange,
  apiKey
}: {
  value?: string;
  onChange?: (value: string) => any;
  apiKey: string;
}) => {
  const { data, isLoading, error: hasStatusError } = useSDK(
    SDK => SDK.mailchimp.getAllList(apiKey),
    [apiKey],
    !apiKey
  );

  return (
    <Select value={value} onChange={onChange} loading={isLoading}>
      {(data.lists || []).map(item => (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
};
