export const ProtobiQueryUnDot = function (obj, key) {
  try {
    const keys = key.split(".");
    let sub = obj;
    for (let i = 0; i < keys.length; i++) {
      sub = sub[keys[i]];
    }
    return sub;
  } catch (e) {
    return undefined;
  }
};
