import { iContact } from "@sdk/crm/crm.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { contactsDefaultQueryConfig } from "./contacts-default-query";

export const {
  slice: ContactsSlice,
  reducers: ContactsSliceReducer,
  actions: {
    setEntityQueryResults: setContactsQueryResults,
    setEntity: setContact,
    setEntities: setContacts,
    patchEntity: patchContact,
    addNewEntity: addNewContact,
    removeEntity: removeContactRecord,
    setEntityFetchingStatus: setContactFetchingStatus,
    setEntitiesFetchingStatus: setContactsFetchingStatus,
    setEntityFailedStatus: setContactFailedStatus,
    setEntityQueryError: setContactsQueryError,
    resetQuery: resetContactsQuery,
    setQueryList: setContactsQueryList
  }
} = CreateEntitySlice<iContact>({
  sliceName: "contacts",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...contactsDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
