import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const HolidayQuoteCreatorModal = React.lazy(
  () => import("./holiday-quote-creator.modal"),
);

const HolidayQuoteCreator: iModalProps<
  typeof HolidayQuoteCreatorModal,
  {
    onAddAsAttachment: (content: string) => any;
    conversationId: string;
    contactId: string;
  }
> = {
  name: "generate-quote",
  component: GenerateModalWithSuspense(HolidayQuoteCreatorModal),
};

export default HolidayQuoteCreator;
