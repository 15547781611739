import { PushpinOutlined } from "@ant-design/icons";
import classNames from "classnames";

import "./title-bar.component.style.scss";

export const TitleBar = ({
  isFullScreen,
  isPinned,
  onClose,
  onFullScreen,
  onMinimize,
  onMaximize,
  onSwitchToCompactMode,
  onPin
}: {
  isFullScreen: boolean;
  isPinned: boolean;
  onClose: () => any;
  onFullScreen: () => any;
  onMinimize: () => any;
  onMaximize: () => any;
  onSwitchToCompactMode: () => any;
  onPin: (status: boolean) => any;
}) => {
  return (
    <div
      className={classNames(
        "titlebar flex flex-row justify-between",
        // "bg-white dark:bg-gray-900",
        {
          alt: isFullScreen
        }
      )}
    >
      <div className="titlebar-stoplight">
        <div className="titlebar-close" onClick={onClose}>
          <svg x="0px" y="0px" viewBox="0 0 6.4 6.4">
            <polygon
              fill="#4d0000"
              points="6.4,0.8 5.6,0 3.2,2.4 0.8,0 0,0.8 2.4,3.2 0,5.6 0.8,6.4 3.2,4 5.6,6.4 6.4,5.6 4,3.2"
            ></polygon>
          </svg>
        </div>
        <div className="titlebar-minimize" onClick={onMinimize}>
          <svg x="0px" y="0px" viewBox="0 0 8 1.1">
            <rect fill="#995700" width="8" height="1.1"></rect>
          </svg>
        </div>
        <div
          className="titlebar-fullscreen"
          onClick={isFullScreen ? onMaximize : onFullScreen}
        >
          <svg className="fullscreen-svg" x="0px" y="0px" viewBox="0 0 6 5.9">
            <path
              fill="#006400"
              d="M5.4,0h-4L6,4.5V0.6C5.7,0.6,5.3,0.3,5.4,0z"
            ></path>
            <path
              fill="#006400"
              d="M0.6,5.9h4L0,1.4l0,3.9C0.3,5.3,0.6,5.6,0.6,5.9z"
            ></path>
          </svg>
          <svg className="maximize-svg" x="0px" y="0px" viewBox="0 0 8 1.1">
            <rect fill="#006400" width="8" height="1.1"></rect>
          </svg>
        </div>
      </div>
      <div className="empty-div cursor-move flex-1 drag-window"></div>
      <div className="right-side flex flex-row justify-center items-center float-right">
        <div
          className={classNames(
            "pin-window w-5 transform scale-75 flex flex-row justify-center items-center cursor-pointer",
            {
              "text-red-600 -rotate-45": isPinned
            }
          )}
          onClick={() => onPin(!isPinned)}
        >
          <PushpinOutlined />
        </div>
      </div>
    </div>
  );
};
