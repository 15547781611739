import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ManagePresetsModalX = React.lazy(
  () => import("./manage-presets-x.modal"),
);

const MyManagePresets: iModalProps<typeof ManagePresetsModalX, {}> = {
  name: "manage-presets",
  component: GenerateModalWithSuspense(ManagePresetsModalX),
};

export default MyManagePresets;
