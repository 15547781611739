import { axios } from "@sdk/axios";
import { PaginatedResults } from "@sdk/utils/paginated-results";
import { AxiosResponse } from "axios";
import { iDynamicForm } from "./dangerous-deletion-model";

export class DangerousDeletionServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  dangerousDeletion = {
    deleteOrganization: async (confirmation: string) => {
      const results: AxiosResponse<PaginatedResults<
        iDynamicForm
      >> = await axios.post(
        this.config.basePath + `/dangerous-deletion/delete-organization`,
        { confirmation },
        {
          headers: {
            Authorization: this.config.token
          }
        }
      );
      return results.data;
    },
    resetOrganization: async (confirmation: string) => {
      const results: AxiosResponse<PaginatedResults<
        iDynamicForm
      >> = await axios.post(
        this.config.basePath + `/dangerous-deletion/reset-organization`,
        { confirmation },
        {
          headers: {
            Authorization: this.config.token
          }
        }
      );
      return results.data;
    },
    resetConnections: async (confirmation: string) => {
      const results: AxiosResponse<PaginatedResults<
        iDynamicForm
      >> = await axios.post(
        this.config.basePath + `/dangerous-deletion/reset-connections`,
        { confirmation },
        {
          headers: {
            Authorization: this.config.token
          }
        }
      );
      return results.data;
    },
    deleteConnection: async (connectionId: string, confirmation: string) => {
      const results: AxiosResponse<PaginatedResults<
        iDynamicForm
      >> = await axios.post(
        this.config.basePath + `/dangerous-deletion/delete-connection`,
        { confirmation, connectionId },
        {
          headers: {
            Authorization: this.config.token
          }
        }
      );
      return results.data;
    },
    resetContacts: async (confirmation: string) => {
      const results: AxiosResponse<PaginatedResults<
        iDynamicForm
      >> = await axios.post(
        this.config.basePath + `/dangerous-deletion/reset-contacts`,
        { confirmation },
        {
          headers: {
            Authorization: this.config.token
          }
        }
      );
      return results.data;
    },
    resetConversations: async (confirmation: string) => {
      const results: AxiosResponse<PaginatedResults<
        iDynamicForm
      >> = await axios.post(
        this.config.basePath + `/dangerous-deletion/reset-conversations`,
        { confirmation },
        {
          headers: {
            Authorization: this.config.token
          }
        }
      );
      return results.data;
    },
    deleteContact: async (contactId: string, confirmation: string) => {
      const results: AxiosResponse<PaginatedResults<
        iDynamicForm
      >> = await axios.post(
        this.config.basePath + `/dangerous-deletion/delete-contact`,
        { confirmation, contactId },
        {
          headers: {
            Authorization: this.config.token
          }
        }
      );
      return results.data;
    },
    deleteConversation: async (
      conversationId: string,
      confirmation: string
    ) => {
      const results: AxiosResponse<PaginatedResults<
        iDynamicForm
      >> = await axios.post(
        this.config.basePath + `/dangerous-deletion/delete-conversation`,
        { confirmation, conversationId },
        {
          headers: {
            Authorization: this.config.token
          }
        }
      );
      return results.data;
    }
  };
}
