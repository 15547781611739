import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ManageChatCampaignsModal = React.lazy(
  () => import("./manage-chat-campaigns.modal"),
);

const ManageChatCampaigns: iModalProps<
  typeof ManageChatCampaignsModal,
  {
    connectionId: string;
  }
> = {
  name: "manage-chat-campaigns",
  component: GenerateModalWithSuspense(ManageChatCampaignsModal),
};

export default ManageChatCampaigns;
