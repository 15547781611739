import { iIgMedia } from "@sdk/ig-media/instagram-models";
import { createSelector } from "reselect";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectIgMediaQueryMap,
  selectEntityMap: selectIgMediasMap,
  selectQueryMapAll: selectIgMediasMapAll,
  selectQueryAllList: selectIgMediasAllList,
  selectAllEntities: selectAllIgMedias,
  selectAllEntitiesQuery: selectAllIgMediasQuery,
  selectEntityById: selectIgMediaById,
  selectEntityQuery: selectIgMediaQuery,
  selectEntityQueryList: selectIgMediaQueryList
} = CreateSelectorForEntities<iStore, iIgMedia>({
  sliceName: "igMedias"
});

export const selectUnresolvedCountForIGMediaQuery = (query: string) =>
  createSelector([selectIgMediaQuery(query)], _igMedias => {
    const igMedias = _igMedias.list || [];
    return igMedias.reduce(
      (acc, igMedia) => {
        const unresolved =
          (igMedia.comments_count || 0) -
          (igMedia.metaData.resolvedCommentCount || 0);
        acc.count = acc.count + unresolved;
        return acc;
      },
      {
        count: 0
      }
    );
  });
