import { ProtobiQueryUnDot } from "@libs/protobi-query-patches";
import _ from "lodash";
import { iEntitySlice } from "./enitity-slice-model";
import { QueryAliasResolver } from "./query-alias-resolver";
import { QueryConfig } from "./query-config";

import Query from "query";

const isJson = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const deduceListChangesOnNewEntity = (
  entity: any & { id: string },
  state: Partial<iEntitySlice<any>>,
  resolveQueryAlias?: QueryAliasResolver,
): {
  listToRemoveFrom: string[];
  listToAddTo: string[];
  matchedQueries: (QueryConfig & {
    alias: string;
  })[];
} => {
  const entityId = entity.id;

  const entityQueryMap = state.queries!;
  const queryConfigAliases = Object.keys(entityQueryMap);

  const existingList = queryConfigAliases.filter(
    (query) => entityQueryMap[query].list.indexOf(entityId) > -1,
  );

  const allQueries: (QueryConfig & {
    alias: string;
  })[] = queryConfigAliases.map((queryConfigAlias) => {
    if (resolveQueryAlias) {
      const resolvedQuery = resolveQueryAlias(queryConfigAlias);
      if (resolvedQuery) {
        return resolvedQuery;
      }
    }

    if (!isJson(queryConfigAlias)) {
      console.error("Probably Query Alias Resolver is missing");
    }

    const parsedQueryConfig = JSON.parse(queryConfigAlias) as QueryConfig;
    return {
      ...parsedQueryConfig,
      alias: queryConfigAlias,
    };
  });

  const matchedQueries = allQueries.filter((queryConfig) => {
    try {
      const matches = Query.query(
        [entity],
        queryConfig.query,
        ProtobiQueryUnDot,
      );
      return matches.length > 0;
    } catch (e) {
      console.log(
        "Error while processing protobi Query",
        [entity],
        queryConfig.query,
      );
      return true;
    }
  });

  // Remove from the removed List
  const listToRemoveFrom = _.difference(
    existingList,
    matchedQueries.map((item) => item.alias),
  );

  // Add to the new lists
  const listToAddTo = _.difference(
    matchedQueries.map((item) => item.alias),
    existingList,
  );
  return { listToRemoveFrom, listToAddTo, matchedQueries };
};
