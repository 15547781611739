import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddFacebookConnectionModal = React.lazy(
  () => import("./add-facebook-connection.modal"),
);

const AddFacebookConnection: iModalProps<typeof AddFacebookConnectionModal> = {
  name: "add-facebook-connection",
  component: GenerateModalWithSuspense(AddFacebookConnectionModal),
};

export default AddFacebookConnection;
