import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddEditCheckListModal = React.lazy(
  () => import("./add-edit-checklist-tour.modal"),
);

const AddEditCheckList: iModalProps<
  typeof AddEditCheckListModal,
  {
    checkListId?: string;
  }
> = {
  name: "add-edit-checklist",
  component: GenerateModalWithSuspense(AddEditCheckListModal),
};

export default AddEditCheckList;
