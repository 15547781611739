import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iCheckListStep } from "@sdk/checklists/checklists-model";
import React from "react";

const CheckListStepEditorModal = React.lazy(
  () => import("./checklist-step-editor.modal"),
);

const CheckListStepEditor: iModalProps<
  typeof CheckListStepEditorModal,
  {
    step: iCheckListStep;
    onChange: (step: iCheckListStep) => any;
  }
> = {
  name: "checklist-step-editor",
  component: GenerateModalWithSuspense(CheckListStepEditorModal),
};

export default CheckListStepEditor;
