import { useEffect } from "react";
import { LocalNotificationsService } from "./local-notifications-controller";
import { usePushNotificationStatus } from "./use-push-noitificaiton-status";

export const useAutoSubscribeToPush = () => {
  const pushStatus = usePushNotificationStatus();
  useEffect(() => {
    const onPermissionStateChange = () => {
      if (LocalNotificationsService.hasPermission) {
        // Subscribed
        pushStatus.subscribeToPush();
      }
    };
    LocalNotificationsService.permissionEvents.addListener(
      "PERMISSION_CHANGE",
      onPermissionStateChange,
    );

    // cleanup this component
    return () => {
      LocalNotificationsService.permissionEvents.removeListener(
        "PERMISSION_CHANGE",
        onPermissionStateChange,
      );
    };
  }, [pushStatus]);

  return pushStatus;
};
