import { SDK } from "@sdk";

import { iTask } from "@sdk/tasks/task-model";
import { Store } from "redux";
import { QueryConfig } from "store/utils/query-config";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { TasksSlice } from "./tasks.slice";

export const loadQueryTasks = (
  queryConfig: QueryConfig,
  queryAlias?: string
) => async (store: Store<iStore>, forceReload?: boolean) => {
  queryAlias = queryAlias || JSON.stringify(queryConfig);
  await loadTasksQuery(queryConfig, queryAlias)(store, forceReload);
};

export const {
  loadEntityQueries: loadTasksQuery,
  loadEntityById: loadTaskById,
  loadMoreEntityQueries: loadMoreTasksQueries
} = CreateHelpersForEntity<iStore, iTask>({
  sliceName: "tasks",
  slice: TasksSlice,
  isPaginatedQueries: true,
  fetchPageSize: 50,
  loadEntityQueryProvider: query => SDK.tasks.query(query),
  loadEntityByIdProvider: (entityId: string) => SDK.tasks.getById(entityId)
});
