export const CountryCodes: {
  name: string;
  dialCode: string;
  code: string;
  currencyCode?: string;
  currencySymbol?: string;
}[] = [
  { name: "Afghanistan", dialCode: "+93", code: "AF", currencyCode: "AFN" },
  {
    name: "Aland Islands",
    dialCode: "+358",
    code: "AX"
  },
  { name: "Albania", dialCode: "+355", code: "AL", currencyCode: "ALL" },
  { name: "Algeria", dialCode: "+213", code: "DZ", currencyCode: "DZD" },
  {
    name: "AmericanSamoa",
    dialCode: "+1684",
    code: "AS"
  },
  {
    name: "Andorra",
    dialCode: "+376",
    code: "AD",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  { name: "Angola", dialCode: "+244", code: "AO", currencyCode: "AOA" },
  { name: "Anguilla", dialCode: "+1264", code: "AI", currencyCode: "XCD" },
  { name: "Antarctica", dialCode: "+672", code: "AQ", currencyCode: "XCD" },
  {
    name: "Antigua and Barbuda",
    dialCode: "+1268",
    code: "AG",
    currencyCode: "XCD"
  },
  { name: "Argentina", dialCode: "+54", code: "AR", currencyCode: "ARS" },
  { name: "Armenia", dialCode: "+374", code: "AM", currencyCode: "AMD" },
  { name: "Aruba", dialCode: "+297", code: "AW", currencyCode: "AWG" },
  { name: "Australia", dialCode: "+61", code: "AU", currencyCode: "AUD" },
  {
    name: "Austria",
    dialCode: "+43",
    code: "AT",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  { name: "Azerbaijan", dialCode: "+994", code: "AZ", currencyCode: "AZN" },
  { name: "Bahamas", dialCode: "+1242", code: "BS", currencyCode: "BSD" },
  { name: "Bahrain", dialCode: "+973", code: "BH", currencyCode: "BHD" },
  { name: "Bangladesh", dialCode: "+880", code: "BD", currencyCode: "BDT" },
  { name: "Barbados", dialCode: "+1246", code: "BB", currencyCode: "BBD" },
  { name: "Belarus", dialCode: "+375", code: "BY", currencyCode: "BYR" },
  {
    name: "Belgium",
    dialCode: "+32",
    code: "BE",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  { name: "Belize", dialCode: "+501", code: "BZ", currencyCode: "BZD" },
  { name: "Benin", dialCode: "+229", code: "BJ", currencyCode: "XOF" },
  { name: "Bermuda", dialCode: "+1441", code: "BM", currencyCode: "BMD" },
  { name: "Bhutan", dialCode: "+975", code: "BT", currencyCode: "BTN" },
  {
    name: "Bolivia, Plurinational State of Bolivia",
    dialCode: "+591",
    code: "BO"
  },
  {
    name: "Bosnia and Herzegovina",
    dialCode: "+387",
    code: "BA",
    currencyCode: "BAM"
  },
  { name: "Botswana", dialCode: "+267", code: "BW", currencyCode: "BWP" },
  { name: "Bouvet Island", dialCode: "+47", code: "BV", currencyCode: "NOK" },
  { name: "Brazil", dialCode: "+55", code: "BR", currencyCode: "BRL" },
  {
    name: "British Indian Ocean Territory",
    dialCode: "+246",
    code: "IO",
    currencyCode: "USD",
    currencySymbol: "$"
  },
  {
    name: "Brunei Darussalam",
    dialCode: "+673",
    code: "BN"
  },
  { name: "Bulgaria", dialCode: "+359", code: "BG", currencyCode: "BGN" },
  { name: "Burkina Faso", dialCode: "+226", code: "BF", currencyCode: "XOF" },
  { name: "Burundi", dialCode: "+257", code: "BI", currencyCode: "BIF" },
  { name: "Cambodia", dialCode: "+855", code: "KH", currencyCode: "KHR" },
  { name: "Cameroon", dialCode: "+237", code: "CM", currencyCode: "XAF" },
  { name: "Canada", dialCode: "+1", code: "CA", currencyCode: "CAD" },
  { name: "Cape Verde", dialCode: "+238", code: "CV", currencyCode: "CVE" },
  {
    name: "Cayman Islands",
    dialCode: "+1345",
    code: "KY",
    currencyCode: "KYD"
  },
  {
    name: "Central African Republic",
    dialCode: "+236",
    code: "CF",
    currencyCode: "XAF"
  },
  { name: "Chad", dialCode: "+235", code: "TD", currencyCode: "XAF" },
  { name: "Chile", dialCode: "+56", code: "CL", currencyCode: "CLP" },
  { name: "China", dialCode: "+86", code: "CN", currencyCode: "CNY" },
  {
    name: "Christmas Island",
    dialCode: "+61",
    code: "CX",
    currencyCode: "AUD"
  },
  {
    name: "Cocos (Keeling) Islands",
    dialCode: "+61",
    code: "CC",
    currencyCode: "AUD"
  },
  { name: "Colombia", dialCode: "+57", code: "CO", currencyCode: "COP" },
  { name: "Comoros", dialCode: "+269", code: "KM", currencyCode: "KMF" },
  { name: "Congo", dialCode: "+242", code: "CG", currencyCode: "XAF" },
  {
    name: "Congo, The Democratic Republic of the Congo",
    dialCode: "+243",
    code: "CD"
  },
  { name: "Cook Islands", dialCode: "+682", code: "CK", currencyCode: "NZD" },
  { name: "Costa Rica", dialCode: "+506", code: "CR", currencyCode: "CRC" },
  {
    name: "Cote d'Ivoire",
    dialCode: "+225",
    code: "CI"
  },
  { name: "Croatia", dialCode: "+385", code: "HR", currencyCode: "HRK" },
  { name: "Cuba", dialCode: "+53", code: "CU", currencyCode: "CUP" },
  {
    name: "Cyprus",
    dialCode: "+357",
    code: "CY",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  { name: "Czech Republic", dialCode: "+420", code: "CZ", currencyCode: "CZK" },
  { name: "Denmark", dialCode: "+45", code: "DK", currencyCode: "DKK" },
  { name: "Djibouti", dialCode: "+253", code: "DJ", currencyCode: "DJF" },
  { name: "Dominica", dialCode: "+1767", code: "DM", currencyCode: "XCD" },
  {
    name: "Dominican Republic",
    dialCode: "+1849",
    code: "DO",
    currencyCode: "DOP"
  },
  { name: "Ecuador", dialCode: "+593", code: "EC", currencyCode: "ECS" },
  { name: "Egypt", dialCode: "+20", code: "EG", currencyCode: "EGP" },
  { name: "El Salvador", dialCode: "+503", code: "SV", currencyCode: "SVC" },
  {
    name: "Equatorial Guinea",
    dialCode: "+240",
    code: "GQ",
    currencyCode: "XAF"
  },
  { name: "Eritrea", dialCode: "+291", code: "ER", currencyCode: "ERN" },
  {
    name: "Estonia",
    dialCode: "+372",
    code: "EE",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  { name: "Ethiopia", dialCode: "+251", code: "ET", currencyCode: "ETB" },
  {
    name: "Falkland Islands (Malvinas)",
    dialCode: "+500",
    code: "FK"
  },
  { name: "Faroe Islands", dialCode: "+298", code: "FO", currencyCode: "DKK" },
  { name: "Fiji", dialCode: "+679", code: "FJ", currencyCode: undefined },
  {
    name: "Finland",
    dialCode: "+358",
    code: "FI",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  {
    name: "France",
    dialCode: "+33",
    code: "FR",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  {
    name: "French Guiana",
    dialCode: "+594",
    code: "GF",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  {
    name: "French Polynesia",
    dialCode: "+689",
    code: "PF",
    currencyCode: "XPF"
  },
  { name: "Gabon", dialCode: "+241", code: "GA", currencyCode: "XAF" },
  { name: "Gambia", dialCode: "+220", code: "GM", currencyCode: "GMD" },
  { name: "Georgia", dialCode: "+995", code: "GE", currencyCode: "GEL" },
  {
    name: "Germany",
    dialCode: "+49",
    code: "DE",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  { name: "Ghana", dialCode: "+233", code: "GH", currencyCode: "GHS" },
  { name: "Gibraltar", dialCode: "+350", code: "GI", currencyCode: "GIP" },
  {
    name: "Greece",
    dialCode: "+30",
    code: "GR",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  { name: "Greenland", dialCode: "+299", code: "GL", currencyCode: "DKK" },
  { name: "Grenada", dialCode: "+1473", code: "GD", currencyCode: "XCD" },
  {
    name: "Guadeloupe",
    dialCode: "+590",
    code: "GP",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  {
    name: "Guam",
    dialCode: "+1671",
    code: "GU",
    currencyCode: "USD",
    currencySymbol: "$"
  },
  { name: "Guatemala", dialCode: "+502", code: "GT", currencyCode: "QTQ" },
  { name: "Guernsey", dialCode: "+44", code: "GG", currencyCode: undefined },
  { name: "Guinea", dialCode: "+224", code: "GN", currencyCode: "GNF" },
  { name: "Guinea-Bissau", dialCode: "+245", code: "GW", currencyCode: "CFA" },
  { name: "Guyana", dialCode: "+592", code: "GY", currencyCode: "GYD" },
  { name: "Haiti", dialCode: "+509", code: "HT", currencyCode: "HTG" },
  {
    name: "Heard Island and Mcdonald Islands",
    dialCode: "+672",
    code: "HM"
  },
  {
    name: "Holy See (Vatican City State)",
    dialCode: "+379",
    code: "VA",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  { name: "Honduras", dialCode: "+504", code: "HN", currencyCode: "HNL" },
  { name: "Hong Kong", dialCode: "+852", code: "HK", currencyCode: "HKD" },
  { name: "Hungary", dialCode: "+36", code: "HU", currencyCode: "HUF" },
  { name: "Iceland", dialCode: "+354", code: "IS", currencyCode: "ISK" },
  { name: "India", dialCode: "+91", code: "IN", currencyCode: "INR" },
  { name: "Indonesia", dialCode: "+62", code: "ID", currencyCode: "IDR" },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    dialCode: "+98",
    code: "IR"
  },
  { name: "Iraq", dialCode: "+964", code: "IQ", currencyCode: "IQD" },
  {
    name: "Ireland",
    dialCode: "+353",
    code: "IE",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  { name: "Isle of Man", dialCode: "+44", code: "IM", currencyCode: undefined },
  { name: "Israel", dialCode: "+972", code: "IL", currencyCode: "ILS" },
  {
    name: "Italy",
    dialCode: "+39",
    code: "IT",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  { name: "Jamaica", dialCode: "+1876", code: "JM", currencyCode: "JMD" },
  { name: "Japan", dialCode: "+81", code: "JP", currencyCode: "JPY" },
  { name: "Jersey", dialCode: "+44", code: "JE", currencyCode: undefined },
  { name: "Jordan", dialCode: "+962", code: "JO", currencyCode: "JOD" },
  { name: "Kazakhstan", dialCode: "+77", code: "KZ", currencyCode: "KZT" },
  { name: "Kenya", dialCode: "+254", code: "KE", currencyCode: "KES" },
  { name: "Kiribati", dialCode: "+686", code: "KI", currencyCode: "AUD" },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dialCode: "+850",
    code: "KP"
  },
  {
    name: "Korea, Republic of South Korea",
    dialCode: "+82",
    code: "KR"
  },
  { name: "Kosovo", dialCode: "+383", code: "XK", currencyCode: undefined },
  { name: "Kuwait", dialCode: "+965", code: "KW", currencyCode: "KWD" },
  { name: "Kyrgyzstan", dialCode: "+996", code: "KG", currencyCode: "KGS" },
  { name: "Laos", dialCode: "+856", code: "LA", currencyCode: "LAK" },
  { name: "Latvia", dialCode: "+371", code: "LV", currencyCode: "LVL" },
  { name: "Lebanon", dialCode: "+961", code: "LB", currencyCode: "LBP" },
  { name: "Lesotho", dialCode: "+266", code: "LS", currencyCode: "LSL" },
  { name: "Liberia", dialCode: "+231", code: "LR", currencyCode: "LRD" },
  {
    name: "Libyan Arab Jamahiriya",
    dialCode: "+218",
    code: "LY",
    currencyCode: "LYD"
  },
  { name: "Liechtenstein", dialCode: "+423", code: "LI", currencyCode: "CHF" },
  { name: "Lithuania", dialCode: "+370", code: "LT", currencyCode: "LTL" },
  {
    name: "Luxembourg",
    dialCode: "+352",
    code: "LU",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  { name: "Macao", dialCode: "+853", code: "MO", currencyCode: undefined },
  { name: "Macedonia", dialCode: "+389", code: "MK", currencyCode: undefined },
  { name: "Madagascar", dialCode: "+261", code: "MG", currencyCode: "MGF" },
  { name: "Malawi", dialCode: "+265", code: "MW", currencyCode: "MWK" },
  { name: "Malaysia", dialCode: "+60", code: "MY", currencyCode: "MYR" },
  { name: "Maldives", dialCode: "+960", code: "MV", currencyCode: "MVR" },
  { name: "Mali", dialCode: "+223", code: "ML", currencyCode: "XOF" },
  {
    name: "Malta",
    dialCode: "+356",
    code: "MT",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  {
    name: "Marshall Islands",
    dialCode: "+692",
    code: "MH",
    currencyCode: "USD",
    currencySymbol: "$"
  },
  {
    name: "Martinique",
    dialCode: "+596",
    code: "MQ",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  { name: "Mauritania", dialCode: "+222", code: "MR", currencyCode: "MRO" },
  { name: "Mauritius", dialCode: "+230", code: "MU", currencyCode: "MUR" },
  {
    name: "Mayotte",
    dialCode: "+262",
    code: "YT",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  { name: "Mexico", dialCode: "+52", code: "MX", currencyCode: "MXN" },
  {
    name: "Micronesia, Federated States of Micronesia",
    dialCode: "+691",
    code: "FM"
  },
  { name: "Moldova", dialCode: "+373", code: "MD", currencyCode: "MDL" },
  {
    name: "Monaco",
    dialCode: "+377",
    code: "MC",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  { name: "Mongolia", dialCode: "+976", code: "MN", currencyCode: "MNT" },
  { name: "Montenegro", dialCode: "+382", code: "ME", currencyCode: undefined },
  { name: "Montserrat", dialCode: "+1664", code: "MS", currencyCode: "XCD" },
  { name: "Morocco", dialCode: "+212", code: "MA", currencyCode: "MAD" },
  { name: "Mozambique", dialCode: "+258", code: "MZ", currencyCode: "MZN" },
  { name: "Myanmar", dialCode: "+95", code: "MM", currencyCode: "MMR" },
  { name: "Namibia", dialCode: "+264", code: "NA", currencyCode: "NAD" },
  { name: "Nauru", dialCode: "+674", code: "NR", currencyCode: "AUD" },
  { name: "Nepal", dialCode: "+977", code: "NP", currencyCode: "NPR" },
  {
    name: "Netherlands",
    dialCode: "+31",
    code: "NL",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  {
    name: "Netherlands Antilles",
    dialCode: "+599",
    code: "AN",
    currencyCode: "ANG"
  },
  { name: "New Caledonia", dialCode: "+687", code: "NC", currencyCode: "XPF" },
  { name: "New Zealand", dialCode: "+64", code: "NZ", currencyCode: "NZD" },
  { name: "Nicaragua", dialCode: "+505", code: "NI", currencyCode: "NIO" },
  { name: "Niger", dialCode: "+227", code: "NE", currencyCode: "XOF" },
  { name: "Nigeria", dialCode: "+234", code: "NG", currencyCode: "NGN" },
  { name: "Niue", dialCode: "+683", code: "NU", currencyCode: "NZD" },
  { name: "Norfolk Island", dialCode: "+672", code: "NF", currencyCode: "AUD" },
  {
    name: "Northern Mariana Islands",
    dialCode: "+1670",
    code: "MP",
    currencyCode: "USD",
    currencySymbol: "$"
  },
  { name: "Norway", dialCode: "+47", code: "NO", currencyCode: "NOK" },
  { name: "Oman", dialCode: "+968", code: "OM", currencyCode: "OMR" },
  { name: "Pakistan", dialCode: "+92", code: "PK", currencyCode: "PKR" },
  {
    name: "Palau",
    dialCode: "+680",
    code: "PW",
    currencyCode: "USD",
    currencySymbol: "$"
  },
  {
    name: "Palestinian Territory, Occupied",
    dialCode: "+970",
    code: "PS"
  },
  { name: "Panama", dialCode: "+507", code: "PA", currencyCode: "PAB" },
  {
    name: "Papua New Guinea",
    dialCode: "+675",
    code: "PG",
    currencyCode: "PGK"
  },
  { name: "Paraguay", dialCode: "+595", code: "PY", currencyCode: "PYG" },
  { name: "Peru", dialCode: "+51", code: "PE", currencyCode: "PEN" },
  { name: "Philippines", dialCode: "+63", code: "PH", currencyCode: "PHP" },
  { name: "Pitcairn", dialCode: "+64", code: "PN", currencyCode: undefined },
  { name: "Poland", dialCode: "+48", code: "PL", currencyCode: "PLN" },
  {
    name: "Portugal",
    dialCode: "+351",
    code: "PT",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  {
    name: "Puerto Rico",
    dialCode: "+1939",
    code: "PR",
    currencyCode: "USD",
    currencySymbol: "$"
  },
  { name: "Qatar", dialCode: "+974", code: "QA", currencyCode: "QAR" },
  { name: "Romania", dialCode: "+40", code: "RO", currencyCode: "RON" },
  { name: "Russia", dialCode: "+7", code: "RU", currencyCode: undefined },
  { name: "Rwanda", dialCode: "+250", code: "RW", currencyCode: "RWF" },
  {
    name: "Reunion",
    dialCode: "+262",
    code: "RE",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  {
    name: "Saint Barthelemy",
    dialCode: "+590",
    code: "BL"
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dialCode: "+290",
    code: "SH"
  },
  {
    name: "Saint Kitts and Nevis",
    dialCode: "+1869",
    code: "KN",
    currencyCode: "XCD"
  },
  { name: "Saint Lucia", dialCode: "+1758", code: "LC", currencyCode: "XCD" },
  {
    name: "Saint Martin",
    dialCode: "+590",
    code: "MF"
  },
  {
    name: "Saint Pierre and Miquelon",
    dialCode: "+508",
    code: "PM",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  {
    name: "Saint Vincent and the Grenadines",
    dialCode: "+1784",
    code: "VC",
    currencyCode: "XCD"
  },
  { name: "Samoa", dialCode: "+685", code: "WS", currencyCode: "WST" },
  {
    name: "San Marino",
    dialCode: "+378",
    code: "SM",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  {
    name: "Sao Tome and Principe",
    dialCode: "+239",
    code: "ST",
    currencyCode: "STD"
  },
  { name: "Saudi Arabia", dialCode: "+966", code: "SA", currencyCode: "SAR" },
  { name: "Senegal", dialCode: "+221", code: "SN", currencyCode: "XOF" },
  { name: "Serbia", dialCode: "+381", code: "RS", currencyCode: "RSD" },
  { name: "Seychelles", dialCode: "+248", code: "SC", currencyCode: "SCR" },
  { name: "Sierra Leone", dialCode: "+232", code: "SL", currencyCode: "SLL" },
  { name: "Singapore", dialCode: "+65", code: "SG", currencyCode: "SGD" },
  {
    name: "Slovakia",
    dialCode: "+421",
    code: "SK",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  {
    name: "Slovenia",
    dialCode: "+386",
    code: "SI",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  {
    name: "Solomon Islands",
    dialCode: "+677",
    code: "SB",
    currencyCode: "SBD"
  },
  { name: "Somalia", dialCode: "+252", code: "SO", currencyCode: "SOS" },
  { name: "South Africa", dialCode: "+27", code: "ZA", currencyCode: "ZAR" },
  { name: "South Sudan", dialCode: "+211", code: "SS", currencyCode: "SSP" },
  {
    name: "South Georgia and the South Sandwich Islands",
    dialCode: "+500",
    code: "GS",
    currencyCode: "GBP"
  },
  {
    name: "Spain",
    dialCode: "+34",
    code: "ES",
    currencyCode: "EUR",
    currencySymbol: "€"
  },
  { name: "Sri Lanka", dialCode: "+94", code: "LK", currencyCode: "LKR" },
  { name: "Sudan", dialCode: "+249", code: "SD", currencyCode: "SDG" },
  { name: "Suriname", dialCode: "+597", code: "SR", currencyCode: "SRD" },
  {
    name: "Svalbard and Jan Mayen",
    dialCode: "+47",
    code: "SJ",
    currencyCode: "NOK"
  },
  { name: "Eswatini", dialCode: "+268", code: "SZ", currencyCode: undefined },
  { name: "Sweden", dialCode: "+46", code: "SE", currencyCode: "SEK" },
  { name: "Switzerland", dialCode: "+41", code: "CH", currencyCode: "CHF" },
  {
    name: "Syrian Arab Republic",
    dialCode: "+963",
    code: "SY"
  },
  { name: "Taiwan", dialCode: "+886", code: "TW", currencyCode: undefined },
  { name: "Tajikistan", dialCode: "+992", code: "TJ", currencyCode: "TJS" },
  {
    name: "Tanzania, United Republic of Tanzania",
    dialCode: "+255",
    code: "TZ"
  },
  { name: "Thailand", dialCode: "+66", code: "TH", currencyCode: "THB" },
  {
    name: "Timor-Leste",
    dialCode: "+670",
    code: "TL"
  },
  { name: "Togo", dialCode: "+228", code: "TG", currencyCode: "XOF" },
  { name: "Tokelau", dialCode: "+690", code: "TK", currencyCode: "NZD" },
  { name: "Tonga", dialCode: "+676", code: "TO", currencyCode: "TOP" },
  {
    name: "Trinidad and Tobago",
    dialCode: "+1868",
    code: "TT",
    currencyCode: "TTD"
  },
  { name: "Tunisia", dialCode: "+216", code: "TN", currencyCode: "TND" },
  { name: "Turkey", dialCode: "+90", code: "TR", currencyCode: "TRY" },
  { name: "Turkmenistan", dialCode: "+993", code: "TM", currencyCode: "TMT" },
  {
    name: "Turks and Caicos Islands",
    dialCode: "+1649",
    code: "TC",
    currencyCode: "USD",
    currencySymbol: "$"
  },
  { name: "Tuvalu", dialCode: "+688", code: "TV", currencyCode: "AUD" },
  { name: "Uganda", dialCode: "+256", code: "UG", currencyCode: "UGX" },
  { name: "Ukraine", dialCode: "+380", code: "UA", currencyCode: "UAH" },
  {
    name: "United Arab Emirates",
    dialCode: "+971",
    code: "AE",
    currencyCode: "AED"
  },
  {
    name: "United Kingdom",
    dialCode: "+44",
    code: "GB",
    currencyCode: "GBP",
    currencySymbol: "£"
  },
  { name: "United States", dialCode: "+1", code: "US", currencyCode: "USD" },
  { name: "Uruguay", dialCode: "+598", code: "UY", currencyCode: "UYU" },
  { name: "Uzbekistan", dialCode: "+998", code: "UZ", currencyCode: "UZS" },
  { name: "Vanuatu", dialCode: "+678", code: "VU", currencyCode: "VUV" },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dialCode: "+58",
    code: "VE"
  },
  { name: "Vietnam", dialCode: "+84", code: "VN", currencyCode: "VND" },
  {
    name: "Virgin Islands, British",
    dialCode: "+1284",
    code: "VG",
    currencyCode: "USD"
  },
  {
    name: "Virgin Islands, U.S.",
    dialCode: "+1340",
    code: "VI",
    currencyCode: "USD"
  },
  {
    name: "Wallis and Futuna",
    dialCode: "+681",
    code: "WF",
    currencyCode: "XPF"
  },
  { name: "Yemen", dialCode: "+967", code: "YE", currencyCode: "YER" },
  { name: "Zambia", dialCode: "+260", code: "ZM", currencyCode: "ZMW" },
  { name: "Zimbabwe", dialCode: "+263", code: "ZW", currencyCode: "ZWD" }
];
