import { useSelector } from "react-redux";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";

export const useBrandAndWorkspaceConfig = () => {
  const organization = useSelector(selectOrganization);
  return [
    {
      label: "Primary Website",
      description:
        "Your Primary website that you use as your home page for your brand",
      icon: <i className="ri-global-fill" />,
      isConfigured: Boolean(organization?.data?.website),
    },
    {
      label: "Branding",
      description: "Set Logo and Brand Colour",
      icon: <i className="ri-drop-fill" />,
      isConfigured:
        Boolean(organization?.data?.primaryColor) &&
        Boolean(organization?.data?.logo),
    },
    {
      label: "Industry",
      description: "Based on your Industry, your workspace will be tweaked",
      icon: <i className="ri-building-3-line" />,
      isConfigured: Boolean(organization?.data?.industry),
    },
    {
      label: "Country",
      description:
        "Country to be used as default country when handling phone numbers",
      icon: <i className="ri-flag-line" />,
      isConfigured: Boolean(organization?.data?.country),
    },
    {
      label: "Timezone",
      description:
        "Timezone to be used as default timezone for scheduled reports",
      icon: <i className="ri-flag-line" />,
      isConfigured: Boolean(organization?.data?.timezone),
    },
    {
      label: "Mailing Address",
      description: "The mailing address that will be used in newsletters",
      icon: <i className="ri-map-pin-line" />,
      isConfigured: Boolean(organization?.data?.mailingAddress?.addressLine1),
    },
  ];
};
