import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { Button, ColProps, Form, Spin, Tooltip } from "antd";

import { useForm } from "antd/es/form/Form";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { UserTracker } from "user-tracker";
import classNames from "utils/classnames";
import { useDebounce } from "utils/hooks/use-debounce";
import "./configuration-editor-mini.scss";
import ConfigurationEditor from "./configuration-editor.modal-registry";
import { LoadingIndicatorWithoutSpin } from "./loading-indicator/loading-indicator";
import { useEditEntity } from "./use-edit-entity";
import { useSelectEntity } from "./use-select-entity";

export const ConfigurationEditorMiniComponent = ({
  children,
  icon,
  title,
  description,
  entityType,
  entityId,
  transformFormValues,
  transformEntityValues,
  onFormValueChange,
  ignoreNavigationWarning,
  onSaved,
  formLayout,
  labelCol,
  miniEditor,
  triggerFullConfigOn,
  minEditorAutoSave = true,
  miniEditorAutoSaveDebounce,
  bordered,
  className,
  modalWidth,
}: {
  icon: string | JSX.Element;
  title: string;
  description: string;
  children?: any;
  entityType:
    | "CONNECTION"
    | "CONTACT"
    | "WORKSPACE"
    | "MY_PROFILE"
    | "USER"
    | "WIDGET"
    | "SELF_SERVICE_PORTAL"
    | "DYNAMIC_FORM"
    | "MAGIC_ASSISTANT";
  entityId?: string;
  transformFormValues?: (formValues: any) => any;
  transformEntityValues?: (entity: any) => any;
  onFormValueChange?: (formValues: any) => any;
  ignoreNavigationWarning?: boolean;
  onSaved?: () => any;
  formLayout?: "vertical" | "inline" | "horizontal";
  labelCol?: ColProps;
  miniEditor?: JSX.Element;
  minEditorAutoSave?: boolean;
  miniEditorAutoSaveDebounce?: number;
  triggerFullConfigOn?: string[];
  bordered?: boolean;
  className?: string;
  modalWidth?: number;
}) => {
  const [form] = useForm();

  const { triggerTempModal } = useModalPanels();
  const configEditorRef = useRef<any>();
  const configureModal = useCallback(() => {
    if (children) {
      triggerTempModal(ConfigurationEditor, {
        children,
        icon:
          typeof icon === "string" ? (
            <i className={`${icon} text-2xl`}></i>
          ) : (
            icon
          ),
        title,
        entityType,
        entityId,
        transformFormValues,
        transformEntityValues,
        onFormValueChange,
        configEditorRef,
        ignoreNavigationWarning,
        onSaved,
        formLayout,
        labelCol,
        modalWidth,
        afterClose: () => {
          form.resetFields();
        },
      });
    }
  }, [
    children,
    entityId,
    entityType,
    form,
    formLayout,
    icon,
    ignoreNavigationWarning,
    labelCol,
    modalWidth,
    onFormValueChange,
    onSaved,
    title,
    transformEntityValues,
    transformFormValues,
    triggerTempModal,
  ]);

  const entity = useSelectEntity(entityType, entityId);

  const initialValues = useMemo(() => {
    if (!entity) {
      return {};
    }
    return {
      ...(transformEntityValues ? transformEntityValues(entity) : entity),
    };
  }, [entity, transformEntityValues]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
    form.resetFields();
  }, [form, initialValues]);

  const { doAction: _editEntity, isProcessing } = useEditEntity(
    entityType,
    entityId,
  );

  const editEntity = useCallback(
    async (edits) => {
      UserTracker.track(`${entityType} - Edit - ${title}`, {});
      if (transformFormValues) {
        edits = transformFormValues(edits);
      }
      await _editEntity(edits);
    },
    [_editEntity, entityType, title, transformFormValues],
  );

  const editEntityWithDebounce = useDebounce(
    editEntity,
    miniEditorAutoSaveDebounce || 100,
  );

  return (
    <ConfigurationEditorMiniBase
      title={title}
      description={description}
      onClick={children ? configureModal : undefined}
      bordered={bordered}
      miniEditorSection={
        <>
          {miniEditor && (
            <div className="viewer">
              <Form
                layout={"inline"}
                onFinish={() => {
                  const formValues = form.getFieldsValue();
                  if (miniEditorAutoSaveDebounce) {
                    editEntityWithDebounce(formValues);
                  } else {
                    editEntity(formValues);
                  }
                }}
                form={form}
                initialValues={initialValues}
                onValuesChange={(d) => {
                  if (form.isFieldsTouched()) {
                    if (triggerFullConfigOn) {
                      const configValue =
                        form.getFieldValue(triggerFullConfigOn);
                      if (configValue) {
                        configureModal();
                        setTimeout(() => {
                          configEditorRef.current
                            .getForm()
                            .setFieldValue(triggerFullConfigOn, configValue);
                          configEditorRef.current.setFormTouched(true);
                        }, 100);
                        return;
                      }
                    }
                    if (minEditorAutoSave) {
                      form.submit();
                    }
                  }
                  onFormValueChange && onFormValueChange(form.getFieldsValue());
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {miniEditor}
                {!minEditorAutoSave && (
                  <Form.Item shouldUpdate noStyle>
                    {({ isFieldsTouched }) => {
                      const isFieldTouched = isFieldsTouched();
                      if (isFieldTouched) {
                        return (
                          <div className="flex flex-row items-center gap-2 ml-2">
                            <Button
                              onClick={() => form.submit()}
                              className="font-bold animated fadeInRight"
                              icon={<i className="ri-save-line"></i>}
                              loading={isProcessing}
                              type="primary"
                            >
                              Save
                            </Button>
                            <Button
                              onClick={() => {
                                form.resetFields();
                                setTimeout(() => {
                                  onFormValueChange &&
                                    onFormValueChange(form.getFieldsValue());
                                }, 100);
                              }}
                              className="font-bold animated fadeInRight"
                              icon={<i className="ri-reset-left-line"></i>}
                              type="text"
                            ></Button>
                          </div>
                        );
                      }
                      return <></>;
                    }}
                  </Form.Item>
                )}
              </Form>
            </div>
          )}
        </>
      }
      icon={icon}
    />
  );
};

export const ConfigurationEditorMiniBase = ({
  className,
  bordered,
  onClick,
  icon,
  title,
  description,
  miniEditorSection,
  actionButtonIcon,
  isProcessing = false,
}: {
  className?: string;
  bordered?: boolean;
  onClick?: () => any;
  icon: string | JSX.Element;
  title: string;
  description?: string;
  miniEditorSection?: JSX.Element;
  actionButtonIcon?: JSX.Element;
  isProcessing?: boolean;
}) => {
  return (
    <Spin spinning={isProcessing} indicator={<LoadingIndicatorWithoutSpin />}>
      <div
        className={classNames(
          "px-1 rounded-lg flex flex-row  gap-2 items-center justify-between configuration-mini-editor",
          {
            "hover:bg-gray-100 hover:dark:bg-gray-800": true,
            "cursor-pointer": !!onClick,
            "border border-gray-200 dark:border-gray-700": bordered,
          },
          className,
        )}
        onClick={onClick}
      >
        <Tooltip title={description}>
          <div className="flex flex-row gap-2 items-center">
            <div className="icon-container">
              {typeof icon === "string" ? (
                <i className={`${icon} text-lg`}></i>
              ) : (
                icon
              )}
            </div>
            <div className="label">{title}</div>
          </div>
        </Tooltip>
        <div
          className={classNames("flex flex-row gap-2 items-center", {
            "mr-1": miniEditorSection && !onClick,
          })}
        >
          {miniEditorSection}

          {onClick && (
            <div className="config" onClick={(e) => e.stopPropagation()}>
              <Button
                type="text"
                icon={
                  actionButtonIcon || <i className="ri-settings-2-line"></i>
                }
                onClick={onClick}
              ></Button>
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
};
