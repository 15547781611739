export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt?.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const toTitleCase2 = (s: string = "") =>
  s
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase()); // First char after each -/_
