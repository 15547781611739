import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const GitHubIssuesBrowserModal = React.lazy(
  () => import("./github-issue-browser.modal"),
);

const GitHubIssueBrowser: iModalProps<
  typeof GitHubIssuesBrowserModal,
  {
    onSelected?: (pageId: string) => any;
  }
> = {
  name: "github-issues-browser",
  component: GenerateModalWithSuspense(GitHubIssuesBrowserModal),
};

export default GitHubIssueBrowser;
