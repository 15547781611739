import { mdiLabelOutline } from "@mdi/js";
import { iCCAutomationRuleTrigger } from "@sdk/user-management/user-management.models";
import { MDIIcon } from "components/common/mdi-icon";

export const AutomationRuleTriggerLabels: Record<
  iCCAutomationRuleTrigger,
  string
> = {
  MESSAGE_NEW: "on new message",
  CUSTOMER_TAGGED: "on customer tagged",
  CONVERSATION_TAGGED: "on conversation tagged",
  CONVERSATION_CLOSED: "on conversation closed",
  CONVERSATION_INACTIVE: "on conversation become inactive",
  CONVERSATION_NEW: "on new conversation",
  CONVERSATION_RE_OPEN: "on conversation re-open",
  TICKET_NEW: "on new ticket",
  TICKET_COMMENT_NEW: "on new comment on a ticket",
  TICKET_STAGE_UPDATED: "on ticket stage updated",
  FB_COMMENT_NEW: "on new FB comment",
};

export const AutomationRuleTriggerIcons: Record<
  iCCAutomationRuleTrigger,
  JSX.Element
> = {
  MESSAGE_NEW: <i className="ri-chat-1-line"></i>,
  CUSTOMER_TAGGED: (
    <MDIIcon
      icon={mdiLabelOutline}
      size="1rem"
      style={{ marginRight: "0.6rem" }}
    />
  ),
  CONVERSATION_TAGGED: (
    <MDIIcon
      icon={mdiLabelOutline}
      size="1rem"
      style={{ marginRight: "0.6rem" }}
    />
  ),
  CONVERSATION_CLOSED: <i className="ri-inbox-archive-line"></i>,
  CONVERSATION_INACTIVE: <i className="ri-zzz-line"></i>,
  CONVERSATION_NEW: <i className="ri-chat-new-line"></i>,
  CONVERSATION_RE_OPEN: <i className="ri-inbox-unarchive-line"></i>,
  TICKET_NEW: <i className="ri-ticket-2-line"></i>,
  TICKET_COMMENT_NEW: <i className="ri-message-2-line"></i>,
  TICKET_STAGE_UPDATED: <i className="ri-kanban-view"></i>,
  FB_COMMENT_NEW: <i className="ri-facebook-box-line"></i>,
};
