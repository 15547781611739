import { iConnectorBlock } from "@sdk/chat-connectors/chat-connectors.models";
import { Button, Divider, Dropdown, Menu } from "antd";
import _ from "lodash";
import { forwardRef, useImperativeHandle, useMemo, useReducer } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import { AvailableBlocks } from "./available-blocks";
import { iBlockBuilderState } from "./block-builder-model";
import { reducer } from "./blocks-store-reducer";
import { GenerateBlock } from "./generate-block";
import { reorder } from "./helpers/re-order-array";
import { SingleConfigureBlock } from "./single-configure-block.component";

const buildBlockStateFromBlocks = (blocks: iConnectorBlock[]) => {
  return {
    blocks: {
      byIds: _.keyBy(blocks, "id"),
      list: blocks.map((item) => item.id),
    },
  } as iBlockBuilderState;
};

const buildBlocksFromSate = (blockState: iBlockBuilderState) => {
  return blockState.blocks.list.map((id) => blockState.blocks.byIds[id]);
};

export const BlockBuilder = forwardRef(
  (
    {
      initialBlocks = [],
      onChange,
    }: {
      initialBlocks?: iConnectorBlock[];
      onChange?: (state: iBlockBuilderState) => any;
    },
    ref,
  ) => {
    const initialState = useMemo(() => {
      return buildBlockStateFromBlocks(initialBlocks);
    }, [initialBlocks]);

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffectWhen(
      () => {
        onChange && onChange(state);
      },
      [onChange, state],
      [state],
    );

    useImperativeHandle(
      ref,
      () => ({
        setSnapshot(blocks: iConnectorBlock[]) {
          dispatch({
            type: "RESET_STATE",
            payload: {
              state: buildBlockStateFromBlocks(blocks),
            },
          });
        },
        getSnapshot() {
          return buildBlocksFromSate(state);
        },
      }),
      [state],
    );

    const onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;
      if (result.type === "block") {
        const items = reorder(state.blocks.list, sourceIndex, destIndex);
        dispatch({
          type: "REORDER_BLOCK",
          payload: {
            list: items,
          },
        });
      }
    };

    return (
      <div className="container flex flex-col relative">
        <div className="flex flex-col items-center">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" type="block">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    // background: snapshot.isDraggingOver ? "lightblue" : "lightgrey",
                    width: "100%",
                  }}
                >
                  {state.blocks.list.map((blockId, index) => (
                    <SingleConfigureBlock
                      blockId={blockId}
                      state={state}
                      dispatch={dispatch}
                      key={blockId}
                      index={index}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="w-full">
            <Divider>
              <Dropdown
                overlay={
                  <Menu data-click-context="Add Block Menu">
                    {AvailableBlocks.map((block) => (
                      <Menu.Item
                        key={block.id}
                        icon={<i className={"ri-filter-line"}></i>}
                        onClick={() => {
                          dispatch({
                            type: "ADD_BLOCK",
                            payload: {
                              block: GenerateBlock(block.id),
                            },
                          });
                        }}
                      >
                        {block.label}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                placement="topCenter"
                trigger={["click"]}
                arrow
              >
                <Button
                  type="dashed"
                  style={{ width: 150 }}
                  icon={<i className="ri-add-line"></i>}
                >
                  Add Block
                </Button>
              </Dropdown>
            </Divider>
          </div>
        </div>
        {/* <div className="whitespace-pre">{JSON.stringify(state, null, 2)}</div> */}
      </div>
    );
  },
);
