import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { iTelnyxConnectionData } from "@sdk/conversations/conversations.models";
import {
  Alert,
  Button,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Spin,
} from "antd";
import {
  ConfigurationEditorMiniBase,
  ConfigurationEditorMiniComponent,
} from "components/common/configuration-editor-mini";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import { MobileNumberInput } from "components/common/mobile-number-input/mobile-number-input";
import { SectionHeader } from "components/common/section-header";
import SimpleModal from "components/common/simple-empty-modal/simple-empty.modal-registry";
import { StyledSwitch } from "components/common/styled-swtich";
import { GeneralConnectionConfig } from "components/modules/connections/common-connection-settings/general-connection-config";
import { RoutingPreferencesForm } from "components/modules/connections/common-connection-settings/routing-preferences-form/routing-preferences-form";
import { useSelector } from "react-redux";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectAllGroups } from "store/modules/groups/groups.selectors";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { cleanMobileNumber } from "utils/clean-mobile-number";
import ManageTelnyxCallbackWidget from "../manage-telnyx-callback-widget/manage-telnyx-callback-widget.modal-registry";

export const ManageTelnyxConnection = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const connectionData = connection?.data as iTelnyxConnectionData | undefined;

  const userGroups = useSelector(selectAllGroups);

  const { triggerTempModal } = useModalPanels();

  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  return (
    <Spin
      spinning={false}
      indicator={<LoadingIndicatorWithoutSpin />}
      wrapperClassName="flex-1 flex fill-spinner"
    >
      <div className="animated fadeInLeftMin  flex flex-col w-full">
        <SectionHeader
          title="Connected Phone"
          icon={<i className="ri-phone-line"></i>}
          description="Manage incoming call configurations, sms routing, callback widgets etc"
          addons={
            <Space>
              <Button
                type="link"
                onClick={() => {
                  triggerTempModal(ManageTelnyxCallbackWidget, {
                    connectionId,
                  });
                }}
                icon={<i className="ri-phone-line"></i>}
              >
                Callback Widget
              </Button>
            </Space>
          }
        />
        <Divider />

        <div className="flex flex-row gap-4">
          <div className="flex-1">
            <Divider>Connected Number: {connectionData?.phoneNumber}</Divider>

            <ConfigurationEditorMiniBase
              icon={"ri-phone-line"}
              title={"Connected Number Details"}
              description={""}
              onClick={() => {
                triggerTempModal(SimpleModal, {
                  config: {
                    title: "Connected Number Details",
                    content: (
                      <Descriptions
                        title=""
                        bordered
                        layout="horizontal"
                        column={1}
                        className="mb-4"
                        // style={{ maxWidth: 600 }}
                      >
                        <Descriptions.Item label="Phone Number">
                          {connectionData?.phoneNumber}
                        </Descriptions.Item>

                        <Descriptions.Item label="Is Voice Available?">
                          {connectionData?.isPhoneEnabled ? "Yes" : "No"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Is Messaging Available?">
                          {connectionData?.isMessagingEnabled ? "Yes" : "No"}
                        </Descriptions.Item>

                        {isAdvancedMode && (
                          <>
                            <Descriptions.Item label="Order ID">
                              {connectionData?.order?.orderId}
                            </Descriptions.Item>
                            <Descriptions.Item label="Phone Id">
                              {connectionData?.phoneId}
                            </Descriptions.Item>
                            <Descriptions.Item label="Messaging Profile Id">
                              {connectionData?.messagingProfileId}
                            </Descriptions.Item>
                            <Descriptions.Item label="Phone Control Application ID">
                              {connectionData?.applicationId}
                            </Descriptions.Item>
                            <Descriptions.Item label="Billing Group Id">
                              {connectionData?.billingGroupId}
                            </Descriptions.Item>
                          </>
                        )}
                      </Descriptions>
                    ),
                    icon: <i className="ri-plug-line"></i>,
                    width: 800,
                  },
                });
              }}
              actionButtonIcon={<i className="ri-eye-line"></i>}
            />
          </div>
          <div className="flex-1"></div>
        </div>

        <div className="flex flex-row gap-4">
          <div className="flex-1">
            <Divider>Incoming Calls</Divider>

            <ConfigurationEditorMiniComponent
              entityType="CONNECTION"
              entityId={connectionId}
              icon={"ri-user-smile-line"}
              title="Incoming Call Greetings"
              description="Greetings to play when someone calls your connection"
            >
              <Form.Item
                label="Narration"
                name={["data", "incomingCallConfig", "welcomeMessage", "value"]}
                rules={[{ required: true, message: "Please enter a sentence" }]}
                help="Our system will automatically convert your text to speech and play it"
              >
                <Input placeholder="" size="large" />
              </Form.Item>
            </ConfigurationEditorMiniComponent>

            <ConfigurationEditorMiniComponent
              entityType="CONNECTION"
              entityId={connectionId}
              icon={"ri-timer-line"}
              title="Waiting Timeout and Voice Mail"
              description="Configure how long the customer can wait in the queue and what to do when the timeout occurs"
            >
              <Form.Item
                label="Timeout in seconds"
                name={[
                  "data",
                  "incomingCallConfig",
                  "waitingTimeout",
                  "timeout",
                ]}
                rules={[{ required: true, message: "Please enter a value" }]}
              >
                <Input placeholder="" type="number" />
              </Form.Item>

              <Form.Item
                label="Narration on timeout"
                name={[
                  "data",
                  "incomingCallConfig",
                  "waitingTimeout",
                  "message",
                ]}
                rules={[{ required: true, message: "Please enter a sentence" }]}
                help="Our system will automatically convert your text to speech and play it"
              >
                <Input placeholder="" size="large" />
              </Form.Item>
              <StyledSwitch
                label="Voice Mail"
                name={[
                  "data",
                  "incomingCallConfig",
                  "waitingTimeout",
                  "voiceMail",
                ]}
                disabled={true}
              />
              <Form.Item
                label="Forward calls to"
                name={[
                  "data",
                  "incomingCallConfig",
                  "waitingTimeout",
                  "forwardWhenBusyTo",
                ]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator: async (rule, value) => {
                      if (value) {
                        const phone = await cleanMobileNumber(value);
                        if (!phone.isValid) {
                          throw "Invalid Number";
                        }
                      }
                      return true;
                    },
                  }),
                ]}
                help="When timeout occurs, configure a number to forward the calls to"
              >
                <MobileNumberInput defaultCountry={""} />
              </Form.Item>
            </ConfigurationEditorMiniComponent>

            <ConfigurationEditorMiniComponent
              entityType="CONNECTION"
              entityId={connectionId}
              icon={"ri-keyboard-fill"}
              title="IVR"
              description="Set up an IVR system to handle incoming calls"
            >
              <Alert
                type="info"
                message="This feature is not available in your account"
              />
            </ConfigurationEditorMiniComponent>

            <ConfigurationEditorMiniComponent
              entityType="CONNECTION"
              entityId={connectionId}
              icon={"ri-share-forward-line"}
              title="Call Forwarder"
              description="Setup call forwarder to this connection"
            >
              <Form.Item
                label="Forward calls to"
                name={["data", "incomingCallConfig", "forwardAllCallsTo"]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator: async (rule, value) => {
                      if (value) {
                        const phone = await cleanMobileNumber(value);
                        if (!phone.isValid) {
                          throw "Invalid Number";
                        }
                      }
                      return true;
                    },
                  }),
                ]}
                help="Forward all incoming calls to this number"
                normalize={(str) => str || null}
              >
                <MobileNumberInput defaultCountry={""} />
              </Form.Item>
            </ConfigurationEditorMiniComponent>
            <ConfigurationEditorMiniComponent
              entityType="CONNECTION"
              entityId={connectionId}
              icon={"ri-route-line"}
              title="Call Routing"
              description="Configure Call Routing for incoming calls"
            >
              {/* Routing Strategy */}
              <Form.Item
                label="Routing Strategy"
                name={[
                  "data",
                  "incomingCallConfig",
                  "routing",
                  "routingMethod",
                ]}
                help="Strategy used to pick the user while routing"
              >
                <Select>
                  <Select.Option value={"RING_ALL"}>Ring All</Select.Option>
                  <Select.Option value={"ROUND_ROBING_SINGLE"} disabled={true}>
                    Round Robin
                  </Select.Option>
                  <Select.Option value={"RANDOM_SINGLE"}>Random</Select.Option>
                  {/* <Select.Option value={"ROUND_ROBIN_RING_TEAM"}>
                Don't Route
              </Select.Option> */}
                </Select>
              </Form.Item>

              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const routingStrategy = getFieldValue([
                    "data",
                    "incomingCallConfig",
                    "routing",
                    "routingMethod",
                  ]);
                  if (
                    routingStrategy === "ROUND_ROBING_SINGLE" ||
                    routingStrategy === "RANDOM_SINGLE"
                  ) {
                    return (
                      <>
                        <Form.Item
                          label="Restrict Routing to specific user groups"
                          name={[
                            "data",
                            "incomingCallConfig",
                            "routing",
                            "groupsToRoute",
                          ]}
                          help="Select the user groups that can receive call invites"
                        >
                          <Select allowClear mode="multiple">
                            {(userGroups || []).map((userGroup) => (
                              <Select.Option
                                key={userGroup.label}
                                value={userGroup.id}
                              >
                                {userGroup.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </>
                    );
                  }
                  return <></>;
                }}
              </Form.Item>

              <Form.Item
                label="Expire Routing invitations"
                name={["data", "incomingCallConfig", "routing", "timeout"]}
                help="Invitation expiry in seconds"
              >
                <InputNumber
                  defaultValue={60}
                  min={30}
                  max={120}
                  step={1}
                  className="w-full"
                  {...{ addonAfter: "Seconds" }}
                />
              </Form.Item>
            </ConfigurationEditorMiniComponent>

            {/* Routing Settings */}
          </div>
          <div className="flex-1">
            <Divider>SMS Routing Settings</Divider>

            {/* Routing Settings */}
            <RoutingPreferencesForm connectionId={connectionId} />
          </div>
        </div>

        <GeneralConnectionConfig
          connectionId={connectionId}
          withoutHeader
          powerSettingsDefaultExpanded
        />
      </div>
    </Spin>
  );
};
