import { useEffect, useRef } from "react";
import { some, useConditionalEffect, useDeltaArray } from "react-delta";

// export const useEffectWhen = (effect, deps, whenDeps) => {
//   const whenRef = useRef(whenDeps || []);
//   const initial = whenRef.current === whenDeps;
//   const whenDepsChanged =
//     initial || !whenRef.current.every((w, i) => w === whenDeps[i]);
//   whenRef.current = whenDeps;
//   const nullDeps = deps.map(() => null);

//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   return useEffect(
//     whenDepsChanged ? effect : () => {},
//     whenDepsChanged ? deps : nullDeps
//   );
// };

export const useEffectWhen = (
  effect: () => any,
  deps: any[],
  whenDeps: any[]
) => {
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  const whenDepsDelta = useDeltaArray(whenDeps);
  useConditionalEffect(effect, isMounted.current && some(whenDepsDelta));
};
