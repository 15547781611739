import { NotificationHelper } from "components/common/notifications/notifications-helper";
import { combineEpics, ofType } from "redux-observable";
import { mergeMap, tap } from "rxjs/operators";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectConversationById } from "store/modules/conversations/conversations.selectors";
import { selectUser } from "store/modules/users/users.selectors";
import { selectConnectionContextVisibility } from "store/modules/workspace/workspace.selectors";
import { iStore } from "store/store.model";

const onNewNotification = (action$, state$) => {
  return action$.pipe(
    ofType("notifications/addNewEntity"),
    // subscribe to actions observable
    tap(
      ({
        payload: {
          id,
          message,
          data: { type, ...data }
        }
      }) => {
        const state = state$.value as iStore;
        console.log("Add New Notification Fired");
        switch (type) {
          case "CONVERSATION_INVITE_REQUEST": {
            const { conversationId, expireAt } = data;
            const contextVisibility = selectConnectionContextVisibility(state);
            const conversation = selectConversationById(conversationId)(state);
            let connectionName: string;
            if (contextVisibility && conversation) {
              const connection = selectConnectionById(
                conversation.connectionId
              )(state);
              connectionName = connection?.label;
            }

            NotificationHelper.fire.onUserInvitedToConversation({
              connectionName: connectionName! || undefined,
              conversationId,
              notificationId: id,
              expireAt
            });
            break;
          }
          case "CONVERSATION_TRANSFER_REQUEST": {
            const { conversationId, transferredBy } = data;
            const user = selectUser(transferredBy)(state);
            const username = user.data.firstName;
            NotificationHelper.fire.onTransferRequest({
              conversationId: conversationId,
              username: username,
              userId: transferredBy
            });
            break;
          }
          case "CONVERSATION_TRANSFER_REQUEST_ACCEPTED": {
            const { conversationId, transferredTo } = data;
            const user = selectUser(transferredTo)(state);
            const username = user.data.firstName;
            NotificationHelper.fire.onTransferAccepted({
              conversationId: conversationId,
              username: username,
              userId: transferredTo
            });
            break;
          }
          case "CONVERSATION_TRANSFER_REQUEST_REJECTED": {
            const { conversationId, transferredTo } = data;
            const user = selectUser(transferredTo)(state);
            const username = user.data.firstName;
            NotificationHelper.fire.onTransferAccepted({
              conversationId: conversationId,
              username: username,
              userId: transferredTo
            });
            break;
          }
          case "CONVERSATION_ASSIGNED_ALERT": {
            const { conversationId } = data;

            NotificationHelper.fire.simpleConversationNotification({
              conversationId: conversationId,
              title: message,
              text: "Click here to view",
              notificationId: id
            });
            break;
          }
        }
      }
    ),
    mergeMap((actions: any) => [])
  );
};

export const notificationsEffects = combineEpics(onNewNotification);
