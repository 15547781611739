import MailOutlined from "@ant-design/icons/MailOutlined";
import {
  getPermissionFromRole,
  Roles,
} from "@sdk/roles-and-permissions/roles-and-permissions";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iUserInvitation } from "@sdk/user-management/user-management.models";
import { Button, Form, Input, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { last } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";

import { BadgesController } from "badge-controller";

import { useDebounce } from "utils/hooks/use-debounce";
import { debouncedEmailAvailability } from "../../helpers/email-availability";

export const InviteUserForm = ({
  onInviteSent,
}: {
  onInviteSent: (invite: iUserInvitation) => any;
}) => {
  const [userInviteForm] = useForm();
  const currentOrganization = useSelector(selectOrganization);

  const { doAction: onInviteUser, isProcessing: isInviteProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (values) =>
          SDK.inviteUser({
            email: values.email,
            permissions: getPermissionFromRole(values.role)!,
          }).then((invite) => {
            BadgesController.processBadgeEvent("invite-a-user");
            onInviteSent(invite);
          }),
        successMessage: "User has been invited",
        failureMessage: "Something went wrong",
        throwError: true,
      }),
      [onInviteSent],
    );

  const sendInvite = useCallback(() => {
    onInviteUser(userInviteForm.getFieldsValue())
      .then((d) => {
        userInviteForm.resetFields();
      })
      .catch((e) => {
        //
      });
  }, [onInviteUser, userInviteForm]);

  const [isValid, setFormValid] = useState(false);

  const _validateForm = useCallback(() => {
    return userInviteForm
      .validateFields()
      .then((d) => {
        setFormValid(true);
      })
      .catch((e) => {
        if (e?.errorFields?.length === 0) {
          setFormValid(true);
        } else {
          setFormValid(false);
        }
      });
  }, [userInviteForm]);

  const initialValues = useMemo(() => ({ role: last(Roles)?.label }), []);

  const validateForm = useDebounce(_validateForm, 500);

  const firstInput = useRef<any>();

  useEffect(() => {
    setTimeout(() => {
      firstInput.current?.focus();
    }, 100);
  }, []);

  return (
    <Form
      layout="vertical"
      form={userInviteForm}
      onFinish={sendInvite}
      onChange={validateForm as any}
      initialValues={initialValues}
    >
      <div className="flex flex-col" style={{ minWidth: "30rem" }}>
        <Form.Item
          name="email"
          hasFeedback
          validateFirst={true}
          rules={[
            { type: "email", required: true },
            ({ getFieldValue }) => ({
              validator: async (rule, value) =>
                await debouncedEmailAvailability(
                  value,
                  currentOrganization?.id,
                ),
            }),
          ]}
          label="Email"
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Email"
            autoFocus
            ref={firstInput}
          />
        </Form.Item>
        <Form.Item
          label="Level of access"
          name="role"
          rules={[
            {
              required: true,
              message: "Please select a role",
            },
          ]}
        >
          <Select placeholder="Select a role">
            {Roles.map((role) => (
              <Select.Option key={role.label} value={role.label}>
                {role.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <div className="flex flex-row justify-end items-center">
          <Button
            htmlType="submit"
            type="primary"
            loading={isInviteProcessing}
            className="font-bold"
            icon={<i className="ri-mail-send-line"></i>}
            disabled={!isValid}
          >
            Send Invite
          </Button>
        </div>
      </div>
    </Form>
  );
};
