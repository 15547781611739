import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ConversationsPickerModal = React.lazy(
  () => import("./conversation-picker.modal"),
);

const ConversationsPicker: iModalProps<
  typeof ConversationsPickerModal,
  { onConversationSelected: (conversationId: string) => any; query: any }
> = {
  name: "conversation-picker",
  component: GenerateModalWithSuspense(ConversationsPickerModal),
};

export default ConversationsPicker;
