import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  iPreset,
  iPresetFolder,
  iPresetState
} from "@sdk/user-management/preset-state-model";
import { Button, Collapse, Space, Tooltip } from "antd";
import copyToClipboard from "copy-to-clipboard";
import _ from "lodash";
import { useMemo } from "react";
import { AddEllipsis } from "utils/add-ellipsis";
import { convertHtmlToPlainText } from "utils/html-to-plain-text";
import "./preset-folder-manager.scss";

export const PresetItemEditorView = ({
  preset,
  onDelete,
  onEdit
}: {
  preset: iPreset;
  onDelete: () => any;
  onEdit: () => any;
}) => {
  return (
    <div
      className="flex flex-row items-center w-full hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer p-4"
      onClick={onEdit}
    >
      <div className="template-type">
        {preset.type === "RICH_TEXT" ? (
          <Tooltip title="Rich Text Preset">
            <i className="ri-font-size-2"></i>
          </Tooltip>
        ) : (
          <Tooltip title="Simple Text Preset">
            <i className="ri-text"></i>
          </Tooltip>
        )}
      </div>
      <div className="template-label font-bold ml-3">
        {AddEllipsis(preset.label, 30)}
      </div>
      <div className="template-label flex-1 text-gray-600 ml-4">
        {AddEllipsis(convertHtmlToPlainText(preset.value), 20)}
      </div>
      <Space>
        <Tooltip title="Delete Preset">
          <Button
            icon={<i className="ri-delete-bin-line"></i>}
            type="text"
            shape="circle"
            onClick={e => {
              e.stopPropagation();
              onDelete();
            }}
          />
        </Tooltip>
        <Tooltip title="Copy Preset">
          <Button
            icon={<i className="ri-file-copy-2-line"></i>}
            type="text"
            shape="circle"
            onClick={e => {
              e.stopPropagation();
              copyToClipboard(preset.value);
            }}
          />
        </Tooltip>
      </Space>
    </div>
  );
};

export const PresetFolderManager = ({
  folder,
  state,
  onEditFolder,
  onDeleteFolder,
  onAddPreset,
  onEditPreset,
  onDeletePreset,
  ...collapsiblePanelProps
}: {
  folder: iPresetFolder;
  state: iPresetState;
  onEditFolder: (folder: iPresetFolder) => any;
  onDeleteFolder: (folder: iPresetFolder, withPresets?: boolean) => any;
  onAddPreset: (folder: iPresetFolder) => any;
  onEditPreset: (preset: iPreset) => any;
  onDeletePreset: (preset: iPreset) => any;
}) => {
  const presets = useMemo(() => {
    return _.filter(state.presets, { folder: folder.id });
  }, [folder, state]);

  const [parent] = useAutoAnimate(/* optional config */);

  return (
    <Collapse.Panel
      key={folder.id}
      {...collapsiblePanelProps}
      className="preset-folder-manager border border-gray-200 dark:border-gray-700 rounded-md mb-2"
      header={
        <div className="flex flex-row w-full items-center">
          <div className="icon">
            <i className="ri-folder-2-line"></i>
          </div>
          <div className="texts flex flex-row pl-4 flex-grow flex-1">
            <div className="text-lg font-bold">{folder.label}</div>
          </div>
          <div className="flex flex-row items-center">
            <div className="mr-4">{presets.length} Preset(s)</div>
          </div>
          <Tooltip title="Edit Folder">
            <Button
              icon={<i className="ri-pencil-line"></i>}
              type="text"
              shape="circle"
              onClick={e => {
                e.stopPropagation();
                onEditFolder(folder);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete Folder">
            <Button
              icon={<i className="ri-delete-bin-line"></i>}
              type="text"
              shape="circle"
              onClick={e => {
                e.stopPropagation();
                onDeleteFolder(folder);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete With Presets">
            <Button
              icon={<i className="ri-delete-bin-fill"></i>}
              type="text"
              shape="circle"
              onClick={e => {
                e.stopPropagation();
                onDeleteFolder(folder, true);
              }}
            />
          </Tooltip>
        </div>
      }
    >
      <div ref={parent as any}>
        {presets.map(preset => (
          <PresetItemEditorView
            key={preset.id}
            preset={preset}
            onDelete={() => onDeletePreset(preset)}
            onEdit={() => onEditPreset(preset)}
          />
        ))}
      </div>
    </Collapse.Panel>
  );
};
