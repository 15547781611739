import { iReminder } from "@sdk/reminders/reminder-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { remindersDefaultQueryConfig } from "./reminders-default-query";

export const {
  slice: RemindersSlice,
  reducers: RemindersSliceReducer,
  actions: {
    setEntityQueryResults: setRemindersQueryResults,
    setEntity: setReminder,
    setEntities: setReminders,
    patchEntity: patchReminder,
    addNewEntity: addNewReminder,
    setEntityFetchingStatus: setReminderFetchingStatus,
    setEntitiesFetchingStatus: setRemindersFetchingStatus,
    setEntityFailedStatus: setReminderFailedStatus,
    setEntityQueryError: setRemindersQueryError,
    resetQuery: resetRemindersQuery
  }
} = CreateEntitySlice<iReminder>({
  sliceName: "reminders",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...remindersDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
