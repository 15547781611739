import { iNPSSubmission } from "@sdk/nps-submissions/nps-submissions-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { NPSSubmissionsDefaultQueryConfig } from "./nps-submissions-default-query";

export const {
  slice: NPSSubmissionsSlice,
  reducers: NPSSubmissionsSliceReducer,
  actions: {
    setEntityQueryResults: setNPSSubmissionsQueryResults,
    setEntity: setNPSSubmission,
    setEntities: setNPSSubmissions,
    patchEntity: patchNPSSubmission,
    addNewEntity: addNewNPSSubmission,
    setEntityFetchingStatus: setNPSSubmissionFetchingStatus,
    setEntitiesFetchingStatus: setNPSSubmissionsFetchingStatus,
    setEntityFailedStatus: setNPSSubmissionFailedStatus,
    setEntityQueryError: setNPSSubmissionsQueryError,
    resetQuery: resetNPSSubmissionsQuery,
    setQueryList: setNPSSubmissionQueryList,
  },
} = CreateEntitySlice<iNPSSubmission>({
  sliceName: "NPSSubmissions",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...NPSSubmissionsDefaultQueryConfig,
        alias: "all",
      };
    }
  },
});
