import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const CCEmbedPreviewModal = React.lazy(
  () => import("./cc-embed-preview.modal"),
);

const CCEmbedPreview: iModalProps<
  typeof CCEmbedPreviewModal,
  {
    type: "KB_PORTAL" | "TRACKER";
    kbPortalId?: string;
    articleId?: string;
    trackerTypeId?: string;
    viewMode?: "kanban" | "list";
    isDarkMode?: boolean;
  }
> = {
  name: "cc-embed-preview",
  component: GenerateModalWithSuspense(CCEmbedPreviewModal),
};

export default CCEmbedPreview;
