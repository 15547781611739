import { iCheckList } from "@sdk/checklists/checklists-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { checkListsDefaultQueryConfig } from "./checklists-default-query";

export const {
  slice: CheckListsSlice,
  reducers: CheckListsSliceReducer,
  actions: {
    setEntityQueryResults: setCheckListsQueryResults,
    setEntity: setCheckList,
    setEntities: setCheckLists,
    patchEntity: patchCheckList,
    addNewEntity: addNewCheckList,
    setEntityFetchingStatus: setCheckListFetchingStatus,
    setEntitiesFetchingStatus: setCheckListsFetchingStatus,
    setEntityFailedStatus: setCheckListFailedStatus,
    setEntityQueryError: setCheckListsQueryError,
    resetQuery: resetCheckListsQuery,
    setQueryList: setCheckListQueryList,
  },
} = CreateEntitySlice<iCheckList>({
  sliceName: "checkLists",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...checkListsDefaultQueryConfig,
        alias: "all",
      };
    }
  },
});
