import { DeepPartial } from "@reduxjs/toolkit";
import { axios, AxiosResponse } from "@sdk/axios";
import { SDKConfig } from "@sdk/default-sdk-config";
import { iTwitterUser } from "@sdk/twitter/twitter-models";
import { EntityServices } from "@sdk/utils/entity.service";
import { UserTracker } from "user-tracker";
import { PaginatedOptions, PaginatedResults } from "../utils/paginated-results";
import {
  ConversationsReq_BulkAction,
  FbIntegrationStatusResponse,
  FbWabaIntegrationStatusResponse,
  iNewConversationRequest,
} from "./conversations.controller-models";
import {
  GmailSendAsItem,
  iChatTranslatorConfig,
  iConnection,
  iConnectionRoutingConfigurationData,
  iConversation,
  iMessage,
} from "./conversations.models";

export class ConversationService {
  constructor(protected config: { basePath: string; token: string }) {
    this.connections.configure(config);
  }

  /* -------------------------------- Connection -------------------------------- */

  connections = Object.assign(
    new EntityServices<iConnection>(SDKConfig, "connections", {
      onCreate: (data: iConnection) => {
        UserTracker.track("CONNECTIONS - Create", {
          type: data.type,
        });
      },
      onDelete: () => {
        UserTracker.track("CONNECTIONS - Delete", {});
      },
    }),
    {
      editCustomDomain: async (
        connectionId: string,
        data: { domain: string; email: string; senderName: string } | {},
      ) => {
        UserTracker.track("CONNECTIONS - Add Custom Domain");
        const results: AxiosResponse<iConnection> = await axios.patch(
          this.config.basePath + `/connections/${connectionId}/custom-domain`,
          data,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      verifyCustomDomain: async (connectionId: string) => {
        const results: AxiosResponse<iConnection> = await axios.post(
          this.config.basePath +
            `/connections/${connectionId}/custom-domain/verify`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      activateTracking: async (connectionId: string) => {
        UserTracker.track("CONNECTIONS - Activate Tracking");
        const results: AxiosResponse<iConnection> = await axios.post(
          this.config.basePath +
            `/connections/${connectionId}/custom-domain/activate-tracking`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      checkEmailAvailability: async (email: string) => {
        const results: AxiosResponse<{
          availability: boolean;
          connection?: string;
        }> = await axios.get(
          this.config.basePath + "/connections/emails/check-email-availability",
          {
            headers: {
              Authorization: this.config.token,
            },
            params: {
              email,
            },
          },
        );
        return results.data;
      },
      verifyAndModifyCustomSmtp: async (connectionId: string, data: any) => {
        UserTracker.track("CONNECTIONS - Setup SMTP");
        const results: AxiosResponse<{
          availability: boolean;
          connection?: string;
        }> = await axios.post(
          this.config.basePath +
            `/connections/${connectionId}/custom-smtp/verify-and-modify`,
          data,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      // Sync FB Conversations
      loadAllRecentFbConversation: async (connectionId: string) => {
        UserTracker.track("CONNECTIONS - Sync FB Conversations");
        const results: AxiosResponse<iConnection> = await axios.post(
          this.config.basePath +
            `/connections/${connectionId}/sync-fb-conversations`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      // Sync IG Conversations
      loadAllRecentIgConversation: async (connectionId: string) => {
        UserTracker.track("CONNECTIONS - Sync IG Conversations");
        const results: AxiosResponse<iConnection> = await axios.post(
          this.config.basePath +
            `/instagram/${connectionId}/sync-conversations`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      // Test Connections
      testIncomingEmail: async (connectionId: string) => {
        UserTracker.track("CONNECTIONS - Test Incoming Email");
        const results: AxiosResponse<{ message: string }> = await axios.post(
          this.config.basePath +
            `/connections/${connectionId}/test-connection/incoming-email`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      testOutgoingEmail: async (connectionId: string) => {
        UserTracker.track("CONNECTIONS - Test Outgoing Email");
        const results: AxiosResponse<{ message: string }> = await axios.post(
          this.config.basePath +
            `/connections/${connectionId}/test-connection/outgoing-email`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      checkFacebookAuthenticationStatus: async (connectionId: string) => {
        const results: AxiosResponse<FbIntegrationStatusResponse> =
          await axios.post(
            this.config.basePath +
              `/connections/${connectionId}/test-connection/fb-integration`,
            {},
            {
              headers: {
                Authorization: this.config.token,
              },
            },
          );
        return results.data;
      },
      checkWhatsappOfficialAuthenticationStatus: async (
        connectionId: string,
      ) => {
        const results: AxiosResponse<FbWabaIntegrationStatusResponse> =
          await axios.post(
            this.config.basePath +
              `/connections/${connectionId}/test-connection/waba-integration`,
            {},
            {
              headers: {
                Authorization: this.config.token,
              },
            },
          );
        return results.data;
      },
      checkWhatsappCloudNativeAuthenticationStatus: async (
        connectionId: string,
      ) => {
        const results: AxiosResponse<FbWabaIntegrationStatusResponse> =
          await axios.post(
            this.config.basePath +
              `/connections/${connectionId}/test-connection/wa-cloud-native-integration`,
            {},
            {
              headers: {
                Authorization: this.config.token,
              },
            },
          );
        return results.data;
      },
      checkInstagramAuthenticationStatus: async (connectionId: string) => {
        const results: AxiosResponse<FbIntegrationStatusResponse> =
          await axios.post(
            this.config.basePath +
              `/connections/${connectionId}/test-connection/ig-integration`,
            {},
            {
              headers: {
                Authorization: this.config.token,
              },
            },
          );
        return results.data;
      },
      checkTwitterAuthenticationStatus: async (connectionId: string) => {
        const results: AxiosResponse<iTwitterUser> = await axios.post(
          this.config.basePath +
            `/connections/${connectionId}/test-connection/twitter-integration`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      reAuthenticateInstagramConnection: async (
        connectionId: string,
        accessToken: string,
      ) => {
        UserTracker.track("CONNECTIONS - ReAuthenticate IG");
        const results: AxiosResponse<{ status: boolean }> = await axios.post(
          this.config.basePath +
            `/connections/${connectionId}/re-authenticate-instagram`,
          { accessToken },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      reAuthenticateFacebookConnection: async (
        connectionId: string,
        accessToken: string,
      ) => {
        UserTracker.track("CONNECTIONS - Reauthenticate FB", { accessToken });
        const results: AxiosResponse<{ status: boolean }> = await axios.post(
          this.config.basePath +
            `/connections/${connectionId}/re-authenticate-facebook`,
          { accessToken },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      reAuthenticateWhatsappConnection: async (
        connectionId: string,
        accessToken: string,
      ) => {
        const results: AxiosResponse<{ status: boolean }> = await axios.post(
          this.config.basePath +
            `/connections/${connectionId}/re-authenticate-whatsapp`,
          { accessToken },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      reAuthenticateWhatsappCloudNativeConnection: async (
        connectionId: string,
        accessToken: string,
        code: string,
      ) => {
        const results: AxiosResponse<{ status: boolean }> = await axios.post(
          this.config.basePath +
            `/connections/${connectionId}/re-authenticate-whatsapp-cloud-native`,
          { accessToken, code },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      reAuthenticateTwitterConnection: async (
        connectionId: string,
        req: {
          oath: {
            oauth_verifier: string;
            oauth_token: string;
            code: string;
          };
        },
      ) => {
        const results: AxiosResponse<{ status: boolean }> = await axios.post(
          this.config.basePath +
            `/connections/${connectionId}/re-authenticate-twitter`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      getAllBlockedFbUsers: async (connectionId: string, userId?: string) => {
        const results: AxiosResponse<
          {
            id: string;
            name: string;
          }[]
        > = await axios.get(
          this.config.basePath + `/connections/${connectionId}/blocked-users`,
          {
            headers: {
              Authorization: this.config.token,
            },
            params: {
              userId,
            },
          },
        );
        return results.data;
      },
      blockUserFromPage: async (connectionId: string, userId: string) => {
        UserTracker.track("CONNECTIONS - FB Block User");
        const results: AxiosResponse<{ status: boolean }> = await axios.post(
          this.config.basePath + `/connections/${connectionId}/blocked-users`,
          { userId },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      unblockUserFromPage: async (connectionId: string, userId: string) => {
        UserTracker.track("CONNECTIONS - FB Unblock User");
        const results: AxiosResponse<{ status: boolean }> = await axios.delete(
          this.config.basePath + `/connections/${connectionId}/blocked-users`,
          {
            headers: {
              Authorization: this.config.token,
            },
            params: {
              userId,
            },
          },
        );
        return results.data;
      },
      completeGmailAuth: async (connectionId: string, code: string) => {
        UserTracker.track("CONNECTIONS - Complete Gmail Auth");
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath +
            `/connections/${connectionId}/connect-gmail/complete`,
          { code },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      getGmailAuthUrl: async () => {
        const results: AxiosResponse<{ authUrl: string }> = await axios.post(
          this.config.basePath + `/connections/connect-gmail/get-auth-url`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      getGmailAliases: async (connectionId: string) => {
        const results: AxiosResponse<GmailSendAsItem[]> = await axios.post(
          this.config.basePath +
            `/connections/${connectionId}/get-gmail-aliases`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      getWAConnectedAccounts: async (code: string) => {
        const results: AxiosResponse<{ accessToken: string; accounts: any[] }> =
          await axios.post(
            this.config.basePath +
              `/whatsapp-cloud-native/get-connected-accounts`,
            { code },
            {
              headers: {
                Authorization: this.config.token,
              },
            },
          );
        return results.data;
      },
    },
  );

  /* -------------------------------- Conversation -------------------------------- */

  async queryConversations(req: { query: any; options: PaginatedOptions }) {
    const results: AxiosResponse<PaginatedResults<iConversation>> =
      await axios.post(this.config.basePath + "/conversations/query", req, {
        headers: {
          Authorization: this.config.token,
        },
      });
    return results.data;
  }

  async getConversationById(id: string) {
    const results: AxiosResponse<iConversation> = await axios.get(
      this.config.basePath + `/conversations/${id}`,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async createConversation(body: {
    contactId?: string;
    connectionId: string;
    contactData?: {
      firstName: string;
      lastName: string;
      email: string;
    };
    subject: string;
    data: any;
  }) {
    UserTracker.track("CONVERSATIONS - Create");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/create-conversation`,
      body,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async editConversation(id: string, edits: DeepPartial<iConversation>) {
    UserTracker.track("CONVERSATIONS - Edit");
    const results: AxiosResponse<iConversation> = await axios.patch(
      this.config.basePath + `/conversations/${id}`,
      edits,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async updateConversationTags(id: string, tags: string[]) {
    UserTracker.track("CONVERSATIONS - Update Tags");
    const results: AxiosResponse<iConversation> = await axios.patch(
      this.config.basePath + `/conversations/${id}/tags`,
      { tags },
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async joinConversation(id: string) {
    UserTracker.track("CONVERSATIONS - Join Conversation");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${id}/join`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async leaveConversation(id: string) {
    UserTracker.track("CONVERSATIONS - Leave Conversation");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${id}/leave`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async bidToJoin(id: string) {
    UserTracker.track("CONVERSATIONS - Bid To Join");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${id}/bid-to-join`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async closeConversation(id: string) {
    UserTracker.track("CONVERSATIONS - Close Conversation");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${id}/close`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async openConversation(id: string) {
    UserTracker.track("CONVERSATIONS - Open Conversation");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${id}/open`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async syncFbConversation(id: string) {
    UserTracker.track("CONVERSATIONS - Sync FB Conversation");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${id}/re-sync-fb-conversation`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async syncIgConversation(id: string) {
    UserTracker.track("CONVERSATIONS - Sync IG Conversations");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${id}/re-sync-ig-conversation`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async syncWAPrivateConversation(id: string) {
    UserTracker.track("CONVERSATIONS - Sync WA Conversations");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${id}/re-sync-ig-conversation`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async syncTwitterConversation(id: string) {
    UserTracker.track("CONVERSATIONS - Sync Twitter Conversation");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath +
        `/conversations/${id}/re-sync-twitter-conversation`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async addMembersToConversation(conversationId: string, userIds: string[]) {
    UserTracker.track("CONVERSATIONS - Add Members to Conversation");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${conversationId}/add-members`,
      { userIds },
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async removeMembersFromConversation(
    conversationId: string,
    userIds: string[],
  ) {
    UserTracker.track("CONVERSATIONS - Remove Members from Conversation");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${conversationId}/remove-members`,
      { userIds },
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async transferConversation(id: string, to: { userId: string }) {
    UserTracker.track("CONVERSATIONS - Transfer Conversation");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${id}/transfer`,
      to,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async requestToTransferConversation(id: string, to: { userId: string }) {
    UserTracker.track("CONVERSATIONS - Request to Transfer Conversation");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${id}/request-to-transfer`,
      to,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async cancelTransferRequestToConversation(
    id: string,
    to: { userId: string },
  ) {
    UserTracker.track("CONVERSATIONS - Cancel Transfer Request");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${id}/cancel-transfer-request`,
      to,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async acceptConversationTransferRequest(conversationId: string) {
    UserTracker.track("CONVERSATIONS - Accept Transfer Request");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${conversationId}/accept-transfer`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async declineConversationTransferRequest(conversationId: string) {
    UserTracker.track("CONVERSATIONS - Decline Transfer Request");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath +
        `/conversations/${conversationId}/decline-transfer`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async updateWatermark(id: string, timestamp: number) {
    const results: AxiosResponse<iConversation> = await axios.patch(
      this.config.basePath + `/conversations/${id}/read-watermark`,
      { timestamp },
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async raiseFlag(id: string, req: { flag: "requiresAttention" }) {
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${id}/raise-flag`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async dismissFlag(
    id: string,
    req: { flag: "requiresAttention" | "requiresFollowup" },
  ) {
    UserTracker.track("CONVERSATIONS - Dismiss Flag", { flag: req.flag });
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${id}/dismiss-flag`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async autoRoute(id: string, config: iConnectionRoutingConfigurationData) {
    UserTracker.track("CONVERSATIONS - Auto Route Conversation");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${id}/auto-route`,
      config,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async emailConversation(id: string, req: { email: string }) {
    UserTracker.track("CONVERSATIONS - Email Conversation");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${id}/email`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async activateChatBot(id: string, req: { chatBotId: string }) {
    UserTracker.track("CONVERSATIONS - Activate Chat Bot");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${id}/activate-bot`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async takeOverFromBot(id: string) {
    UserTracker.track("CONVERSATIONS - Takeover Chat from Bot");
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${id}/deactivate-bot`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async setAutoTranslationMode(
    conversationId: string,
    config: iChatTranslatorConfig,
  ) {
    UserTracker.track("CONVERSATIONS - Set Auto Translation", {
      active: config.isEnabled,
    });
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath +
        `/conversations/${conversationId}/auto-translation`,
      config,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async bulkEditConversations(req: ConversationsReq_BulkAction) {
    UserTracker.track("CONVERSATIONS - Bulk Edit Conversations", {
      action: req.action,
    });
    const results: AxiosResponse<{
      status: boolean;
      successful: string[];
      failed: string[];
    }> = await axios.post(
      this.config.basePath + `/conversations/bulk-edit`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async markConversationAsSpam(conversationId: string) {
    UserTracker.track("CONVERSATIONS - Mark as Spam", {});
    const results: AxiosResponse<iConversation> = await axios.post(
      this.config.basePath + `/conversations/${conversationId}/mark-as-spam`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  /* -------------------------------- Message -------------------------------- */

  async startNewConversationWithMessage(req: iNewConversationRequest) {
    UserTracker.track("CONVERSATIONS - Start Chat With Message");
    const results: AxiosResponse<{
      status: boolean;
    }> = await axios.post(this.config.basePath + `/messages`, req, {
      headers: {
        Authorization: this.config.token,
      },
    });
    return results.data;
  }

  async getMessagesOfContact(contactId: string, limit = 50, offset = 0) {
    const results: AxiosResponse<PaginatedResults<iMessage>> = await axios.get(
      this.config.basePath + `/messages`,
      {
        headers: {
          Authorization: this.config.token,
        },
        params: {
          contactId,
          limit,
          offset,
        },
      },
    );
    return results.data;
  }

  async getMessagesByConversationId(
    conversationId: string,
    limit = 50,
    offset = 0,
  ) {
    const results: AxiosResponse<PaginatedResults<iMessage>> = await axios.get(
      this.config.basePath + `/messages`,
      {
        headers: {
          Authorization: this.config.token,
        },
        params: {
          conversationId,
          limit,
          offset,
        },
      },
    );
    return results.data;
  }

  async getMessagesById(id: string) {
    const results: AxiosResponse<iMessage> = await axios.get(
      this.config.basePath + `/messages/${id}`,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async editMessage(id: string, edits: DeepPartial<iMessage>) {
    UserTracker.track("CONVERSATIONS - Edit Message");
    const results: AxiosResponse<iMessage> = await axios.patch(
      this.config.basePath + `/messages/${id}`,
      edits,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async forwardMessage(
    id: string,
    req: { emails: string[]; body: string; attachments: string[] },
  ) {
    UserTracker.track("CONVERSATIONS - Forward Message");
    const results: AxiosResponse<iMessage> = await axios.post(
      this.config.basePath + `/messages/${id}/forward`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }
}
