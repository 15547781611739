import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { AvailabilityStatus } from "@sdk/user-management/user-management.models";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setAwayDetectorState } from "store/modules/ui-state/ui-state.actions";
import { selectAwayDetectorAlert } from "store/modules/ui-state/ui-state.selectors";
import { selectCurrentUserAvailabilityStatus } from "store/modules/users/users.selectors";

export const AwayDetectorBanner = () => {
  const isAwayDetectorBannerActive = useSelector(selectAwayDetectorAlert);

  const currentUserAvailability = useSelector(
    selectCurrentUserAvailabilityStatus,
  );

  const { doAction: changeAvailabilityStatus, isProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (availabilityStatus: AvailabilityStatus) =>
          SDK.editCurrentUser({
            metaData: {
              availabilityStatus,
            },
          }),
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const dispatch = useDispatch();

  return (
    <>
      {isAwayDetectorBannerActive && currentUserAvailability === "AWAY" && (
        <div className="bg-yellow-200 dark:bg-yellow-400 font-bold text-orange-800 flex justify-center items-center p-2 absolute w-full z-10">
          You availability status has been changed automatically
          <Button
            type="text"
            icon={<i className="ri-checkbox-circle-line"></i>}
            onClick={() => changeAvailabilityStatus("AVAILABLE")}
            className="dark:bg-yellow-400"
          >
            Become Available
          </Button>
          <Button
            type="text"
            icon={<i className="ri-rest-time-line"></i>}
            onClick={() => dispatch(setAwayDetectorState(false))}
            className="dark:bg-yellow-400"
          >
            Dismiss
          </Button>
        </div>
      )}
    </>
  );
};
