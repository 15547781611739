import { iCampaign } from "@sdk/campaigns/campaigns-model";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectCampaignQueryMap,
  selectEntityMap: selectCampaignsMap,
  selectQueryMapAll: selectCampaignsMapAll,
  selectQueryAllList: selectCampaignsAllList,
  selectAllEntities: selectAllCampaigns,
  selectAllEntitiesQuery: selectAllCampaignsQuery,
  selectEntityById: selectCampaignById,
  selectEntityQuery: selectCampaignQuery,
  selectEntityQueryList: selectCampaignQueryList
} = CreateSelectorForEntities<iStore, iCampaign>({
  sliceName: "campaigns"
});

export const selectCampaignByConnectionId = (connectionId: string) => (
  state: iStore
) => {
  return _.find(selectAllCampaigns(state), {
    connectionId
  });
};
