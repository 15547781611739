import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import smoothscroll from "smoothscroll-polyfill";
import { UserTracker } from "user-tracker";
import App from "./App";
import "./index.css";
import "./init";

import * as serviceWorker from "./serviceWorker";

// kick off the polyfill!
smoothscroll.polyfill();

(window as any).__forceSmoothScrollPolyfill__ = true;

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://92a70330a4044b4098dec68dec602db6@o288872.ingest.sentry.io/1525356",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",
      "ChunkLoadError",
      "URIError",
      "Failed to execute 'insertBefore' on 'Node'",
      "Loading CSS chunk",
      "Network Error",
      "Request failed with",
      "Request failed with status code 403",
      "Request failed with status code 400",
      "Request failed with status code 500",
      "Cannot read properties of undefined (reading 'default')",
      "timeout of 0ms exceeded",
      "Failed to load Stripe.js",
      "Request aborted",
      "Unable to find node on an unmounted component",
      "SecurityError: Failed to get ServiceWorkerRegistration",
      "ServiceWorker script at",
      "An attempt was made to use an object that is not, or is no",
      "/C:/Users",
      "Failed to update a ServiceWorker for scope ",
      "Non-Error promise rejection captured with keys",
      "Page should start with 1",
      "InvalidStateError: Failed to update a ServiceWorker for scope",
      "An attempt was made to use an object that is not, or is no longer, usable",
      "AbortError: The play() request was interrupted",
      "Error: Minified Lexical error",
      "Error while setting state. 1) State accepts only",
      "InvalidStateError: Failed to update a ServiceWorker for scope",
      "unmountComponentAtNode",
      "an object that is not, or is no longer, usable",
      "Unable to preload CSS for",
      "Failed to fetch dynamically imported module",
      "(reading 'ancestors')",
      "Unable to preload CSS for",
      "error loading dynamically",
    ],
    beforeSend: (event, hint) => {
      if (!event.exception) {
        // not an error transaction
        return null;
      }
      return event;
    },
    beforeBreadcrumb: (breadcrumb, hint) => {
      // Eg:
      // <button data-sentry="very-important-button">Hi there!</button>;
      if (breadcrumb?.category?.startsWith("ui")) {
        const target = hint?.event.target;
        const customName = target.dataset.sentry;
        const customMessage = `${target.tagName.toLowerCase()}[sentry="${customName}"]`;
        breadcrumb.message = customName ? customMessage : breadcrumb.message;
        // Todo: Could Re-use the mixpanel naming
      }
      return breadcrumb;
    },
  });
  UserTracker.init();
}

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({});
