export const ConnectionIcons = {
  EMAIL: "ri-mail-line",
  EMAIL_V2: "ri-mail-line",
  SMS: "ri-chat-4-fill",
  FACEBOOK: "ri-facebook-box-fill",
  WEB_CHAT: "ri-message-3-line",
  WEB_FORM: "ri-file-list-line",
  WHATS_APP_PRIVATE: "ri-whatsapp-fill",
  WHATS_APP_OFFICIAL: "ri-whatsapp-fill",
  WHATS_APP_CLOUD: "ri-whatsapp-fill",
  WHATS_APP_CLOUD_NATIVE: "ri-whatsapp-fill",
  INSTAGRAM: "ri-instagram-fill",
  TWITTER: "ri-twitter-fill",
  TELNYX: "ri-phone-fill", // ri-smartphone-fill
  TELEGRAM: "ri-telegram-fill", // ri-smartphone-fill
};
