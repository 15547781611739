import { axios, AxiosResponse } from "@sdk/axios";
import {
  iArticle,
  iArticleCollection,
} from "@sdk/knowledge-base/knowledge-base.models";
import {
  PaginatedOptions,
  PaginatedResults,
} from "@sdk/utils/paginated-results";

const CCOrganizationId = "fc89e0";

export class CCKnowledgeBaseService {
  constructor(protected config: { basePath: string; token: string }) {}

  /* -------------------------------- Connection -------------------------------- */

  ccArticles = {
    query: async (req: { query: any; options: PaginatedOptions }) => {
      const results: AxiosResponse<PaginatedResults<iArticle>> =
        await axios.post(this.config.basePath + `/articles/public/query`, req, {
          headers: {
            Authorization: this.config.token,
          },
          params: {
            organizationId: CCOrganizationId,
          },
        });
      return results.data;
    },
    getById: async (id: string) => {
      const results: AxiosResponse<iArticle> = await axios.get(
        this.config.basePath + `/articles/public/${id}`,
        {
          headers: {
            Authorization: this.config.token,
          },
          params: {
            organizationId: CCOrganizationId,
          },
        },
      );
      return results.data;
    },
    search: async (searchTerm: string) => {
      const results: AxiosResponse<iArticle[]> = await axios.post(
        this.config.basePath + `/articles/public/search`,
        { query: searchTerm },
        {
          headers: {
            Authorization: this.config.token,
          },
          params: {
            organizationId: CCOrganizationId,
          },
        },
      );
      return results.data;
    },
  };

  ccCollections = {
    query: async (req: { query: any; options: PaginatedOptions }) => {
      const results: AxiosResponse<PaginatedResults<iArticleCollection>> =
        await axios.post(
          this.config.basePath + `/article-collections/public/query`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
            params: {
              organizationId: CCOrganizationId,
            },
          },
        );
      return results.data;
    },
    getById: async (id: string) => {
      const results: AxiosResponse<iArticleCollection> = await axios.get(
        this.config.basePath + `/article-collections/public/${id}`,
        {
          headers: {
            Authorization: this.config.token,
          },
          params: {
            organizationId: CCOrganizationId,
          },
        },
      );
      return results.data;
    },
  };
}
