import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ConversationSummaryModal = React.lazy(
  () => import("./conversation-summary.modal"),
);

const ConversationSummary: iModalProps<
  typeof ConversationSummaryModal,
  {
    conversationId: string;
  }
> = {
  name: "chat-analyzer",
  component: GenerateModalWithSuspense(ConversationSummaryModal),
};

export default ConversationSummary;
