import { iFbConnectionData } from "@sdk/conversations/conversations.models";
import { iContactConnectionTypes } from "@sdk/crm/crm.models";
import gravatarUrl from "gravatar-url";
import { useMemo } from "react";
import { useSelector, useStore } from "react-redux";
import { selectConnectionsMap } from "store/modules/connections/connections.selectors";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { selectIsSessionOnline } from "store/modules/user-presence/user-presence.selectors";
import { useDoubleSelectorWithMemoize } from "store/utils/use-double-selector-with-memoize";
import { getFbAvatar } from "utils/get-fb-avatar";

export const useContactAvatar = (contactId: string, connectionId?: string) => {
  const store = useStore();

  const { contactExists, connections, primaryEmail, firstName, avatar } =
    useDoubleSelectorWithMemoize(
      () => selectContactById(contactId!),
      [contactId],
      (contact) => ({
        contactExists: Boolean(contact),
        connections: contact?.metaData.connections,
        primaryEmail: contact?.data.primaryEmail,
        firstName: contact?.data.firstName,
        avatar: contact?.data.avatar,
      }),
    );

  const avatarData = useMemo(() => {
    const data: {
      sessionIds: string[];
      primaryEmail?: string;
      fbProfile?: {
        fbUserId: string;
        connectionId: string;
        accessToken: string;
      };
      connectionTypes: iContactConnectionTypes[];
      connectionTypeInContext?: string;
    } = {
      sessionIds: [],
      connectionTypes: [],
    };
    if (!contactExists) {
      return data;
    }
    const connectionsMap = selectConnectionsMap(store.getState());
    if (connectionId) {
      data.connectionTypeInContext = connectionsMap[connectionId]?.type;
    }

    for (const connectionData of connections || []) {
      if (connectionId && connectionData.connectionId !== connectionId) {
        continue;
      }
      data.connectionTypes.push(connectionData.type);
      if (connectionData.type === "FACEBOOK") {
        data.fbProfile = {
          fbUserId: connectionData.data.fbUserId!,
          connectionId: connectionData.connectionId,
          accessToken: connectionsMap[connectionData.connectionId!]
            ? (
                connectionsMap[connectionData.connectionId!]
                  .data as iFbConnectionData
              )?.page?.access_token
            : "",
        };
      }
      if (connectionData.type === "WEB_CHAT") {
        data.sessionIds = [
          ...data.sessionIds,
          ...(connectionData.data.associatedSessions || []),
        ];
      }
    }
    data.primaryEmail = primaryEmail;
    return data;
  }, [connectionId, connections, contactExists, primaryEmail, store]);

  const isOnline = useSelector(selectIsSessionOnline(avatarData.sessionIds));

  return {
    name: firstName,
    avatar:
      avatarData.fbProfile && avatarData.fbProfile.accessToken
        ? getFbAvatar(
            avatarData.fbProfile.fbUserId,
            avatarData.fbProfile.accessToken,
          )
        : avatar ||
          (avatarData.primaryEmail
            ? gravatarUrl(avatarData.primaryEmail, {
                size: 64,
                default: "identicon", // https://en.gravatar.com/site/implement/images/
                // default:
                //   "https://cc.clickconnector.com/assets/img/avatars/missing-profile-picture.jpg",
              })
            : undefined),
    connectionType:
      avatarData.connectionTypeInContext || avatarData.connectionTypes[0],
    isOnline,
  };
};
