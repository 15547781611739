import { axios, AxiosResponse } from "@sdk/axios";
import { UserTracker } from "user-tracker";
import { iNotificationRecord } from "./notifications.models";

export class NotificationInboxServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  async getAllNotifications() {
    const results: AxiosResponse<iNotificationRecord[]> = await axios.get(
      this.config.basePath + `/notifications`,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async getNotificationById(notificationId: string) {
    const results: AxiosResponse<iNotificationRecord> = await axios.get(
      this.config.basePath + `/notifications/${notificationId}`,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async dismissNotification(notificationId: string) {
    UserTracker.track("NOTIFICATIONS - Dismiss Notification", {});
    const results: AxiosResponse<iNotificationRecord> = await axios.post(
      this.config.basePath + `/notifications/${notificationId}/dismiss`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async dismissAllNotification() {
    UserTracker.track("NOTIFICATIONS - Dismiss All Notifications", {});
    const results: AxiosResponse<iNotificationRecord> = await axios.post(
      this.config.basePath + `/notifications/dismiss-all`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }
}
