import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const EditUserModal = React.lazy(() => import("./edit-user.modal"));

const EditUser: iModalProps<typeof EditUserModal, { userId: string }> = {
  name: "edit-users",
  component: GenerateModalWithSuspense(EditUserModal),
};

export default EditUser;
