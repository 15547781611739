import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddTwitterConnectionModal = React.lazy(
  () => import("./add-twitter-connection.modal"),
);

const AddTwitterConnection: iModalProps<typeof AddTwitterConnectionModal> = {
  name: "add-twitter-connection",
  component: GenerateModalWithSuspense(AddTwitterConnectionModal),
};

export default AddTwitterConnection;
