import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ConfigurationsModal = React.lazy(() => import("./configurations.modal"));

const Configurations: iModalProps<typeof ConfigurationsModal, {}> = {
  name: "configurations",
  component: GenerateModalWithSuspense(ConfigurationsModal),
};

export default Configurations;
