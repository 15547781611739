import { flatten } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectShopifyStoreConfig } from "store/modules/workspace/workspace.selectors";
import { AllActionsByGroup as _AllActionsByGroup } from "../action-editor/actions-registry";

export const useValidActions = ({
  includedActionGroups,
  excludeActions,
}: {
  excludeActions?: string[];
  includedActionGroups?: string[];
}) => {
  const shopifyStoreConfig = useSelector(selectShopifyStoreConfig);

  const AllActionsByGroup = useMemo(() => {
    const isShopifyConnected = shopifyStoreConfig?.isActive;
    return _AllActionsByGroup
      .filter((item) =>
        item.label === "Shopify Actions" ? isShopifyConnected : true,
      )
      .filter((item) => {
        if (includedActionGroups) {
          if (includedActionGroups.includes(item.label)) {
            return true;
          }
          return false;
        }
        return true;
      });
  }, [includedActionGroups, shopifyStoreConfig?.isActive]);

  const AllValidActions = useMemo(() => {
    return flatten(
      AllActionsByGroup.map((item) =>
        item.actions.filter((action) => {
          if (excludeActions && excludeActions.includes(action)) {
            return false;
          }
          return true;
        }),
      ),
    );
  }, [AllActionsByGroup, excludeActions]);

  return { AllValidActions, AllActionsByGroup };
};
