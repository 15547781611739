import {
  Fields,
  FieldSettings,
} from "@libs/@awesome-query-builder/raqb-models";
import dayjs from "dayjs";

import { durationOptions } from "components/pages/reports/helpers/chat-duration-options";
import { messageCountOptions } from "components/pages/reports/helpers/chat-message-count-options";
import { chatRatingOptions } from "components/pages/reports/helpers/chat-rating-options";
import _ from "lodash";
// https://codesandbox.io/s/4crkq?file=/src/demo/config_simple.tsx

/*  
        connectionId
        connectionType
        contactId
        priority
        subject
        status "OPEN" | "CLOSED";
        pipeline
        channel
        tags
        members
        */

export interface iChatSearchSchemaFilter {
  label: string;
  placeholder: string;
  icon?: string;
  key: string;
  type: "number" | "text" | "select" | "boolean" | "CUSTOM";
  customWidget?: "CONTACT_SELECTOR" | "USER_SELECTOR";
  fieldSettings?: FieldSettings;
}
interface iChatSearchSchema {
  filters: iChatSearchSchemaFilter[];
}

export const ChatSearch: iChatSearchSchema = {
  filters: [
    {
      label: "Connection",
      icon: "ri-wireless-charging-line",
      placeholder: "Select a connection",
      key: "connectionId",
      type: "select",
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Connection Type",
      icon: "ri-sim-card-fill",
      key: "connectionType",
      placeholder: "Select a connection type",
      type: "select",
      fieldSettings: {
        listValues: [
          { value: "Chrome", title: "Chrome" },
          { value: "IOS", title: "IOS" },
          { value: "Firefox", title: "Firefox" },
        ],
      },
    },
    {
      label: "Contact",
      // icon: "ri-map-pin-user-line",
      icon: "ri-contacts-line",
      placeholder: "Select a contact",
      key: "contactId",
      type: "CUSTOM",
      customWidget: "CONTACT_SELECTOR",
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Subject",
      icon: "ri-message-2-line",
      placeholder: "Enter subject line",
      key: "subject",
      type: "text",
    },
    {
      label: "Notes",
      icon: "ri-sticky-note-2-line",
      placeholder: "...",
      key: "notes",
      type: "text",
    },
    {
      label: "Status",
      icon: "ri-archive-line",
      placeholder: "Select conversation status",
      key: "status",
      type: "select",
      fieldSettings: {
        listValues: [
          { value: "OPEN", title: "Open" },
          { value: "CLOSED", title: "Closed" },
        ],
      },
    },
    {
      label: "Pipeline",
      icon: "ri-flag-line",
      // icon: "ri-inbox-archive-line",
      // icon: "ri-filter-fill",
      placeholder: "Select a pipeline stage",
      key: "pipeline",
      type: "select",
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Channel",
      icon: "ri-archive-drawer-line",
      placeholder: "Select a conversation channel",
      key: "channel",
      type: "select",
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Tags",
      icon: "ri-price-tag-3-fill",
      key: "tags",
      type: "select",
      placeholder: "Select a tag",
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Members",
      icon: "ri-group-line",
      placeholder: "Select a conversation members",
      key: "members",
      type: "CUSTOM",
      customWidget: "USER_SELECTOR",
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "User Group",
      icon: "ri-group-line",
      placeholder: "Select a user group",
      key: "userGroup",
      type: "select",
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Includes Text",
      icon: "ri-chat-quote-line",
      placeholder: "Input in any text",
      key: "messageText",
      type: "text",
    },
    {
      label: "Requires Attention",
      icon: "ri-alarm-warning-line",
      placeholder: "Requires Attention",
      key: "requiresAttention",
      type: "boolean",
    },
    {
      label: "Requires Followup",
      icon: "ri-flag-line",
      placeholder: "Requires Followup",
      key: "requiresFollowup",
      type: "boolean",
    },
    {
      label: "First Response Time",
      icon: "ri-timer-flash-line",
      placeholder: "Select a First Response Time Period",
      key: "responseTime",
      type: "select",
      fieldSettings: {
        listValues: [],
      },
    },
    {
      label: "Duration",
      icon: "ri-timer-line",
      placeholder: "Select a conversation Duration",
      key: "duration",
      type: "select",
      fieldSettings: {
        listValues: durationOptions,
      },
    },
    {
      label: "Message Count",
      icon: "ri-list-ordered",
      placeholder: "Select a conversation Message Count",
      key: "messageCount",
      type: "select",
      fieldSettings: {
        listValues: messageCountOptions,
      },
    },
    {
      label: "Conversation Rating",
      icon: "ri-emotion-happy-line",
      placeholder: "Select a conversation Rating",
      key: "chatRating",
      type: "select",
      fieldSettings: {
        listValues: chatRatingOptions,
      },
    },
  ],
};

type FilterObjKeys =
  | "period"
  | "periodType"
  | "connectionId"
  | "connectionType"
  | "contactId"
  | "subject"
  | "notes"
  | "status"
  | "pipeline"
  | "channel"
  | "tags"
  | "members"
  | "messageText"
  | "requiresAttention"
  | "requiresFollowup"
  | "responseTime"
  | "duration"
  | "messageCount"
  | "chatRating";

export const generateFilterQuery = (
  filterObj: Record<FilterObjKeys, string>,
) => {
  return {
    conversation: {
      ..._.omit(filterObj, [
        "period",
        "periodType",
        "messageText",
        "members",
        "pipeline",
        "requiresAttention",
        "responseTime",
        "duration",
        "messageCount",
        "chatRating",
        "requiresFollowup",
      ]),
      ...(filterObj.period && filterObj.period.length === 2
        ? (() => {
            if (filterObj.periodType === "CREATED_DATE") {
              return {
                "metaData.objectCreatedAt": {
                  $gt: dayjs(filterObj.period[0]).startOf("day").valueOf(),
                  $lt: dayjs(filterObj.period[1]).endOf("day").valueOf(),
                },
              };
            }
            if (filterObj.periodType === "LAST_ACTIVITY") {
              return {
                "metaData.lastMessage.timestamp": {
                  $gt: dayjs(filterObj.period[0]).startOf("day").valueOf(),
                  $lt: dayjs(filterObj.period[1]).endOf("day").valueOf(),
                },
              };
            }

            return {
              "metaData.objectCreatedAt": {
                $gt: dayjs(filterObj.period[0]).startOf("day").valueOf(),
              },
              "metaData.lastMessage.timestamp": {
                $lt: dayjs(filterObj.period[1]).endOf("day").valueOf(),
              },
            };
          })()
        : {}),
      ...(filterObj.members &&
      Array.isArray(filterObj.members as any) &&
      (filterObj.members as any).length > 0
        ? {
            members: {
              $elemMatch: {
                userId: { $in: filterObj.members },
              },
            },
          }
        : {}),
      ...(filterObj.pipeline
        ? {
            "pipeline.id": filterObj.pipeline,
          }
        : {}),

      ...(filterObj.requiresAttention
        ? {
            "metaData.flags.requiresAttention.isActive": true,
          }
        : {}),
      ...(filterObj.requiresFollowup
        ? {
            "metaData.flags.requiresFollowup.isActive": true,
          }
        : {}),
      ...(filterObj.responseTime
        ? {
            "metaData.firstResponseTime": JSON.parse(filterObj.responseTime),
          }
        : {}),
      ...(filterObj.duration
        ? {
            "metaData.duration": JSON.parse(filterObj.duration),
          }
        : {}),
      ...(filterObj.messageCount
        ? {
            "metaData.totalMessages": JSON.parse(filterObj.messageCount),
          }
        : {}),
      ...(filterObj.chatRating
        ? {
            "chatRating.rating": Number(filterObj.chatRating),
          }
        : {}),
    },
    messages: filterObj.messageText
      ? GenerateMessageTextFilterQuery(filterObj.messageText)
      : undefined,
  };
};

export const GenerateQueryFieldSchema = (searchSchema: iChatSearchSchema) => {
  const fields: Fields = {};
  for (const filterSchema of searchSchema.filters) {
    fields[filterSchema.key] = {
      label: filterSchema.label,
      type: filterSchema.type,
      fieldSettings: filterSchema.fieldSettings,
      valueSources: ["value"],
      //   preferWidgets: ["number","rangeslider"],
    };
  }
  return fields;
};

export const GenerateMessageTextFilterQuery = (term: string) => {
  return {
    $text: {
      $search: term,
    },
  };
  // return {
  //   message: {
  //     $regex: escapeRegExp(term),
  //     $options: "i",
  //   },
  // };
};
