import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iHQSlice } from "./hq.model";

const HQSlice = createSlice({
  name: "hq",
  initialState: {
    HQAccessToken: ""
  } as iHQSlice,
  reducers: {
    setHQAccessToken(state, action: PayloadAction<string>) {
      state.HQAccessToken = action.payload;
    },

    logoutHQ(state) {
      state.HQAccessToken = "";
    }
  }
});

export const HQSliceReducers = HQSlice.reducer;

export const HQSliceActions = HQSlice.actions;
