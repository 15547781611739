import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const KnowledgePlaygroundModal = React.lazy(
  () => import("./knowledge-playground.modal"),
);

const KnowledgePlayground: iModalProps<
  typeof KnowledgePlaygroundModal,
  {
    initialQuery?: string;
    processInitialQuery?: boolean;
  }
> = {
  name: "knowledge-playground",
  component: GenerateModalWithSuspense(KnowledgePlaygroundModal),
};

export default KnowledgePlayground;
