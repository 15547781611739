import SimplePrompt from "components/common/simple-promot-modal/simple-prompt.modal-registry";
import AddChatWidget from "components/modules/connections/channels/chat-widget/components/add-chat-widget-connection-modal/add-chat-widget-connection.modal-registry";
import AddEmailConnection from "components/modules/connections/channels/email/components/add-email-connection-modal/add-email-connection.modal-registry";
import AddFacebookConnection from "components/modules/connections/channels/facebook/add-facebook-connection-modal/add-facebook-connection.modal-registry";
import AddInstagramConnection from "components/modules/connections/channels/instagram/add-instagram-connection-modal/add-instagram-connection.modal-registry";
import AddTelegramConnection from "components/modules/connections/channels/telegram/add-telegram-connection-modal/add-telegram-connection.modal-registry";
import AddTelnyxConnection from "components/modules/connections/channels/telnyx/add-telnyx-connection-modal/add-telnyx-connection.modal-registry";
import AddTwitterConnection from "components/modules/connections/channels/twitter/add-twitter-connection-modal/add-twitter-connection.modal-registry";
import AddWACloudNativeConnection from "components/modules/connections/channels/whatsapp-cloud-native/add-whatsapp-cloud-native-connection-modal/add-whatsapp-cloud-native-connection.modal-registry";
import AddWACloudConnection from "components/modules/connections/channels/whatsapp-cloud/add-whatsapp-cloud-connection-modal/add-whatsapp-cloud-connection.modal-registry";
import AddWhatsappConnection from "components/modules/connections/channels/whatsapp-private/add-whatsapp-private-connection-modal/add-whatsapp-private-connection.modal-registry";
import TroubleshootWhatsappConnection from "components/modules/connections/channels/whatsapp-private/troubleshoot-whatsapp-private-connection-modal/troubleshoot-whatsapp-private-connection.modal-registry";
import AddNewConnection from "components/pages/configurations/sections/add-new-connection.modal-registry";
import AcademyViewer from "components/pages/home/academy-viewer.modal-registy";
import ExpressAccountSetUp from "components/pages/home/express-account-setup.modal-registry";
import CustomizeExperience from "components/pages/home/onboarding/customize-experience.modal-registry";

import BgPatternPicker from "components/common/bg-pattern-selector/bg-pattern-selector.modal-registry";

import HelpCenterLegacy from "components/common/help-center-legacy/help-center.modal-registry";
import UnsplashImageSelector from "components/common/unsplash-image-selector/unsplash-image-selector.modal-registry";
import DownloadApp from "components/electron-app/download-app/download-app.modal-registry";

import ConfigurePersonalMailbox from "components/modules/connections/channels/email/components/configure-personal-mailbox/configure-personal-mailbox.modal-registry";
import AddQuickCustomInbox from "components/modules/conversations/components/add-quick-custom-inbox/add-quick-custom-inbox.modal-registry";
import ScheduleReminder from "components/modules/conversations/components/chat-view-side-bar/components/reminder-scheduler.modal-registry";
import ConversationsPicker from "components/modules/conversations/components/conversation-picker-modal/conversation-picker.modal-registry";
import ConversationViewer from "components/modules/conversations/components/conversation-viewer-modal/conversation-viewer.modal-registry";
import AddChatBot from "components/modules/conversations/components/manage-chat-bots/add-chat-bot.modal-registry";
import BotBuilder from "components/modules/conversations/components/manage-chat-bots/bot-builder.modal-registry";
import EditChatBot from "components/modules/conversations/components/manage-chat-bots/edit-chat-bot.modal-registry";
import AddMessagePreset from "components/modules/conversations/components/manage-presets-x/add-message-preset.modal-registry";
import MyManagePresets from "components/modules/conversations/components/manage-presets-x/manage-presets-x.modal-registry";
import NewMessageCreator from "components/modules/conversations/components/new-message/new-message.modal-registry";
import AddActivity from "components/modules/crm/activities/add-activity/add-activity.modal-registry";
import AddCompany from "components/modules/crm/companies/components/add-company/add-company.modal-registry";
import CompanyPicker from "components/modules/crm/companies/components/company-picker-modal/company-picker.modal-registry";
import AddContact from "components/modules/crm/contacts/components/add-contact/add-contact.modal-registry";
import BulkAddContacts from "components/modules/crm/contacts/components/bulk-add-contacts/bulk-add-contacts.modal-registry";
import ContactPicker from "components/modules/crm/contacts/components/contact-picker-modal/contact-picker.modal-registry";
import EditContact from "components/modules/crm/contacts/components/edit-contact/edit-contact.modal-registry";
import AddEngagement from "components/modules/crm/engagements/add-engagement.modal-registry";
import CreateOpportunity from "components/modules/crm/opportunities/create-opportunity.modal-registry";
import AddProduct from "components/modules/crm/products/add-product.modal-registry";
import MyEmailSignatures from "components/modules/email-signatures/email-signature-manager.modal-registry";
import DPASigner from "components/modules/legal/dpa-signer.modal-registry";
import AddAPIUser from "components/modules/organization-management/general/apps-and-integrations/add-api-user/add-api-user.modal-registry";
import AddWebhookApp from "components/modules/organization-management/general/apps-and-integrations/add-webhook-app-model/add-webhook-app.model-registry";
import TestWebhookApp from "components/modules/organization-management/general/apps-and-integrations/test-webhook-app-model/test-webhook-app.model-registry";
import CreateSubAccountWorkspace from "components/modules/organization-management/general/billing/create-sub-account.modal-registry";
import AddTask from "components/modules/tasks/add-task-modal/add-task.modal-registry";
import TaskViewer from "components/modules/tasks/task-viewer/task-viewer.modal-registry";
import TestimonialCollection from "components/modules/testimonial-collection-modal/testimonial-collection.modal-registry";
import AddEditUserGroup from "components/modules/user-management/user-groups/add-edit-user-group/add-edit-user-group.modal-registry";
import ManageUserGroupMembers from "components/modules/user-management/user-groups/manage-members/manager-members.registry";
import AddUser from "components/modules/user-management/users/components/add-user/add-user.modal-registry";
import BulkAddUsers from "components/modules/user-management/users/components/bulk-add-users/bulk-add-users.modal-registry";
import BulkEditUsers from "components/modules/user-management/users/components/bulk-edit-users/bulk-edit-users.modal-registry";
import EditUser from "components/modules/user-management/users/components/edit-user/edit-user.modal-registry";
import ChangeMyEmail from "components/modules/user-management/users/components/my-profile/components/change-email.modal-regsitry";
import ChangeMyPassword from "components/modules/user-management/users/components/my-profile/components/change-password.modal-registry";
import MyProfileX from "components/modules/user-management/users/components/my-profile/my-profile.modal-registry";
import UserPicker from "components/modules/user-management/users/components/user-picker-modal/user-picker.modal-registry";
import MyProfile from "components/modules/user-management/users/components/user-profile/user-profile.modal-registry";
import MessageVariantEditor from "components/pages/bot-builder/node-types/message-node/message-variant-editor.modal-registry";
import AddCampaign from "components/pages/campaigns/add-campaign.modal-registry";
import ViewCampaignReportLogs from "components/pages/campaigns/components/campaign-logs-modal/view-campaign-logs.modal-registry";
import ViewCampaignReport from "components/pages/campaigns/components/campaign-report-modal/view-campaign-report.modal-registry";
import ConfigureCampaign from "components/pages/campaigns/configure-campaign.modal-registry";
import EditEmailCampaign from "components/pages/campaigns/edit-email-campaign.modal-registry";
import SimpleEmailBlockEditor from "components/pages/campaigns/simple-email-creator/simple-email-block-editor.modal-registry";
import CompanyProfile from "components/pages/companies/components/company-profile/company-profile.modal-registry";
import SideBarContactProfileSectionConfig from "components/pages/configurations/sections/inbox-settings/components/chat-side-bar-profile-config.modal-registry";
import ContactProfile from "components/pages/contacts/components/contact-profile/contact-profile-v2.modal-registry";
import ConversationSummary from "components/pages/conversations/conversation-summary.modal-registry";

import PNRConverter from "components/pages/conversations/pnr-converter/pnr-converter.modal-registry";
import HolidayQuoteCreator from "components/pages/conversations/quote-creator/holiday-quote-creator.modal-registry";
import QuoteBlockEditor from "components/pages/conversations/quote-creator/quote-block-editor.modal-registry";
import QuoteEditor from "components/pages/conversations/quote-creator/quote-editor.modal-registry";
import QuoteHeaderEditor from "components/pages/conversations/quote-creator/quoter-header-editor.modal-registry";
import NewScreenRecorder from "components/pages/conversations/screen-recording-picker/new-screen-recorder/new-screen-recorder.modal-registry";
import ScreenRecordingPicker from "components/pages/conversations/screen-recording-picker/screen-recording-picker.modal-registry";
import UniboxShortcutsExplorer from "components/pages/conversations/unibox-shortcuts-explorer.modal-registry";
import AddNewForm from "components/pages/dynamic-forms/manage-dynamic-forms/add-dynamic-form.modal-registry";
import ManageForm from "components/pages/dynamic-forms/manage-dynamic-forms/configure-dynamic-form.modal-registry";
import AllBadges from "components/pages/home/all-badges.modal-registry";
import BadgeViewer from "components/pages/home/badge-viewer.modal-registry";
import NewBadgeEarned from "components/pages/home/new-badge-earned.modal-registry";
import AddSelfServicePortal from "components/pages/knowledge-base/add-self-service-portals.modal-registry";

import ArticlePreview from "components/pages/knowledge-base/article-preview/article-preview.modal-registry";
import CollectionAddEdit from "components/pages/knowledge-base/collections/components/collection-add-edit.modal-registry";
import FileSelector from "components/pages/knowledge-base/collections/components/file-selector.modal-registry";
import ConfigureSelfServicePortal from "components/pages/knowledge-base/configure-self-service-portals.modal-registry";
import KnowledgeBaseManageArticles from "components/pages/knowledge-base/knowledge-base-manage-articles.modal-registry";
import KnowledgeBaseManageCollections from "components/pages/knowledge-base/knowledge-base-manage-collection.modal-registry";
import AddEditKnowledgeDocument from "components/pages/knowledge-kit-page/add-edit-knowledge-document/add-edit-document.modal-registry";
import AddEditMagicAssistant from "components/pages/knowledge-kit-page/add-edit-magic-assistants.modal-registry";

import CreatePipelineCardModal from "components/pages/pipelines/components/create-pipeline-card.modal-registry";
import AddEditProductTour from "components/pages/product-tours/add-edit-product-tour.modal-registry";
import ConfigureTour from "components/pages/product-tours/configure-tour/configure-tour.modal-registry";
import TourStepEditor from "components/pages/product-tours/configure-tour/tour-step-editor.modal-registry";
import AddCustomReport from "components/pages/reports/components/add-custom-report-modal/add-custom-report.modal-registry";
import AddSequence from "components/pages/sequences-page/add-sequence/add-sequence.modal-registry";
import SequenceDelayEditor from "components/pages/sequences-page/delay-editor/delay-editor.modal-registry";
import EmailBodyEditor from "components/pages/sequences-page/email-body-editor/email-body-editor.modal-registry";
import SequenceEditor from "components/pages/sequences-page/sequence-editor-modal/sequence-editor.modal-registry";
import SequenceStatus from "components/pages/sequences-page/sequence-status-modal/sequence-status.modal-registry";
import SequenceStepEditor from "components/pages/sequences-page/step-editor-modal/step-editor.modal-registry";
import CreateTicket from "components/pages/tickets/components/create-ticket/create-ticket.model-registry";

import APIVideoPlayerPreview from "components/common/api-video-player/api-video-player-preview.modal-registry";
import CCEmbedPreview from "components/common/cc-embed-preview/cc-embed-preview.modal-registry";
import CCEmbed from "components/common/cc-embed/cc-embed.modal-registry";
import ChangeLogsLegacy from "components/common/change-logs-legacy/change-logs.modal-registry";
import ConfigurationEditor from "components/common/configuration-editor.modal-registry";
import SimpleConfirm from "components/common/simple-confirm-modal/simple-confirm.modal-registry";
import SimpleModal from "components/common/simple-empty-modal/simple-empty.modal-registry";
import EditFeatureRequestLegacy from "components/feature-requests-legacy/components/edit-feature-request-modal/edit-feature-request.modal-registry";
import FeatureRequestViewerLegacy from "components/feature-requests-legacy/components/feature-viewer-modal/feature-viewer.modal-registry";
import ReportBugLegacy from "components/feature-requests-legacy/components/report-bug-modal/report-bug-modal.modal-registry";
import RequestLegacyFeature from "components/feature-requests-legacy/components/request-feature-modal/request-feature.modal-registry";
import FeatureRequestsLegacy from "components/feature-requests-legacy/feature-requests.modal-registry";
import ManageChatCampaigns from "components/modules/connections/channels/chat-widget/components/manage-triggers/manage-chat-campaigns.modal-registry";
import AddEmailV2Connection from "components/modules/connections/channels/email/components/add-email-v2-connection-modal/add-email-v2-connection.modal-registry";
import FBBlockedUsers from "components/modules/connections/channels/facebook/blocked-facebook-users/blocked-facebook-users.modal-registry";
import ManageTelnyxCallbackWidget from "components/modules/connections/channels/telnyx/manage-telnyx-callback-widget/manage-telnyx-callback-widget.modal-registry";
import ActionEditor from "components/modules/conversations/components/action-editor/action-editor.modal-registry";
import AutomationRuleEditor from "components/modules/conversations/components/automation-rules/automation-rule-editor/automation-rule-editor.modal-registry";
import BulkEditConversations from "components/modules/conversations/components/bulk-edit-conversations/bulk-edit-conversations.modal-registry";
import ChatViewSidebarEditor from "components/modules/conversations/components/chat-view-side-bar/chat-view-sidebar-editor.modal-registry";
import AsanaAddTask from "components/modules/conversations/components/chat-view-side-bar/components/asana-side-bar/asana-add-task.modal-registry";
import AsanaTaskBrowser from "components/modules/conversations/components/chat-view-side-bar/components/asana-side-bar/asana-task-browser.modal-registry";
import ClickUpAddTask from "components/modules/conversations/components/chat-view-side-bar/components/clickup-side-bar/clickup-add-task.modal-registry";
import ClickUpTaskBrowser from "components/modules/conversations/components/chat-view-side-bar/components/clickup-side-bar/clickup-task-browser.modal-registry";
import FreshDeskCreateTicket from "components/modules/conversations/components/chat-view-side-bar/components/fresh-desk-side-bar/fresh-desk-create-ticket.modal-registry";
import GitHubAddIssue from "components/modules/conversations/components/chat-view-side-bar/components/github-side-bar/github-add-issue.modal-registry";
import GitHubIssueBrowser from "components/modules/conversations/components/chat-view-side-bar/components/github-side-bar/github-issue-browser.modal-registry";
import HubSpotCreateContact from "components/modules/conversations/components/chat-view-side-bar/components/hubspot-side-bar/hubspot-create-contact.modal-registry";
import JiraAddTask from "components/modules/conversations/components/chat-view-side-bar/components/jira-side-bar/jira-add-issue.modal-registry";
import JiraTaskBrowser from "components/modules/conversations/components/chat-view-side-bar/components/jira-side-bar/jira-task-browser.modal-registry";
import NotionDBAddRecord from "components/modules/conversations/components/chat-view-side-bar/components/notion-side-bar/notion-db-add-record.modal-registry";
import NotionDatabaseBrowser from "components/modules/conversations/components/chat-view-side-bar/components/notion-side-bar/notion-db-browser.modal-registry";
import PipeDriveCreateDeal from "components/modules/conversations/components/chat-view-side-bar/components/pipedrive-side-bar/pipedrive-create-deal.modal-registry";
import ShopifyProductBrowser from "components/modules/conversations/components/chat-view-side-bar/components/shopify-side-bar/shopify-product-browser.modal-registry";
import ZenDeskCreateTicket from "components/modules/conversations/components/chat-view-side-bar/components/zen-desk-side-bar/zen-desk-create-ticket.modal-registry";
import ZohoDeskCreateTicket from "components/modules/conversations/components/chat-view-side-bar/components/zoho-desk-side-bar/zoho-desk-create-ticket.modal-registry";
import AddEditMacro from "components/modules/conversations/components/macros/add-edit-macro/add-edit-macro.modal-registry";
import MangeCustomInboxViews from "components/modules/conversations/components/manage-custom-views/manage-custom-views.modal-registry";
import AddEditNewsfeedType from "components/modules/conversations/components/news-feed-type/add-edit-news-feed-type.modal-registry";
import AddSendingDomain from "components/modules/conversations/components/sending-domains/add-sending-domain.modal-registry";
import AddEditSupportCaseType from "components/modules/conversations/components/support-case-types/add-edit-case-type.modal-registry";
import NewPlanComparison from "components/modules/organization-management/general/billing/new-plan-comparison.modal-registry";
import ProactiveCampaignEditor from "components/modules/proactive-campaigns/proactive-campaign-rule-editor/proactive-campaign-editor.modal-registry";
import ChatbotActivator from "components/pages/bot-kit/components/chatbot-activator-modal/chatbot-activator.modal-registry";
import MagicAssistantConnectedAPIEditor from "components/pages/bot-kit/components/magic-assistant-connected-api-editor/magic-assistant-connected-api-editor.modal-registry";
import ChatbotResponsesExplorer from "components/pages/chat-bots/chat-bot-responses-explorer/chat-bot-responses-explorer.modal-registry";
import ChatbotTemplatesExplorer from "components/pages/chat-bots/template-explorer/template-explorer.modal-registry";
import Configurations from "components/pages/configurations/configurations.modal-registry";
import SimilarContactsExplorer from "components/pages/contacts/components/similar-contacts-explorer/similar-contacts-explorer.modal-registry";
import CalLinkPicker from "components/pages/conversations/cal-event-link-picker/cal-event-link-picker.modal-registry";
import CalendlyLinkPicker from "components/pages/conversations/calendly-event-link-picker/calendly-event-link-picker.modal-default";
import CreateChatListView from "components/pages/conversations/components/add-edit-custom-inbox/create-chat-list-view.modal-registry";
import EditCustomInbox from "components/pages/conversations/components/add-edit-custom-inbox/edit-custom-inbox.modal-registry";
import AiReplyWriter from "components/pages/conversations/components/chat-input/components/chat-input-powerups/ai-reply-writer/ai-reply-writer.modal-registry";
import ConfigureChatInput from "components/pages/conversations/components/chat-input/components/chat-input-powerups/configure-chat-input/configure-chat-input.modal-registry";
import MagicAssistant from "components/pages/conversations/components/chat-input/components/chat-input-powerups/magic-assistant-modal/magic-assistant.modal-registry";
import OpenAiAssistant from "components/pages/conversations/components/chat-input/components/chat-input-powerups/open-ai-assistant-modal/open-ai-assistant.modal-registry";
import EmailTranscript from "components/pages/conversations/components/email-transcript-modal/email-transcript.modal-registry";
import EditInboxSettings from "components/pages/conversations/components/inbox-settings/edit-inbox-settings.modal-modal-registry";
import MessageScheduler from "components/pages/conversations/components/message-scheduler/message-scheduler.modal-registry";
import GoogleMeetScheduler from "components/pages/conversations/google-meet/google-meet-scheduler.modal-registry";
import AddEmailChannel from "components/pages/home/onboarding/saas-onboarding/components/add-email-channel/add-email-channel.modal-registry";
import AddLiveChatWidget from "components/pages/home/onboarding/saas-onboarding/components/add-live-chat-widget/add-live-chat-widget.modal-registry";
import EmailSeriesGeneratorWithPrompt from "components/pages/home/onboarding/saas-onboarding/components/creating-email-series/components/email-series-generator-with-prompt/email-series-generator-with-prompt.modal-registry";
import EmailSeriesTemplatePreview from "components/pages/home/onboarding/saas-onboarding/components/creating-email-series/components/email-series-template-preview/email-series-template-preview.modal-registry";
import CreateEmailSeriesWizard from "components/pages/home/onboarding/saas-onboarding/components/creating-email-series/create-email-series-wizard.modal-registry";
import TourGeneratorWithPrompt from "components/pages/home/onboarding/saas-onboarding/components/creating-tours/components/tour-generator-with-prompt/tour-generator-with-prompt.modal-registry";
import TourTemplatePreview from "components/pages/home/onboarding/saas-onboarding/components/creating-tours/components/tour-template-preview/tour-template-preview.modal-registry";
import CreatingToursWizard from "components/pages/home/onboarding/saas-onboarding/components/creating-tours/create-tours-wizard.modal-registry";
import LiveChatWidgetStrategy from "components/pages/home/onboarding/saas-onboarding/components/live-chat-widget-strategy/live-chat-widget-strategy.modal-registry";
import ProactiveCampaignsWizard from "components/pages/home/onboarding/saas-onboarding/components/proactive-campaigns-wizard/proactive-campaigns-wizard.modal-registry";
import SeedKBWizard from "components/pages/home/onboarding/saas-onboarding/components/seed-kb-flow/seed-kb-wizard.modal-registry";
import ArticleEditor from "components/pages/knowledge-base/article-editor/article-editor.modal-registry";
import KnowledgeQueryLogsViewer from "components/pages/knowledge-kit-page/knowledge-query-logs-viewer/knowledge-query-logs-viewer.modal-registry";
import MatchedKnowledgeDocumentsViewer from "components/pages/knowledge-kit-page/matched-documents-viewer/matched-documents-viewer.modal-registry";
import KnowledgePlayground from "components/pages/knowledge-kit-page/query-knowledge-playground/knowledge-playground.modal-registry";
import EmailCampaignEditor from "components/pages/sequences-page/email-campaign-editor/email-campaign-editor.modal-registry";
import AddEditCheckList from "components/pages/sequences-page/manage-checklists/components/add-edit-checklist/add-edit-checklist.modal-registry";
import CheckListStepEditor from "components/pages/sequences-page/manage-checklists/components/checklist-step-editor/checklist-step-editor.modal-registry";
import ConfigureCheckList from "components/pages/sequences-page/manage-checklists/components/configure-checklist/configure-checklist.modal-registry";
import AddEditNewsfeedItem from "components/pages/sequences-page/manage-newsfeed-items/components/add-edit-newsfeed-item/add-edit-newsfeed-item.modal-registry";
import TicketsPicker from "components/pages/tickets/components/ticket-picker-modal/ticket-picker.modal-registry";
import TicketView from "components/pages/tickets/components/ticket-view/ticket-view.modal-registry";
import CreateTracker from "components/pages/trackers/components/create-tracker-modal/create-tracker.modal-registry";

export const RegisteredModal = [
  CustomizeExperience,
  ExpressAccountSetUp,
  ConfigurationEditor,
  // Onboarding
  LiveChatWidgetStrategy,
  AddLiveChatWidget,
  AddEmailChannel,
  SeedKBWizard,
  AcademyViewer,
  CreatingToursWizard,
  TourTemplatePreview,
  TourGeneratorWithPrompt,
  CreateEmailSeriesWizard,
  EmailSeriesTemplatePreview,
  EmailSeriesGeneratorWithPrompt,
  ProactiveCampaignsWizard,
  // Add Connection
  AddNewConnection,
  AddChatWidget,
  AddFacebookConnection,
  AddInstagramConnection,
  AddWACloudConnection,
  AddWACloudNativeConnection,
  AddEmailConnection,
  AddEmailV2Connection,
  AddWhatsappConnection,
  AddTwitterConnection,
  AddTelnyxConnection,
  AddTelegramConnection,
  ConfigurePersonalMailbox,
  TroubleshootWhatsappConnection,
  FBBlockedUsers,
  ManageTelnyxCallbackWidget,
  ManageChatCampaigns,
  AddSendingDomain,
  // CRM
  ContactProfile,
  EditContact,
  BulkAddContacts,
  AddContact,
  ContactPicker,
  SimilarContactsExplorer,
  AddActivity,
  CreateOpportunity,
  AddEngagement,
  AddCompany,
  CompanyProfile,
  CompanyPicker,
  AddProduct,
  // Conversations
  NewMessageCreator,
  MessageScheduler,
  ConversationViewer,
  ConversationsPicker,
  EmailTranscript,
  CreateChatListView,
  EditCustomInbox,
  EditInboxSettings,
  BulkEditConversations,
  ChatViewSidebarEditor,
  MangeCustomInboxViews,
  ConfigureChatInput,
  // Task
  TaskViewer,
  // Workspace Management
  AddUser,
  EditUser,
  BulkAddUsers,
  BulkEditUsers,
  UserPicker,
  ManageUserGroupMembers,
  AddEditUserGroup,
  // Apps and Integrations
  AddAPIUser,
  AddWebhookApp,
  TestWebhookApp,
  // Reports
  AddCustomReport,
  // Chat Bots
  BotBuilder,
  EditChatBot,
  AddChatBot,
  ChatbotActivator,
  MessageVariantEditor,
  // Forms
  ManageForm,
  AddNewForm,
  // Sequences
  AddSequence,
  SequenceStatus,
  SequenceEditor,
  EmailBodyEditor,
  EmailCampaignEditor,
  SequenceStepEditor,
  SequenceDelayEditor,
  SimpleEmailBlockEditor,
  // Pipelines
  CreatePipelineCardModal,
  // Newsfeed Types
  AddEditNewsfeedType,
  AddEditNewsfeedItem,
  // Check Lists
  AddEditCheckList,
  CheckListStepEditor,
  ConfigureCheckList,
  // File Picker
  FileSelector,
  // Workspace Settings
  Configurations,
  SideBarContactProfileSectionConfig,
  AddEditSupportCaseType,
  ChatbotResponsesExplorer,
  ChatbotTemplatesExplorer,
  ProactiveCampaignEditor,
  // My Profile
  MyProfile,
  MyProfileX,
  ChangeMyPassword,
  ChangeMyEmail,
  MyEmailSignatures,
  AddMessagePreset,
  MyManagePresets,
  AddQuickCustomInbox,
  AllBadges,
  BadgeViewer,
  NewBadgeEarned,
  // Reminders
  ScheduleReminder,
  // Tasks
  AddTask,
  // Open AI Assistant
  OpenAiAssistant,
  ConversationSummary,
  AiReplyWriter,
  // PNR Converter
  PNRConverter,
  // Holiday Quote
  QuoteBlockEditor,
  QuoteEditor,
  QuoteHeaderEditor,
  HolidayQuoteCreator,
  // External Apps
  // Shopify
  ShopifyProductBrowser,
  // Notion
  NotionDatabaseBrowser,
  NotionDBAddRecord,
  //Asana
  AsanaTaskBrowser,
  AsanaAddTask,
  // Clickup
  ClickUpTaskBrowser,
  ClickUpAddTask,
  // Jira
  JiraTaskBrowser,
  JiraAddTask,
  // Github
  GitHubIssueBrowser,
  GitHubAddIssue,
  // CRM
  HubSpotCreateContact,
  PipeDriveCreateDeal,
  // Help Desk
  ZenDeskCreateTicket,
  FreshDeskCreateTicket,
  ZohoDeskCreateTicket,
  // Event Schedulers
  GoogleMeetScheduler,
  CalLinkPicker,
  CalendlyLinkPicker,
  // Campaigns
  AddCampaign,
  ConfigureCampaign,
  EditEmailCampaign,
  ViewCampaignReport,
  ViewCampaignReportLogs,
  // Knowledge Kit
  AddEditMagicAssistant,
  AddEditKnowledgeDocument,
  KnowledgeQueryLogsViewer,
  MagicAssistant,
  MagicAssistantConnectedAPIEditor,
  MatchedKnowledgeDocumentsViewer,
  KnowledgePlayground,
  // Knowledge Base
  ConfigureSelfServicePortal,
  AddSelfServicePortal,
  CollectionAddEdit,
  ArticleEditor,
  ArticlePreview,
  KnowledgeBaseManageCollections,
  KnowledgeBaseManageArticles,
  // Tickets
  TicketView,
  CreateTicket,
  CreateTracker,
  TicketsPicker,
  // Automation Rules
  AutomationRuleEditor,
  ActionEditor,
  // Macros
  AddEditMacro,
  // Screen Recording
  ScreenRecordingPicker,
  NewScreenRecorder,
  APIVideoPlayerPreview,
  // Tour Steps
  ConfigureTour,
  AddEditProductTour,
  TourStepEditor,
  // Sub Account Management
  CreateSubAccountWorkspace,
  // Billing
  NewPlanComparison,
  // App Modal
  CCEmbed,
  CCEmbedPreview,

  DPASigner,
  HelpCenterLegacy,
  ChangeLogsLegacy,
  FeatureRequestsLegacy,
  FeatureRequestViewerLegacy,
  EditFeatureRequestLegacy,
  RequestLegacyFeature,
  ReportBugLegacy,
  TestimonialCollection,
  DownloadApp,
  UniboxShortcutsExplorer,
  UnsplashImageSelector,
  BgPatternPicker,
  SimplePrompt,
  SimpleConfirm,
  SimpleModal,
];
