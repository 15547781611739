import { SDK } from "@sdk";

import { iCustomDomain } from "@sdk/sending-domains/sending-domains-models";
import { Store } from "redux";
import { QueryConfig } from "store/utils/query-config";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { sendingDomainsDefaultQueryConfig } from "./sending-domains-default-query";
import { SendingDomainsSlice } from "./sending-domains.slice";

export const loadAllSendingDomains =
  () => async (store: Store<iStore>, forceReload?: boolean) => {
    await loadSendingDomainsQuery(sendingDomainsDefaultQueryConfig, "all")(
      store,
      forceReload,
    );
  };

export const loadQuerySendingDomains =
  (queryConfig: QueryConfig, queryAlias?: string) =>
  async (store: Store<iStore>, forceReload?: boolean) => {
    queryAlias = queryAlias || JSON.stringify(queryConfig);
    await loadSendingDomainsQuery(queryConfig, queryAlias)(store, forceReload);
  };

export const {
  loadEntityQueries: loadSendingDomainsQuery,
  loadEntityById: loadSendingDomainById,
  loadMoreEntityQueries: loadMoreSendingDomainsQueries,
} = CreateHelpersForEntity<iStore, iCustomDomain>({
  sliceName: "sendingDomains",
  slice: SendingDomainsSlice,
  isPaginatedQueries: true,
  fetchPageSize: 50,
  loadEntityQueryProvider: (query) => SDK.sendingDomains.query(query),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.sendingDomains.getById(entityId),
});
