import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddNewConnectionModal = React.lazy(
  () => import("./add-new-connection.modal"),
);

const AddNewConnection: iModalProps<typeof AddNewConnectionModal> = {
  name: "add-new-connection",
  component: GenerateModalWithSuspense(AddNewConnectionModal),
};

export default AddNewConnection;
