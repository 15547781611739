import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const SequenceStatusModal = React.lazy(() => import("./sequence-status.modal"));

const SequenceStatus: iModalProps<
  typeof SequenceStatusModal,
  { sequenceId: string }
> = {
  name: "sequence-status",
  component: GenerateModalWithSuspense(SequenceStatusModal),
};

export default SequenceStatus;
