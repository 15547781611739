import { iArticle } from "@sdk/knowledge-base/knowledge-base.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { ccArticleDefaultQueryConfig } from "./cc-article-default-query";

export const {
  slice: CCArticlesSlice,
  reducers: CCArticlesSliceReducer,
  actions: {
    setEntityQueryResults: setCCArticlesQueryResults,
    setEntity: setCCArticle,
    setEntities: setCCArticles,
    patchEntity: patchCCArticle,
    addNewEntity: addCCNewArticle,
    setEntityFetchingStatus: setCCArticleFetchingStatus,
    setEntitiesFetchingStatus: setCCArticlesFetchingStatus,
    setEntityFailedStatus: setCCArticleFailedStatus,
    setEntityQueryError: setArticlesQueryError,
    resetQuery: resetCCArticlesQuery
  }
} = CreateEntitySlice<iArticle>({
  sliceName: "ccArticles",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...ccArticleDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
