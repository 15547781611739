import { useEffect } from "react";

export interface WindowEvent {
  data: any;
}

export interface ClickConnectorEvent {
  type: string;
  data: any;
}

function stringToJson(str: string) {
  let obj;
  try {
    obj = JSON.parse(str);
  } catch (e) {
    return false;
  }
  return obj;
}

const ccEventListener = (onInit: () => any) => {
  const CC_EVENT_METHOD = (window as any).addEventListener
    ? "addEventListener"
    : "attachEvent";
  const CC_EVENT_LISTENER = window[CC_EVENT_METHOD as any] as any;
  const CC_EVENT_TYPE =
    CC_EVENT_METHOD === "attachEvent" ? "onmessage" : "message";

  const listener = function(event: WindowEvent) {
    //   if (isWidgetDestroyed) {
    //     return;
    //   }
    const Event: ClickConnectorEvent = stringToJson(event.data);
    // console.log("Event Widget", Event);

    if (!Event) {
      return;
    }
    switch (Event.type) {
      case "INIT": {
        console.log("Event: Init the widget");
        onInit();
        return;
      }
      default:
        return;
    }
  };

  CC_EVENT_LISTENER(CC_EVENT_TYPE, listener, false);

  return () => {
    const CC_EVENT_METHOD = (window as any).addEventListener
      ? "removeEventListener"
      : "detachEvent";
    const CC_EVENT_REMOVER = window[CC_EVENT_METHOD as any] as any;
    CC_EVENT_REMOVER(CC_EVENT_TYPE, listener, false);
  };
};

export const useCCWidgetEvents = ({ onInit }: { onInit: () => any }) => {
  useEffect(() => {
    const cancelSub = ccEventListener(onInit);
    return () => {
      cancelSub();
    };
  }, [onInit]);
};
