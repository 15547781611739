import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const CCEmbedModal = React.lazy(() => import("./cc-embed.modal"));

const CCEmbed: iModalProps<
  typeof CCEmbedModal,
  {
    type: "KB" | "ROAD_MAP" | "FEATURE_REQUEST" | "BUG";
    collectionId?: string;
    articleId?: string;
  }
> = {
  name: "cc-embed",
  component: GenerateModalWithSuspense(CCEmbedModal),
};

export default CCEmbed;
