import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ChangeMyPasswordModal = React.lazy(
  () => import("./change-password.modal"),
);

const ChangeMyPassword: iModalProps<typeof ChangeMyPasswordModal, {}> = {
  name: "change-password",
  component: GenerateModalWithSuspense(ChangeMyPasswordModal),
};

export default ChangeMyPassword;
