import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { useSDK } from "@sdk";
import {
  Audio,
  TelnyxRTCContext,
  TelnyxRTCProvider,
} from "@telnyx/react-client";
import { IWebRTCCall } from "@telnyx/webrtc/lib/src/Modules/Verto/webrtc/interfaces";
import { ICall, INotification, TelnyxRTC } from "@telnyx/webrtc/lib/src/index";
import { Button, Space, Tabs } from "antd";
import { EditableTextArea } from "components/common/editable-text/editable-text";
import { TeamStatusWidget } from "components/modules/user-management/users/components/team-status-widget/team-status-widget";
import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllActivitiesOfContact } from "store/modules/activities/activities.helpers";
import { selectActivityContactId } from "store/modules/activities/activities.selectors";
import {
  loadAllCallLogs,
  loadCallLogOfContact,
} from "store/modules/call-logs/call-logs.helpers";
import {
  selectAllCallLogs,
  selectCallLogsByContactId,
} from "store/modules/call-logs/call-logs.selectors";
import { loadAllConnections } from "store/modules/connections/connections.helpers";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { setConversations } from "store/modules/conversations/conversations.slice";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { UserTracker } from "user-tracker";
import { useCallContact } from "../../helpers/use-call-contact";
import { Dialer } from "../dialer/dialer";
import { TelnyxKeyPad } from "../dialer/telnyx-key-pad";
import { SoftPhoneContactCard } from "../soft-phone-contact-card";
import { TelnyxPhoneLogs } from "../telnyx-phone-logs";
import {
  TelnyxAudioInDeviceSelector,
  TelnyxAudioOutDeviceSelector,
  TelnyxMuteButton,
} from "./call-components/telnyx-call-components";
import { TelnyxCallContext } from "./telnyx-call-context";
import "./telnyx-phone.scss";

// Web RTC Interfaces
// https://github.com/team-telnyx/webrtc/blob/main/packages/js/docs/ts/classes/Call.md#state
// https://github.com/team-telnyx/webrtc-examples/tree/main/js/react-audio-widgets

export const TelnyxPhone = memo(() => {
  const { isClientReady, notification, logs } = useContext(TelnyxCallContext);
  const activeCall = notification && (notification.call as ICall & IWebRTCCall);

  const isDevMode = useSelector(selectIsAdvancedMode);

  const notificationType = notification && notification.type;

  const [mobile, setMobile] = useState("");

  const [activeTab, setActiveTab] = useState("DIALER");

  if (!isClientReady) {
    return (
      <div className="flex flex-col h-full w-full justify-center items-center">
        <div className="name text-2xl">Connecting...</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full w-full phone-container">
      <Tabs
        defaultActiveKey="DIALER"
        className="flex-1"
        activeKey={activeTab}
        onChange={setActiveTab}
      >
        <Tabs.TabPane
          tab={
            <div className="font-bold">
              <i className="ri-phone-line"></i> Phone
            </div>
          }
          key="DIALER"
          className="h-full"
        >
          {(!activeCall ||
            ["hangup", "purge", "destroy"].includes(activeCall.state)) && (
            // <CallInProgress call={activeCall as any} />
            <Dialer />
          )}

          {activeCall &&
            ["new", "trying", "requesting", "early", "recovering"].includes(
              activeCall.state,
            ) && <OutgoingCall call={activeCall} />}

          {activeCall &&
            ["ringing", "answering"].includes(activeCall.state) && (
              <IncomingCall call={activeCall} />
            )}
          {activeCall && ["active", "held"].includes(activeCall.state) && (
            <CallInProgress call={activeCall} />
          )}
        </Tabs.TabPane>
        {isDevMode && (
          <Tabs.TabPane
            tab={
              <div className="font-bold">
                <i className="ri-file-paper-line"></i> Logs
              </div>
            }
            key="LOGS"
          >
            <div
              className="logs-container overflow-auto w-full"
              style={{ height: 430 }}
            >
              {activeCall && <div>{activeCall.state}</div>}
              {notificationType && <div>{notificationType}</div>}
              {activeCall &&
                activeCall.state === "ringing" &&
                "You have an incoming call."}

              {logs.map((item, index) => (
                <div key={index.toString()}>{item}</div>
              ))}
            </div>
          </Tabs.TabPane>
        )}

        <Tabs.TabPane
          tab={
            <div className="font-bold">
              <i className="ri-file-paper-line"></i> Call Log
            </div>
          }
          key="PHONE_LOGS"
        >
          <TelnyxPhoneLogs onSwitchToDialer={() => setActiveTab("DIALER")} />
        </Tabs.TabPane>
      </Tabs>
      {activeTab === "DIALER" &&
        !(activeCall && ["active", "held"].includes(activeCall.state)) && (
          <div className="header">
            <TeamStatusWidget widgetStyle={"PHONE"} />
          </div>
        )}
    </div>
  );
});

export const TelnyxCallContextContainer = ({ children }) => {
  const client: TelnyxRTC | null = useContext(TelnyxRTCContext);
  const [notification, setNotification] = useState(
    undefined as undefined | INotification,
  );
  const activeCall = notification && (notification.call as ICall & IWebRTCCall);

  const [logs, setLogs] = useState([] as string[]);
  const [isClientReady, setClientReady] = useState(false);

  useEffect(() => {
    const onReady = () => {
      // console.log("client ready");
      setClientReady(true);
      setLogs((logs) => [...logs, "Client Ready"]);
    };
    const onNotification = (notification) => {
      // console.log("received notification:", notification);
      if (notification.type === "callUpdate") {
        console.log(notification.call);

        // Do something with the call and update UI accordingly
      } else if (notification.type === "userMediaError") {
        console.log(notification.error);

        // Handle the error and update UI accordingly
      }
      setLogs((logs) => [...logs, notification.type]);
      setNotification(notification);
    };
    const onError = (e) => {
      console.log("Error", e);
      setLogs((logs) => [...logs, "Error"]);
    };
    const onSocketError = (e) => {
      console.log("onSocketError", e);
      setLogs((logs) => [...logs, "onSocketError"]);
    };
    const onSocketClose = (e) => {
      console.log("onSocketClose", e);
      setLogs((logs) => [...logs, "onSocketClose"]);
    };

    if (client) {
      client.on("telnyx.ready", onReady);
      client.on("telnyx.notification", onNotification);
      client.on("telnyx.error", onError);
      client.on("telnyx.socket.error", onSocketError);
      client.on("telnyx.socket.close", onSocketClose);
      return () => {
        client.off("telnyx.ready", onReady);
        client.off("telnyx.notification", onNotification);
        client.off("telnyx.error", onError);
        client.off("telnyx.socket.error", onSocketError);
        client.off("telnyx.socket.close", onSocketClose);
      };
    }
  }, [client]);

  useEffect(() => {
    if (activeCall?.state === "destroy") {
      (document.getElementById("_ringtone") as any)?.pause();
      (document.getElementById("_ringback") as any)?.pause();
    }
  }, [activeCall?.state]);

  return (
    <TelnyxCallContext.Provider
      value={{
        notification,
        isClientReady,
        logs,
      }}
    >
      {children}
      <Audio stream={activeCall && activeCall.remoteStream} />
    </TelnyxCallContext.Provider>
  );
};

export const TelnyxPhoneContainer = React.memo(({ children }) => {
  const options = {
    ringtoneFile: "/assets/sounds/incoming_call.mp3",
    ringbackFile: "/assets/sounds/phone-ringing.mp3",
  };
  const currentUser = useSelector(selectCurrentUser);

  const sipCredential = currentUser?.data?.sipUser;
  const telnyxCredentials = useMemo(() => {
    return {
      login: sipCredential?.username,
      password: sipCredential?.password,
    };
  }, [sipCredential?.password, sipCredential?.username]);

  const { state: connections, retry: reload } = useQueryWithStore(
    selectAllConnections,
    loadAllConnections,
  );

  const telephoneConnection = useMemo(() => {
    return connections.find((item) => item.type === "TELNYX");
  }, [connections]);

  if (!(telephoneConnection && sipCredential)) {
    return <div>{children}</div>;
  }

  return (
    <div>
      <TelnyxRTCProvider credential={telnyxCredentials} options={options}>
        <TelnyxCallContextContainer>{children}</TelnyxCallContextContainer>
      </TelnyxRTCProvider>
    </div>
  );
});

export const OutgoingCall = ({ call }: { call: ICall & IWebRTCCall }) => {
  const { contact: matchedContact, phoneNumber: destinationNumber } =
    useCallContact(call);

  if (["new", "trying", "requesting", "recovering"].includes(call.state)) {
    return (
      <div className="dialer rounded-md w-full h-full flex flex-col justify-center items-center">
        <div className="name text-2xl">Connecting...</div>
      </div>
    );
  }

  return (
    <div className="dialer rounded-md w-full h-full flex flex-col">
      {call.state === "early"}
      <div className="name text-2xl text-center mb-8">Ringing...</div>
      <SoftPhoneContactCard
        contact={matchedContact}
        phoneNumber={destinationNumber}
      />
      <div className="flex  flex-1 flex-row justify-center items-center mt-8">
        <Space>
          <div className="call-button flex flex-row justify-center items-center p-4 transform rotate-90">
            <Button
              type="primary"
              shape="circle"
              icon={
                <i className="ri-phone-fill pr-0 text-2xl transform rotate-45"></i>
              }
              className="text-2xl bg-red-600 border-0"
              style={{ width: 60, height: 60 }}
              onClick={() => {
                call.hangup({}, true);
                UserTracker.track("SIP_PHONE - Hangup", {});
              }}
            />
          </div>
        </Space>
      </div>
      <div className="notes"></div>
    </div>
  );
};

export const IncomingCall = ({ call }: { call: ICall & IWebRTCCall }) => {
  const { contact: matchedContact, phoneNumber } = useCallContact(call);

  const {
    state: allRecentCallLogs,
    retry: reload,
    isLoading,
  } = useQueryWithStore(selectAllCallLogs, loadAllCallLogs(), []);

  const currentUser = useSelector(selectCurrentUser);

  const matchedCallLog = useMemo(() => {
    const callLog = allRecentCallLogs.find(
      (item) =>
        item.sipConnectionId === currentUser.data.sipUser.password &&
        item.state === "LIVE",
    );
    return callLog;
  }, [allRecentCallLogs, currentUser?.data?.sipUser?.password]);

  return (
    <div className="dialer rounded-md w-full h-full flex flex-col">
      <SoftPhoneContactCard
        contact={matchedContact}
        phoneNumber={phoneNumber}
      />

      {/* Todo: Call Back Request */}
      <div className="flex flex-1 flex-row justify-center items-center mt-8">
        <Space>
          <div className="call-button flex flex-row justify-center items-center p-4 transform rotate-90">
            <Button
              type="primary"
              shape="circle"
              icon={
                <i className="ri-phone-fill pr-0 text-2xl transform rotate-45"></i>
              }
              className="text-2xl bg-red-600 border-0"
              style={{ width: 60, height: 60 }}
              onClick={() => {
                call.hangup({}, true);
                UserTracker.track("SIP_PHONE - Hangup", {});
              }}
            />
          </div>
          <div className="call-button flex flex-row justify-center items-center p-4">
            <Button
              type="primary"
              shape="circle"
              icon={<i className="ri-phone-fill pr-0 text-2xl"></i>}
              className="text-2xl bg-green-600 border-0"
              style={{ width: 60, height: 60 }}
              onClick={() => {
                call.answer();
                UserTracker.track("SIP_PHONE - Answer", {});
              }}
            />
          </div>
        </Space>
      </div>
      <div className="notes"></div>
    </div>
  );
};

export const CallInProgress = ({ call }: { call?: ICall & IWebRTCCall }) => {
  // Todo:
  const { contact: matchedContact, phoneNumber } = useCallContact(call);
  const client: TelnyxRTC | null = useContext(TelnyxRTCContext);
  const [isKeypadVisible, setKeypadVisibility] = useState(false);

  const {
    state: allRecentCallLogs,
    retry: reload,
    isLoading,
  } = useQueryWithStore(selectAllCallLogs, loadAllCallLogs(), []);

  const currentUser = useSelector(selectCurrentUser);

  const matchedCallLog = useMemo(() => {
    const callLog = allRecentCallLogs.find(
      (item) =>
        item.sipConnectionId === currentUser.data.sipUser.password &&
        item.state === "LIVE",
    );
    return callLog;
  }, [allRecentCallLogs, currentUser?.data?.sipUser?.password]);

  // const { doAction: updateNotes } = useSDKActionWithDeps(
  //   () => ({
  //     action: (SDK) => (notes: string) =>
  //       SDK.callLogs.editById(matchedCallLog?.id!, { notes }),
  //     // successMessage: "Done",
  //     failureMessage: "Something went wrong",
  //     actionDependencies: [matchedCallLog?.id!],
  //   }),
  //   [matchedCallLog?.id!]
  // );

  const { changePanelState } = useModalPanels();

  const { state: contactActivities, isLoading: isActivitiesLoading } =
    useQueryWithStore(
      selectActivityContactId(matchedContact?.id!),
      loadAllActivitiesOfContact(matchedContact?.id!),
      [matchedContact?.id],
      !matchedContact?.id,
    );

  const dispatch = useDispatch();

  const conversationSearchResult = useSDK(
    async (SDK) => {
      const results = await SDK.queryConversations({
        query: { contactId: matchedContact?.id },
        options: {},
      });
      dispatch(setConversations(results.docs));
      return results;
    },
    [matchedContact?.id],
    !matchedContact?.id,
    {
      data: {
        docs: [],
      },
      isLoading: false,
      error: false,
    },
  );

  const { state: callLogsOfContact } = useQueryWithStore(
    selectCallLogsByContactId(matchedContact?.id!),
    loadCallLogOfContact(matchedContact?.id!),
    [matchedContact?.id],
    !matchedContact?.id,
  );

  return (
    <div className="dialer rounded-md w-full h-full flex flex-col">
      {/* <div className="bg-gray-100 p-2 font-bold mb-2 rounded-md">
        Call in Progress
      </div> */}
      <div className="flex flex-row items-center justify-around gap-4 bg-gray-100 mb-2 rounded-md">
        <TelnyxAudioOutDeviceSelector call={call} />
        <TelnyxAudioInDeviceSelector call={call} />
      </div>

      <SoftPhoneContactCard
        contact={matchedContact}
        phoneNumber={phoneNumber}
        size={40}
      />
      {/* Call Back Request */}
      {/* {matchedCallLog?.callbackRequestId && (
        <div>Callback Request From: {callbackRequest.phoneNumber}</div>
      )} */}

      {matchedContact && (
        <>
          <div className="flex flex-row justify-evenly items-center">
            <Button type="text" icon={<i className="ri-message-2-fill"></i>}>
              {conversationSearchResult?.data?.totalDocs || 0} Chats
            </Button>
            <Button type="text" icon={<i className="ri-phone-fill pr-0"></i>}>
              {callLogsOfContact?.totalItems || 0} Calls
            </Button>
            <Button type="text" icon={<i className="ri-run-line"></i>}>
              {contactActivities.totalItems || 0} Activities
            </Button>
          </div>
        </>
      )}

      <div className="p-4">
        <div className="font-bold">Call Notes</div>
        <EditableTextArea
          value={""}
          placeholder="Notes..."
          onSave={(text) => {}}
        />
      </div>

      <div className="flex flex-row justify-center items-center mt-2">
        <Space>
          <TelnyxMuteButton call={call} />
          <Button
            type="text"
            shape="circle"
            icon={<i className="ri-keyboard-line"></i>}
            className=""
            onClick={() => setKeypadVisibility(!isKeypadVisible)}
          />
        </Space>
      </div>

      <div className="flex flex-row justify-center items-center">
        <Space>
          {call && (
            <div className="call-button flex flex-row justify-center items-center p-4">
              {call?.state === "held" && (
                <Button
                  type="primary"
                  shape="circle"
                  icon={<i className="ri-play-fill pr-0 text-2xl"></i>}
                  className="text-2xl bg-green-600 border-0"
                  style={{ width: 60, height: 60 }}
                  onClick={() => {
                    call.toggleHold();
                    UserTracker.track("SIP_PHONE - Toggle Hold", {});
                  }}
                />
              )}
              {call?.state === "active" && (
                <Button
                  type="primary"
                  shape="circle"
                  icon={<i className="ri-pause-line pr-0 text-2xl"></i>}
                  className="text-2xl bg-yellow-600 border-0"
                  style={{ width: 60, height: 60 }}
                  onClick={() => {
                    call?.toggleHold();
                    UserTracker.track("SIP_PHONE - Toggle Hold", {});
                  }}
                />
              )}
            </div>
          )}

          <div className="call-button flex flex-row justify-center items-center p-4 transform rotate-90">
            <Button
              type="primary"
              shape="circle"
              icon={
                <i className="ri-phone-fill pr-0 text-2xl transform rotate-45"></i>
              }
              className="text-2xl bg-red-600 border-0"
              style={{ width: 60, height: 60 }}
              onClick={() => {
                call?.hangup({}, true);
                UserTracker.track("SIP_PHONE - Hangup", {});
              }}
            />
          </div>
        </Space>
      </div>

      <div
        className="keypad-container bg-white dark:bg-black absolute w-full left-0 bottom-0 rounded-lg overflow-hidden transform"
        style={{
          height: isKeypadVisible ? 295 : 0,
          boxShadow: "0 0 20px #0000001c",
          paddingTop: isKeypadVisible ? 20 : 0,
          paddingBottom: isKeypadVisible ? 20 : 0,
        }}
      >
        <Button
          type="link"
          onClick={() => setKeypadVisibility(!isKeypadVisible)}
          block
        >
          {!isKeypadVisible ? (
            <i className="ri-arrow-up-s-line text-2xl"></i>
          ) : (
            <i className="ri-arrow-down-s-line text-2xl"></i>
          )}
        </Button>
        <TelnyxKeyPad
          onKeyPress={(key) => {
            call?.dtmf(key);
          }}
        />
      </div>
    </div>
  );
};
