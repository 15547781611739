import { Divider } from "antd";
import { SectionHeader } from "components/common/section-header";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { ManageMacros } from "components/modules/conversations/components/macros/manage-macros";
import { PresetMessagesX } from "components/modules/organization-management/conversations/preset-messages-x/preset-messages-x";

export const ConfigureMacrosAndPresets = () => {
  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin p-8 overflow-x-hidden">
      <ManageMacros />

      <div className="my-16"></div>

      <SectionHeader
        title="Manage Presets"
        icon={<i className="ri-booklet-line"></i>}
        description="Create & Manage presets of commonly used messages"
      />

      <PresetMessagesX />

      <Divider />
    </StyledScrollArea>
  );
};
