import { iCCAutomationRule } from "@sdk/user-management/user-management.models";

export const AutomationRuleTemplates: (iCCAutomationRule & {
  description: string;
})[] = [
  {
    id: "9af59670",
    label: "Auto-Close Conversations",
    description:
      "Automatically closes conversations after a period of inactivity",
    trigger: "CONVERSATION_INACTIVE",
    triggerConfig: {},
    conditions: [],
    actions: [
      {
        action: "END_CONVERSATION",
        id: "5efc4703",
        data: {
          closeChatConfig: {
            message: "Conversation has been closed due to inactivity",
          },
        },
      },
    ],
  },
  {
    id: "99ec2dc3",
    label: "Auto Extract Contact Information",
    description:
      "Scan all incoming message and automatically extract emails and mobile numbers and save it in the CRM",
    trigger: "MESSAGE_NEW",
    conditions: [],
    actions: [
      {
        action: "EXTRACT_CONTACT_DATA",
        id: "9bdd9f1d",
      },
    ],
  },
  {
    id: "d171ddeb",
    label: "Auto Flag for Followups",
    description: "Automatically flag conversations that requires followups",
    trigger: "CONVERSATION_NEW",
    conditions: [],
    actions: [
      {
        action: "ACTIVATE_AUTO_FOLLOWUP_FLAGGING",
        data: {
          autoFollowupConfig: {
            template: "3_FOLLOWUPS_7_DAYS",
          },
        },
        id: "6f8503f6",
      },
    ],
  },
  {
    id: "bcde4cbc",
    label: "Auto Email Chat Transcripts",
    description:
      "Email the transcript to your emails when a conversation is marked as closed",
    trigger: "CONVERSATION_CLOSED",
    conditions: [],
    actions: [
      {
        action: "EMAIL_TRANSCRIPT",
        id: "1a6705f3",
      },
    ],
  },
  {
    id: "36d77c94",
    label: "Auto Archive Unsubscribes",
    description:
      "Email the transcript to your emails when a conversation is marked as closedUnclutter your inboxes by archiving conversations with 'Unsubscribe' or 'Pause Question' Signals",
    trigger: "MESSAGE_NEW",
    conditions: [
      {
        field: "message_text",
        operator: "contains",
        value: "Unsubscribe",
      },
    ],
    actions: [
      {
        action: "END_CONVERSATION",
        data: {
          closeChatConfig: {
            message: "Conversation has been closed due to unsubscribe keyword",
          },
        },
        id: "df764927",
      },
    ],
  },
] as any;
