import { SocketConnector } from "@sdk/@libs/socket-connector";
import { iConversation } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { message } from "antd";
import { useCallback, useEffect } from "react";
import { useStateWithGetter } from "utils/hooks/use-state-with-getter";

export const useEmailTester = (
  connectionId: string,
  onIncomingEmailTestSuccessful?: () => any,
  onOutgoingEmailTestSuccessful?: () => any,
) => {
  const [
    isTestingIncomingEmail,
    setIsTestingIncomingEmail,
    getIncomingEmailState,
  ] = useStateWithGetter(false);
  const [
    isTestingOutgoingEmail,
    setIsTestingOutgoingEmail,
    getOutgoingEmailState,
  ] = useStateWithGetter(false);
  const { doAction: _testIncomingEmail } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () => SDK.connections.testIncomingEmail(connectionId!),
      // successMessage: "Email has been sent.",
      failureMessage: "Something went wrong",
    }),
    [connectionId],
  );

  const { doAction: _testOutgoingEmail } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () => SDK.connections.testOutgoingEmail(connectionId!),
      // successMessage:
      //   "Email has been sent. You should be getting a test email to your email address (user)",
      failureMessage: "Something went wrong",
    }),
    [connectionId],
  );

  const testIncomingEmail = useCallback(async () => {
    try {
      setIsTestingIncomingEmail(true);
      await _testIncomingEmail();
      setTimeout(() => {
        if (getIncomingEmailState()) {
          message.warning(
            `Something's not correct. Please check your configurations`,
          );
          setIsTestingIncomingEmail(false);
        }
      }, 10000);
    } catch (e) {
      setIsTestingIncomingEmail(false);
    }
  }, [_testIncomingEmail, getIncomingEmailState, setIsTestingIncomingEmail]);

  const testOutgoingEmail = useCallback(async () => {
    try {
      setIsTestingOutgoingEmail(true);
      await _testOutgoingEmail();
      setTimeout(() => {
        if (getOutgoingEmailState()) {
          message.warning(
            `Something's not correct. Please check your configurations`,
          );
          setIsTestingOutgoingEmail(false);
        }
        setIsTestingOutgoingEmail(false);
      }, 10000);
    } catch (e) {
      setIsTestingOutgoingEmail(false);
    }
  }, [_testOutgoingEmail, getOutgoingEmailState, setIsTestingOutgoingEmail]);

  useEffect(() => {
    const listener = (data: {
      name: string;
      data: {
        connectionId: string;
        mode: "INCOMING_EMAIL_TEST" | "INCOMING_EMAIL_TEST";
      };
    }) => {
      console.log("Received Special Socket Event", data);
      if (data.name === "TEST_EMAIL") {
        if (data.data.mode === "INCOMING_EMAIL_TEST") {
          setIsTestingIncomingEmail(false);
          if (onIncomingEmailTestSuccessful) {
            onIncomingEmailTestSuccessful();
          } else {
            message.success("Successfully received test email");
          }
        } else {
          setIsTestingOutgoingEmail(false);
          if (onOutgoingEmailTestSuccessful) {
            onOutgoingEmailTestSuccessful();
          } else {
            message.success("Successfully received test email");
          }
          message.success("Email has been sent successfully");
        }
      }
    };
    if (connectionId && SocketConnector.socket) {
      SocketConnector.socket.on("SPECIAL_EVENTS", listener);
    }

    return () => {
      if (SocketConnector.socket) {
        SocketConnector.socket.off("SPECIAL_EVENTS", listener);
      }
    };
  }, [
    connectionId,
    onIncomingEmailTestSuccessful,
    onOutgoingEmailTestSuccessful,
    setIsTestingIncomingEmail,
    setIsTestingOutgoingEmail,
  ]);

  useEffect(() => {
    const listener = (data: {
      data: iConversation;
      entityType: "CONVERSATION";
      type: "NEW";
    }) => {
      console.log("Received SYNC Event", data);
      if (
        data.type === "NEW" &&
        data.entityType === "CONVERSATION" &&
        data.data?.data?.subject === "Test Email"
      ) {
        message.success("Successfully received test email");
      }
    };
    if (connectionId && SocketConnector.socket) {
      SocketConnector.socket.on("SYNC", listener);
    }

    return () => {
      if (SocketConnector.socket) {
        SocketConnector.socket.off("SYNC", listener);
      }
    };
  }, [connectionId]);
  return {
    testIncomingEmail,
    isTestingIncomingEmail,
    testOutgoingEmail,
    isTestingOutgoingEmail,
  };
};
