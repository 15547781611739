import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ConfigureCheckListModal = React.lazy(
  () => import("./configure-checklist.modal"),
);

const ConfigureCheckList: iModalProps<
  typeof ConfigureCheckListModal,
  {
    checkListId: string;
  }
> = {
  name: "configure-checkList",
  component: GenerateModalWithSuspense(ConfigureCheckListModal),
};

export default ConfigureCheckList;
