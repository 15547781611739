import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const MagicAssistantModal = React.lazy(() => import("./magic-assistant.modal"));

const MagicAssistant: iModalProps<
  typeof MagicAssistantModal,
  { query?: string; onReplaceText: (content: string) => any }
> = {
  name: "magic-assistant",
  component: GenerateModalWithSuspense(MagicAssistantModal),
};

export default MagicAssistant;
