import { iOpportunity } from "@sdk/crm/crm.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { opportunitiesDefaultQueryConfig } from "./opportunities-default-query";

export const {
  slice: OpportunitiesSlice,
  reducers: OpportunitiesSliceReducer,
  actions: {
    setEntityQueryResults: setOpportunitiesQueryResults,
    setEntity: setOpportunity,
    setEntities: setOpportunities,
    patchEntity: patchOpportunity,
    addNewEntity: addNewOpportunity,
    setEntityFetchingStatus: setOpportunityFetchingStatus,
    setEntitiesFetchingStatus: setOpportunitiesFetchingStatus,
    setEntityFailedStatus: setOpportunityFailedStatus,
    setEntityQueryError: setOpportunitiesQueryError,
    resetQuery: resetOpportunitiesQuery,
    setQueryList: setOpportunitiesQueryList
  }
} = CreateEntitySlice<iOpportunity>({
  sliceName: "opportunities",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...opportunitiesDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
