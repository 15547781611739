import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iUserPresenceSlice } from "./user-presence.model";

export const userPresenceStore_initialState: iUserPresenceSlice = {
  users: {}
};

const UserPresenceSlice = createSlice({
  name: "userPresence",
  initialState: userPresenceStore_initialState,
  reducers: {
    setUserPresence(
      state,
      {
        payload: { userId, isOnline }
      }: PayloadAction<{ userId: string; isOnline: boolean }>
    ) {
      state.users[userId] = {
        isOnline,
        lastUpdated: new Date().getTime()
      };
    },
    setUserPresences(
      state,
      action: PayloadAction<{ userId: string; isOnline: boolean }[]>
    ) {
      for (const { userId, isOnline } of action.payload) {
        state.users[userId] = {
          isOnline,
          lastUpdated: new Date().getTime()
        };
      }
    }
  }
});

export const UserPresenceSliceReducers = UserPresenceSlice.reducer;

export const UserPresenceSliceActions = UserPresenceSlice.actions;
