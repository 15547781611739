import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import axios, { AxiosResponse } from "axios";
import { UserTracker } from "user-tracker";
import {
  iArticle,
  iArticleCollection,
  iSelfServicePortal,
} from "./knowledge-base.models";

export class KnowledgeBaseService {
  constructor(protected config: { basePath: string; token: string }) {
    this.articles.configure(config);
    this.collections.configure(config);
    this.selfServicedPortals.configure(config);
  }

  /* -------------------------------- Connection -------------------------------- */

  articles = Object.assign(
    new EntityServices<iArticle>(SDKConfig, "articles", {
      onCreate: () => {
        UserTracker.track("ARTICLES - Create", {});
      },
      onDelete: () => {
        UserTracker.track("ARTICLES - Delete", {});
      },
    }),
    {
      // getShortUrlAnalytics: async (
      //   shortUrlId: string,
      //   query: {
      //     startDate: number;
      //     endDate: number;
      //     filters: any;
      //     groupBy?: string;
      //     period?: AggregatePeriodTypes;
      //   }
      // ) => {
      //   const results: AxiosResponse<any> = await axios.post(
      //     this.config.basePath + `/short-urls/${shortUrlId}/analytics`,
      //     query.filters,
      //     {
      //       headers: {
      //         Authorization: this.config.token
      //       },
      //       params: {
      //         ..._.omit(query, "filters")
      //       }
      //     }
      //   );
      //   return results.data;
      // }
    },
  );

  collections = Object.assign(
    new EntityServices<iArticleCollection>(SDKConfig, "article-collections", {
      onCreate: () => {
        UserTracker.track("KB_COLLECTIONS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("KB_COLLECTIONS - Delete", {});
      },
    }),
    {},
  );

  selfServicedPortals = Object.assign(
    new EntityServices<iSelfServicePortal>(SDKConfig, "self-service-portals", {
      onCreate: () => {
        UserTracker.track("SELF_SERVICE_PORTALS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("SELF_SERVICE_PORTALS - Delete", {});
      },
    }),
    {
      checkSubDomainAvailability: async (portalId: string, domain: string) => {
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath +
            `/self-service-portals/${portalId}/check-sub-domain-availability`,
          { domain },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      setCustomDomain: async (portalId: string, domain: string) => {
        UserTracker.track("SELF_SERVICE_PORTALS - Set Custom Domain", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath +
            `/self-service-portals/${portalId}/custom-domain`,
          { domain },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      removeCustomDomain: async (portalId: string) => {
        UserTracker.track("SELF_SERVICE_PORTALS - Remove Custom Domain", {});
        const results: AxiosResponse<any> = await axios.delete(
          this.config.basePath +
            `/self-service-portals/${portalId}/custom-domain`,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      refreshValidityCustomDomain: async (portalId: string) => {
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath +
            `/self-service-portals/${portalId}/custom-domain/verify`,
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
