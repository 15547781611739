import { Form, Switch } from "antd";

export const StyledSwitch = ({
  name,
  label,
  description,
  disabled,
  defaultChecked
}: {
  name: string | string[];
  label: string;
  description?: string;
  disabled?: boolean;
  defaultChecked?: boolean;
}) => {
  return (
    <div className="flex flex-row items-center justify-between mb-3">
      <div className="">
        <div className="">{label}</div>
        {description && <div className="text-gray-600">{description}</div>}
      </div>

      <Form.Item
        name={name}
        hasFeedback={false}
        valuePropName="checked"
        style={{ marginBottom: 0, marginLeft: 5 }}
      >
        <Switch disabled={disabled} defaultChecked={defaultChecked} />
      </Form.Item>
    </div>
  );
};
