import { Button } from "antd";
import { CCPopoverV2 } from "components/common/popover/popover-v2-portal";
import { QueryTasksBadgeCount } from "components/modules/tasks/query-tasks-count-badge/query-tasks-count-badge";
import { MyTasksWidget } from "components/pages/home/components/my-tasks-widget";
import { useMemo } from "react";
import { useSelector, useStore } from "react-redux";
import { selectCurrentUserId } from "store/modules/users/users.selectors";

export const MentionsAndTasksPopover = () => {
  const store = useStore();
  const currentUserId = useSelector(selectCurrentUserId);

  const myTasksQuery = useMemo(() => {
    return JSON.stringify({
      query: {
        $or: [
          {
            assignedTo: {
              $elemMatch: {
                userId: currentUserId,
              },
            },
          },
          {
            createdBy: currentUserId,
          },
        ],
        isResolved: false,
      },
      options: {
        sortBy: ["createdAt"],
      },
    });
  }, [currentUserId]);

  return (
    <CCPopoverV2
      // openState={true}
      content={
        <div className="animate-slide-up" style={{ width: "23rem" }}>
          <MyTasksWidget className="border-0" />
        </div>
      }
      popoverId="mentions-popover"
      className="p-0"
    >
      <QueryTasksBadgeCount query={myTasksQuery}>
        <Button
          type="text"
          className="h-10"
          icon={<i className="ri-at-line text-xl"></i>}
          block
        ></Button>
      </QueryTasksBadgeCount>
    </CCPopoverV2>
  );
};
