import { iFileRecord } from "@sdk/file-management/file-management.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Tooltip } from "antd";
import { ImageX } from "components/common/image";
import { useCallback, useEffect, useState } from "react";
import classNames from "utils/classnames";
import { ImageInputWithPreviewV2 } from "../../common/image-input-with-preview/image-input-with-preview";
import {
  iImageUploadResizerOptions,
  iUploadValidation,
} from "./upload-validation-model";
import { uploadFileWithValidation } from "./upload-with-validation";

export const ImageInputWithUploader = ({
  value,
  onChange,
  type,
  entityId,
  width = 80,
  height = 80,
  allowClear,
  useFilePicker,
  filePickerPresetRenderer,
  validations,
  resizeBeforeUpload,
  customRecentUploadQuery,
  noPadding,
  pickButtonLabel,
  imageClassName,
}: {
  value?: any;
  onChange?: any;
  type: string;
  entityId: string;
  width?: number;
  height?: number;
  allowClear?: boolean;
  useFilePicker?: boolean;
  filePickerPresetRenderer?: (data: {
    onFileSelection: (file: iFileRecord) => void;
    selectedItems: string[];
  }) => JSX.Element;
  validations?: iUploadValidation[];
  resizeBeforeUpload?: iImageUploadResizerOptions;
  customRecentUploadQuery?: any;
  noPadding?: boolean;
  pickButtonLabel?: string;
  imageClassName?: string;
}) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>(value);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  useEffect(() => {
    setImageUrl(value);
  }, [value]);

  useEffect(() => {
    if (value) {
      setImageUrl(value);
    }
  }, [value]);

  const onImageUploaded = useCallback(
    async (fileUrl) => {
      if (onChange) {
        await onChange(fileUrl);
      }
    },
    [onChange],
  );

  const { doAction: uploadImage, isProcessing: isUploading } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (file) =>
          SDK.uploadFile(file, {
            type: type,
            entityId: entityId,
          })
            .then((fileRecord) => {
              setSelectedFile(undefined);
              setImageUrl(fileRecord.url);
              onImageUploaded(fileRecord.url);
            })
            .catch((e) => {
              setSelectedFile(undefined);
              setImageUrl(undefined);
              throw e;
            }),
      }),
      [entityId, onImageUploaded, type],
    );

  return (
    <div className="flex flex-row justify-start items-center">
      <ImageInputWithPreviewV2
        selectedFile={selectedFile}
        type={type}
        entityId={entityId}
        onChange={(file) => {
          setSelectedFile(file);
          uploadFileWithValidation({
            file,
            reference: {
              type: type,
              entityId: entityId,
            },
            uploadFileFunction: uploadImage,
            onValidationFailed: () => setSelectedFile(undefined),
            validations,
            resizeBeforeUpload,
          }).catch((e) => {
            // Nothing
          });
        }}
        useFilePicker={useFilePicker}
        pickButtonLabel={pickButtonLabel}
        onFileSelected={(fileRecord) => {
          // console.log("fileRecord", fileRecord);
          setImageUrl(fileRecord.url);
          onImageUploaded(fileRecord.url);
        }}
        isUploading={isUploading}
        className={classNames({
          "my-4": !noPadding,
        })}
        image={
          imageUrl ? (
            <ImageX
              src={imageUrl}
              style={{
                width: "auto",
                height: "auto",
                maxWidth: width,
                maxHeight: height,
                objectFit: "contain",
              }}
              className={classNames("rounded-lg", imageClassName)}
              wrapperClassName={classNames(
                "rounded-lg flex flex-row justify-center align-center",
                imageClassName,
              )}
            />
          ) : undefined
        }
        shape="square"
        width={width}
        height={height}
        filePickerPresetRenderer={filePickerPresetRenderer}
        validations={validations}
        resizeBeforeUpload={resizeBeforeUpload}
        customRecentUploadQuery={customRecentUploadQuery}
      />
      {allowClear && imageUrl && (
        <Tooltip title="Clear Image">
          <Button
            type="text"
            icon={<i className="ri-delete-bin-line"></i>}
            onClick={() => onChange(null)}
          ></Button>
        </Tooltip>
      )}
    </div>
  );
};
