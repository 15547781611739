import en_common from "./locales/en/common.json";
import fr_common from "./locales/fr/common.json";

export const ReactI18NextConfig = {
  resources: {
    en: { common: en_common },
    fr: { common: fr_common }
  },
  lng: "en", // if you're using a language detector, do not define the lng option
  fallbackLng: "en",
  interpolation: {
    escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  }
};
