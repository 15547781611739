import {
  createSelector,
  createSlice,
  PayloadAction,
  Slice,
  Store,
} from "@reduxjs/toolkit";
import _ from "lodash";
import memoize from "proxy-memoize";
import { PaginatedResults } from "../../@sdk/utils/paginated-results";
import { DeepAssign } from "../../utils/deep-assign";
import { iEntitySlice } from "./enitity-slice-model";
import {
  defaultQueryValue,
  ListQueryState,
  setPaginatedQueryListState,
} from "./list-query";
import { deduceListChangesOnNewEntity } from "./new-entity-analyzer";
import { QueryAliasResolver } from "./query-alias-resolver";
import { PaginatedQueryConfig, QueryConfig } from "./query-config";

export const CreateEntitySlice = <Entity extends { id?: string }>({
  sliceName,
  queryAliasResolver,
}: {
  sliceName: string;
  queryAliasResolver?: QueryAliasResolver;
}) => {
  const EntitySlice = createSlice({
    name: sliceName,
    initialState: {
      byIds: {},
      queries: {},
      itemsBeingFetched: [],
      failedItems: [],
    } as iEntitySlice<Entity>,
    reducers: {
      resetQuery(state, action: PayloadAction<string>) {
        delete state.queries[action.payload];
      },
      setQueryList(
        state,
        action: PayloadAction<{
          query: string;
          list: string[];
          totalItems?: number;
        }>,
      ) {
        if (typeof action.payload.totalItems === "number") {
          state.queries[action.payload.query].totalItems =
            action.payload.totalItems;
        }
        state.queries[action.payload.query].list = action.payload.list;
      },
      setEntityQueryResults(
        state,
        action: PayloadAction<{
          query: string;
          results: PaginatedResults<Entity>;
        }>,
      ) {
        setPaginatedQueryListState(
          state,
          action.payload.query,
          action.payload.results,
        );
      },
      setEntity(state, action: PayloadAction<Entity>) {
        if (action.payload) {
          state.byIds[action.payload.id!] = action.payload as any;
        }
      },
      setEntities(state, { payload }: PayloadAction<Entity[]>) {
        for (const entity of payload) {
          state.byIds[entity.id!] = entity as any;
        }
      },
      patchEntity(state, { payload }: PayloadAction<Entity>) {
        if (state.byIds[payload.id!]) {
          DeepAssign(state.byIds[payload.id!], payload);
        }
      },
      addNewEntity(state, action: PayloadAction<Entity>) {
        state.byIds[action.payload.id!] = action.payload as any;
        const entity = action.payload;
        const { listToAddTo, listToRemoveFrom, matchedQueries } =
          deduceListChangesOnNewEntity(entity, state, queryAliasResolver);

        const setQueryList = (
          state,
          action: PayloadAction<{ query: string; list: string[] }>,
        ) => {
          state.queries[action.payload.query].list = action.payload.list;
        };

        for (const queryConfigAlias of listToRemoveFrom) {
          const existingList = state.queries[queryConfigAlias].list;
          setQueryList(state, {
            type: "",
            payload: {
              query: queryConfigAlias,
              list: _.without(existingList, entity.id!)!,
            },
          });
        }
        // In the new list as well as the matched list, check if order is changed and re-order
        for (const queryConfig of matchedQueries) {
          const existingList = [...state.queries[queryConfig.alias].list];
          if (listToAddTo.indexOf(queryConfig.alias) > -1) {
            existingList.push(entity.id!);
          }
          const sortByOptions = (queryConfig.options.sortBy || []).reduce(
            (sortConfig, fieldString) => {
              if (fieldString?.charAt(0) === "-") {
                sortConfig.fields.push(fieldString.slice(1));
                sortConfig.order.push("desc");
              } else {
                sortConfig.fields.push(fieldString);
                sortConfig.order.push("asc");
              }
              return sortConfig;
            },
            {
              fields: [] as string[],
              order: [] as ("asc" | "desc")[],
            },
          );

          const sortedList = _.orderBy(
            existingList.map((id) => state.byIds[id]),
            sortByOptions.fields,
            sortByOptions.order,
          );
          setQueryList(state, {
            type: "",
            payload: {
              query: queryConfig.alias,
              list: sortedList.filter((e) => e).map((e) => e.id!),
            },
          });
        }
      },
      removeEntity(state, action: PayloadAction<string>) {
        const entityId = action.payload;
        delete state.byIds[entityId];

        const allLists = Object.keys(state.queries);
        for (const list of allLists) {
          if (state.queries[list].list.includes(entityId)) {
            state.queries[list].list = _.without(
              state.queries[list].list,
              entityId,
            );
          }
        }
      },
      setEntityFetchingStatus(
        state,
        {
          payload: { id, isFetching },
        }: PayloadAction<{ id: string; isFetching: boolean }>,
      ) {
        if (isFetching && state.itemsBeingFetched.indexOf(id) > -1) {
          state.itemsBeingFetched.push(id);
        }
        if (!isFetching) {
          _.pull(state.itemsBeingFetched, id);
        }
      },
      setEntitiesFetchingStatus(
        state,
        {
          payload: { ids, isFetching },
        }: PayloadAction<{ ids: string[]; isFetching: boolean }>,
      ) {
        if (isFetching) {
          state.itemsBeingFetched = _.uniq([
            ...state.itemsBeingFetched,
            ...ids,
          ]);
        }
        if (!isFetching) {
          _.pull(state.itemsBeingFetched, ...ids);
        }
      },
      setEntityFailedStatus(
        state,
        {
          payload: { id, isFailed },
        }: PayloadAction<{ id: string; isFailed: boolean }>,
      ) {
        if (isFailed && state.failedItems.indexOf(id) > -1) {
          state.failedItems.push(id);
        }
        if (!isFailed) {
          _.pull(state.failedItems, id);
        }
      },
      setEntityQueryError(state, action: PayloadAction<string>) {
        if (!state.queries[action.payload]) {
          state.queries[action.payload] = {
            lastFetched: Date.now(),
            isLoading: false,
            hasError: true,
            list: [],
          };
        } else {
          Object.assign(state.queries[action.payload], {
            lastFetched: Date.now(),
            isLoading: false,
            hasError: true,
          });
        }
      },
      setEntityQueryLoading(state, action: PayloadAction<string>) {
        if (!state.queries[action.payload]) {
          state.queries[action.payload] = {
            lastFetched: 0,
            isLoading: true,
            hasError: false,
            list: [],
          };
        } else {
          Object.assign(state.queries[action.payload], {
            isLoading: true,
          });
        }
      },
    },
  });

  return {
    slice: EntitySlice,
    reducers: EntitySlice.reducer,
    actions: EntitySlice.actions,
    helpers: {},
  };
};

export const CreateSelectorForEntities = <RootState, Entity>({
  sliceName,
}: {
  sliceName: string;
}) => {
  const selectQueryMap = (state: RootState) =>
    (state[sliceName] as iEntitySlice<Entity>).queries;

  const selectEntityMap = (state: RootState) =>
    (state[sliceName] as iEntitySlice<Entity>).byIds;

  const selectQueryMapAll = (state: RootState) => selectQueryMap(state)["all"];
  const selectQueryAllList = (state: RootState) =>
    selectQueryMap(state).all?.list;

  const selectAllEntities = createSelector(
    [selectQueryAllList, selectEntityMap],
    (list, map) => (list || []).map((conversationId) => map[conversationId]),
  );

  const memoizedAllQuerySelector = memoize(
    ({
      query,
      map,
    }: {
      query: ListQueryState;
      map: {
        [entityId: string]: Entity;
      };
    }) => {
      const populatedList = query?.list.map((listId) => map[listId]);
      return {
        ...defaultQueryValue,
        ...query,
        list: populatedList || [],
      };
    },
  );

  const selectAllEntitiesQuery = createSelector(
    [selectQueryMapAll, selectEntityMap],
    (query, map) => {
      return memoizedAllQuerySelector({ query, map });
    },
  );

  const selectEntityById = (entityId: string) =>
    createSelector([selectEntityMap], (map) => map[entityId]);

  const memoizedQuerySelector = memoize(
    ({
      queryId,
      queryMap,
      entitiesMap,
    }: {
      queryId: string;
      queryMap: {
        [query: string]: ListQueryState;
      };
      entitiesMap: {
        [entityId: string]: Entity;
      };
    }) => {
      const query = queryMap[queryId];
      const populatedList = (query?.list || []).map(
        (listId) => entitiesMap[listId],
      );
      return {
        ...defaultQueryValue,
        ...(query || {}),
        list: populatedList || [],
      };
    },
  );

  const memoizedQueryBuildQueryValue = memoize(
    ({
      defaultQueryValue,
      query,
    }: {
      defaultQueryValue: ListQueryState;
      query: ListQueryState;
    }) => {
      return {
        ...defaultQueryValue,
        ...(query || {}),
        list: (query || {}).list || [],
      };
    },
  );

  const memoizedQuerySelectorWithoutPopulation = memoize(
    ({
      queryId,
      queryMap,
    }: {
      queryId: string;
      queryMap: {
        [query: string]: ListQueryState;
      };
    }) => {
      const query = queryMap[queryId];
      return memoizedQueryBuildQueryValue({ defaultQueryValue, query });
    },
  );

  const selectEntityQuery = (queryId: string) =>
    createSelector([selectQueryMap, selectEntityMap], (queryMap, entitiesMap) =>
      memoizedQuerySelector({ queryMap, entitiesMap, queryId }),
    );

  const memoizedQueryListSelector = memoize(
    ({
      queryId,
      queryMap,
    }: {
      queryId: string;
      queryMap: {
        [query: string]: ListQueryState;
      };
    }) => {
      const query = queryMap[queryId];
      return query?.list || [];
    },
  );

  const selectEntityQueryList = (queryId: string) =>
    createSelector([selectQueryMap], (queryMap) =>
      memoizedQueryListSelector({ queryId, queryMap }),
    );

  const selectEntityQueryWithoutPopulation = (queryId: string) =>
    createSelector([selectQueryMap], (queryMap) =>
      memoizedQuerySelectorWithoutPopulation({ queryId, queryMap }),
    );

  return {
    selectQueryMap,
    selectEntityMap,
    selectQueryMapAll,
    selectQueryAllList,
    selectAllEntities,
    selectAllEntitiesQuery,
    selectEntityById,
    selectEntityQuery,
    selectEntityQueryList,
    selectEntityQueryWithoutPopulation,
  };
};

export const CreateHelpersForEntity = <RootStore, Entity>({
  slice,
  sliceName,
  loadEntityQueryProvider,
  postLoadEntities,
  fetchPageSize,
  isPaginatedQueries,
  loadEntityByIdProvider,
  loadEntityByIdsProvider,
  postLoadEntity,
}: {
  slice: Slice<iEntitySlice<Entity>, any>;
  sliceName: string;
  loadEntityQueryProvider: (
    paginatedQueryConfig: PaginatedQueryConfig,
  ) => Promise<Entity[] | PaginatedResults<Entity>>;
  loadEntityByIdProvider: (id: string) => Promise<Entity>;
  loadEntityByIdsProvider?: (ids: string[]) => Promise<Entity[]>;
  isPaginatedQueries: boolean;
  fetchPageSize?: number;
  postLoadEntities?: (
    id: Entity[],
    store: Store<RootStore>,
    forceReload?: boolean,
  ) => Promise<any>;
  postLoadEntity?: (
    id: Entity,
    store: Store<RootStore>,
    forceReload?: boolean,
  ) => Promise<any>;
}) => {
  const loadEntityQueries =
    (queryConfig: QueryConfig, queryAlias: string) =>
    async (store: Store<RootStore>, forceReload?: boolean) => {
      const state = store.getState()[sliceName] as iEntitySlice<Entity>;
      if (
        forceReload ||
        state.queries[queryAlias]?.hasError ||
        (state.queries[queryAlias]?.lastFetched || 0) + 10 * 60 * 1000 <
          Date.now()
      ) {
        try {
          if (forceReload && state.queries[queryAlias]?.list) {
            store.dispatch((slice.actions as any).resetQuery(queryAlias));
          }
          store.dispatch(
            (slice.actions as any).setEntityQueryLoading(queryAlias),
          );
          if (isPaginatedQueries) {
            const results = (await loadEntityQueryProvider({
              query: queryConfig.query,
              options: {
                ...queryConfig.options,
                page: 1,
                limit: fetchPageSize,
                sort: (queryConfig.options.sortBy || []).join(" "),
              },
            })) as PaginatedResults<Entity>;
            store.dispatch(
              (slice.actions as any).setEntityQueryResults({
                query: queryAlias,
                results,
              }),
            );
            postLoadEntities &&
              (await postLoadEntities(results.docs, store, forceReload));
            return results;
          } else {
            const results = (await loadEntityQueryProvider({
              ...queryConfig,
              options: {
                sort: (queryConfig.options.sortBy || []).join(" "),
              },
            })) as Entity[];
            store.dispatch(
              (slice.actions as any).setEntityQueryResults({
                query: queryAlias,
                results: {
                  docs: results,
                  totalDocs: 1,
                  limit: results.length + 100,
                  page: 1,
                  totalPages: 1,
                  offset: 0,
                },
              }),
            );
            postLoadEntities &&
              (await postLoadEntities(results, store, forceReload));
            return results;
          }
        } catch (e) {
          console.error("e", e);
          store.dispatch(
            (slice.actions as any).setEntityQueryError(queryAlias),
          );
          throw e;
        }
      }
    };

  const loadMoreEntityQueries =
    (queryConfig: QueryConfig, queryAlias: string) =>
    async (store: Store<RootStore>) => {
      const state = store.getState()[sliceName] as iEntitySlice<Entity>;
      if (
        state.queries[queryAlias] &&
        state.queries[queryAlias].lastFetchedPage
      ) {
        if (state.queries[queryAlias].hasError) {
          return await loadEntityQueries(queryConfig, queryAlias)(store);
        }
        try {
          store.dispatch(
            (slice.actions as any).setEntityQueryLoading(queryAlias),
          );
          const lastFetchedPage = state.queries[queryAlias].lastFetchedPage!;
          const results = (await loadEntityQueryProvider({
            query: queryConfig.query,
            options: {
              ...queryConfig.options,
              page: lastFetchedPage + 1,
              limit: fetchPageSize,
              sort: (queryConfig.options.sortBy || []).join(" "),
            },
          })) as PaginatedResults<Entity>;
          store.dispatch(
            (slice.actions as any).setEntityQueryResults({
              query: queryAlias,
              results,
            }),
          );
          postLoadEntities && (await postLoadEntities(results.docs, store));
          return results;
        } catch (e) {
          console.error("e", e);
          store.dispatch(
            (slice.actions as any).setEntityQueryError(queryAlias),
          );
          throw e;
        }
      } else {
        return await loadEntityQueries(queryConfig, queryAlias)(store);
      }
    };

  const loadEntityById =
    (entityId: string, ...args) =>
    () =>
    async (store: Store<RootStore>, forceReload?: boolean) => {
      const state = store.getState()[sliceName] as iEntitySlice<Entity>;
      if (
        (!state.byIds[entityId] || forceReload) &&
        state.itemsBeingFetched.indexOf(entityId) === -1
      ) {
        try {
          store.dispatch(
            (slice.actions as any).setEntityFetchingStatus({
              id: entityId,
              isFetching: true,
            }),
          );
          const entity = await (loadEntityByIdProvider as any)(
            entityId,
            ...args,
          );
          if (entity) {
            store.dispatch((slice.actions as any).setEntity(entity));
            postLoadEntity &&
              (await postLoadEntity(entity, store, forceReload));
          }
          store.dispatch(
            (slice.actions as any).setEntityFetchingStatus({
              id: entityId,
              isFetching: false,
            }),
          );
          store.dispatch(
            (slice.actions as any).setEntityFailedStatus({
              id: entityId,
              isFailed: entity ? false : true,
            }),
          );
          return entity;
        } catch (e) {
          console.log("e", e);
          store.dispatch(
            (slice.actions as any).setEntityFetchingStatus({
              id: entityId,
              isFetching: false,
            }),
          );
          store.dispatch(
            (slice.actions as any).setEntityFailedStatus({
              id: entityId,
              isFailed: true,
            }),
          );
          throw e;
        }
      }
      return state.byIds[entityId];
    };

  const loadEntitiesByIds =
    (entityIds: string[]) =>
    async (store: Store<RootStore>, forceReload?: boolean) => {
      if (!loadEntityByIdsProvider) {
        throw new Error("loadEntityByIdsProvider is not provided");
      }
      if (entityIds.length === 0) {
        return;
      }
      const state = store.getState()[sliceName] as iEntitySlice<Entity>;
      const entitiesToFetch = _.without(entityIds, ...state.itemsBeingFetched);
      if (entitiesToFetch.length === 0) {
        return;
      }
      try {
        store.dispatch(
          (slice.actions as any).setEntitiesFetchingStatus({
            ids: entitiesToFetch,
            isFetching: true,
          }),
        );
        const results = await loadEntityByIdsProvider(entitiesToFetch);
        const entities: Entity[] = results.filter((e) => e);
        if (entities.length > 0) {
          store.dispatch((slice.actions as any).setEntities(entities));
        }
        store.dispatch(
          (slice.actions as any).setEntitiesFetchingStatus({
            ids: entitiesToFetch,
            isFetching: false,
          }),
        );
        postLoadEntities &&
          (await postLoadEntities(entities, store, forceReload));
        return entities;
      } catch (e) {
        console.log("e", e);
        store.dispatch(
          (slice.actions as any).setEntitiesFetchingStatus({
            ids: entitiesToFetch,
            isFetching: false,
          }),
        );
        throw e;
      }
    };

  return {
    loadEntityQueries,
    loadEntityById,
    loadEntitiesByIds,
    loadMoreEntityQueries,
  };
};
