import NewMessageCreator from "components/modules/conversations/components/new-message/new-message.modal-registry";
import AddActivity from "components/modules/crm/activities/add-activity/add-activity.modal-registry";
import AddContact from "components/modules/crm/contacts/components/add-contact/add-contact.modal-registry";
import AddEditKnowledgeDocument from "components/pages/knowledge-kit-page/add-edit-knowledge-document/add-edit-document.modal-registry";
import CreateTracker from "components/pages/trackers/components/create-tracker-modal/create-tracker.modal-registry";

export const QuickActions = [
  {
    label: "New Conversation",
    keywords: "Start New Chat, Create New Chat, New Chat",
    icon: <i className="ri-add-line"></i>,
    modal: NewMessageCreator,
    additionalInput: {},
  },
  {
    label: "New Contact",
    keywords: "Add Customer",
    icon: <i className="ri-add-line"></i>,
    modal: AddContact,
    additionalInput: {},
  },
  {
    label: "New Tracker",
    keywords: "New Tracker, New Ticket",
    icon: <i className="ri-add-line"></i>,
    modal: CreateTracker,
    additionalInput: {},
  },
  {
    label: "Add Activity",
    keywords: "Record Activity",
    icon: <i className="ri-add-line"></i>,
    modal: AddActivity,
    additionalInput: {},
  },
  {
    label: "New Knowledge Document",
    keywords: "Add New Document to Knowledge Kit",
    icon: <i className="ri-add-line"></i>,
    modal: AddEditKnowledgeDocument,
    additionalInput: {},
  },
  // {
  //   label: "Add Opportunity",
  //   keywords: "Create Opportunity",
  //   icon: <i className="ri-add-line"></i>,
  //   modal: CreateOpportunityModal,
  //   additionalInput: {}
  // },
  // {
  //   label: "Add Engagement Modal",
  //   keywords: "Log Engagement",
  //   icon: <i className="ri-add-line"></i>,
  //   modal: AddEngagementModal,
  //   additionalInput: {}
  // },
  // {
  //   label: "Add Company",
  //   keywords: "Log Account",
  //   icon: <i className="ri-add-line"></i>,
  //   modal: AddCompany,
  //   additionalInput: {}
  // },
  // {
  //   label: "Add Product",
  //   keywords: "",
  //   icon: <i className="ri-add-line"></i>,
  //   modal: AddProduct,
  //   additionalInput: {}
  // },
];
