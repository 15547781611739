import { useCallback, useEffect, useRef, useState } from "react";

export const useStateWithGetter = <T>(initial: T) => {
  const [state, setState] = useState(initial);
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const getter = useCallback(() => stateRef.current, []);
  return [state, setState, getter] as [
    typeof state,
    typeof setState,
    typeof getter
  ];
};
