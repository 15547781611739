import { SDK } from "@sdk";
import { iWidget } from "@sdk/chat-widgets/chat-widgets.models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { chatWidgetDefaultQueryConfig } from "./chat-widget-default-query";
import { ChatWidgetsSlice } from "./chat-widgets.slice";

export const loadAllChatWidgets = () => async (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  await loadChatWidgetsQuery(chatWidgetDefaultQueryConfig, "all")(
    store,
    forceReload
  );
};

export const {
  loadEntityQueries: loadChatWidgetsQuery,
  loadEntityById: loadChatWidgetById
} = CreateHelpersForEntity<iStore, iWidget>({
  sliceName: "chatWidgets",
  slice: ChatWidgetsSlice,
  isPaginatedQueries: false,
  fetchPageSize: 20,
  loadEntityQueryProvider: query => SDK.chatWidgets.getAll(),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.chatWidgets.getById(entityId)
});
