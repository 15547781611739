import { SDK } from "@sdk";

import debounce from "debounce-promise";

export const debouncedMailboxEmailAvailability = debounce(
  async (domain, connectionId?) => {
    try {
      const status = await SDK.connections.checkEmailAvailability(domain);
      if (status.connection === connectionId) {
        return;
      }
      if (!status.availability) {
        throw "Email is already taken";
      }
      return;
    } catch (e) {
      console.log("e", e);
      throw "Email is already taken";
    }
  },
  1000,
  { leading: false, trailing: true },
);
