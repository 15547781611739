import { useResponsive } from "@libs/@react-hooks-responsive";
import { Divider, Layout } from "antd";

import { useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router";
import { MainNavigationBar } from "../main-navigation-bar/main-navigation-bar-legacy";
import { MainNavigationBar3 } from "../main-navigation-bar/main-navigation-bar-new";
import "./main-layout.style.scss";

const breakpoints = { xs: 0, sm: 480, md: 1024 };

const { Content, Footer } = Layout;

export const MainLayout = (props: {
  children: JSX.Element;
  banner?: JSX.Element;
}) => {
  const { size, orientation, screenIsAtLeast, screenIsAtMost } =
    useResponsive(breakpoints);

  const location = useLocation();

  const isMobile = useMediaQuery({ query: "(max-width: 735px)" });

  const hideMainMenu = useMemo(() => {
    return isMobile && location?.pathname.includes("/conversations/");
  }, [isMobile, location?.pathname]);

  return (
    <>
      <Layout className="main-layout-outer-container">
        {props.banner}
        {!screenIsAtLeast("md") && !hideMainMenu && (
          <>
            <MainNavigationBar mobileMode={true} />
            <Divider type="horizontal" className="my-0" />
          </>
        )}

        <Layout className="bg-white dark:bg-gray-900 mode_transition mode_transition flex flex-row">
          {screenIsAtLeast("md") && (
            <>
              <MainNavigationBar3 />
            </>
          )}

          <Content
            style={{}}
            className="bg-white dark:bg-gray-900 main-layout-container mode_transition"
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
