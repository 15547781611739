import { Select } from "antd";
import { CountryCodes } from "utils/country-codes";

export const CountrySelector = ({ value, onChange }: { value?; onChange? }) => {
  return (
    <Select
      placeholder="Select a country"
      allowClear
      value={value}
      onChange={onChange}
      showSearch
      optionFilterProp="name"
      options={CountryCodes.map((country) => ({
        label: (
          <div className="flex flex-row items-center">
            <span
              className={`flag-icon flag-icon-${country.code.toLowerCase()} mr-2`}
            ></span>{" "}
            {country.name}
          </div>
        ),
        name: country.name,
        value: country.code,
      }))}
    ></Select>
  );
};
