import _ from "lodash";
import { getMobileId } from "utils/clean-mobile-number";
import { escapeRegExp } from "utils/escape-regex-search-string";

export function buildOpportunitySearchQuery(term: string) {
  term = term.trim();
  // 1) User could enter contact Name
  // 2) User could enter contact email
  // 3) User could enter contact mobile
  // 4) User could enter email subject

  // Advanced
  // 5) User could enter a message in the thread

  //* Not Implemented Yet
  // User could enter special keywords
  //      tag:sale
  //      email:abc@abc.com

  const words = term.split(" ");

  // 1) User could enter contact Name
  const searchByContactNameQuery =
    words.length === 1
      ? [
          {
            "contact.data.firstName": {
              $regex: `${escapeRegExp(words[0])}`,
              $options: "i",
            },
          },
          {
            "contact.data.lastName": {
              $regex: `${escapeRegExp(words[0])}`,
              $options: "i",
            },
          },
        ]
      : words.length === 2
      ? [
          {
            $and: [
              {
                "contact.data.firstName": {
                  $regex: `${escapeRegExp(words[0])}`,
                  $options: "i",
                },
              },
              {
                "contact.data.lastName": {
                  $regex: `${escapeRegExp(
                    words.slice(1).join(",").replace(/,/g, " "),
                  )}`,
                  $options: "i",
                },
              },
            ],
          },
          {
            "contact.data.firstName": {
              $regex: `${escapeRegExp(words.join(",").replace(/,/g, " "))}`,
              $options: "i",
            },
          },
        ]
      : [
          {
            $and: [
              {
                "contact.data.firstName": {
                  $regex: `${escapeRegExp(
                    words.slice(0, 2).join(",").replace(/,/g, " "),
                  )}`,
                  $options: "i",
                },
              },
              {
                "contact.data.lastName": {
                  $regex: `${escapeRegExp(
                    words.slice(2).join(",").replace(/,/g, " "),
                  )}`,
                  $options: "i",
                },
              },
            ],
          },
          {
            $and: [
              {
                "contact.data.firstName": {
                  $regex: `${escapeRegExp(
                    words.slice(0, 1).join(",").replace(/,/g, " "),
                  )}`,
                  $options: "i",
                },
              },
              {
                "contact.data.lastName": {
                  $regex: `${escapeRegExp(
                    words.slice(1).join(",").replace(/,/g, " "),
                  )}`,
                  $options: "i",
                },
              },
            ],
          },
        ];

  // 2) User could enter contact email
  const searchByEmailQuery = {
    "contact.data.primaryEmail": {
      $regex: escapeRegExp(term),
      $options: "i",
    },
  };

  // 3) User could enter contact mobile
  const mobileId = getMobileId(term).replace("+", "").trim();
  const searchByMobileQuery = mobileId
    ? {
        "contact.data.primaryMobile": {
          $regex: escapeRegExp(mobileId),
          $options: "i",
        },
      }
    : undefined;

  // 4) User could enter title
  const searchByTitleQuery = {
    title: {
      $regex: escapeRegExp(term),
      $options: "i",
    },
  };

  return {
    opportunities: {
      $or: _.flattenDeep(
        [
          ...searchByContactNameQuery,
          searchByEmailQuery,
          searchByMobileQuery,
          searchByTitleQuery,
        ].filter((e) => e),
      ),
    },
  };
}
