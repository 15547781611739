import PlusCircleFilled from "@ant-design/icons/PlusCircleFilled";
import SettingOutlined from "@ant-design/icons/SettingOutlined";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iCustomField } from "@sdk/user-management/user-management.models";
import { Button, Divider, Space } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { SectionHeader } from "components/common/section-header";
import { SimpleDNDList } from "components/common/simple-dnd-list/dnd-list";
import { StyledScrollArea } from "components/common/styled-scroll-area";

import { FormFieldIcon } from "components/pages/dynamic-forms/manage-dynamic-forms/components/form-icon";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "store/modules/ui-state/ui-state.selectors";
import { setOrganization } from "store/modules/workspace/workspace.actions";
import {
  selectConversationCustomProperties,
  selectOrganization,
} from "store/modules/workspace/workspace.selectors";
import { uuidv4 } from "utils/generate-uuid";
import { useEffectWhen } from "utils/hooks/use-effect-when";
import { useSimpleState } from "utils/hooks/use-simple-state";
import "./custom-properties.scss";
import { CustomFieldEditorModal } from "./field-editor";

export const CustomConversationProperties = (props: {
  showTitle?: boolean;
}) => {
  const organization = useSelector(selectOrganization);
  if (organization) {
    // eslint-disable-next-line react/jsx-pascal-case
    return <_CustomConversationProperties {...props} />;
  }
  return <></>;
};

const _CustomConversationProperties = ({
  showTitle,
}: {
  showTitle?: boolean;
}) => {
  const customProperties = useSelector(selectConversationCustomProperties);
  const isDarkMode = useSelector(selectIsDarkMode);

  const [fieldEditorState, setFieldEditorState] = useSimpleState({
    visibility: false,
    mode: "ADD" as "ADD" | "EDIT",
    field: null as iCustomField | null,
  });

  const [state, setState] = useState<iCustomField[]>(customProperties);

  const {
    doAction: onUpdate,
    isProcessing,
    dispatch,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values) =>
        SDK.editCurrentOrganization(values).then((res) => {
          dispatch(setOrganization(res));
        }),
      // successMessage: "Account has been updated",
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const saveChanges = useCallback(
    (state?: iCustomField[]) => {
      onUpdate({
        appData: {
          HELP_DESK: {
            customConversationProperties: state,
          },
        },
      });
    },
    [onUpdate],
  );

  useEffectWhen(
    () => {
      saveChanges(state);
    },
    [saveChanges, state],
    [state],
  );

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin">
      <SectionHeader
        title="Custom Conversation Properties"
        icon={<i className="ri-database-2-line"></i>}
        description="Conversation Properties helps you to store your own data in the
        conversation as properties."
        addons={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                setFieldEditorState({
                  visibility: true,
                  mode: "ADD",
                  field: null,
                });
              }}
              icon={<PlusCircleFilled />}
              className="flex items-center"
            >
              Add Property
            </Button>
          </Space>
        }
      />
      <Divider />

      <CustomFieldEditorModal
        visible={fieldEditorState.visibility}
        initialValue={fieldEditorState.field!}
        onCancel={() => setFieldEditorState({ visibility: false, field: null })}
        onSave={(newFieldValue) => {
          if (fieldEditorState.mode === "ADD") {
            newFieldValue.id = uuidv4();
            newFieldValue.key = newFieldValue.id;
            setState([...state, newFieldValue]);
          } else if (fieldEditorState.mode === "EDIT") {
            setState((state) =>
              state.map((field) => {
                if (field.id === newFieldValue.id) {
                  return newFieldValue;
                }
                return field;
              }),
            );
          }
          setFieldEditorState({ visibility: false });
        }}
        mode={fieldEditorState.mode}
      />

      <div className="custom-fields-container p-6 relative">
        {state.length === 0 && (
          <EmptyData
            text="No properties to show"
            icon="ri-profile-line"
            className="w-full"
          />
        )}

        <SimpleDNDList
          list={state}
          onListChange={setState}
          renderItem={(fieldItem) => (
            <>
              <div className="my-3 mx-1 h-10 flex items-center border border-gray-200 dark:border-gray-800">
                <div
                  className="flex-grow pl-2 cursor-pointer flex flex-row items-center"
                  onClick={() =>
                    setFieldEditorState({
                      visibility: true,
                      mode: "EDIT",
                      field: fieldItem,
                    })
                  }
                >
                  <div className="mr-2">
                    <FormFieldIcon type={fieldItem.inputType} />
                  </div>
                  {fieldItem.label}
                </div>

                <div
                  className="w-10 flex justify-center items-center cursor-pointer extra-icon"
                  onClick={(e) => {
                    setState((state) =>
                      state.filter((field) => {
                        if (field.id === fieldItem.id) {
                          return false;
                        }
                        return true;
                      }),
                    );
                    e.stopPropagation();
                  }}
                >
                  <i className="ri-delete-bin-line text-lg"></i>
                </div>
                <div
                  className="w-10 flex justify-center items-center cursor-pointer extra-icon"
                  onClick={() =>
                    setFieldEditorState({
                      visibility: true,
                      mode: "EDIT",
                      field: fieldItem,
                    })
                  }
                >
                  <SettingOutlined className="text-lg" />
                </div>
              </div>
            </>
          )}
        />
      </div>
    </StyledScrollArea>
  );
};
