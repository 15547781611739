import { iBadgeRecord } from "@sdk/badges/badge-record-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { badgesDefaultQueryConfig } from "./badges-default-query";

export const {
  slice: BadgesSlice,
  reducers: BadgesSliceReducer,
  actions: {
    setEntityQueryResults: setBadgesQueryResults,
    setEntity: setBadge,
    setEntities: setBadges,
    patchEntity: patchBadge,
    addNewEntity: addNewBadge,
    setEntityFetchingStatus: setBadgeFetchingStatus,
    setEntitiesFetchingStatus: setBadgesFetchingStatus,
    setEntityFailedStatus: setBadgeFailedStatus,
    setEntityQueryError: setBadgesQueryError,
    resetQuery: resetBadgesQuery,
  },
} = CreateEntitySlice<iBadgeRecord>({
  sliceName: "badges",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...badgesDefaultQueryConfig,
        alias: "all",
      };
    }
  },
});
