import { iHolidayQuote } from "@sdk/holiday-quotes/holiday-quotes-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { holidayQuotesDefaultQueryConfig } from "./holiday-quotes-default-query";

export const {
  slice: HolidayQuotesSlice,
  reducers: HolidayQuotesSliceReducer,
  actions: {
    setEntityQueryResults: setHolidayQuotesQueryResults,
    setEntity: setHolidayQuote,
    setEntities: setHolidayQuotes,
    patchEntity: patchHolidayQuote,
    addNewEntity: addNewHolidayQuote,
    setEntityFetchingStatus: setHolidayQuoteFetchingStatus,
    setEntitiesFetchingStatus: setHolidayQuotesFetchingStatus,
    setEntityFailedStatus: setHolidayQuoteFailedStatus,
    setEntityQueryError: setHolidayQuotesQueryError,
    resetQuery: resetHolidayQuotesQuery,
    setQueryList: setHolidayQuoteQueryList
  }
} = CreateEntitySlice<iHolidayQuote>({
  sliceName: "holidayQuotes",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...holidayQuotesDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
