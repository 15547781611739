import {
  ConvertNotionBlocksToPlainText,
  PropertiesToMap,
  getMappableNotionDBProperties,
} from "@sdk/external-accounts/notion/notion-helpers";
import { useSDK, useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iExternalAppConfig_Notion } from "@sdk/user-management/user-management.models";
import { Alert, Button, Form, Select, Spin, Tag, Tooltip, message } from "antd";
import { ExternalAuthConnector } from "components/common/external-auth-connector-button/external-auth-connector-button";
import {
  LoadingIndicatorWithSpin,
  LoadingIndicatorWithoutSpin,
} from "components/common/loading-indicator/loading-indicator";
import { HelpUsHelpYou } from "components/help-us-help-you/help-us-help-you";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "store/modules/ui-state/ui-state.selectors";
import { setOrganization } from "store/modules/workspace/workspace.actions";
import { selectIntegrationConfig_Notion } from "store/modules/workspace/workspace.selectors";

export const NotionIntegrationConfig = () => {
  const integrationConfig = useSelector(selectIntegrationConfig_Notion);
  const isDarkMode = useSelector(selectIsDarkMode);

  const {
    doAction: completeIntegration,
    isProcessing: isAdding,
    dispatch,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (code: string) =>
        SDK.externalAccounts
          .completeIntegration({
            type: "NOTION",
            data: {
              code,
            },
          })
          .then((d) => dispatch(setOrganization(d))),
      successMessage: "Integration has been successful",
      failureMessage: "Something went wrong",
      actionDependencies: [],
    }),
    [],
  );

  const { doAction: saveConfig, isProcessing: isSavingConfig } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (config) =>
          SDK.externalAccounts
            .updateConfig({
              type: "NOTION",
              config,
            })
            .then((d) => dispatch(setOrganization(d))),
        successMessage: "Configuration has been saved",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [dispatch],
    );

  const { doAction: removeIntegration, isProcessing: isRemoving } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.externalAccounts
            .removeIntegration({
              type: "NOTION",
            })
            .then((d) => dispatch(setOrganization(d))),
        successMessage: "Integration has been removed",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [dispatch],
    );

  const [isConfigEditMode, setConfigEditMode] = useState(false);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(
    integrationConfig?.config || ({} as iExternalAppConfig_Notion),
  );

  useEffect(() => {
    if (integrationConfig?.config) {
      setFormValues(integrationConfig?.config);
    }
  }, [integrationConfig?.config]);

  const { data: databaseOptions, isLoading } = useSDK(
    (SDK) =>
      SDK.notion.getAvailableDatabases({ searchTerm: "" }).then((data) => {
        return (data?.results || []).map((item) => ({
          value: item.id,
          label: ConvertNotionBlocksToPlainText(item.title),
          properties: item.properties,
        }));
      }),
    [integrationConfig],
    !integrationConfig || !isConfigEditMode,
    [],
  );

  const selectedDbProperties = useMemo(() => {
    const selectedDBId = formValues.databaseId;
    if (!selectedDBId) {
      return [];
    }
    const selectedDb = databaseOptions.find(
      (item) => item.value === selectedDBId,
    );
    if (!selectedDb) {
      return [];
    }
    return getMappableNotionDBProperties(selectedDb.properties);
  }, [databaseOptions, formValues.databaseId]);

  const onSaveConfig = useCallback(
    (e) => {
      e.preventDefault();
      const process = async () => {
        try {
          await form.validateFields();
          const formValues = form.getFieldsValue();
          const selectedDatabase = databaseOptions.find(
            (item) => item.value === formValues.databaseId,
          );

          saveConfig({
            ...formValues,
            isReady: true,
            databaseName: selectedDatabase?.label,
          } as iExternalAppConfig_Notion).then((d) => {
            setConfigEditMode(false);
          });
        } catch (e) {
          message.error("Please check your input");
        }
      };
      process();
    },
    [databaseOptions, form, saveConfig],
  );

  const {
    data: test,
    isLoading: isTestingIntegration,
    error,
    reload: reTestIntegration,
  } = useSDK(
    (SDK) => SDK.notion.getAvailableDatabases({ searchTerm: "" }),
    [integrationConfig?.config?.isReady],
    !integrationConfig?.config?.isReady,
  );

  useEffect(() => {
    if (integrationConfig && !integrationConfig.config.isReady) {
      setConfigEditMode(true);
    }
  }, [integrationConfig]);

  return (
    <>
      {integrationConfig && (
        <div>
          {integrationConfig.config.isReady ? (
            <Spin
              spinning={isTestingIntegration}
              indicator={<LoadingIndicatorWithSpin />}
            >
              {!error && (
                <Alert
                  message="Integration is active"
                  type="success"
                  showIcon
                />
              )}
              {error && (
                <Alert
                  message="Integration is not working correctly. Try Re-Authenticating"
                  type="error"
                  showIcon
                />
              )}
            </Spin>
          ) : (
            <Alert
              message="Notion integration is not ready yet. Complete the below steps to enable it"
              type="warning"
              showIcon
            />
          )}

          <div className="flex flex-row justify-between mt-8">
            <div className="text">Connected Workspace</div>
            <div className="flex flex-row items-center">
              <div className="">
                <Tag>{integrationConfig.config.workspaceName}</Tag>
              </div>
              <Tooltip title="Remove Integration">
                <Button
                  type="link"
                  icon={<i className="ri-delete-bin-line"></i>}
                  onClick={() => {
                    removeIntegration();
                  }}
                  loading={isRemoving}
                ></Button>
              </Tooltip>
            </div>
          </div>
          <div className="flex flex-row justify-between my-2">
            <div className="text">Default Database</div>
            <div className="flex flex-row items-center">
              <div className="">
                <Tag>
                  {integrationConfig.config.databaseName || "Not Selected"}
                </Tag>
                (
                {
                  Object.keys(integrationConfig?.config?.propertyMap || {})
                    .length
                }{" "}
                Columns Mapped)
              </div>
              {!isConfigEditMode && (
                <>
                  <Tooltip title="Configure">
                    <Button
                      type="link"
                      icon={<i className="ri-pencil-line"></i>}
                      onClick={() => {
                        setConfigEditMode(true);
                      }}
                      loading={isRemoving}
                    ></Button>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
          {isConfigEditMode && (
            <div className=" w-full">
              <Form
                form={form}
                layout="vertical"
                initialValues={integrationConfig.config}
                requiredMark={false}
                className="bold-form-labels"
                onFinish={onSaveConfig}
                onValuesChange={() => {
                  const formValues = form.getFieldsValue();
                  setFormValues(formValues);
                }}
              >
                <Form.Item
                  name="databaseId"
                  label="Database"
                  rules={[
                    {
                      required: true,
                      message: "Please select a database",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Database"
                    options={databaseOptions}
                    showSearch
                  />
                </Form.Item>

                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const databaseId = getFieldValue(["databaseId"]);
                    if (!databaseId) {
                      return <></>;
                    }
                    return (
                      <>
                        <div className="font-bold">
                          Map Database Columns to Conversation Properties
                        </div>
                        <Alert
                          type="info"
                          showIcon
                          message="Only Title, Rich Text and URL columns can be mapped"
                          className="mb-2"
                        />
                        {selectedDbProperties.map((property) => (
                          <div key={property.id}>
                            <Form.Item
                              name={["propertyMap", property.id]}
                              label={property.name}
                              rules={[]}
                            >
                              <Select
                                placeholder="Select Field"
                                options={PropertiesToMap}
                                showSearch
                                allowClear
                              />
                            </Form.Item>
                          </div>
                        ))}
                      </>
                    );
                  }}
                </Form.Item>

                <div className="flex flex-row justify-end items-center">
                  <Button
                    type="primary"
                    className="font-bold"
                    loading={isAdding}
                    onClick={onSaveConfig}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          )}
          <HelpUsHelpYou
            chatMessage="How can we improve Notion Integration for you?"
            emoji="☝️"
            description="Share your workflows with Notion and we'll ensure that our Notion Integration saves you time."
          />
        </div>
      )}

      {!integrationConfig && (
        <div className="flex flex-row justify-center items-center">
          <Spin
            spinning={isAdding}
            indicator={<LoadingIndicatorWithoutSpin />}
            wrapperClassName="flex fill-spinner"
          >
            <ExternalAuthConnector
              type="NOTION"
              onAuthenticated={({ code }) => {
                completeIntegration(code);
              }}
              label="Connect Notion"
            />
          </Spin>
        </div>
      )}
    </>
  );
};
