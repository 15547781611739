import { Divider } from "antd";
import { SectionHeader } from "components/common/section-header";
import { ChannelNameForm } from "components/modules/connections/common-connection-settings/channel-name/channel-name-form";

import { ConnectionTaggingPreferencesEditor } from "components/modules/connections/common-connection-settings/connection-tagging-preference-editor/connection-tagging-preferences-editor";
import { RestrictAccessForm } from "components/modules/connections/common-connection-settings/restrict-access-form/restrict-access-form";

import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { ConfigurationEditorMiniBase } from "components/common/configuration-editor-mini";
import { SimpleCollapseWithDivider } from "components/common/simple-collapse-with-header/simple-collapse-with-divider";
import SimpleModal from "components/common/simple-empty-modal/simple-empty.modal-registry";
import { useState } from "react";
import { ConnectionLimits } from "./connection-limits/connection-limits";
import { DeleteConnectionSettings } from "./delete-connection-settings/delete-connection-settings";
import { EmailNotificationsForm } from "./email-notifications-form/email-notifications-form";

export const GeneralConnectionConfig = ({
  connectionId,
  withoutHeader,
  additionalPowerSettings,
  powerSettingsDefaultExpanded,
}: {
  connectionId?: string;
  withoutHeader?: boolean;
  additionalPowerSettings?: JSX.Element;
  powerSettingsDefaultExpanded?: boolean;
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const { triggerTempModal } = useModalPanels();

  return (
    <div className="animated fadeInLeftMin">
      {!withoutHeader && (
        <>
          <SectionHeader
            title="General Connection Settings"
            icon={<i className="ri-tools-line"></i>}
            description="Fine-tune this connection"
          />
          <Divider />
        </>
      )}

      <SimpleCollapseWithDivider
        title="Power Settings"
        defaultExpanded={powerSettingsDefaultExpanded}
      >
        {/* Name */}
        <ChannelNameForm connectionId={connectionId} />

        <RestrictAccessForm connectionId={connectionId} />

        <ConnectionTaggingPreferencesEditor connectionId={connectionId} />

        <EmailNotificationsForm connectionId={connectionId} />

        <ConfigurationEditorMiniBase
          icon={"ri-git-commit-fill"}
          title={"User Limits"}
          description={"Prevent users from being assigned too many chats"}
          onClick={() => {
            triggerTempModal(SimpleModal, {
              config: {
                title: "User Limits",
                content: <ConnectionLimits connectionId={connectionId!} />,
                icon: <i className="ri-git-commit-fill"></i>,
                width: 800,
              },
            });
          }}
        />

        {additionalPowerSettings}

        <SimpleCollapseWithDivider title="Danger">
          <DeleteConnectionSettings connectionId={connectionId!} />
        </SimpleCollapseWithDivider>
      </SimpleCollapseWithDivider>
    </div>
  );
};
