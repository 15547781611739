import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import { useMemo } from "react";
import { selectAllUsersQuery } from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { loadAllUsers } from "../../../../../../../store/modules/users/users.helpers";

export const useTeamStatusStats = () => {
  const {
    state: { list: allUsers, isLoading },
    retry: reload
  } = useQueryWithStore(selectAllUsersQuery, loadAllUsers());

  const {
    users,
    onlineUsers,
    awayUsers,
    busyUsers,
    OfflineUsers,
    onlineAndAvailableForCall,
    awayAndAvailableForCall,
    notAvailableForCall
  } = useMemo(() => {
    try {
      return {
        users: (allUsers || []).map(user => ({
          id: user.id,
          name: `${user.data.firstName} ${user.data.lastName}`,
          email: user.credentials.email,
          role: getRoleFromPermission(user.permissions),
          isActive: user.metaData.isActive,
          isDeleted: user.metaData.isDeleted,
          lastOnline: user.metaData.isAvailable
            ? user.metaData.availabilityStatus
            : user.metaData.lastOnline,
          status: (() => {
            if (user.metaData.isAvailable) {
              return user.metaData.availabilityStatus;
            }
            return "Offline";
          })()
        })),
        onlineUsers: (allUsers || []).filter(
          d =>
            d.metaData.isAvailable &&
            d.metaData.availabilityStatus === "AVAILABLE"
        ).length,
        awayUsers: (allUsers || []).filter(
          d =>
            d.metaData.isAvailable && d.metaData.availabilityStatus === "AWAY"
        ).length,
        busyUsers: (allUsers || []).filter(
          d =>
            d.metaData.isAvailable && d.metaData.availabilityStatus === "BUSY"
        ).length,
        OfflineUsers: (allUsers || []).filter(
          d => !d.metaData.isAvailable && d.metaData.isActive
        ).length,
        onlineAndAvailableForCall: (allUsers || []).filter(
          d =>
            d.metaData.isAvailable &&
            d.metaData.availabilityStatus === "AVAILABLE" &&
            d.data.sipUser?.username
        ).length,
        awayAndAvailableForCall: (allUsers || []).filter(
          d =>
            d.metaData.isAvailable &&
            (d.metaData.availabilityStatus === "AWAY" ||
              d.metaData.availabilityStatus === "BUSY") &&
            d.data.sipUser?.username
        ).length,
        notAvailableForCall: (allUsers || []).filter(
          d =>
            (!d.metaData.isAvailable && d.metaData.isActive) ||
            (d.metaData.isAvailable &&
              d.metaData.isActive &&
              !d.data.sipUser?.username)
        ).length
      };
    } catch (e) {
      // Cannot read properties of undefined (reading 'firstName'). This is quite not possible
      console.log(
        "Error while processing users",
        e,
        (e as any)?.message,
        (e as any)?.stack,
        JSON.stringify(allUsers)
      );
      throw e;
    }
  }, [allUsers]);

  return {
    users,
    onlineUsers,
    awayUsers,
    busyUsers,
    OfflineUsers,
    onlineAndAvailableForCall,
    awayAndAvailableForCall,
    notAvailableForCall
  };
};
