import { Tooltip } from "antd";
import classNames from "classnames";
import React, { useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { replaceKeyBindingToActualKey } from "./keyboard-shortcut-helpers";
import "./keyboard-shortcut.scss";

export const KeyboardShortcut = ({
  label,
  inDarkBg,
  disableToolTip,
  borderClass,
}: {
  label: string;
  inDarkBg?: boolean;
  disableToolTip?: boolean;
  borderClass?: string;
}) => {
  const keys = useMemo(() => {
    // const parts = parseKeybinding(label);
    const parts = label.split("+");
    const flattenedParts = parts.map((part) =>
      Array.isArray(part) ? part.join("") : part,
    );
    return flattenedParts.map((part) => replaceKeyBindingToActualKey(part));
  }, [label]);

  const Container = disableToolTip ? React.Fragment : Tooltip;

  const isMobileView = useMediaQuery({ query: "(max-width: 500px)" });

  if (isMobileView) {
    return <></>;
  }

  return (
    <Container {...(disableToolTip ? {} : { title: "Keyboard Shortcut" })}>
      <div className="ml-2 inline-block">
        <div
          className={classNames(
            "keyboard-shortcut border rounded-md px-1 mr-1 font-mono",
            {
              "text-gray-600": !inDarkBg,
              "border-gray-300 dark:border-gray-800": !borderClass && !inDarkBg,
              "text-gray-100": inDarkBg,
              "border-gray-100": !borderClass && inDarkBg,
            },
          )}
        >
          {keys.join(" ")}
        </div>
      </div>
    </Container>
  );
};
