import { Form, Select, Switch } from "antd";
import { ConfigurationEditorMiniComponent } from "components/common/configuration-editor-mini";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectConversationTags } from "store/modules/workspace/workspace.selectors";

export const ConnectionTaggingPreferencesEditor = ({
  connectionId,
}: {
  connectionId;
}) => {
  const conversationTags = useSelector(selectConversationTags);

  const existingConversationTagGroups = useMemo(() => {
    const grouped = _.groupBy(conversationTags, "tagGroup");
    return Object.keys(grouped).map((tagGroup) =>
      tagGroup === "undefined" ? "General Tags" : tagGroup,
    );
  }, [conversationTags]);

  return (
    <ConfigurationEditorMiniComponent
      entityType="CONNECTION"
      entityId={connectionId}
      icon={"ri-price-tag-3-line"}
      title="Make Conversation Tags Mandatory"
      description="Users will not be allowed to continue chatting without tagging the chat"
    >
      {/* Force User to tag */}
      <div className="auto-trigger flex flex-row items-center justify-between mb-2">
        <div className="font-bold">Make Tagging Mandatory</div>
        <Form.Item
          name={["data", "forceUserToTag"]}
          hasFeedback={false}
          valuePropName="checked"
          style={{ marginBottom: 0, marginLeft: 5 }}
        >
          <Switch />
        </Form.Item>
      </div>

      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const isMandatory = getFieldValue(["data", "forceUserToTag"]);
          if (isMandatory) {
            return (
              <>
                <Form.Item
                  label="Tag Groups that are mandatory to tag"
                  name={["data", "forceUserToTagByTagGroup"]}
                  // className="mb-0"
                >
                  <Select
                    style={{ minWidth: 300 }}
                    placeholder="Select tag group"
                    mode="tags"
                  >
                    {existingConversationTagGroups.map((item, index) => (
                      <Select.Option value={item} key={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            );
          }
          return <></>;
        }}
      </Form.Item>
    </ConfigurationEditorMiniComponent>
  );
};
