import { useSDK } from "@sdk";
import { Button, Space, Table, Tag } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import dayjs from "dayjs";
import { forwardRef, useImperativeHandle } from "react";

const invoicesColumns = [
  {
    title: "Amount",
    dataIndex: "total",
    render: (total: number) => <span>{`$${total / 100}`}</span>,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (status: string) => (
      <span>
        <Tag>{status?.toUpperCase()}</Tag>
      </span>
    ),
  },
  {
    title: "Due",
    dataIndex: "created",
    render: (created: number) => (
      <span>{dayjs((created || 0) * 1000).format("DD-MM-YYYY")}</span>
    ),
  },
  {
    title: "",
    key: "invoice_pdf",
    render: (invoice_pdf, record) => {
      return (
        <Space size="middle">
          <Button type="link" href={record.invoice_pdf} target="_blank">
            Download
          </Button>
        </Space>
      );
    },
  },
];

export const RecentInvoices = forwardRef((props, ref) => {
  const {
    data: invoices,
    isLoading: isInvoiceLoading,
    reload: reloadInvoices,
  } = useSDK((SDK) => SDK.getRecentInvoices(), [], false, []);

  useImperativeHandle(
    ref,
    () => ({
      reloadInvoices: reloadInvoices,
    }),
    [reloadInvoices],
  );

  return (
    <>
      <div className="subtitle text-gray-600 dark:text-gray-400 mb-2 mt-8 ml-2 font-bold text-lg mode_transition">
        Recent Invoices
      </div>
      <Table
        columns={invoicesColumns}
        dataSource={invoices}
        pagination={invoices.length < 10 ? false : undefined}
        rowKey="id"
        loading={
          isInvoiceLoading
            ? { indicator: <LoadingIndicatorWithoutSpin /> }
            : false
        }
        locale={{
          emptyText: (
            <EmptyData text="No invoices to show" icon="ri-bill-line" />
          ),
        }}
      />
    </>
  );
});
