import { StandardTextInput } from "./components/standard-text-input";
import { iCondition } from "./models";

export const RoutingOptions = [
  {
    label: "Stop Routing",
    value: "STOP_ROUTING",
  },
  {
    label: "Route to any available agent",
    value: "ROUTE_TO_ANY_AVAILABLE_AGENT",
  },
  {
    label: "route to a user group",
    value: "CUSTOM_ROUTING",
  },
  {
    label: "Rout using default configuration",
    value: "STANDARD_ROUTING",
  },
];

export const RoutingConditionConfigurations: iCondition[] = [
  // {
  //   label: "Ad Id",
  //   key: "adID",
  //   operators: [
  //     {
  //       key: "is",
  //       label: "is",
  //       help: "Select ads that should match the rule condition",
  //       widget: MultiAdReferenceSelector
  //     }
  //   ]
  // },
  {
    label: "Ad Name",
    key: "adName",
    operators: [
      {
        key: "is",
        label: "is",
        help: "Enter a string to match against Ad Name reference received for the conversation",
        widget: StandardTextInput,
      },
      {
        key: "contains",
        label: "contains",
        help: "Enter a string to match against Ad name reference received for the conversation. The string will be evaluated for for partial match",
        widget: StandardTextInput,
      },
    ],
  },
  {
    label: "Lead Message",
    key: "leadMessage",
    operators: [
      {
        key: "is",
        label: "is",
        help: "Enter a string to match against Lead Message reference received for the conversation",
        widget: StandardTextInput,
      },
      {
        key: "contains",
        label: "contains",
        help: "Enter a string to match against Lead Message reference received for the conversation. The string will be evaluated for for partial match",
        widget: StandardTextInput,
      },
    ],
  },
  // {
  //   label: "Language",
  //   key: "language",
  //   operators: [
  //     {
  //       key: "is",
  //       label: "is",
  //       help:
  //         "Enter a string to match against language of the client received for the conversation",
  //       widget: StandardTextInput
  //     }
  //   ]
  // }
];
