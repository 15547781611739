import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ClickUpAddTaskModal = React.lazy(
  () => import("./clickup-add-task.modal"),
);

const ClickUpAddTask: iModalProps<
  typeof ClickUpAddTaskModal,
  {
    onAdded?: (pageId: string) => any;
    contactId?: string;
    conversationId?: string;
  }
> = {
  name: "click-up-add-task",
  component: GenerateModalWithSuspense(ClickUpAddTaskModal),
};

export default ClickUpAddTask;
