import { message } from "antd";
// import Resizer from "react-image-file-resizer";
import { ImageResizer } from "./react-image-file-resizer";
import {
  iImageUploadResizerOptions,
  iUploadValidation,
} from "./upload-validation-model";

export function uploadFileWithValidation<T>({
  file,
  reference,
  uploadFileFunction,
  validations = [],
  onValidationFailed,
  metaData,
  resizeBeforeUpload,
}: {
  file: File;
  reference: {
    type: string;
    entityId: string;
  };
  uploadFileFunction: (
    file: File,
    reference: {
      type: string;
      entityId: string;
    },
    metaData?: any,
  ) => Promise<T>;
  validations?: iUploadValidation[];
  onValidationFailed?: () => void;
  metaData?: any;
  resizeBeforeUpload?: iImageUploadResizerOptions;
}) {
  return new Promise(
    (resolve: (val: T) => any, reject: (val: string) => any) => {
      if (!file) {
        reject("File is required");
      }

      const uploadFunction = (
        file: File,
        reference: {
          type: string;
          entityId: string;
        },
        metaData?: any,
      ) => {
        if (resizeBeforeUpload) {
          // https://www.npmjs.com/package/react-image-file-resizer
          ImageResizer.createResizedImage(
            file, // Is the file of the image which will resized.
            resizeBeforeUpload.maxWidth!, // Is the maxWidth of the resized new image.
            resizeBeforeUpload.maxHeight!, // Is the maxHeight of the resized new image.
            resizeBeforeUpload.compressFormat!, // Is the compressFormat of the resized new image.
            resizeBeforeUpload.quality!, // Is the quality of the resized new image.
            resizeBeforeUpload.rotation!, // Is the degree of clockwise rotation to apply to uploaded image.
            (resizedFile) => {
              uploadFileFunction(resizedFile as File, reference, metaData)
                .then((d) => {
                  resolve(d);
                })
                .catch((e) => {
                  reject(e);
                });
            }, // Is the callBack function of the resized new image URI.
            "file", // Is the output type of the resized new image.
            resizeBeforeUpload.minWidth!, // Is the minWidth of the resized new image.
            resizeBeforeUpload.minHeight!, // Is the minHeight of the resized new image.
            resizeBeforeUpload.forceWidth,
            resizeBeforeUpload.forceHeight,
            resizeBeforeUpload.backgroundColor,
          );
        } else {
          uploadFileFunction(file, reference, metaData)
            .then((d) => {
              resolve(d);
            })
            .catch((e) => {
              reject(e);
            });
        }
      };

      const typeValidation = validations.find(
        (validation) => validation.type === "FILE_TYPE",
      );

      if (typeValidation) {
        const isAcceptedType = typeValidation.acceptedTypes.includes(file.type);
        if (!isAcceptedType) {
          const errorMessage = `You can only upload ${typeValidation.acceptedTypes.join(
            ", ",
          )} file!`;
          message.error(errorMessage);
          onValidationFailed && onValidationFailed();
          reject(errorMessage);
        }
      }

      const sizeValidation = validations.find(
        (validation) => validation.type === "FILE_SIZE",
      );

      if (sizeValidation) {
        const isLt2M = file.size / 1024 / 1024 < sizeValidation.maxSize;
        if (!isLt2M) {
          const errorMessage = `File must be smaller than ${sizeValidation.maxSize}MB!`;
          message.error(errorMessage);
          onValidationFailed && onValidationFailed();
          reject(errorMessage);
        }
      }

      const imageDimensionsValidation = validations.find(
        (validation) => validation.type === "IMAGE_DIMENSIONS",
      );

      if (imageDimensionsValidation) {
        const img = new Image();
        img.onload = () => {
          if (
            img.width < imageDimensionsValidation.minWidth ||
            img.height < imageDimensionsValidation.minHeight
          ) {
            const errorMessage = `Image dimensions should be at least ${imageDimensionsValidation.minWidth}x${imageDimensionsValidation.minHeight}.`;
            message.error(errorMessage);
            reject(errorMessage);
          } else if (
            img.width > imageDimensionsValidation.maxWidth ||
            img.height > imageDimensionsValidation.maxHeight
          ) {
            const errorMessage = `Image dimensions should not exceed ${imageDimensionsValidation.maxWidth}x${imageDimensionsValidation.maxHeight}.`;
            message.error(errorMessage);
            reject(errorMessage);
          } else {
            // If all checks pass, upload the file
            uploadFunction(file, reference, metaData);
          }
        };
        img.onerror = () => {
          message.error("Invalid image file");
          reject("Invalid image file");
          onValidationFailed && onValidationFailed();
        };
        img.src = URL.createObjectURL(file);
      } else {
        // No Image Dimension Validation, continue to upload
        uploadFunction(file, reference, metaData);
      }
    },
  );
}
