import { mdiCommentEditOutline } from "@mdi/js";
import { Form, Input, Select } from "antd";
import { CollapsibleConfigurationSection } from "components/common/collapsible-configuration-panel/collapsible-configuration-container";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { MDIIcon } from "components/common/mdi-icon";
import { StyledSwitch } from "components/common/styled-swtich";
import { useSelector } from "react-redux";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import {
  selectIndustry,
  selectOrganization,
} from "store/modules/workspace/workspace.selectors";
import { TopLanguages } from "utils/languages";
import { ChatSideBarConfig } from "../sections/inbox-settings/components/chat-side-bar-config";

export const InboxPowerSettings = () => {
  const organization = useSelector(selectOrganization);

  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  const industry = useSelector(selectIndustry);

  return (
    <>
      <ConfigurationEditorComponent
        icon={<i className="ri-notification-3-line text-3xl"></i>}
        title={"New chats will notify everyone"}
        description={"Incoming chat will be alerted to all users"}
        entityType="WORKSPACE"
      >
        <StyledSwitch
          label="Active"
          name={["appData", "HELP_DESK", "alertAllOnNewIncomingChat"]}
        />
      </ConfigurationEditorComponent>

      <ConfigurationEditorComponent
        icon={<i className="ri-alarm-warning-line text-3xl"></i>}
        title={"Alert all on unassigned chats"}
        description={"Show a visual indicator of unassigned chats"}
        entityType="WORKSPACE"
      >
        <StyledSwitch
          label="Active"
          name={["appData", "HELP_DESK", "alertEveryoneOnUnassignedChats"]}
        />
      </ConfigurationEditorComponent>

      <ConfigurationEditorComponent
        icon={<i className="ri-sword-line text-3xl"></i>}
        title={`Prevent "Join Chat" clashes`}
        description={
          "Prevent additional users joining a chat for few seconds after it has been assigned to a user"
        }
        entityType="WORKSPACE"
      >
        <StyledSwitch
          label="Active"
          name={["appData", "HELP_DESK", "preventBurstJoins"]}
          description={
            'This will also add "Join Chat" button in queued chats notifications'
          }
        />
      </ConfigurationEditorComponent>

      <ConfigurationEditorComponent
        icon={"ri-honour-line"}
        title={"Display connection context"}
        description={
          "Display connection name across chats, contacts and sessions"
        }
        entityType="WORKSPACE"
      >
        <StyledSwitch
          label="Active"
          name={["appData", "HELP_DESK", "showConnectionContext"]}
          description={"This will show the connection label next to chats"}
        />
      </ConfigurationEditorComponent>

      <ConfigurationEditorComponent
        icon={"ri-folders-line"}
        title={"Default Inboxes"}
        description={"Configure which inboxes should be visible for new users"}
        entityType="WORKSPACE"
      >
        <StyledSwitch
          label="Inboxes By Tags"
          name={[
            "appData",
            "HELP_DESK",
            "defaultInboxConfig",
            "showInboxesByTags",
          ]}
        />
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const tagsInbox = getFieldValue([
              "appData",
              "HELP_DESK",
              "defaultInboxConfig",
              "showInboxesByTags",
            ]);
            if (tagsInbox) {
              return (
                <>
                  <StyledSwitch
                    label="Group Tag Inboxes"
                    name={[
                      "appData",
                      "HELP_DESK",
                      "defaultInboxConfig",
                      "groupInboxesByTags",
                    ]}
                  />
                </>
              );
            }
            return <></>;
          }}
        </Form.Item>
        <StyledSwitch
          label="Inboxes by Connections"
          name={[
            "appData",
            "HELP_DESK",
            "defaultInboxConfig",
            "showInboxesByConnections",
          ]}
        />
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const connectionInbox = getFieldValue([
              "appData",
              "HELP_DESK",
              "defaultInboxConfig",
              "showInboxesByConnections",
            ]);
            if (connectionInbox) {
              return (
                <>
                  <StyledSwitch
                    label="Group Connection Inboxes"
                    name={[
                      "appData",
                      "HELP_DESK",
                      "defaultInboxConfig",
                      "groupInboxesByConnections",
                    ]}
                  />
                </>
              );
            }
            return <></>;
          }}
        </Form.Item>

        <StyledSwitch
          label="My Inbox by Date"
          name={[
            "appData",
            "HELP_DESK",
            "defaultInboxConfig",
            "showMyInboxByDates",
          ]}
        />
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const myInboxByDate = getFieldValue([
              "appData",
              "HELP_DESK",
              "defaultInboxConfig",
              "showMyInboxByDates",
            ]);
            if (myInboxByDate) {
              return (
                <>
                  <StyledSwitch
                    label="Group Inboxes by Date"
                    name={[
                      "appData",
                      "HELP_DESK",
                      "defaultInboxConfig",
                      "groupMyInboxByDates",
                    ]}
                  />
                </>
              );
            }
            return <></>;
          }}
        </Form.Item>

        <StyledSwitch
          label="Show Quick Inbox Add Shortcut"
          name={[
            "appData",
            "HELP_DESK",
            "defaultInboxConfig",
            "showQuickInboxAddition",
          ]}
        />

        <StyledSwitch
          label="Load All Inboxes At Start"
          name={[
            "appData",
            "HELP_DESK",
            "defaultInboxConfig",
            "loadAllInboxesAtStart",
          ]}
        />

        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const loadAllInboxAtStart = getFieldValue([
              "appData",
              "HELP_DESK",
              "defaultInboxConfig",
              "loadAllInboxesAtStart",
            ]);
            if (loadAllInboxAtStart) {
              return (
                <>
                  <StyledSwitch
                    label="Show Inbox Count"
                    name={[
                      "appData",
                      "HELP_DESK",
                      "defaultInboxConfig",
                      "showInboxCounts",
                    ]}
                  />
                </>
              );
            }
            return <></>;
          }}
        </Form.Item>
      </ConfigurationEditorComponent>

      {/* Chat Side Bar */}

      <CollapsibleConfigurationSection
        icon={"ri-layout-right-line"}
        title={"Default Chat Side Bar Setup"}
        description={
          "Configure how chat side bar should be organized for new users"
        }
      >
        <ChatSideBarConfig target="WORKSPACE" />
      </CollapsibleConfigurationSection>

      <ConfigurationEditorComponent
        icon={"ri-translate"}
        title={"Auto Translation"}
        description={
          "Configure the default language for converting incoming messages"
        }
        entityType="WORKSPACE"
      >
        <Form.Item
          label="Default Language"
          name={["appData", "HELP_DESK", "autoTranslator", "defaultLanguage"]}
        >
          <Select allowClear showSearch>
            {TopLanguages.map((lang) => (
              <Select.Option key={lang.code} value={lang.code}>
                {lang.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </ConfigurationEditorComponent>

      {isAdvancedMode && (
        <>
          <ConfigurationEditorComponent
            icon={
              <MDIIcon
                icon={mdiCommentEditOutline}
                size="2rem"
                className="mt-2"
              />
            }
            title={"AI Writing Assistant Fine Tuning"}
            description={
              "Fine tune writing assistant by pre-configuring personas"
            }
            entityType="WORKSPACE"
          >
            <Form.Item
              label="Customer Profile"
              name={[
                "appData",
                "HELP_DESK",
                "writingAssistant",
                "customerProfile",
              ]}
            >
              <Input.TextArea placeholder="Write a brief customer profile" />
            </Form.Item>
            <Form.Item
              label="Agent Profile"
              name={[
                "appData",
                "HELP_DESK",
                "writingAssistant",
                "agentProfile",
              ]}
            >
              <Input.TextArea placeholder="Write a brief agent profile" />
            </Form.Item>
          </ConfigurationEditorComponent>
        </>
      )}
    </>
  );
};
