import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";
import { iConnectorBlock } from "@sdk/chat-connectors/chat-connectors.models";
import { Collapse, Form, Input } from "antd";
import classnames from "classnames";
import React, { useCallback, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { URLBuilder } from "../url-builder";
import { iBlockBuilderState } from "./block-builder-model";

const { Panel } = Collapse;

export const SingleConfigureBlock = ({
  blockId,
  state,
  dispatch,
  index
}: {
  blockId: string;
  state: iBlockBuilderState;
  dispatch: React.Dispatch<any>;
  index: number;
}) => {
  const block = state.blocks.byIds[blockId];
  const [activeKey, setActiveKey] = useState([
    `Collection${blockId}`
  ] as string[]);

  const toggleCollapse = useCallback(() => {
    setActiveKey(activeKey.length === 0 ? [`Collection${blockId}`] : []);
  }, [activeKey.length, blockId]);

  if (!state.blocks.byIds[blockId]) {
    return <></>;
  }
  return (
    <Draggable key={blockId} draggableId={blockId} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{
            // background: snapshot.isDragging ? "lightgreen" : "grey",
            // styles we need to apply on draggables
            ...provided.draggableProps.style
          }}
        >
          <Collapse
            className="p-0 shadow-xl rounded single-editable-faq-collection mb-3"
            activeKey={activeKey}
          >
            <Panel
              header={
                <div className="flex-col justify-center items-center w-full">
                  <div
                    className="flex flex-row justify-center items-center cursor-move w-full"
                    onClick={e => e.stopPropagation()}
                    {...provided.dragHandleProps}
                  >
                    {/* Move Handle Icon */}
                    <div
                      className={classnames("w-6 flex items-center move-icon")}
                    >
                      <EllipsisOutlined className="text-2xl transform rotate-90  " />
                    </div>
                    {/* Heading */}
                    <div className="flex flex-row flex-1 items-center h-16">
                      <div className="flex-1 flex flex-col justify-center">
                        <div
                          className={
                            "font-semibold flex flex-row cursor-pointer"
                          }
                          onClick={e => {
                            e.stopPropagation();
                            toggleCollapse();
                          }}
                        >
                          <span>
                            {activeKey.length > 0 ? (
                              <i className="ri-arrow-down-s-fill pr-2"></i>
                            ) : (
                              <i className="ri-arrow-right-s-fill pr-2"></i>
                            )}
                          </span>
                          {block.type}
                        </div>
                      </div>
                    </div>
                    {/* Delete Icon */}
                    <div
                      className=" pr-5"
                      onClick={e => {
                        e.stopPropagation();
                        dispatch({
                          type: "REMOVE_BLOCK",
                          payload: {
                            blockId: blockId
                          }
                        });
                      }}
                    >
                      <i className="ri-delete-bin-line cursor-pointer"></i>
                    </div>
                  </div>
                </div>
              }
              key={`Collection${blockId}`}
              showArrow={false}
            >
              <BlockTypeConfigurations
                block={block}
                onChange={block => {
                  dispatch({
                    type: "EDIT_BLOCK",
                    payload: {
                      block
                    }
                  });
                }}
              />
            </Panel>
          </Collapse>
        </div>
      )}
    </Draggable>
  );
};

export const BlockTypeConfigurations = ({
  block,
  onChange
}: {
  block: iConnectorBlock;
  onChange: (block: iConnectorBlock) => any;
}) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={block}
      onChange={() => {
        const formValue = form.getFieldsValue();
        onChange({ ...block, ...formValue });
      }}
    >
      {(block.type === "HEADING" ||
        block.type === "TEXT" ||
        block.type === "MARKDOWN") && (
        <>
          <Form.Item
            name="label"
            label="Text"
            rules={[
              {
                required: true,
                message: "Please enter a label"
              }
            ]}
          >
            <Input.TextArea placeholder="Enter a text" />
          </Form.Item>
        </>
      )}

      {block.type === "IFRAME" && (
        <>
          <Form.Item name="url" label="">
            <URLBuilder />
          </Form.Item>
          <Form.Item name="width" label="Width">
            <Input placeholder="100%" />
          </Form.Item>
          <Form.Item name="height" label="Height">
            <Input placeholder="100%" />
          </Form.Item>
        </>
      )}

      {block.type === "SIMPLE_TEXT_DATA" && (
        <>
          <Form.Item name="url" label="">
            <URLBuilder />
          </Form.Item>
          <Form.Item
            name="textTemplate"
            label="Text Template"
            rules={[
              {
                required: true,
                message: "Please enter a Text Template"
              }
            ]}
          >
            <Input.TextArea placeholder="Enter a text" />
          </Form.Item>
        </>
      )}

      {(block.type === "SIMPLE_ARRAY_TABLE" ||
        block.type === "SIMPLE_KEY_VALUE") && (
        <>
          <Form.Item name="url" label="">
            <URLBuilder />
          </Form.Item>
        </>
      )}

      {block.type === "SIMPLE_ACTION_BUTTON" && (
        <>
          <Form.Item name="buttonLabel" label="Button Label">
            <Input placeholder="Action Button" />
          </Form.Item>
          <Form.Item name="url" label="">
            <URLBuilder />
          </Form.Item>
        </>
      )}
    </Form>
  );
};
