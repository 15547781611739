import { Button } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectConversationsCountForQuery } from "store/modules/conversations/conversations.selectors";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { selectAlertEveryoneOnUnassignedChats } from "store/modules/workspace/workspace.selectors";

export const UnassignedChatsBanner = () => {
  const currentUserId = useSelector(selectCurrentUserId);
  const unassignedCount = useSelector(
    selectConversationsCountForQuery("Unassigned"),
  );

  const alertEveryoneOnUnassignedChats = useSelector(
    selectAlertEveryoneOnUnassignedChats,
  );

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    setShowBanner(true);
  }, [unassignedCount]);

  return (
    <>
      {currentUserId &&
        Boolean(unassignedCount) &&
        alertEveryoneOnUnassignedChats &&
        showBanner && (
          <div className="outer-container absolute w-full p-4 px-24">
            <div
              className="bg-red-700 font-semibold flex md:flex-row flex-col justify-center items-center p-2 w-full absolute z-50 rounded-md text-white"
              style={{ maxWidth: "calc(100% - 12rem)" }}
            >
              <div className="font-bold">You have unassigned chats!</div>
              <Button
                type="text"
                onClick={() => setShowBanner(false)}
                className="text-white ml-2"
              >
                Dismiss
              </Button>
            </div>
          </div>
        )}
    </>
  );
};
