import { iArticle } from "@sdk/knowledge-base/knowledge-base.models";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";
import { ccArticleDefaultQueryConfig } from "./cc-article-default-query";

export const {
  selectQueryMap: selectCCArticleQueryMap,
  selectEntityMap: selectCCArticlesMap,
  selectQueryMapAll: selectCCArticlesMapAll,
  selectQueryAllList: selectCCArticlesAllList,
  selectAllEntities: selectAllCCArticles,
  selectAllEntitiesQuery: selectCCAllArticlesQuery,
  selectEntityById: selectCCArticleById,
  selectEntityQuery: selectCCArticlesQuery
} = CreateSelectorForEntities<iStore, iArticle>({
  sliceName: "ccArticles"
});

export const selectCCArticleByCollectionId = (collectionId: string) => (
  state: iStore
) =>
  selectAllCCArticles(state).filter(item =>
    item.collections.includes(collectionId)
  );

export const selectCCArticleByCollectionIdFromQuery = (
  collectionId: string
) => (state: iStore) =>
  selectCCArticlesQuery(
    JSON.stringify({
      query: { collections: collectionId },
      options: ccArticleDefaultQueryConfig.options
    })
  )(state);
