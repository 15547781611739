import { axios, AxiosResponse } from "@sdk/axios";
import { PaginatedOptions, PaginatedResults } from "../utils/paginated-results";
import { iRoutingLog } from "./routing-logs.models";

export class RoutingLogsService {
  constructor(protected config: { basePath: string; token: string }) {}

  routingLogs = {
    query: async (query: any, options: PaginatedOptions) => {
      const results: AxiosResponse<PaginatedResults<
        iRoutingLog
      >> = await axios.post(
        this.config.basePath + `/conversations/query-routing-logs`,
        { query, options },
        {
          headers: {
            Authorization: this.config.token
          }
        }
      );
      return results.data;
    }
  };
}
