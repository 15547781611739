import { axios, AxiosResponse } from "@sdk/axios";
import { WABusinessProfile } from "./whatsapp-official.controller-models";

export class WhatsappOfficialServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  whatsAppOfficial = {
    getConnectedAccounts: async (channels: string[]) => {
      const results: AxiosResponse<any[]> = await axios.post(
        this.config.basePath + `/connections/get-whatsapp-accounts`,
        { channels },
        {
          headers: {
            Authorization: this.config.token
          }
        }
      );
      return results.data;
    },
    getWABAInstanceStatus: async (connectionId: string) => {
      const results: AxiosResponse<{
        gatewayStatus: string;
        currentPhoneNumber: string;
      }> = await axios.get(
        this.config.basePath + `/connections/${connectionId}/instance-status`,
        {
          headers: {
            Authorization: this.config.token
          }
        }
      );
      return results.data;
    },
    updateAbout: async (connectionId: string, text: string) => {
      const results: AxiosResponse<{
        status: boolean;
      }> = await axios.patch(
        this.config.basePath + `/connections/${connectionId}/about`,
        { text },
        {
          headers: {
            Authorization: this.config.token
          }
        }
      );
      return results.data;
    },
    updateBusinessProfile: async (
      connectionId: string,
      data: WABusinessProfile
    ) => {
      const results: AxiosResponse<{
        status: boolean;
      }> = await axios.patch(
        this.config.basePath + `/connections/${connectionId}/business-profile`,
        data,
        {
          headers: {
            Authorization: this.config.token
          }
        }
      );
      return results.data;
    },
    updateProfilePicture: async (
      connectionId: string,
      profilePictureUrl: string
    ) => {
      const results: AxiosResponse<{
        status: boolean;
      }> = await axios.patch(
        this.config.basePath + `/connections/${connectionId}/profile-picture`,
        { url: profilePictureUrl },
        {
          headers: {
            Authorization: this.config.token
          }
        }
      );
      return results.data;
    }
  };
}
