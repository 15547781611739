import { iSpamEmail } from "@sdk/spam-emails/spam-email-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { spamEmailsDefaultQueryConfig } from "./spam-emails-default-query";

export const {
  slice: SpamEmailsSlice,
  reducers: SpamEmailsSliceReducer,
  actions: {
    setEntityQueryResults: setSpamEmailsQueryResults,
    setEntity: setSpamEmail,
    setEntities: setSpamEmails,
    patchEntity: patchSpamEmail,
    addNewEntity: addNewSpamEmail,
    setEntityFetchingStatus: setSpamEmailFetchingStatus,
    setEntitiesFetchingStatus: setSpamEmailsFetchingStatus,
    setEntityFailedStatus: setSpamEmailFailedStatus,
    setEntityQueryError: setSpamEmailsQueryError,
    resetQuery: resetSpamEmailsQuery,
    setQueryList: setSpamEmailQueryList
  }
} = CreateEntitySlice<iSpamEmail>({
  sliceName: "spamEmails",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...spamEmailsDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
