import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Divider, Popconfirm, Space, Table } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { SectionHeader } from "components/common/section-header";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { loadOrganization } from "store/modules/workspace/workspace.helpers";
import { selectNewsfeedTypes } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";

import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { iNewsFeedType } from "@sdk/newsfeed-items/newsfeed-model";
import { useMemo } from "react";
import AddEditNewsfeedType from "./add-edit-news-feed-type.modal-registry";

export const ManageNewsfeedTypes = () => {
  const { state: newsFeedTypes, retry: reload } = useQueryWithStore(
    selectNewsfeedTypes,
    loadOrganization,
  );

  const { triggerTempModal } = useModalPanels();

  const { doAction: deleteNewsfeedType } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (id: string) =>
        SDK.slaTemplates.deleteSLATemplate(id).then((d) => {
          reload();
        }),
      successMessage: "Newsfeed type has been removed",
      failureMessage: "Something went wrong",
    }),
    [reload],
  );

  const columns = useMemo(
    () => [
      {
        title: "Label",
        dataIndex: "label",
        render: (data, record: iNewsFeedType, index) => {
          return `${record.emoji || "📢"} ${record.label}`;
        },
      },
      {
        title: "",
        dataIndex: "actions",
        width: 200,
        render: (data, record, index) => (
          <Space>
            <Button
              type="link"
              icon={<i className="ri-pencil-line"></i>}
              onClick={() => {
                triggerTempModal(AddEditNewsfeedType, {
                  mode: "EDIT",
                  initialValue: record,
                });
              }}
            >
              Edit
            </Button>
            <Popconfirm
              placement="leftTop"
              title="Are you sure to delete?"
              onConfirm={async () => {
                deleteNewsfeedType(record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="link"
                shape="circle"
                icon={<i className="ri-delete-bin-line"></i>}
              ></Button>
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [deleteNewsfeedType, triggerTempModal],
  );

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin p-8 overflow-x-hidden manage-chat-bots">
      <SectionHeader
        title="Newsfeed Types"
        icon={<i className="ri-megaphone-line"></i>}
        description="Create & Manage Newsfeed Types for your organization"
        addons={
          <Space>
            <Button
              type="primary"
              // size="large"
              icon={<i className="ri-add-line"></i>}
              onClick={() => {
                triggerTempModal(AddEditNewsfeedType, {
                  mode: "ADD",
                });
              }}
            >
              New Newsfeed Type
            </Button>
          </Space>
        }
      />

      <Divider />

      <Table
        columns={columns}
        dataSource={newsFeedTypes}
        pagination={false}
        rowKey="id"
        locale={{
          emptyText: (
            <EmptyData text="No Newsfeed types to show" icon="ri-flag-line" />
          ),
        }}
      />
    </StyledScrollArea>
  );
};
