import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddSendingDomainModal = React.lazy(
  () => import("./add-sending-domain.modal"),
);

const AddSendingDomain: iModalProps<typeof AddSendingDomainModal, {}> = {
  name: "add-sending-domain",
  component: GenerateModalWithSuspense(AddSendingDomainModal),
};

export default AddSendingDomain;
