import { notification } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";

export const dismissInAppNotification = (
  notificationId: string,
  api: NotificationInstance,
) => {
  console.log("Dismiss Notification");
  // (api as any).close();
  notification.destroy(notificationId);
  // Patch for Dimmer
  // CloseNotificationvbackground();
};
