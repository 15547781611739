import { Button, Modal } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { useEffect, useMemo, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { selectAllUsers } from "store/modules/users/users.selectors";
import {
  selectAccountLimits,
  selectAccountStatus,
  selectOrganization,
} from "store/modules/workspace/workspace.selectors";
import { useLocalStorageStore } from "utils/hooks/use-local-storage-store";

export const AccountStatusBannerAndModal = () => {
  const store = useStore();
  const accountStatus = useSelector(selectAccountStatus);
  const organization = useSelector(selectOrganization);

  const [showLimitWarning, setShowLimitWarning] = useState(false);
  const [lastShownData, setLastShownDate] = useLocalStorageStore(
    "trial-modal-last-shown",
    0,
  );

  const showTrialBanner = useMemo(
    () =>
      lastShownData < Date.now() - 12 * 60 * 60 * 1000 &&
      (organization?.metaData.createdTime || 0) >
        Date.now() + 6 * 60 * 60 * 1000,
    [lastShownData, organization?.metaData.createdTime],
  );

  useEffect(() => {
    if (window.location.href.includes("hq")) {
      return;
    }
    if (accountStatus === "TRIAL") {
      if (lastShownData < Date.now() - 12 * 60 * 60 * 1000) {
        // Modal.info({
        //   title: (
        //     <ModalTitle
        //       title={"ClickConnector Trial"}
        //       icon={<i className="ri-bill-line"></i>}
        //     />
        //   ),
        //   icon: false,
        //   content: <div>Hello Worl</div>,
        //   okText: "Close",
        //   width: 800,
        // });
        // setLastShownDate(Date.now());
      }
    } else if (accountStatus === "DEACTIVATED") {
      Modal.warn({
        title: (
          <ModalTitle
            title={"YOUR ACCOUNT HAS BEEN DEACTIVATED"}
            icon={<i className="ri-lock-line"></i>}
          />
        ),
        icon: false,
        content: (
          <div className="flex flex-col justify-center items-center w-full mt-4">
            <div className="text-2xl mt-4">Your subscription has expired.</div>
            <i className="ri-delete-bin-line text-6xl"></i>
            <div className="mb-8 mt-2">
              Your data will be permanently deleted from our servers if you
              <br />
              failed to reactive your account within 60 days of deactivation.
            </div>
          </div>
        ),
        okText: "Reach out to Support",
        onOk: () => {
          (window as any).ccWidget.setWidgetVisibility(true);
        },
        okButtonProps: {
          disabled: false,
          className: "font-bold",
          icon: <i className="ri-chat-2-line"></i>,
        },
        closable: false,
        maskClosable: false,
        width: 800,
        maskStyle: { backgroundColor: "#000000de" },
      });
    } else if (accountStatus === "WARNING") {
      // Modal.info({
      //   title: (
      //     <ModalTitle
      //       title={"Your Account has not been paid for"}
      //       icon={<i className="ri-bill-line"></i>}
      //     />
      //   ),
      //   icon: false,
      //   content: <div>Hello World</div>,
      //   okText: "I understand that my account may be deleted soon",
      //   width: 800,
      // });
    }
  }, [accountStatus, lastShownData, setLastShownDate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const users = selectAllUsers(store.getState());
      const connections = selectAllConnections(store.getState());
      const limits = selectAccountLimits(store.getState());
      if (limits) {
        const activeUsers = users.filter((user) => user.metaData.isActive);
        const activeConnections = connections.filter(
          (connection) => connection.metaData.isActive,
        );
        if (
          activeUsers.length > limits.maxUserCount ||
          activeConnections.length > limits.maxConnectionCount
        ) {
          setShowLimitWarning(true);
        }
      }
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [store]);

  return (
    <>
      {accountStatus === "DEACTIVATED" && (
        <div className="bg-yellow-300 dark:bg-yellow-400 font-bold text-orange-700 flex justify-center items-center p-2">
          YOUR ACCOUNT HAS BEEN DEACTIVATED
        </div>
      )}

      {accountStatus === "WARNING" && (
        <div className="bg-yellow-300 dark:bg-yellow-400 font-bold text-orange-700 flex justify-center items-center p-2">
          It looks like there is a billing issue in your account. Please update
          your billing details.
        </div>
      )}

      {showLimitWarning && (
        <div className="bg-yellow-300 dark:bg-yellow-400 font-bold text-orange-700 flex justify-center items-center p-2">
          It looks like your account has exceeded either user or connection
          limits. Please contact your Account Manager
        </div>
      )}
      {accountStatus === "TRIAL" && showTrialBanner && (
        <div className="bg-yellow-200 dark:bg-yellow-400 font-bold text-orange-800 flex justify-center items-center p-2">
          You are using ClickConnector Trial
          <Button
            type="text"
            icon={<i className="ri-rest-time-line"></i>}
            onClick={() => setLastShownDate(Date.now())}
            className="dark:text-orange-800"
          >
            Dismiss
          </Button>
        </div>
      )}
    </>
  );
};
