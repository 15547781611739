import { SDK } from "@sdk";
import { iAutomationWorkflow } from "@sdk/automation-workflows/automation-workflows-model";

import { Store } from "redux";
import { QueryConfig } from "store/utils/query-config";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { automationWorkflowsDefaultQueryConfig } from "./automation-workflows-default-query";
import { AutomationWorkflowsSlice } from "./automation-workflows.slice";

export const loadAllAutomationWorkflows = () => async (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  await loadAutomationWorkflowsQuery(
    automationWorkflowsDefaultQueryConfig,
    "all"
  )(store, forceReload);
};

export const generateAutomationWorkflowOfContactQuery = (
  contactId: string
) => ({
  ...automationWorkflowsDefaultQueryConfig,
  query: {
    contactId
  }
});

export const loadAutomationWorkflowOfContact = (contactId: string) => async (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  await loadAutomationWorkflowsQuery(
    generateAutomationWorkflowOfContactQuery(contactId),
    JSON.stringify(generateAutomationWorkflowOfContactQuery(contactId))
  )(store, forceReload);
};

export const loadQueryAutomationWorkflows = (
  queryConfig: QueryConfig,
  queryAlias?: string
) => async (store: Store<iStore>, forceReload?: boolean) => {
  queryAlias = queryAlias || JSON.stringify(queryConfig);
  await loadAutomationWorkflowsQuery(queryConfig, queryAlias)(
    store,
    forceReload
  );
};

export const {
  loadEntityQueries: loadAutomationWorkflowsQuery,
  loadEntityById: loadAutomationWorkflowById,
  loadMoreEntityQueries: loadMoreAutomationWorkflowsQueries
} = CreateHelpersForEntity<iStore, iAutomationWorkflow>({
  sliceName: "automationWorkflows",
  slice: AutomationWorkflowsSlice,
  isPaginatedQueries: true,
  fetchPageSize: 50,
  loadEntityQueryProvider: query => SDK.automationWorkflows.query(query),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.automationWorkflows.getById(entityId)
});
