import { iKnowledgeDocument } from "@sdk/knowledge-documents/knowledge-documents-model";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectKnowledgeDocumentQueryMap,
  selectEntityMap: selectKnowledgeDocumentsMap,
  selectQueryMapAll: selectKnowledgeDocumentsMapAll,
  selectQueryAllList: selectKnowledgeDocumentsAllList,
  selectAllEntities: selectAllKnowledgeDocuments,
  selectAllEntitiesQuery: selectAllKnowledgeDocumentsQuery,
  selectEntityById: selectKnowledgeDocumentById,
  selectEntityQuery: selectKnowledgeDocumentQuery,
  selectEntityQueryList: selectKnowledgeDocumentQueryList
} = CreateSelectorForEntities<iStore, iKnowledgeDocument>({
  sliceName: "knowledgeDocuments"
});

export const selectKnowledgeDocumentByConnectionId = (connectionId: string) => (
  state: iStore
) => {
  return _.find(selectAllKnowledgeDocuments(state), {
    connectionId
  });
};
