export const CollapsibleHeader = ({
  title,
  description,
  icon,
  help,
}: {
  title: string;
  description: string | JSX.Element;
  icon: JSX.Element;
  help?: JSX.Element;
}) => (
  <div className="flex flex-row">
    <div className="icon">{icon}</div>
    <div className="texts flex-col pl-4 flex-1">
      <div className="header font-bold">{title}</div>
      <div className="header text-gray-600 dark:text-gray-400 mode_transition">
        {description}
      </div>
    </div>
    {help ? <div className="self-end">{help}</div> : ""}
  </div>
);
