import { Button } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useThrottle } from "react-use";
import { selectSocketStatus } from "store/modules/app-state/app-state.selectors";
import {
  selectCurrentUserDisabledFromJoiningChats,
  selectCurrentUserId
} from "store/modules/users/users.selectors";

export const SocketDisconnectedBanner = () => {
  const currentUserId = useSelector(selectCurrentUserId);

  // throttledCurrentUserId to detect if currentUserId is recently changed
  const throttledCurrentUserId = useThrottle(currentUserId, 2000);

  const isCurrentUserDisabledFromJoiningChats = useSelector(
    selectCurrentUserDisabledFromJoiningChats
  );

  const isSocketConnected = useSelector(selectSocketStatus);

  const [isAppReady, setAppReady] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setAppReady(true);
    }, 10000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const showBanner =
    currentUserId && !isSocketConnected && isAppReady && throttledCurrentUserId;

  return (
    <>
      {showBanner && (
        <div className="bg-red-700 font-bold text-white flex justify-center items-center p-2">
          Connection is not active. Trying to reconnect...
          <Button
            type="text"
            icon={<i className="ri-router-line"></i>}
            className="text-white"
            target="_blank"
            href="https://click-connector.betteruptime.com"
          >
            Check Status
          </Button>
          {/* <Button
            type="text"
            icon={<i className="ri-information-line"></i>}
            className="text-white"
          >
            Learn More
          </Button> */}
        </div>
      )}
    </>
  );
};
