import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ConfigureDynamicFormModal = React.lazy(
  () => import("./configure-dynamic-form.modal"),
);

const ManageForm: iModalProps<
  typeof ConfigureDynamicFormModal,
  { dynamicFormId: string }
> = {
  name: "manage-form",
  component: GenerateModalWithSuspense(ConfigureDynamicFormModal),
};

export default ManageForm;
