import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const BulkAddContactsModal = React.lazy(
  () => import("./bulk-add-contacts.modal"),
);

const BulkAddContacts: iModalProps<typeof BulkAddContactsModal> = {
  name: "bulk-add-contacts",
  component: GenerateModalWithSuspense(BulkAddContactsModal),
};

export default BulkAddContacts;
