import { iConnection } from "@sdk/conversations/conversations.models";
import { iFileRecord } from "@sdk/file-management/file-management.models";
import { Button, Dropdown, Form, Input } from "antd";
import { Rule } from "antd/lib/form";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { ImageInputWithUploader } from "components/modules/file-management/image-input-with-uploader";
import {
  iImageUploadResizerOptions,
  iUploadValidation,
} from "components/modules/file-management/upload-validation-model";
import { LogoUploadValidations } from "components/modules/file-management/upload-validations";
import { useEffect, useState } from "react";
import { BlockPicker } from "react-color";
import { defaultWidgetColors } from "../../../chat-widget/components/configure-chat-widget/default-color-selections";

export const ColorPickerFormItem = ({
  fieldName,
  label,
}: {
  fieldName: string | string[];
  label: string;
}) => {
  return (
    <Form.Item shouldUpdate>
      {({ getFieldValue }) => {
        const fieldValue = getFieldValue(fieldName);
        return (
          <Form.Item
            name={fieldName}
            label={label}
            rules={[
              {
                pattern: new RegExp("^#(?:[0-9a-fA-F]{3,4}){1,2}$"),
                message: "Please input a valid Hex-Color code",
              },
            ]}
          >
            <Input
              suffix={
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <SimpleCardSection>
                      <Form.Item
                        name={fieldName}
                        valuePropName="color"
                        getValueFromEvent={(e) => e.hex}
                      >
                        <BlockPicker
                          colors={defaultWidgetColors}
                          width={"300px"}
                          styles={{
                            default: {
                              card: {},
                            },
                          }}
                          triangle="hide"
                        />
                      </Form.Item>
                    </SimpleCardSection>
                  }
                >
                  <Button style={{ background: fieldValue }}> </Button>
                </Dropdown>
              }
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export const ImageUploaderFormItem = ({
  fieldName,
  label,
  entityType,
  entityId,
  rules,
  allowClear,
  useFilePicker,
  validations,
  resizeBeforeUpload,
  customRecentUploadQuery,
  filePickerPresetRenderer,
  pickButtonLabel,
  imageClassName,
}: {
  fieldName: string | string[];
  label: string | JSX.Element;
  entityType: string;
  entityId: string;
  rules?: Rule[];
  allowClear?: boolean;
  useFilePicker?: boolean;
  validations?: iUploadValidation[];
  resizeBeforeUpload?: iImageUploadResizerOptions;
  customRecentUploadQuery?: any;
  filePickerPresetRenderer?: (data: {
    onFileSelection: (file: iFileRecord) => void;
    selectedItems: string[];
  }) => JSX.Element;
  pickButtonLabel?: string;
  imageClassName?: string;
}) => {
  return (
    <Form.Item shouldUpdate>
      {({ getFieldValue }) => {
        const fieldValue = getFieldValue(fieldName);
        return (
          <div className="flex flew-row justify-between p-4 border-2 border-dashed border-gray-400 dark:border-gray-700 rounded-lg">
            <div className="label">{label}</div>
            <Form.Item name={fieldName} noStyle rules={rules}>
              <ImageInputWithUploader
                type={entityType}
                entityId={entityId}
                width={200}
                height={120}
                allowClear={allowClear}
                useFilePicker={useFilePicker}
                validations={validations}
                resizeBeforeUpload={resizeBeforeUpload}
                customRecentUploadQuery={customRecentUploadQuery}
                filePickerPresetRenderer={filePickerPresetRenderer}
                pickButtonLabel={pickButtonLabel}
                imageClassName={imageClassName}
              />
            </Form.Item>
          </div>
        );
      }}
    </Form.Item>
  );
};

export const EmailTemplate = ({
  connectionId,
  connection,
  editConnectionById,
}: {
  connectionId: string;
  connection: iConnection;
  editConnectionById: any;
}) => {
  const [themeColor, setThemeColor] = useState("");
  useEffect(() => {
    setThemeColor(connection.data.primaryColor);
  }, [connection.data.primaryColor]);
  return (
    <div style={{ minWidth: 600 }}>
      <ConfigurationEditorComponent
        title="Configure Email Templates"
        description="configure Email Templates"
        icon={"ri-drop-fill"}
        entityType="CONNECTION"
        entityId={connectionId}
      >
        <ColorPickerFormItem
          label="Theme Color"
          fieldName={["data", "primaryColor"]}
        />
        <ImageUploaderFormItem
          label="Logo"
          fieldName={["data", "logo"]}
          entityType="EMAIL"
          entityId={connectionId}
          validations={LogoUploadValidations}
        />
      </ConfigurationEditorComponent>
    </div>
  );
};
