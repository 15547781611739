import { flatten } from "lodash";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { DeepPartial } from "redux";
import { loadAllChatWidgets } from "store/modules/chat-widgets/chat-widgets.helpers";
import { selectChatWidgetByConnectionId } from "store/modules/chat-widgets/chat-widgets.selectors";
import { selectAllUsers } from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { defaultWidgetPreviewData } from "./widget-preivew/default-widget-data";
import { WidgetHomePreview } from "./widget-preivew/widget-home-preview";

export const WidgetPreview = forwardRef(
  ({ connectionId }: { connectionId: string }, ref) => {
    const { state: widget } = useQueryWithStore(
      selectChatWidgetByConnectionId(connectionId),
      loadAllChatWidgets(),
      [connectionId],
    );

    const [widgetColor, setWidgetColor] = useState(
      widget?.configurations?.appearance.colorPallet.primary || "#0466ff",
    );

    const [headerPattern, setHeaderPattern] = useState(
      widget?.configurations?.appearance.headerPattern,
    );

    useEffect(() => {
      setWidgetColor(widget?.configurations?.appearance.colorPallet.primary!);
      setHeaderPattern(widget?.configurations?.appearance.headerPattern!);
    }, [
      widget?.configurations?.appearance.colorPallet.primary,
      widget?.configurations?.appearance.headerPattern,
    ]);

    const allUsers = useSelector(selectAllUsers);

    const widgetPreviewConfig = useMemo(() => {
      const faqCollections = widget?.configurations.faqCollections;
      const faqs = flatten(
        (faqCollections || []).map((collection) =>
          collection.faqList.map((question) => ({
            QUESTION: question.question,
          })),
        ),
      );
      return {
        HOME_HEADER: {
          WELCOME_TEXT:
            widget?.configurations.locale?.custom?.GREETINGS_MESSAGE ||
            defaultWidgetPreviewData.HOME_HEADER.WELCOME_TEXT,
          HEADER_TAGLINE:
            widget?.configurations.locale?.custom?.GUIDE_MESSAGE ||
            defaultWidgetPreviewData.HOME_HEADER.HEADER_TAGLINE,
          AVATARS_IMAGES: allUsers.map((user) => user.data.avatar),
          TOTAL_USERS: allUsers.length,
          HEADER_PATTERN:
            headerPattern || widget?.configurations.appearance?.headerPattern,
        },
        FAQ: {
          COLLECTIONS: widget?.configurations.faqCollections,
        },
      } as DeepPartial<typeof defaultWidgetPreviewData>;
    }, [
      allUsers,
      headerPattern,
      widget?.configurations.appearance?.headerPattern,
      widget?.configurations.faqCollections,
      widget?.configurations.locale?.custom?.GREETINGS_MESSAGE,
      widget?.configurations.locale?.custom?.GUIDE_MESSAGE,
    ]);

    useImperativeHandle(
      ref,
      () => ({
        setHeaderPattern,
        setWidgetColor,
      }),
      [setHeaderPattern, setWidgetColor],
    );

    return (
      <div
        className="chat-widget-container"
        // cursor-not-allowed
        style={{
          width: "28rem",
          height: "47rem",
          overflow: "hidden",
          borderRadius: "10px 10px 0 0",
          // boxShadow: "0 0 20px #00000038",
        }}
      >
        <div
          className="text-black relative"
          style={{ width: 420, height: "47rem" }}
        >
          <WidgetHomePreview
            color={widgetColor}
            configData={widgetPreviewConfig}
          />
          {/* <div className="absolute w-full h-full z-10 top-0 left-0"></div> */}
        </div>
      </div>
    );
  },
);
