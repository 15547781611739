import { iAutomationWorkflow } from "@sdk/automation-workflows/automation-workflows-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { automationWorkflowsDefaultQueryConfig } from "./automation-workflows-default-query";

export const {
  slice: AutomationWorkflowsSlice,
  reducers: AutomationWorkflowsSliceReducer,
  actions: {
    setEntityQueryResults: setAutomationWorkflowsQueryResults,
    setEntity: setAutomationWorkflow,
    setEntities: setAutomationWorkflows,
    patchEntity: patchAutomationWorkflow,
    addNewEntity: addNewAutomationWorkflow,
    setEntityFetchingStatus: setAutomationWorkflowFetchingStatus,
    setEntitiesFetchingStatus: setAutomationWorkflowsFetchingStatus,
    setEntityFailedStatus: setAutomationWorkflowFailedStatus,
    setEntityQueryError: setAutomationWorkflowsQueryError,
    resetQuery: resetAutomationWorkflowsQuery,
    setQueryList: setAutomationWorkflowQueryList
  }
} = CreateEntitySlice<iAutomationWorkflow>({
  sliceName: "automationWorkflows",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...automationWorkflowsDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
