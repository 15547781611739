import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";
import { iProductTourIdea } from "../helpers/generate-tour-data";

const TourTemplatePreviewModal = React.lazy(
  () => import("./tour-template-preview.modal"),
);

const TourTemplatePreview: iModalProps<
  typeof TourTemplatePreviewModal,
  {
    template: iProductTourIdea;
    changeParentModalVisibility?: (state: boolean) => any;
  }
> = {
  name: "tour-template-preview",
  component: GenerateModalWithSuspense(TourTemplatePreviewModal),
};

export default TourTemplatePreview;
