export enum ApplicationMode {
  "WINDOW" = "WINDOW",
  "TRAY" = "TRAY",
}

export interface iNativeMenuItem {
  type?: "separator" | "radio" | "checkbox";
  label: string;
  checked?: boolean;
  role?: string;
  click?: () => any;
}
