import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iSelfServicePortal } from "@sdk/knowledge-base/knowledge-base.models";
import React from "react";

const AddSelfServicePortalModal = React.lazy(
  () => import("./add-self-service-portals.modal"),
);

const AddSelfServicePortal: iModalProps<
  typeof AddSelfServicePortalModal,
  {
    onCreated?: (portal: iSelfServicePortal) => any;
  }
> = {
  name: "add-kb-portal",
  component: GenerateModalWithSuspense(AddSelfServicePortalModal),
};

export default AddSelfServicePortal;
