import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const BlockedUsersModal = React.lazy(
  () => import("./blocked-facebook-users.modal"),
);

const FBBlockedUsers: iModalProps<
  typeof BlockedUsersModal,
  {
    connectionId: string;
  }
> = {
  name: "fb-blocked-users",
  component: GenerateModalWithSuspense(BlockedUsersModal),
};

export default FBBlockedUsers;
