import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";

import React from "react";

const SeedKBWizardModal = React.lazy(() => import("./seed-kb-wizard.modal"));

const SeedKBWizard: iModalProps<typeof SeedKBWizardModal, {}> = {
  name: "seed-kb-wizard",
  component: GenerateModalWithSuspense(SeedKBWizardModal),
};

export default SeedKBWizard;
