export interface ValidatedMobileNumber {
  original: string;
  isValid: boolean;
  isValidMobileNumber: boolean;
  formatted: string;
  mobileId: string;
}

export const cleanMobileNumber = async (
  phone: string,
  countryCode?: string,
): Promise<ValidatedMobileNumber> => {
  const GooglePhoneNumberLib = await import("google-libphonenumber");
  const phoneUtil = GooglePhoneNumberLib.PhoneNumberUtil.getInstance();
  try {
    const number = phoneUtil.parse(phone, countryCode);
    return {
      original: phone,
      isValid: phoneUtil.isValidNumber(number),
      isValidMobileNumber:
        phoneUtil.isValidNumber(number) &&
        (phoneUtil.getNumberType(number) ===
          GooglePhoneNumberLib.PhoneNumberType.MOBILE ||
          phoneUtil.getNumberType(number) ===
            GooglePhoneNumberLib.PhoneNumberType.FIXED_LINE_OR_MOBILE),
      formatted: phoneUtil.format(
        number,
        GooglePhoneNumberLib.PhoneNumberFormat.INTERNATIONAL,
      ),
      mobileId: phoneUtil.format(
        number,
        GooglePhoneNumberLib.PhoneNumberFormat.E164,
      ),
    };
  } catch (e) {
    return {
      original: phone,
      isValid: false,
      isValidMobileNumber: false,
      formatted: phone,
      mobileId: getMobileId(phone),
    };
  }
};

export const getMobileId = (mobile: string = "", countryCode?: string) => {
  // Remove all non-numerical characters using regular expression
  // Returns in PhoneNumberFormat.E16 eg: +1234567890
  if (!mobile) {
    return "";
  }
  const cleanInput = mobile.replace(/[^0-9]/g, "");
  // Ensure the cleanInput starts with '+'
  if (!cleanInput.startsWith("+")) {
    if (countryCode) {
      return `${countryCode}${cleanInput}`;
    }
    return `+${cleanInput}`;
  }
  return cleanInput;
};
