import { iCallLog } from "@sdk/call-logs/call-logs-model";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";
import { generateCallLogOfContactQuery } from "./call-logs.helpers";

export const {
  selectQueryMap: selectCallLogQueryMap,
  selectEntityMap: selectCallLogsMap,
  selectQueryMapAll: selectCallLogsMapAll,
  selectQueryAllList: selectCallLogsAllList,
  selectAllEntities: selectAllCallLogs,
  selectAllEntitiesQuery: selectAllCallLogsQuery,
  selectEntityById: selectCallLogById,
  selectEntityQuery: selectCallLogQuery,
  selectEntityQueryList: selectCallLogQueryList
} = CreateSelectorForEntities<iStore, iCallLog>({
  sliceName: "callLogs"
});

export const selectCallLogByConnectionId = (connectionId: string) => (
  state: iStore
) => {
  return _.find(selectAllCallLogs(state), {
    connectionId
  });
};

export const selectCallLogsByContactId = (contactId: string) => {
  return selectCallLogQuery(
    JSON.stringify(generateCallLogOfContactQuery(contactId))
  );
};
