import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const NotionDatabaseBrowserModal = React.lazy(
  () => import("./notion-db-browser.modal"),
);

const NotionDatabaseBrowser: iModalProps<
  typeof NotionDatabaseBrowserModal,
  {
    onSelected?: (pageId: string) => any;
  }
> = {
  name: "notion-db-browser",
  component: GenerateModalWithSuspense(NotionDatabaseBrowserModal),
};

export default NotionDatabaseBrowser;
