import { GlobalConfig } from "config";
import { io, Socket } from "socket.io-client";
import { UserTracker } from "user-tracker";
const ENV = {
  SOCKET_SERVER: GlobalConfig.SOCKET_SERVER,
};

export class SocketConnector {
  static uuid = new Date().getTime().toString(); // To Identify each browser session
  static socket: Socket<any, any>;
  static connectionLogs: {
    message: string;
    time: string;
    isOnline: boolean;
  }[] = [];
  static lastToken: string;
  static lastDisconnectTime: number = 0;

  static connect(auth: string) {
    // The following condition is added to support HMR
    if (
      this.lastToken !== auth &&
      (!this.socket || (this.socket && this.socket.disconnected))
    ) {
      const socketConnectionQuery = `namespace=user&accessToken=${auth}&uuid=${this.uuid}`;
      const autoConnect = true;
      if (SocketConnector.socket) {
        SocketConnector.socket.disconnect();
      }
      const socket = io(ENV.SOCKET_SERVER, {
        query: {
          namespace: "user",
          accessToken: auth,
          uuid: this.uuid,
        },
        secure: window.location.protocol === "https:",
        autoConnect,
        transports: ["websocket"],
        rememberUpgrade: true,
        upgrade: false,
        reconnectionDelayMax: 2000,
        transportOptions: {
          pingInterval: 25000, // default - 25000
          pingTimeout: 60000, // default - 60000
        },
      });

      SocketConnector.socket = socket;
      this.patchSocketListener();
      this.onConnected();
    }
  }

  static disconnect() {
    if (SocketConnector.socket) {
      SocketConnector.socket.disconnect();
    }
  }

  private static patchSocketListener() {
    // Updated solution for socket.io-client 1.3.7
    // https://stackoverflow.com/questions/10405070/socket-io-client-respond-to-all-events-with-one-handler=
    const onevent = (this.socket as any).onevent;
    (this.socket as any).onevent = function (packet) {
      const args = packet.data || [];
      onevent.call(this, packet); // original call
      packet.data = ["*"].concat(args);
      onevent.call(this, packet); // additional call to catch-all
    };
  }

  private static onConnected() {
    const socket = SocketConnector.socket;

    socket.on("connect", () => {
      // performance.navigation.type == performance.navigation.TYPE_RELOAD
      console.log("SC: Connected");
      this.connectionLogs.push({
        message: "Connected",
        time: new Date().toString(),
        isOnline: window.navigator.onLine,
      });

      UserTracker.track("Socket Connected");
    });

    socket.on("reconnect", () => {
      console.log("SC: Reconnected");
      this.connectionLogs.push({
        message: "Reconnected",
        time: new Date().toString(),
        isOnline: window.navigator.onLine,
      });
      // If it grater than 15 minutes, reload the page
      if (
        this.lastDisconnectTime &&
        this.lastDisconnectTime + 15 * 60 * 1000 < new Date().getTime()
      ) {
        window.location.reload();
        return;
      }
      UserTracker.track("Socket Re-Connected");
    });

    socket.on("disconnect", (reason) => {
      // Todo: Not sure at what time disconnected gets logged when pc goes to sleep
      // Probably need to have a timer that logs the last active time do the location reload logic above
      console.log("SC: Disconnected", reason);
      this.connectionLogs.push({
        message: "Disconnected",
        time: new Date().toString(),
        isOnline: window.navigator.onLine,
      });

      this.lastDisconnectTime = new Date().getTime();
      UserTracker.track("Socket Disconnected");
    });

    socket.on("KNOCK_KNOCK", () => {
      console.log("KNOCK_KNOCK");
      socket.emit("YES_YES");
    });

    socket.on("RELOAD", () => {
      console.log("RELOAD");
      window.location.reload();
    });
  }

  static req(event: string, data) {
    // Todo: Got to re-check this.
    // The idea is to make a request to socket server and return the response through await interface
    return new Promise((resolve, reject) => {
      const maxTimeout = 5000;
      const timer = setTimeout(() => {
        throw new Error("Max Timeout Exceeded");
      }, maxTimeout);
      this.socket.emit(event, data, (response: any) => {
        clearTimeout(timer);
        resolve(response);
      });
    });
  }
}
