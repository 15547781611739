import { iConnectionUserLimit } from "@sdk/conversations/conversations.models";
import { Form, Input, message, Modal, Select } from "antd";
import { ModalTitle } from "components/common/modal-title";
import { UserGroupSelector } from "components/modules/user-management/user-groups/user-group-selector/user-group-selector";
import { SingleUserSelector } from "components/modules/user-management/users/components/single-user-selector/single-user-selector";
import { DarkModeBg } from "dark-mode-bg";
import { useEffect } from "react";
import { uuidv4 } from "utils/generate-uuid";

export const ConnectionLimitEditorModal = ({
  visible,
  mode,
  onChangeVisibility,
  onSaved,
  initialValue,
}: {
  visible: boolean;
  mode: "ADD" | "EDIT";
  onChangeVisibility: (state: boolean) => any;
  onSaved: (value: iConnectionUserLimit) => any;
  initialValue?: iConnectionUserLimit;
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...initialValue,
    });
  }, [visible, mode, initialValue, form]);

  return (
    <Modal
      title={
        mode === "ADD" ? (
          <ModalTitle
            icon={<i className="ri-stop-circle-line"></i>}
            title="Add Limit"
          />
        ) : (
          <ModalTitle
            icon={<i className="ri-pencil-line"></i>}
            title="Edit Limit"
          />
        )
      }
      open={visible}
      onOk={async () => {
        try {
          await form.validateFields();
          const formValues = form.getFieldsValue();
          console.log("formValues", formValues);
          onSaved({
            id: initialValue?.id || uuidv4(),
            ...formValues,
          });
        } catch (e) {
          message.error("Please check your input");
        }
      }}
      onCancel={() => {
        onChangeVisibility(false);
      }}
      okText="Save"
      okButtonProps={{ className: "font-bold" }}
      data-click-context="Connection Limit Editor Modal"
      width={500}
    >
      <Form
        form={form}
        layout="horizontal"
        name="form_in_modal"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
          limitType: "CONVERSATIONS_JOIN",
          targetType: "ALL",
          limitPeriod: "DAILY",
          limit: 25,
        }}
        colon={false}
        className="bold-form-labels"
        requiredMark={false}
      >
        <div className="flex flex-row justify-center items-center w-full">
          <Form.Item name="limitType" label="Limit Type" className="w-full">
            <Select placeholder="Select Type">
              <Select.Option value={"CONVERSATIONS_JOIN"}>
                New Conversation Joins
              </Select.Option>
              <Select.Option value={"PRIVATE_REPLY"}>
                New Comment Private Replies
              </Select.Option>
            </Select>
            {/* <Radio.Group
              defaultValue="CONVERSATIONS_JOIN"
              buttonStyle="solid"
              className="w-full"
            >
              <Radio.Button
                value="CONVERSATIONS_JOIN"
                style={{ width: "50%", textAlign: "center" }}
              >
                New Conversation Joins
              </Radio.Button>
              <Radio.Button
                value="PRIVATE_REPLY"
                style={{ width: "50%", textAlign: "center" }}
              >
                New Comment Private Replies
              </Radio.Button>
            </Radio.Group> */}
          </Form.Item>
        </div>

        <div className="flex flex-row justify-center items-center w-full">
          <Form.Item name="targetType" label="Limit Target" className="w-full">
            <Select placeholder="Select Target">
              <Select.Option value={"USER"}>User</Select.Option>
              <Select.Option value={"USER_GROUP"}>User Group</Select.Option>
              <Select.Option value={"ALL"}>All</Select.Option>
            </Select>
            {/*             
            <Radio.Group
              defaultValue="ALL"
              buttonStyle="solid"
              className="w-full"
            >
              <Radio.Button
                value="USER"
                style={{ width: 200, textAlign: "center" }}
              >
                User
              </Radio.Button>
              <Radio.Button
                value="USER_GROUP"
                style={{ width: 200, textAlign: "center" }}
              >
                User Group
              </Radio.Button>
              <Radio.Button
                value="ALL"
                style={{ width: 200, textAlign: "center" }}
              >
                All
              </Radio.Button>
            </Radio.Group> */}
          </Form.Item>
        </div>

        <Form.Item shouldUpdate noStyle>
          {() => {
            const targetType = form.getFieldValue(["targetType"]);
            if (targetType === "USER") {
              return (
                <div className="">
                  <Form.Item
                    name="value"
                    label="User"
                    rules={[
                      {
                        required: true,
                        message: "Please select a user",
                      },
                    ]}
                  >
                    <SingleUserSelector />
                  </Form.Item>
                </div>
              );
            } else if (targetType === "USER_GROUP") {
              return (
                <div className="">
                  <Form.Item
                    name="value"
                    label="User Group"
                    rules={[
                      {
                        required: true,
                        message: "Please select a user group",
                      },
                    ]}
                  >
                    <UserGroupSelector size="middle" />
                  </Form.Item>
                </div>
              );
            }
            return <></>;
          }}
        </Form.Item>

        <div className="flex flex-row justify-center items-center w-full">
          <Form.Item name="limitPeriod" label="Limit Period" className="w-full">
            <Select placeholder="Select Typ">
              <Select.Option value={"DAILY"}>Daily</Select.Option>
              <Select.Option value={"WEEKLY"}>Weekly</Select.Option>
              <Select.Option value={"MONTHLY"}>Monthly</Select.Option>
            </Select>
            {/*             
            <Radio.Group
              defaultValue="DAILY"
              buttonStyle="solid"
              className="w-full"
            >
              <Radio.Button
                value="DAILY"
                style={{ width: 200, textAlign: "center" }}
              >
                Daily
              </Radio.Button>
              <Radio.Button
                value="WEEKLY"
                style={{ width: 200, textAlign: "center" }}
              >
                Weekly
              </Radio.Button>
              <Radio.Button
                value="MONTHLY"
                style={{ width: 200, textAlign: "center" }}
              >
                Monthly
              </Radio.Button>
            </Radio.Group> */}
          </Form.Item>
        </div>

        <Form.Item
          label="Limit"
          name="limit"
          rules={[
            {
              required: true,
              message: "Please enter a value",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
      </Form>
      <DarkModeBg />
    </Modal>
  );
};
