import { iScreenTour } from "@sdk/screen-tours/screen-tours-model";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectScreenTourQueryMap,
  selectEntityMap: selectScreenToursMap,
  selectQueryMapAll: selectScreenToursMapAll,
  selectQueryAllList: selectScreenToursAllList,
  selectAllEntities: selectAllScreenTours,
  selectAllEntitiesQuery: selectAllScreenToursQuery,
  selectEntityById: selectScreenTourById,
  selectEntityQuery: selectScreenTourQuery,
  selectEntityQueryList: selectScreenTourQueryList,
} = CreateSelectorForEntities<iStore, iScreenTour>({
  sliceName: "screenTours",
});

export const selectScreenTourByConnectionId =
  (connectionId: string) => (state: iStore) => {
    return _.find(selectAllScreenTours(state), {
      connectionId,
    });
  };
