import { iNotificationRecord } from "@sdk/notification-inbox/notifications.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { notificationsDefaultQueryConfig } from "./notifications-default-query";

export const {
  slice: NotificationsSlice,
  reducers: NotificationsSliceReducer,
  actions: {
    setEntityQueryResults: setNotificationsQueryResults,
    setEntity: setNotification,
    setEntities: setNotifications,
    patchEntity: patchNotification,
    addNewEntity: addNewNotification,
    setEntityFetchingStatus: setNotificationFetchingStatus,
    setEntitiesFetchingStatus: setNotificationsFetchingStatus,
    setEntityFailedStatus: setNotificationFailedStatus,
    setEntityQueryError: setNotificationsQueryError,
    resetQuery: resetNotificationsQuery
  }
} = CreateEntitySlice<iNotificationRecord>({
  sliceName: "notifications",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...notificationsDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
