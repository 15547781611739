import { axios, AxiosResponse } from "@sdk/axios";
import { SDKConfig } from "@sdk/default-sdk-config";
import { iOrganization } from "@sdk/user-management/user-management.models";

import { UserTracker } from "user-tracker";

export class SLARulesServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.slaRules.config = config;
  }

  slaRules = Object.assign(
    { config: SDKConfig },
    {
      activateSLA: async (req: {
        conversationId: string;
        slaTemplateId: string;
      }) => {
        UserTracker.track("SLA_RULES - Add Activate SLA", {});
        const results: AxiosResponse<iOrganization> = await axios.post(
          this.config.basePath + `/sla-rules/activate-sla`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      cancelSLA: async (req: { conversationId: string }) => {
        UserTracker.track("SLA_RULES - cancel-sla", {});
        const results: AxiosResponse<iOrganization> = await axios.post(
          this.config.basePath + `/sla-rules/cancel-sla`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      dismissNextSLAWarning: async (req: { conversationId: string }) => {
        UserTracker.track("SLA_RULES - dismiss-sla-warning", {});
        const results: AxiosResponse<iOrganization> = await axios.post(
          this.config.basePath + `/sla-rules/dismiss-sla-warning`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
