import { useSDK } from "@sdk";
import { iWACloudConnectionData } from "@sdk/conversations/conversations.models";
import { Alert, Descriptions, Divider, Spin } from "antd";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import { SectionHeader } from "components/common/section-header";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const ManageWACloudConnection = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const wabaConnectionData = connection?.data as iWACloudConnectionData;

  const {
    data: connectionStatus,
    isLoading,
    error,
    reload: reloadDebug,
  } = useSDK(
    (SDK) =>
      SDK.connections.checkWhatsappOfficialAuthenticationStatus(connectionId),
    [connectionId],
    !connectionId,
  );

  return (
    <Spin
      spinning={!error && isLoading}
      indicator={<LoadingIndicatorWithoutSpin />}
      wrapperClassName="flex-1 flex fill-spinner"
    >
      <div className="animated fadeInLeftMin  flex flex-col w-full">
        <SectionHeader
          title="Connected Account"
          icon={<i className="ri-whatsapp-line"></i>}
          description="Channel, Conversation & Posts Integration"
        />
        <Divider />
        {/* <div className="flex flex-row w-full justify-end items-center mb-4">
          <Space>
            <Button
              type="link"
              loading={isConversationSyncing}
              onClick={() => {
                syncConversations(connectionId);
              }}
              icon={<i className="ri-refresh-line"></i>}
            >
              (Re)Sync Conversations
            </Button>
          </Space>
        </div> */}

        <Alert
          message={
            <div>
              Your Whatsapp Webhook URL:
              https://engine.clickconnector.app/api/v1/whatsapp-cloud/webhook?connectionId=
              {connectionId} <br />
              Your Verify Token: {wabaConnectionData?.webhookVerifyToken}
            </div>
          }
          type="info"
          showIcon
          className="mb-2"
        />

        <Descriptions
          title="Integration Details"
          bordered
          layout="horizontal"
          column={1}
          style={{ maxWidth: 600 }}
        >
          <Descriptions.Item label="Connected WABA">
            {wabaConnectionData?.wabaId}
          </Descriptions.Item>
          <Descriptions.Item label="Connection Phone Id">
            {wabaConnectionData?.phoneId}
          </Descriptions.Item>
          <Descriptions.Item label="Connection Phone Number">
            {wabaConnectionData?.phoneNumber}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </Spin>
  );
};
