import { SDK } from "@sdk";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { GlobalConfig } from "config";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { iPricingPlan } from "./pricing-plan-model";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(GlobalConfig.STRIPE_KEY);

const cardOptions = {
  style: {
    base: {
      fontSize: "16px",
      color: "#424770",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

const _UpdateCard = ({
  plan,
  onSuccess,
}: {
  plan: iPricingPlan;
  onSuccess: () => any;
}) => {
  const stripe = useStripe()!;
  const elements = useElements()!;
  const [form] = useForm();
  const [isLoading, setLoading] = useState(false);

  const currentUser = useSelector(selectCurrentUser);

  const handleSubmit = async (event?) => {
    event && event.preventDefault();

    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      setLoading(true);
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement)!,
        billing_details: {
          address: {
            city: values.city,
            line1: values.address,
            postal_code: values.zip,
            state: values.state,
          },
          email: values.email || currentUser.credentials.email,
          name: values.name,
          // phone: values.phone,
        },
      });
      await handleStripePaymentMethod(result);
      setLoading(false);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const handleStripePaymentMethod = async (result) => {
    if (result.error) {
      Modal.error({
        title: "Error",
        content: result.error.message,
      });
    } else {
      const values = form.getFieldsValue();
      const subscription = await SDK.changePlan({
        paymentMethodId: result.paymentMethod.id,
        planId: plan.planId!,
        billingDetails: {
          email: values.email,
          name: values.name,
          phone: values.phone || "",
          address: {
            city: values.city,
            line1: values.address,
            postalCode: values.zip,
            state: values.state,
            country: values.country,
          },
        },
      }).catch((e) => {
        message.error("Something went wrong", e);
        setLoading(false);
      });

      handleSubscription(subscription);
    }
  };

  const handleSubscription = (subscription) => {
    const { latest_invoice } = subscription;
    const { payment_intent } = latest_invoice;

    if (payment_intent) {
      const { client_secret, status } = payment_intent;

      if (status === "requires_action") {
        stripe.confirmCardPayment(client_secret).then(function (result) {
          if (result.error) {
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            Modal.error({
              title: "Error",
              content: result.error.message,
            });
            // Ideally we should mark the subscription as not valid here
            SDK.changePlan({
              planId: "FREE",
            }).catch((e) => {
              // Do Nothing
            });
          } else {
            // Success!
            onSuccess();
          }
        });
      } else {
        // No additional information was needed
        onSuccess();
      }
    } else {
      console.log(`handleSubscription: No payment information received!`);
      onSuccess();
    }
  };

  return (
    <div style={{ minWidth: 320 }}>
      <Form
        onFinish={() => {
          handleSubmit();
        }}
        layout="vertical"
        form={form}
        className="bold-form-labels"
        requiredMark={false}
      >
        {/* <Form.Item
          label="Billing Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input placeholder="john@abc.om" />
        </Form.Item>

        <Form.Item
          label="Billing Phone"
          name="phone"
          rules={[
            {
              required: true,
              message: "Please enter your phone number",
            },
          ]}
        >
          <Input placeholder="Phone..." />
        </Form.Item> */}

        <Form.Item
          label="Name on card"
          name="nameOnCard"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input placeholder="Jane Doe" />
        </Form.Item>
        <Form.Item
          label="Address"
          name="address"
          rules={[{ required: true, message: "Address is required" }]}
        >
          <Input placeholder="1234 Almond Ave" />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: "City is required" }]}
        >
          <Input placeholder="San Bernardino" />
        </Form.Item>
        <Input.Group>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label="State"
                name="state"
                rules={[{ required: true, message: "State is required" }]}
              >
                <Input placeholder="CA" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="ZIP"
                name="zip"
                rules={[{ required: true, message: "Zip code is required" }]}
              >
                <Input placeholder="92401" />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>
        <Form.Item
          label="Card"
          name="card"
          rules={[{ required: true, message: "Card is required" }]}
        >
          <CardElement options={cardOptions} />
        </Form.Item>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          className="checkout-button"
          disabled={!stripe}
          block
        >
          Continue
        </Button>
      </Form>
    </div>
  );
};

export const UpdateCard = ({
  plan,
  onSuccess,
}: {
  plan: iPricingPlan;
  onSuccess: () => any;
}) => {
  return (
    <Elements stripe={stripePromise}>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <_UpdateCard plan={plan} onSuccess={onSuccess} />
    </Elements>
  );
};
