import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { UserGroupType } from "@sdk/user-management/user-management.models";
import React from "react";

const AddEditUserGroupModal = React.lazy(
  () => import("./add-edit-user-group.modal"),
);

const AddEditUserGroup: iModalProps<
  typeof AddEditUserGroupModal,
  { userGroupId: string; userGroupType: UserGroupType }
> = {
  name: "add-edit-user-group",
  component: GenerateModalWithSuspense(AddEditUserGroupModal),
};

export default AddEditUserGroup;
