import { useSDK, useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iExternalAppConfig_ClickUp } from "@sdk/user-management/user-management.models";
import { Alert, Button, Form, Select, Spin, Tag, Tooltip, message } from "antd";
import { ExternalAuthConnector } from "components/common/external-auth-connector-button/external-auth-connector-button";
import {
  LoadingIndicatorWithSpin,
  LoadingIndicatorWithoutSpin,
} from "components/common/loading-indicator/loading-indicator";
import { HelpUsHelpYou } from "components/help-us-help-you/help-us-help-you";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "store/modules/ui-state/ui-state.selectors";
import { selectIntegrationConfig_ClickUp } from "store/modules/workspace/workspace.selectors";

export const ClickUpIntegrationConfig = () => {
  const integrationConfig = useSelector(selectIntegrationConfig_ClickUp);
  const isDarkMode = useSelector(selectIsDarkMode);

  const { doAction: completeIntegration, isProcessing: isAdding } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (code: string) =>
          SDK.externalAccounts.completeIntegration({
            type: "CLICK_UP",
            data: {
              code,
            },
          }),
        successMessage: "Integration has been successful",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const { doAction: removeIntegration, isProcessing: isRemoving } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.externalAccounts.removeIntegration({
            type: "CLICK_UP",
          }),
        successMessage: "Integration has been removed",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const { doAction: saveConfig, isProcessing: isSavingConfig } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (config) =>
          SDK.externalAccounts.updateConfig({
            type: "CLICK_UP",
            config,
          }),
        successMessage: "Configuration has been saved",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const [form] = Form.useForm();
  const [isConfigEditMode, setConfigEditMode] = useState(false);
  const [formValues, setFormValues] = useState(
    integrationConfig?.config || ({} as iExternalAppConfig_ClickUp),
  );

  useEffect(() => {
    if (integrationConfig?.config) {
      setFormValues(integrationConfig?.config);
    }
  }, [integrationConfig?.config]);

  const { data: teamsOptions, isLoading: isTeamsLoading } = useSDK(
    (SDK) =>
      SDK.clickUp.getAvailableWorkspaces().then((teamsRes) => {
        return (teamsRes.teams || []).map((item) => ({
          value: item.id,
          label: item.name,
        }));
      }),
    [integrationConfig],
    !integrationConfig || !isConfigEditMode,
    [],
  );

  const { data: spaceOptions, isLoading: isSpacesLoading } = useSDK(
    (SDK) =>
      SDK.clickUp
        .getAvailableSpaces({ teamId: formValues.teamId! })
        .then((projectsRes) => {
          return (projectsRes?.spaces || []).map((item) => ({
            value: item.id,
            label: item.name,
          }));
        }),
    [integrationConfig],
    !formValues.teamId || !isConfigEditMode,
    [],
  );

  const { data: foldersOptions, isLoading: isFoldersLoading } = useSDK(
    (SDK) =>
      SDK.clickUp
        .getAvailableFolders({ spaceId: formValues.spaceId! })
        .then((projectsRes) => {
          return (projectsRes?.folders || []).map((item) => ({
            value: item.id,
            label: item.name,
          }));
        }),
    [integrationConfig],
    !formValues.spaceId || !isConfigEditMode,
    [],
  );

  const { data: listOptions, isLoading: isListsLoading } = useSDK(
    (SDK) =>
      SDK.clickUp
        .getAvailableLists({ folderId: formValues.folderId! })
        .then((projectsRes) => {
          return (projectsRes?.lists || []).map((item) => ({
            value: item.id,
            label: item.name,
          }));
        }),
    [integrationConfig],
    !formValues.folderId || !isConfigEditMode,
    [],
  );

  const onSaveConfig = useCallback(
    (e) => {
      e.preventDefault();
      const process = async () => {
        try {
          await form.validateFields();
          const formValues = form.getFieldsValue();
          const selectedTeam = teamsOptions.find(
            (item) => item.value === formValues.workspaceId,
          );
          const selectedSpace = spaceOptions.find(
            (item) => item.value === formValues.spaceId,
          );

          const selectedFolder = foldersOptions.find(
            (item) => item.value === formValues.folderId,
          );

          const selectedList = listOptions.find(
            (item) => item.value === formValues.listId,
          );
          saveConfig({
            ...formValues,
            isReady: true,
            teamName: selectedTeam?.label,
            spaceName: selectedSpace?.label,
            listName: selectedList?.label,
            folderName: selectedFolder?.label,
          } as iExternalAppConfig_ClickUp).then((d) => {
            setConfigEditMode(false);
          });
        } catch (e) {
          message.error("Please check your input");
        }
      };
      process();
    },
    [foldersOptions, form, listOptions, saveConfig, spaceOptions, teamsOptions],
  );

  const {
    data: test,
    isLoading: isTestingIntegration,
    error,
    reload: reTestIntegration,
  } = useSDK(
    (SDK) => SDK.clickUp.getAvailableWorkspaces(),
    [integrationConfig?.config?.isReady],
    !integrationConfig?.config?.isReady,
  );

  useEffect(() => {
    if (integrationConfig && !integrationConfig.config.isReady) {
      setConfigEditMode(true);
    }
  }, [integrationConfig]);

  return (
    <>
      {integrationConfig && (
        <div>
          {integrationConfig.config.isReady ? (
            <Spin
              spinning={isTestingIntegration}
              indicator={<LoadingIndicatorWithSpin />}
            >
              {!error && (
                <Alert
                  message="Integration is active"
                  type="success"
                  showIcon
                />
              )}
              {error && (
                <Alert
                  message="Integration is not working correctly. Try Re-Authenticating"
                  type="error"
                  showIcon
                />
              )}
            </Spin>
          ) : (
            <Alert
              message="ClickUp integration is not ready yet. Complete the below steps to enable it"
              type="warning"
              showIcon
            />
          )}
          <div className="flex flex-row justify-between mt-8">
            <div className="text">Connected Workspace</div>
            <div className="flex flex-row items-center">
              <div className="">
                <Tag>
                  {integrationConfig.config.teamName || "Team Not Selected"}
                </Tag>
              </div>
              <Tooltip title="Remove Integration">
                <Button
                  type="link"
                  icon={<i className="ri-delete-bin-line"></i>}
                  onClick={() => {
                    removeIntegration();
                  }}
                  loading={isRemoving}
                ></Button>
              </Tooltip>
            </div>
          </div>
          <div className="flex flex-row justify-between my-2">
            <div className="text">Default List</div>
            <div className="flex flex-row items-center">
              <div className="">
                <Tag>
                  {integrationConfig.config.spaceName || "No Space Selected"}
                </Tag>
                <Tag>
                  {integrationConfig.config.folderName || "No Folder Selected"}
                </Tag>
                <Tag>
                  {integrationConfig.config.listName || "No List Selected"}
                </Tag>
              </div>
              {!isConfigEditMode && (
                <>
                  <Tooltip title="Configure">
                    <Button
                      type="link"
                      icon={<i className="ri-pencil-line"></i>}
                      onClick={() => {
                        setConfigEditMode(true);
                      }}
                      loading={isRemoving}
                    ></Button>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
          {isConfigEditMode && (
            <div className=" w-full">
              <Form
                form={form}
                layout="vertical"
                initialValues={integrationConfig.config}
                requiredMark={false}
                className="bold-form-labels"
                onFinish={onSaveConfig}
                onValuesChange={() => {
                  const formValues = form.getFieldsValue();
                  setFormValues(formValues);
                }}
              >
                <Form.Item
                  name="teamId"
                  label="Workspace"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Workspace",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Workspace"
                    options={teamsOptions}
                    showSearch
                    loading={isTeamsLoading}
                  />
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const teamId = getFieldValue(["teamId"]);
                    if (!teamId) {
                      return <></>;
                    }
                    return (
                      <Form.Item
                        name="spaceId"
                        label="Space"
                        rules={[
                          {
                            required: true,
                            message: "Please select a Space",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Space"
                          options={spaceOptions}
                          showSearch
                          loading={isSpacesLoading}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const spaceId = getFieldValue(["spaceId"]);
                    if (!spaceId) {
                      return <></>;
                    }
                    return (
                      <>
                        <Form.Item
                          name="folderId"
                          label="Folder"
                          rules={[
                            {
                              required: true,
                              message: "Please select a Folder",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Folder"
                            options={foldersOptions}
                            showSearch
                            loading={isFoldersLoading}
                          />
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const folderId = getFieldValue(["folderId"]);
                    if (!folderId) {
                      return <></>;
                    }
                    return (
                      <>
                        <Form.Item
                          name="listId"
                          label="List"
                          rules={[
                            {
                              required: true,
                              message: "Please select a List",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select List"
                            options={listOptions}
                            showSearch
                            loading={isListsLoading}
                          />
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.Item>
                <div className="flex flex-row justify-end items-center">
                  <Button
                    type="primary"
                    className="font-bold"
                    loading={isAdding}
                    onClick={onSaveConfig}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          )}
          <HelpUsHelpYou
            chatMessage="How can we improve ClickUp Integration for you?"
            emoji="☝️"
            description="Share your workflows with ClickUp and we'll ensure that our ClickUp Integration saves you time."
          />
        </div>
      )}

      {!integrationConfig && (
        <div className="flex flex-row justify-center items-center">
          <Spin
            spinning={isAdding}
            indicator={<LoadingIndicatorWithoutSpin />}
            wrapperClassName="flex fill-spinner"
          >
            <ExternalAuthConnector
              type="CLICK_UP"
              onAuthenticated={({ code }) => {
                completeIntegration(code);
              }}
              label="Connect Click Up"
            />
          </Spin>
        </div>
      )}
    </>
  );
};
