import { iNotificationRecord } from "@sdk/notification-inbox/notifications.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Alert, Button, List, Tabs } from "antd";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { loadAllNotifications } from "store/modules/notifications/notifications.helpers";
import { selectAllNotifications } from "store/modules/notifications/notifications.selectors";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { EmptyData } from "../empty-data/empty-data";
import { useNotificationsPermission } from "../notifications/use-notifications-permission";
import { usePushNotificationStatus } from "../notifications/use-push-noitificaiton-status";
import { NotificationItem } from "./notification-item";
import "./notifications.scss";

export const NotificationsInbox = () => {
  const { state: notifications, retry: reload } = useQueryWithStore(
    selectAllNotifications,
    loadAllNotifications,
  );

  const { requestNotificationPermission, notificationPermissionState } =
    useNotificationsPermission();

  const {
    recentNotifications = [] as iNotificationRecord[],
    archivedNotifications = [] as iNotificationRecord[],
  } = useMemo(() => {
    return _.groupBy(
      _.orderBy(notifications, (notification) => -notification.createdAt),
      (notifications) =>
        notifications.isDismissed
          ? "archivedNotifications"
          : "recentNotifications",
    );
  }, [notifications]);

  const {
    doAction: archiveAllNotifications,
    isProcessing,
    response,
    dispatch,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values) =>
        SDK.dismissAllNotification().then(() => {
          reload();
        }),
      successMessage: "Done",
      failureMessage: "Something went wrong",
      actionDependencies: [reload],
    }),
    [reload],
  );

  const { subscribeToPush, isOfflinePushEnabled } = usePushNotificationStatus();

  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  const { doAction: testPushNotification, isProcessing: isProcessingTest } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () => SDK.testPushNotification(),
        failureMessage: "Something went wrong",
      }),
      [],
    );

  return (
    <div style={{ width: "23rem", height: "75vh" }} className="overflow-auto">
      {!notificationPermissionState && (
        <Alert
          message={
            <div className="flex flex-row justify-between items-center">
              Allow Desktop Notifications
              <Button
                type="text"
                icon={<i className="ri-shield-check-line pr-2"></i>}
                onClick={requestNotificationPermission}
                className="bg-orange-200 hover:bg-orange-300"
              >
                Allow
              </Button>
            </div>
          }
          type="warning"
          showIcon
        />
      )}

      {isAdvancedMode && (
        <Button
          type="text"
          icon={<i className="ri-notification-3-line"></i>}
          loading={isProcessingTest}
          onClick={() => {
            testPushNotification();
          }}
        >
          Test Push Notification
        </Button>
      )}

      <Tabs defaultActiveKey="RECENT">
        <Tabs.TabPane
          tab={
            <div className="font-bold">
              <i className="ri-history-line"></i> Recent
            </div>
          }
          key="RECENT"
        >
          {recentNotifications.length > 0 && (
            <div className="flex flex-row justify-end items-center">
              <Button type="link" onClick={archiveAllNotifications}>
                Archive All
              </Button>
            </div>
          )}

          <List
            itemLayout="horizontal"
            locale={{
              emptyText: (
                <EmptyData text="All Caught up" icon="ri-notification-3-line" />
              ),
            }}
            dataSource={recentNotifications}
            renderItem={(notification) => (
              <NotificationItem notification={notification} />
            )}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <div className="font-bold">
              <i className="ri-archive-line"></i> Archived
            </div>
          }
          key="ARCHIVED"
        >
          <List
            itemLayout="horizontal"
            locale={{
              emptyText: (
                <EmptyData text="All Caught up" icon="ri-notification-3-line" />
              ),
            }}
            dataSource={archivedNotifications}
            renderItem={(notification) => (
              <NotificationItem notification={notification} />
            )}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
