import { iWidget } from "@sdk/chat-widgets/chat-widgets.models";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectChatWidgetQueryMap,
  selectEntityMap: selectChatWidgetsMap,
  selectQueryMapAll: selectChatWidgetsMapAll,
  selectQueryAllList: selectChatWidgetsAllList,
  selectAllEntities: selectAllChatWidgets,
  selectAllEntitiesQuery: selectAllChatWidgetsQuery,
  selectEntityById: selectChatWidgetById,
  selectEntityQuery: selectChatWidgetQuery,
  selectEntityQueryList: selectChatWidgetQueryList
} = CreateSelectorForEntities<iStore, iWidget>({
  sliceName: "chatWidgets"
});

export const selectChatWidgetByConnectionId = (connectionId: string) => (
  state: iStore
) => {
  return _.find(selectAllChatWidgets(state), {
    connectionId
  });
};
