import { SDK } from "@sdk";
import { iConversation } from "@sdk/conversations/conversations.models";
import { Store } from "redux";
import { QueryConfig } from "store/utils/query-config";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import {
  bulkLoadContactsByIds,
  loadContactById
} from "../contacts/contacts.helpers";
import { DefaultConversationQueryConfig } from "../ui-state/ui-state.slice";
import { ConversationsSlice } from "./conversations.slice";

export const loadQueryConversations = (
  queryConfig: QueryConfig,
  queryAlias?: string
) => async (store: Store<iStore>, forceReload?: boolean) => {
  queryAlias = queryAlias || JSON.stringify(queryConfig);
  await loadConversationsQuery(queryConfig, queryAlias)(store, forceReload);
};

export const loadContactConversations = (contactId: string) => async (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  const queryConfig = {
    ...DefaultConversationQueryConfig,
    query: {
      contactId
    }
  };
  const queryAlias = JSON.stringify(queryConfig);
  await loadConversationsQuery(queryConfig, queryAlias)(store, forceReload);
};

export const {
  loadEntityQueries: loadConversationsQuery,
  loadEntityById: loadConversationById,
  loadMoreEntityQueries: loadMoreConversationsQueries
} = CreateHelpersForEntity<iStore, iConversation>({
  sliceName: "conversations",
  slice: ConversationsSlice,
  isPaginatedQueries: true,
  fetchPageSize: 20,
  loadEntityQueryProvider: queryConfig => SDK.queryConversations(queryConfig),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.getConversationById(entityId),
  postLoadEntity: async (conversation, store, forceReload) => {
    await loadContactById(conversation.contactId!)()(store, forceReload);
  },
  postLoadEntities: async (conversations, store, forceReload) => {
    const associatedContacts = conversations.map(
      conversation => conversation.contactId
    );
    // Load Contacts
    if (associatedContacts.length > 0) {
      await bulkLoadContactsByIds(associatedContacts)(store, forceReload);
    }
  }
});

export const updateReadWatermark = async (
  conversationId: string,
  timestamp: number
) => {
  await SDK.updateWatermark(conversationId, timestamp);
};
