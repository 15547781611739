import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddTelnyxConnectionModal = React.lazy(
  () => import("./add-telnyx-connection.modal"),
);

const AddTelnyxConnection: iModalProps<typeof AddTelnyxConnectionModal> = {
  name: "add-telnyx-connection",
  component: GenerateModalWithSuspense(AddTelnyxConnectionModal),
};

export default AddTelnyxConnection;
