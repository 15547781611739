import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddEngagementModal = React.lazy(() => import("./add-engagement.modal"));

const AddEngagement: iModalProps<typeof AddEngagementModal> = {
  name: "add-engagement",
  component: GenerateModalWithSuspense(AddEngagementModal),
};

export default AddEngagement;
