import qs from "query-string";
import { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router";

export const useUrlState = <T extends string>(
  path: string,
  defaultState?: T,
) => {
  const location = useLocation();
  const history = useHistory();

  const setUrlState = useCallback(
    (newState: T, additionalStateChanges?: any) => {
      const existingSearch = qs.parse(window.location.search);
      const newSearch = qs.stringify(
        {
          ...existingSearch,
          [path]: newState,

          ...additionalStateChanges,
        },
        { encode: false },
      );
      // console.log("newSearch", newSearch);
      history.push({
        search: newSearch,
      });
    },
    [history, path],
  );

  const urlState = qs.parse(location.search)[path] as T;

  useEffect(() => {
    if (!urlState && defaultState) {
      setUrlState(urlState);
    }
  }, [defaultState, setUrlState, urlState]);

  return [urlState || defaultState, setUrlState] as [
    typeof urlState,
    typeof setUrlState,
  ];
};
