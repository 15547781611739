import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ConfigurePersonalMailboxModal = React.lazy(
  () => import("./configure-personal-mailbox.modal"),
);

const ConfigurePersonalMailbox: iModalProps<
  typeof ConfigurePersonalMailboxModal
> = {
  name: "configure-personal-mailbox",
  component: GenerateModalWithSuspense(ConfigurePersonalMailboxModal),
};

export default ConfigurePersonalMailbox;
