import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";
import React, { Suspense } from "react";

const ReactJson = React.lazy(() => import("react-json-view"));
export const LazyJSONViewer = ({
  src,
  collapsed,
}: {
  src: any;
  collapsed?: boolean;
}) => {
  return (
    <Suspense fallback={<SuspenseLoadingIndicator />}>
      <ReactJson src={src} collapsed={collapsed} />
    </Suspense>
  );
};
