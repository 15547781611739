import { Button } from "antd";
import { useMemo } from "react";

export const ShopifyInstallInstruction = ({
  storeDomain,
}: {
  storeDomain: string;
}) => {
  const appEmbedUrl = useMemo(() => {
    return `https://${storeDomain}/admin/themes/current/editor?context=apps&appEmbed=af33a210-5b85-40ff-9ca9-8381f0c32909%2Fapp-embed`;
  }, [storeDomain]);

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="text my-8">
        You can enable ClickConnector's Chat Widget through the App Embed
        configurations. All you have to do is toggle the switch and save the
        settings
      </div>
      <Button
        type="primary"
        icon={<i className="ri-arrow-right-line"></i>}
        href={appEmbedUrl}
        target="_blank"
        rel="noreferrer"
      >
        Go to App Embeds
      </Button>
      <div className="illustration mt-8">
        <img
          src="/assets/integrations/enable-widget-config.png"
          alt="Shopify App Embed"
          style={{ maxWidth: 400 }}
        />
      </div>
    </div>
  );
};
