import { mdiWrenchCog } from "@mdi/js";
import { Divider } from "antd";
import { MDIIcon } from "components/common/mdi-icon";
import { SectionHeader } from "components/common/section-header";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { AccessAndAvailabilitySettings } from "./access-and-availability-settings";
import { InboxPowerSettings } from "./inbox-power-settings";
import { OtherPowerSettings } from "./other-settings";

export const ConfigurePowerSettings = () => {
  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin p-8 overflow-x-hidden">
      <SectionHeader
        title="Power Settings"
        icon={<MDIIcon icon={mdiWrenchCog} size="3rem" />}
        description="Access Configurations, Inbox Tweaking etc"
      />

      <Divider>Inbox Settings</Divider>

      <InboxPowerSettings />

      <Divider>Access and Availability</Divider>

      <AccessAndAvailabilitySettings />

      <Divider>Other</Divider>
      {/* Holiday Quotes Settings */}
      <OtherPowerSettings />
    </StyledScrollArea>
  );
};
