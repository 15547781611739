import {
  GetWindowVisibility,
  OnVisibilityChange
} from "@libs/window-visibility-detector/window-visibility-detector.service";
import { Store } from "@reduxjs/toolkit";
import { NotificationHelper } from "components/common/notifications/notifications-helper";
import { setAwayDetectorState } from "store/modules/ui-state/ui-state.actions";
import { changeUserAvailabilityStatus } from "store/modules/users/users.helpers";

export class AwayDetectorService {
  // Todo: Got to refactor the terms and reduce no of variables
  static REMINDER_THRESHOLD = 10 * 60 * 1000;
  static AWAY_TIMEOUT = 12 * 60 * 1000;
  static LAST_ONLINE_TIME?: number = undefined;
  static IS_REMINDER_ACTION_TAKEN = false;
  static IS_AWAY_ACTION_TAKEN = false;
  static IS_AWAY_DETECTOR_ACTIVE = false;
  static IS_AWAY_DETECTOR_ACTIVE_FOR_ORGANIZATION = false;
  static STARTED = false;

  static init(store: Store) {
    // this.onRestart();
    if (this.STARTED) {
      return;
    }
    const removeVisibilityChangeDetection = OnVisibilityChange(() => {
      const isWindowVisible = GetWindowVisibility();
      if (isWindowVisible) {
        this.LAST_ONLINE_TIME = undefined;
        this.IS_REMINDER_ACTION_TAKEN = false;
        this.IS_AWAY_ACTION_TAKEN = false;
      } else {
        this.LAST_ONLINE_TIME = new Date().getTime();
      }
    });

    const awayDetectorTimer = setInterval(() => {
      const currentTime = new Date().getTime();
      // console.log("Away Detector Running xxxxx", {
      //   LAST_ONLINE_TIME: this.LAST_ONLINE_TIME,
      //   IS_AWAY_DETECTOR_ACTIVE: this.IS_AWAY_DETECTOR_ACTIVE,
      //   IS_AWAY_DETECTOR_ACTIVE_FOR_ORGANIZATION: this
      //     .IS_AWAY_DETECTOR_ACTIVE_FOR_ORGANIZATION,
      //   IS_AWAY_ACTION_TAKEN: this.IS_AWAY_ACTION_TAKEN,
      //   isAwayItemedOut:
      //     (this.LAST_ONLINE_TIME || 0) + this.AWAY_TIMEOUT < currentTime,
      //   IS_REMINDER_ACTION_TAKEN: this.IS_REMINDER_ACTION_TAKEN,
      //   isReminderTimedOut:
      //     (this.LAST_ONLINE_TIME || 0) + this.REMINDER_THRESHOLD < currentTime,
      //   AWAY_TIMEOUT: this.AWAY_TIMEOUT,
      //   REMINDER_THRESHOLD: this.REMINDER_THRESHOLD,
      // });
      //  Check if the user is not away - this.IS_AWAY_DETECTOR_ACTIVE indicated if the user is away or not
      if (
        this.LAST_ONLINE_TIME &&
        this.IS_AWAY_DETECTOR_ACTIVE &&
        this.IS_AWAY_DETECTOR_ACTIVE_FOR_ORGANIZATION
      ) {
        if (
          !this.IS_AWAY_ACTION_TAKEN &&
          this.LAST_ONLINE_TIME + this.AWAY_TIMEOUT < currentTime
        ) {
          this.IS_AWAY_ACTION_TAKEN = true;

          // Change User Status
          changeUserAvailabilityStatus("AWAY");
          // Notify User this.notificationsService.fire.onMadeAway(this.AWAY_TIMEOUT);
          NotificationHelper.fire.onMadeAway(this.AWAY_TIMEOUT);
          store.dispatch(setAwayDetectorState(true));
        } else if (
          !this.IS_REMINDER_ACTION_TAKEN &&
          this.LAST_ONLINE_TIME + this.REMINDER_THRESHOLD < currentTime
        ) {
          this.IS_REMINDER_ACTION_TAKEN = true;
          // Threshold reminder
          // this.notificationsService.fire.onAwayDetected(
          //   this.REMINDER_THRESHOLD
          // );
          NotificationHelper.fire.onAwayDetected(this.REMINDER_THRESHOLD);
        }
      }
    }, 10 * 1000);

    // this.onRestart = () => {
    //   clearInterval(awayDetectorTimer);
    //   removeVisibilityChangeDetection();
    // };
    this.STARTED = true;
  }
}
