import { axios, AxiosResponse } from "@sdk/axios";
import {
  PaginatedOptions,
  PaginatedResults
} from "@sdk/utils/paginated-results";
import {
  iUserPresenceDailySummaryLog,
  iUserPresenceLog
} from "./user-presence-logs.model";

export class UserPresenceLogsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  async addUserPresenceLogs(logs: iUserPresenceLog[]) {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + "/user-presence-logs",
      logs,
      {
        headers: {
          Authorization: this.config.token
        }
      }
    );
    return results.data;
  }

  async queryUserPresenceLogs(req: { query: any; options: PaginatedOptions }) {
    const results: AxiosResponse<PaginatedResults<
      iUserPresenceLog
    >> = await axios.post(
      this.config.basePath + `/user-presence-logs/query-logs`,
      req,
      {
        headers: {
          Authorization: this.config.token
        }
      }
    );
    return results.data;
  }

  async queryUserPresenceDailySummaryLogs(req: {
    query: any;
    options: PaginatedOptions;
  }) {
    const results: AxiosResponse<PaginatedResults<
      iUserPresenceDailySummaryLog
    >> = await axios.post(
      this.config.basePath + `/user-presence-logs/query-daily-summary`,
      req,
      {
        headers: {
          Authorization: this.config.token
        }
      }
    );
    return results.data;
  }
}
