import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";
import { QueryConfig } from "store/utils/query-config";

const CreateChatListViewModal = React.lazy(
  () => import("./create-chat-list-view.modal"),
);

const CreateChatListView: iModalProps<
  typeof CreateChatListViewModal,
  { queryConfig: QueryConfig }
> = {
  name: "create-chat-list-view",
  component: GenerateModalWithSuspense(CreateChatListViewModal),
};

export default CreateChatListView;
