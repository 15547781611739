import React, { useState } from "react";

interface _iTempModalState {
  id: string;
  name: string;
}

export type iTempModalState = _iTempModalState & Record<string, any>;

type SetStateAction = (
  state: iTempModalState[] | ((state: iTempModalState[]) => iTempModalState[])
) => any;

export const ModalsStateContext = React.createContext({
  state: ([] as any) as iTempModalState[],
  setState: ((state: iTempModalState[]) => {}) as SetStateAction
});

export const WithModalState = ({ children }) => {
  const [state, setState] = useState([] as iTempModalState[]);
  return (
    <ModalsStateContext.Provider value={{ state, setState }}>
      {children}
    </ModalsStateContext.Provider>
  );
};
