import { SDK } from "@sdk";
import { iBadgeRecord } from "@sdk/badges/badge-record-model";

import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { badgesDefaultQueryConfig } from "./badges-default-query";
import { BadgesSlice } from "./badges.slice";

export const loadAllBadges = (store: Store<iStore>, forceReload?: boolean) =>
  loadBadgesQuery(badgesDefaultQueryConfig, "all")(store, forceReload);

export const {
  loadEntityQueries: loadBadgesQuery,
  loadEntityById: loadBadgeById,
  loadEntitiesByIds: bulkLoadBadgesByIds,
} = CreateHelpersForEntity<iStore, iBadgeRecord>({
  sliceName: "badges",
  slice: BadgesSlice,
  isPaginatedQueries: false,
  loadEntityQueryProvider: (query) => SDK.badges.getAll(),
  loadEntityByIdProvider: (entityId: string) => SDK.badges.getById(entityId),
});
