export function unwindBy<T>(
  arr: T[],
  field: string,
  valueMapper?: (obj) => any
) {
  return arr.reduce(
    (r, o) =>
      r.concat(
        o[field].map(v => ({
          ...o,
          [field]: valueMapper ? valueMapper(v) : v
        }))
      ),
    [] as T[]
  );
}
