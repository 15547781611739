import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const HubSpotCreateContactModal = React.lazy(
  () => import("./hubspot-create-contact.modal"),
);

const HubSpotCreateContact: iModalProps<
  typeof HubSpotCreateContactModal,
  {
    onAdded?: (pageId: string) => any;
    contactId?: string;
    conversationId?: string;
  }
> = {
  name: "hub-spot-create-contact",
  component: GenerateModalWithSuspense(HubSpotCreateContactModal),
};

export default HubSpotCreateContact;
