import { useEffect, useState } from "react";
import { LocalNotificationsService } from "./local-notifications-controller";

export const useNotificationsPermission = () => {
  const requestNotificationPermission =
    LocalNotificationsService.requestPermissions;

  const [
    notificationPermissionState,
    setNotificationPermissionState
  ] = useState(LocalNotificationsService.hasPermission);

  useEffect(() => {
    const onPermissionStateChange = () => {
      setNotificationPermissionState(LocalNotificationsService.hasPermission);
    };
    LocalNotificationsService.permissionEvents.addListener(
      "PERMISSION_CHANGE",
      onPermissionStateChange
    );

    // cleanup this component
    return () => {
      LocalNotificationsService.permissionEvents.removeListener(
        "PERMISSION_CHANGE",
        onPermissionStateChange
      );
    };
  }, []);
  return { requestNotificationPermission, notificationPermissionState };
};
