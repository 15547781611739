import { SDK } from "@sdk";
import { iArticleCollection } from "@sdk/knowledge-base/knowledge-base.models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { articleCollectionDefaultQueryConfig } from "./article-collection-default-query";
import { ArticleCollectionsSlice } from "./article-collection.slice";

export const loadAllArticleCollections = (
  store: Store<iStore>,
  forceReload?: boolean
) =>
  loadArticleCollectionsQuery(articleCollectionDefaultQueryConfig, "all")(
    store,
    forceReload
  );

export const {
  loadEntityQueries: loadArticleCollectionsQuery,
  loadEntityById: loadArticleCollectionById,
  loadEntitiesByIds: bulkLoadArticleCollectionsByIds
} = CreateHelpersForEntity<iStore, iArticleCollection>({
  sliceName: "articleCollections",
  slice: ArticleCollectionsSlice,
  isPaginatedQueries: false,
  loadEntityQueryProvider: query => SDK.collections.getAll(),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.collections.getById(entityId),
  loadEntityByIdsProvider: (articleCollectionIds: string[]) =>
    (async () => {
      const articleCollections = await Promise.all(
        articleCollectionIds.map(articleCollectionId =>
          SDK.collections.getById(articleCollectionId)
        )
      );
      return articleCollections;
    })()
});
