import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { AvailabilityStatus } from "@sdk/user-management/user-management.models";

import { Divider, Switch, Tooltip, message } from "antd";
import { BadgesController } from "badge-controller";
import { UserAvatar } from "components/common/avatar/user-avatar";
import { useAutoSubscribeToPush } from "components/common/notifications/use-auto-subscribe-to-push";
import { usePushNotificationStatus } from "components/common/notifications/use-push-noitificaiton-status";
import { CCPopoverV2 } from "components/common/popover/popover-v2-portal";
import { TeamStatusWidget } from "components/modules/user-management/users/components/team-status-widget/team-status-widget";

import { useRegisterActions } from "@libs/kbar";
import { NotificationHelper } from "components/common/notifications/notifications-helper";

import CCEmbed from "components/common/cc-embed/cc-embed.modal-registry";
import MyManagePresets from "components/modules/conversations/components/manage-presets-x/manage-presets-x.modal-registry";
import MyEmailSignatures from "components/modules/email-signatures/email-signature-manager.modal-registry";
import TestimonialCollection from "components/modules/testimonial-collection-modal/testimonial-collection.modal-registry";
import MyProfileX from "components/modules/user-management/users/components/my-profile/my-profile.modal-registry";
import { useMemo, useRef } from "react";
import { useSelector, useStore } from "react-redux";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { removeRecentLoginData } from "store/modules/app-state/app-state.actions";
import { setDarkMode } from "store/modules/ui-state/ui-state.actions";
import {
  selectIsAdvancedMode,
  selectIsDarkMode,
} from "store/modules/ui-state/ui-state.selectors";
import { logout } from "store/modules/users/users.actions";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import {
  selectOrganization,
  selectOrganizationDomain,
} from "store/modules/workspace/workspace.selectors";
import { UserTracker } from "user-tracker";
import {
  SideBarSubMenuIconItem,
  SideBarSubMenuItem,
} from "./side-bar-sub-menu-item";

export const ProfileMenu = () => {
  const store = useStore();
  const user = useSelector(selectCurrentUser);
  const organization = useSelector(selectOrganization);
  const currentOrganizationDomain = useSelector(selectOrganizationDomain);
  const currentAvailabilityStatus = user.metaData.availabilityStatus;
  const isDarkMode = useSelector(selectIsDarkMode);

  const { doAction: changeAvailabilityStatus, isProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (availabilityStatus: AvailabilityStatus) =>
          SDK.editCurrentUser({
            metaData: {
              availabilityStatus,
            },
          }).then((d) => {
            BadgesController.processBadgeEvent("use-availability");
          }),
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const { changePanelState } = useModalPanels();

  const { subscribeToPush, isOfflinePushEnabled } = usePushNotificationStatus();
  useAutoSubscribeToPush();

  const DevMenu = useMemo(() => {
    return [
      {
        icon: <i className="ri-notification-3-line"></i>,
        label: "Enable Push Notifications",
        // {isOfflinePushEnabled && <i className="ri-check-line"></i>}
        onClick: () => {
          subscribeToPush();
          popoverRef.current.closePopover();
        },
      },
      {
        icon: <i className="ri-pulse-fill"></i>,
        label: "Send Test Error",
        onClick: () => {
          popoverRef.current.closePopover();
          throw new Error("Test Error");
        },
      },
      {
        icon: <i className="ri-notification-3-line"></i>,
        label: "Show Notification",
        onClick: () => {
          NotificationHelper.fire.testNotification({
            title: "Hello World",
            body: "Some Body",
          });
          popoverRef.current.closePopover();
        },
      },
    ];
  }, [subscribeToPush]);

  const MyProfileMenu = useMemo(() => {
    return [
      {
        icon: <i className="ri-shield-user-line"></i>,
        label: "My Profile",
        onClick: () => {
          changePanelState(MyProfileX, true, {});
          popoverRef.current.closePopover();
        },
      },
      {
        icon: <i className="ri-chat-settings-line"></i>,
        label: "My Message Presets",
        onClick: () => {
          changePanelState(MyManagePresets, true, {});
          popoverRef.current.closePopover();
        },
      },
      {
        icon: <i className="ri-pen-nib-line"></i>,
        label: "My Email Signatures",
        onClick: () => {
          changePanelState(MyEmailSignatures, true, {});
          popoverRef.current.closePopover();
        },
      },
      // {
      //   icon: <i className="ri-download-2-line"></i>,
      //   label: "Download App (Beta)",
      //   onClick: () => {
      //     changePanelState(DownloadApp, true, {});
      //     popoverRef.current.closePopover();
      //   },
      // },
    ];
  }, [changePanelState]);

  const kbBindings = useMemo(() => {
    return MyProfileMenu.map((item) => ({
      id: item.label,
      icon: item.icon,
      name: item.label,
      keywords: item.label,
      perform: item.onClick,
      section: "My Profile",
    }));
  }, [MyProfileMenu]);

  useRegisterActions(kbBindings, [kbBindings]);

  const isDevMode = useSelector(selectIsAdvancedMode);

  const popoverRef = useRef(null as any);

  const ProductMenu = useMemo(() => {
    return [
      {
        icon: <i className="ri-service-line"></i>,
        label: "Send us a Testimonial",
        onClick: () => {
          changePanelState(TestimonialCollection, true, {});
          popoverRef.current.closePopover();
        },
      },
      {
        icon: <i className="ri-lightbulb-line"></i>,
        label: "Feature Requests",
        onClick: () => {
          changePanelState(CCEmbed, true, {
            type: "FEATURE_REQUEST" as const,
          });
          popoverRef.current.closePopover();
        },
      },
      {
        icon: <i className="ri-bug-line"></i>,
        label: "Report a Bug",
        onClick: () => {
          changePanelState(CCEmbed, true, {
            type: "BUG" as const,
          });
          popoverRef.current.closePopover();
        },
      },
      {
        icon: <i className="ri-guide-line"></i>,
        label: "Road map",
        onClick: () => {
          changePanelState(CCEmbed, true, {
            type: "ROAD_MAP" as const,
          });
          popoverRef.current.closePopover();
        },
      },
      // {
      //   icon: <i className="ri-file-paper-2-line"></i>,
      //   label: "Release Notes",
      //   onClick: () => {
      //     changePanelState(CCEmbed, true, {
      //       type: "RELEASE_NOTES" as const,
      //     });
      //     popoverRef.current.closePopover();
      //   },
      // },
    ];
  }, [changePanelState]);

  return (
    <CCPopoverV2
      ref={popoverRef}
      // openState={true}
      content={
        <div className="animate-slide-up" style={{ width: "20rem" }}>
          <div className="flex flex-row">
            <div className="p-2 flex-1">
              <div className="font-bold text-lg">
                {user?.data?.firstName} {user?.data?.lastName}
              </div>
              <div className="text-sm text-gray-800 dark:text-gray-400">
                {user?.credentials?.email}
              </div>
              <div className="text-sm text-gray-800 dark:text-gray-400">
                {organization?.data?.name}
              </div>
            </div>
            <div className="">
              <Tooltip title="Toggle Dark Mode">
                <DarkModeSwitch
                  style={{ marginBottom: "1rem" }}
                  checked={isDarkMode}
                  onChange={(checked) => {
                    store.dispatch(setDarkMode(checked));
                  }}
                  size={25}
                />
              </Tooltip>
            </div>
          </div>
          <Divider style={{ margin: 0 }} />
          <TeamStatusWidget widgetStyle="TWO" />
          <div className="flex flex-row justify-between m-2">
            <div className="text-gray-800 dark:text-gray-400">Away Mode</div>
            <Switch
              checked={currentAvailabilityStatus !== "AVAILABLE"}
              onChange={(checked) => {
                if (checked) {
                  changeAvailabilityStatus("AWAY");
                } else {
                  changeAvailabilityStatus("AVAILABLE");
                }
              }}
            />
          </div>

          <Divider style={{ margin: 0 }} />
          <div className="">
            {MyProfileMenu.map((item) => (
              <SideBarSubMenuItem key={item.label} {...item} />
            ))}
          </div>
          <Divider style={{ margin: 0 }} />
          {isDevMode && (
            <>
              <div className="text-gray-800 dark:text-gray-400">
                {DevMenu.map((item) => (
                  <SideBarSubMenuItem key={item.label} {...item} />
                ))}
              </div>
              <Divider style={{ margin: 0 }} />
            </>
          )}
          <SideBarSubMenuItem
            icon={<i className="ri-shut-down-line"></i>}
            label="Logout"
            onClick={() => {
              message.loading("Logging out", 3);
              UserTracker.track("AUTH - Logout", {});
              store.dispatch(logout());
              store.dispatch(
                removeRecentLoginData({
                  email: user.credentials.email,
                  domain: currentOrganizationDomain!,
                }),
              );
            }}
          />
          <div className="flex flex-row flex-wrap gap-4 items-center justify-center">
            {ProductMenu.map((item) => (
              <SideBarSubMenuIconItem key={item.label} {...item} />
            ))}
          </div>
        </div>
      }
      popoverId="profile-menu"
      className="p-4"
    >
      <div
        className="flex flex-row justify-center items-center p-2"
        id="profile-menu-icon"
      >
        <UserAvatar userId={user.id} size={36} className="cursor-pointer" />
      </div>
    </CCPopoverV2>
  );
};
