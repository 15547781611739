import { Select } from "antd";
import { useEffect } from "react";
import { iQueryInputElementProps } from "../models";

const tokenSeparators = [","];

export const MultiPhraseInput = ({
  value,
  onChange,
}: iQueryInputElementProps) => {
  useEffect(() => {
    if (!Array.isArray(value)) {
      onChange([]);
    }
  }, [onChange, value]);
  return (
    <Select
      value={value}
      onChange={onChange}
      mode="tags"
      style={{ width: "100%" }}
      placeholder="Add Phrases"
      dropdownStyle={{ display: "none" }}
      allowClear
      tokenSeparators={tokenSeparators}
      suffixIcon={null}
    />
  );
};
