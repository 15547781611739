import { Divider } from "antd";
import { useRef, useState } from "react";
import { Collapse } from "react-collapse";

export const SimpleCollapseWithDivider = ({
  title,
  children,
  defaultExpanded = false,
}: {
  title: string;
  children;
  defaultExpanded?: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const collapseViewRef = useRef<HTMLDivElement>(null);
  return (
    <div className="">
      <div
        onClick={() => {
          setIsExpanded(!isExpanded);
          setTimeout(() => {
            if (!isExpanded) {
              console.log("isCollapsed", isExpanded, collapseViewRef.current);
              collapseViewRef.current?.scrollIntoView({
                behavior: "auto",
              });
            } else {
              collapseViewRef.current?.scrollIntoView({
                behavior: "auto",
                block: "end",
              });
            }
          }, 0);
        }}
        className="cursor-pointer hover:bg-gray-100 hover:dark:bg-gray-800 rounded-lg"
        ref={collapseViewRef}
      >
        <Divider>
          {title}
          {isExpanded ? (
            <i className="ri-arrow-up-s-line text-xl"></i>
          ) : (
            <i className="ri-arrow-down-s-line text-xl"></i>
          )}
        </Divider>
      </div>

      <Collapse isOpened={isExpanded}>{children}</Collapse>
    </div>
  );
};
