import throttle from "lodash/throttle";
import { useCallback, useEffect, useRef } from "react";

const defaultOption = { leading: false, trailing: true };
export function useThrottle(
  cb,
  delay,
  options?: { leading: boolean; trailing: boolean }
) {
  if (!options) {
    options = defaultOption; // add custom lodash options
  }
  const cbRef = useRef(cb);
  // use mutable ref to make useCallback/throttle not depend on `cb` dep
  useEffect(() => {
    cbRef.current = cb;
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    throttle<any>((...args) => cbRef.current(...args), delay, options),
    [delay]
  );
}
