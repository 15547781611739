import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const KnowledgeQueryLogsViewerModal = React.lazy(
  () => import("./knowledge-query-logs-viewer.modal"),
);

const KnowledgeQueryLogsViewer: iModalProps<
  typeof KnowledgeQueryLogsViewerModal,
  {}
> = {
  name: "knowledge-query-logs",
  component: GenerateModalWithSuspense(KnowledgeQueryLogsViewerModal),
};

export default KnowledgeQueryLogsViewer;
