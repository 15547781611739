import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const CreatingToursWizardModal = React.lazy(
  () => import("./create-tours-wizard.modal"),
);

const CreatingToursWizard: iModalProps<typeof CreatingToursWizardModal, {}> = {
  name: "create-tours-wizard",
  component: GenerateModalWithSuspense(CreatingToursWizardModal),
};

export default CreatingToursWizard;
