import { iProactiveCampaign } from "@sdk/proactive-campaigns/proactive-campaigns-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { proactiveCampaignsDefaultQueryConfig } from "./proactive-campaigns-default-query";

export const {
  slice: ProactiveCampaignsSlice,
  reducers: ProactiveCampaignsSliceReducer,
  actions: {
    setEntityQueryResults: setProactiveCampaignsQueryResults,
    setEntity: setProactiveCampaign,
    setEntities: setProactiveCampaigns,
    patchEntity: patchProactiveCampaign,
    addNewEntity: addNewProactiveCampaign,
    setEntityFetchingStatus: setProactiveCampaignFetchingStatus,
    setEntitiesFetchingStatus: setProactiveCampaignsFetchingStatus,
    setEntityFailedStatus: setProactiveCampaignFailedStatus,
    setEntityQueryError: setProactiveCampaignsQueryError,
    resetQuery: resetProactiveCampaignsQuery,
    setQueryList: setProactiveCampaignQueryList,
  },
} = CreateEntitySlice<iProactiveCampaign>({
  sliceName: "proactiveCampaigns",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...proactiveCampaignsDefaultQueryConfig,
        alias: "all",
      };
    }
  },
});
