import { iArticleCollection } from "@sdk/knowledge-base/knowledge-base.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { ccArticleCollectionDefaultQueryConfig } from "./cc-article-collection-default-query";

export const {
  slice: CCArticleCollectionsSlice,
  reducers: CCArticleCollectionsSliceReducer,
  actions: { setEntityQueryResults: setArticleCollectionsQueryResults }
} = CreateEntitySlice<iArticleCollection>({
  sliceName: "ccArticleCollections",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...ccArticleCollectionDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
