import { axios, AxiosResponse } from "@sdk/axios";
import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import {
  PaginatedOptions,
  PaginatedResults,
} from "@sdk/utils/paginated-results";
import { UserTracker } from "user-tracker";
import {
  iProactiveCampaign,
  iProactiveCampaignLog,
} from "./proactive-campaigns-model";

export class ProactiveCampaignCampaignsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.proactiveCampaigns.configure(config);
  }

  proactiveCampaigns = Object.assign(
    new EntityServices<iProactiveCampaign>(SDKConfig, "proactive-campaigns", {
      onCreate: () => {
        UserTracker.track("PROACTIVE_CAMPAIGN - Create", {});
      },
      onDelete: () => {
        UserTracker.track("PROACTIVE_CAMPAIGN - Delete", {});
      },
    }),
    {
      queryLogs: async (
        campaignId: string,
        req: { query: any; options: PaginatedOptions },
      ) => {
        const results: AxiosResponse<PaginatedResults<iProactiveCampaignLog>> =
          await axios.post(
            this.config.basePath +
              `/proactive-campaigns/${campaignId}/logs/query`,
            req,
            {
              headers: {
                Authorization: this.config.token,
              },
            },
          );
        return results.data;
      },
      queryReport: async (campaignId: string, req: { query: any }) => {
        const results: AxiosResponse<{ count: number }> = await axios.post(
          this.config.basePath + `/proactive-campaigns/${campaignId}/report`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
