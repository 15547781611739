import { useState } from "react";
import { arrayMove } from "react-sortable-hoc";

export const useSortableTable = <T,>(
  initialData: T[],
  onSorted?: (newState: T[]) => any
) => {
  const [state, setState] = useState(initialData);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const dataSource = state;
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        ([] as any).concat(dataSource),
        oldIndex,
        newIndex
      ).filter(el => !!el) as any[];
      setState(newData);
      onSorted && onSorted(newData);
    }
  };
  return {
    state,
    setState,
    onSortEnd
  };
};
