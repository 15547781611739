import { SDK } from "@sdk";
import { iArticle } from "@sdk/knowledge-base/knowledge-base.models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { ccArticleDefaultQueryConfig } from "./cc-article-default-query";
import { CCArticlesSlice as ccArticlesSlice } from "./cc-articles.slice";

export const loadAllCCArticles = (
  store: Store<iStore>,
  forceReload?: boolean
) =>
  loadCCArticlesQuery(ccArticleDefaultQueryConfig, "all")(store, forceReload);

export const ccLoadArticlesOfCollection = (collectionId: string) => (
  store: Store<iStore>,
  forceReload?: boolean
) =>
  loadCCArticlesQuery(
    {
      query: { collections: collectionId },
      options: ccArticleDefaultQueryConfig.options
    },
    JSON.stringify({
      query: { collections: collectionId },
      options: ccArticleDefaultQueryConfig.options
    })
  )(store, forceReload);

export const {
  loadEntityQueries: loadCCArticlesQuery,
  loadEntityById: loadCCArticleById,
  loadEntitiesByIds: bulkCCLoadArticlesByIds
} = CreateHelpersForEntity<iStore, iArticle>({
  sliceName: "ccArticles",
  slice: ccArticlesSlice,
  isPaginatedQueries: true,
  fetchPageSize: 100,
  loadEntityQueryProvider: query => SDK.ccArticles.query(query),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.ccArticles.getById(entityId),
  loadEntityByIdsProvider: (articleIds: string[]) =>
    (async () => {
      const articles = await Promise.all(
        articleIds.map(articleId => SDK.ccArticles.getById(articleId))
      );
      return articles;
    })()
});
