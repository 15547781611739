import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddCustomReportModal = React.lazy(
  () => import("./add-custom-report.modal"),
);

const AddCustomReport: iModalProps<typeof AddCustomReportModal, {}> = {
  name: "add-custom-report",
  component: GenerateModalWithSuspense(AddCustomReportModal),
};

export default AddCustomReport;
