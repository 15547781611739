import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ContactProfileV2Modal = React.lazy(
  () => import("./contact-profile-v2.modal"),
);

const ContactProfile: iModalProps<
  typeof ContactProfileV2Modal,
  { contactId: string }
> = {
  name: "contact-profile",
  component: GenerateModalWithSuspense(ContactProfileV2Modal),
};

export default ContactProfile;
