import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const APIVideoPlayerPreviewModal = React.lazy(
  () => import("./api-video-player-preview.modal"),
);

const APIVideoPlayerPreview: iModalProps<
  typeof APIVideoPlayerPreviewModal,
  { videoId: string; title: string }
> = {
  name: "api-video-player-preview",
  component: GenerateModalWithSuspense(APIVideoPlayerPreviewModal),
};

export default APIVideoPlayerPreview;
