import { iSelfServicePortal } from "@sdk/knowledge-base/knowledge-base.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { selfServicePortalsDefaultQueryConfig } from "./self-service-portals-default-query";

export const {
  slice: SelfServicePortalsSlice,
  reducers: SelfServicePortalsSliceReducer,
  actions: {
    setEntityQueryResults: setSelfServicePortalsQueryResults,
    setEntity: setSelfServicePortal,
    setEntities: setSelfServicePortals,
    patchEntity: patchSelfServicePortal,
    addNewEntity: addNewSelfServicePortal,
    setEntityFetchingStatus: setSelfServicePortalFetchingStatus,
    setEntitiesFetchingStatus: setSelfServicePortalsFetchingStatus,
    setEntityFailedStatus: setSelfServicePortalFailedStatus,
    setEntityQueryError: setSelfServicePortalsQueryError,
    resetQuery: resetSelfServicePortalsQuery
  }
} = CreateEntitySlice<iSelfServicePortal>({
  sliceName: "selfServicePortals",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...selfServicePortalsDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
