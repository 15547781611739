import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iConversationTag } from "@sdk/user-management/user-management.models";
import { Button, Divider, Form, Input, message, Modal, Switch } from "antd";
import { useForm } from "antd/lib/form/Form";
import classnames from "classnames";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { setOrganization } from "store/modules/workspace/workspace.actions";
import { selectContactTags } from "store/modules/workspace/workspace.selectors";
import { ColorInput } from "../../../../common/color-input/color-input";
import "./crm-tags.scss";

export const CRMTagsConfiguration = () => {
  const [tagsForm] = useForm();
  const contactTags = useSelector(selectContactTags);

  const existingConversationTags = useMemo(() => {
    const _contactTags = _.cloneDeep(contactTags);

    const grouped = _.groupBy(
      contactTags?.map((item) => ({
        ...item,
        savedInBackend: true,
        isVisible: !item.isHidden,
      })),
      "tagGroup",
    );
    return grouped;
  }, [contactTags]);

  const {
    doAction: onUpdate,
    isProcessing,
    response,
    dispatch,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (values: iConversationTag[]) =>
        SDK.patchAvailableContactTags(values).then((res) => {
          dispatch(setOrganization(res));
        }),
      successMessage: "Tags have been updated",
      failureMessage: "Something went wrong",
    }),
    [],
  );

  const [addTagGroupForm] = useForm();

  const [isAddTagGroupModalVisible, setAddTagGroupModalVisibility] =
    useState(false);

  const [availableTagGroups, setAvailableTagGroups] = useState(
    Object.keys(existingConversationTags),
  );
  const [visibleTagGroups, setVisibleTagGroups] = useState(
    Object.keys(existingConversationTags),
  );
  useEffect(() => {
    setAvailableTagGroups(Object.keys(existingConversationTags));
    setVisibleTagGroups(Object.keys(existingConversationTags));
    tagsForm.setFieldsValue(existingConversationTags);
  }, [existingConversationTags, tagsForm]);

  //react-beautiful-dnd functions
  const reorder = (list: string[], startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const orderedAvailableTagGroups = reorder(
      availableTagGroups,
      result.source.index,
      result.destination.index,
    );
    setAvailableTagGroups(orderedAvailableTagGroups);
    console.log("setAvailableTagGroups", availableTagGroups);
  }
  //end of react-beautiful-dnd functions
  return (
    <div>
      {/* <div className="header flex flex-row mb-8">
        <div className="text-gray-700 dark:text-gray-200 flex-1  mode_transition">
          <div className="text-5xl">Conversation Tags</div>
          Conversation Tags allows you to easily group and identify
          conversations. You can use tag groups create multiple segmentation
          that helps you in reporting
        </div>
      </div> */}
      <div className="flex flex-row justify-end py-8">
        <Button
          type="dashed"
          onClick={() => setAddTagGroupModalVisibility(true)}
          icon={<i className="ri-add-circle-line"></i>}
        >
          Add Tag Group
        </Button>
      </div>
      <div>
        <Form
          name="settings"
          //initialValues={existingConversationTags}
          layout="vertical"
          form={tagsForm}
          onFinish={() => {
            const values = tagsForm.getFieldsValue();
            console.log("values", values);
            // const groups = Object.keys(values);
            // console.log("groups", groups);
            const tags = _.flatten(
              availableTagGroups.map((group) =>
                values[group].map((tag) => ({
                  ...tag,
                  tagGroup: group === "undefined" ? "General Tags" : group,
                  isHidden: !tag.isVisible,
                })),
              ),
            );
            onUpdate(tags);
          }}
        >
          {/* // Tags */}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {availableTagGroups.map((tagGroup, index) => (
                    <Draggable
                      draggableId={tagGroup}
                      index={index}
                      key={tagGroup}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="conversation-tags-container pb-3"
                        >
                          <div className="border dark:border-gray-700 border-gray-300">
                            <div className="title font-bold text-2xl py-2  pl-5 flex justify-between">
                              <div>
                                <i className="ri-drag-move-2-fill text-lg  mr-2"></i>
                                {tagGroup === "undefined"
                                  ? "General Tags"
                                  : tagGroup}
                              </div>
                              <div>
                                {tagGroup !== "undefined" &&
                                  tagGroup !== "General Tags" && (
                                    <i
                                      className="ri-close-line text-lg cursor-pointer mr-2"
                                      onClick={() => {
                                        setAvailableTagGroups(
                                          availableTagGroups.filter(
                                            (tagGroups) =>
                                              tagGroups !== tagGroup,
                                          ),
                                        );
                                      }}
                                    ></i>
                                  )}

                                <i
                                  className={classnames(
                                    visibleTagGroups.includes(tagGroup)
                                      ? "ri-arrow-up-s-line"
                                      : "ri-arrow-down-s-line",
                                    " text-lg cursor-pointer mr-2",
                                  )}
                                  onClick={() => {
                                    setVisibleTagGroups(
                                      visibleTagGroups.includes(tagGroup)
                                        ? visibleTagGroups.filter(
                                            (tagGroups) =>
                                              tagGroups !== tagGroup,
                                          )
                                        : [...visibleTagGroups, tagGroup],
                                    );
                                  }}
                                ></i>
                              </div>
                            </div>
                            <div
                              className={classnames(
                                visibleTagGroups.includes(tagGroup)
                                  ? ""
                                  : "hidden",
                                "border-t  p-5 ease-in-out dark:border-gray-700 border-gray-300",
                              )}
                            >
                              <Form.List name={tagGroup}>
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map((field) => (
                                      <div key={field.key} className="mb-4">
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "label"]}
                                          className="flex-1 w-full"
                                        >
                                          <Input
                                            placeholder="Tag Label"
                                            // Disable All Fields Saved in backend
                                            disabled={
                                              tagsForm.getFieldValue([
                                                tagGroup,
                                                field.name,
                                                "savedInBackend",
                                              ]) === true
                                            }
                                            addonBefore={
                                              <Form.Item
                                                {...field}
                                                name={[field.name, "color"]}
                                              >
                                                <ColorInput />
                                              </Form.Item>
                                            }
                                            addonAfter={
                                              <div className="flex flex-row justify-center items-center">
                                                <Form.Item
                                                  {...field}
                                                  name={[
                                                    field.name,
                                                    "isVisible",
                                                  ]}
                                                  valuePropName="checked"
                                                >
                                                  <Switch
                                                    checkedChildren={
                                                      <i className="ri-eye-line cursor-pointer"></i>
                                                    }
                                                    unCheckedChildren={
                                                      <i className="ri-eye-off-line cursor-pointer"></i>
                                                    }
                                                    size="small"
                                                  />
                                                </Form.Item>

                                                <Divider type="vertical" />
                                                <Button
                                                  type="text"
                                                  onClick={() =>
                                                    remove(field.name)
                                                  }
                                                >
                                                  <i className="ri-indeterminate-circle-line text-lg cursor-pointer"></i>
                                                </Button>
                                              </div>
                                            }
                                          />
                                        </Form.Item>
                                      </div>
                                    ))}
                                    {fields.length === 0 && (
                                      <div className="font-bold mb-4">
                                        No Tags Found
                                      </div>
                                    )}
                                    <Form.Item>
                                      <Button
                                        type="dashed"
                                        onClick={() =>
                                          add({
                                            label: "",
                                            color: "#1273de",
                                            isVisible: true,
                                          })
                                        }
                                        block
                                        icon={
                                          <i className="ri-add-circle-line"></i>
                                        }
                                      >
                                        Add Tag
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </Form.List>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="flex flex-row justify-end">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={isProcessing}
                icon={<i className="ri-save-line"></i>}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </Form>

        <Modal
          title={
            <ModalTitle
              title="Add Tag Group"
              icon={<i className="ri-folder-add-line"></i>}
            />
          }
          open={isAddTagGroupModalVisible}
          onOk={async () => {
            try {
              await addTagGroupForm.validateFields();
              const { label } = addTagGroupForm.getFieldsValue();
              setAvailableTagGroups(_.uniq([...availableTagGroups, label]));
              setVisibleTagGroups(_.uniq([...availableTagGroups, label]));
              setAddTagGroupModalVisibility(false);
            } catch (e) {
              message.error("Please check your input");
            }
          }}
          onCancel={() => {
            setAddTagGroupModalVisibility(false);
          }}
          okText="Add"
          data-click-context="Add Tag Group Modal"
        >
          <Form layout="vertical" form={addTagGroupForm}>
            {/* Label */}
            <Form.Item
              name="label"
              label="Label"
              rules={[{ required: true, message: "Please enter a label" }]}
            >
              <Input placeholder="Tag Group Label" size="large" />
            </Form.Item>
          </Form>
          <DarkModeBg />
        </Modal>
      </div>
    </div>
  );
};
