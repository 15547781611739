export const messageCountOptions = [
  {
    value: JSON.stringify({
      $lt: 20
    }),
    title: "0-20"
  },
  {
    value: JSON.stringify({
      $gte: 20,
      $lt: 50
    }),
    title: "20-50"
  },
  {
    value: JSON.stringify({
      $gte: 50
    }),
    title: "50+"
  }
];
