import { IconConfiguration } from "@sdk/chat-connectors/chat-connectors.models";
import { Divider, Popover } from "antd";
import classNames from "classnames";
import { ImageInputWithUploader } from "components/modules/file-management/image-input-with-uploader";
import { useSelector } from "react-redux";
import { selectOrganizationId } from "store/modules/workspace/workspace.selectors";

const RiIconMapper = {
  STAR: <i className="ri-star-line"></i>,
  SHIELD_STAR: <i className="ri-shield-star-line"></i>,
  MAIL: <i className="ri-mail-send-line"></i>,
  AWARD: <i className="ri-award-line"></i>,
  FLAG: <i className="ri-flag-fill"></i>,
  GLOBAL: <i className="ri-global-line"></i>,
  REPLY: <i className="ri-reply-line"></i>,
  VIDEO_CHAT: <i className="ri-video-chat-line"></i>,
  MAGIC: <i className="ri-magic-line"></i>,
  TOOLS: <i className="ri-tools-line"></i>,
  BUG: <i className="ri-bug-fill"></i>,
  TV: <i className="ri-tv-2-fill"></i>,
  PHONE: <i className="ri-phone-fill"></i>,
  FINGER_PRINT: <i className="ri-fingerprint-line"></i>,
  RSS: <i className="ri-rss-line"></i>,
  FILE: <i className="ri-file-text-line"></i>,
  FOLDER: <i className="ri-folder-2-line"></i>,
  WALLET: <i className="ri-wallet-fill"></i>,
  SHOPPING: <i className="ri-shopping-bag-line"></i>,
  TAG: <i className="ri-price-tag-2-line"></i>,
  COUPON: <i className="ri-coupon-line"></i>,
  CURRENCY: <i className="ri-currency-line"></i>,
  GIFT: <i className="ri-gift-2-fill"></i>,
  HEART: <i className="ri-heart-fill"></i>,
  PULSE: <i className="ri-pulse-line"></i>,
  MAP: <i className="ri-map-pin-line"></i>,
  ROCKET: <i className="ri-rocket-2-line"></i>,
  CAR: <i className="ri-car-fill"></i>,
  SETTINGS: <i className="ri-settings-3-fill"></i>,
  ACCOUNT: <i className="ri-account-box-line"></i>,
};

export const IconSelector = ({
  value,
  onChange,
}: {
  value?: IconConfiguration;
  onChange?: (config: IconConfiguration) => any;
}) => {
  const currentOrganizationId = useSelector(selectOrganizationId);
  return (
    <Popover
      content={
        <div style={{ width: 450, height: 450 }} className="overflow-auto p-4">
          <Divider className="font-bold">Custom</Divider>
          <ImageInputWithUploader
            value={value?.type === "CUSTOM" && value?.value}
            type="ICON_SELECTOR"
            entityId={currentOrganizationId!}
            onChange={(url) => {
              onChange &&
                onChange({
                  type: "CUSTOM",
                  value: url,
                });
            }}
          />
          <Divider className="font-bold">Presets</Divider>
          <div className="flex flex-wrap flex-row">
            {Object.keys(RiIconMapper).map((iconKey) => (
              <div
                key={iconKey}
                style={{ width: 50, height: 50 }}
                className={classNames(
                  "flex flex-row justify-center items-center border rounded-md border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer m-2",
                  {
                    "border border-gray-300 dark:border-gray-700 bg-gray-300 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-700":
                      value?.value === iconKey,
                  },
                )}
                onClick={() => {
                  onChange &&
                    onChange({
                      type: "REMIX",
                      value: iconKey,
                    });
                }}
              >
                {RiIconMapper[iconKey]}
              </div>
            ))}
          </div>
        </div>
      }
      trigger="click"
      overlayClassName="color-input-popover"
    >
      <div
        style={{ width: 50, height: 50 }}
        className="flex flex-row justify-center items-center border rounded-lg border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer"
      >
        <IconRender icon={value} />
      </div>
    </Popover>
  );
};

export const IconRender = ({
  icon: value,
  className,
}: {
  icon?: IconConfiguration;
  className?: string;
}) => {
  return (
    <span className={className}>
      {value?.type === "CUSTOM" && (
        <img
          src={value?.value}
          className={classNames("w-full h-full")}
          alt="icon"
        />
      )}
      {value?.type === "REMIX" &&
        (RiIconMapper[value?.value] || <i className="ri-magic-line"></i>)}
      {!value?.type && <i className="ri-magic-line"></i>}
    </span>
  );
};
