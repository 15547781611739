import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import { iMarketingReference } from "./marketing-references.models";

export class MarketingReferencesServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.marketingReferences.configure(config);
  }

  marketingReferences = Object.assign(
    new EntityServices<iMarketingReference>(SDKConfig, "marketing-reference"),
    {},
  );
}
