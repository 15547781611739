import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iNewsFeedType } from "@sdk/newsfeed-items/newsfeed-model";
import React from "react";

const AddEditNewsfeedTypeModal = React.lazy(
  () => import("./add-edit-news-feed-type.modal"),
);

const AddEditNewsfeedType: iModalProps<
  typeof AddEditNewsfeedTypeModal,
  {
    initialValue?: iNewsFeedType;
    mode: "ADD" | "EDIT";
    onSave?: () => any;
  }
> = {
  name: "add-edit-newsfeed-type",
  component: GenerateModalWithSuspense(AddEditNewsfeedTypeModal),
};

export default AddEditNewsfeedType;
