import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { useSDK } from "@sdk";
import { iFbConnectionData } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Alert, Descriptions, Divider, Form, Spin, Switch } from "antd";
import {
  ConfigurationEditorMiniBase,
  ConfigurationEditorMiniComponent,
} from "components/common/configuration-editor-mini";
import { ExternalAuthConnector } from "components/common/external-auth-connector-button/external-auth-connector-button";
import { LoadingIndicatorWithoutSpin } from "components/common/loading-indicator/loading-indicator";
import { SectionHeader } from "components/common/section-header";
import SimpleModal from "components/common/simple-empty-modal/simple-empty.modal-registry";
import { ConnectionStartWithChatBotConfig } from "components/modules/connections/common-automation-configs/start-with-chat-bot-config";
import { GeneralConnectionConfig } from "components/modules/connections/common-connection-settings/general-connection-config";
import { RoutingPreferencesForm } from "components/modules/connections/common-connection-settings/routing-preferences-form/routing-preferences-form";
import { RoutingRules } from "components/modules/conversations/components/routing-rules/routing-rules";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { stringArrayToSentence } from "utils/string-array-to-sentence";
import FBBlockedUsers from "../blocked-facebook-users/blocked-facebook-users.modal-registry";

export const ManageFacebookConnection = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const isDevMode = useSelector(selectIsAdvancedMode);
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const { doAction: syncConversations, isProcessing: isConversationSyncing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (connectionId: string) =>
          SDK.connections.loadAllRecentFbConversation(connectionId),
        successMessage: "Conversations have been synced",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const { doAction: syncPosts, isProcessing: isPostsSyncing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (connectionId: string) =>
          SDK.syncAllPosts(connectionId),
        successMessage: "Posts have been synced",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const fbConnectionData = connection?.data as iFbConnectionData;

  const {
    data: connectionStatus,
    isLoading,
    error,
    reload: reloadDebug,
  } = useSDK(
    (SDK) =>
      SDK.connections
        .checkFacebookAuthenticationStatus(connectionId)
        .then((d) => {
          const grantedScopes = d?.debugResponse?.data.scopes;
          if (!grantedScopes.includes("pages_read_user_content")) {
            throw {
              message: "Missing pages_read_user_content",
            };
          }
          return d;
        }),
    [connectionId],
    !connectionId,
  );

  const {
    doAction: reAuthenticateFacebookConnection,
    isProcessing: isAuthenticating,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (connectionId: string, accessToken: string) =>
        SDK.connections
          .reAuthenticateFacebookConnection(connectionId, accessToken)
          .then((d) => {
            return reloadDebug();
          }),
      successMessage: "Re-authentication has been successful",
      failureMessage: "Something went wrong",
    }),
    [reloadDebug],
  );

  const { triggerTempModal } = useModalPanels();

  return (
    <>
      <Spin
        spinning={!error && isLoading}
        indicator={<LoadingIndicatorWithoutSpin />}
        wrapperClassName="flex-1 flex fill-spinner"
      >
        <div className="animated fadeInLeftMin  flex flex-col w-full">
          <SectionHeader
            title={fbConnectionData?.page?.name || "Connected Page"}
            icon={<i className="ri-facebook-box-line"></i>}
            description="Chat routing, access, user limits, email notifications, etc."
          />
          <Divider />

          {error || (!isLoading && !connectionStatus?.debugResponse) ? (
            <>
              <Alert
                message={
                  <div className="font-bold">
                    It looks like your Facebook Integration is broken
                  </div>
                }
                description={
                  <div>
                    It could happen for the following reasons <br />
                    <ul>
                      <li>👉 You disconnected ClickConnector Manually</li>
                      <li>
                        👉 The user who gave permission is no longer part of the
                        page
                      </li>
                      <li>
                        👉 The user who gave permission changed their password
                      </li>
                    </ul>
                    <div
                      className="flex flex-row justify-end items-center"
                      style={{ width: 202 }}
                    >
                      <Spin
                        spinning={isAuthenticating || (!error && isLoading)}
                        indicator={<LoadingIndicatorWithoutSpin />}
                        wrapperClassName="flex-1 flex fill-spinner mt-4"
                      >
                        <ExternalAuthConnector
                          type="FACEBOOK"
                          onAuthenticated={({ accessToken }) => {
                            reAuthenticateFacebookConnection(
                              connection.id,
                              accessToken,
                            );
                          }}
                          label="Re-Connect Facebook"
                        />
                      </Spin>
                    </div>
                  </div>
                }
                type="error"
                showIcon
                className="p-4 rounded-lg my-2 mb-4"
              />
            </>
          ) : (
            <></>
          )}

          {isDevMode &&
            !(error || (!isLoading && !connectionStatus?.debugResponse)) && (
              <div>
                <Spin
                  spinning={isAuthenticating || (!error && isLoading)}
                  indicator={<LoadingIndicatorWithoutSpin />}
                  wrapperClassName="flex-1 flex fill-spinner"
                >
                  <ExternalAuthConnector
                    type="FACEBOOK"
                    onAuthenticated={({ accessToken }) => {
                      reAuthenticateFacebookConnection(
                        connection.id,
                        accessToken,
                      );
                    }}
                    label="Re-Authenticate Facebook"
                    insider={true}
                  />
                </Spin>
              </div>
            )}
        </div>
      </Spin>

      <div className="flex flex-row gap-4">
        <div className="flex-1">
          <Divider>Facebook Connection</Divider>

          <ConfigurationEditorMiniBase
            icon={"ri-plug-line"}
            title={"Connected Page Details"}
            description={"Access, expiration, integration id etc"}
            onClick={() => {
              triggerTempModal(SimpleModal, {
                config: {
                  title: "Connected Page Details",
                  content: (
                    <Descriptions
                      title=""
                      bordered
                      layout="horizontal"
                      column={1}
                      style={{ maxWidth: 600 }}
                    >
                      <Descriptions.Item label="Connected Page">
                        {fbConnectionData?.page?.name}
                      </Descriptions.Item>
                      <Descriptions.Item label="Page Category">
                        {fbConnectionData?.page?.category}
                      </Descriptions.Item>
                      {!error && (
                        <>
                          <Descriptions.Item label="Application">
                            {connectionStatus?.debugResponse?.data.application}
                          </Descriptions.Item>
                          <Descriptions.Item label="Application">
                            {connectionStatus?.debugResponse?.data.application}
                          </Descriptions.Item>
                          <Descriptions.Item label="Application">
                            {connectionStatus?.debugResponse?.data.application}{" "}
                            ({connectionStatus?.debugResponse?.data.app_id})
                          </Descriptions.Item>
                          <Descriptions.Item label="Data Access Expiry">
                            {dayjs(
                              connectionStatus?.debugResponse?.data
                                .data_access_expires_at * 1000,
                            ).toString()}
                          </Descriptions.Item>
                          <Descriptions.Item label="Access Issued at">
                            {dayjs(
                              connectionStatus?.debugResponse?.data.issued_at *
                                1000,
                            ).toString()}
                          </Descriptions.Item>
                          <Descriptions.Item label="Profile ID">
                            {connectionStatus?.debugResponse?.data.profile_id}
                          </Descriptions.Item>
                          <Descriptions.Item label="Granted Permissions">
                            {stringArrayToSentence(
                              connectionStatus?.debugResponse?.data.scopes ||
                                [],
                            )}
                          </Descriptions.Item>
                          <Descriptions.Item label="UserId">
                            {connectionStatus?.debugResponse?.data.user_id}
                          </Descriptions.Item>
                        </>
                      )}
                    </Descriptions>
                  ),
                  icon: <i className="ri-plug-line"></i>,
                  width: 800,
                },
              });
            }}
            actionButtonIcon={<i className="ri-eye-line"></i>}
          />

          <ConfigurationEditorMiniBase
            icon={"ri-spam-3-line"}
            title={"Blocked Users"}
            description={"Manage Blocked Faebook Users"}
            onClick={() => {
              triggerTempModal(FBBlockedUsers, { connectionId });
            }}
          />

          <ConfigurationEditorMiniBase
            icon={"ri-refresh-line"}
            title={"(Re)Sync Conversations"}
            description={""}
            isProcessing={isConversationSyncing}
            actionButtonIcon={<i className="ri-arrow-right-line"></i>}
            onClick={() => {
              syncConversations(connectionId);
            }}
          />

          {/* loading={isPostsSyncing} */}
          <ConfigurationEditorMiniBase
            icon={"ri-refresh-line"}
            title={"(Re)Sync Posts"}
            description={""}
            isProcessing={isPostsSyncing}
            actionButtonIcon={<i className="ri-arrow-right-line"></i>}
            onClick={() => {
              syncPosts(connectionId);
            }}
          />

          {/* Routing Settings */}
        </div>
        <div className="flex-1">
          <Divider>Chat Routing Settings</Divider>

          {/* Routing Settings */}
          <RoutingPreferencesForm connectionId={connectionId} />
          <ConnectionStartWithChatBotConfig connectionId={connectionId!} />

          <ConfigurationEditorMiniComponent
            entityType="CONNECTION"
            entityId={connectionId!}
            icon={"ri-route-line"}
            title="Auto Campaign Routing"
            description="Route Conversations based on campaigns"
            miniEditor={
              <>
                <Form.Item
                  valuePropName="checked"
                  name={["data", "autoRouteConfig", "active"]}
                >
                  <Switch size="small" />
                </Form.Item>
              </>
            }
          >
            <div className="auto-trigger flex flex-row items-center justify-between">
              <div className="font-bold">Enable 'Auto Campaign Routing'</div>
              <Form.Item
                name={["data", "autoRouteConfig", "active"]}
                hasFeedback={false}
                valuePropName="checked"
                style={{ marginBottom: 0, marginLeft: 5 }}
              >
                <Switch />
              </Form.Item>
            </div>
            <Form.Item shouldUpdate>
              {({ getFieldValue }) => {
                const configEnabled = getFieldValue([
                  "data",
                  "autoRouteConfig",
                  "active",
                ]);
                if (configEnabled) {
                  return (
                    <div className="mt-4">
                      <Form.Item
                        label=""
                        name={["data", "autoRouteConfig", "rules"]}
                      >
                        <RoutingRules />
                      </Form.Item>
                    </div>
                  );
                }
                return <></>;
              }}
            </Form.Item>
          </ConfigurationEditorMiniComponent>
        </div>
      </div>

      <GeneralConnectionConfig
        connectionId={connectionId}
        withoutHeader
        powerSettingsDefaultExpanded
      />
    </>
  );
};
