import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const TaskViewerModal = React.lazy(() => import("./task-viewer.modal"));

const TaskViewer: iModalProps<typeof TaskViewerModal, { taskId: string }> = {
  name: "task-viewer",
  component: GenerateModalWithSuspense(TaskViewerModal),
};

export default TaskViewer;
