import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const CreateEmailSeriesWizardModal = React.lazy(
  () => import("./create-email-series-wizard.modal"),
);

const CreateEmailSeriesWizard: iModalProps<
  typeof CreateEmailSeriesWizardModal,
  {}
> = {
  name: "create-email-series-wizard",
  component: GenerateModalWithSuspense(CreateEmailSeriesWizardModal),
};

export default CreateEmailSeriesWizard;
