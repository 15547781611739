import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, Modal, Popconfirm, Space } from "antd";
import { CollapsibleConfigurationSection } from "components/common/collapsible-configuration-panel/collapsible-configuration-container";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import JSONModal from "utils/json-modal/json-modal";
import { StartTroubleshoot } from "../../../../../modules/organization-management/general/account-configurations/start-troubleshoot/start-troubleshoot";

export const TroubleshootWorkspace = () => {
  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  const organization = useSelector(selectOrganization);

  const [
    troubleshootOrganizationResults,
    setTroubleshootOrganizationResults
  ] = useState({} as any);

  const {
    doAction: troubleshootOrganization,
    isProcessing: isTroubleshootProcessing
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => (organizationId: string) =>
        SDK.troubleshoot(organizationId).then(data => {
          setTroubleshootOrganizationResults(data);
          // console.log("troubleshootOrganization result", data);
        }),
      successMessage: "Done",
      failureMessage: "Something went wrong"
    }),
    []
  );

  const {
    doAction: deleteWorkspace,
    isProcessing: isDeletingWorkspace
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => (confirmation: string) =>
        SDK.dangerousDeletion.deleteOrganization(confirmation),
      successMessage: "Connection and associated data is deleted",
      failureMessage: "Something went wrong"
    }),
    []
  );

  const {
    doAction: triggerAutoSetup,
    isProcessing: isAutoSetupProcessing
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => edits => SDK.autoSetupAccount(),
      // successMessage: "Done",
      failureMessage: "Something went wrong"
    }),
    []
  );

  if (isAdvancedMode) {
    return (
      <CollapsibleConfigurationSection
        icon={"ri-tools-line"}
        title={"Troubleshoot Workspace"}
        description={"All about your account details"}
      >
        <>
          <div className="flex items-center justify-between mb-8 ml-2">
            <div>
              <Space>
                <Button
                  type="primary"
                  size="small"
                  onClick={e => {
                    organization && troubleshootOrganization(organization?.id);
                    Modal.info({
                      title: "Troubleshoot Result",
                      content: organization && (
                        <StartTroubleshoot
                          troubleshootOrganizationResults={
                            troubleshootOrganizationResults
                          }
                        />
                      ),
                      onOk() {}
                    });
                  }}
                  icon={<i className="ri-tools-fill"></i>}
                >
                  Start Troubleshoot
                </Button>
                <Button
                  type="primary"
                  size="small"
                  onClick={e => {
                    triggerAutoSetup();
                  }}
                  loading={isAutoSetupProcessing}
                  icon={<i className="ri-tools-fill"></i>}
                >
                  Reset Configs
                </Button>

                <i
                  className="ri-code-box-line cursor-pointer ml-2"
                  onClick={() => {
                    JSONModal(organization!);
                  }}
                />
              </Space>
            </div>
            <div className="">
              {window.location.href.includes("localhost") && (
                <Popconfirm
                  placement="leftTop"
                  title="Are you sure? All conversation and contact records will be permanently removed"
                  onConfirm={() => {
                    deleteWorkspace("CONFIRM");
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="text"
                    icon={<i className="ri-delete-bin-3-line"></i>}
                    loading={isDeletingWorkspace}
                  >
                    Delete Workspace
                  </Button>
                </Popconfirm>
              )}
            </div>
          </div>
        </>
      </CollapsibleConfigurationSection>
    );
  }
  return <></>;
};
