import { iConnection } from "@sdk/conversations/conversations.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { connectionDefaultQueryConfig } from "./connection-default-query";

export const {
  slice: ConnectionsSlice,
  reducers: ConnectionsSliceReducer,
  actions: {
    setEntityQueryResults: setConnectionsQueryResults,
    setEntity: setConnection,
    setEntities: setConnections,
    patchEntity: patchConnection,
    addNewEntity: addNewConnection,
    setEntityFetchingStatus: setConnectionFetchingStatus,
    setEntitiesFetchingStatus: setConnectionsFetchingStatus,
    setEntityFailedStatus: setConnectionFailedStatus,
    setEntityQueryError: setConnectionsQueryError,
    resetQuery: resetConnectionsQuery
  }
} = CreateEntitySlice<iConnection>({
  sliceName: "connections",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...connectionDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
