import { iDynamicForm } from "@sdk/dynamic-forms/dynamic-forms-model";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectDynamicFormQueryMap,
  selectEntityMap: selectDynamicFormsMap,
  selectQueryMapAll: selectDynamicFormsMapAll,
  selectQueryAllList: selectDynamicFormsAllList,
  selectAllEntities: selectAllDynamicForms,
  selectAllEntitiesQuery: selectAllDynamicFormsQuery,
  selectEntityById: selectDynamicFormById,
  selectEntityQuery: selectDynamicFormQuery,
  selectEntityQueryList: selectDynamicFormQueryList
} = CreateSelectorForEntities<iStore, iDynamicForm>({
  sliceName: "dynamicForms"
});

export const selectDynamicFormByConnectionId = (connectionId: string) => (
  state: iStore
) => {
  return _.find(selectAllDynamicForms(state), {
    connectionId
  });
};
