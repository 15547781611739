import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const LiveChatWidgetStrategyModal = React.lazy(
  () => import("./live-chat-widget-strategy.modal"),
);

const LiveChatWidgetStrategy: iModalProps<
  typeof LiveChatWidgetStrategyModal,
  {}
> = {
  name: "live-chat-widget-strategy",
  component: GenerateModalWithSuspense(LiveChatWidgetStrategyModal),
};

export default LiveChatWidgetStrategy;
