import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { ApiUserType } from "@sdk/user-management/user-management.controller-models";
import React from "react";

const AddAPIUserModal = React.lazy(() => import("./add-api-user.modal"));

const AddAPIUser: iModalProps<typeof AddAPIUserModal, { type: ApiUserType }> = {
  name: "add-api-user",
  component: GenerateModalWithSuspense(AddAPIUserModal),
};

export default AddAPIUser;
