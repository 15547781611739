import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ChatbotTemplatesExplorerModal = React.lazy(
  () => import("./template-explorer.modal"),
);

const ChatbotTemplatesExplorer: iModalProps<
  typeof ChatbotTemplatesExplorerModal,
  {}
> = {
  name: "chat-bot-template-explorer",
  component: GenerateModalWithSuspense(ChatbotTemplatesExplorerModal),
};

export default ChatbotTemplatesExplorer;
