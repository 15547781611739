import { LazyJSONViewer } from "@libs/lazy-json-viewer/lazy-json-viewer";
import { Modal } from "antd";

import PerfectScrollbar from "react-perfect-scrollbar";
import "./json-modal.scss";

const JSONModal = (JSONData: object) => {
  return Modal.success({
    content: (
      <PerfectScrollbar style={{ maxHeight: 800 }}>
        <LazyJSONViewer src={JSONData} />
      </PerfectScrollbar>
    ),

    maskClosable: true,
    icon: <></>,
  });
};

export default JSONModal;
