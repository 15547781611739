import { Select } from "antd";
import _ from "lodash";
import { useEffect } from "react";
import { loadOrganization } from "store/modules/workspace/workspace.helpers";
import { selectTicketTypes } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const TicketTypeSelector = ({
  value,
  onChange,
  autoSelectFirstOption,
  allowClear,
  autoFocus,
}: {
  value?: string;
  onChange?: (ticketTypeId: string) => any;
  autoSelectFirstOption?: boolean;
  allowClear?: boolean;
  autoFocus?: boolean;
}) => {
  const { state: ticketTypes, retry: reload } = useQueryWithStore(
    selectTicketTypes,
    loadOrganization,
  );

  useEffect(() => {
    // * Todo: Could properly rewrite this
    if (autoSelectFirstOption) {
      if (value) {
        if (!_.find(ticketTypes, { id: value })) {
          if (ticketTypes.length > 0) {
            onChange && onChange(ticketTypes[0].id!);
          }
          onChange && onChange(undefined!);
        }
      } else {
        if (ticketTypes.length > 0) {
          onChange && onChange(ticketTypes[0].id!);
        }
      }
    } else {
      if (value) {
        if (!_.find(ticketTypes, { id: value })) {
          onChange && onChange(undefined!);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSelectFirstOption, ticketTypes]);

  return (
    <Select
      showSearch
      value={value}
      placeholder="Select Ticket Type"
      filterOption={false}
      onChange={onChange}
      style={{ width: "100%" }}
      allowClear={allowClear}
      defaultOpen={autoFocus}
      autoFocus={autoFocus}
    >
      {(ticketTypes || []).map((form) => (
        <Select.Option key={form.id} value={form.id!}>
          <div className="flex flex-row">
            <div className="icon-container mr-2">
              <i className="ri-survey-line"></i>
            </div>
            <div className="label">{form.label}</div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};
