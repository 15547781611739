import { axios, AxiosResponse } from "@sdk/axios";
import { omit } from "lodash";

import {
  AggregateFunctions,
  AggregatePeriodTypes,
  iReportQuery
} from "./reports-controller-models";

export class ReportsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  /* ---------------------------------- Reports --------------------------------- */

  getConversationReports = async (query: iReportQuery) => {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/reports/conversations`,
      query.filters,
      {
        headers: {
          Authorization: this.config.token
        },
        params: {
          ...omit(query, "filters"),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
    );
    return results.data;
  };

  getContactReports = async (query: iReportQuery) => {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/reports/contacts`,
      query.filters,
      {
        headers: {
          Authorization: this.config.token
        },
        params: {
          ...omit(query, "filters"),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
    );
    return results.data;
  };

  getActivitiesReports = async (query: iReportQuery) => {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/reports/activities`,
      query.filters,
      {
        headers: {
          Authorization: this.config.token
        },
        params: {
          ...omit(query, "filters"),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
    );
    return results.data;
  };

  generateCompaniesReport = async (query: iReportQuery) => {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/reports/companies`,
      query.filters,
      {
        headers: {
          Authorization: this.config.token
        },
        params: {
          ...omit(query, "filters"),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
    );
    return results.data;
  };

  generateEngagementsReport = async (query: iReportQuery) => {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/reports/engagements`,
      query.filters,
      {
        headers: {
          Authorization: this.config.token
        },
        params: {
          ...omit(query, "filters"),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
    );
    return results.data;
  };

  generateOpportunitiesReport = async (query: iReportQuery) => {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/reports/opportunities`,
      query.filters,
      {
        headers: {
          Authorization: this.config.token
        },
        params: {
          ...omit(query, "filters"),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
    );
    return results.data;
  };

  getRoutingLogsReports = async (query: {
    startDate: number;
    endDate: number;
    filters: any;
    groupBy?: string;
    period?: AggregatePeriodTypes;
  }) => {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/reports/routing-logs`,
      query.filters,
      {
        headers: {
          Authorization: this.config.token
        },
        params: {
          ...omit(query, "filters")
        }
      }
    );
    return results.data;
  };

  generateAnalyticsReport = async (query: {
    startDate: number;
    endDate: number;
    filters: any;
    groupBy?: string;
    period?: AggregatePeriodTypes;
    aggregateFunction?: AggregateFunctions;
    aggregateTargetField?: string;
    dateField?: string;
  }) => {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/reports/analytics`,
      query.filters,
      {
        headers: {
          Authorization: this.config.token
        },
        params: {
          ...omit(query, "filters"),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
    );
    return results.data;
  };

  generateMessagesReport = async (query: {
    startDate: number;
    endDate: number;
    filters: any;
    groupBy?: string;
    period?: AggregatePeriodTypes;
    aggregateFunction?: AggregateFunctions;
    aggregateTargetField?: string;
    dateField?: string;
  }) => {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/reports/messages`,
      query.filters,
      {
        headers: {
          Authorization: this.config.token
        },
        params: {
          ...omit(query, "filters"),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
    );
    return results.data;
  };
}
