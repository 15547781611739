import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const QuoteEditorModal = React.lazy(() => import("./quote-editor.modal"));

const QuoteEditor: iModalProps<
  typeof QuoteEditorModal,
  {
    onAddAsAttachment?: (content: string) => any;
    holidayQuoteId: string;
  }
> = {
  name: "quote-editor",
  component: GenerateModalWithSuspense(QuoteEditorModal),
};

export default QuoteEditor;
