import { iFbPost } from "@sdk/facebook-posts/facebook-posts.models";
import { createSelector } from "reselect";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectFbPostQueryMap,
  selectEntityMap: selectFbPostsMap,
  selectQueryMapAll: selectFbPostsMapAll,
  selectQueryAllList: selectFbPostsAllList,
  selectAllEntities: selectAllFbPosts,
  selectAllEntitiesQuery: selectAllFbPostsQuery,
  selectEntityById: selectFbPostById,
  selectEntityQuery: selectFbPostQuery,
  selectEntityQueryList: selectFbPostQueryList
} = CreateSelectorForEntities<iStore, iFbPost>({
  sliceName: "fbPosts"
});

export const selectUnresolvedCountForFBPostQuery = (query: string) =>
  createSelector([selectFbPostQuery(query)], _fbPosts => {
    const fbPosts = _fbPosts.list || [];
    return fbPosts.reduce(
      (acc, fbPost) => {
        const unresolved =
          (fbPost.comments?.summary?.total_count || 0) -
          (fbPost.metaData.resolvedCommentCount || 0);
        acc.count = acc.count + unresolved;
        return acc;
      },
      {
        count: 0
      }
    );
  });
