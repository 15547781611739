import { SDK } from "@sdk";
import { iContactList } from "@sdk/crm/crm.models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { connectionListDefaultQueryConfig } from "./connection-lists-default-query";
import { ContactListsSlice } from "./contact-lists.slice";

export const loadAllContactLists = (
  store: Store<iStore>,
  forceReload?: boolean
) =>
  loadContactListsQuery(connectionListDefaultQueryConfig, "all")(
    store,
    forceReload
  );

export const {
  loadEntityQueries: loadContactListsQuery,
  loadEntityById: loadContactListById,
  loadEntitiesByIds: bulkLoadContactListsByIds
} = CreateHelpersForEntity<iStore, iContactList>({
  sliceName: "contactLists",
  slice: ContactListsSlice,
  isPaginatedQueries: false,
  loadEntityQueryProvider: () => SDK.contactLists.getAll(),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.contactLists.getById(entityId),
  loadEntityByIdsProvider: (contactListIds: string[]) =>
    (async () => {
      const contactLists = await Promise.all(
        contactListIds.map(contactListId =>
          // Todo:
          SDK.contactLists.getById(contactListId)
        )
      );
      return contactLists;
    })()
});
