/* eslint-disable no-case-declarations */

export class ImageResizer {
  static changeHeightWidth(
    height: number,
    maxHeight: number,
    width: number,
    maxWidth: number,
    minWidth?: number,
    minHeight?: number,
    forceWidth?: number,
    forceHeight?: number,
  ): { height: number; width: number } {
    if (forceWidth && forceHeight) {
      const aspectRatio = width / height;
      if (forceWidth / aspectRatio <= forceHeight) {
        height = Math.round(forceWidth / aspectRatio);
        width = forceWidth;
      } else {
        width = Math.round(forceHeight * aspectRatio);
        height = forceHeight;
      }
    } else {
      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }
      if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }
      if (minWidth && width < minWidth) {
        height = Math.round((height * minWidth) / width);
        width = minWidth;
      }
      if (minHeight && height < minHeight) {
        width = Math.round((width * minHeight) / height);
        height = minHeight;
      }
    }
    return { height, width };
  }

  static resizeAndRotateImage(
    image: HTMLImageElement,
    maxWidth: number,
    maxHeight: number,
    minWidth?: number,
    minHeight?: number,
    compressFormat: string = "jpeg",
    quality: number = 100,
    rotation: number = 0,
    forceWidth?: number,
    forceHeight?: number,
    backgroundColor: string = "rgba(255, 255, 255, 0)",
  ): string {
    const qualityDecimal = quality / 100;
    const canvas = document.createElement("canvas");

    let width = image.width;
    let height = image.height;

    const newHeightWidth = this.changeHeightWidth(
      height,
      maxHeight,
      width,
      maxWidth,
      minWidth,
      minHeight,
      forceWidth,
      forceHeight,
    );

    if (rotation && (rotation === 90 || rotation === 270)) {
      canvas.width = newHeightWidth.height;
      canvas.height = newHeightWidth.width;
    } else {
      canvas.width = forceWidth || newHeightWidth.width;
      canvas.height = forceHeight || newHeightWidth.height;
    }

    width = newHeightWidth.width;
    height = newHeightWidth.height;

    const ctx = canvas.getContext("2d");
    if (!ctx) {
      throw new Error("Could not get canvas context");
    }

    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    if (ctx.imageSmoothingEnabled && ctx.imageSmoothingQuality) {
      ctx.imageSmoothingQuality = "high";
    }

    if (rotation) {
      ctx.rotate((rotation * Math.PI) / 180);
      if (rotation === 90) {
        ctx.translate(0, -canvas.width);
      } else if (rotation === 180) {
        ctx.translate(-canvas.width, -canvas.height);
      } else if (rotation === 270) {
        ctx.translate(-canvas.height, 0);
      } else if (rotation === 0 || rotation === 360) {
        ctx.translate(0, 0);
      }
    }

    // Center the image in the canvas if forceWidth and forceHeight are provided
    const xOffset = forceWidth ? (forceWidth - width) / 2 : 0;
    const yOffset = forceHeight ? (forceHeight - height) / 2 : 0;

    ctx.drawImage(image, xOffset, yOffset, width, height);

    return canvas.toDataURL(`image/${compressFormat}`, qualityDecimal);
  }

  static b64toByteArrays(
    b64Data: string,
    contentType: string = "image/jpeg",
  ): Uint8Array[] {
    const sliceSize = 512;

    const byteCharacters = atob(
      b64Data
        .toString()
        .replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, ""),
    );
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    return byteArrays;
  }

  static b64toBlob(b64Data: string, contentType: string): Blob {
    const byteArrays = this.b64toByteArrays(b64Data, contentType);
    return new Blob(byteArrays, {
      type: contentType,
      lastModified: new Date(),
    } as any);
  }

  static b64toFile(
    b64Data: string,
    fileName: string,
    contentType: string,
  ): File {
    const byteArrays = this.b64toByteArrays(b64Data, contentType);
    return new File(byteArrays, fileName, {
      type: contentType,
      lastModified: new Date(),
    } as any);
  }

  static createResizedImage(
    file: File,
    maxWidth: number,
    maxHeight: number,
    compressFormat: string,
    quality: number,
    rotation: number,
    responseUriFunc: (uri: string | Blob | File) => void,
    outputType: string = "base64",
    minWidth: number | null = null,
    minHeight: number | null = null,
    forceWidth: number | null = null,
    forceHeight: number | null = null,
    backgroundColor: string = "rgba(255, 255, 255, 0)",
  ): void {
    const reader = new FileReader();
    if (file) {
      if (file.type && !file.type.includes("image")) {
        throw new Error("File Is NOT Image!");
      } else {
        reader.readAsDataURL(file);
        reader.onload = () => {
          const image = new Image();
          image.src = reader.result as string;
          image.onload = function () {
            const resizedDataUrl = ImageResizer.resizeAndRotateImage(
              image,
              maxWidth,
              maxHeight,
              minWidth as any,
              minHeight as any,
              compressFormat,
              quality,
              rotation,
              forceWidth as any,
              forceHeight as any,
              backgroundColor,
            );
            const contentType = `image/${compressFormat}`;
            switch (outputType) {
              case "blob":
                const blob = ImageResizer.b64toBlob(
                  resizedDataUrl,
                  contentType,
                );
                responseUriFunc(blob);
                break;
              case "base64":
                responseUriFunc(resizedDataUrl);
                break;
              case "file":
                const fileName = file.name;
                const fileNameWithoutFormat = fileName
                  .toString()
                  .replace(/(png|jpeg|jpg|webp)$/i, "");
                const newFileName = fileNameWithoutFormat.concat(
                  compressFormat.toString(),
                );
                const newFile = ImageResizer.b64toFile(
                  resizedDataUrl,
                  newFileName,
                  contentType,
                );
                responseUriFunc(newFile);
                break;
              default:
                responseUriFunc(resizedDataUrl);
            }
          };
        };
        reader.onerror = (error) => {
          throw new Error(error as any);
        };
      }
    } else {
      throw new Error("File Not Found!");
    }
  }
}

export default {
  imageFileResizer: (
    file: File,
    maxWidth: number,
    maxHeight: number,
    compressFormat: string,
    quality: number,
    rotation: number,
    responseUriFunc: (uri: string | Blob | File) => void,
    outputType: string,
    minWidth?: number,
    minHeight?: number,
    forceWidth?: number,
    forceHeight?: number,
    backgroundColor?: string,
  ) => {
    return ImageResizer.createResizedImage(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      quality,
      rotation,
      responseUriFunc,
      outputType,
      minWidth,
      minHeight,
      forceWidth,
      forceHeight,
      backgroundColor,
    );
  },
};
