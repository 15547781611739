import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { loadQueryTasks } from "store/modules/tasks/tasks.helpers";
import { selectTaskQuery } from "store/modules/tasks/tasks.selectors";
import { selectCurrentUserId } from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const useMyTasks = () => {
  const currentUserId = useSelector(selectCurrentUserId);

  const viewQuery = useMemo(() => {
    const _queryConfig = {
      query: {
        $or: [
          {
            assignedTo: {
              $elemMatch: {
                userId: currentUserId
              }
            }
          },
          {
            createdBy: currentUserId
          }
        ],
        isResolved: false
      },
      options: {
        sortBy: ["createdAt"]
      }
    };
    return {
      ..._queryConfig,
      alias: JSON.stringify(_queryConfig),
      options: {
        sortBy: ["-createdAt"]
      }
    };
  }, [currentUserId]);

  const { state, retry: reload } = useQueryWithStore(
    selectTaskQuery(viewQuery.alias),
    loadQueryTasks(_.omit(viewQuery, "alias"), viewQuery.alias),
    [viewQuery]
  );
  return state;
};
