import { iConnection } from "@sdk/conversations/conversations.models";
import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import { Button, Space, Tag } from "antd";
import { useIsAccountOnboard } from "components/pages/home/use-is-account-with-onboarding";

import { useModalPanels } from "@libs/modal-panels/use-modal-panels";

import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { ConnectionTypeToLabelMap } from "./connection-types-to-label-map";

export const ConnectionIssuesBanner = () => {
  const currentUser = useSelector(selectCurrentUser);

  const { isOnboard } = useIsAccountOnboard();

  const userRole = useMemo(() => {
    const currentUserRole = getRoleFromPermission(
      currentUser?.permissions || [],
    );
    return currentUserRole;
  }, [currentUser?.permissions]);

  const connections = useSelector(selectAllConnections);
  const [brokenConnections, setBrokenConnections] = useState(
    [] as iConnection[],
  );

  useEffect(() => {
    let connectionsThatHasIssues: iConnection[] = [];
    for (const connection of connections) {
      if (connection.metaData.hasError && connection.metaData.isActive) {
        connectionsThatHasIssues.push(connection);
      }
    }
    connectionsThatHasIssues = _.uniq(connectionsThatHasIssues);
    setBrokenConnections(connectionsThatHasIssues);
  }, [setBrokenConnections, connections]);

  const { changePanelStateWithName } = useModalPanels();

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    setShowBanner(true);
  }, [brokenConnections.length]);

  if (isOnboard && brokenConnections.length > 0 && showBanner) {
    return (
      <div className="outer-container absolute w-full p-4 px-24">
        <div
          className="bg-yellow-300 font-semibold border border-orange-700 text-orange-700 flex md:flex-row flex-col justify-center items-center p-2  w-full absolute z-50 rounded-md"
          style={{ maxWidth: "calc(100% - 12rem)" }}
        >
          <div className="text">
            The following connection(s) seems not to be working correctly:{" "}
            <Space>
              {brokenConnections.map((item) => (
                <Tag
                  key={item.id}
                  className="bg-yellow-300 border-orange-700 text-orange-700"
                >
                  {ConnectionTypeToLabelMap[item.type]}: {item.label}
                </Tag>
              ))}
            </Space>{" "}
            {userRole !== "Owner" &&
              `Your account administrator will have to re-configure this`}
          </div>
          <Button
            type="text"
            onClick={() => setShowBanner(false)}
            className="text-orange-700 font-bold"
          >
            Dismiss
          </Button>
          {userRole === "Owner" && (
            <Button
              type="text"
              onClick={() => {
                changePanelStateWithName(
                  "configurations",
                  true,
                  {},
                  {
                    configurations: `MANAGE_CHANNELS/${brokenConnections[0].id}`,
                  },
                );
              }}
              className="text-orange-700 font-bold"
            >
              Re-Configure Now
            </Button>
          )}
        </div>
      </div>
    );
  }
  return <></>;
};
