import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Form, Input, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { CollapsibleConfigurationSection } from "components/common/collapsible-configuration-panel/collapsible-configuration-container";
import { ModalTitle } from "components/common/modal-title";
import { UserPill } from "components/modules/user-management/users/components/single-user-selector/user-selector-pill";
import { DarkModeBg } from "dark-mode-bg";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { selectUsersMap } from "store/modules/users/users.selectors";
import { setOrganization } from "store/modules/workspace/workspace.actions";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";
import "./about-workspace.scss";

export const AboutWorkspace = () => {
  const isAdvancedMode = useSelector(selectIsAdvancedMode);
  const [accountDetailsForm] = useForm();
  const organization = useSelector(selectOrganization);
  const usersMap = useSelector(selectUsersMap);

  const {
    doAction: onUpdate,
    isProcessing,
    response,
    dispatch
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => values =>
        SDK.editCurrentOrganization(values)
          .then(res => {
            dispatch(setOrganization(res));
          })
          .then(d => {
            setEditWorkspaceModalVisibility(false);
          }),
      successMessage: "Account has been updated",
      failureMessage: "Something went wrong"
    }),
    []
  );

  const [
    isEditWorkspaceModalVisible,
    setEditWorkspaceModalVisibility
  ] = useState(false);

  const initialFormValue = {
    name: organization?.data?.name
  };

  return (
    (<CollapsibleConfigurationSection
      icon={"ri-building-4-line"}
      title={"Workspace"}
      description={"About Domain & Account Basics"}
    >
      <div className="about-workspace">
        <div className="list-details hover:bg-gray-100 dark:hover:bg-gray-800 editable-field">
          <div className="label text-gray-600 dark:text-gray-400 text-sm mode_transition">
            Workspace Name
          </div>
          <div
            className="font-bold"
            onClick={() => setEditWorkspaceModalVisibility(true)}
          >
            {organization?.data.name}{" "}
            <i className="ri-pencil-line edit-icon"></i>
          </div>
        </div>
        <div className="list-details hover:bg-gray-100 dark:hover:bg-gray-800">
          <div className="label text-gray-600 dark:text-gray-400 text-sm mode_transition">
            Workspace Domain
          </div>
          <div className="font-bold">
            {organization?.domain}.clickconnector.com
          </div>
        </div>
        <div className="list-details hover:bg-gray-100 dark:hover:bg-gray-800">
          <div className="label text-gray-600 dark:text-gray-400 text-sm mode_transition">
            Account Owner
          </div>
          <div className="font-bold">
            <UserPill
              name={`${
                usersMap[organization?.metaData.adminUserId!]?.data?.firstName
              } ${
                usersMap[organization?.metaData.adminUserId!]?.data?.lastName
              }`}
              email={
                usersMap[organization?.metaData.adminUserId!]?.credentials.email
              }
            />
          </div>
        </div>

        <Modal
          title={
            <ModalTitle
              title="Edit Workspace Name"
              icon={<i className="ri-building-line"></i>}
            />
          }
          open={isEditWorkspaceModalVisible}
          okText="Save"
          onOk={() => {
            const values = accountDetailsForm.getFieldsValue();
            onUpdate({
              data: values
            });
          }}
          confirmLoading={isProcessing}
          onCancel={() => {
            setEditWorkspaceModalVisibility(false);
          }}
          width={500}
          data-click-context="Edit Workspace Name Modal"
        >
          <Form
            name="settings"
            initialValues={initialFormValue}
            layout="vertical"
            form={accountDetailsForm}
          >
            <Form.Item
              name="name"
              label="Workspace Name"
              rules={[
                { required: true, message: "This field cannot be blank" }
              ]}
            >
              <Input placeholder="Workspace Name" />
            </Form.Item>
          </Form>
          <DarkModeBg />
        </Modal>
      </div>
    </CollapsibleConfigurationSection>)
  );
};
