import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddCompanyModal = React.lazy(() => import("./add-company.modal"));

const AddCompany: iModalProps<typeof AddCompanyModal> = {
  name: "add-company",
  component: GenerateModalWithSuspense(AddCompanyModal),
};

export default AddCompany;
