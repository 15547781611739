import { Select } from "antd";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { loadAllSendingDomains } from "store/modules/sending-domains/sending-domains.helpers";
import { selectAllSendingDomains } from "store/modules/sending-domains/sending-domains.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const SendingDomainSelector = ({
  value,
  onChange,
  autoSelectFirstOption,
  allowClear,
  autoFocus,
  showInvalidOptions,
}: {
  value?: string;
  onChange?: (formId: string) => any;
  autoSelectFirstOption?: boolean;
  allowClear?: boolean;
  autoFocus?: boolean;
  showInvalidOptions?: boolean;
}) => {
  const { state: _sendingDomains, retry: reload } = useQueryWithStore(
    selectAllSendingDomains,
    loadAllSendingDomains(),
  );

  const sendingDomains = useMemo(() => {
    if (showInvalidOptions) {
      return _sendingDomains;
    }
    return _sendingDomains.filter(
      (item) => item.isActive && item.isMailBoxReady,
    );
  }, [_sendingDomains, showInvalidOptions]);

  useEffect(() => {
    // * Todo: Could properly rewrite this
    if (autoSelectFirstOption) {
      if (value) {
        if (!_.find(sendingDomains, { id: value })) {
          if (sendingDomains.length > 0) {
            onChange && onChange(sendingDomains[0].id!);
          }
          onChange && onChange(undefined!);
        }
      } else {
        if (sendingDomains.length > 0) {
          onChange && onChange(sendingDomains[0].id!);
        }
      }
    } else {
      if (value) {
        if (!_.find(sendingDomains, { id: value })) {
          onChange && onChange(undefined!);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSelectFirstOption, sendingDomains]);

  return (
    <Select
      showSearch
      value={value}
      placeholder="Select Sending Domain"
      filterOption={false}
      onChange={onChange}
      style={{ width: "100%" }}
      allowClear={allowClear}
      defaultOpen={autoFocus}
      autoFocus={autoFocus}
    >
      {(sendingDomains || []).map((domainRecord) => (
        <Select.Option key={domainRecord.id} value={domainRecord.id!}>
          <div className="flex flex-row">
            <div className="icon-container mr-2">
              <i className="ri-settings-3-line"></i>
            </div>
            <div className="label">{domainRecord.domain}</div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};
