import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const TicketsPickerModal = React.lazy(() => import("./ticket-picker.modal"));

const TicketsPicker: iModalProps<
  typeof TicketsPickerModal,
  {
    onTicketSelected: (contactId: string) => any;
    title?: JSX.Element;
    hideAdd?: boolean;
    hideOnSelected?: boolean;
    ticketTypeId?: string;
  }
> = {
  name: "ticket-picker",
  component: GenerateModalWithSuspense(TicketsPickerModal),
};

export default TicketsPicker;
