import { iConversionWidget } from "@sdk/conversion-widgets/conversion-widgets-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { conversionWidgetsDefaultQueryConfig } from "./conversion-widgets-default-query";

export const {
  slice: ConversionWidgetsSlice,
  reducers: ConversionWidgetsSliceReducer,
  actions: {
    setEntityQueryResults: setConversionWidgetsQueryResults,
    setEntity: setConversionWidget,
    setEntities: setConversionWidgets,
    patchEntity: patchConversionWidget,
    addNewEntity: addNewConversionWidget,
    setEntityFetchingStatus: setConversionWidgetFetchingStatus,
    setEntitiesFetchingStatus: setConversionWidgetsFetchingStatus,
    setEntityFailedStatus: setConversionWidgetFailedStatus,
    setEntityQueryError: setConversionWidgetsQueryError,
    resetQuery: resetConversionWidgetsQuery,
    setQueryList: setConversionWidgetQueryList
  }
} = CreateEntitySlice<iConversionWidget>({
  sliceName: "conversionWidgets",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...conversionWidgetsDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
