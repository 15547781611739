import { iProactiveCampaign } from "@sdk/proactive-campaigns/proactive-campaigns-model";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectProactiveCampaignQueryMap,
  selectEntityMap: selectProactiveCampaignsMap,
  selectQueryMapAll: selectProactiveCampaignsMapAll,
  selectQueryAllList: selectProactiveCampaignsAllList,
  selectAllEntities: selectAllProactiveCampaigns,
  selectAllEntitiesQuery: selectAllProactiveCampaignsQuery,
  selectEntityById: selectProactiveCampaignById,
  selectEntityQuery: selectProactiveCampaignQuery,
  selectEntityQueryList: selectProactiveCampaignQueryList,
} = CreateSelectorForEntities<iStore, iProactiveCampaign>({
  sliceName: "proactiveCampaigns",
});

export const selectProactiveCampaignByConnectionId =
  (connectionId: string) => (state: iStore) => {
    return _.find(selectAllProactiveCampaigns(state), {
      connectionId,
    });
  };
