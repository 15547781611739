import { iArticleCollection } from "@sdk/knowledge-base/knowledge-base.models";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";

export const {
  selectQueryMap: selectArticleCollectionQueryMap,
  selectEntityMap: selectArticleCollectionsMap,
  selectQueryMapAll: selectArticleCollectionsMapAll,
  selectQueryAllList: selectArticleCollectionsAllList,
  selectAllEntities: selectAllArticleCollections,
  selectAllEntitiesQuery: selectAllArticleCollectionsQuery,
  selectEntityById: selectArticleCollectionById,
  selectEntityQuery: selectArticleCollectionsQuery
} = CreateSelectorForEntities<iStore, iArticleCollection>({
  sliceName: "articleCollections"
});
