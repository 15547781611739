import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ChatbotActivatorModal = React.lazy(
  () => import("./chatbot-activator.modal"),
);

const ChatbotActivator: iModalProps<
  typeof ChatbotActivatorModal,
  { chatbotId: string }
> = {
  name: "chatbot-activator",
  component: GenerateModalWithSuspense(ChatbotActivatorModal),
};

export default ChatbotActivator;
