import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const TourGeneratorWithPromptModal = React.lazy(
  () => import("./tour-generator-with-prompt.modal"),
);

const TourGeneratorWithPrompt: iModalProps<
  typeof TourGeneratorWithPromptModal,
  {
    generatorType: "WELCOME_TOUR" | "CUSTOM_TOUR";
  }
> = {
  name: "tour-generator-with-prompt",
  component: GenerateModalWithSuspense(TourGeneratorWithPromptModal),
};

export default TourGeneratorWithPrompt;
