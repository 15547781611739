export const ChatWidgetUserAvatarContainer = ({ data }: { data: string[] }) => {
  const defaultAvatar =
    "https://widget.clickconnector.app/assets/default-avatar.png";

  const image1 = data[0] || defaultAvatar;
  const image2 = data[1] || defaultAvatar;
  const image3 = data[2] || defaultAvatar;
  return (
    <div>
      <div className=" user-avatar-container relative mr-6">
        <div className="flex">
          <div
            className="top-left"
            style={{
              width: "1.8rem",
              height: "1.8rem",
              zIndex: 1
            }}
          >
            <img
              src={image1}
              style={{ borderRadius: "50%", transform: "scale(0.9)" }}
              alt=""
              className="chat-widget-avatar-container"
            />
          </div>
          <div
            className="top-right"
            style={{
              width: "1.8rem",
              height: "1.8rem",
              zIndex: 2,
              marginLeft: "-0.625rem"
            }}
          >
            <img
              src={image2}
              style={{ borderRadius: "50%", transform: "scale(0.9)" }}
              alt=""
              className="chat-widget-avatar-container"
            />
          </div>
          <div
            className="bottom-center"
            style={{
              width: "1.8rem",
              height: "1.8rem",
              zIndex: 3,
              marginLeft: "-2.4rem",
              marginTop: "1.175rem"
            }}
          >
            <img
              src={image3}
              style={{ borderRadius: "50%", transform: "scale(0.9)" }}
              alt=""
              className="chat-widget-avatar-container"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
