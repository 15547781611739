import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ChatViewSidebarEditorModal = React.lazy(
  () => import("./chat-view-sidebar-editor.modal"),
);

const ChatViewSidebarEditor: iModalProps<
  typeof ChatViewSidebarEditorModal,
  {}
> = {
  name: "chat-view-side-bar-editor",
  component: GenerateModalWithSuspense(ChatViewSidebarEditorModal),
};

export default ChatViewSidebarEditor;
