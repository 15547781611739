import { Button, Progress, Tag } from "antd";
import { useSelector } from "react-redux";
import { selectAllUsers } from "store/modules/users/users.selectors";
import { CalculateAnnualPlanPrice } from "./calculate-annual-plan";
import { useSubscriptionDetails } from "./use-subscription-details";
export const ActivePlanSummaryCard = ({
  onChangePlan,
}: {
  onChangePlan: () => any;
}) => {
  const { isSubscribed, subscriptionDetails, isYearly, plan } =
    useSubscriptionDetails();

  console.log("plan", plan);

  const allActiveUsers = useSelector(selectAllUsers);

  return (
    <>
      <div className="plan-card flex flex-col rounded-lg border border-gray-200 dark:border-gray-800 p-4">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            <div className="line-1 flex flex-row justify-start items-center">
              <div className="text-xl mr-2">{plan?.name}</div>
              <Tag color={"blue"}>{isYearly ? "Yearly" : "Monthly"}</Tag>
            </div>
            <div className="text-gray-600  text-xs">{plan?.description}</div>
          </div>
          <div className="flex flex-row items-end justify-start">
            {plan && (
              <div className="text-3xl">
                {isYearly ? CalculateAnnualPlanPrice(plan) : plan?.price}
              </div>
            )}

            <div className="text-gray-600 text-xs">
              /{isYearly ? "yr" : "mo"}
            </div>
          </div>
        </div>
        {plan && (
          <div className="plan-card">
            <div className="font-bold">
              {allActiveUsers.length} of {plan?.limits?.users} Users
            </div>
            <Progress
              percent={(allActiveUsers.length / plan?.limits?.users) * 100}
              showInfo={false}
            />
          </div>
        )}

        <div className="flex flex-row items-center justify-end">
          <Button
            type="text"
            icon={<i className="ri-arrow-up-circle-line"></i>}
            className="font-bold"
            onClick={() => {
              onChangePlan();
            }}
          >
            Upgrade Plan
          </Button>
        </div>
      </div>
    </>
  );
};
