import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddEditSupportCaseTypeModal = React.lazy(
  () => import("./add-edit-case-type.modal"),
);

const AddEditSupportCaseType: iModalProps<
  typeof AddEditSupportCaseTypeModal,
  {
    setSelectedPipeline: (string) => any;
    onCreated: () => any;
    ticketTypeId: string;
  }
> = {
  name: "add-edit-case-type-modal",
  component: GenerateModalWithSuspense(AddEditSupportCaseTypeModal),
};

export default AddEditSupportCaseType;
