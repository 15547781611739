import { SDK } from "@sdk";
import { iOpportunity } from "@sdk/crm/crm.models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { opportunitiesDefaultQueryConfig } from "./opportunities-default-query";
import { OpportunitiesSlice } from "./opportunities.slice";

export const opportunitiesPageSize = 100;

export const loadAllOpportunities = (
  store: Store<iStore>,
  forceReload?: boolean
) =>
  loadOpportunitiesQuery(opportunitiesDefaultQueryConfig, "all")(
    store,
    forceReload
  );

export const loadContactOpportunities = (contactId: string) => async (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  const queryConfig = {
    ...opportunitiesDefaultQueryConfig,
    query: {
      contactId
    }
  };
  const queryAlias = JSON.stringify(queryConfig);
  await loadOpportunitiesQuery(queryConfig, queryAlias)(store, forceReload);
};

export const {
  loadEntityQueries: loadOpportunitiesQuery,
  loadEntityById: loadOpportunityById,
  loadEntitiesByIds: bulkLoadOpportunitiesByIds
} = CreateHelpersForEntity<iStore, iOpportunity>({
  sliceName: "opportunities",
  slice: OpportunitiesSlice,
  isPaginatedQueries: true,
  fetchPageSize: opportunitiesPageSize,
  loadEntityQueryProvider: query => SDK.opportunities.query(query),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.opportunities.getById(entityId),
  loadEntityByIdsProvider: (opportunityIds: string[]) =>
    (async () => {
      const opportunities = await Promise.all(
        opportunityIds.map(opportunityId =>
          SDK.opportunities.getById(opportunityId)
        )
      );
      return opportunities;
    })()
});
