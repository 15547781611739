import _ from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { useIsMounted } from "./is-mounted";

const options = {
  leading: false,
  trailing: true
};

export function useDebounce(cb, delay) {
  const inputsRef = useRef(cb);
  const isMounted = useIsMounted();
  useEffect(() => {
    inputsRef.current = { cb, delay };
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    _.debounce<any>(
      (...args) => {
        // Don't execute callback, if (1) component in the meanwhile
        // has been unmounted or (2) delay has changed
        if (inputsRef.current.delay === delay && isMounted())
          inputsRef.current.cb(...args);
      },
      delay,
      options
    ),
    [delay, _.debounce]
  );
}
