import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AvailableLanguages, iPreference } from "./preferences.model";

const PreferenceSlice = createSlice({
  name: "preferences",
  initialState: {
    language: AvailableLanguages.ENGLISH,
    previousDomain: ""
  } as iPreference,
  reducers: {
    setLanguage(state, action: PayloadAction<AvailableLanguages>) {
      state.language = action.payload;
    },
    setPreviousDomain(state, action: PayloadAction<string>) {
      state.previousDomain = action.payload;
    }
  }
});

export const PreferenceSliceReducers = PreferenceSlice.reducer;

export const PreferenceSliceActions = PreferenceSlice.actions;
