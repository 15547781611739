import EditOutlined from "@ant-design/icons/EditOutlined";
import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";
import { Button, Collapse, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import classnames from "classnames";
import { SuspenseLoadingIndicator } from "components/common/loading-indicator/loading-indicator";

import { iFAQStoreState } from "components/modules/connections/channels/chat-widget/components/faq-editor/faq-model";

import React, { Suspense, useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import "./single-editable-faq-collection.scss";
const { Panel } = Collapse;
const DraftJSInput = React.lazy(
  () => import("components/common/draft-js-input/draft-js-input"),
);

const SingleEditableFAQ = ({
  faqCollectionId,
  faqId,
  values,
  dispatch,
  index,
}: {
  faqCollectionId: string;
  faqId: string;
  values: iFAQStoreState;
  dispatch: React.Dispatch<any>;
  index: number;
}) => {
  const draftJsRef = useRef<any>();
  const [activeKey, setActiveKey] = useState([] as string[]);
  const [FAQForm] = useForm();

  if (!values.faqs.byIds[faqId]) {
    return <></>;
  }

  return (
    <Draggable
      key={`${faqCollectionId}_${faqId}`}
      draggableId={`${faqCollectionId}_${faqId}`}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{
            // background: snapshot.isDragging ? "lightgreen" : "grey",
            // styles we need to apply on draggables
            ...provided.draggableProps.style,
          }}
        >
          <Collapse
            activeKey={activeKey}
            onChange={() => {}}
            className="p-0 m-2 shadow-xl rounded single-editable-faq-collection"
          >
            <Panel
              header={
                <div className="flex-col justify-center items-center w-full">
                  <div
                    className="flex flex-row justify-center items-center  cursor-move"
                    onClick={(e) => e.stopPropagation()}
                    {...provided.dragHandleProps}
                  >
                    <div
                      className={classnames("w-6 flex items-center move-icon")}
                    >
                      <EllipsisOutlined className="text-2xl transform rotate-90  " />
                    </div>
                    <div className="flex flex-row flex-1 items-center py-5">
                      <div className="flex-1 flex flex-col justify-center">
                        <div className="  font-semibold flex flex-row">
                          <i className="ri-file-text-fill pr-2"></i>{" "}
                          {values.faqs.byIds[faqId].question}
                        </div>
                      </div>
                    </div>
                    <div
                      className="mr-3"
                      onClick={() => {
                        setActiveKey(
                          activeKey.length === 0 ? [`faq${faqId}`] : [],
                        );
                      }}
                    >
                      <EditOutlined className="cursor-pointer" />
                    </div>
                    <div
                      className=" pr-5"
                      onClick={(e) => {
                        dispatch({
                          type: "DELETE_FAQ",
                          payload: {
                            collectionId: faqCollectionId,
                            faqId: faqId,
                          },
                        });
                      }}
                    >
                      <i className="ri-delete-bin-line cursor-pointer"></i>
                    </div>
                  </div>
                  {activeKey[0] !== `faq${faqId}` && (
                    <div className="w-full p-2">
                      <div
                        className="cursor-pointer px-4"
                        onClick={() => {
                          setActiveKey(
                            activeKey.length === 0 ? [`faq${faqId}`] : [],
                          );
                        }}
                      >
                        <div
                          className="raw-html-rendered-block"
                          dangerouslySetInnerHTML={{
                            __html: values.faqs.byIds[faqId].body,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              }
              key={`faq${faqId}`}
              showArrow={false}
            >
              <Form
                layout="vertical"
                form={FAQForm}
                initialValues={{
                  FAQQuestion: values.faqs.byIds[faqId].question,
                }}
                onFinish={() => {
                  dispatch({
                    type: "EDIT_FAQ",
                    payload: {
                      faq: {
                        id: faqId,
                        question: FAQForm.getFieldValue("FAQQuestion"),
                        body: draftJsRef.current.getHtmlContent(),
                      },
                    },
                  });
                  setActiveKey([]);
                }}
              >
                <Form.Item
                  label=""
                  name="FAQQuestion"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Question or title of article" />
                </Form.Item>

                <div className="draft-js-container">
                  <Suspense fallback={<SuspenseLoadingIndicator />}>
                    <DraftJSInput
                      initialValue={values.faqs.byIds[faqId].body}
                      ref={draftJsRef}
                    />
                  </Suspense>
                </div>

                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                <Button
                  type="text"
                  onClick={() => {
                    setActiveKey([]);
                  }}
                >
                  Cancel
                </Button>
              </Form>
            </Panel>
          </Collapse>
        </div>
      )}
    </Draggable>
  );
};
export default SingleEditableFAQ;
