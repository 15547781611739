import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iSLATemplate } from "@sdk/user-management/user-management.models";
import { Button, Divider, Popconfirm, Space, Table, Tag } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { SectionHeader } from "components/common/section-header";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { loadOrganization } from "store/modules/workspace/workspace.helpers";
import { selectSLATemplates } from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useSimpleState } from "utils/hooks/use-simple-state";

import classNames from "utils/classnames";
import { convertMillisecondsToReadableString } from "utils/convert-milliseconds-to-readable-string";
import { AddEditSLATemplateModal } from "./add-edit-sla-template";

export const ManageSLATemplates = () => {
  const { state: slaTemplates, retry: reload } = useQueryWithStore(
    selectSLATemplates,
    loadOrganization,
  );

  const { doAction: addSLATemplate, isProcessing: isAddingSLATemplate } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (template: iSLATemplate) =>
          SDK.slaTemplates.addSLATemplate(template),
        successMessage: "SLA Template has been added",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const { doAction: editSLATemplate, isProcessing: isEditingRule } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (id, template: iSLATemplate) =>
          SDK.slaTemplates.editSLATemplate(id, template),
        successMessage: "SLA Template has been edited",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const {
    doAction: deleteSLATemplate,
    isProcessing: isDeletingAutomationRule,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (id: string) =>
        SDK.slaTemplates.deleteSLATemplate(id).then((d) => {
          reload();
        }),
      successMessage: "SLA Template has been removed",
      failureMessage: "Something went wrong",
    }),
    [reload],
  );

  const columns = [
    {
      title: "Label",
      dataIndex: "label",
    },
    {
      title: "Rules",
      dataIndex: "actions",

      render: (data, record: iSLATemplate, index) => {
        return (
          <Space>
            {record.firstResponseTime && (
              <Tag>
                First Response:{" "}
                {convertMillisecondsToReadableString(record.firstResponseTime)}
              </Tag>
            )}
            {record.nextResponseTime && (
              <Tag>
                Next Response:{" "}
                {convertMillisecondsToReadableString(record.nextResponseTime)}
              </Tag>
            )}
            {record.timeToClose && (
              <Tag>
                Time to Close:{" "}
                {convertMillisecondsToReadableString(record.timeToClose)}
              </Tag>
            )}
          </Space>
        );
      },
    },

    {
      title: "",
      dataIndex: "actions",
      width: 200,
      render: (data, record, index) => (
        <Space>
          <Button
            type="link"
            icon={<i className="ri-pencil-line"></i>}
            onClick={() => {
              setTemplateEditorState({ visibility: true, rule: record });
            }}
          >
            Edit
          </Button>
          <Popconfirm
            placement="leftTop"
            title="Are you sure to delete?"
            onConfirm={async () => {
              deleteSLATemplate(record.id);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="link"
              shape="circle"
              icon={<i className="ri-delete-bin-line"></i>}
            ></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const [templateEditorState, setTemplateEditorState] = useSimpleState({
    visibility: false,
    rule: undefined as iSLATemplate | undefined,
  });

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin p-8 overflow-x-hidden manage-chat-bots">
      <SectionHeader
        title="SLA Templates"
        icon={<i className="ri-timer-line"></i>}
        description="Create & Manage SLA Rules for your organization"
        addons={
          <Space>
            <Button
              type="primary"
              // size="large"
              icon={<i className="ri-inbox-fill"></i>}
              onClick={() =>
                setTemplateEditorState({ visibility: true, rule: null as any })
              }
            >
              Add New Template
            </Button>
          </Space>
        }
      />

      <Divider />

      <Divider />

      <Table
        columns={columns}
        dataSource={slaTemplates}
        pagination={false}
        rowKey="id"
        locale={{
          emptyText: (
            <EmptyData text="No Templates to show" icon="ri-flag-line" />
          ),
        }}
      />

      <Divider />

      <div
        className={classNames(
          "p-4 rounded-lg border border-gray-300 dark:border-gray-700 hidden md:flex flex-col  mt-8",
        )}
      >
        <div className="flex flex-row items-center">
          <div className="text-4xl">☝️</div>
          <div className="text-xl font-bold ml-3">Using SLA Templates</div>
        </div>

        <div className="text-gray-600 dark:text-gray-400">
          SLAs templates can be activated on conversations manually via the chat
          actions or automatically in the chatbot flows (chat actions)
        </div>
      </div>

      <AddEditSLATemplateModal
        visible={templateEditorState.visibility}
        onChangeVisibility={(visibility) =>
          setTemplateEditorState({ visibility })
        }
        initialValue={templateEditorState.rule}
        onSave={(template) => {
          if (template.id && templateEditorState?.rule?.id === template.id) {
            // Edit
            editSLATemplate(template.id, template);
          } else {
            addSLATemplate(template);
          }
          setTemplateEditorState({ visibility: false });
        }}
      />
    </StyledScrollArea>
  );
};
