import dayjs from "dayjs";
export const GetUpcomingInvoiceDate = (createdDate: number) => {
  if (dayjs(createdDate).isAfter(dayjs())) {
    // console.log("Date is a future date");
    return dayjs(createdDate);
  }

  if (Number(dayjs(createdDate).get("date")) > Number(dayjs().get("date"))) {
    // console.log("Payment cycle should be in current month");
    return dayjs(createdDate).month(dayjs().get("month"));
  }
  //   console.log("Payment cycle should be in next month");
  return dayjs(createdDate).month(dayjs().get("month") + 1);
};
