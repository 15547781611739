import classnames from "classnames";
import { CSSProperties } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  Droppable,
} from "react-beautiful-dnd";

import "./dnd-item.scss";
import { reorder } from "./reorder";

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#f4f4f4" : "transparent",
  minHeight: "50px",
});

const getItemStyle = (draggableStyle, isDragging) => ({
  // change background colour if dragging
  background: isDragging ? "#F4F4F4" : "#fff",

  // styles we need to apply on draggables
  ...draggableStyle,
});

function move<T>(
  source: T[],
  destination: T[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
) {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: { [droppableId: string]: T[] } = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
}

export const DraggableItem = ({
  key,
  draggableId,
  index,
  children,
  dragHandleContainerStyle,
  dragItemContainerClassName,
  noDarkMode,
}: {
  key: string;
  draggableId: string;
  index: number;
  children;
  dragHandleContainerStyle?: CSSProperties;
  dragItemContainerClassName?: string;
  noDarkMode?: boolean;
}) => {
  return (
    <Draggable key={key} draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{
            // styles we need to apply on draggables
            ...provided.draggableProps.style,
          }}
          className="dnd-item"
        >
          <div className={classnames("relative", dragItemContainerClassName)}>
            <div
              className={classnames("w-6 flex items-center absolute move-icon")}
              {...provided.dragHandleProps}
              style={{
                left: -28,
                paddingLeft: 10,
                paddingRight: 10,
                height: "100%",
                ...{ ...(dragHandleContainerStyle || {}) },
              }}
            >
              <i
                className={classnames("ri-draggable text-gray-700 text-2xl", {
                  "dark:text-gray-200": !noDarkMode,
                })}
              ></i>
            </div>
            {children}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export const SimpleDNDList = <T extends { id: string }>({
  list,
  onListChange,
  renderItem,
  dragHandleContainerStyle,
  enableRenderClone,
  dragItemContainerClassName,
  noDarkMode,
}: {
  list: T[];
  onListChange: (list: T[]) => any;
  renderItem: (item: T, index: number) => JSX.Element;
  dragHandleContainerStyle?: CSSProperties;
  enableRenderClone?: boolean;
  dragItemContainerClassName?: string;
  noDarkMode?: boolean;
}) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items: any = reorder(
      list,
      result.source.index,
      result.destination.index,
    );
    onListChange(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="droppable"
        renderClone={
          enableRenderClone
            ? (provided, snapshot, rubric) => (
                <div {...provided.draggableProps} ref={provided.innerRef}>
                  <div
                    className="dnd-item"
                    style={{
                      fontFamily: `-apple-system,BlinkMacSystemFont,"Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
                      fontSize: 13,
                    }}
                  >
                    <div className="relative">
                      {renderItem(
                        list[rubric.source.index],
                        rubric.source.index,
                      )}
                    </div>
                  </div>
                </div>
              )
            : undefined
        }
      >
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {list.map((item, index) => (
              <DraggableItem
                key={item.id}
                draggableId={`item-${item.id}`}
                index={index}
                dragHandleContainerStyle={dragHandleContainerStyle}
                dragItemContainerClassName={dragItemContainerClassName}
                noDarkMode={noDarkMode}
              >
                {renderItem(item, index)}
              </DraggableItem>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
