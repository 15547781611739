import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iAction } from "@sdk/chat-bots/action-models";
import React from "react";

const ActionEditorModal = React.lazy(() => import("./action-editor.modal"));

const ActionEditor: iModalProps<
  typeof ActionEditorModal,
  {
    onSave: (action: iAction) => any;
    initialValue?: iAction;
    excludeActions?: string[];
    includedActionGroups?: string[];
  }
> = {
  name: "action-editor",
  component: GenerateModalWithSuspense(ActionEditorModal),
};

export default ActionEditor;
