import { iChatFAQCollection } from "@sdk/chat-widgets/chat-widgets.models";

export const defaultWidgetPreviewData = {
  CONVERSATIONS_SECTION_TITLE: "Your Conversations",
  SEE_ALL_TEXT: "See All",
  CONVERSATION_NAME: "Sales Team",
  DATE_COUNT: "a few seconds ago",
  CONVERSATION_TAGLINE: "Hey 👋. What would you like to know today?",
  HOME_HEADER: {
    WELCOME_TEXT: "Hey 👋",
    HEADER_TAGLINE: "We usually reply within minutes",
    AVATARS_IMAGES: [
      "https://firebasestorage.googleapis.com/v0/b/click-connector-staging.appspot.com/o/fc89e0_mathesh-avatar-final-min.1634577738524.png?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/click-connector-staging.appspot.com/o/fc89e0_ruban.1634577477498.png?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/click-connector-staging.appspot.com/o/fc89e0_veivesh-avatar-final-min.1634577960907.png?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/click-connector-staging.appspot.com/o/fc89e0_steve-avatar-final-min.1634614346794.png?alt=media",
    ],
    TOTAL_USERS: 8,
    HEADER_PATTERN: "",
  },
  FAQ: {
    FAQ_TITLE: "Frequently Asked Questions",
    SEE_ALL_TEXT: "See All",
    COLLECTIONS: [
      {
        label: "General Questions",
        faqList: [
          {
            question: "Can ClickConnector be integrated with an existing CRM?",
            body: " ",
          },
          {
            question: "Is my data secure?",
            body: " ",
          },
          {
            question: "How long does it take to integrate ClickConnector?",
            body: " ",
          },
        ],
      },
    ] as iChatFAQCollection[],
    BROWSE_ALL_BUTTON: "Browse All",
  },
  CONVERSATION_PAGE: {
    CONVERSATION_TITLE: "Sales Team",
    CONVERSATION_TAGLINE: "We usually reply within minutes",
  },
  MESSAGES: {
    RECEIVE_MESSAGES: {
      MESSAGE1: {
        MESSAGE: "Hey 👋. What would you like to know today?",
        DATE: "September 9, 2022",
      },
      MESSAGE2: {
        MESSAGE: "Cool, fire away...",
        DATE: "September 9, 2022",
      },
      MESSAGE3: {
        MESSAGE: "Meanwhile, let me try connect you with the support team,",
        DATE: "September 9, 2022",
      },
    },
    SENT_MESSAGES: {
      MESSAGE1: {
        MESSAGE: "Ask a Question",
        DATE: "September 9, 2022",
      },
    },
  },
  SEARCH_ARTICLES: {
    SEARCH_ARTICLE_TITLE: "Browse FAQs",
    SUBTITLE: "Categories",
    COLLECTIONS: [
      {
        label: "General Questions",
        faqList: [
          {
            question: "Can ClickConnector be integrated with an existing CRM?",
            body: " ",
          },
          {
            question: "Is my data secure?",
            body: " ",
          },
          {
            question: "How long does it take to integrate ClickConnector?",
            body: " ",
          },
          {
            question: "Can I use LiveChat on several websites?",
            body: " ",
          },
          {
            question: "How does ClickConnector compare to a competitor?",
            body: " ",
          },
          {
            question: "Do I need to supply credit card details?",
            body: " ",
          },
          {
            question: "What happens if I cancel?",
            body: " ",
          },
          {
            question: "Do you provide support to get started?",
            body: " ",
          },
        ],
      },
    ] as iChatFAQCollection[],
  },
};
