import PlusOutlined from "@ant-design/icons/PlusOutlined";
import { Button, Divider, Space, Table, Tooltip } from "antd";
import { SectionHeader } from "components/common/section-header";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import copyToClipboard from "copy-to-clipboard";
import dayjs from "dayjs";
import { loadAllProducts } from "store/modules/products/products.helpers";
import { selectAllProducts } from "store/modules/products/products.selectors";
import { useQueryWithStore } from "store/store.hooks";
import { useSimpleState } from "utils/hooks/use-simple-state";
import { AddProductModal } from "./add-product-modal";

const ProductsPage = () => {
  const columns = [
    {
      title: "Label",
      dataIndex: "label",
      render: (label: string, record) => {
        return (
          <>
            {label}{" "}
            <Tooltip title="Copy Product Id">
              <Button
                onClick={(e) => {
                  copyToClipboard(record.id);
                  e.stopPropagation();
                }}
                type="link"
                icon={<i className="ri-clipboard-line"></i>}
              ></Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Created on",
      dataIndex: "createdOn",
      width: 120,
      render: (timestamp: string) => {
        return (
          <div className="text-gray-700 dark:text-gray-200 mode_transition">
            {dayjs(timestamp).format("DD-MM-YYYY")}
          </div>
        );
      },
    },
    {
      title: "",
      key: "action",
      width: 100,
      render: (text, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              // setEditChatBotModalState({
              //   visibility: true,
              //   chatBotId: record.id,
              // });
            }}
            icon={<i className="ri-settings-2-line"></i>}
          >
            Edit Label
          </Button>
          <Button
            onClick={() => {
              // setConfigureChatBotModalState({
              //   visibility: true,
              //   chatBotId: record.id,
              // });
            }}
            icon={<i className="ri-settings-2-line"></i>}
          >
            Configure
          </Button>
        </Space>
      ),
    },
  ];

  const { state: products, retry } = useQueryWithStore(
    selectAllProducts,
    loadAllProducts(),
  );

  const [addProductModalState, setAddProductModalState] = useSimpleState({
    visibility: false,
  });

  return (
    <StyledScrollArea className="w-full h-full flex-1 animated fadeInLeftMin">
      <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4">
        <SectionHeader
          title="Products"
          description="Build your product catalogue in ClickConnector so that you can easily share them with your customers"
          icon={<i className="ri-store-line"></i>}
          addons={
            <>
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => {
                  setAddProductModalState({ visibility: true });
                }}
              >
                Add New Product
              </Button>
            </>
          }
        />
        <Divider />
        <Table
          columns={columns}
          dataSource={products}
          pagination={false}
          rowKey="id"
          className="mb-16"
        />

        <AddProductModal
          visible={addProductModalState.visibility}
          onChangeVisibility={(state) =>
            setAddProductModalState({ visibility: state })
          }
          onCreated={() => {}}
        />
      </SimpleCardSection>
    </StyledScrollArea>
  );
};
export default ProductsPage;
