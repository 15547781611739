import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const KnowledgeBaseManageArticlesModal = React.lazy(
  () => import("./knowledge-base-manage-articles.modal"),
);

const KnowledgeBaseManageArticles: iModalProps<
  typeof KnowledgeBaseManageArticlesModal,
  { collectionId?: string }
> = {
  name: "manage-articles-base",
  component: GenerateModalWithSuspense(KnowledgeBaseManageArticlesModal),
};

export default KnowledgeBaseManageArticles;
