import { Modal } from "antd";
import { DarkModeBg } from "dark-mode-bg";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useThrottle } from "utils/hooks/use-throttle";

export const RouterPrompt = ({
  when,
  title = "",
  okText = "Yes",
  cancelText = "Cancel",
}: {
  when: boolean;
  onOK?: () => any;
  onCancel?: () => any;
  title?: string;
  okText?: string;
  cancelText?: string;
}) => {
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);
  const [nextLocation, setNextLocation] = useState<{
    path: string;
    search: string;
  } | null>(null);

  const unblockRef = useRef<any>();

  function handleShowModal() {
    setShowPrompt(true);
  }

  function onCancel() {
    setShowPrompt(false);
  }

  const confirmNavigation = () => {
    if (nextLocation) {
      unblockRef.current();
      history.push(nextLocation.path + nextLocation.search);
    }
  };

  const setNextLocationThrottled = useThrottle(
    (location: { path: string; search: string }) => {
      setNextLocation(location);
    },
    10,
    { leading: true, trailing: false },
  );

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (when) {
        setNextLocationThrottled({
          path: location.pathname,
          search: location.search,
        });
        handleShowModal();
        return false;
      }
      return;
    });
    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [history, setNextLocationThrottled, when]);

  const handleConfirm = () => {
    setShowPrompt(false);
    confirmNavigation();
  };

  return showPrompt ? (
    <Modal
      title={title}
      open={showPrompt}
      onOk={handleConfirm}
      okText={okText}
      onCancel={onCancel}
      cancelText={cancelText}
      closable={true}
      zIndex={99999}
    >
      There are unsaved changes. Are you sure you want to leave this page?
      <DarkModeBg />
    </Modal>
  ) : null;
};
