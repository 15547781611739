import { find, uniq } from "lodash";

import React, { useEffect, useMemo, useState } from "react";
import { iModalPanelRegistryItem } from "./modal-panels-model";
import { ModalsStateContext } from "./modal-state-context";
import { useModalPanels } from "./use-modal-panels";

export const TemporaryModalContainer = ({
  Component,
  onPortalDestroyed,
  ...props
}) => {
  const [isModalVisible, setModalVisibility] = useState(true);
  const [isModalDestroyed, destroyModal] = useState(false);
  useEffect(() => {
    if (!isModalVisible) {
      const timer = setTimeout(() => {
        destroyModal(true);
        onPortalDestroyed && onPortalDestroyed();
      }, 400);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isModalVisible, onPortalDestroyed]);
  if (isModalDestroyed) {
    return <></>;
  }
  return (
    <Component
      {...props}
      visible={isModalVisible}
      onChangeVisibility={setModalVisibility}
    />
  );
};
export const ModalsPanelsRegistry = ({
  modals,
}: {
  modals: iModalPanelRegistryItem<any>[];
}) => {
  const { activePortals, changePanelState, changePanelStateWithName } =
    useModalPanels();

  const { state, setState } = React.useContext(ModalsStateContext);
  const [activePortalHistory, setActivePortalHistory] = useState(
    [] as string[],
  );

  useEffect(() => {
    const activePortalNames = activePortals.map((item) => item.name);
    setActivePortalHistory((portals) =>
      uniq([...portals, ...activePortalNames]),
    );
  }, [activePortals]);

  const currentModals = useMemo(() => {
    // Create a mapping of activePortal names to their index for ordering
    const activePortalsOrder = activePortals.reduce((acc, portal, index) => {
      acc[portal.name] = index;
      return acc;
    }, {});

    // Sort the modals based on the order defined in activePortals
    const sortedModals = [...modals].sort((a, b) => {
      const orderA =
        activePortalsOrder[a.name] !== undefined
          ? activePortalsOrder[a.name]
          : Infinity;
      const orderB =
        activePortalsOrder[b.name] !== undefined
          ? activePortalsOrder[b.name]
          : Infinity;
      return orderA - orderB;
    });

    return sortedModals.map((modal) => {
      const modalState = find(activePortals, { name: modal.name })!;
      const ModalComponent = modal.component;
      if (!modalState?.visible && !activePortalHistory.includes(modal.name)) {
        return <React.Fragment key={modal.name}></React.Fragment>;
      }
      return (
        <ModalComponent
          key={modal.name}
          {...modalState}
          onChangeVisibility={(state) =>
            changePanelStateWithName(modal.name, state)
          }
          zIndex={
            modalState?.zIndex || 200 + (activePortalsOrder[modal.name] || 0)
          }
        />
      );
    });
  }, [activePortalHistory, activePortals, changePanelStateWithName, modals]);

  const temporaryModals = useMemo(() => {
    return state.map((item, index) => {
      const modalRecord = find(modals, { name: item.name })!;
      const ModalComponent = modalRecord.component;
      return (
        <TemporaryModalContainer
          key={item.id}
          {...item}
          Component={ModalComponent}
          onPortalDestroyed={() => {
            setState((existingPortals) =>
              existingPortals.filter((itemX) => itemX.id !== item.id),
            );
          }}
          zIndex={item.zIndex || 400 + (index || 0)}
        />
      );
    });
  }, [modals, setState, state]);

  return (
    <>
      {currentModals.map((modal) => modal)}
      {temporaryModals.map((modal) => modal)}
    </>
  );
};
