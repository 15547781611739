import { iMessageSenderType } from "@sdk/conversations/conversations.models";
import { Select } from "antd";
import { useEffect } from "react";
import { iQueryInputElementProps } from "../models";

const MessageFromOptions = [
  {
    value: iMessageSenderType.USER,
    label: "Team Member",
  },
  {
    value: iMessageSenderType.CLIENT,
    label: "Customer",
  },
];
export const MessageFromSelector = ({
  value,
  onChange,
}: iQueryInputElementProps) => {
  useEffect(() => {
    if (!value || value === "") {
      onChange(iMessageSenderType.CLIENT);
    }
  }, [onChange, value]);
  return (
    <Select
      options={MessageFromOptions}
      value={value}
      onChange={onChange}
      className="w-full"
    />
  );
};
