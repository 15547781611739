import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const CalendlyLinkPickerModal = React.lazy(
  () => import("./calendly-event-link-picker.modal"),
);

const CalendlyLinkPicker: iModalProps<
  typeof CalendlyLinkPickerModal,
  {
    onLinkSelected?: (content: string) => any;
  }
> = {
  name: "calendly-link-picker",
  component: GenerateModalWithSuspense(CalendlyLinkPickerModal),
};

export default CalendlyLinkPicker;
