import dayjs from "dayjs";
export const EmailBlock_MessageThread: (data: {
  messages: { text: string; timestamp: number; sentBy: string }[];
}) => string = ({ messages }) => {
  return /*html*/ `<table class="row">
    <tbody>
        <tr>
            <!-- Main Email content -->
            <th class="small-12 large-12 columns first last">
                <table>
                    <tbody><tr>
                    <th class="expander"></th>
                        <th>
                        ${messages
                          .map((message) => {
                            return `
                                            <table class="row">
                                            <tbody>
                                                <tr>
                                                    <th class="small-12 large-12 columns first last">
                                                        <table>
                                                            <tr>
                                                                <th class="chat-message">
                                                                <hr />
                                                               ${message.text}
                                                                </th>
                                                                <th class="expander"></th>
                                                            </tr>
                                                            <tr>
                                                            <th class="smaller-lighter-font">
                                                            ${message.sentBy}
                                                            on ${dayjs(
                                                              message.timestamp,
                                                            ).format(
                                                              "dddd, MMMM Do YYYY, h:mm:ss a",
                                                            )}
                                                            </th>
                                                            <th class="expander"></th>
                                                        </tr>
                                                        </table>
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                                                                        
                                            `;
                          })
                          .join("")}
                        </th>
                        <th class="expander"></th>
                    </tr>
                </tbody></table>
            </th>
        </tr>
    </tbody>
</table>`;
};
