export const SetupEmbedsOptions: {
  label: string;
  description: string;
  icon: JSX.Element;
}[] = [
  // Todo
  // {
  //   label: "New chats will notify everyone",
  //   description: "Incoming chat will be alerted to all users",
  //   icon: <i className="ri-notification-3-line" />,
  // },
];
