import { useSDK } from "@sdk";
import { Col, Row, Spin } from "antd";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import dayjs from "dayjs";
import { forwardRef, useImperativeHandle } from "react";
import { GetUpcomingInvoiceDate } from "./billing-helpers/get-next-invoice-data";

export const NextPaymentSummary = forwardRef((props, ref) => {
  // Stripe Data
  const {
    data: activeSubscription,
    isLoading: isSubscriptionLoading,
    reload: reloadSubscriptions,
  } = useSDK((SDK) => SDK.retrieveSubscription(), [], false);

  useImperativeHandle(
    ref,
    () => ({
      reloadSubscriptions: reloadSubscriptions,
    }),
    [reloadSubscriptions],
  );

  return (
    <>
      <Spin
        spinning={!activeSubscription.subscription || isSubscriptionLoading}
        indicator={<LoadingIndicatorWithSpin />}
      >
        <div className="summary bg-blue-100 dark:bg-gray-800 my-4 p-4 rounded-lg">
          <Row gutter={10}>
            <Col span={8}>
              <div className="flex flex-col">
                <div className="label text-sm text-gray-600 dark:text-gray-400 mode_transition">
                  Next Payment
                </div>
                {activeSubscription.invoice && (
                  <div className="font-bold text-blue-600 dark:text-blue-200 mode_transition">
                    ${activeSubscription.invoice.total / 100} on{" "}
                    {GetUpcomingInvoiceDate(
                      activeSubscription.invoice.created * 1000,
                    ).format("MMMM Do YYYY")}
                  </div>
                )}
              </div>
            </Col>
            <Col span={8}>
              <div className="flex flex-col">
                <div className="label text-sm text-gray-600 dark:text-gray-400 mode_transition">
                  Started
                </div>
                {activeSubscription.subscription && (
                  <div className="font-bold">
                    {dayjs(
                      activeSubscription.subscription.created * 1000,
                    ).format("MMMM Do YYYY")}
                  </div>
                )}
              </div>
            </Col>
            <Col span={8}>
              <div className="flex flex-col">
                <div className="label text-sm text-gray-600 dark:text-gray-400 mode_transition">
                  Coupon
                </div>
                <div className="">N/A</div>
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
});
