import { Form, Select } from "antd";
import { ConfigurationEditorMiniComponent } from "components/common/configuration-editor-mini";
import { useSelector } from "react-redux";
import { selectAllGroups } from "store/modules/groups/groups.selectors";

export const RestrictAccessForm = ({ connectionId }: { connectionId }) => {
  const userGroups = useSelector(selectAllGroups);

  return (
    <ConfigurationEditorMiniComponent
      entityType="CONNECTION"
      entityId={connectionId}
      icon={"ri-lock-line"}
      title="Restrict Access by Teams, Departments or Custom Groups"
      description="change who can access this connection"
    >
      <Form.Item
        label="User Groups"
        name={"userGroups"}
        help="Select the user groups that will have access to this connection. (Leave it blank to keep open access)"
      >
        <Select size="large" allowClear mode="multiple">
          {(userGroups || []).map((userGroup) => (
            <Select.Option key={userGroup.label} value={userGroup.id}>
              {userGroup.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </ConfigurationEditorMiniComponent>
  );
};
