import { Button, Divider, Space, Tag } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { SectionHeader } from "components/common/section-header";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { useQueryWithStore } from "store/store.hooks";

import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { iCustomDomain } from "@sdk/sending-domains/sending-domains-models";

import { useState } from "react";
import { Collapse } from "react-collapse";
import { loadAllSendingDomains } from "store/modules/sending-domains/sending-domains.helpers";
import { selectAllSendingDomains } from "store/modules/sending-domains/sending-domains.selectors";
import { default as AddSendingDomain } from "./add-sending-domain.modal-registry";
import { SendingDomainConfig } from "./sending-domain-config/sending-domain-config";

export const ManageSendingDomains = () => {
  const { state: sendingDomains, retry: reload } = useQueryWithStore(
    selectAllSendingDomains,
    loadAllSendingDomains(),
  );

  const { triggerTempModal } = useModalPanels();

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin p-8 overflow-x-hidden manage-chat-bots">
      <SectionHeader
        title="Sending Domains"
        icon={<i className="ri-mail-send-line"></i>}
        description="Manage sending domains for emails"
        addons={
          <Space>
            <Button
              type="primary"
              // size="large"
              icon={<i className="ri-add-line"></i>}
              onClick={() => {
                triggerTempModal(AddSendingDomain, {
                  onAdded: (domain) => {
                    setTimeout(() => {
                      const collapsible = document.getElementById(
                        `sending-domain-${domain.id}`,
                      );
                      collapsible?.click();
                    }, 1000);
                  },
                });
              }}
            >
              New Sending Domain
            </Button>
          </Space>
        }
      />
      <Divider />
      <div className="flex flex-col gap-4">
        {sendingDomains.map((domain) => (
          <div key={domain.domain}>
            <SimpleCollapseWithHeader domain={domain} />
          </div>
        ))}
      </div>
      {sendingDomains.length === 0 && (
        <>
          <EmptyData text="No domains to show" icon="ri-flag-line" />
        </>
      )}
    </StyledScrollArea>
  );
};

const SimpleCollapseWithHeader = ({ domain }: { domain: iCustomDomain }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <div className="bg-gray-100 dark:bg-gray-900 border rounded-lg border-gray-200 dark:border-gray-700 p-2 flex flex-col">
      <div
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="flex flex-row items-center justify-between cursor-pointer"
        id={`sending-domain-${domain.id}`}
      >
        <div className="flex flex-row items-center p-2">
          <i className="ri-mail-settings-line"></i>{" "}
          <div className="ml-4 flex flex-row items-center">
            <span className="font-bold mr-1">{domain.domain}</span>
            <div className="ml-2">
              {domain.isMailBoxReady ? <Tag>Active</Tag> : <Tag>Not Ready</Tag>}
            </div>
            <div className="ml-2">{domain.mailgunData.message}</div>
          </div>
        </div>
        <div>
          {isCollapsed ? (
            <i className="ri-arrow-up-s-line text-xl"></i>
          ) : (
            <i className="ri-arrow-down-s-line text-xl"></i>
          )}
        </div>
      </div>

      <div onClick={(e) => e.stopPropagation()} className="">
        <Collapse isOpened={isCollapsed}>
          <Divider className="mt-2" />
          <div className="p-4">
            <SendingDomainConfig sendingDomain={domain} />
          </div>
          {/* <pre>{JSON.stringify(domain, null, 2)}</pre> */}
        </Collapse>
      </div>
    </div>
  );
};
