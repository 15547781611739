import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddUserModal = React.lazy(() => import("./add-user.modal"));

const AddUser: iModalProps<typeof AddUserModal> = {
  name: "add-users",
  component: GenerateModalWithSuspense(AddUserModal),
};

export default AddUser;
