import { Input, Modal } from "antd";
import classNames from "classnames";
import { UserAvatar } from "components/common/avatar/user-avatar";
import { ModalTitle } from "components/common/modal-title";
import { DarkModeBg } from "dark-mode-bg";
import { antInputModalAutoFocusWithRef } from "utils/ant-input-modal-auto-focus";
import { useSearch } from "utils/hooks/use-search";
import { useTeamStatusStats } from "../hooks/use-team-status-stats";

export const TeamStatusWidgetModal = ({
  visibility,
  onChangeVisibilityChange,
}: {
  visibility: boolean;
  onChangeVisibilityChange: (visibility: boolean) => any;
}) => {
  const { users, onlineUsers, awayUsers, busyUsers, OfflineUsers } =
    useTeamStatusStats();

  const { filteredData, searchTerm, setSearchTerm } = useSearch(
    users.filter((d) => d.isActive),
  );

  return (
    <>
      <Modal
        title={
          <ModalTitle title="Team" icon={<i className="ri-group-line"></i>} />
        }
        open={visibility}
        onCancel={() => {
          onChangeVisibilityChange(false);
        }}
        footer={null}
        data-click-context="Team Status Modal"
      >
        <div className="flex flex-col">
          <div className="flex items-center justify-center mb-4">
            <div
              className={classNames(
                "flex items-center p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md cursor-pointer",
                {
                  "bg-gray-100 dark:bg-gray-700":
                    searchTerm.includes("Available"),
                },
              )}
              onClick={() => setSearchTerm("Available")}
            >
              <i className="ri-checkbox-blank-circle-fill text-green-500 mr-1"></i>
              Available: {onlineUsers}
            </div>
            <div
              className={classNames(
                "flex items-center ml-5 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md cursor-pointer",
                {
                  "bg-gray-100 dark:bg-gray-700": searchTerm.includes("Away"),
                },
              )}
              onClick={() => setSearchTerm("Away")}
            >
              <i className="ri-checkbox-blank-circle-fill text-yellow-500 mr-1"></i>
              Away: {awayUsers}
            </div>
            <div
              className={classNames(
                "flex items-center ml-5 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md cursor-pointer",
                {
                  "bg-gray-100 dark:bg-gray-700": searchTerm.includes("Busy"),
                },
              )}
              onClick={() => setSearchTerm("Busy")}
            >
              <i className="ri-checkbox-blank-circle-fill text-red-500 mr-1"></i>
              Busy: {busyUsers}
            </div>
            <div
              className={classNames(
                "flex items-center ml-5 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md cursor-pointer",
                {
                  "bg-gray-100 dark:bg-gray-700":
                    searchTerm.includes("Offline"),
                },
              )}
              onClick={() => setSearchTerm("Offline")}
            >
              <i className="ri-checkbox-blank-circle-fill text-black dark:text-white mr-1"></i>
              Offline {OfflineUsers}
            </div>
          </div>
          <Input.Search
            placeholder="Search..."
            size="large"
            className="rounded-md mb-8"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            autoFocus
            ref={antInputModalAutoFocusWithRef}
          />
          <div
            className="user-list-container overflow-auto"
            style={{ height: 300 }}
          >
            {filteredData.map((user) => (
              <div
                key={user.id}
                className="p-2 border-b border-gray-200 dark:border-gray-700 mb-3 b hover:bg-gray-200 dark:hover:bg-gray-700"
              >
                <div className="flex-row flex justify-start items-center">
                  <UserAvatar userId={user.id} size={50} />
                  <div className="flex-col pl-3">
                    <div className="font-bold">{`${user.name}`}</div>
                    <div className="description">{`${user.email}`}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <DarkModeBg />
      </Modal>
    </>
  );
};
