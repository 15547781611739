import { iDynamicForm } from "@sdk/dynamic-forms/dynamic-forms-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { dynamicFormsDefaultQueryConfig } from "./dynamic-forms-default-query";

export const {
  slice: DynamicFormsSlice,
  reducers: DynamicFormsSliceReducer,
  actions: {
    setEntityQueryResults: setDynamicFormsQueryResults,
    setEntity: setDynamicForm,
    setEntities: setDynamicForms,
    patchEntity: patchDynamicForm,
    addNewEntity: addNewDynamicForm,
    setEntityFetchingStatus: setDynamicFormFetchingStatus,
    setEntitiesFetchingStatus: setDynamicFormsFetchingStatus,
    setEntityFailedStatus: setDynamicFormFailedStatus,
    setEntityQueryError: setDynamicFormsQueryError,
    resetQuery: resetDynamicFormsQuery,
    setQueryList: setDynamicFormQueryList
  }
} = CreateEntitySlice<iDynamicForm>({
  sliceName: "dynamicForms",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...dynamicFormsDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
