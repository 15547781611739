import { Badge } from "antd";
import { selectConversationQueryWithIds } from "store/modules/conversations/conversations.selectors";
import { useSelectorWithMemoize } from "store/utils/use-selector-with-memoize";

const pageCount = 20;

// Currently only used un unassigned conversation
export const QueryConversationsBadgeCount = ({ query }: { query?: string }) => {
  const queryObject = useSelectorWithMemoize(
    () => selectConversationQueryWithIds(query!),
    [query],
  );
  const conversationCount = queryObject?.list?.length || 0;

  const totalAvailableItems = queryObject.totalItems || 0;
  const totalFetchedItems = (queryObject.lastFetchedPage || 0) * pageCount;
  const hasMore = totalAvailableItems > totalFetchedItems;

  return (
    <>
      {Boolean(conversationCount) && (
        <Badge
          count={hasMore ? conversationCount + 1 : conversationCount}
          overflowCount={Math.min(totalFetchedItems, 99)}
          className="un-read-badge blue"
          size="small"
        ></Badge>
      )}
    </>
  );
};

export const QueryConversationsBadgeCountText = ({
  query,
}: {
  query?: string;
}) => {
  const queryObject = useSelectorWithMemoize(
    () => selectConversationQueryWithIds(query!),
    [query],
  );
  const conversationCount = queryObject?.list?.length || 0;

  const totalAvailableItems = queryObject.totalItems || 0;
  const totalFetchedItems = (queryObject.lastFetchedPage || 0) * pageCount;
  const hasMore = totalAvailableItems > totalFetchedItems;

  return (
    <span className="font-semibold ml-2">
      {Boolean(conversationCount) && <>({conversationCount})</>}
    </span>
  );
};
