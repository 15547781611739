import { SDK } from "@sdk";

import { iTicket } from "@sdk/tickets/tickets-model";
import { Store } from "redux";
import { QueryConfig } from "store/utils/query-config";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { ticketsDefaultQueryConfig } from "./tickets-default-query";
import { TicketsSlice } from "./tickets.slice";

export const loadQueryTickets =
  (queryConfig: QueryConfig, queryAlias?: string) =>
  async (store: Store<iStore>, forceReload?: boolean) => {
    queryAlias = queryAlias || JSON.stringify(queryConfig);
    await loadTicketsQuery(queryConfig, queryAlias)(store, forceReload);
  };

export const {
  loadEntityQueries: loadTicketsQuery,
  loadEntityById: loadTicketById,
  loadMoreEntityQueries: loadMoreTicketsQueries,
} = CreateHelpersForEntity<iStore, iTicket>({
  sliceName: "tickets",
  slice: TicketsSlice,
  isPaginatedQueries: true,
  fetchPageSize: 50,
  loadEntityQueryProvider: (query) => SDK.tickets.query(query),
  loadEntityByIdProvider: (entityId: string) => SDK.tickets.getById(entityId),
});

export const loadTicketsByContactId =
  (contactId: string) =>
  async (store: Store<iStore>, forceReload?: boolean) => {
    const queryConfig = {
      ...ticketsDefaultQueryConfig,
      query: {
        "references.contactIds": contactId,
      },
    };
    const queryAlias = JSON.stringify(queryConfig);
    await loadTicketsQuery(queryConfig, queryAlias)(store, forceReload);
  };
