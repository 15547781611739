import { axios, AxiosResponse } from "@sdk/axios";

export class SyncServerServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  async getRoomStatus(roomId: string) {
    const results: AxiosResponse<{
      online?: boolean;
    }> = await axios.get(this.config.basePath + `/sockets/status/${roomId}`, {
      headers: {
        Authorization: this.config.token
      }
    });
    return results.data;
  }

  async queryStatus(roomIds: string[]) {
    const results: AxiosResponse<{
      [roomId: string]: boolean;
    }> = await axios.post(
      this.config.basePath + `/sockets/get-statuses`,
      {
        roomIds
      },
      {
        headers: {
          Authorization: this.config.token
        }
      }
    );
    return results.data;
  }

  async getAllStatusByNamespace(namespace: string) {
    const results: AxiosResponse<{
      [subNameSpaceId: string]: boolean;
    }> = await axios.get(
      this.config.basePath + `/sockets/status-by-namespace/${namespace}`,
      {
        headers: {
          Authorization: this.config.token
        }
      }
    );
    return results.data;
  }
}
