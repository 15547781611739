import { useSelector } from "react-redux";
import { selectIsUserOnline } from "store/modules/user-presence/user-presence.selectors";
import { selectUser } from "store/modules/users/users.selectors";

export const useUserAvatar = (userId: string) => {
  const user = useSelector(selectUser(userId));
  const isOnline = useSelector(selectIsUserOnline(userId));
  return {
    name: user?.data.firstName,
    avatar: user?.data.avatar,
    isOnline,
    isAvailable: user?.metaData?.isAvailable,
    availabilityStatus: user?.metaData.availabilityStatus,
  };
};
