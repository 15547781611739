import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const PNRConverterModal = React.lazy(() => import("./pnr-converter.modal"));

const PNRConverter: iModalProps<
  typeof PNRConverterModal,
  {
    onAddAsAttachment: (content: string) => any;
  }
> = {
  name: "pnr-converter",
  component: GenerateModalWithSuspense(PNRConverterModal),
};

export default PNRConverter;
