import { iBlockTypes } from "@sdk/chat-connectors/chat-connectors.models";

export const AvailableBlocks: {
  id: iBlockTypes;
  label: string;
}[] = [
  {
    id: "TEXT",
    label: "Text",
  },
  {
    id: "HEADING",
    label: "Heading",
  },
  {
    id: "MARKDOWN",
    label: "Markdown",
  },
  {
    id: "IFRAME",
    label: "Iframe",
  },
  {
    id: "SIMPLE_TEXT_DATA",
    label: "Simple Text Data",
  },
  {
    id: "SIMPLE_ARRAY_TABLE",
    label: "Simple Table from Array",
  },
  {
    id: "SIMPLE_KEY_VALUE",
    label: "Simple Table from Object",
  },
  {
    id: "SIMPLE_ACTION_BUTTON",
    label: "Simple Action Button",
  },
];
