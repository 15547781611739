import { SDK } from "@sdk";
import { iConnector } from "@sdk/chat-connectors/chat-connectors.models";
import { Store } from "redux";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { chatConnectorsDefaultQueryConfig } from "./chat-connectors-default-query";
import { ChatConnectorsSlice } from "./chat-connectors.slice";

export const loadAllChatConnectors = () => async (
  store: Store<iStore>,
  forceReload?: boolean
) => {
  await loadChatConnectorsQuery(chatConnectorsDefaultQueryConfig, "all")(
    store,
    forceReload
  );
};

export const {
  loadEntityQueries: loadChatConnectorsQuery,
  loadEntityById: loadChatConnectorById
} = CreateHelpersForEntity<iStore, iConnector>({
  sliceName: "chatConnectors",
  slice: ChatConnectorsSlice,
  isPaginatedQueries: false,
  fetchPageSize: 20,
  loadEntityQueryProvider: query => SDK.chatConnectors.getAll(),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.chatConnectors.getById(entityId)
});
