import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const EmailSignatureManagerModal = React.lazy(
  () => import("./email-signature-manager.modal"),
);
const MyEmailSignatures: iModalProps<typeof EmailSignatureManagerModal, {}> = {
  name: "email-signatures",
  component: GenerateModalWithSuspense(EmailSignatureManagerModal),
};

export default MyEmailSignatures;
