import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import {
  iCCAutomationConditionRule,
  iCCAutomationRule,
} from "@sdk/user-management/user-management.models";
import { Button, Divider, Dropdown, Space, Switch, Tooltip } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { SectionHeader } from "components/common/section-header";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { useEffect, useMemo } from "react";
import { loadOrganization } from "store/modules/workspace/workspace.helpers";
import {
  selectAutomationRules,
  selectIsAppSumoUser,
} from "store/modules/workspace/workspace.selectors";
import { useQueryWithStore } from "store/store.hooks";

import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { mdiCogPlay } from "@mdi/js";
import { MDIIcon } from "components/common/mdi-icon";
import { last } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { uuidv4 } from "utils/generate-uuid";
import { ActionIcons, ActionLabels } from "../action-editor/actions-registry";
import AutomationRuleEditor from "./automation-rule-editor/automation-rule-editor.modal-registry";
import { AutomationRuleTriggerLabels } from "./automation-rule-editor/automation-rule-trigger-labels";
import { AllAutomationRuleConditionConfigurations } from "./automation-rule-editor/automaton-rules-config";
import { AutomationRuleTemplates } from "./automation-rule-templates";
import "./manage-automation-rules.scss";

export const ManageAutomationRules = () => {
  const { state: automationRules, retry: reload } = useQueryWithStore(
    selectAutomationRules,
    loadOrganization,
  );

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { changePanelState, triggerTempModal } = useModalPanels();

  const { doAction: addAutomationRule, isProcessing: isAddingRule } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (rule: iCCAutomationRule) =>
          SDK.automationRules.addAutomationRule(rule),
        successMessage: "Rule has been added",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const { doAction: editAutomationRule, isProcessing: isEditingRule } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (id, rule: iCCAutomationRule) =>
          SDK.automationRules.editAutomationRule(id, rule),
        successMessage: "Rule has been edited",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const {
    doAction: deleteAutomationRule,
    isProcessing: isDeletingAutomationRule,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => (id: string) =>
        SDK.automationRules.deleteAutomationRule(id).then((d) => {
          return reload();
        }),
      successMessage: "Rule has been removed",
      failureMessage: "Something went wrong",
    }),
    [reload],
  );

  const isAppSumoUser = useSelector(selectIsAppSumoUser);

  const templatesToShow = useMemo(() => {
    if (isAppSumoUser && automationRules.length > 5) {
      return [];
    }
    return AutomationRuleTemplates.filter((template) => {
      const matchedAutomation = automationRules.find(
        (item) => template.label === item.label,
      );
      return !matchedAutomation;
    });
  }, [automationRules, isAppSumoUser]);

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin p-8 overflow-x-hidden manage-automation-rules">
      <SectionHeader
        title="Workflow Automation Rules"
        icon={<MDIIcon icon={mdiCogPlay} size="3rem" />}
        description="Create & Manage Automation Rules for your organization"
        addons={
          <Space>
            {isAppSumoUser && automationRules.length > 5 ? (
              <Tooltip className="Limit exceeded">
                <Button
                  type="primary"
                  // size="large"
                  icon={<i className="ri-add-line"></i>}
                  disabled
                >
                  Add New Rule
                </Button>
              </Tooltip>
            ) : (
              <Button
                type="primary"
                // size="large"
                icon={<i className="ri-add-line"></i>}
                onClick={() => {
                  triggerTempModal(AutomationRuleEditor, {});
                }}
              >
                Add New Rule
              </Button>
            )}
          </Space>
        }
      />
      <Divider />

      <Divider>Created Rules</Divider>

      {automationRules.length === 0 && (
        <EmptyData text="No rules to show" icon="ri-flag-line" />
      )}

      {automationRules.map((rule) => (
        <div
          key={rule.id}
          className="my-8 p-4 rounded-lg border border-gray-200 dark:border-gray-800 cursor-pointer hover:bg-gray-100 hover:dark:bg-gray-800"
          onClick={() => {
            triggerTempModal(AutomationRuleEditor, {
              automationRuleId: rule.id,
            });
          }}
        >
          <div className="flex flex-row items-center">
            <div className="icon flex justify-center items-center">
              <AutomationIcon rule={rule} />
            </div>
            <div className="texts flex-col pl-4 flex-1">
              <div className="header font-bold">{rule.label}</div>
              <div className="header text-gray-600 dark:text-gray-400 mode_transition">
                {AutomationRuleTriggerLabels[rule.trigger]}
                <AutomationConditionTriggerConditionLabel rule={rule} />
                {", "}
                <AutomationConditionsLabel rule={rule} />{" "}
                <AutomationConditionActionLabel rule={rule} />
              </div>
            </div>
            {/* <div className="mx-4">
            <i className="ri-pencil-line"></i>
              <Button
                type="text"
                icon={<i className="ri-settings-4-line"></i>}
              />
            </div> */}
            <div
              className="flex flex-row justify-center items-center"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Switch
                checked={rule.isActive}
                onChange={(isActive) => {
                  editAutomationRule(rule.id, { isActive });
                }}
              />
            </div>
            <div
              className="mx-4"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "DUPLICATE_AUTOMATION",
                      icon: <i className="ri-file-copy-line"></i>,
                      label: "Duplicate Automation",
                      onClick: () => {
                        addAutomationRule({
                          ...rule,
                          id: uuidv4(),
                          label: `Copy of ${rule?.label}`,
                        });
                      },
                    },
                    {
                      key: "DELETE_AUTOMATION",
                      icon: <i className="ri-delete-bin-line"></i>,
                      label: "Remove Automation",
                      onClick: () => {
                        deleteAutomationRule(rule.id);
                      },
                    },
                  ],
                }}
                trigger={["click"]}
              >
                <Button type="text" icon={<i className="ri-more-line"></i>} />
              </Dropdown>
            </div>
          </div>
        </div>
      ))}

      {templatesToShow.length > 0 && (
        <>
          <Divider>Pre-made templates</Divider>
          {templatesToShow.map((rule) => (
            <div
              key={rule.id}
              className="my-8 p-4 rounded-lg border border-gray-200 dark:border-gray-800 "
            >
              <div className="flex flex-row items-center">
                <div className="icon flex justify-center items-center">
                  <AutomationIcon rule={rule} />
                </div>
                <div className="texts flex-col pl-4 flex-1">
                  <div className="header font-bold">{rule.label}</div>
                  <div className="header text-gray-600 dark:text-gray-400 mode_transition">
                    {AutomationRuleTriggerLabels[rule.trigger]}
                    <AutomationConditionTriggerConditionLabel rule={rule} />
                    {", "}
                    <AutomationConditionsLabel rule={rule} />{" "}
                    <AutomationConditionActionLabel rule={rule} />
                  </div>
                </div>
                <div
                  className="mx-4"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Tooltip title="Add Automation">
                    <Button
                      type="text"
                      icon={<i className="ri-add-line"></i>}
                      onClick={() => {
                        const newId = uuidv4();
                        addAutomationRule({
                          ...rule,
                          id: uuidv4(),
                          label: `${rule.label}`,
                        }).then((organization) => {
                          const addedWorkflow = last(
                            organization?.appData.HELP_DESK.automationRules,
                          );
                          triggerTempModal(AutomationRuleEditor, {
                            automationRuleId: addedWorkflow?.id,
                          });
                        });
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </StyledScrollArea>
  );
};

const AutomationConditionTriggerConditionLabel = ({
  rule,
}: {
  rule: iCCAutomationRule;
}) => {
  if (rule.trigger === "CONVERSATION_INACTIVE") {
    return (
      <>
        {" "}
        ({"for "}
        {rule?.triggerConfig?.inactivePeriod || 48} hours)
      </>
    );
  }
  return <></>;
};

const AutomationConditionsLabel = ({ rule }: { rule: iCCAutomationRule }) => {
  if (rule.conditions.length > 0) {
    return (
      <>
        if{" "}
        {rule.conditions.map((condition, i) => (
          <React.Fragment key={i.toString()}>
            <AutomationConditionLabel condition={condition} />
            {i !== rule.conditions.length - 1 && " and "}
          </React.Fragment>
        ))}{" "}
        then
      </>
    );
  }
  return <></>;
};

const AutomationConditionLabel = ({
  condition,
}: {
  condition: iCCAutomationConditionRule;
}) => {
  const { matchedField, matchedOperator } = useMemo(() => {
    const fieldConfig = AllAutomationRuleConditionConfigurations.find(
      (item) => item.key === condition.field,
    );
    const operatorConfig = fieldConfig?.operators.find(
      (item) => item.key === condition.operator,
    );
    return { matchedField: fieldConfig, matchedOperator: operatorConfig };
  }, [condition.field, condition.operator]);

  return (
    <>
      {matchedField?.label.toLowerCase()} {matchedOperator?.label} "
      {matchedOperator?.labelRender
        ? matchedOperator?.labelRender(condition.value)
        : condition?.value?.toString?.()}
      "
    </>
  );
};

const AutomationConditionActionLabel = ({
  rule,
}: {
  rule: iCCAutomationRule;
}) => {
  return (
    <>
      {rule.actions
        .map((action) => `${ActionLabels[action.action]}`.toLowerCase())
        .join(" and then ")}
    </>
  );
};

const AutomationIcon = ({ rule }: { rule: iCCAutomationRule }) => {
  return (
    <span className="text-3xl automation-rule-icon">
      {ActionIcons[rule.actions[0].action]}
    </span>
  );
};
