export const BetaFeatures = [
  {
    key: "QUOTE_CREATOR",
    label: "Holiday Quote Creator",
  },
  {
    key: "PIPELINES",
    label: "Pipelines",
  },
  {
    key: "FORMS",
    label: "Forms",
  },
  {
    key: "SEQUENCES",
    label: "Sequences",
  },
  {
    key: "EMAIL_CAMPAIGNS",
    label: "Email Campaigns",
  },
  {
    key: "APP_DOWNLOAD",
    label: "App Download Link",
  },
  {
    key: "TICKETS",
    label: "Tickets",
  },
];
