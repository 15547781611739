import { iTelnyxConnectionData } from "@sdk/conversations/conversations.models";
import { TelnyxRTCContext } from "@telnyx/react-client";
import { Button, Divider, message } from "antd";
import { MobileNumberInput } from "components/common/mobile-number-input/mobile-number-input";
import { ModalTitle } from "components/common/modal-title";

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { selectContactById } from "store/modules/contacts/contacts.selectors";
import { cleanMobileNumber } from "utils/clean-mobile-number";

import ContactPickerModal from "components/modules/crm/contacts/components/contact-picker-modal/contact-picker.modal";
import { UserTracker } from "user-tracker";
import "./dialer.scss";
import { TelnyxKeyPad } from "./telnyx-key-pad";

export const Dialer = () => {
  const store = useStore();
  const [numberInput, setNumberInput] = useState("+13022615327");
  const client = useContext(TelnyxRTCContext);

  const connections = useSelector(selectAllConnections);
  const telephoneConnection = useMemo(() => {
    return connections.find((item) => item.type === "TELNYX");
  }, [connections]);
  const telephonyData = telephoneConnection?.data as iTelnyxConnectionData;

  const callNumber = useCallback(
    (number: string) => {
      if (numberInput) {
        UserTracker.track("SIP_PHONE - Call Number", {});
        const call = client?.newCall({
          // Destination is required and can be a phone number or SIP URI
          destinationNumber: numberInput,
          callerNumber: telephonyData?.phoneNumber,
        });
      }
    },
    [client, numberInput, telephonyData?.phoneNumber],
  );

  // const [form] = useForm();

  // const joinCall = useCallback(async () => {
  //   const formValues = form.getFieldsValue();
  //   console.log("formValues", formValues);
  //   const call = await client?.newCall({
  //     // Destination is required and can be a phone number or SIP URI
  //     ...formValues,
  //     destinationNumber: "+13022615327",
  //     callerNumber: telephonyData?.phoneNumber,
  //   });
  //   console.log("call", call);
  // }, [client, form, telephonyData?.phoneNumber]);

  const [contactPickerModalState, setContactPickerModalState] = useState(false);

  const [isInputValid, setInputValidity] = useState(false);

  useEffect(() => {
    const validate = async () => {
      const number = await cleanMobileNumber(numberInput);
      if (number.isValid) {
        setInputValidity(true);
      } else {
        setInputValidity(false);
      }
    };
    validate();
  }, [numberInput]);

  return (
    <div className="dialer rounded-md w-full h-full flex flex-col">
      {/* <Form layout="vertical" form={form}>
        <Form.Item
          style={{ margin: 0 }}
          name={["telnyxSessionId"]}
          label="telnyxSessionId"
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{ margin: 0 }}
          name={["telnyxLegId"]}
          label="telnyxLegId"
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{ margin: 0 }}
          name={["telnyxCallControlId"]}
          label="telnyxCallControlId"
        >
          <Input />
        </Form.Item>
      </Form>
      <Button onClick={() => joinCall()}>Call</Button> */}
      <div className="phone-input flex-1 flex justify-center items-center">
        <MobileNumberInput
          placeholder="Enter a number"
          className="border-0"
          value={numberInput}
          onChange={setNumberInput}
        />
      </div>
      <Divider className="m-2" />
      <div className="contact-picker">
        <Button
          type="text"
          icon={<i className="ri-contacts-book-line"></i>}
          block
          className="font-semibold"
          onClick={() => setContactPickerModalState(true)}
        >
          Pick a Contact & Call
        </Button>
        <ContactPickerModal
          visible={contactPickerModalState}
          onChangeVisibility={setContactPickerModalState}
          onContactSelected={async (contactId) => {
            const contact = selectContactById(contactId)(store.getState());
            if (!contact?.data?.primaryMobile) {
              message.warning("No mobile number found");
            } else {
              callNumber(contact?.data?.primaryMobile);
            }
          }}
          title={
            <ModalTitle
              title="Pick a contact to call"
              icon={<i className="ri-contacts-book-line"></i>}
            />
          }
        />
      </div>
      <Divider className="m-2" />
      <div className="keypad flex flex-col justify-end items-center">
        <TelnyxKeyPad
          onKeyPress={(number) => setNumberInput((input) => input + number)}
        />

        <div className="call-button flex flex-row justify-center items-center p-4">
          <Button
            type="primary"
            shape="circle"
            icon={<i className="ri-phone-fill pr-0 text-2xl"></i>}
            className="text-2xl"
            style={{ width: 60, height: 60 }}
            onClick={() => callNumber(numberInput)}
            disabled={!isInputValid}
          />
        </div>
      </div>
    </div>
  );
};
