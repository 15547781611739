import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const BulkAddUsersModal = React.lazy(() => import("./bulk-add-users.modal"));

const BulkAddUsers: iModalProps<typeof BulkAddUsersModal> = {
  name: "bulk-add-users",
  component: GenerateModalWithSuspense(BulkAddUsersModal),
};

export default BulkAddUsers;
