import { iWidget } from "@sdk/chat-widgets/chat-widgets.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { chatWidgetDefaultQueryConfig } from "./chat-widget-default-query";

export const {
  slice: ChatWidgetsSlice,
  reducers: ChatWidgetsSliceReducer,
  actions: {
    setEntityQueryResults: setChatWidgetsQueryResults,
    setEntity: setChatWidget,
    setEntities: setChatWidgets,
    patchEntity: patchChatWidget,
    addNewEntity: addNewChatWidget,
    setEntityFetchingStatus: setChatWidgetFetchingStatus,
    setEntitiesFetchingStatus: setChatWidgetsFetchingStatus,
    setEntityFailedStatus: setChatWidgetFailedStatus,
    setEntityQueryError: setChatWidgetsQueryError,
    resetQuery: resetChatWidgetsQuery,
    setQueryList: setChatWidgetQueryList
  }
} = CreateEntitySlice<iWidget>({
  sliceName: "chatWidgets",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...chatWidgetDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
