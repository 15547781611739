import { iPipelineStageCheckListItemType } from "@sdk/user-management/user-management.models";

export const CheckListItemType: Record<
  iPipelineStageCheckListItemType,
  JSX.Element
> = {
  TASK: <i className="ri-checkbox-line"></i>,
  DATA_COLLECTION: <i className="ri-database-2-line"></i>,
  CONDITION: <i className="ri-question-mark"></i>,
  DATA_INTEGRATION: <i className="ri-plug-line"></i>,
};
