import { mdiConnection } from "@mdi/js";
import { Button, Divider, Space, Table } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import { MDIIcon } from "components/common/mdi-icon";
import { SectionHeader } from "components/common/section-header";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { useMemo } from "react";
import { useQueryWithStore } from "store/store.hooks";

import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { iConnection } from "@sdk/conversations/conversations.models";
import AddNewConnection from "components/pages/configurations/sections/add-new-connection.modal-registry";
import { loadAllConnections } from "store/modules/connections/connections.helpers";
import { selectAllConnections } from "store/modules/connections/connections.selectors";
import { ConnectionIcons } from "./helpers/connection-icons";

export const ManageChannels = ({ setSettings }) => {
  const { state: allConnections, retry: reload } = useQueryWithStore(
    selectAllConnections,
    loadAllConnections,
  );

  const { changePanelState } = useModalPanels();

  const columns = useMemo(
    () => [
      {
        title: "Channel Name",
        dataIndex: "label",
        render: (label, connection: iConnection) => {
          return (
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center">
                <i className={ConnectionIcons[connection.type]}></i>
                <div className="font-bold ml-2"> {connection.label}</div>
              </div>
              <div className="px-2">
                <i className="ri-arrow-right-line"></i>
              </div>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin p-8 overflow-x-hidden">
      <SectionHeader
        title="Manage Channels"
        icon={<MDIIcon icon={mdiConnection} size="3rem" />}
        description="Add and change customer support channels"
        addons={
          <Space>
            <Button
              type="primary"
              // size="large"
              icon={<i className="ri-add-line"></i>}
              onClick={() => changePanelState(AddNewConnection, true, {})}
            >
              Add New Chanel
            </Button>
          </Space>
        }
      />
      <Divider />

      <Table
        columns={columns}
        dataSource={allConnections}
        pagination={false}
        rowKey="id"
        locale={{
          emptyText: (
            <EmptyData
              text="You have not connected any channels yet"
              icon="ri-flag-line"
            />
          ),
        }}
        onRow={(record) => ({
          onClick: () => {
            setSettings(`MANAGE_CHANNELS/${record.id}`);
          },
          className: "cursor-pointer",
        })}
      />
    </StyledScrollArea>
  );
};
