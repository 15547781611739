import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ScheduleReminderModal = React.lazy(
  () => import("./reminder-scheduler.modal"),
);

const ScheduleReminder: iModalProps<
  typeof ScheduleReminderModal,
  {
    entityType: string;
    entityId: string;
    onScheduled?: (company: any) => any;
    hideOnScheduled?: boolean;
  }
> = {
  name: "add-reminder",
  component: GenerateModalWithSuspense(ScheduleReminderModal),
};

export default ScheduleReminder;
