import { Button, Col, Row } from "antd";
import { useState } from "react";
import { AvailableAddons, iAddOnOption } from "./pricing-plans-data";

export const AddOnPlans = ({
  onBuyAddOn,
}: {
  onBuyAddOn?: (addOn: iAddOnOption) => any;
}) => {
  return (
    <div>
      <Row gutter={10}>
        {AvailableAddons.map((plan) => (
          <Col span={8} key={plan.title}>
            <AddonCard addOn={plan} onBuyAddOn={onBuyAddOn} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export const AddonCard = ({
  addOn,
  onBuyAddOn: _onBuyAddOn,
}: {
  addOn: iAddOnOption;
  onBuyAddOn?: (addOn: iAddOnOption) => any;
}) => {
  const [isProcessing, setProcessing] = useState(false);

  const onBuyAddOn = async () => {
    setProcessing(true);
    try {
      _onBuyAddOn && (await _onBuyAddOn(addOn));
    } catch (e) {
      // Ignore
    }
    setProcessing(false);
  };
  return (
    <div className="rounded-md pricing-card items-center justify-center flex py-6 flex-col border dark:border-gray-700 dark:bg-gray-800 m-4 hover:shadow-md mode_transition">
      <div className="font-semibold text-2xl mb-2">{addOn.title}</div>
      <div className="font-semibold">
        {addOn.pricing.monthly}/<span className="text-sm">month</span>
      </div>
      <div className="text-gray-600 dark:text-gray-400 mb-4 mode_transition">
        Billed Monthly
      </div>
      <div className="description p-2 text-center text-sm">
        {addOn.description}
      </div>

      {_onBuyAddOn && (
        <div className="plan-selector my-2">
          <Button type="dashed" onClick={onBuyAddOn} loading={isProcessing}>
            Buy now
          </Button>
        </div>
      )}
    </div>
  );
};
