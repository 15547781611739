import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const MyProfileModalX = React.lazy(() => import("./my-profile.modal"));

const MyProfileX: iModalProps<typeof MyProfileModalX, {}> = {
  name: "my-profile-x",
  component: GenerateModalWithSuspense(MyProfileModalX),
};

export default MyProfileX;
