import { axios, AxiosResponse } from "@sdk/axios";
import { SDKConfig } from "@sdk/default-sdk-config";
import {
  iOrganization,
  iSLATemplate,
} from "@sdk/user-management/user-management.models";

import { UserTracker } from "user-tracker";

export class SLATemplatesManagementServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.slaTemplates.config = config;
  }

  slaTemplates = Object.assign(
    { config: SDKConfig },
    {
      addSLATemplate: async (data: iSLATemplate) => {
        UserTracker.track("SLA_RULES - Add SLATemplate", {});
        const results: AxiosResponse<iOrganization> = await axios.post(
          this.config.basePath + `/organizations/sla-templates`,
          data,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      editSLATemplate: async (id: string, data: iSLATemplate) => {
        UserTracker.track("SLA_RULES - Edit SLATemplate", {});
        const results: AxiosResponse<iOrganization> = await axios.patch(
          this.config.basePath + `/organizations/sla-templates/${id}`,
          data,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      deleteSLATemplate: async (id: string) => {
        UserTracker.track("SLA_RULES - Delete SLATemplate", {});
        const results: AxiosResponse<iOrganization> = await axios.delete(
          this.config.basePath + `/organizations/sla-templates/${id}`,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
