export const EmailBlock_Text: (data: {
  text: string;
  textAlign?: string;
}) => string = ({ text, textAlign = "left" }) => {
  return /*html*/ `<table class="row">
    <tbody>
        <tr>
            <!-- Main Email content -->
            <th class="small-12 large-12 columns first last">
                <table>
                    <tbody><tr>
                    <th class="expander"></th>
                        <th>
                            <p style="text-align:${textAlign}">${text}</p>
                        </th>
                        <th class="expander"></th>
                    </tr>
                </tbody></table>
            </th>
        </tr>
    </tbody>
</table>`;
};
