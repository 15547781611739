import { SDK } from "@sdk";

import { iMagicAssistant } from "@sdk/magic-assistants/magic-assistants-model";
import { Store } from "redux";
import { QueryConfig } from "store/utils/query-config";
import { iStore } from "../../store.model";
import { CreateHelpersForEntity } from "../../utils/entity-slice-creator";
import { magicAssistantsDefaultQueryConfig } from "./magic-assistants-default-query";
import { MagicAssistantsSlice } from "./magic-assistants.slice";

export const loadAllMagicAssistants = async (
  store: Store<iStore>,
  forceReload?: boolean,
) => {
  await loadMagicAssistantsQuery(magicAssistantsDefaultQueryConfig, "all")(
    store,
    forceReload,
  );
};

export const loadQueryMagicAssistants =
  (queryConfig: QueryConfig, queryAlias?: string) =>
  async (store: Store<iStore>, forceReload?: boolean) => {
    queryAlias = queryAlias || JSON.stringify(queryConfig);
    await loadMagicAssistantsQuery(queryConfig, queryAlias)(store, forceReload);
  };

export const {
  loadEntityQueries: loadMagicAssistantsQuery,
  loadEntityById: loadMagicAssistantById,
  loadMoreEntityQueries: loadMoreMagicAssistantsQueries,
} = CreateHelpersForEntity<iStore, iMagicAssistant>({
  sliceName: "magicAssistants",
  slice: MagicAssistantsSlice,
  isPaginatedQueries: true,
  fetchPageSize: 50,
  loadEntityQueryProvider: (query) => SDK.magicAssistants.query(query),
  loadEntityByIdProvider: (entityId: string) =>
    SDK.magicAssistants.getById(entityId),
});
