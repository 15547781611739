import { useSDK, useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iExternalAppConfig_Jira } from "@sdk/user-management/user-management.models";
import { Alert, Button, Form, Spin, Tag, Tooltip, message } from "antd";
import { ExternalAuthConnector } from "components/common/external-auth-connector-button/external-auth-connector-button";
import {
  LoadingIndicatorWithSpin,
  LoadingIndicatorWithoutSpin,
} from "components/common/loading-indicator/loading-indicator";
import { HelpUsHelpYou } from "components/help-us-help-you/help-us-help-you";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "store/modules/ui-state/ui-state.selectors";
import { selectIntegrationConfig_Zendesk } from "store/modules/workspace/workspace.selectors";

export const ZenDeskIntegrationConfig = () => {
  const integrationConfig = useSelector(selectIntegrationConfig_Zendesk);
  const isDarkMode = useSelector(selectIsDarkMode);

  const { doAction: completeIntegration, isProcessing: isAdding } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (code: string, state: string) =>
          SDK.externalAccounts.completeIntegration({
            type: "ZENDESK",
            data: {
              code,
              state,
            },
          }),
        successMessage: "Integration has been successful",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const { doAction: removeIntegration, isProcessing: isRemoving } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.externalAccounts.removeIntegration({
            type: "ZENDESK",
          }),
        successMessage: "Integration has been removed",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const { doAction: saveConfig, isProcessing: isSavingConfig } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (config) =>
          SDK.externalAccounts.updateConfig({
            type: "ZENDESK",
            config,
          }),
        successMessage: "Configuration has been saved",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const [form] = Form.useForm();

  const [formValues, setFormValues] = useState(
    integrationConfig?.config || ({} as iExternalAppConfig_Jira),
  );

  useEffect(() => {
    if (integrationConfig?.config) {
      setFormValues(integrationConfig?.config);
    }
  }, [integrationConfig?.config]);
  const [isConfigEditMode, setConfigEditMode] = useState(false);

  // const { data: accountOptions, isLoading: isAccountsLoading } = useSDK(
  //   (SDK) =>
  //     SDK.jira.getAvailableResources().then((data) => {
  //       return (data || []).map((item) => ({
  //         value: item.id,
  //         label: item.name,
  //       }));
  //     }),
  //   [integrationConfig],
  //   !integrationConfig || !isConfigEditMode,
  //   []
  // );

  // const { data: projectOptions, isLoading: isProjectsLoading } = useSDK(
  //   (SDK) =>
  //     SDK.jira
  //       .getAvailableProjects({ cloudId: formValues.cloudId })
  //       .then((projectsRes) => {
  //         return (projectsRes || []).map((item) => ({
  //           value: item.id,
  //           label: item.name,
  //         }));
  //       }),
  //   [integrationConfig],
  //   !formValues.cloudId || !isConfigEditMode,
  //   []
  // );

  const onSaveConfig = useCallback(
    (e) => {
      e.preventDefault();
      const process = async () => {
        try {
          await form.validateFields();
          const formValues = form.getFieldsValue();

          // const selectedAccount = accountOptions.find(
          //   (item) => item.value === formValues.cloudId
          // );

          // const selectedProject = projectOptions.find(
          //   (item) => item.value === formValues.projectId
          // );
          // saveConfig({
          //   ...formValues,
          //   isReady: true,
          //   accountName: selectedAccount?.label,
          //   projectName: selectedProject?.label,
          // } as iExternalAppConfig_Jira).then((d) => {
          //   setConfigEditMode(false);
          // });
        } catch (e) {
          message.error("Please check your input");
        }
      };
      process();
    },
    [form],
  );

  const {
    data: test,
    isLoading: isTestingIntegration,
    error,
    reload: reTestIntegration,
  } = useSDK(
    (SDK) => SDK.zenDesk.searchContacts({ query: "test" }),
    [integrationConfig?.config?.isReady],
    !integrationConfig?.config?.isReady,
  );

  useEffect(() => {
    if (integrationConfig && !integrationConfig.config.isReady) {
      setConfigEditMode(true);
    }
  }, [integrationConfig]);

  return (
    <>
      {integrationConfig && (
        <div>
          {integrationConfig.config.isReady ? (
            <Spin
              spinning={isTestingIntegration}
              indicator={<LoadingIndicatorWithSpin />}
            >
              {!error && (
                <Alert
                  message="Integration is active"
                  type="success"
                  showIcon
                />
              )}
              {error && (
                <Alert
                  message="Integration is not working correctly. Try Re-Authenticating"
                  type="error"
                  showIcon
                />
              )}
            </Spin>
          ) : (
            <Alert
              message="ZenDesk integration is not ready yet. Complete the below steps to enable it"
              type="warning"
              showIcon
            />
          )}
          <div className="flex flex-row justify-between mt-8">
            <div className="text">Connected Account</div>
            <div className="flex flex-row items-center">
              <div className="">
                <Tag>
                  {integrationConfig.config.apiDomain || "No Account Selected"}
                </Tag>
              </div>
              <Tooltip title="Remove Integration">
                <Button
                  type="link"
                  icon={<i className="ri-delete-bin-line"></i>}
                  onClick={() => {
                    removeIntegration();
                  }}
                  loading={isRemoving}
                ></Button>
              </Tooltip>
            </div>
          </div>
          {/* <div className="flex flex-row justify-between my-2">
            <div className="text">Default Project</div>
            <div className="flex flex-row items-center">
              <div className="">
                <Tag>
                  {integrationConfig.config.projectName || "Not Selected"}
                </Tag>
              </div>
              {!isConfigEditMode && (
                <>
                  <Tooltip title="Configure">
                    <Button
                      type="link"
                      icon={<i className="ri-pencil-line"></i>}
                      onClick={() => {
                        setConfigEditMode(true);
                      }}
                      loading={isRemoving}
                    ></Button>
                  </Tooltip>
                </>
              )}
            </div>
          </div> */}
          {isConfigEditMode && (
            <div className=" w-full">
              <Form
                form={form}
                layout="vertical"
                initialValues={integrationConfig.config}
                requiredMark={false}
                className="bold-form-labels"
                onFinish={onSaveConfig}
                onValuesChange={() => {
                  const formValues = form.getFieldsValue();
                  setFormValues(formValues);
                }}
              >
                {/* <Form.Item
                  name="cloudId"
                  label="Account"
                  rules={[
                    {
                      required: true,
                      message: "Please select an account",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Account"
                    options={accountOptions}
                    showSearch
                    loading={isAccountsLoading}
                  />
                </Form.Item>

                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const cloudId = getFieldValue(["cloudId"]);
                    if (!cloudId) {
                      return <></>;
                    }
                    return (
                      <Form.Item
                        name="projectId"
                        label="Project"
                        rules={[
                          {
                            required: true,
                            message: "Please select a Project",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Project"
                          options={projectOptions}
                          showSearch
                          loading={isProjectsLoading}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item> */}

                <div className="flex flex-row justify-end items-center">
                  <Button
                    type="primary"
                    className="font-bold"
                    loading={isAdding}
                    onClick={onSaveConfig}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          )}
          <HelpUsHelpYou
            chatMessage="How can we improve ZenDesk Integration for you?"
            emoji="☝️"
            description="Share your workflows with ZenDesk and we'll ensure that our ZenDesk Integration saves you time."
          />
        </div>
      )}

      {!integrationConfig && (
        <div className="flex flex-row justify-center items-center">
          <Spin
            spinning={isAdding}
            indicator={<LoadingIndicatorWithoutSpin />}
            wrapperClassName="flex fill-spinner"
          >
            <ExternalAuthConnector
              type="ZENDESK"
              onAuthenticated={({ code, state }) => {
                completeIntegration(code, state);
              }}
              label="Connect ZenDesk"
            />
          </Spin>
        </div>
      )}
    </>
  );
};
