import { iContact } from "@sdk/crm/crm.models";
import _ from "lodash";
import { iStore } from "../../store.model";
import { CreateSelectorForEntities } from "../../utils/entity-slice-creator";
import { contactsDefaultQueryConfig } from "./contacts-default-query";

export const {
  selectQueryMap: selectContactQueryMap,
  selectEntityMap: selectContactsMap,
  selectQueryMapAll: selectContactsMapAll,
  selectQueryAllList: selectContactsAllList,
  selectAllEntities: selectAllContacts,
  selectAllEntitiesQuery: selectAllContactsQuery,
  selectEntityById: selectContactById,
  selectEntityQuery: selectContactsQuery
} = CreateSelectorForEntities<iStore, iContact>({
  sliceName: "contacts"
});

const generateContactsByCompanyIdQuery = _.memoize((companyId: string) => {
  const queryConfig = {
    ...contactsDefaultQueryConfig,
    query: {
      companyId
    }
  };
  return JSON.stringify(queryConfig);
});

export const selectContactsByCompanyId = (companyId: string) => {
  return selectContactsQuery(generateContactsByCompanyIdQuery(companyId));
};
