import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const NotionDBAddRecordModal = React.lazy(
  () => import("./notion-db-add-record.modal"),
);

const NotionDBAddRecord: iModalProps<
  typeof NotionDBAddRecordModal,
  {
    onAdded?: (pageId: string) => any;
    contactId?: string;
    conversationId?: string;
  }
> = {
  name: "notion-db-add-record",
  component: GenerateModalWithSuspense(NotionDBAddRecordModal),
};

export default NotionDBAddRecord;
