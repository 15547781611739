export const ChatbotAvatarPresets = [
  "https://firebasestorage.googleapis.com/v0/b/click-connector-staging.appspot.com/o/fc89e0_1.1730617560187.png?alt=media",
  "https://firebasestorage.googleapis.com/v0/b/click-connector-staging.appspot.com/o/fc89e0_2.1730617581624.png?alt=media",
  "https://firebasestorage.googleapis.com/v0/b/click-connector-staging.appspot.com/o/fc89e0_3.1730617748394.png?alt=media",
  "https://firebasestorage.googleapis.com/v0/b/click-connector-staging.appspot.com/o/fc89e0_4.1730617616110.png?alt=media",
  "https://firebasestorage.googleapis.com/v0/b/click-connector-staging.appspot.com/o/fc89e0_5.1730617628194.png?alt=media",
  "https://firebasestorage.googleapis.com/v0/b/click-connector-staging.appspot.com/o/fc89e0_6.1730617640993.png?alt=media",
  "https://firebasestorage.googleapis.com/v0/b/click-connector-staging.appspot.com/o/fc89e0_7.1730617654527.png?alt=media",
  "https://firebasestorage.googleapis.com/v0/b/click-connector-staging.appspot.com/o/fc89e0_8.1730617669975.png?alt=media",
  "https://firebasestorage.googleapis.com/v0/b/click-connector-staging.appspot.com/o/fc89e0_9.1730617682399.png?alt=media",
  "https://firebasestorage.googleapis.com/v0/b/click-connector-staging.appspot.com/o/fc89e0_10.1730617694418.png?alt=media",
  "https://firebasestorage.googleapis.com/v0/b/click-connector-staging.appspot.com/o/fc89e0_11.1730617720765.png?alt=media",
  "https://firebasestorage.googleapis.com/v0/b/click-connector-staging.appspot.com/o/fc89e0_12.1730617735630.png?alt=media",
];
