import { Popover } from "antd";
import React from "react";
import { GithubPicker } from "react-color";
import "./color-input.scss";

export const ColorInput = ({
  value,
  onChange,
  isRounded
}: {
  value?: string;
  onChange?: (value: string) => any;
  isRounded?: boolean;
}) => {
  return (
    <Popover
      content={
        <GithubPicker
          triangle="hide"
          color={value}
          onChange={e => {
            console.log("value", value);
            onChange && onChange(e.hex);
          }}
          width={"210px"}
        />
      }
      trigger="click"
      overlayClassName="color-input-popover"
    >
      <div
        className="color-picker-selected-color rounded-full cursor-pointer"
        style={{ borderRadius: isRounded ? "50%" : 3, background: value }}
      ></div>
    </Popover>
  );
};
