import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddTelegramConnectionModal = React.lazy(
  () => import("./add-telegram-connection.modal"),
);

const AddTelegramConnection: iModalProps<typeof AddTelegramConnectionModal> = {
  name: "add-telegram-connection",
  component: GenerateModalWithSuspense(AddTelegramConnectionModal),
};

export default AddTelegramConnection;
