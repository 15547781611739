export const defaultWidgetColors = [
  "#011f40",
  "#0f0fb2",
  "#00d19c",
  "#000000",
  "#40c280",
  "#ffc300",
  "#f11124",
  "#9013FE",
  "#0e3c55",
  "#3964fe",
  "#f89406",
  "#222222",
  "#D25852",
  "#8A2BE2",
  "#00B16A",
  "#EF4836",
  "#3455DB",
  "#D91E18"
];
