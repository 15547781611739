import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const CreateOpportunityModal = React.lazy(
  () => import("./create-opportunity.modal"),
);

const CreateOpportunity: iModalProps<typeof CreateOpportunityModal> = {
  name: "add-opportunity",
  component: GenerateModalWithSuspense(CreateOpportunityModal),
};

export default CreateOpportunity;
