import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import { Button, Space, Table } from "antd";
import { EmptyData } from "components/common/empty-data/empty-data";
import {
  DragHandle,
  DraggableBodyRowGenerator,
  DraggableContainerGenerator,
} from "components/common/sortable-table/sortable-table-elements";
import { useSortableTable } from "components/common/sortable-table/use-sortable-table";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { useEffectWhen } from "utils/hooks/use-effect-when";

import { iAction } from "../../../../../@sdk/chat-bots/action-models";

import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { useValidActions } from "../action-selector/use-valid-actionts";
import ActionEditor from "./action-editor.modal-registry";
import { ActionLabels } from "./actions-registry";

export const ActionList = ({
  value,
  onChange,
  label,
  excludeActions,
  includedActionGroups,
}: {
  value?: iAction[];
  onChange?: (actions: iAction[]) => any;
  label?: string;
  excludeActions?: string[];
  includedActionGroups?: string[];
}) => {
  const tableData = useMemo(() => value || [], [value]);

  const { state, setState, onSortEnd } = useSortableTable(tableData);

  useEffectWhen(
    () => {
      if (onChange) {
        onChange(state);
      }
    },
    [onChange, state],
    [state],
  );

  useEffect(() => {
    setState(tableData);
  }, [setState, tableData]);

  const { triggerTempModal } = useModalPanels();

  const columns = useMemo(
    () => [
      {
        title: "",
        dataIndex: "sort",
        width: 30,
        className: "drag-visible",
        render: () => <DragHandle />,
      },
      {
        title: "Action",
        dataIndex: "action",
        render: (data, record: iAction, index) =>
          `${ActionLabels[record.action] || "Unknown Action"}`,
        className: "drag-visible",
      },
      {
        title: "",
        dataIndex: "description",
        className: "drag-visible",
        render: (data, record: iAction, index) => {
          return ``;
        },
      },
      {
        title: "",
        dataIndex: "remove",
        width: 100,
        render: (data, record, index) => (
          <Space>
            <Button
              icon={<i className="ri-edit-line" />}
              type="link"
              onClick={() => {
                triggerTempModal(ActionEditor, {
                  initialValue: record,
                  excludeActions,
                  includedActionGroups,
                  onSave: (action) => {
                    setState((actions) => {
                      if (_.find(actions, { id: action.id })) {
                        return actions.map((item) => {
                          if (item.id === action.id) {
                            return action;
                          }
                          return item;
                        });
                      }
                      return [...actions, action];
                    });
                  },
                });
              }}
            />
            <Button
              icon={<DeleteOutlined />}
              type="link"
              onClick={() => {
                setState(_.without(state, record));
              }}
            />
          </Space>
        ),
      },
    ],
    [excludeActions, includedActionGroups, setState, state, triggerTempModal],
  );

  const { AllValidActions } = useValidActions({
    excludeActions,
    includedActionGroups,
  });

  useEffect(() => {
    const validActions = (value || []).filter((item) => {
      return AllValidActions.includes(item.action);
    });

    if (validActions.length !== (value || []).length) {
      onChange && onChange(validActions);
    }
  }, [AllValidActions, includedActionGroups, onChange, value]);

  return (
    <div className="w-full">
      {state.length > 0 && (
        <Table
          columns={columns}
          dataSource={state}
          pagination={false}
          showHeader={false}
          rowKey="id"
          components={{
            body: {
              wrapper: DraggableContainerGenerator(onSortEnd),
              row: DraggableBodyRowGenerator(state),
            },
          }}
          locale={{
            emptyText: (
              <EmptyData
                text="No Actions have been added"
                icon="ri-route-line"
              />
            ),
          }}
          className="w-full"
        />
      )}

      <div className="flex flex-row justify-center items-center my-8">
        <Button
          type="dashed"
          icon={<i className="ri-add-line"></i>}
          onClick={() => {
            triggerTempModal(ActionEditor, {
              initialValue: undefined,
              excludeActions,
              includedActionGroups,
              onSave: (action) => {
                setState((actions) => {
                  if (_.find(actions, { id: action.id })) {
                    return actions.map((item) => {
                      if (item.id === action.id) {
                        return action;
                      }
                      return item;
                    });
                  }
                  return [...actions, action];
                });
              },
            });
          }}
          className="font-bold"
        >
          {label || "Add Further Action"}
        </Button>
      </div>
    </div>
  );
};
