import { iStore } from "../../store.model";

const emptyArray = [];

export const selectSocketStatus = (state: iStore) =>
  state.appState.isSocketConnected;

export const selectIsSwitchingProfile = (state: iStore) =>
  state.appState.isSwitchingProfile;

export const selectRecentLogins = (state: iStore) =>
  state.appState.recentLogins || emptyArray;

export const selectLastAppVersion = (state: iStore) =>
  state.appState.lastAppVersion;

export const selectAppData = (state: iStore) => state.appState.appData;
