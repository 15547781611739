import { axios, AxiosResponse } from "@sdk/axios";
import { SDKConfig } from "@sdk/default-sdk-config";
import {
  iCCAutomationRule,
  iOrganization,
} from "@sdk/user-management/user-management.models";
import { UserTracker } from "user-tracker";

export class AutomationRulesManagementServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.automationRules.config = config;
  }

  automationRules = Object.assign(
    { config: SDKConfig },
    {
      addAutomationRule: async (data: iCCAutomationRule) => {
        UserTracker.track("AUTOMATION_RULES - Add AutomationRule", {});
        const results: AxiosResponse<iOrganization> = await axios.post(
          this.config.basePath + `/organizations/automation-rules`,
          data,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      editAutomationRule: async (id: string, data: iCCAutomationRule) => {
        UserTracker.track("AUTOMATION_RULES - Edit AutomationRule", {});
        const results: AxiosResponse<iOrganization> = await axios.patch(
          this.config.basePath + `/organizations/automation-rules/${id}`,
          data,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      deleteAutomationRule: async (id: string) => {
        UserTracker.track("AUTOMATION_RULES - Delete AutomationRule", {});
        const results: AxiosResponse<iOrganization> = await axios.delete(
          this.config.basePath + `/organizations/automation-rules/${id}`,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
