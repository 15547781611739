import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const PipeDriveCreateDealModal = React.lazy(
  () => import("./pipedrive-create-deal.modal"),
);

const PipeDriveCreateDeal: iModalProps<
  typeof PipeDriveCreateDealModal,
  {
    onAdded?: (pageId: string) => any;
    contactId?: string;
    conversationId?: string;
  }
> = {
  name: "pipe-drive-create-deal",
  component: GenerateModalWithSuspense(PipeDriveCreateDealModal),
};

export default PipeDriveCreateDeal;
