import { useSDK } from "@sdk";
import { SimpleCardSection } from "components/common/simple-carded-section";
import { StyledScrollArea } from "components/common/styled-scroll-area";
import { useSelector } from "react-redux";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";

export const ManagedByParentWorkspace = () => {
  const organization = useSelector(selectOrganization);
  const { data: parentWorkspace, isLoading } = useSDK(
    (SDK) =>
      SDK.getOrganizationPublicData(organization?.data?.parentOrganizationId!),
    [organization?.data?.parentOrganizationId],
    !organization?.data?.parentOrganizationId,
  );
  return (
    <StyledScrollArea className="w-full h-full animated fadeInLeftMin">
      <SimpleCardSection className="max-w-screen-md xxl:max-w-screen-lg m-auto p-8 lg:p-12 my-4 flex flex-col justify-center items-center">
        {/* Managed By Parent Workspace */}
        <img
          src={parentWorkspace?.data?.logo}
          alt="Parent Workspace"
          style={{}}
        />
        <div style={{}}>
          Your workspace subscription billing is managed by{" "}
          {parentWorkspace?.data?.name}
        </div>
      </SimpleCardSection>
    </StyledScrollArea>
  );
};
