import { iIndexedWebPage } from "@sdk/indexed-web-pages/indexed-web-pages-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { indexedWebPagesDefaultQueryConfig } from "./indexed-web-pages-default-query";

export const {
  slice: IndexedWebPagesSlice,
  reducers: IndexedWebPagesSliceReducer,
  actions: {
    setEntityQueryResults: setIndexedWebPagesQueryResults,
    setEntity: setIndexedWebPage,
    setEntities: setIndexedWebPages,
    patchEntity: patchIndexedWebPage,
    addNewEntity: addNewIndexedWebPage,
    setEntityFetchingStatus: setIndexedWebPageFetchingStatus,
    setEntitiesFetchingStatus: setIndexedWebPagesFetchingStatus,
    setEntityFailedStatus: setIndexedWebPageFailedStatus,
    setEntityQueryError: setIndexedWebPagesQueryError,
    resetQuery: resetIndexedWebPagesQuery,
    setQueryList: setIndexedWebPageQueryList
  }
} = CreateEntitySlice<iIndexedWebPage>({
  sliceName: "indexedWebPages",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...indexedWebPagesDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
