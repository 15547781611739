import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ConfigureCampaignModal = React.lazy(
  () => import("./configure-campaign.modal"),
);

const ConfigureCampaign: iModalProps<
  typeof ConfigureCampaignModal,
  {
    campaignId: string;
    mode?: "SEND" | "EDIT";
  }
> = {
  name: "configure-campaign",
  component: GenerateModalWithSuspense(ConfigureCampaignModal),
};

export default ConfigureCampaign;
