import { iConnectorConversationActionData } from "@sdk/chat-connectors/chat-connectors.models";
import { Form, Input } from "antd";
import { forwardRef, useImperativeHandle } from "react";
import { IconSelector } from "./icon-selector";
import { URLBuilder } from "./url-builder";

export const ConfigureChatAction = forwardRef((props, ref) => {
  const [form] = Form.useForm();

  useImperativeHandle(
    ref,
    () => ({
      setSnapshot(connectorData: iConnectorConversationActionData) {
        form.setFieldsValue(connectorData);
      },
      getSnapshot() {
        const data = form.getFieldsValue();
        return data as iConnectorConversationActionData;
      }
    }),
    [form]
  );

  return (
    <div className="chat-action-builder">
      <div className="border rounded-lg p-4 m-4" style={{}}>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ type: "CONVERSATION_ACTION" }}
        >
          <Form.Item
            name="actionLabel"
            label={<div className="font-bold">Chat Action Label</div>}
            rules={[
              {
                required: true,
                message: "Please enter a label"
              }
            ]}
          >
            <Input placeholder="Label" size="large" />
          </Form.Item>
          <Form.Item
            name="actionIcon"
            label={<div className="font-bold">Chat Action Icon</div>}
            rules={[]}
          >
            <IconSelector />
          </Form.Item>
          <Form.Item
            name="apiCall"
            label={<div className="font-bold">Action API Call</div>}
          >
            <URLBuilder />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
});
