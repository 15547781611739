import { getRoleFromPermission } from "@sdk/roles-and-permissions/roles-and-permissions";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "store/modules/users/users.selectors";
import { selectOrganization } from "store/modules/workspace/workspace.selectors";

export const useIsAccountOnboard = () => {
  const user = useSelector(selectCurrentUser);
  const organization = useSelector(selectOrganization);
  const currentUserRole = getRoleFromPermission(user?.permissions || []);

  const isNewAccount =
    (organization?.metaData?.createdTime || 0) > 1663940158210;

  return {
    isOnboard: isNewAccount
      ? organization?.appData?.ONBOARDING?.preferenceCompleted
      : true,
    isOwner: currentUserRole === "Owner",
    isSetupCompleted:
      organization?.appData?.ONBOARDING?.workspaceSetupCompleted,
  };
};
