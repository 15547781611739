import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const AddWhatsappConnectionModal = React.lazy(
  () => import("./add-whatsapp-private-connection.modal"),
);

const AddWhatsappConnection: iModalProps<typeof AddWhatsappConnectionModal> = {
  name: "add-whatsapp-connection",
  component: GenerateModalWithSuspense(AddWhatsappConnectionModal),
};

export default AddWhatsappConnection;
