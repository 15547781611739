import { axios, AxiosResponse } from "@sdk/axios";
import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import {
  PaginatedOptions,
  PaginatedResults,
} from "@sdk/utils/paginated-results";
import { UserTracker } from "user-tracker";
import { iNewsFeedItem, iNewsFeedItemReactionLog } from "./newsfeed-model";

export class NewsfeedItemsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.newsFeedItems.configure(config);
  }

  newsFeedItems = Object.assign(
    new EntityServices<iNewsFeedItem>(SDKConfig, "newsfeed-items", {
      onCreate: () => {
        UserTracker.track("NEWSFEED_ITEM - Create", {});
      },
      onDelete: () => {
        UserTracker.track("NEWSFEED_ITEM - Delete", {});
      },
    }),
    {
      queryLogs: async (
        newsfeedItemId: string,
        req: { query: any; options: PaginatedOptions },
      ) => {
        const results: AxiosResponse<
          PaginatedResults<iNewsFeedItemReactionLog>
        > = await axios.post(
          this.config.basePath + `/newsfeed-items/${newsfeedItemId}/logs/query`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      queryReport: async (newsfeedItemId: string, req: { query: any }) => {
        const results: AxiosResponse<{ count: number }> = await axios.post(
          this.config.basePath + `/newsfeed-items/${newsfeedItemId}/report`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
    },
  );
}
