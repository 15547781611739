import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const CreateSubAccountWorkspaceModal = React.lazy(
  () => import("./create-sub-account.modal"),
);

const CreateSubAccountWorkspace: iModalProps<
  typeof CreateSubAccountWorkspaceModal,
  {
    onCreated?: () => any;
  }
> = {
  name: "create-sub-account",
  component: GenerateModalWithSuspense(CreateSubAccountWorkspaceModal),
};

export default CreateSubAccountWorkspace;
