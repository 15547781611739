import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import { iConnectedApp } from "@sdk/connected-apps/connected-apps.models";
import React from "react";

const AddWebhookAppModel = React.lazy(() => import("./add-webhook-app.model"));

const AddWebhookApp: iModalProps<
  typeof AddWebhookAppModel,
  { onCreated: (app: iConnectedApp) => any }
> = {
  name: "add-webhook-app",
  component: GenerateModalWithSuspense(AddWebhookAppModel),
};

export default AddWebhookApp;
