import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const MyProfileModal = React.lazy(() => import("./user-profile.modal"));

const MyProfile: iModalProps<typeof MyProfileModal, {}> = {
  name: "my-profile",
  component: GenerateModalWithSuspense(MyProfileModal),
};

export default MyProfile;
