import { BuiltInControlType } from "braft-editor";

export const defaultAdditionalExcludedBraftControllers = [
  "text-color",
  "headings",
  "font-size",
  "emoji",
  "fullscreen",
  "hr",
  "letter-spacing",
  "remove-styles",
  "undo",
  "redo",
  "text-indent",
  "text-align",
] as BuiltInControlType[];

export const defaultAdditionalExcludedBraftControllersType2 = [
  "text-color",
  "headings",
  "font-size",
  "emoji",
  "fullscreen",
  "hr",
  "link",
  "letter-spacing",
  "remove-styles",
  "undo",
  "redo",
  "text-indent",
  "text-align",
] as BuiltInControlType[];
