import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const BotBuilderModal = React.lazy(() => import("./bot-builder.modal"));

const BotBuilder: iModalProps<typeof BotBuilderModal, { chatBotId: string }> = {
  name: "bot-builder",
  component: GenerateModalWithSuspense(BotBuilderModal),
};

export default BotBuilder;
