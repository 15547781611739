import {
  mdiCalendarMultiselectOutline,
  mdiCellphoneBasic,
  mdiFormDropdown,
  mdiFormTextarea,
  mdiFormTextbox,
  mdiNumeric,
  mdiRadioboxMarked,
  mdiToggleSwitchOffOutline
} from "@mdi/js";
import { iInputType } from "@sdk/dynamic-forms/dynamic-forms-model";
import { MDIIcon } from "components/common/mdi-icon";

export const FormFieldIcon = ({ type }: { type: iInputType }) => {
  if (type === "CHECK_BOX") {
    return <i className="ri-checkbox-circle-line"></i>;
  }

  if (type === "DATE") {
    return <i className="ri-calendar-2-line"></i>;
  }

  if (type === "EMAIL") {
    return <i className="ri-mail-line"></i>;
  }

  if (type === "MULTI_SELECT") {
    return <MDIIcon icon={mdiCalendarMultiselectOutline} />;
  }

  if (type === "NUMBER") {
    return <MDIIcon icon={mdiNumeric} />;
  }

  if (type === "PHONE") {
    return <MDIIcon icon={mdiCellphoneBasic} />;
  }

  if (type === "RADIO") {
    return <MDIIcon icon={mdiRadioboxMarked} />;
  }

  if (type === "SELECT") {
    return <MDIIcon icon={mdiFormDropdown} />;
  }

  if (type === "SWITCH") {
    return <MDIIcon icon={mdiToggleSwitchOffOutline} />;
  }

  if (type === "TEXT") {
    return <MDIIcon icon={mdiFormTextbox} />;
  }

  if (type === "TEXTAREA") {
    return <MDIIcon icon={mdiFormTextarea} />;
  }
  return <>UnidentifiedType</>;
};
