import { iNotificationRecord } from "@sdk/notification-inbox/notifications.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Button, List, Space } from "antd";
import { push } from "connected-react-router";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useDispatch } from "react-redux";
dayjs.extend(relativeTime);

export const NotificationItem_Invite = ({
  notification,
}: {
  notification: iNotificationRecord;
}) => {
  const { conversationId, expireAt } = notification.data as {
    conversationId: string;
    expireAt: number;
  };
  const { doAction: archive, isProcessing } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () => SDK.dismissNotification(notification.id),
      successMessage: "Done",
      failureMessage: "Something went wrong",
      actionDependencies: [notification.id],
    }),
    [notification.id]
  );

  const dispatch = useDispatch();

  const {
    doAction: joinConversation,
    isProcessing: isActionProcessing,
  } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () =>
        SDK.joinConversation(conversationId)
          .then((d) => {
            // Navigate to page
            dispatch(push(`/conversations/auto/${conversationId}`));
            return d;
          })
          .then((d) => {
            return archive(notification.id);
          }),
      successMessage: "Done",
      failureMessage: "Something went wrong",
      actionDependencies: [conversationId, archive, notification.id],
    }),
    [archive, conversationId, dispatch, notification.id]
  );

  const currentTime = new Date().getTime();

  return (
    <List.Item
      onClick={() => {
        dispatch(push(`/conversations/auto/${conversationId}`));
      }}
      className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-900 rounded-lg p-4 notification-item mode_transition"
    >
      <List.Item.Meta
        avatar={
          <div className=" rounded-full bg-gray-100  dark:bg-gray-700 w-10 h-10 flex flex-row justify-center items-center font-bold mode_transition">
            <i className="ri-chat-new-line"></i>,
          </div>
        }
        title={<div className="font-bold">{notification.message}</div>}
        description={
          <div className="flex-row justify-between flex">
            <span>{dayjs(notification.createdAt).fromNow()}</span>
            <div className="actions flex flex-row justify-end items-center">
              <Space>
                {!notification.isDismissed && (
                  <Button
                    type="text"
                    onClick={(e) => {
                      archive();
                      e.stopPropagation();
                    }}
                    className="archive-button"
                  >
                    <i className="ri-inbox-archive-line"></i>
                  </Button>
                )}

                <Button
                  type="primary"
                  size="small"
                  onClick={(e) => {
                    joinConversation();
                    e.stopPropagation();
                  }}
                  loading={isActionProcessing}
                  disabled={expireAt < currentTime}
                >
                  Accept
                </Button>
              </Space>
            </div>
          </div>
        }
      />
    </List.Item>
  );
};
