import React from "react";
import { Collapse } from "react-collapse";

export const SimpleCollapsePane = ({
  isCollapsed,
  children
}: {
  isCollapsed: boolean;
  children;
}) => {
  return <Collapse isOpened={isCollapsed}>{children}</Collapse>;
};
