import { iConnector } from "@sdk/chat-connectors/chat-connectors.models";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { chatConnectorsDefaultQueryConfig } from "./chat-connectors-default-query";

export const {
  slice: ChatConnectorsSlice,
  reducers: ChatConnectorsSliceReducer,
  actions: {
    setEntityQueryResults: setChatConnectorsQueryResults,
    setEntity: setChatConnector,
    setEntities: setChatConnectors,
    patchEntity: patchChatConnector,
    addNewEntity: addNewChatConnector,
    setEntityFetchingStatus: setChatConnectorFetchingStatus,
    setEntitiesFetchingStatus: setChatConnectorsFetchingStatus,
    setEntityFailedStatus: setChatConnectorFailedStatus,
    setEntityQueryError: setChatConnectorsQueryError,
    resetQuery: resetChatConnectorsQuery,
    setQueryList: setChatConnectorQueryList
  }
} = CreateEntitySlice<iConnector>({
  sliceName: "chatConnectors",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...chatConnectorsDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
