import { axios, AxiosResponse } from "@sdk/axios";
import { UserTracker } from "user-tracker";
import { GooglePlaceDetailRes } from "./google-places-api-model";
import { iPNRConverterResponse } from "./pnr-cerverter-response-model";

export interface iSendRequestBody {
  subject: string;
  data: { [key: string]: string };
}

export class AppService {
  constructor(protected config: { basePath: string; token: string }) {}

  async getAppConfig() {
    const results: AxiosResponse<iAppData> = await axios.get(
      this.config.basePath + `/cc/app-data`,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async sendRequest(req: iSendRequestBody) {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/cc/send-to-team`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async sendErrorLog(req: iSendRequestBody) {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/cc/send-error-log`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async resetDemo() {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/cc/reset-demo`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async seedDemo() {
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/cc/seed-demo`,
      {},
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async processOpenAiPrompts(req: {
    type: "COMPLETE" | "CHAT" | "EDIT";
    instruction: string;
    input: string;
  }) {
    UserTracker.track("Process OPEN AI Prompt", req);
    const results: AxiosResponse<any> = await axios.post(
      this.config.basePath + `/cc/process-open-ai-prompt`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );

    return results.data;
  }

  async pnrConverter(req: { pnr: string }) {
    UserTracker.track("Convert PNR", req);
    // const data = new URLSearchParams();
    const results: AxiosResponse<iPNRConverterResponse> = await axios.post(
      // "https://api.pnrconverter.com/api/convert",
      "https://us-central1-click-connector.cloudfunctions.net/app/pnr-converter",
      req,
      {
        headers: {},
      },
    );
    return results.data;
  }

  async convertToBase64(req: { url: string }) {
    // const data = new URLSearchParams();
    const results: AxiosResponse<{ base44: string }> = await axios.post(
      "https://us-central1-click-connector.cloudfunctions.net/app/to-base64",
      req,
      {
        headers: {},
      },
    );
    return results.data;
  }

  async getAppDownloadLinks() {
    const results: AxiosResponse<iGithubLatestRelease> = await axios.get(
      `https://api.github.com/repos/click-connector/clickconnector-app/releases/latest`,
      {
        headers: {
          "Content-Length": "0",
        },
      },
    );
    return results.data;
  }

  // Get Place Details

  async getPlaceDetails(placeId: string) {
    const results: AxiosResponse<GooglePlaceDetailRes> = await axios.post(
      "https://us-central1-click-connector.cloudfunctions.net/app/get-place-data",
      {
        placeId,
      },
      {
        headers: {},
      },
    );
    return results.data;
  }
  async getDestinationSnippet(title: string) {
    const results: AxiosResponse<{
      batchcomplete: string;
      query: {
        pages: {
          [key: string]: {
            pageid: number;
            ns: number;
            title: string;
            extract: string;
          };
        };
      };
    }> = await axios.post(
      "https://us-central1-click-connector.cloudfunctions.net/app/get-wiki-snippet",
      {
        title,
      },
      {
        headers: {},
      },
    );
    return results.data;
  }

  async getWeatherUrl(placeId: string) {
    const results: AxiosResponse<{
      path: string;
    }> = await axios.post(
      "https://us-central1-click-connector.cloudfunctions.net/app/get-weather-api-url",
      {
        placeId,
      },
      {
        headers: {},
      },
    );
    return results.data;
  }

  async getPlaceId(input: string) {
    const results: AxiosResponse<{
      candidates: {
        place_id: string;
      }[];
      status: string;
    }> = await axios.post(
      "https://us-central1-click-connector.cloudfunctions.net/app/get-place-id",
      {
        input,
      },
      {
        headers: {},
      },
    );
    return results.data;
  }
}

export interface iGithubLatestRelease {
  url: string;
  assets_url: string;
  upload_url: string;
  html_url: string;
  id: number;
  author: Author;
  node_id: string;
  tag_name: string;
  target_commitish: string;
  name: string;
  draft: boolean;
  prerelease: boolean;
  created_at: string;
  published_at: string;
  assets: {
    url: string;
    id: number;
    node_id: string;
    name: string;
    label: string;
    uploader: Author;
    content_type: string;
    state: string;
    size: number;
    download_count: number;
    created_at: string;
    updated_at: string;
    browser_download_url: string;
  }[];
  tarball_url: string;
  zipball_url: string;
  body: string;
}

interface Author {
  login: string;
  id: number;
  node_id: string;
  avatar_url: string;
  gravatar_id: string;
  url: string;
  html_url: string;
  followers_url: string;
  following_url: string;
  gists_url: string;
  starred_url: string;
  subscriptions_url: string;
  organizations_url: string;
  repos_url: string;
  events_url: string;
  received_events_url: string;
  type: string;
  site_admin: boolean;
}

export interface iAppData {
  latestVersion: number;
  insiderUsers: string[];
  insiderOrganizations: string[];
  alphaUsers: string[];
  alphaOrganizations: string[];
  betaUsers: string[];
  betaOrganizations: string[];
}
