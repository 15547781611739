export const EmailBlock_CTA: (data: {
  label: string;
  url: string;
  buttonColor?: string;
}) => string = ({ label, url, buttonColor = "#8c54a1" }) => {
  return /*html*/ `<table class="row">
    <tbody>
        <tr>
            <!-- Call to Action -->
            <th class="small-12 large-12 columns first last">
                <table>
                    <tr>
                        <th>
                        <center data-parsed="">
                        <br>
                        <div class="button">
                            <!--[if mso]>
<v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="${url}" style="height:40px;v-text-anchor:middle;width:200px;" arcsize="8%" strokecolor="${buttonColor}" fillcolor="${buttonColor}">
<w:anchorlock/>
<center style="color:#ffffff;font-family:sans-serif;font-size:16px;font-weight:bold;">${label}</center>
</v:roundrect>
<![endif]-->
                            <a href="${url}" class="button-link">${label}</a>
                        </div>
                    </center>
                        </th>
                        <th class="expander"></th>
                    </tr>
                </table>
            </th>
        </tr>
    </tbody>
</table>`;
};
