import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const JiraTaskBrowserModal = React.lazy(
  () => import("./jira-task-browser.modal"),
);

const JiraTaskBrowser: iModalProps<
  typeof JiraTaskBrowserModal,
  {
    onSelected?: (pageId: string) => any;
  }
> = {
  name: "jira-task-browser",
  component: GenerateModalWithSuspense(JiraTaskBrowserModal),
};

export default JiraTaskBrowser;
