import { useSDK, useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Alert, Button, Form, Input, Spin, Tooltip, message } from "antd";
import { LoadingIndicatorWithSpin } from "components/common/loading-indicator/loading-indicator";
import { HelpUsHelpYou } from "components/help-us-help-you/help-us-help-you";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "store/modules/ui-state/ui-state.selectors";
import { selectIntegrationConfig_Stripe } from "store/modules/workspace/workspace.selectors";
import { AddEllipsis } from "utils/add-ellipsis";
import { MaskSecretKey } from "utils/mask-secert-key";

export const StripeIntegrationConfig = () => {
  const integrationConfig = useSelector(selectIntegrationConfig_Stripe);
  const isDarkMode = useSelector(selectIsDarkMode);

  const { doAction: completeIntegration, isProcessing: isAdding } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (apiKey: string) =>
          SDK.externalAccounts.completeIntegration({
            type: "STRIPE",
            data: {
              apiKey,
            },
          }),
        successMessage: "Integration has been successful",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const { doAction: removeIntegration, isProcessing: isRemoving } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.externalAccounts.removeIntegration({
            type: "STRIPE",
          }),
        successMessage: "Integration has been removed",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const [form] = Form.useForm();

  const onFinish = useCallback(
    (e) => {
      e.preventDefault();
      const process = async () => {
        try {
          await form.validateFields();
          const { apiKey } = form.getFieldsValue();
          completeIntegration(apiKey);
        } catch (e) {
          message.error("Please check your input");
        }
      };
      process();
    },
    [completeIntegration, form],
  );

  const {
    data: test,
    isLoading: isTestingIntegration,
    error,
    reload: reTestIntegration,
  } = useSDK(
    (SDK) => SDK.stripe.searchCustomers({ name: "test" }),
    [integrationConfig?.auth?.apiKey],
    !integrationConfig?.auth?.apiKey,
  );

  return (
    <>
      {integrationConfig && (
        <div>
          <Spin
            spinning={isTestingIntegration}
            indicator={<LoadingIndicatorWithSpin />}
          >
            {!error && (
              <Alert message="Integration is active" type="success" showIcon />
            )}
            {error && (
              <Alert
                message="Integration is not working correctly. Check your API Key"
                type="error"
                showIcon
              />
            )}
          </Spin>

          <div className="flex flex-row justify-between my-8">
            <div className="text">API Key</div>
            <div className="flex flex-row items-center">
              <div className="text-gray-600">
                {AddEllipsis(
                  MaskSecretKey(integrationConfig.auth.apiKey || "", 4),
                  40,
                )}
              </div>
              <Tooltip title="Remove Integration">
                <Button
                  type="link"
                  icon={<i className="ri-delete-bin-line"></i>}
                  onClick={() => {
                    removeIntegration();
                  }}
                  loading={isRemoving}
                ></Button>
              </Tooltip>
            </div>
          </div>
          <HelpUsHelpYou
            chatMessage="How can we improve Stripe Integration for you?"
            emoji="☝️"
            description="Share your workflows with Stripe and we'll ensure that our Stripe Integration saves you time."
          />
        </div>
      )}

      {!integrationConfig && (
        <div className=" w-full">
          <Form
            form={form}
            layout="vertical"
            initialValues={{}}
            requiredMark={false}
            className="bold-form-labels"
            onFinish={onFinish}
          >
            <Form.Item
              name="apiKey"
              label="API Key"
              rules={[
                {
                  required: true,
                  message: "Please enter a API Key",
                },
              ]}
            >
              <Input placeholder="API Key" type="password" />
            </Form.Item>
            <div className="flex flex-row justify-end items-center">
              <Button
                type="primary"
                className="font-bold"
                loading={isAdding}
                onClick={onFinish}
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};
