import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSubscriptionDetails } from "store/modules/workspace/workspace.selectors";

export const useShopifyBillingStatusChecker = () => {
  const subscriptionDetails = useSelector(selectSubscriptionDetails);

  const {
    doAction: reloadBillingStatus,
    isProcessing: isReloadingStatus
  } = useSDKActionWithDeps(
    () => ({
      action: SDK => () => SDK.shopify.refreshBillingStatus(),
      // successMessage: "Done",
      failureMessage: "Something went wrong"
    }),
    []
  );

  const [isShopifyBillingCheckerActivated, setSHopifyBillingChecker] = useState(
    false
  );

  useEffect(() => {
    if (isShopifyBillingCheckerActivated) {
      let counter = 0;
      const timer = setInterval(() => {
        counter++;
        reloadBillingStatus();
        if (counter > 20) {
          clearInterval(timer);
        }
      }, 8000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [isShopifyBillingCheckerActivated, reloadBillingStatus]);

  useEffect(() => {
    if (subscriptionDetails?.planId) {
      setSHopifyBillingChecker(false);
    }
  }, [subscriptionDetails?.planId]);

  return [
    isShopifyBillingCheckerActivated,
    setSHopifyBillingChecker,
    reloadBillingStatus,
    isReloadingStatus
  ] as [
    typeof isShopifyBillingCheckerActivated,
    typeof setSHopifyBillingChecker,
    typeof reloadBillingStatus,
    typeof isReloadingStatus
  ];
};
