import { Divider, Switch } from "antd";
import { CodeBlockWithCopyButton } from "components/common/code-blocks-with-copy-button";
import { GlobalConfig } from "config";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelfServicePortalById } from "store/modules/self-service-portals/self-service-portals.selectors";

export const EmbedKBPortalSetup = ({ kbPortalId }: { kbPortalId: string }) => {
  const selfServicePortal = useSelector(
    selectSelfServicePortalById(kbPortalId),
  );

  const [isDarkMode, setDarkMode] = useState(false);

  const portalUrl = useMemo(() => {
    const selectedPortal = selfServicePortal;
    if (GlobalConfig.IS_PROD) {
      const domainConfig = selectedPortal.data.domain;
      const url =
        domainConfig.customDomainConfig?.domain &&
        domainConfig.customDomainConfig?.isVerified &&
        domainConfig.customDomainConfig?.isConfigured
          ? `https://${domainConfig.customDomainConfig?.domain}`
          : `https://${domainConfig.subdomain}.helpcenter.guide`;
      return url;
    }
    return `https://kb.clickconnector.com`;
    // return `http://localhost:3000`;
  }, [selfServicePortal]);

  const embedCode = useMemo(() => {
    return `<iframe 
src="${portalUrl}/embed?theme=${isDarkMode ? "dark" : "light"}"
style="border:0px # none;" name="cc-embed-kb-frame" scrolling="yes"
frameborder="0" marginheight="0px" marginwidth="0px" 
height="500px" width="100%px" allowfullscreen>
</iframe>`;
  }, [isDarkMode, portalUrl]);

  return (
    <>
      KnowledgeBase portals can be embed in two methods.
      <ol className="ml-2">
        <li className="mt-2">
          <span>1. Opening in a modal panel when Chat Widget is installed</span>
        </li>
        <li className="mt-2">2. Using an Iframe</li>
      </ol>
      <Divider>Customize Portal</Divider>
      <div className="my-2">
        <div className="flex flex-row gap-2 items-center">
          Dark Mode:
          <Switch checked={isDarkMode} onChange={setDarkMode} />
        </div>
      </div>
      <Divider>Show as a modal (popup)</Divider>
      <div className="p-4">
        <CodeBlockWithCopyButton className="mt-2" language={"JS"}>
          {`ChatWidget.showEmbed({
  type: "KB",
  portalUrl: "${portalUrl}",
  isDarkMode: ${isDarkMode ? "true" : "false"},
});`}
        </CodeBlockWithCopyButton>
        <div className="mt-2">
          <i className="ri-information-fill mr-2"></i>
          Make sure that you have already loaded the Chat Widget before using
          the below code
        </div>
      </div>
      <Divider>Using an Iframe</Divider>
      <div className="p-4">
        <CodeBlockWithCopyButton className="mt-2" language={"HTML"}>
          {embedCode}
        </CodeBlockWithCopyButton>
        <div className="mt-2">
          <i className="ri-information-fill mr-2"></i>
          You may have to change the width and height as required
        </div>
      </div>
    </>
  );
};
