import { iEmailV2ConnectionData } from "@sdk/conversations/conversations.models";
import { Alert, message } from "antd";
import { SimpleCollapse } from "components/common/simple-collapse/simple-collapse";
import copyToClipboard from "copy-to-clipboard";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { useQueryWithStore } from "store/store.hooks";

export const EmailV2ForwardingEmailInstructions = ({
  connectionId,
}: {
  connectionId: string;
}) => {
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  return (
    <>
      <div className="text-center">
        You have to setup auto-forwarding in your existing email provider to
        start receiving emails in ClickConnector Inbox
      </div>
      <div className="flex flex-row justify-center items-center gap-4 my-4">
        <div className="">
          {(connection?.data as iEmailV2ConnectionData)?.defaultSendingEmail}
        </div>
        <i className="ri-arrow-right-line"></i>
        <div className="">
          {(connection?.data as iEmailV2ConnectionData)?.email}
        </div>
      </div>
      <SimpleCollapse title="Setting up forwarding in Google Workspace">
        <div className="ml-8 mb-8 reset-list-styles">
          <Alert
            type="warning"
            showIcon
            description="Please avoid using mailing lists like Google Groups to forward
              emails into ClickConnector, as they modify the From address,
              making it difficult to associate emails with the correct contact."
            message="Important:"
            className="my-4"
          />

          <h3 className="text-xl font-bold mt-4">
            Forwarding from Google Workspace or Gmail
          </h3>
          <p>
            Google offers two ways to set up automatic email forwarding. Ensure
            only one method is used to prevent duplicate emails in
            ClickConnector.
          </p>
          <h4 className="text-lg font-bold mt-2">
            Google Workspace Default Routing Rule (Recommended)
          </h4>
          <p>
            This method avoids rate limits and doesn't require a mailbox. Admin
            access is needed to set it up.
            <br />
            Learn more in{" "}
            <a target="_new" rel="noopener">
              Google&rsquo;s help article
            </a>
            .
          </p>
          <ul className="ml-4 my-2">
            <li>
              <strong>Important</strong>: Do not forward from an email linked to
              a Google Workspace user, mailbox, or Google Group.
            </li>
            <li>
              <strong>Note</strong>: Your setup might require using Apps &gt;
              Google Workspace &gt; Gmail settings &gt; Routing instead of
              "Default routing."
            </li>
          </ul>
          <h4 className="text-lg font-bold mt-2">Gmail Forwarding Setting</h4>
          <p>
            If you're not using Google Workspace, you can forward emails through
            the{" "}
            <a
              href="https://mail.google.com/mail/u/0/#settings/fwdandpop"
              target="_new"
              rel="noopener"
            >
              Forwarding and POP/IMAP settings
            </a>{" "}
            in Gmail. Be aware that Google limits daily forwarded emails.
          </p>
          <p>
            Learn more in{" "}
            <a
              href="https://support.google.com/a/answer/166852?hl=en"
              target="_new"
              rel="noopener"
            >
              Google&rsquo;s help article
            </a>
            .
          </p>
        </div>
      </SimpleCollapse>
      <SimpleCollapse title="Setting up forwarding in Microsoft 365">
        <div className="ml-8 mb-8 reset-list-styles">
          <h3 className="text-xl font-bold mt-4">
            Setting Up Forwarding in Office 365
          </h3>
          <ol>
            <li>
              Log in to Microsoft Office 365 as an admin and go to{" "}
              <strong>Admin &gt; Exchange</strong>.
            </li>
            <li>
              In the left menu, select <strong>Mail Flow</strong>.
            </li>
            <li>
              Click the '+' icon in the Rules section and choose{" "}
              <strong>Create a new rule</strong>.
            </li>
            <li>Enter a name for the rule.</li>
            <li>
              From the dropdown, select the condition{" "}
              <strong>The recipient is</strong>.
            </li>
            <li>
              Choose the support email address (e.g.,{" "}
              <a rel="noopener">
                {
                  (connection?.data as iEmailV2ConnectionData)
                    ?.defaultSendingEmail
                }
              </a>
              ) and click <strong>OK</strong>.
            </li>
            <li>
              In the next dropdown, select{" "}
              <strong>Redirect the message to</strong>.
            </li>
            <li>
              Enter the forwarding email address (e.g.,{" "}
              <a rel="noopener">
                {(connection?.data as iEmailV2ConnectionData)?.email}
              </a>
              ) in the &lsquo;check names&rsquo; field.
            </li>
            <li>
              Click <strong>Check Names</strong> to verify the address.
            </li>
            <li>
              Click <strong>OK</strong> and then <strong>Save</strong> to apply
              the rule.
            </li>
            <li>
              Make sure other users haven&rsquo;t set up their own forwarding
              rules in Outlook 365, or this rule won&rsquo;t work.
            </li>
          </ol>
        </div>
      </SimpleCollapse>
      <div className="mb-8 text-center text-gray-600 dark:text-gray-400 mt-8">
        <div className="">Your ClickConnector email address</div>
        <div
          className="bg-gray-200 dark:bg-gray-800 p-4 rounded-lg my-4 text-lg  cursor-pointer"
          onClick={() => {
            // Todo
            copyToClipboard(
              `${(connection.data as iEmailV2ConnectionData).email}`,
            );
            message.success("copied to clipboard");
          }}
        >
          {/* {emailConnection?.data?.email} */}
          {(connection.data as iEmailV2ConnectionData).email}{" "}
          <i className="ri-clipboard-line"></i>
        </div>
      </div>
    </>
  );
};
