import { axios, AxiosResponse } from "@sdk/axios";
import { DeepPartial } from "redux";
import { PaginatedOptions, PaginatedResults } from "./paginated-results";

export class EntityServices<Entity> {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(
    protected config: { basePath: string; token: string },
    private path: string,
    private hooks?: { onCreate?: (details) => any; onDelete?: () => any },
  ) {}

  async getAll() {
    const results: AxiosResponse<Entity[]> = await axios.get(
      this.config.basePath + `/${this.path}`,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async query(req: { query: any; options: PaginatedOptions }) {
    const results: AxiosResponse<PaginatedResults<Entity>> = await axios.post(
      this.config.basePath + `/${this.path}/query`,
      req,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async getById(id: string) {
    const results: AxiosResponse<Entity> = await axios.get(
      this.config.basePath + `/${this.path}/${id}`,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async editById(id: string, edits: DeepPartial<Entity>) {
    const results: AxiosResponse<Entity> = await axios.patch(
      this.config.basePath + `/${this.path}/${id}`,
      edits,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    return results.data;
  }

  async deleteById(id: string) {
    const results: AxiosResponse<Entity> = await axios.delete(
      this.config.basePath + `/${this.path}/${id}`,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    this.hooks?.onDelete?.();
    return results.data;
  }

  async create(details: DeepPartial<Entity>) {
    const results: AxiosResponse<Entity> = await axios.post(
      this.config.basePath + `/${this.path}/`,
      details,
      {
        headers: {
          Authorization: this.config.token,
        },
      },
    );
    this.hooks?.onCreate?.(details);
    return results.data;
  }
}
