import { iTask } from "@sdk/tasks/task-model";
import { CreateEntitySlice } from "../../utils/entity-slice-creator";
import { tasksDefaultQueryConfig } from "./tasks-default-query";

export const {
  slice: TasksSlice,
  reducers: TasksSliceReducer,
  actions: {
    setEntityQueryResults: setTasksQueryResults,
    setEntity: setTask,
    setEntities: setTasks,
    patchEntity: patchTask,
    addNewEntity: addNewTask,
    setEntityFetchingStatus: setTaskFetchingStatus,
    setEntitiesFetchingStatus: setTasksFetchingStatus,
    setEntityFailedStatus: setTaskFailedStatus,
    setEntityQueryError: setTasksQueryError,
    resetQuery: resetTasksQuery,
    setQueryList: setTaskQueryList
  }
} = CreateEntitySlice<iTask>({
  sliceName: "tasks",
  queryAliasResolver: (queryAlias: string) => {
    if (queryAlias === "all") {
      return {
        ...tasksDefaultQueryConfig,
        alias: "all"
      };
    }
  }
});
